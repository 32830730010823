import { Injectable } from '@angular/core'
import { Subject, BehaviorSubject } from 'rxjs'
import { CRUDService } from './crud.service'
import { getSessionValue, getArrayFromString } from './common-utils'
import { AES, enc } from 'crypto-js'
import io from 'socket.io-client'
import { UserService } from './shared/services/user.service'
import { MeetingService } from './shared/services/meeting.service'
import { RoleService } from './shared/services/role.service'
import { ValuesMapService } from './shared/services/values-map.service'
import { CandidateService } from './shared/services/candidate.service'

@Injectable({ providedIn: 'root' })
export class CommonService {
  constructor(
    private crudservice: CRUDService,
    private valuesMapService: ValuesMapService,
    private candidateService: CandidateService,
    private userService: UserService,
    private meetingService: MeetingService,
    private roleService: RoleService
  ) {}
  // tslint:disable: indent
  private CommonSource = new Subject<string>()
  private resetSource = new Subject()
  CommonSource$ = this.CommonSource.asObservable()
  resetSource$ = this.resetSource.asObservable()
  private messageSource = new BehaviorSubject(Object)
  currentMessage = this.messageSource.asObservable()
  private messageSource1 = new BehaviorSubject(Object)
  currentNotificationMessage = this.messageSource1.asObservable()
  private dataToBePassed = new BehaviorSubject(Object)
  dataToBePassedObs = this.dataToBePassed.asObservable()
  //io('https://upplftstaging.com:4001/'); // 'http://upplftmvp.com:4000/
  // Disable socket initialization
  // socket = io(this.crudservice.apiRoot);

  userInfo: any

  roleDetails: any
  benefitsArray: any
  candidatesList
  role_completion = 0
  business_completion = 0
  value_completion = 0
  miscellaneous_completion = 0
  profileCompletion = 0
  roleValueAray: any[] = [
    'looking_for',
    'roles_interested',
    'technical_skills',
    'salary',
    'day_rate',
    'location',
    'other_expectations'
  ]
  businessValueArray: any[] = ['industry_interest', 'business_size']
  roleDetail: any[] = [
    {
      name: 'Type',
      value: 'looking_for',
      percentage: 4
    },

    {
      name: 'Category',
      value: 'roles_interested',
      percentage: 4
    },

    {
      name: 'Skill Selector',
      value: 'technical_skills',
      percentage: 6
    },

    {
      name: 'Salary',
      value: 'salary',
      percentage: 1
    },

    {
      name: 'Day Rate',
      value: 'day_rate',
      percentage: 1
    },

    {
      name: 'Location',
      value: 'location',
      percentage: 1
    },

    {
      name: 'Incentives',
      value: 'other_expectations',
      percentage: 1
    },

    {
      name: 'Leadership Role',
      value: '',
      percentage: 1
    },

    {
      name: 'Remote Working',
      value: '',
      percentage: 1
    }
  ]
  businessDetail: any[] = [
    {
      name: 'Industry',
      value: 'industry_interest',
      percentage: 12.5
    },
    {
      name: 'Business Size',
      value: 'business_size',
      percentage: 12.5
    }
    // {
    // 	name: 'Hiring Manager',
    // 	value: '',
    // 	percentage: 5
    // }
  ]
  valueDetail: any[] = [
    {
      name: 'Values Selection',
      value: 'culture',
      percentage: 50
    }
  ]
  valueArray: any[] = ['culture']

  miscellaneousDetail: any[] = [
    // {
    // 	name: 'COVID Vaccinated',
    // 	value: 'vaccinated_yn',
    // 	percentage: 5
    // }
  ]
  miscellaneousArray: any[] = []

  // tslint:disable-next-line: typedef
  initializationSocket() {
    // disable socket initialization
    // return this.socket;
  }

  async updateRecordNew(moduleName: any, data: any): Promise<any> {
    if (moduleName.toLowerCase() === 'candidateprofile') {
      const perc = this.calcProfileComplPerc(data)
      data.profile_completion = perc
    }
    this.userInfo = getSessionValue('USER_INFO')
    let response: any
    if (typeof data.id !== 'undefined') {
      if (data.id !== null && data.id !== '') {
        data.updated_at = this.getCurrentDateTime()
        data.updated_by = this.userInfo.id
        response = await this.crudservice.AddData(moduleName, data).toPromise()
        response.id = data.id
      } else {
        data.created_at = this.getCurrentDateTime()
        data.created_by = Number(this.userInfo.id)
        if (typeof data.status === 'undefined') {
          data.status = 1
        }
        if (data.status === '' || data.status == null) {
          data.status = 1
        }
        response = await this.crudservice.AddData(moduleName, data).toPromise()
      }
    } else {
      data.created_at = this.getCurrentDateTime()
      data.created_by = Number(this.userInfo.id)
      if (typeof data.status === 'undefined') {
        data.status = 1
      }
      if (data.status === '' || data.status == null) {
        data.status = 1
      }
      response = await this.crudservice.AddData(moduleName, data).toPromise()
    }
    return response
  }

  // tslint:disable: typedef
  async scheduleTeamsMeeting(
    startDateTime: any,
    endDateTime: any,
    businessPostCandidate: any,
    businessInfo: any,
    jobInfo: any
  ) {
    const data = await this.userService.getById(businessPostCandidate.user_id).toPromise()
    const userInfo = data.data
    let event_id: any = encodeURIComponent(this.encryptData(businessPostCandidate.id))
    // let event_id = this.encryptData(businessPostCandidate.id);
    // event_id = encodeURIComponent(event_id);
    const content: any =
      '<tr><td>Hi ' + userInfo.first_name ||
      '' + ' ' + userInfo.last_name ||
      '' +
        ',</td></tr>' +
        '<p style="text-align: center;"><strong>Event Name:&nbsp;</strong>Interview with ' +
        businessInfo.name +
        // ' (Upplft)  </p>  <p><br></p>  <p><strong>' +
        '</p>  <p><br></p>  <p><strong>' +
        businessInfo.name +
        '</strong> would like to invite you to attend an interview for the role of <strong>' +
        jobInfo.title +
        '</strong></p> <p>Thanks and we look forward to talking to you.</p> <br>' +
        // dataRes[0].emailbody +
        '<tr><td> Need to make changes to this event? <br/><br/> Click to <a href="' +
        this.crudservice.getAPPRoot() +
        'calendar/cancel/' +
        event_id +
        '">Cancel</a></td></tr>' +
        '<tr><td> or <a href="' +
        this.crudservice.getAPPRoot() +
        'calendar/reschedule/' +
        event_id +
        '">Reschedule</a></td></tr>'
    const teamMeetingBody: any = {
      subject: 'Upplft - Discussion Schedule Details',
      body: {
        contentType: 'html',
        content: content
      },
      start: {
        dateTime: startDateTime,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
      },
      end: {
        dateTime: endDateTime,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
      },
      location: {
        displayName: 'Teams Room'
      },
      attendees: [
        {
          emailAddress: {
            address: userInfo.email,
            name: userInfo.first_name + ' ' + userInfo.last_name
          },
          type: 'required'
        }
      ],
      isOnlineMeeting: true,
      onlineMeetingProvider: 'teamsForBusiness'
    }
    const response = await this.meetingService.scheduleTeamsMeeting(teamMeetingBody).toPromise()
    if (response?.success == true) {
      businessPostCandidate.slot_start_time = startDateTime
      businessPostCandidate.slot_end_time = endDateTime
      businessPostCandidate.meeting_id = response?.data?.id
      businessPostCandidate.status = 1
      businessPostCandidate.action_status = 6
      await this.roleService
        .updateRoleCandidate({
          body: businessPostCandidate,
          role_id: businessPostCandidate.post_id,
          user_id: businessPostCandidate.user_id
        })
        .toPromise()
      return response
    }
  }

  async cancelTeamsMeeting(businessPostCandidate: any) {
    // const userInfo = getSessionValue("USER_INFO");
    // TODOD: implement teams meeting stuff
    await this.meetingService.cancelTeamsMeeting(businessPostCandidate.meeting_id).toPromise()
  }

  encryptData(value: any) {
    return AES.encrypt(JSON.stringify(value), 'encryptSecretKey').toString()
  }

  decryptData(encryptedValue: any) {
    try {
      const bytes = AES.decrypt(encryptedValue, 'encryptSecretKey')
      if (bytes.toString()) {
        return JSON.parse(bytes.toString(enc.Utf8))
      }
      return encryptedValue
    } catch (e) {}
  }

  getCurrentDateTime(): any {
    const date = new Date()

    const DateTime =
      date.getFullYear() +
      '-' +
      ('00' + (date.getMonth() + 1)).slice(-2) +
      '-' +
      ('00' + date.getDate()).slice(-2) +
      ' ' +
      ('00' + date.getHours()).slice(-2) +
      ':' +
      ('00' + date.getMinutes()).slice(-2) +
      ':00'
    return DateTime
  }

  changeMessage(message: any) {
    this.messageSource.next(message)
  }
  changeNotificationMessage(message: any) {
    this.messageSource1.next(message)
  }
  changeData(newData) {
    this.dataToBePassed.next(newData)
  }

  async addAppNotification(title: any, message: any, users: any[], ref_type: any, ref_id: any) {
    users.forEach(async (user) => {
      const AppNotification = {
        title,
        status: 1,
        user_id: user,
        message,
        read_status: 0,
        read_on: null,
        ref_type,
        ref_id
      }
      await this.saveRecord('AppNotifications', AppNotification)
    })
  }
  // async getNotifications() {
  //     let userInfo = getSessionValue('USER_INFO');
  //     let notifications: any = await this.crudservice.getDataByField('AppNotifications', 'user_id', userInfo?.id).toPromise();
  //     let notificationArray: any = notifications.filter((val: any) => (val.read_status === '0'));
  //     sessionStorage.setItem('notification_count', notificationArray.length);
  //     this.changeMessage("Notification Arrived")
  // }
  async getUnreadCount() {
    this.userInfo = getSessionValue('USER_INFO')
    const data: any = await this.crudservice.getUnreadCount().toPromise()
    if (data) {
      const notificationCount: any = data.filter((val: any) => val.user_id === this.userInfo.id)
      if (notificationCount?.length > 0) {
        sessionStorage.setItem('notification_count', notificationCount[0].unread_count)
        this.changeMessage('Notification Arrived')
      } else {
        sessionStorage.setItem('notification_count', '0')
        this.changeMessage('Notification Arrived')
      }
    }
  }

  async saveRecord(moduleName: any, data: any): Promise<any> {
    if (moduleName.toLowerCase() === 'candidateprofile') {
      const perc = this.calcProfileComplPerc(data)
      data.profile_completion = perc
    }
    this.userInfo = getSessionValue('USER_INFO')
    let response: any
    if (typeof data.id !== 'undefined') {
      if (data.id !== null && data.id !== '') {
        data.updated_at = this.getCurrentDateTime()
        data.updated_by = this.userInfo.id
        response = await this.crudservice.updateData(moduleName, data).toPromise()
        response.id = data.id
      } else {
        data.created_at = this.getCurrentDateTime()
        data.created_by = Number(this.userInfo.id)
        if (typeof data.status === 'undefined') {
          data.status = 1
        }
        if (data.status === '' || data.status == null) {
          data.status = 1
        }
        response = await this.crudservice.createData(moduleName, data).toPromise()
      }
    } else {
      data.created_at = this.getCurrentDateTime()
      data.created_by = Number(this.userInfo.id)
      if (typeof data.status === 'undefined') {
        data.status = 1
      }
      if (data.status === '' || data.status == null) {
        data.status = 1
      }
      response = await this.crudservice.createData(moduleName, data).toPromise()
    }
    if (moduleName.toLowerCase() === 'candidateprofile') {
      response.profile_completion = data.profile_completion
    }
    response.ROWID = response?.data?.[0]?.id
    return response
  }

  /* async updateRecord(moduleName: any, data: any): Promise<any>{
		let response = await this.saveRecord(moduleName, data);
		return response;
	} */

  async updateRecord(moduleName: any, data: any): Promise<any> {
    if (moduleName.toLowerCase() === 'candidateprofile') {
      const perc = this.calcProfileComplPerc(data)
      data.profile_completion = perc
    }
    this.userInfo = getSessionValue('USER_INFO')
    let response: any
    if (typeof data.id !== 'undefined') {
      if (data.id !== null && data.id !== '') {
        data.updated_at = this.getCurrentDateTime()
        data.updated_by = this.userInfo.id
        response = await this.crudservice.update(moduleName, data).toPromise()
        response.id = data.id
      } else {
        data.created_at = this.getCurrentDateTime()
        data.created_by = Number(this.userInfo.id)
        if (typeof data.status === 'undefined') {
          data.status = 1
        }
        if (data.status === '' || data.status == null) {
          data.status = 1
        }
        response = await this.crudservice.create(moduleName, data).toPromise()
      }
    } else {
      data.created_at = this.getCurrentDateTime()
      data.created_by = Number(this.userInfo.id)
      if (typeof data.status === 'undefined') {
        data.status = 1
      }
      if (data.status === '' || data.status == null) {
        data.status = 1
      }
      response = await this.crudservice.create(moduleName, data).toPromise()
    }
    if (moduleName.toLowerCase() === 'candidateprofile') {
      response.profile_completion = data.profile_completion
    }
    return response
  }

  calcProfileComplPerc(profile) {
    const fieldsToInc = [
      'image_url',
      'education',
      'location',
      'language',
      'salary',
      'day_rate',
      'personality_type',
      'bio',
      'skills',
      'culture',
      'frustrations',
      'personality',
      'goals',
      'other_expectations',
      'business_size',
      'industry_interest',
      'linkedin_url',
      'domain_exp',
      'hashtags',
      'residency_status',
      'contact_preference',
      'looking_for',
      'qualification',
      'roles_interested',
      'technical_skills',
      'english_proficiency',
      'hobbies'
    ]
    let perc: any = 0
    let filledFieldsCount = 0
    let fieldsCount = fieldsToInc.length + 6
    Object.entries(profile).forEach((entry) => {
      const [key, value] = entry
      if (fieldsToInc?.includes(key)) {
        /* if (key !== 'created_by' && key !== 'created_at' && key !== 'updated_by' && key !== 'updated_at' && key !== 'id' && key !== 'status' && key !== 'user_id' && key !== 'name' && key !== 'marital_status'
					&& key !== 'current_job' && key !== 'personality_desc' && key !== 'experience_2_img_url' && key !== 'status') { */
        if (typeof value != 'undefined' && value !== null && value !== '') {
          if (key == 'skills' || key == 'culture' || key == 'technical_skills') {
            let valueArr = value.toString().split(';')
            if (valueArr.length > 1) {
              filledFieldsCount = filledFieldsCount + 1
            }
            if (valueArr.length > 5) {
              filledFieldsCount = filledFieldsCount + 1
            }
            if (valueArr.length > 9) {
              filledFieldsCount = filledFieldsCount + 1
            }
          } else {
            filledFieldsCount = filledFieldsCount + 1
          }
        }
      }
    })
    if (filledFieldsCount > 0) {
      perc = (filledFieldsCount * 100) / fieldsCount
    }
    if (perc > 100) {
      perc = 100
    }
    perc = Math.round(perc)
    return perc
  }

  getCandidateData(candidate) {
    this.role_completion = 0
    this.business_completion = 0
    this.value_completion = 0
    this.miscellaneous_completion = 0
    this.profileCompletion = 0

    if (candidate.currenly_employed_yn == 'Y') {
      candidate.currentlyEmployed = true
    } else {
      candidate.currentlyEmployed = false
    }
    this.calcRoleCompletion(candidate)
    candidate.skillsList = null
    if (typeof candidate.skills !== 'undefined') {
      if (candidate.skills !== null) {
        candidate.skillsList = this.getActiveInactiveSkillsList(
          candidate.skills,
          this.roleDetails.skillsArray
        )
      }
    }
    candidate.benefitsList = null
    if (typeof candidate.other_expectations !== 'undefined') {
      if (candidate.other_expectations !== null) {
        candidate.benefitsList = this.getActiveInactiveList(
          candidate.other_expectations,
          this.benefitsArray
        )
      }
    }
    candidate.cultureList = null

    if (typeof candidate.culture !== 'undefined') {
      if (candidate.culture !== null) {
        candidate.cultureList = this.getActiveInactiveListForOther(
          candidate.culture,
          this.roleDetails.cultureArray
        )
      }
    }
    candidate.technical_skills = candidate.technical_skills?.split(';')
    candidate.frustrations = candidate.frustrations?.split(';')
    candidate.personality = candidate.personality?.split(';')
    candidate.languages = candidate.languages?.split(';')
    candidate.domain_exp = candidate.domain_exp?.split(';')
    candidate.industry_interest = candidate.industry_interest?.split(';')
    candidate.looking_for = candidate.looking_for?.split(';')
  }

  calcRoleCompletion(candidate) {
    this.roleValueAray.map((value) => {
      if (candidate[value] !== undefined && candidate[value] !== null && candidate[value] !== '') {
        let obj = this.roleDetail.filter((e) => e.value === value)
        this.role_completion += obj[0].percentage
      }
    })
    this.profileCompletion += this.role_completion
    this.calcBusinessCompletion(candidate)
  }

  calcBusinessCompletion(candidate) {
    this.businessValueArray.map((value) => {
      if (candidate[value] !== undefined && candidate[value] !== null && candidate[value] !== '') {
        let obj = this.businessDetail.filter((e) => e.value === value)

        this.business_completion += obj[0].percentage
      }
    })
    this.profileCompletion += this.business_completion
    this.calcValueCompletion(candidate)
  }

  calcValueCompletion(candidate) {
    this.valueArray.map((value) => {
      if (candidate[value] !== undefined && candidate[value] !== null && candidate[value] !== '') {
        let obj = this.valueDetail.filter((e) => e.value === value)
        this.value_completion += obj[0].percentage
      }
    })
    this.profileCompletion += this.value_completion
    this.calcMiscellaneousCompletion(candidate)
  }

  calcMiscellaneousCompletion(candidate) {
    let perc: any = 0
    this.miscellaneousArray.map((value) => {
      if (
        candidate[value] !== undefined &&
        candidate[value] !== null &&
        candidate[value] !== '' &&
        candidate[value] !== 'N'
      ) {
        let obj = this.miscellaneousDetail.filter((e) => e.value === value)

        this.miscellaneous_completion += obj[0].percentage
      }
    })
    this.profileCompletion += this.miscellaneous_completion
    //this.profileCompletion += (this.miscellaneous_completion * 5) / 100;
    perc = this.profileCompletion
    return perc
  }

  async loadCandidates(businessProfile, roleDetails, list_type): Promise<any> {
    this.roleDetails = roleDetails
    let data: any

    let benefitsData = await this.valuesMapService
      .getFilteredValuesMap(50, 0, '', 'BENEFITS')
      .toPromise()
    this.benefitsArray = benefitsData.data.items
    this.benefitsArray = getArrayFromString(this.roleDetails.benefits)

    if (list_type == 'applied_candidates') {
      const appliedCandidates = await this.roleService
        .getCandidatesByRole(this.roleDetails.id)
        .toPromise()
      // data = appliedCandidates.filter((e) => Number(e.profile_completion) > 50);
      data = appliedCandidates.data.items
    } else {
      const { data: candidateData } = await this.candidateService
        .getAllCandidates({
          limit: 0,
          status: [1]
        })
        .toPromise()
      data = candidateData.items
    }

    data = data.filter((val) => val.experience_1_compname != businessProfile.name)
    for (let candidate of data) {
      if (candidate.currenly_employed_yn == 'Y') {
        candidate.currentlyEmployed = true
      } else {
        candidate.currentlyEmployed = false
      }
      //let matchPerc = this.calculateMatchPerc(candidate, businessProfile, roleDetails);
      let matchPerc = await this.calculateMatchPercV1_1(candidate, businessProfile, roleDetails)
      candidate.match_perc = matchPerc
      //this.getCandidateData(candidate);
      //candidate.match_perc = this.profileCompletion;
    }
    return data
  }

  calculateMatchPerc(candidate, businessProfile, roleDetails) {
    let perc = 0
    let roleMatchPerc = this.calcRoleMatch(candidate, roleDetails, 50)
    let businessMatchPerc = this.calcBusinessMatch(candidate, businessProfile, 25)
    let valuesMatchPerc = this.calcValuesMatch(candidate, businessProfile, 20)
    let miscMatching = this.calcMiscMatch(candidate, 5)
    perc = roleMatchPerc + businessMatchPerc + valuesMatchPerc + miscMatching
    return Math.round(perc)
  }

  async calculateMatchPercV1_1(candidate, businessProfile, roleDetails): Promise<any> {
    let perc = 0
    let hygieneMatchPerc = await this.calcHygieneMatch(candidate, roleDetails, 40)
    let domainMatchPerc = await this.calcDomainMatch(candidate, roleDetails, 40)
    let skillsMatchPerc = this.calcSkillsMatchV1_1(candidate, roleDetails, 10)
    let valuesMatchPerc = this.calcValuesMatchV1_1(candidate, businessProfile, 10)
    perc = domainMatchPerc + hygieneMatchPerc + valuesMatchPerc + skillsMatchPerc
    perc = Math.round(perc)
    return perc
  }

  calcSkillsMatchV1_1(candidate, roleDetails, weightage) {
    let perc: any = 0
    let candidateSkills: any[] = Array.isArray(candidate?.technical_skills)
      ? candidate?.technical_skills
      : candidate.technical_skills?.split(';')
    let reqSkills: any[] = roleDetails.skills?.split(';')
    let skills_match_count = 0
    if (typeof reqSkills != 'undefined' && typeof candidateSkills != 'undefined') {
      for (let skill of reqSkills) {
        if (candidateSkills?.toString().trim().includes(skill?.toString().trim())) {
          skills_match_count = skills_match_count + 1
        }
      }
    }
    if (skills_match_count > 0) {
      if (skills_match_count == 1) {
        perc = 30
      } else if (skills_match_count == 2) {
        perc = 50
      } else if (skills_match_count == 3) {
        perc = 80
      } else if (skills_match_count >= 4) {
        perc = 100
      }
      /* perc = (culture_match_count / reqCulture.length) * 100; */
      perc = (perc * weightage) / 100
    }
    return perc
  }

  calcValuesMatchV1_1(candidate, businessProfile, weightage) {
    let perc: any = 0
    let candidateCulture: any[] = candidate.culture?.split(';')
    let reqCulture: any[] = businessProfile.company_values?.split(';')
    let culture_match_count = 0
    if (typeof candidateCulture != 'undefined' && typeof reqCulture != 'undefined') {
      for (let benefit of reqCulture) {
        if (candidateCulture?.toString().trim().includes(benefit?.toString().trim())) {
          culture_match_count = culture_match_count + 1
        }
      }
    }
    if (culture_match_count > 0) {
      if (culture_match_count == 1) {
        perc = 15
      } else if (culture_match_count == 2) {
        perc = 25
      } else if (culture_match_count == 3) {
        perc = 40
      } else if (culture_match_count == 4) {
        perc = 50
      } else if (culture_match_count == 5) {
        perc = 75
      } else if (culture_match_count == 6) {
        perc = 90
      } else if (culture_match_count > 7) {
        perc = 100
      }
      /* perc = (culture_match_count / reqCulture.length) * 100; */
      perc = (perc * weightage) / 100
    }
    return perc
  }

  async calcDomainMatch(candidate, roleDetails, weightage) {
    let perc: any = 0
    let exp_match = false
    let domain_match = false
    let role_domainArr: any[] = roleDetails?.experience ? roleDetails?.experience?.split(';') : []
    let candidate_domainArr: any[] = Array.isArray(candidate?.domain_exp)
      ? candidate?.domain_exp
      : candidate?.domain_exp?.split(';')
    let domain_exp_list = getSessionValue('domain_exp_list')

    for (let roleDomain of role_domainArr) {
      if (!exp_match) {
        // let role_domain: any[] = roleDomain.split("-");
        const [roleDomainName, ...roleDomainExp] = roleDomain.split('-')
        let index = candidate_domainArr?.findIndex((val) => val.split('-')[0] == roleDomainName)
        if (index > -1) {
          domain_match = true
          let [...candidateDomainExp] = candidate_domainArr[index].split('-')
          let candidateExpindex = domain_exp_list?.findIndex(
            (val) => val.description == candidateDomainExp.join('-')
          )
          let roleExpindex = domain_exp_list?.findIndex(
            (val) => val.description == roleDomainExp.join('-')
          )
          if (roleExpindex >= candidateExpindex) {
            exp_match = true
            break
          }
        }
      }
    }
    if (domain_match) {
      perc = perc + 50
      if (exp_match) {
        perc = perc + 50
      } else {
        perc = perc + 25
      }
    }
    perc = (perc * weightage) / 100
    return perc
  }

  async calcHygieneMatch(candidate, roleDetails, weightage) {
    let perc: any = 0
    let role_type_match = false
    let salary_match = false

    let role_type: any[] = roleDetails?.role_type?.split(';')
    let roles_interested: any[] = candidate?.roles_interested?.split(';')
    if (typeof roles_interested != 'undefined') {
      for (let roleType of roles_interested) {
        if (role_type?.includes(roleType)) {
          role_type_match = true
          break
        }
      }
    }

    if (role_type_match) {
      perc = perc + 50
    }
    let roleSalary: any = roleDetails.salary?.replaceAll('$', '').replaceAll('k', '')
    let roleSalaryArr: any = roleSalary?.split('-')
    if (roleDetails?.day_rate == candidate?.day_rate) {
      salary_match = true
    }
    if (!salary_match) {
      const salaryListSession = getSessionValue('salary_list')
      let salary_list = salaryListSession ? salaryListSession : []
      let index = salary_list?.findIndex((val) => val?.description == candidate?.salary)
      let candidateSalaryBand: any = salary_list[index]?.code?.split('-')
      if (candidateSalaryBand?.length > 1 && roleSalaryArr?.length > 1) {
        if (
          candidateSalaryBand[0] >= roleSalaryArr[0] &&
          candidateSalaryBand[0] <= roleSalaryArr[1] &&
          candidateSalaryBand[1] <= roleSalaryArr[1] &&
          candidateSalaryBand[1] >= roleSalaryArr[1]
        ) {
          salary_match = true
        }
      }
    }
    if (salary_match) {
      perc = perc + 50
    }
    perc = (perc * weightage) / 100
    return perc
  }

  calcRoleMatch(candidate, roleDetails, weightage) {
    let perc: any = 0
    let role_type_match = false
    let emp_type_match = false
    // let category_match = false;
    // let skills_match = false;
    // let day_rate_match = false;
    // let salary_match = false;
    let incentives_match = false
    let location_match = false
    let remote_match = false
    let leadership_match = true

    let emp_type: any[] = roleDetails.employment_type?.split(';')
    let looking_for: any[] = candidate.looking_for?.split(';')

    if (typeof looking_for != 'undefined') {
      for (let empType of looking_for) {
        if (emp_type?.includes(empType)) {
          emp_type_match = true
          break
        }
      }
    }

    let role_type: any[] = roleDetails.role_type?.split(';')
    let roles_interested: any[] = candidate.roles_interested?.split(';')

    if (typeof roles_interested != 'undefined') {
      for (let roleType of roles_interested) {
        if (role_type?.includes(roleType)) {
          role_type_match = true
          break
        }
      }
    }

    let expectations: any[] = candidate.other_expectations?.split(';')
    if (typeof expectations != 'undefined') {
      if (expectations?.includes('Remote Working')) {
        if (roleDetails.remote == 'Y') {
          remote_match = true
        }
      } else {
        if (roleDetails.remote != 'Y') {
          remote_match = true
        }
      }
    }

    let candidateSkills: any[] = candidate.technical_skills?.split(';')
    let reqSkills: any[] = roleDetails.skills?.split(';')
    let skills_match_count = 0
    if (typeof reqSkills != 'undefined' && typeof candidateSkills != 'undefined') {
      for (let skill of reqSkills) {
        if (candidateSkills?.includes(skill)) {
          skills_match_count = skills_match_count + 1
        }
      }
    }
    let candidateExpectations: any[] = candidate.other_expectations?.split(';')
    let reqBenefits: any[] = roleDetails.benefits?.split(';')
    if (typeof candidateExpectations != 'undefined' && typeof reqBenefits != 'undefined') {
      for (let benefit of reqBenefits) {
        if (candidateExpectations?.includes(benefit)) {
          incentives_match = true
        }
      }
    }
    let aplLocations: any[] = roleDetails.location?.split(';')
    if (typeof aplLocations != 'undefined') {
      if (aplLocations?.includes(candidate.location)) {
        location_match = true
      }
    }
    /* let roleSalary: any = roleDetails.salary.replace('$', '').replace('k', '');
		let candidateSalary: any = candidate.salary.replace('$', '').replace('k', '');
		let candidateSalaryArr: any = candidateSalary.split('-');
		let roleSalaryArr: any = roleSalary.split('-');
		if(candidateSalaryArr[0]==roleSalaryArr[0]) */

    if (emp_type_match) {
      perc = perc + 20
    }
    if (role_type_match) {
      perc = perc + 20
    }
    if (skills_match_count > 0) {
      let skill_perc = (skills_match_count / reqSkills.length) * 100
      skill_perc = skill_perc * 0.3
      skill_perc = skill_perc
      perc = perc + skill_perc
    }
    if (roleDetails.salary == candidate.salary) {
      perc = perc + 5
    }
    if (roleDetails.day_rate == candidate.day_rate) {
      perc = perc + 5
    }
    if (incentives_match) {
      perc = perc + 5
    }
    if (location_match) {
      perc = perc + 5
    }
    if (remote_match) {
      perc = perc + 5
    }
    if (leadership_match) {
      perc = perc + 5
    }
    perc = (perc * weightage) / 100
    return perc
  }

  calcBusinessMatch(candidate, businessProfile, weightage) {
    let perc: any = 0
    /* industry -50%
		business -50% */
    let industry_match = false
    let business_match = false

    let candidateIndustry: any[] = candidate.industry_interest?.split(';')
    if (typeof candidateIndustry != 'undefined') {
      if (candidateIndustry?.includes(businessProfile.industry)) {
        industry_match = true
      }
    }
    let candidateBusinessSize: any[] = candidate.business_size?.split(';')
    if (typeof candidateBusinessSize != 'undefined') {
      if (candidateIndustry?.includes(businessProfile.business_size)) {
        business_match = true
      }
    }
    if (industry_match) {
      perc = perc + 50
    }
    if (business_match) {
      perc = perc + 50
    }
    perc = (perc * weightage) / 100
    return perc
  }

  calcValuesMatch(candidate, businessProfile, weightage) {
    let perc: any = 0

    let candidateCulture: any[] = candidate.culture?.split(';')
    let reqCulture: any[] = businessProfile.company_culture?.split(';')
    let culture_match_count = 0
    if (typeof candidateCulture != 'undefined' && typeof reqCulture != 'undefined') {
      for (let benefit of reqCulture) {
        if (candidateCulture?.includes(benefit)) {
          culture_match_count = culture_match_count + 1
        }
      }
    }
    if (culture_match_count > 0) {
      perc = (culture_match_count / reqCulture.length) * 100
      perc = (perc * weightage) / 100
    }

    return perc
  }

  calcMiscMatch(candidate, weightage) {
    let perc: any = 0
    // if (candidate.vaccinated_yn == 'Y') {
    // 	perc = 100;
    // }
    perc = (perc * weightage) / 100
    return perc
  }

  getActiveInactiveList(value: any, array: any[]): any[] {
    let arrayValue: any[] = getArrayFromString(value)
    let valueList: any[] = []
    arrayValue.forEach((element) => {
      let value: any = {}
      let index = array.findIndex((val) => val.description == element)
      value.description = element
      if (index > -1) {
        value.active = true
      } else {
        value.active = false
      }
      valueList.push(value)
    })

    return valueList
  }

  getActiveInactiveSkillsList(value: any, array: any[]): any[] {
    let arrayValue: any[] = getArrayFromString(value)
    let valueList: any[] = []
    arrayValue.forEach((element) => {
      let value: any = {}
      let index = array.findIndex((val) => val == element)
      value.description = element
      if (index > -1) {
        value.active = true
      } else {
        value.active = false
      }
      valueList.push(value)
    })

    return valueList
  }

  getActiveInactiveListForOther(value: any, array: any[]): any[] {
    let arrayValue: any[] = getArrayFromString(value)
    let valueList: any[] = []
    array.forEach((element) => {
      let value: any = {}
      let index = arrayValue.findIndex((val) => val == element.description)
      value.description = element.description
      if (index > -1) {
        value.active = true
      } else {
        value.active = false
      }
      valueList.push(value)
    })

    return valueList
  }
}
