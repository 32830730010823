<div class="body" fxLayout="row wrap">
  <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
    <div class="row col-12 pl-0">
      <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12">
        <div class="postsBox">
          <div class="row col-12 text-center pr-0">
            <div
              [ngClass]="
                notificationIcon
                  ? 'col-xs-12 col-sm-12 col-md-11 col-lg-11 pl-0 pr-0 search'
                  : 'col-xs-12 col-sm-12 col-md-12 col-lg-12 pl-0 pr-0 search'
              "
            >
              <app-header-search-bar></app-header-search-bar>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-1 col-lg-1" *ngIf="notificationIcon">
              <app-header-notification-button [previousPath]="previousPath">
              </app-header-notification-button>
            </div>
          </div>
        </div>
        <hr />
        <div class="postsBox pl-4 pr-4">
          <div class="d-flex mt-3 mb-3" style="justify-content: space-between">
            <div class="">
              <i
                class="fa fa-angle-left back-icon"
                aria-hidden="true"
                routerLink="/dashboards/business-settings"
              ></i>
              <label class="heading-text font-18">Business Account</label>
            </div>
            <div class="pr-2">
              <img
                src="../../../../assets/images/chat-icon/Edit.svg"
                style="width: 15px; height: 15px"
                (click)="editBusinessForm()"
              />
            </div>
          </div>
          <form [formGroup]="businessProfileForm" noValidate autocomplete="off">
            <div class="row form-group">
              <div class="col-lg-6 col-md-6 col-sm-12">
                <label class="form-label">Legal Entity</label>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-12 form-value">
                <mat-label class="example-full-width" *ngIf="!editFormFlag">
                  {{ businessProfileForm.controls.legalEntity.value }}
                </mat-label>
                <mat-form-field class="example-full-width" appearance="fill" *ngIf="editFormFlag">
                  <input
                    type="text"
                    matInput
                    placeholder="Legal Entity"
                    formControlName="legalEntity"
                  />
                </mat-form-field>
              </div>
              <div
                *ngIf="submitted && f?.legalEntity.dirty && f.legalEntity.errors"
                class="alert error-message"
              >
                <span *ngIf="f.legalEntity.errors.required"> Please Enter legalEntity </span>
              </div>
            </div>
            <div class="row form-group mt-2">
              <div class="col-lg-6 col-md-6 col-sm-12">
                <label class="form-label">Company Address</label>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-12 form-value">
                <mat-label class="example-full-width" *ngIf="!editFormFlag">
                  {{ businessProfileForm.controls.companyAddress.value }}
                </mat-label>
                <mat-form-field
                  *ngIf="editFormFlag"
                  appearance="fill"
                  class="select-mat"
                  fxFlex="100%"
                >
                  <mat-select
                    #matSelect
                    name="location"
                    placeholder="Select Location"
                    panelClass="myPanelClass2"
                    formControlName="companyAddress"
                  >
                    <div (mouseleave)="matSelect.close()">
                      <mat-option *ngFor="let location of locationList" [value]="location">
                        {{ location }}
                      </mat-option>
                    </div>
                  </mat-select>
                </mat-form-field>
              </div>
              <div
                *ngIf="submitted && f?.companyAddress.dirty && f.companyAddress.errors"
                class="alert error-message"
              >
                <span *ngIf="f.companyAddress.errors.required"> Please Enter companyAddress </span>
              </div>
            </div>
            <div class="row form-group mt-2">
              <div class="col-lg-6 col-md-6 col-sm-12">
                <label class="form-label">First Name</label>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-12 form-value">
                <mat-label class="example-full-width" *ngIf="!editFormFlag">
                  {{ businessProfileForm.controls.firstName.value }}
                </mat-label>
                <mat-form-field class="example-full-width" appearance="fill" *ngIf="editFormFlag">
                  <input
                    type="text"
                    matInput
                    placeholder="First Name"
                    formControlName="firstName"
                  />
                </mat-form-field>
              </div>
              <div
                *ngIf="submitted && f?.firstName.dirty && f.firstName.errors"
                class="alert error-message"
              >
                <span *ngIf="f.firstName.errors.required"> Please Enter firstName </span>
              </div>
            </div>
            <div class="row form-group mt-2">
              <div class="col-lg-6 col-md-6 col-sm-12">
                <label class="form-label">Last Name</label>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-12 form-value">
                <mat-label class="example-full-width" *ngIf="!editFormFlag">
                  {{ businessProfileForm.controls.lastName.value }}
                </mat-label>
                <mat-form-field class="example-full-width" appearance="fill" *ngIf="editFormFlag">
                  <input type="text" matInput placeholder="Last Name" formControlName="lastName" />
                </mat-form-field>
              </div>
              <div
                *ngIf="submitted && f?.lastName.dirty && f.lastName.errors"
                class="alert error-message"
              >
                <span *ngIf="f.lastName.errors.required"> Please Enter lastName </span>
              </div>
            </div>
            <div class="row form-group mt-2">
              <div class="col-lg-6 col-md-6 col-sm-12">
                <label class="form-label">Email</label>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-12 form-value">
                <mat-label class="example-full-width" *ngIf="!editFormFlag">
                  {{ businessProfileForm.controls.email.value }}
                </mat-label>
                <mat-form-field class="example-full-width" appearance="fill" *ngIf="editFormFlag">
                  <input type="text" matInput placeholder="Email" formControlName="email" />
                </mat-form-field>
              </div>
              <div
                *ngIf="submitted && f?.email.dirty && f.email.errors"
                class="alert error-message"
              >
                <span *ngIf="f.email.errors.required"> Please Enter email </span>
              </div>
            </div>
            <div class="row form-group mt-2">
              <div class="col-lg-6 col-md-6 col-sm-12">
                <label class="form-label">Phone Number</label>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-12 form-value">
                <mat-label class="example-full-width" *ngIf="!editFormFlag">
                  {{ businessProfileForm.controls.phonenumber.value }}
                </mat-label>
                <mat-form-field class="example-full-width" appearance="fill" *ngIf="editFormFlag">
                  <input
                    type="text"
                    matInput
                    placeholder="Phone Number"
                    formControlName="phonenumber"
                  />
                </mat-form-field>
              </div>
              <div
                *ngIf="submitted && f?.phonenumber.dirty && f.phonenumber.errors"
                class="alert error-message"
              >
                <span *ngIf="f.phonenumber.errors.required"> Please Enter phonenumber </span>
              </div>
            </div>
            <div class="row form-group mt-2">
              <div class="col-lg-6 col-md-6 col-sm-12">
                <label class="form-label">Role in Business</label>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-12 form-value">
                <mat-label class="example-full-width" *ngIf="!editFormFlag">
                  {{ businessProfileForm.controls.roleInBusiness.value }}
                </mat-label>
                <mat-form-field class="example-full-width" appearance="fill" *ngIf="editFormFlag">
                  <input
                    type="text"
                    matInput
                    placeholder="Role in Business"
                    formControlName="roleInBusiness"
                  />
                </mat-form-field>
              </div>
              <div
                *ngIf="submitted && f?.roleInBusiness.dirty && f.roleInBusiness.errors"
                class="alert error-message"
              >
                <span *ngIf="f.roleInBusiness.errors.required"> Please Enter roleInBusiness </span>
              </div>
            </div>
            <div class="row form-group mt-2">
              <div class="col-lg-6 col-md-6 col-sm-12">
                <label class="form-label">Type of Business</label>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-12 form-value">
                <mat-label class="example-full-width" *ngIf="!editFormFlag">
                  {{ businessProfileForm.controls.typeOfBusiness.value }}
                </mat-label>
                <mat-form-field class="example-full-width" appearance="fill" *ngIf="editFormFlag">
                  <input
                    type="text"
                    matInput
                    placeholder="Type of Business"
                    formControlName="typeOfBusiness"
                  />
                </mat-form-field>
              </div>
              <div
                *ngIf="submitted && f?.typeOfBusiness.dirty && f.typeOfBusiness.errors"
                class="alert error-message"
              >
                <span *ngIf="f.typeOfBusiness.errors.required"> Please Enter typeOfBusiness </span>
              </div>
            </div>
            <div class="row form-group mt-2">
              <div class="col-lg-6 col-md-6 col-sm-12">
                <label class="form-label">ABN</label>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-12 form-value">
                <mat-label class="example-full-width" *ngIf="!editFormFlag">
                  {{ businessProfileForm.controls.ABN.value }}
                </mat-label>
                <mat-form-field class="example-full-width" appearance="fill" *ngIf="editFormFlag">
                  <input type="text" matInput placeholder="ABN" formControlName="ABN" />
                </mat-form-field>
              </div>
              <div *ngIf="submitted && f?.ABN.dirty && f.ABN.errors" class="alert error-message">
                <span *ngIf="f.ABN.errors.required"> Please Enter ABN </span>
              </div>
            </div>
            <div class="row form-group mt-2">
              <div class="col-lg-6 col-md-6 col-sm-12">
                <label class="form-label">Website</label>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-12 form-value">
                <mat-label class="example-full-width" *ngIf="!editFormFlag">
                  {{ businessProfileForm.controls.website.value }}
                </mat-label>
                <mat-form-field class="example-full-width" appearance="fill" *ngIf="editFormFlag">
                  <input type="text" matInput placeholder="Website" formControlName="website" />
                </mat-form-field>
              </div>
              <div
                *ngIf="submitted && f?.website.dirty && f.website.errors"
                class="alert error-message"
              >
                <span *ngIf="f.website.errors.required"> Please Enter Website </span>
              </div>
            </div>
            <div
              class="col-6 mx-auto"
              style="margin-top: 10px; text-align: center"
              *ngIf="editFormFlag"
            >
              <button mat-raised-button class="search-btn" (click)="submit()">Submit</button>
            </div>
          </form>
        </div>
      </div>
      <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
        <app-profile-overview-verified></app-profile-overview-verified>
      </div>
    </div>
  </div>
</div>
