import { Component, OnInit } from '@angular/core'
import { getSessionValue } from 'src/app/common-utils'
import { environment } from '../../../../environments/environment'
@Component({
  selector: 'app-candidate-setting',
  templateUrl: './candidate-setting.component.html',
  styleUrls: ['./candidate-setting.component.scss']
})
export class CandidateSettingComponent implements OnInit {
  previousPath: any
  showBody: any = false
  notificationIcon: boolean = false
  role: any
  candidateSettings = [
    { name: 'Candidate Account', link: '/dashboards/candidate-account' },
    { name: 'About Upplft', link: '/dashboards/about-upplft' },
    { name: 'Change Email Address', link: '/dashboards/change-email' },
    { name: 'Change Password', link: '/dashboards/change-password' },
    { name: 'Help', link: '/dashboards/help' },
    { name: "FAQ's", link: '/dashboards/faqs' },
    { name: 'Deactivate Account', link: '/dashboards/deactivate-account' }
  ]
  keysArray: string[] = ['name']
  clonedCandidateSettings
  constructor() {}

  ngOnInit(): void {
    this.previousPath = '/dashboards/candidate-settings'
    this.showBody = environment.menuShowHide
    this.role = getSessionValue('USER_INFO')?.user_type
    this.notificationIcon = environment.notificationShow
    this.clonedCandidateSettings = [...this.candidateSettings]
  }

  eventFromChild(data) {
    this.candidateSettings = data
  }
}
