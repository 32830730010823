import { Component, OnInit } from '@angular/core'
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal'
import { PostRoleComponent } from '../../modals/post-role/post-role.component'
import { ViewCandidatesComponent } from '../../modals/view-candidates/view-candidates.component'
import { getArrayFromString, getSessionValue, getArrayStringValue } from 'src/app/common-utils'
import { Router } from '@angular/router'
import { CRUDService } from 'src/app/crud.service'
import { ScheduleCalendarComponent } from 'src/app/scheduler/schedule-calendar/schedule-calendar.component'
import { NgxSpinnerService } from 'ngx-spinner'
import { CommonService } from 'src/app/common.service'
import { environment } from 'src/environments/environment'
import { RecruitmentProcessComponent } from '../../modals/recruitment-process/recruitment-process.component'
import { ToastrService } from 'ngx-toastr'
import { ValuesMapService } from 'src/app/shared/services/values-map.service'
import { RoleService } from 'src/app/shared/services/role.service'
import { SkillsetService } from 'src/app/shared/services/skillset.service'
import { S3Service } from 'src/app/shared/services/s3.service'

@Component({
  selector: 'app-view-role',
  templateUrl: './view-role.component.html',
  styleUrls: ['./view-role.component.scss']
})
export class ViewRoleComponent implements OnInit {
  benefitsArray: any = []
  skillsArray: any = []
  bsModalRef: BsModalRef
  bsModalRef1: BsModalRef
  selectedSalary: any
  selectedDayRate: any
  selectedAssessmentRound: any
  roleDetails: any = {}
  businessProfile: any
  userInfo: any
  apiUrl: any
  roleStatusDesc: any

  salaryArray: any = []
  dayRateArray: any = []
  editDataFlag = false
  role: any = ''
  description: any = ''
  location: any = ''
  salary: any = ''
  benefits: any = []
  skills: any = []
  day_rate: any
  attachment: any
  postAttachmentArray: any
  imageSrc: any[] = []
  resImageSrc: any[] = []
  videoUrl: any[] = []
  resVideoUrl: any[] = []
  audioUrl: any[] = []
  resAudioUrl: any[] = []
  docUrl: any[] = []
  resDocUrl: any[] = []
  previousPath: any
  target: any
  allowEditing = true
  targetAttachment: any
  stateData: any
  assessment_rounds: any
  assessmentRoundArray: any = ['1', '2', '3', '4', '5']
  notificationIcon: boolean = false

  roleType: any
  selectedRoleType: any
  roleTypeArray: any = []
  otherRoleTypeFlag: boolean

  employmentType: any
  selectedEmploymentType: any
  employmentTypeArray: any = [
    'Part-Time',
    'Full-Time',
    'Contracting',
    'Casual',
    'Side-hustle',
    'Advisory or Board Role'
  ]

  startDate: any
  startDateArray: any = [
    'Immediate/ASAP',
    'Within next 30 days',
    'When I find the right person',
    "We're in no rush"
  ]

  remote_work: any
  isChecked: boolean = false

  softSkills: any = []
  selectedSoftSkills: any
  softSkillsArray: any = []

  experience: any
  domain: any
  domainArray: any = []
  domainExp: any
  personalityFactors
  cultureFactors
  personalityTypeDetails
  cultureInfo

  constructor(
    public bsModalService: BsModalService,
    private CRUDService: CRUDService,
    private commonService: CommonService,
    private router: Router,
    private SpinnerService: NgxSpinnerService,
    private toastr: ToastrService,
    private valuesMapService: ValuesMapService,
    private roleService: RoleService,
    private skillsetService: SkillsetService,
    private s3Service: S3Service
  ) {
    this.stateData = this.router.getCurrentNavigation().extras.state
  }

  ngOnInit(): void {
    this.userInfo = getSessionValue('USER_INFO')
    this.apiUrl = environment.apiUrl
    this.businessProfile = getSessionValue('BUSINESS_PROFILE')

    this.selectedEmploymentType = this.employmentTypeArray.find(
      (val) => val === this.roleDetails.employment_type
    )

    if (typeof this.stateData?.roleInfo !== 'undefined') {
      this.SpinnerService.show()
      this.roleDetails = this.stateData.roleInfo
      this.roleDetails.role_link = `${environment.appRoot.replace('www.', '')}${
        environment.isOldRoleLink ? '/view-job/' : '/authentication/apply-job/'
      }${this.roleDetails.id}${
        this.userInfo.user_type == '4'
          ? '?' + btoa(JSON.stringify({ talentPartnerLink: true }))
          : ''
      }`
      this.roleDetails.isCopied = false
      this.allowEditing = this.stateData.edit
      this.roleStatusDesc = this.getStatusDesc(this.roleDetails.status)
      // this.benefitsArray = getArrayFromString(this.roleDetails.benefits);
      // this.skillsArray = getArrayFromString(this.roleDetails.skills);
      this.benefits = getArrayFromString(this.roleDetails?.benefits)
      this.softSkills = getArrayFromString(this.roleDetails.soft_skills)
      this.experience = getArrayFromString(this.roleDetails.experience)
      this.skills = getArrayFromString(this.roleDetails.skills)
      this.SpinnerService.hide()

      this.valuesMapService
        .getFilteredValuesMap(100, 0, '', 'PERSONALITY_FACTORS')
        .subscribe((res) => {
          this.personalityFactors = res.data.items
          this.personalityFactors.forEach((ele) => {
            ele['description'] = JSON.parse(
              ele['description']?.replace('"Adjectives"', ', "Adjectives"')
            )
          })
          this.loadRoleTypeData()
        })

      this.valuesMapService.getFilteredValuesMap(100, 0, '', 'CULTURE_FACTORS').subscribe((res) => {
        this.cultureFactors = res.data.items
        this.cultureFactors.forEach((ele) => {
          ele['description'] = JSON.parse(ele['description'])
        })
        this.loadCultureData()
      })

      this.valuesMapService.getFilteredValuesMap(100, 0, '', 'ROLES').subscribe((res) => {
        const sortedItem = res.data?.items?.sort((a, b) => a.display_order - b.display_order)
        let names = sortedItem.map(function (item: any) {
          return item['description']
        })
        this.roleTypeArray = names
        this.selectedRoleType = this.roleTypeArray.find(
          (val) => val.code === this.roleDetails.role_type
        )
      })

      this.valuesMapService.getFilteredValuesMap(100, 0, '', 'SALARY').subscribe((res) => {
        this.salaryArray = res.data?.items
        this.selectedSalary = this.salaryArray.find((val) => val.code === this.roleDetails.salary)
      })
      this.valuesMapService.getFilteredValuesMap(100, 0, '', 'DAY_RATE').subscribe((res) => {
        const names = res.data?.items.map(function (item) {
          return item.description
        })
        this.dayRateArray = names
        this.selectedDayRate = this.dayRateArray.find((val) => val === this.roleDetails.day_rate)
      })
      this.valuesMapService.getFilteredValuesMap(100, 0, '', 'INCENTIVES').subscribe((res) => {
        const sortedItems = res.data.items.sort((a, b) => (a.description > b.description ? 1 : -1))
        let names = sortedItems.map(function (item: any) {
          return item['description']
        })
        this.benefitsArray = names
      })
      // this.valuesMapService.getFilteredValuesMap(100, 0, "", 'BENEFITS').subscribe((data: any) => {
      //   this.benefitsArray = data;
      // });
      this.valuesMapService.getFilteredValuesMap(100, 0, '', 'SOFT_SKILLS').subscribe((res) => {
        const sortedItems = res.data.items.sort((a, b) => (a.description > b.description ? 1 : -1))
        //data = data.sort((a, b) => (a.description > b.description ? 1 : -1));
        let names = sortedItems.map(function (item: any) {
          return item['description']
        })
        this.softSkillsArray = names
      })
      this.valuesMapService.getFilteredValuesMap(100, 0, '', 'INDUSTRY').subscribe((res) => {
        const sortedItems = res.data.items.sort((a, b) => (a.description > b.description ? 1 : -1))
        //data = data.sort((a, b) => (a.description > b.description ? 1 : -1));
        let names = sortedItems.map(function (item: any) {
          return item['description']
        })
        this.domainArray = names
      })

      this.skillsetService.getFilteredSkillset(2000, 0, '').subscribe((res) => {
        // data = data.sort((a, b) => (a.description > b.description ? 1 : -1));
        let names = res.data?.items.map(function (item: any) {
          //return item['category'] + '-' + item['sub_category'];
          return item['category']
        })
        this.skillsArray = names
      })
      this.getBusinessPostAttachments()
    } else if (this.userInfo.user_type === '2') {
      this.router.navigate(['dashboards/roles'])
    } else if (this.userInfo.user_type === '4') {
      this.router.navigate(['dashboards/active-roles'])
    } else {
      this.router.navigate(['dashboards/work'])
    }
    this.notificationIcon = environment.notificationShow
    this.remoteWorkValue()
  }
  async getBusinessPostAttachments() {
    const res = await this.roleService.getRoleAttachmentsByRoleId(this.roleDetails.id).toPromise()
    this.postAttachmentArray = res.data.items
    this.postAttachmentArray = this.postAttachmentArray.filter(
      (attachment) => attachment.status == '1'
    )
  }
  viewCandidateModal() {
    // const initialState: any = { data: this.roleDetails, type: 'role', };
    // this.bsModalRef = this.bsModalService.show(ViewCandidatesComponent, { ariaLabelledBy: 'modal-basic-title', class: 'modal-height modal-lg', backdrop: 'static', initialState });

    if (this.roleDetails.display_candidates == 'Y') {
      const initialState: any = { data: this.roleDetails, type: 'role' }
      this.bsModalRef = this.bsModalService.show(ViewCandidatesComponent, {
        ariaLabelledBy: 'modal-basic-title',
        class: 'modal-height modal-lg view-candidates-modal',
        backdrop: 'static',
        initialState
      })
    } else {
      this.toastr.info(
        'We are currently working on finding candidates that match criteria for this role. Candidates list will be displayed soon.'
      )
    }
  }
  viewCalendarModal() {
    const initialState: any = { data: this.roleDetails, type: 'role' }
    // const modalConfig = {
    //   animated: true,
    //   keyboard: true,
    //   backdrop: true,
    //   ignoreBackdropClick: false,
    // };
    this.bsModalRef = this.bsModalService.show(ScheduleCalendarComponent, {
      ariaLabelledBy: 'modal-basic-title',
      class: 'event-modal',
      backdrop: 'static',
      initialState
    })
  }
  viewRecruitmentModal() {
    const initialState: any = {
      data: this.roleDetails,
      type: 'role',
      roldId: this.roleDetails.id
    }
    // const modalConfig = {
    //   animated: true,
    //   keyboard: true,
    //   backdrop: true,
    //   ignoreBackdropClick: false,
    // };
    this.bsModalRef = this.bsModalService.show(RecruitmentProcessComponent, {
      ariaLabelledBy: 'modal-basic-title',
      class: 'event-modal recruitment-process-modal',
      backdrop: 'static',
      initialState
    })
  }

  editRole() {
    this.editDataFlag = true
    this.role = this.roleDetails.title
    this.roleType = this.roleDetails.role_type
    // this.employmentType = this.employmentType;
    this.startDate = this.roleDetails.start_by
    this.remote_work = this.roleDetails.remote_work
    this.description = this.roleDetails.description
    this.description = this.description.replace(/\r?\n|\r/g, ' ').trim()
    this.location = this.roleDetails.location
    this.salary = Number(this.roleDetails.salary)
    // this.benefits = this.benefits;
    // this.softSkills = this.softSkills;
    // this.experience = this.experience;
    // this.skills = this.skills;
    this.day_rate = this.selectedDayRate
    this.assessment_rounds = this.roleDetails.assessment_rounds
  }

  openPostRoleModal() {
    const initialState: any = {
      roleDetails: this.roleDetails
    }
    const bsModalRef = this.bsModalService.show(PostRoleComponent, {
      ariaLabelledBy: 'modal-basic-title',
      class: 'modal-lg',
      backdrop: 'static',
      initialState
    })

    bsModalRef.onHide.subscribe((result: any) => {
      this.roleService.getRoleById(this.roleDetails.id).subscribe((data) => {
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
          this.router.navigate(['/dashboards/view-role'], {
            state: { roleInfo: data.data, edit: true }
          })
        )
      })
    })
  }

  async saveRole() {
    this.editDataFlag = false
    this.roleDetails.title = this.role
    this.roleDetails.role_type = this.roleType
    this.roleDetails.start_by = this.startDate
    this.roleDetails.remote_work = this.remote_work
    this.roleDetails.employment_type = getArrayStringValue(this.employmentType)
    this.roleDetails.description = this.description
    this.roleDetails.location = this.location
    this.roleDetails.salary = this.salary
    this.roleDetails.benefits = getArrayStringValue(this.benefits)
    this.roleDetails.soft_skills = getArrayStringValue(this.softSkills)
    this.roleDetails.experience = getArrayStringValue(this.experience)
    this.roleDetails.skills = getArrayStringValue(this.skills)
    this.roleDetails.day_rate = this.day_rate
    this.roleDetails.assessment_rounds = this.assessment_rounds
    const json = {
      title: this.roleDetails.title,
      role_type: this.roleDetails.role_type,
      start_by: this.roleDetails.start_by,
      remote_work: this.roleDetails.remote_work,
      employment_type: this.roleDetails.employment_type,
      description: this.roleDetails.description,
      location: this.roleDetails.location,
      salary: this.roleDetails.salary,
      benefits: this.roleDetails.benefits,
      soft_skills: this.roleDetails.soft_skills,
      experience: this.roleDetails.experience,
      skills: this.roleDetails.skills,
      day_rate: this.roleDetails.day_rate,
      hashtags: '',
      visibility: 1,
      status: 1,
      business_id: this.businessProfile.id,
      post_type: 2,
      assessment_rounds: this.roleDetails.assessment_rounds,
      display_order: 1,
      id: this.roleDetails.id
    }
    this.roleStatusDesc = this.getStatusDesc(this.roleDetails.status)
    this.valuesMapService.getFilteredValuesMap(100, 0, '', 'SALARY').subscribe((data: any) => {
      const salaryArray: any[] = data
      this.salaryArray = data
      this.selectedSalary = salaryArray.find((val) => val.code === this.roleDetails.salary)
    })
    this.valuesMapService.getFilteredValuesMap(100, 0, '', 'DAY_RATE').subscribe((data: any) => {
      const names = data.map(function (item: any) {
        return item.description
      })
      this.dayRateArray = names
      const dayRateData: any[] = names
      this.selectedDayRate = dayRateData.find((val) => val === this.roleDetails.day_rate)
    })
    const response: any = await this.commonService.updateRecord('BusinessPosts', json)
    if (response.success == 'true' || response.success == true) {
      this.toastr.success('Post update successfully')
    }
  }
  showImageUpload(attachment: any) {
    this.attachment = attachment
  }
  async uploadImageProfile(event: any, srcUrl: any, type: any) {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0]
      await this.SpinnerService.show()
      if (file.size <= environment.imageDocSize && (srcUrl === 'image' || srcUrl === 'doc')) {
        await this.uploadFile(file, srcUrl, type)
      } else if (
        file.size <= environment.audioVideoSize &&
        (srcUrl === 'video' || srcUrl === 'audio')
      ) {
        await this.uploadFile(file, srcUrl, type)
      } else {
        await this.SpinnerService.hide()
        this.toastr.warning(environment.fileErrorMessage)
      }
    }
  }
  async uploadFile(file: any, srcUrl: any, type: any) {
    await this.SpinnerService.show()
    if (this.attachment && type == '1') {
      await this.SpinnerService.show()
      await this.s3Service
        .uploadFile(file, `business/${this.businessProfile.id}/${this.userInfo.id}`)
        .then(async (res) => {
          const postAttachmentJson: any = {
            status: 1,
            post_id: this.roleDetails.id,
            display_order: 1,
            updated_at: null,
            updated_by: null,
            file_name: file.name,
            file_path: res.Location,
            file_type: srcUrl,
            id: this.attachment.id
          }
          await this.SpinnerService.show()
          if (this.attachment.id) {
            const attachmentRes = await this.roleService
              .updateAttachment(this.roleDetails.id, this.attachment.id, postAttachmentJson)
              .toPromise()
            if (attachmentRes.success) {
              this.attachment.file_path = res.Location
            }
          } else {
            const attachmentRes = await this.roleService
              .createAttachment(this.roleDetails.id, postAttachmentJson)
              .toPromise()
            if (attachmentRes.success) {
              this.attachment.file_path = res.Location
            }
          }
          this.SpinnerService.hide()
          this.toastr.success('Attachment uploaded')
        })
        .finally(async () => {
          await this.SpinnerService.hide()
        })
    } else {
      await this.SpinnerService.show()
      await this.s3Service
        .uploadFile(file, `business/${this.businessProfile.id}/${this.userInfo.id}`)
        .then(async (res) => {
          const urlConst = res.Location
          if (srcUrl === 'image') {
            this.imageSrc.push(urlConst)
            this.resImageSrc.push(res.Location)
          } else if (srcUrl === 'video') {
            this.videoUrl.push(urlConst)
            this.resVideoUrl.push(res.Location)
          } else if (srcUrl === 'audio') {
            this.audioUrl.push(urlConst)
            this.resAudioUrl.push(res.Location)
          } else if (srcUrl === 'doc') {
            this.docUrl.push({ file_name: file.name, url: res.Location })
            this.resDocUrl.push(res.Location)
          }
          const fileJson = {
            file_name: file.name,
            file_type: srcUrl,
            file_path: res.Location
          }
          await this.uploadAttachments(fileJson)
        })
        .finally(() => {
          this.SpinnerService.hide()
        })
    }
  }
  async uploadAttachments(fileJson: any) {
    const postAttachmentJson: any = {
      status: 1,
      post_id: this.roleDetails.id,
      display_order: 1,
      updated_at: null,
      updated_by: null,
      file_name: fileJson.file_name,
      file_path: fileJson.file_path,
      file_type: fileJson.file_type
    }
    const response = await this.roleService
      .createAttachment(this.roleDetails.id, postAttachmentJson)
      .toPromise()
    if (response.success) {
      this.getBusinessPostAttachments()
      this.toastr.success('Attachment Uploaded')
    }
  }

  openDeleteModal(targetModal: any, target: any) {
    this.bsModalRef1 = this.bsModalService.show(targetModal)
    this.target = target
  }
  openDeleteModal1(targetModal: any, target: any, attachment: any) {
    this.bsModalRef1 = this.bsModalService.show(targetModal)
    this.target = target
    this.targetAttachment = attachment
  }
  closeModal() {
    this.bsModalService.hide()
  }
  async holdPosting() {
    const json = {
      title: this.roleDetails.title,
      role_type: this.roleDetails.role_type,
      employment_type: this.roleDetails.employment_type,
      start_by: this.roleDetails.start_by,
      remote_work: this.roleDetails.remote_work,
      description: this.roleDetails.description,
      location: this.roleDetails.location,
      salary: this.roleDetails?.salary,
      benefits: this.roleDetails.benefits,
      soft_skills: this.roleDetails.soft_skills,
      experience: this.roleDetails.experience,
      skills: this.roleDetails.skills,
      day_rate: this.roleDetails.day_rate,
      hashtags: '',
      visibility: 1,
      status: 3,
      business_id: this.businessProfile.id,
      post_type: 2,
      assessment_rounds: 1,
      display_order: 1,
      id: this.roleDetails.id
    }
    const response = await this.roleService.updateARolePost(json).toPromise()
    if (response.success == true) {
      this.roleStatusDesc = this.getStatusDesc(json.status)
      this.roleDetails.status = json.status
      this.bsModalService.hide()
      this.toastr.success('Role put on hold')
    }
  }
  async reOpenRole() {
    const json = {
      title: this.roleDetails.title,
      role_type: this.roleDetails.role_type,
      employment_type: this.roleDetails.employment_type,
      start_by: this.roleDetails.start_by,
      remote_work: this.roleDetails.remote_work,
      description: this.roleDetails.description,
      location: this.roleDetails.location,
      salary: this.roleDetails?.salary,
      benefits: this.roleDetails.benefits,
      soft_skills: this.roleDetails.soft_skills,
      experience: this.roleDetails.experience,
      skills: this.roleDetails.skills,
      day_rate: this.roleDetails.day_rate,
      hashtags: '',
      visibility: 1,
      status: 1,
      business_id: this.businessProfile.id,
      post_type: 2,
      assessment_rounds: 1,
      display_order: 1,
      id: this.roleDetails.id
    }
    const response = await this.roleService.updateARolePost(json).toPromise()
    if (response.success == true) {
      this.roleStatusDesc = this.getStatusDesc(json.status)
      this.roleDetails.status = json.status
      this.bsModalService.hide()
      this.toastr.success('Role reopened')
    }
  }

  async deleteRole() {
    if (this.target == 'role') {
      const json = {
        title: this.roleDetails.title,
        role_type: this.roleDetails.role_type,
        employment_type: this.roleDetails.employment_type,
        start_by: this.roleDetails.start_by,
        remote_work: this.roleDetails.remote_work,
        description: this.roleDetails.description,
        location: this.roleDetails.location,
        salary: this.roleDetails?.salary,
        benefits: this.roleDetails.benefits,
        soft_skills: this.roleDetails.soft_skills,
        experience: this.roleDetails.experience,
        skills: this.roleDetails.skills,
        day_rate: this.roleDetails.day_rate,
        hashtags: '',
        visibility: 1,
        status: 2,
        business_id: this.businessProfile.id,
        post_type: 2,
        assessment_rounds: 1,
        display_order: 1,
        id: this.roleDetails.id
      }
      const response = await this.roleService.updateARolePost(json).toPromise()
      if (response.success == true) {
        this.roleStatusDesc = this.getStatusDesc(json.status)
        this.roleDetails.status = json.status
        this.bsModalService.hide()
        this.toastr.success('Role deleted')
        this.router.navigate(['/dashboards/roles'])
      }
    } else if (this.target == 'attachment') {
      this.removeAttachment()
    }
  }

  async closePosting() {
    const json = {
      title: this.roleDetails.title,
      role_type: this.roleDetails.role_type,
      employment_type: this.roleDetails.employment_type,
      start_by: this.roleDetails.start_by,
      remote_work: this.roleDetails.remote_work,
      description: this.roleDetails.description,
      location: this.roleDetails.location,
      salary: this.roleDetails?.salary,
      benefits: this.roleDetails.benefits,
      soft_skills: this.roleDetails.soft_skills,
      experience: this.roleDetails.experience,
      skills: this.roleDetails.skills,
      day_rate: this.roleDetails.day_rate,
      hashtags: '',
      visibility: 1,
      status: 4,
      business_id: this.businessProfile.id,
      post_type: 2,
      assessment_rounds: 1,
      display_order: 1,
      id: this.roleDetails.id
    }
    const response = await this.roleService.updateARolePost(json).toPromise()
    if (response.success == true) {
      this.roleStatusDesc = this.getStatusDesc(json.status)
      this.roleDetails.status = json.status
      this.bsModalService.hide()
      this.toastr.success('Role closed')
      this.router.navigate(['/dashboards/roles'])
    }
  }

  async removeAttachment() {
    this.targetAttachment.status = 3
    const response = await this.roleService
      .updateAttachment(this.roleDetails.id, this.targetAttachment.id, this.targetAttachment)
      .toPromise()
    if (response.success) {
      this.bsModalService.hide()
      this.getBusinessPostAttachments()
    }
  }

  downloadFile(doc: any) {
    const route = doc
    window.open(route)
  }

  getStatusDesc(status: string | number) {
    if (status == 1) {
      return 'Active'
    } else if (status == 4) {
      return 'Closed'
    } else if (status == 3) {
      return 'Hold'
    } else if (status == 2) {
      return 'Deleted'
    }
  }

  selectRoleType(value) {
    if (value === 'Other (not listed)' || value === 'other (not listed)') {
      this.otherRoleTypeFlag = true
      this.roleType = ''
    } else {
      this.otherRoleTypeFlag = false
    }
  }

  remoteWorkValue() {
    let remoteWork = ''
    if (this.roleDetails.remote_work === 'Y') {
      remoteWork = 'Yes'
      this.isChecked = true
    } else {
      remoteWork = 'No'
      this.isChecked = false
    }
    return remoteWork
  }

  remoteWork() {
    this.remote_work = this.isChecked ? 'Y' : 'N'
  }
  addDomainExp() {
    this.experience.push(this.domain + ' - ' + this.domainExp)
    let index = this.domainArray.findIndex((val) => val == this.domain)
    this.domain = null
    this.domainExp = null
    this.domainArray.splice(index, 1)
  }

  formatLabel(value: number) {
    if (value >= 10000) {
      return Math.round(value / 10000) + 'k'
    }

    return value
  }
  changeString(str) {
    return str?.replaceAll(';', ', ')
  }
  viewSalary() {
    let salary = Number(this.roleDetails.salary)
    return salary
  }
  async copySuccess(event, data) {
    this.roleDetails.isCopied = false
    data.isCopied = event

    if (event == true) {
      this.toastr.success('Link copied successfully')
    }
  }

  async loadRoleTypeData() {
    if (this.roleDetails?.id) {
      const data = await this.roleService
        .getPersonalityRankingByRoleId(this.roleDetails?.id)
        .toPromise()
      const personalityTypeDetails = data.data
      for (let x in personalityTypeDetails) {
        if (x !== 'id' && x !== 'post_id') {
          if (personalityTypeDetails) {
            this.personalityTypeDetails = this.personalityFactors.find((value) => {
              return value.code === personalityTypeDetails[x]
            })
            break
          }
        }
      }
    }
  }

  loadCultureData() {
    try {
      let cultureData = JSON.parse(this.roleDetails?.culture_profile)
      cultureData = cultureData.sort((a, b) => a.rank - b.rank)

      this.cultureInfo = this.cultureFactors.find(
        (e) => e.description?.name === cultureData[0].name
      )?.description
    } catch (error) {
      return
    }
  }
}
