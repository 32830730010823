<div class="modal-header">
  <h6 class="circularBold signUpHeading">Event Details</h6>
  <button
    type="button"
    class="close ml-2"
    aria-label="Close"
    data-dismiss="modal"
    (click)="closeDialog()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="body" fxLayout="row wrap" style="overflow-x: auto">
  <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
    <!-- <div class="row col-12 pl-0"> -->
    <div class="role-main-div">
      <div *ngIf="events?.extendedProps?.type === 'SCHEDULE'" class="details-div">
        <label class="title-value">Job Title</label>
        <mat-label class="example-full-width title">
          {{ viewAs == 'Candidate' ? events?.title : roleDetails?.title }}
        </mat-label>
      </div>
      <div
        *ngIf="events?.extendedProps?.type === 'SCHEDULE' && viewAs == 'Business'"
        class="details-div mt-3"
      >
        <label class="title-value">Candidate Name</label>
        <mat-label class="example-full-width title">
          {{ events?.title }}
        </mat-label>
      </div>
      <div
        *ngIf="events?.extendedProps?.type === 'SCHEDULE' && viewAs == 'Candidate'"
        class="details-div mt-3"
      >
        <label class="title-value">Company Name</label>
        <mat-label class="example-full-width title">
          {{ businessData?.name }}
        </mat-label>
      </div>
      <div *ngIf="events?.extendedProps?.type === 'SCHEDULE'" class="details-div mt-3">
        <label class="title-value">Location</label>
        <mat-label class="example-full-width title">
          {{ events?.extendedProps?.location?.replaceAll(';', ', ') }}
        </mat-label>
      </div>
      <div *ngIf="events?.extendedProps?.type === 'SCHEDULE'" class="details-div mt-3">
        <label class="title-value">Start Date/Time</label>
        <mat-label class="example-full-width title">
          {{
            events?.extendedProps?.slot_start_time
              ? (events?.extendedProps?.slot_start_time | date: 'dd/MM/yy HH:mm' : '+0:00')
              : (events?.extendedProps?.startDate | date: 'dd/MM/yy HH:mm' : '+0:00')
          }}
        </mat-label>
      </div>
      <!-- <div class="details-div mt-3">
                <label class="title-value">End Date</label>
                <mat-label class="example-full-width title">
                    {{events?.extendedProps?.endDate}}
                </mat-label>
            </div> -->
      <div *ngIf="events?.extendedProps?.type === 'SCHEDULE'" class="details-div mt-3">
        <label class="title-value">Duration</label>
        <mat-label class="example-full-width title"> 30 Mins </mat-label>
      </div>
      <div *ngIf="events?.extendedProps?.type === 'SLOT' && roleDetails" class="details-div mt-3">
        <label class="title-value">Job Title</label>
        <mat-label class="example-full-width title">
          {{ roleDetails?.title }}
        </mat-label>
      </div>
      <div *ngIf="events?.extendedProps?.type === 'SLOT' && roleDetails" class="details-div mt-3">
        <label class="title-value">Location</label>
        <mat-label class="example-full-width title">
          {{ roleDetails?.location.replaceAll(';', ', ') }}
        </mat-label>
      </div>
      <div *ngIf="events?.extendedProps?.type === 'SLOT'" class="details-div mt-3">
        <label class="title-value">Start Date</label>
        <mat-label class="example-full-width title">
          {{ events?.extendedProps?.startDate | date: 'dd/MM/yyyy' }}
        </mat-label>
      </div>
      <div *ngIf="events?.extendedProps?.type === 'SLOT'" class="details-div mt-3">
        <label class="title-value">Start/End Time</label>
        <mat-label class="example-full-width title">
          {{ slotTime }}
        </mat-label>
      </div>
    </div>
    <div fxLayout="row wrap" class="buttonPanel" *ngIf="events?.meeting_id !== ''">
      <!-- <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100"> -->
      <button
        mat-raised-button
        (click)="cancel(events)"
        class="mr-2 secondary mt-2"
        aria-label="CANCEL"
      >
        Cancel
      </button>
      <button mat-raised-button (click)="reschedule(events)" class="primary mt-2" aria-label="SAVE">
        Reschedule
      </button>

      <!-- </div> -->
    </div>
    <!-- </div> -->
  </div>
</div>
