import { Component, EventEmitter, OnInit, Output } from '@angular/core'
import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal'

@Component({
  selector: 'app-other-info-value',
  templateUrl: './other-info-value.component.html',
  styleUrls: ['./other-info-value.component.scss']
})
export class OtherInfoValueComponent implements OnInit {
  otherValue: any
  data: any
  @Output() messageEvent = new EventEmitter<string>()
  constructor(
    private _bsModalRef: BsModalRef,
    private options: ModalOptions
  ) {}

  ngOnInit(): void {}
  ngAfterViewInit(): void {
    this.data = this.options.initialState.data
    this.otherValue = this.data
  }
  closeModal() {
    this._bsModalRef.hide()
  }
  submitData() {
    this.messageEvent.emit(this.otherValue)
    this._bsModalRef.hide()
  }
}
