<div class="modal-content">
  <div class="modal-header">
    <h6 class="circularBold signUpHeading">Notifications</h6>
    <button
      type="button"
      class="close ml-2"
      aria-label="Close"
      data-dismiss="modal"
      (click)="closeModal()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div style="display: flex">
      <hr class="hr-style" />
      <span class="message-style">Unread Messages</span>
      <hr class="hr-style" />
    </div>
    <div *ngFor="let notification of notificationArray; let i = index">
      <div class="postsBox mt-3" *ngIf="notification.sender_id !== user_uuid">
        <div class="row role-content-div">
          <div class="d-flex d-upper-div">
            <div class="profile-div pl-2">
              <img class="profile-img" src="../../../assets/images/background/login-register.jpg" />
            </div>
            <div class="pl-3 info-div">
              <h3 class="profile-label">{{ notification.title }}</h3>
              <label class="role-text mt-1">{{ notification.message }}</label>
              <mat-hint class="time-label">{{ notification.created }}</mat-hint>
            </div>
          </div>

          <div class="action-buttons-div">
            <button
              mat-raised-button
              class="view-role-btn"
              (click)="updateReadStatus(notification)"
            >
              Open
            </button>
            <button
              mat-raised-button
              class="view-role-btn"
              *ngIf="notification?.showAccept"
              (click)="acceptConnection(notification)"
            >
              Accept
            </button>
            <button
              mat-raised-button
              class="view-role-btn"
              (click)="clearNotification(notification, i)"
            >
              Clear
            </button>
          </div>
        </div>
      </div>
    </div>
    <div style="display: flex; margin-top: 8px">
      <hr class="hr-style" />
      <span class="message-style">Read Messages</span>
      <hr class="hr-style" />
    </div>

    <div *ngFor="let notification of readNotifications; let i = index">
      <div class="postsBox mt-3" *ngIf="notification.sender_id !== user_uuid">
        <div class="row role-content-div">
          <div class="d-flex d-upper-div">
            <div class="profile-div pl-2">
              <img class="profile-img" src="../../../assets/images/background/login-register.jpg" />
            </div>
            <div class="pl-3 info-div" (click)="openNotifications(notification)">
              <h3 class="profile-label">{{ notification.title }}</h3>
              <label class="role-text mt-1">{{ notification.message }}</label>
              <mat-hint class="time-label">{{ notification.created }}</mat-hint>
            </div>
          </div>
          <!-- <div>
                        <button mat-raised-button class="view-role-btn"
                            (click)="clearNotification(notification,i)">Clear</button>
                        <button mat-raised-button class="view-role-btn"
                            (click)="updateReadStatus(notification)">Open</button>
                    </div> -->
        </div>
      </div>
    </div>
  </div>
  <ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
    <p style="color: white">Please Wait.</p>
  </ngx-spinner>
</div>
