<div class="ease-in">
  <div class="modal-header">
    <div class="w-100 position-relative">
      <button
        mat-icon-button
        *ngIf="isCLoseIcon"
        class="close-icon-button"
        (click)="closeModal('exit')"
      >
        <i class="fa fa-times"></i>
      </button>
    </div>
    <h1 class="circularBold signUpHeading">
      <!-- Wait, are you sure you want to leave? -->
      {{ header }}
    </h1>
  </div>
  <div class="modal-body">
    <div
      fxLayout="row wrap"
      class="text-center"
      style="margin-top: 10px; margin-left: 5%; margin-right: 5%"
    >
      <div
        fxFlex.gt-sm="100"
        fxFlex.gt-xs="100"
        fxFlex="100"
        style="margin-top: 10px; display: flex"
      >
        <p class="description-text">
          <!-- To help us better match you to roles we’d like to understand your personality type. Your progress
                    won’t be saved, but you can complete this test at a later date from your profile. -->
          {{ description1 }}
          <br />
          <br />
          <!-- Are you sure you don’t have time to quickly complete this questionnaire? -->
          {{ description2 }}
        </p>
      </div>
    </div>
  </div>
  <div class="modal-actions">
    <button mat-button class="continue-button" (click)="closeModal('continue')">
      {{ buttons[0] }}
    </button>
    <button *ngIf="buttons.length > 1" mat-button class="exit-button" (click)="closeModal('exit')">
      {{ buttons[1] }}
    </button>
  </div>
</div>
