import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core'
import { FormBuilder, FormGroup } from '@angular/forms'
import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal'
import { CRUDService } from 'src/app/crud.service'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { ValuesMapService } from 'src/app/shared/services/values-map.service'
@Component({
  selector: 'app-reason-modal',
  templateUrl: './reason-modal.component.html',
  styleUrls: ['./reason-modal.component.scss']
})
export class ReasonModalComponent implements OnInit {
  reasonForm: FormGroup
  reasonList: any = []
  type: any
  bsModalRef: BsModalRef
  showInput: boolean = false
  @Output() messageEvent = new EventEmitter<string>()
  constructor(
    public dialogRef: MatDialogRef<ReasonModalComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private _bsModalRef: BsModalRef,
    public options: ModalOptions,
    private CRUDService: CRUDService,
    private valuesMapService: ValuesMapService,
    private fb: FormBuilder
  ) {}
  ngOnInit(): void {
    this.type = this.options?.initialState?.type
    var type = this.data?.initialState
    this.reasonForm = this.fb.group({
      reason: [''],
      otherReason: [''],
      businessReason: ['']
    })
    if (type === 'reject' || this.type === 'reject') {
      this.valuesMapService.getFilteredValuesMap(100, 0, '', 'REJECT_REASON').subscribe((res) => {
        const data = res.data.items.sort((a, b) => a.display_order - b.display_order)
        let names = data.map(function (item: any) {
          return item['description']
        })
        this.reasonList = names
      })
    } else if (type === 'accept' || this.type === 'accept') {
      this.valuesMapService
        .getFilteredValuesMap(100, 0, '', 'SHORTLIST_REASON')
        .subscribe((res) => {
          const data = res.data.items.sort((a, b) => a.display_order - b.display_order)
          let names = data.map(function (item: any) {
            return item['description']
          })
          this.reasonList = names
        })
    } else if (type === 'rejectByCandidate' || this.type === 'rejectByCandidate') {
      this.reasonList = ["Role isn't for me", "I'm not currently looking for new role"]
    } else {
      this.showInput = true
    }
  }
  closeModal() {
    this._bsModalRef.hide()
    this.dialogRef.close()
    // this.bsModalService.hide();
  }
  reasonSubmit() {
    let msg: any
    if (
      this.reasonForm.controls.reason.value !== undefined &&
      this.reasonForm.controls.reason.value !== null &&
      this.reasonForm.controls.reason.value !== ''
    ) {
      if (this.reasonForm.controls.reason.value.toLowerCase() !== 'other') {
        msg = {
          reason: this.reasonForm.controls.reason.value
        }
      } else {
        if (
          this.reasonForm.controls.otherReason.value !== undefined &&
          this.reasonForm.controls.otherReason.value !== null &&
          this.reasonForm.controls.otherReason.value !== ''
        ) {
          msg = {
            reason:
              this.reasonForm.controls.reason.value +
              '-' +
              this.reasonForm.controls.otherReason.value
          }
        } else {
          msg = {
            reason: this.reasonForm.controls.reason.value
          }
        }
      }
    } else {
      msg = {
        reason: this.reasonForm.controls.businessReason.value
      }
    }
    this.messageEvent.emit(msg)
    this.dialogRef.close(msg)
    this._bsModalRef.hide()
  }
}
