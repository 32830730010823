import { NgModule } from '@angular/core'

import { MenuItems } from './menu-items/menu-items'
import { HorizontalMenuItems } from './menu-items/horizontal-menu-items'

import { AccordionAnchorDirective, AccordionLinkDirective, AccordionDirective } from './accordion'
import { MatCardModule } from '@angular/material/card'
import { MatButtonModule } from '@angular/material/button'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { MatSelectModule } from '@angular/material/select'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatIconModule } from '@angular/material/icon'
import { MatChipsModule } from '@angular/material/chips'
import { MatAutocompleteModule } from '@angular/material/autocomplete'
import { MatSlideToggleModule } from '@angular/material/slide-toggle'
import { ModalModule } from 'ngx-bootstrap/modal'
import { CarouselModule } from 'ngx-bootstrap/carousel'
import { MatProgressBarModule } from '@angular/material/progress-bar'
import { MatDatepickerModule } from '@angular/material/datepicker'
import { NgxSpinnerModule } from 'ngx-spinner'
import { CandidateProfileOverviewVerifiedComponent } from '../dashboards/dashboard-components/candidate-profile-overview-verified/candidate-profile-overview-verified.component'
import { ProfileOverviewVerifiedComponent } from '../dashboards/dashboard-components'
import { CommonModule } from '@angular/common'
import { NgxDropzoneModule } from 'ngx-dropzone'
import { ImageCropperModule } from 'ngx-image-cropper'
import { DragDropModule } from '@angular/cdk/drag-drop'
import { SortableModule } from 'ngx-bootstrap/sortable'
import { DateFormatDirective } from './date-format/date-format.directive'
@NgModule({
  declarations: [
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
    CandidateProfileOverviewVerifiedComponent,
    ProfileOverviewVerifiedComponent,
    DateFormatDirective
  ],
  imports: [
    MatCardModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    CarouselModule.forRoot(),
    MatSlideToggleModule,
    MatSelectModule,
    FormsModule,
    MatIconModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    NgxSpinnerModule,
    CommonModule,
    ImageCropperModule,
    ModalModule.forRoot(),
    MatProgressBarModule,
    ReactiveFormsModule,
    NgxDropzoneModule,
    DragDropModule,
    SortableModule.forRoot()
  ],
  exports: [
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
    DateFormatDirective,
    CarouselModule,
    MatProgressBarModule,
    MatCardModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSlideToggleModule,
    CommonModule,
    MatDatepickerModule,
    NgxSpinnerModule,
    CandidateProfileOverviewVerifiedComponent,
    ProfileOverviewVerifiedComponent,
    MatSelectModule,
    FormsModule,
    MatIconModule,
    MatChipsModule,
    MatAutocompleteModule,
    ModalModule,
    ReactiveFormsModule,
    NgxDropzoneModule,
    ImageCropperModule,
    DragDropModule,
    SortableModule
  ],
  providers: [MenuItems, HorizontalMenuItems]
})
export class SharedModule {}
