import { Component, OnInit } from '@angular/core'
import { MatDialog, MatDialogRef } from '@angular/material/dialog'
import { getArrayFromString, getSessionValue } from 'src/app/common-utils'
import { Router, ActivatedRoute } from '@angular/router'
import { CRUDService } from 'src/app/crud.service'
import { ScheduleEventNewComponent } from 'src/app/scheduler/schedule-calendar/schedule-event-new/schedule-event-new.component'
import { NullTemplateVisitor } from '@angular/compiler'
import { Subject } from 'rxjs'
import { NgxSpinnerService } from 'ngx-spinner'
import { CommonService } from 'src/app/common.service'
import { environment } from 'src/environments/environment'
import { FormBuilder, Validators } from '@angular/forms'
import { BsModalService } from 'ngx-bootstrap/modal'
import { BusinessProfileViewComponent } from '../business-profile-view/business-profile-view.component'
import { RoleAppliedComponent } from '../../modals/role-applied/role-applied.component'
import { ReasonModalComponent } from '../../modals/reason-modal/reason-modal.component'
import { ToastrService } from 'ngx-toastr'
import { AcceptConnectionComponent } from '../../modals/accept-connection/accept-connection.component'
import { PersonalityNotificationComponent } from '../../modals/personality-notification/personality-notification.component'
import { CandidateProfileViewComponent } from '../candidate-profile-view/candidate-profile-view.component'
import { RoleService } from 'src/app/shared/services/role.service'
import { ValuesMapService } from 'src/app/shared/services/values-map.service'
import { ValuesMap } from 'src/app/core/models/values-map'
import { BusinessService } from 'src/app/shared/services/business.service'
import { ROLE_CANDIDATE_STATUS } from 'src/app/core/models/enums'

@Component({
  selector: 'app-view-job',
  templateUrl: './view-job.component.html',
  styleUrls: ['./view-job.component.scss']
})
export class ViewJobComponent implements OnInit {
  dialogRef: MatDialogRef<ScheduleEventNewComponent> = Object.create(NullTemplateVisitor)
  dialogRef3: MatDialogRef<ReasonModalComponent> = Object.create(NullTemplateVisitor)
  refresh: Subject<any> = new Subject()
  userInfo: any
  businessProfile: any
  businessLists: any[] = []
  jobDetails: any
  submitted: boolean = false
  required_skills: any
  benefitsArray: any[] = []
  skillsArray: any[] = []
  locationValue: any
  locationArray: any = []
  selectedSalary: any
  businessPostCandidateInfo: any
  businessPostSlots: any = []
  salaryArray: any
  benefits: any
  skills: any
  apiUrl: any
  stateData: any
  jobDetailsForm: any
  softSkillsArray: any
  domainExperienceArray: any
  roleToken: any
  viewJobByTokenFlag: boolean = false
  BusinessPost: any = []
  candidateProfile: any
  applyThroughLink: boolean = false
  isTalentPartnerLink: boolean = false
  isApplythroughLink: boolean = false
  partnerId
  personalityTypeDetails
  personalityFactors
  cultureInfo
  cultureFactors

  constructor(
    public bsModalService: BsModalService,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private CRUDService: CRUDService,
    private commonService: CommonService,
    private roleService: RoleService,
    private valuesMapService: ValuesMapService,
    private businessService: BusinessService,
    private router: Router,
    private SpinnerService: NgxSpinnerService,
    private toastr: ToastrService
  ) {
    this.stateData = this.router.getCurrentNavigation().extras.state
  }

  async ngOnInit() {
    this.jobDetailsForm = this.fb.group({
      roleType: [''],
      employmentType: [''],
      startDate: [''],
      remoteWork: [''],
      location: ['', Validators.maxLength(120)],
      description: ['', Validators.maxLength(600)],
      dayRate: [''],
      benefits: [''],
      softSkills: [''],
      experience: [''],
      skills: [''],
      salary: ['']
    })
    this.candidateProfile = await getSessionValue('CANDIDATE_PROFILE')
    if (this.route.snapshot.paramMap.get('roleToken')) {
      // this.roleToken = this.route.snapshot.paramMap.get("roleToken");
      this.viewJobByTokenFlag = true
      const data = JSON.parse(sessionStorage.getItem('REDIRECT'))
      if (data) {
        this.roleToken = data?.token
        this.isTalentPartnerLink = data?.isTalentPartnerLink
        this.isApplythroughLink = true
        this.partnerId = data?.talentPartnerId
        sessionStorage.removeItem('REDIRECT')
        this.applyThroughLink = true
      } else {
        this.router.navigate(['authentication/login-option'])
      }
    } else if (sessionStorage.getItem('REDIRECT')) {
      const data = JSON.parse(sessionStorage.getItem('REDIRECT'))
      this.roleToken = data?.token
      this.isTalentPartnerLink = data?.isTalentPartnerLink
      this.isApplythroughLink = true
      this.partnerId = data?.talentPartnerId
      sessionStorage.removeItem('REDIRECT')
      this.viewJobByTokenFlag = false
      this.applyThroughLink = true
    }
    this.apiUrl = environment.apiUrl
    this.userInfo = getSessionValue('USER_INFO')

    await this.loadJobDetails()

    if (this.jobDetails) {
      this.valuesMapService
        .getFilteredValuesMap(100, 0, '', 'PERSONALITY_FACTORS')
        .subscribe((data) => {
          this.personalityFactors = data.data.items
          this.personalityFactors.forEach((ele) => {
            ele['description'] = JSON.parse(
              ele['description']?.replace('"Adjectives"', ', "Adjectives"')
            )
          })
          this.loadRoleTypeData()
        })

      this.valuesMapService
        .getFilteredValuesMap(100, 0, '', 'CULTURE_FACTORS')
        .subscribe((data) => {
          this.cultureFactors = data.data.items
          this.cultureFactors.forEach((ele) => {
            ele['description'] = JSON.parse(ele['description'])
          })
          this.loadCultureData()
        })
    }
  }

  async loadJobDetails() {
    if (this.roleToken) {
      this.roleService.getRoleById(this.roleToken).subscribe({
        next: async (response) => {
          if (response.success) {
            this.BusinessPost = { ...response.data }
            this.jobDetails = response.data

            if (
              this.jobDetails?.round_no == '1' &&
              this.jobDetails?.meeting_id != null &&
              this?.jobDetails.meeting_id != ''
            ) {
              this.jobDetails.scheduled = true
            } else {
              this.jobDetails.scheduled = false
            }
            await getBusinessData()

            this.jobDetailsForm.controls.roleType.setValue(this.jobDetails.role_type)
            this.jobDetailsForm.controls.employmentType.setValue(this.jobDetails.employment_type)
            this.jobDetailsForm.controls.startDate.setValue(this.jobDetails.start_by)
            this.jobDetailsForm.controls.remoteWork.setValue(this.jobDetails.remote_work)
            this.jobDetailsForm.controls.location.setValue(this.jobDetails.location)
            this.jobDetailsForm.controls.description.setValue(this.jobDetails.description)
            this.jobDetailsForm.controls.dayRate.setValue(this.jobDetails.day_rate)
            this.jobDetailsForm.controls.salary.setValue(this.jobDetails.salary)
            this.benefitsArray = getArrayFromString(this.jobDetails.benefits)
            this.softSkillsArray = getArrayFromString(this.jobDetails.soft_skills)
            this.domainExperienceArray = getArrayFromString(this.jobDetails.experience)
            this.skillsArray = getArrayFromString(this.jobDetails.skills)
            this.jobDetailsForm.controls.benefits.setValue(this.benefitsArray)
            this.jobDetailsForm.controls.softSkills.setValue(this.softSkillsArray)
            this.jobDetailsForm.controls.experience.setValue(this.domainExperienceArray)
            this.jobDetailsForm.controls.skills.setValue(this.skillsArray)
            this.valuesMapService.getFilteredValuesMap(100, 0, '', 'SALARY').subscribe((data) => {
              const salaryArray: ValuesMap[] = data.data.items
              this.salaryArray = data.data.items
              this.selectedSalary = salaryArray.find((val) => val.code === this.jobDetails.salary)
              this.jobDetailsForm.controls.salary.setValue(this.selectedSalary?.code)
              this.SpinnerService.hide()
            })
          } else {
            this.toastr.error(response.error)
          }
        },
        error: (error) => {
          console.error(error)
        }
      })

      let getBusinessData = async () => {
        this.roleService
          .getRoleCandidate({
            role_id: this.jobDetails.id,
            user_id: this.candidateProfile.user_id
          })
          .subscribe({
            next: (response) => {
              if (response.data) {
                this.jobDetails.businessPostsCandidate = response?.data?.application_meta
              } else {
                this.jobDetails.businessPostsCandidate = null
              }
            },
            error: (error) => {
              console.error(error)
            }
          })
        this.businessService.getBusiness(this.jobDetails.business_id).subscribe({
          next: (response) => {
            this.jobDetails.businessProfile = response.data
          },
          error: (error) => {
            console.error(error)
          }
        })
      }
    } else if (typeof this.stateData?.roleInfo !== 'undefined') {
      this.SpinnerService.show()
      this.jobDetails = this.stateData.roleInfo
      this.jobDetails['businessPostsCandidate'] = this.stateData.businessPostCandidateInfo
      this.jobDetailsForm.controls.roleType.setValue(this.jobDetails.role_type)
      this.jobDetailsForm.controls.employmentType.setValue(this.jobDetails.employment_type)
      this.jobDetailsForm.controls.startDate.setValue(this.jobDetails.start_by)
      this.jobDetailsForm.controls.remoteWork.setValue(this.jobDetails.remote_work)
      this.jobDetailsForm.controls.location.setValue(this.jobDetails.location)
      this.jobDetailsForm.controls.description.setValue(this.jobDetails.description)
      this.jobDetailsForm.controls.dayRate.setValue(this.jobDetails.day_rate)
      this.jobDetailsForm.controls.salary.setValue(this.jobDetails.salary)
      this.benefitsArray = getArrayFromString(this.jobDetails.benefits)
      this.softSkillsArray = getArrayFromString(this.jobDetails.soft_skills)
      this.domainExperienceArray = getArrayFromString(this.jobDetails.experience)
      this.skillsArray = getArrayFromString(this.jobDetails.skills)
      this.jobDetailsForm.controls.benefits.setValue(this.benefitsArray)
      this.jobDetailsForm.controls.softSkills.setValue(this.softSkillsArray)
      this.jobDetailsForm.controls.experience.setValue(this.domainExperienceArray)
      this.jobDetailsForm.controls.skills.setValue(this.skillsArray)
      this.valuesMapService.getFilteredValuesMap(100, 0, '', 'SALARY').subscribe((data) => {
        const salaryArray: any[] = data.data.items
        this.salaryArray = data.data.items
        this.selectedSalary = salaryArray.find((val) => val.code === this.jobDetails.salary)
        this.jobDetailsForm.controls.salary.setValue(this.selectedSalary?.code)
        this.SpinnerService.hide()
      })
    } else {
      this.router.navigate(['dashboards/work'])
    }
  }

  async getJobInfo(post: any) {
    this.SpinnerService.show()
    try {
      let res = await this.roleService
        .getRoleCandidate({ role_id: post.id, user_id: this.userInfo.id })
        .toPromise()
      this.businessPostCandidateInfo = res?.data.application_meta

      let data = await this.roleService.getEventsForRole(post.id).toPromise()
      this.businessPostSlots = data?.data?.items?.filter(
        (e) => e.round_no == this.businessPostCandidateInfo?.round_no && e.status == '1'
      )
    } catch (error) {
      console.error(error)
    }
    this.SpinnerService.hide()
  }

  async loadRoleTypeData() {
    if (this.jobDetails?.id) {
      const data = await this.roleService
        .getPersonalityRankingByRoleId(this.jobDetails?.id)
        .toPromise()
      const personalityTypeDetails = data.data
      for (let x in personalityTypeDetails) {
        if (x !== 'id' && x !== 'post_id') {
          if (personalityTypeDetails[x]) {
            this.personalityTypeDetails = this.personalityFactors.find((value) => {
              return value.code === personalityTypeDetails[x]
            })
            break
          }
        }
      }
    }
  }

  async apply(isReschedule: any) {
    let isValid: boolean = false
    if (!environment.isApplyForRoleWithoutProfileCompletion) {
      let isExpValid = false
      if (this.candidateProfile.currently_employed_yn == 'Y') {
        if (
          this.candidateProfile[`experience_1_compname`] &&
          this.candidateProfile[`experience_1_role`] &&
          this.candidateProfile[`experience_1_from`]
        ) {
          isExpValid = true
        }
      } else {
        for (let i = 1; i < 4; i++) {
          if (
            this.candidateProfile[`experience_${i}_compname`] &&
            this.candidateProfile[`experience_${i}_role`] &&
            this.candidateProfile[`experience_${i}_from`] &&
            this.candidateProfile[`experience_${i}_to`]
          ) {
            isExpValid = true
            break
          }
        }
      }
      isValid =
        this.candidateProfile.looking_for &&
        this.candidateProfile.roles_interested &&
        (this.candidateProfile.slary || this.candidateProfile.day_rate) &&
        this.candidateProfile.domain_exp &&
        this.candidateProfile.culture.split(';').length >= 5 &&
        this.candidateProfile.residency_status &&
        this.candidateProfile.availability &&
        isExpValid
    }
    if (!environment.isApplyForRoleWithoutProfileCompletion && !isValid) {
      const dialogRef = this.dialog.open(PersonalityNotificationComponent, {
        panelClass: 'personality-notification',
        width: '55vw',
        autoFocus: 'false',
        data: {
          header: 'Low profile completion status',
          description1:
            'To help us better match you to roles, please update the mandatory information on your profile which is marked with an asterisk * e.g. Salary, Domain experience.',
          buttons: ['Yes, update and apply', 'No, apply anyway']
        }
      })

      dialogRef.afterClosed().subscribe((data) => {
        if (data === 'continue') {
          const dialogRef1 = this.dialog.open(CandidateProfileViewComponent, {
            panelClass: 'mat-candidate-profile'
          })

          dialogRef1.afterClosed().subscribe(() => {
            this.applyForRole(isReschedule)
          })
        } else if (data === 'exit') {
          this.applyForRole(isReschedule)
        }
      })
    } else {
      this.applyForRole(isReschedule)
    }
  }

  async applyForRole(isReschedule) {
    if (this.viewJobByTokenFlag) {
      localStorage.setItem(
        'REDIRECT',
        JSON.stringify({ url: '/dashboards/view-job', token: this.roleToken })
      )
      sessionStorage.setItem('SOURCE', JSON.stringify({ source: 'role' }))
      if (
        this.userInfo !== undefined &&
        this.userInfo !== null &&
        JSON.stringify(this.userInfo) !== '{}'
      ) {
        this.router.navigate(['/dashboards/view-job'])
      } else {
        this.router.navigate(['/authentication/login-option'])
      }
    } else {
      const createBusinessPostCandidate = async () => {
        let BusinessPostCandidates: any = {}
        let response
        if (!this.jobDetails.businessPostsCandidate) {
          response = await this.roleService.applyToRole(this.jobDetails.id).toPromise()
          // BusinessPostCandidates.post_id = this.BusinessPost.id;
          // BusinessPostCandidates.user_id = this.candidateProfile.user_id;
          // BusinessPostCandidates.round_no = 1;
          // BusinessPostCandidates.status = 0;
          // BusinessPostCandidates.action_status = 0;
          // BusinessPostCandidates.match_perc = this.candidateProfile?.match_perc;
          // BusinessPostCandidates.shortlist_type = this.isApplythroughLink
          //   ? this.isTalentPartnerLink
          //     ? "Premium"
          //     : "Applied"
          //   : "Matched";
          // BusinessPostCandidates.talent_partner_id = this.partnerId
          //   ? this.partnerId
          //   : null;
        } else {
          response = await this.roleService
            .updateRoleCandidateStatus({
              role_id: this.jobDetails.id,
              user_id: this.candidateProfile.user_id,
              status: ROLE_CANDIDATE_STATUS.REINSTATE,
              round_no: 1
            })
            .toPromise()
          // BusinessPostCandidates = this.jobDetails.businessPostsCandidate;
          // BusinessPostCandidates["post_id"] = this.BusinessPost.id;
          // BusinessPostCandidates["round_no"] = 1;
          // BusinessPostCandidates["status"] = 0;
          // BusinessPostCandidates["action_status"] = 0;
          // BusinessPostCandidates["match_perc"] =
          //   this.candidateProfile?.match_perc;
          // BusinessPostCandidates["shortlist_type"] = this.isApplythroughLink
          //   ? this.isTalentPartnerLink
          //     ? "Premium"
          //     : "Applied"
          //   : "Matched";
          // BusinessPostCandidates["talent_partner_id"] = this.partnerId
          //   ? this.partnerId
          //   : null;
        }
        // let response = await this.commonService.saveRecord(
        //   "BusinessPostCandidates",
        //   BusinessPostCandidates
        // );
        if (response?.success) {
          await this.loadJobDetails()
        }
        return response
      }
      await this.getJobInfo(this.jobDetails)
      let response = await createBusinessPostCandidate()
      if (response?.success == true) {
        this.openModal()
        // const mailBody = {
        //   name: this.userInfo?.first_name,
        //   business_name: this.jobDetails?.businessProfile?.name,
        //   role_title: this.jobDetails?.title,
        //   to: this.userInfo?.email_id,
        //   host: environment.appRoot,
        // };
        // await this.CRUDService.appliedForRoleMail(mailBody).toPromise();
      } else if (!response?.success) {
        this.toastr.error(response?.message)
      }
    }
  }

  async viewTimeSlots() {
    await this.getJobInfo(this.jobDetails)
    this.dialogRef = this.dialog.open(ScheduleEventNewComponent, {
      panelClass: 'calendar-event-dialog',
      data: {
        business_info: this.jobDetails.businessProfile,
        job_info: this.jobDetails,
        availability: this.businessPostSlots,
        businessPostCandidateInfo: this.jobDetails.businessPostsCandidate,
        isReschedule: false
      },
      autoFocus: false
    })

    this.dialogRef.afterClosed().subscribe(async (res) => {
      if (!res) {
        return
      } else {
        if (res.action == 'book') {
          this.jobDetails.scheduled = true
          this.loadJobDetails()
          // this.openModal();
        }
      }
      this.dialogRef = Object.create(null)
      await this.loadJobDetails()
    })
  }

  // async accept(isReschedule) {
  //   await this.getJobInfo(this.jobDetails);
  //   this.dialogRef = this.dialog.open(ScheduleEventNewComponent, {
  //     panelClass: "calendar-event-dialog",
  //     data: {
  //       business_info: this.jobDetails.businessProfile,
  //       job_info: this.jobDetails,
  //       availability: this.businessPostSlots,
  //       businessPostCandidateInfo: this.jobDetails.businessPostsCandidate,
  //       isReschedule: isReschedule,
  //     },
  //   });

  //   this.dialogRef.afterClosed().subscribe(async (res) => {
  //     if (!res) {
  //       return;
  //     } else {
  //       if (res.action == "book") {
  //         this.jobDetails.scheduled = true;
  //         this.loadJobDetails();
  //         this.openModal();
  //       }
  //     }
  //     this.dialogRef = Object.create(null);
  //     await this.loadJobDetails();
  //   });
  // }

  // async reject() {
  //   await this.getJobInfo(this.jobDetails);
  //   this.businessPostCandidateInfo.status = 2;
  //   this.businessPostCandidateInfo.action_status = 3;
  //   var initialState = "rejectByCandidate";
  //   this.dialogRef3 = this.dialog.open(ReasonModalComponent, {
  //     data: { initialState },
  //     panelClass: "event-details-dialog",
  //     width: "30vw",
  //     autoFocus: false,
  //   });
  //   this.dialogRef3.afterClosed().subscribe(async (res) => {
  //     this.businessPostCandidateInfo.remarks = res.reason.reason;
  //     let response = await this.commonService.updateRecord(
  //       "BusinessPostCandidates",
  //       this.businessPostCandidateInfo
  //     );
  //     if (response.success == true || response.success == "true") {
  //       this.refresh.next();
  //       this.jobDetails.businessPostsCandidate.action_status = 3;
  //       await this.loadJobDetails();
  //       this.toastr.success("Role Rejected");
  //     } else {
  //       this.toastr.error("Failed to Reject The Role");
  //       this.refresh.next();
  //     }
  //   });
  // }

  openModal() {
    this.dialog.open(RoleAppliedComponent, {
      panelClass: 'role-applied-modal',
      width: '55vw',
      maxHeight: '80vh',
      autoFocus: 'false'
    })
  }
  employmentTypeFn() {
    let str = this.jobDetails?.employment_type
    str = str?.replaceAll?.(';', ', ')
    return str
  }

  remoteWorkValue() {
    let remoteWork = ''
    if (this.jobDetails?.remote_work === 'Y') {
      remoteWork = 'Yes'
    } else if (this.jobDetails?.remote_work === 'N') {
      remoteWork = 'No'
    } else {
      remoteWork = ''
    }
    return remoteWork
  }

  getStatusDesc(status: any) {
    if (status === '1') {
      return 'Active'
    } else if (status === '4') {
      return 'Closed'
    } else if (status === '3') {
      return 'Hold'
    } else if (status === '2') {
      return 'Deleted'
    }
  }

  changeString(str) {
    return str?.replaceAll(';', ', ')
  }

  viewBusinessProfile(id) {
    const isEdit: boolean = false
    const dialogRef = this.dialog.open(BusinessProfileViewComponent, {
      panelClass: 'mat-candidate-profile',
      data: { id, isEdit }
    })

    dialogRef.afterClosed().subscribe(async (result) => {})
  }

  openAcceptConnectionModal() {
    const dialog = this.dialog.open(AcceptConnectionComponent, {
      panelClass: 'role-model-post',
      width: '45vw',
      autoFocus: false,
      data: this.jobDetails?.business_name
    })

    dialog.afterClosed().subscribe(async (data) => {
      if (data) {
        await this.getJobInfo(this.jobDetails)
        if (data.status === 'rejected') {
          const response = await this.roleService
            .updateRoleCandidateStatus({
              role_id: this.jobDetails.id,
              user_id: this.candidateProfile.user_id,
              status: ROLE_CANDIDATE_STATUS.REJECT_CONNECTION,
              remarks: data?.reason
            })
            .toPromise()
          // this.businessPostCandidateInfo.status = 2;
          // this.businessPostCandidateInfo.action_status = 3;
          // this.businessPostCandidateInfo.remarks = data?.reason;
          // let response = await this.commonService.updateRecord(
          //   "BusinessPostCandidates",
          //   this.businessPostCandidateInfo
          // );
          if (response.success) {
            this.refresh.next()
            this.jobDetails.businessPostsCandidate.action_status = 3
            await this.loadJobDetails()
            this.toastr.success('Connection Rejected')
            const user = []
            user.push(this.jobDetails?.created_by)
            // TODO: automatically create app notification
            // this.commonService.addAppNotification(
            //   "The candidate " +
            //     this.candidateProfile?.name +
            //     " doesn`t want to meet.",
            //   "A candidate in your shortlist has left feedback, their status will show as 'Not Interested'",
            //   user,
            //   "BUSINESS",
            //   this.jobDetails.id
            // );
          } else {
            this.toastr.error('Something went wrong, please try again later')
            this.refresh.next()
          }
        } else if (data.status === 'accepted') {
          const response = await this.roleService
            .updateRoleCandidateStatus({
              role_id: this.jobDetails.id,
              user_id: this.candidateProfile.user_id,
              status: ROLE_CANDIDATE_STATUS.ACCEPT_CONNECTION
            })
            .toPromise()
          // this.businessPostCandidateInfo.status = 1;
          // this.businessPostCandidateInfo.action_status = 5;
          // let response = await this.commonService.updateRecord(
          //   "BusinessPostCandidates",
          //   this.businessPostCandidateInfo
          // );
          if (response.success) {
            await this.loadJobDetails()
            this.jobDetails.businessPostsCandidate.action_status = 5
            this.refresh.next()
          } else {
            this.toastr.error('Something went wrong, please try again later')
            this.refresh.next()
          }
        }
      }
    })
  }

  loadCultureData() {
    try {
      let cultureData = JSON.parse(this.jobDetails?.culture_profile)
      cultureData = cultureData.sort((a, b) => a.rank - b.rank)

      this.cultureInfo = this.cultureFactors.find(
        (e) => e.description?.name === cultureData[0].name
      )?.description
    } catch (error) {
      return
    }
  }
}
