import { NullTemplateVisitor } from '@angular/compiler'
import { Component, OnInit } from '@angular/core'
import { MatDialog, MatDialogRef } from '@angular/material/dialog'
import { getLoggedInUser, getSessionValue } from 'src/app/common-utils'
import { CRUDService } from 'src/app/crud.service'
import { environment } from '../../../../environments/environment'
import { BusinessProfileViewComponent } from '../business-profile-view/business-profile-view.component'
import { CommonService } from 'src/app/common.service'
import { ToastrService } from 'ngx-toastr'
import { RoleService } from 'src/app/shared/services/role.service'
import { BusinessService } from 'src/app/shared/services/business.service'
import { CandidateService } from 'src/app/shared/services/candidate.service'

@Component({
  selector: 'app-profile-overview-verified',
  templateUrl: './profile-overview-verified.component.html',
  styleUrls: ['./profile-overview-verified.component.scss']
})
export class ProfileOverviewVerifiedComponent implements OnInit {
  selectedRole: any
  roleArray: any = []
  businessProfile: any
  userInfo: any
  apiUrl: any
  roles_available = false
  roles_available_check = false
  profileImage: string

  dialogRef: MatDialogRef<BusinessProfileViewComponent> = Object.create(NullTemplateVisitor)

  constructor(
    private CRUDService: CRUDService,
    private roleService: RoleService,
    private businessService: BusinessService,
    private candidateService: CandidateService,
    private dialog: MatDialog,
    private commonService: CommonService,
    private toastr: ToastrService
  ) {}

  async ngOnInit(): Promise<void> {
    this.apiUrl = environment.apiUrl
    this.userInfo = getLoggedInUser()
    const groupData = await this.businessService.getBusiness(this.userInfo.group_id).toPromise()
    this.businessProfile = groupData.data
    const rolesRes = await this.roleService
      .getRolesByBusinessId(this.userInfo?.group_id)
      .toPromise()
    this.roleArray = rolesRes.data
    this.roles_available_check = this.businessProfile.roles_available === '1' ? true : false
    this.roles_available = this.businessProfile.roles_available
    if (this.businessProfile?.logo_url) {
      this.profileImage = this.businessProfile.logo_url
    }
  }
  openViewProfile() {
    this.dialogRef = this.dialog.open(BusinessProfileViewComponent, {
      panelClass: 'mat-candidate-profile'
    })
    this.dialogRef.afterClosed().subscribe(async (result) => {
      let data = await this.businessService.getBusiness(this.businessProfile.id).toPromise()
      if (data.data) {
        this.businessProfile = data.data
        if (this.businessProfile?.logo_url) {
          this.profileImage = this.businessProfile.logo_url
        }
        this.roles_available_check = this.businessProfile.roles_available === '1' ? true : false
        this.roles_available = this.businessProfile.roles_available === '1' ? true : false
      }
    })
  }
  async changeRolesStatus(event: any) {
    this.roles_available = event.checked
    if (event.checked) {
      this.businessProfile.roles_available = '1'
    } else {
      this.businessProfile.roles_available = '0'
    }
    let response = await this.businessService.update(this.businessProfile).toPromise()
    if (response) {
      sessionStorage.setItem('BUSINESS_PROFILE', JSON.stringify(this.businessProfile))
      this.toastr.success('Business Profile Updated')
      // this._router.navigate(['/dashboards/candidate'])
    }
  }
}
