<div class="modal-content" #test>
  <div class="modal-header">
    <h6 class="circularBold signUpHeading">Schedule Next Round</h6>
    <button
      type="button"
      class="close ml-2"
      aria-label="Close"
      data-dismiss="modal"
      (click)="closeModal()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="nextRoundForm" autocomplete="off">
      <div>
        <mat-form-field appearance="fill">
          <mat-select
            placeholder="Select Round"
            [(ngModel)]="roundDetails"
            (selectionChange)="changeRound()"
            formControlName="roundNo"
          >
            <mat-option *ngFor="let round of roundList" [value]="round?.round_no">
              {{ round?.round_name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <div
          class="form-error"
          *ngIf="nextRoundForm.get('roundNo').hasError('required') && submitted"
        >
          This field is required
        </div>
      </div>

      <!-- <div>
                <mat-form-field appearance="fill">
                    <input placeholder="Select the slot date" matInput [matDatepicker]="picker"
                        formControlName="slotDate" [(ngModel)]="slotDate" (click)="picker.open()" [min]="minDate">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
                <div class="form-error" *ngIf="nextRoundForm.get('slotDate').hasError('required') && submitted">
                    This field is required
                </div>
            </div> -->

      <!-- <div>
                <mat-form-field appearance="fill">
                    <mat-select placeholder="Start Time" formControlName="startTime" [(ngModel)]="selectedStartTime"
                        name="startTime" appearance="fill">
                        <mat-option *ngFor="let time of timeSlotsList" [value]="time.value">
                            {{time.viewValue}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <div class="form-error" *ngIf="nextRoundForm.get('startTime').hasError('required') && submitted">
                    This field is required
                </div>
            </div> -->

      <div>
        <mat-form-field appearance="fill">
          <textarea
            matInput
            rows="5"
            placeholder="Remarks"
            formControlName="remarks"
            [(ngModel)]="remarks"
          ></textarea>
        </mat-form-field>
        <div></div>
      </div>

      <div>
        <button mat-raised-button class="like-btn" (click)="submit()">Submit</button>
      </div>
    </form>
  </div>
</div>
