<div class="ease-in">
  <div class="close-dialog-div">
    <button
      type="button"
      class="close"
      aria-label="Close"
      data-dismiss="modal"
      (click)="closeModal()"
    >
      <span class="close-icon" aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-header">
    <h1 class="circularBold signUpHeading">Thanks for your Application!</h1>
  </div>
  <div class="modal-body">
    <div fxLayout="row wrap" style="margin-top: 10px; margin-left: 5%; margin-right: 5%">
      <div
        fxFlex.gt-sm="100"
        fxFlex.gt-xs="100"
        fxFlex="100"
        class="text-center"
        style="margin-top: 10px; display: flex"
      >
        <p class="description-text">
          As a next step, we will run our smarts and see if you’re a match for the role.
          <br />
          <br />
          If you are a match you will be added to the shortlist and if there’s interest to meet,
          you’ll be contacted to find a suitable time to connect.
          <br />
          <br />
          If you’re not a direct match, you’ll still be shown as an applicant and could be contacted
          to progress.
          <br />
          <br />
          Though don’t worry as in the background we’re going to match you against other roles on
          Upplft and see if you’re a match.
          <br />
          <br />
          Either way, you’re in the best place to help you take the next step in your career, at
          Upplft.
        </p>
      </div>

      <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100" style="margin-top: 15px"></div>

      <div
        fxFlex.gt-sm="100"
        fxFlex.gt-xs="100"
        fxFlex="100"
        style="display: flex; justify-content: center; align-items: center"
      >
        <button class="btn-lg m-t-20 m-b-20 loginButton" type="submit" (click)="closeModal()">
          Let`s go
        </button>
      </div>
    </div>
  </div>
</div>
