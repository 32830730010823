<div class="ease-in">
  <ng-container *ngIf="jobsPosts?.length > 0">
    <!-- {{jobsPosts | json}} -->
    <div *ngFor="let post of jobsPosts; trackBy: trackByFn" class="ease-in">
      <div fxLayout="row wrap" class="jobPosts res-card flex-column">
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex" style="width: calc(100% - 150px); padding-top: 10px">
            <div title="{{ post?.businessProfile?.name }}">
              <img
                src="{{ post?.businessProfile?.image }}"
                alt="Business Photo"
                class="businessImg"
                onerror="this.onerror=null;this.src='assets/images/business/business_logo.png'"
              />
            </div>
            <div class="res-business">
              <span class="header"
                ><B>{{ post.title }}</B></span
              ><br />
              <span
                class="jobTitle"
                style="padding-top: 10px"
                [innerText]="
                  post.showMoreFlag ? post?.description : addEllipsis(post?.description, 300)
                "
              ></span>
              <div *ngIf="showMoreFlag && post?.description?.length > 300">
                <div
                  class="mt-2 ml-auto d-flex"
                  style="text-decoration: underline; width: 100%"
                  *ngIf="!post.showMoreFlag"
                >
                  <a
                    href="javascript:void(0)"
                    (click)="post.showMoreFlag = !post.showMoreFlag"
                    style="width: 100%; text-align: right; margin-right: 30px; color: #ec3b83"
                  >
                    <mat-icon>keyboard_arrow_down</mat-icon>
                  </a>
                </div>
                <div
                  class="mt-2 ml-auto d-flex"
                  style="text-decoration: underline; width: 100%"
                  *ngIf="post.showMoreFlag"
                >
                  <a
                    href="javascript:void(0)"
                    (click)="post.showMoreFlag = !post.showMoreFlag"
                    style="width: 100%; text-align: right; margin-right: 30px; color: #ec3b83"
                    ><mat-icon>remove</mat-icon></a
                  >
                </div>
              </div>
              <!-- <div *ngIf="post.showMoreFlag">
                <div class="details-div mt-3">
                  <label class="title-value">Role Type</label>
                  <mat-label class="example-full-width title">
                    {{post.role_type}}
                  </mat-label>
                </div>
      
                <div class="details-div mt-3">
                  <label class="title-value">Employment Type</label>
                  <mat-label class="example-full-width title">
                    {{employmentTypeFn(post.employment_type)}}
                  </mat-label>
                </div>
      
                <div class="col-12 col-sm-12 col-md-12 col-lg-12 d-flex pl-0 pr-0">
                  <div class="col-sm-6 col-md-6 col-lg-6 pl-0 pr-1">
                    <div class="details-div mt-3">
                      <label class="title-value">Start Date</label>
                      <mat-label class="example-full-width title">
                        {{post.start_by}}
                      </mat-label>
                    </div>
                  </div>
                  <div class="col-sm-6 col-md-6 col-lg-6 pl-1 pr-0">
                    <div class="details-div mt-3">
                      <label class="title-value">Remote Work</label>
                      <mat-label class="example-full-width title">
                        {{remoteWorkValue(post.remote_work)}}
                      </mat-label>
                    </div>
                  </div>
                </div>
      
                <div class="col-12 col-sm-12 col-md-12 col-lg-12 d-flex pl-0 pr-0">
                  <div class="col-sm-6 col-md-6 col-lg-6 pl-0 pr-1">
                    <div class="details-div mt-3">
                      <label class="title-value">Location</label>
                      <mat-label class="example-full-width title">
                        {{post.location}}
                      </mat-label>
                    </div>
                  </div>
                  <div class="col-sm-6 col-md-6 col-lg-6 pl-1 pr-0">
                    <div class="details-div mt-3">
                      <label class="title-value">Salary (base)</label>
                      <mat-label class="example-full-width title">
                        {{post.salary}}
                      </mat-label>
                    </div>
                  </div>
                </div>
      
                <div class="details-div mt-3">
                  <label class="title-value">Day-rate (exc super)</label>
                  <mat-label class="example-full-width title">
                    {{post.day_rate}}
                  </mat-label>
                </div>
      
                <div class="details-div mt-3">
                  <label class="title-value">Benefits</label>
                  <mat-chip-list aria-label="Benefits Selection" class="mt-2">
                    <mat-chip *ngFor="let benefit of roleBenefits(post.benefits)">{{benefit}}</mat-chip>
                  </mat-chip-list>
                </div>
      
                <div class="details-div mt-3">
                  <label class="title-value">Soft Skills</label>
                  <mat-chip-list aria-label="Soft Skills Selection" class="mt-2">
                    <mat-chip *ngFor="let softSkill of roleSoftSkills(post.soft_skills)">{{softSkill}}</mat-chip>
                  </mat-chip-list>
                </div>
      
                <div class="details-div mt-3">
                  <label class="title-value">Domain Experience</label>
                  <mat-chip-list aria-label="Soft Skills Selection" class="mt-2">
                    <mat-chip *ngFor="let exp of roleExp(post.experience)">{{exp}}</mat-chip>
                  </mat-chip-list>
                </div>
      
                <div class="details-div mt-3">
                  <label class="title-value">Required Skills</label>
                  <mat-chip-list aria-label="Skills Selection" class="mt-2">
                    <mat-chip *ngFor="let skill of roleSkills(post.skills)">{{skill}}</mat-chip>
                  </mat-chip-list>
                </div>
              </div> -->
            </div>
          </div>
          <div class="d-flex flex-column pb-2">
            <button class="secondary-button" style="cursor: pointer" (click)="viewJob(post)">
              View Role
            </button>
            <button
              mat-raised-button
              *ngIf="
                !post?.businessPostsCandidate || post?.businessPostsCandidate?.action_status == null
              "
              class="primary-button"
              style="cursor: pointer"
              (click)="apply(post, false)"
            >
              Apply
              <mat-icon style="font-size: 19px; padding: 4px">event</mat-icon>
            </button>
            <button
              class="appliedButton"
              mat-button
              *ngIf="
                post?.businessPostsCandidate?.action_status == '0' &&
                post?.businessPostsCandidate?.status == '0'
              "
            >
              Applied
            </button>
            <button
              class="shortlistedButton"
              mat-button
              *ngIf="
                post?.businessPostsCandidate?.action_status == '1' &&
                post?.businessPostsCandidate?.status == '1'
              "
            >
              Shortlisted
            </button>
            <button
              class="rejectedButton"
              mat-button
              *ngIf="
                (post?.businessPostsCandidate?.action_status == '2' ||
                  post?.businessPostsCandidate?.action_status == '3') &&
                post?.businessPostsCandidate?.status == '2'
              "
            >
              Rejected
            </button>
            <button
              class="primary-button"
              mat-raised-button
              (click)="openAcceptConnectionModal(post)"
              *ngIf="
                post?.businessPostsCandidate?.action_status == '1' &&
                post?.businessPostsCandidate?.status == '1'
              "
            >
              Accept Connection?
            </button>
            <button
              class="primary-button"
              mat-raised-button
              (click)="viewTimeSlots(post)"
              *ngIf="
                (post?.businessPostsCandidate?.action_status == '5' ||
                  (post?.businessPostsCandidate?.action_status == '6' &&
                    !post?.businessPostsCandidate?.meeting_id)) &&
                post?.businessPostsCandidate?.status == '1'
              "
            >
              View Timeslots
            </button>
            <button
              class="assessmentButton"
              mat-button
              *ngIf="
                post?.businessPostsCandidate?.action_status == '6' &&
                post?.businessPostsCandidate?.status == '1' &&
                post?.businessPostsCandidate?.meeting_id
              "
            >
              Assessment
            </button>
            <!-- <div
              *ngIf="post?.businessPostsCandidate?.action_status != '0' && post?.businessPostsCandidate?.action_status != '1'"
              class="secondaryButton" style="cursor: pointer;" (click)="openFeedbackModal(FeedbackModal, post)">
              Feedback
            </div> -->
          </div>
        </div>
        <div class="row" *ngIf="post?.postAttachmentArray?.length > 0">
          <div
            class="col-12 mt-2"
            *ngFor="let attachment of post.postAttachmentArray; trackBy: trackByFn"
          >
            <div *ngIf="post.postAttachmentArray.length > 0" class="text-start">
              <div class="col-12 text-center" *ngIf="attachment.file_type == 'image'">
                <img class="post-img" src="{{ attachment.file_path }}" />
              </div>
              <div class="col-12 text-start" *ngIf="attachment.file_type == 'video'">
                <video class="post-img" controls>
                  <source src="{{ attachment.file_path }}" />
                </video>
              </div>
              <div class="col-12 text-start" *ngIf="attachment.file_type == 'audio'">
                <audio controls src="{{ attachment?.file_path }}"></audio>
              </div>
              <div
                class="col-12 text-start file-css"
                *ngIf="attachment.file_type == 'doc'"
                (click)="downloadFile(attachment?.file_path)"
              >
                <label>{{ attachment?.file_name }}</label>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="ml-auto" *ngIf="showMoreFlag">
          <div class="mt-2 ml-auto d-flex" style="text-decoration: underline; width: 100%;" *ngIf="!post.showMoreFlag">
            <a href="javascript:void(0)" (click)="showMoreFn(post)"
              style="width: 100%; text-align: right; margin-right: 30px;">Show More</a>
          </div>
          <div class="mt-2 ml-auto d-flex" style="text-decoration: underline; width: 100%;" *ngIf="post.showMoreFlag">
            <a href="javascript:void(0)" (click)="showMoreFn(post)"
              style="width: 100%; text-align: right; margin-right: 30px;">Show Less</a>
          </div>
        </div> -->
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="jobsPosts?.length === 0 && profileData?.profile_completion < 7">
    Please complete your profile in the profile builder to be eligible for businesses to invite you
    for a role.
  </ng-container>
  <ng-container *ngIf="jobsPosts?.length === 0 && profileData?.profile_completion > 6">
    Great job, you’ve done the hard part by completing your profile! There’s no matches right now
    but we will notify you when we find a match.</ng-container
  >
  <ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
    <p style="color: white">Please Wait.</p>
  </ngx-spinner>

  <ng-template #FeedbackModal class="modal-content">
    <div class="modal-header">
      <h4 class="modal-title">Feedback</h4>
      <button type="button" class="close" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div>
        <div>
          <label>Rating</label>
        </div>
        <div class="rate">
          <input
            type="radio"
            id="star5"
            name="rate"
            value="5"
            [(ngModel)]="response.rating"
            (click)="response.rating = 5"
          />
          <label for="star5" title="text">5 stars</label>
          <input
            type="radio"
            id="star4"
            name="rate"
            value="4"
            [(ngModel)]="response.rating"
            (click)="response.rating = 4"
          />
          <label for="star4" title="text">4 stars</label>
          <input
            type="radio"
            id="star3"
            name="rate"
            value="3"
            [(ngModel)]="response.rating"
            (click)="response.rating = 3"
          />
          <label for="star3" title="text">3 stars</label>
          <input
            type="radio"
            id="star2"
            name="rate"
            value="2"
            [(ngModel)]="response.rating"
            (click)="response.rating = 2"
          />
          <label for="star2" title="text">2 stars</label>
          <input
            type="radio"
            id="star1"
            name="rate"
            value="1"
            [(ngModel)]="response.rating"
            (click)="response.rating = 1"
          />
          <label for="star1" title="text">1 star</label>
        </div>
      </div>
      <div>
        <textarea
          class="title-value"
          matInput
          placeholder="Feedback"
          [(ngModel)]="response.feedback"
        ></textarea>
      </div>
    </div>
    <div class="modal-footer">
      <button mat-raised-button class="like-btn" (click)="submit()">Submit</button>
    </div>
  </ng-template>
</div>
