import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core'
import { MatDialog, MatDialogRef } from '@angular/material/dialog'
import { NullTemplateVisitor } from '@angular/compiler'
import { Observable, Subject, Subscription } from 'rxjs'
import { ScheduleEventNewComponent } from 'src/app/scheduler/schedule-calendar/schedule-event-new/schedule-event-new.component'
import { Router } from '@angular/router'
import { CRUDService } from 'src/app/crud.service'
import { getArrayFromString, getSessionValue } from 'src/app/common-utils'
import { NgxSpinnerService } from 'ngx-spinner'
import { environment } from 'src/environments/environment'
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal'
import { CommonService } from 'src/app/common.service'
import { RoleAppliedComponent } from '../../modals/role-applied/role-applied.component'
import { ToastrService } from 'ngx-toastr'
import { AcceptConnectionComponent } from '../../modals/accept-connection/accept-connection.component'
import { RoleService } from 'src/app/shared/services/role.service'
import { ValuesMapService } from 'src/app/shared/services/values-map.service'
import { SkillsetService } from 'src/app/shared/services/skillset.service'
import { CandidateService } from 'src/app/shared/services/candidate.service'
import { UserService } from 'src/app/shared/services/user.service'
import { BusinessService } from 'src/app/shared/services/business.service'
import { ROLE_CANDIDATE_STATUS } from 'src/app/core/models/enums'

@Component({
  selector: 'app-jobs-posts',
  templateUrl: './jobs-posts.component.html',
  styleUrls: ['../../dashboards.scss', './jobs-posts.component.scss']
})
export class JobsPostsComponent implements OnInit, OnDestroy {
  jobsPosts: any[] = []
  dialogRef: MatDialogRef<ScheduleEventNewComponent> = Object.create(NullTemplateVisitor)
  refresh: Subject<any> = new Subject()
  userInfo: any
  businessProfile: any
  businessLists: any[] = []
  businessPostCandidateInfo: any
  businessPostSlots: any[] = []
  apiUrl: any
  profileData: any
  eventSubscription: Subscription
  salaryArray: any
  // showMoreFlag: boolean;
  postData: any

  @Input() events: Observable<any>
  @Output() sendDataToParent = new EventEmitter<any>()
  @Input() jobPosts: any
  response: any
  candidateProfile: any
  bsModalRef: BsModalRef
  showMoreFlag: boolean = true

  constructor(
    public dialog: MatDialog,
    private CRUDService: CRUDService,
    private roleService: RoleService,
    private valuesMapService: ValuesMapService,
    private skillsetService: SkillsetService,
    private candidateService: CandidateService,
    private businessService: BusinessService,
    private userService: UserService,
    private router: Router,
    private SpinnerService: NgxSpinnerService,
    private modalService: BsModalService,
    private commonService: CommonService,
    private toastr: ToastrService
  ) {
    this.userInfo = getSessionValue('USER_INFO')
    this.apiUrl = environment.apiUrl
    this.loadPosts()
  }

  ngOnInit() {
    this.candidateProfile = getSessionValue('CANDIDATE_PROFILE')

    // this.showMoreFlag = environment.test;
    this.eventSubscription = this.events.subscribe(async (data: any) => {
      if (data !== undefined) {
        this.jobsPosts = []
        this.jobsPosts = data
      } else {
        this.jobsPosts = []
        this.jobsPosts = data
      }
    })

    this.valuesMapService.getFilteredValuesMap(100, 0, '', 'SALARY').subscribe((data) => {
      // const salaryArray: any[] = data;
      this.salaryArray = data.data.items
    })
  }

  // ngAfterViewInit(){
  //   this.eventSubscription = this.events.subscribe(async () => {
  //     this.jobsPosts = this.jobPosts
  //   });
  // }
  async loadPosts() {
    const postsResponse = await this.roleService.getCandidateRoleMatches().toPromise()
    this.jobsPosts = postsResponse.data?.items?.map((post) => {
      return {
        ...post,
        postAttachmentArray: post?.attachements || [],
        businessPostsCandidate: post?.candidate || {},
        businessProfile: post?.business_profile || [],
        business_name: post?.business_profile?.name || ''
      }
    })
    this.sendDataToParent.emit(this.jobsPosts)
    const data2 = await this.candidateService.getSelf().toPromise()
    this.profileData = data2.data

    return

    let BusinessPostAttachmentsData: any =
      await this.CRUDService.getAllData('BusinessPostAttachments').toPromise()
    let BusinessPostCandidateData: any =
      await this.CRUDService.getAllData('BusinessPostCandidates').toPromise()
    this.CRUDService.getAllData('BusinessProfile').subscribe((BusinessData: any) => {
      this.SpinnerService.hide()
      this.businessLists = BusinessData
      // this.CRUDService.getAllData("BusinessPosts").subscribe((data: any) => {
      //   this.jobsPosts = data.filter((value) => {
      //     return value.status == "1";
      //   });
      //   const candidate = JSON.parse(
      //     sessionStorage.getItem("CANDIDATE_PROFILE")
      //   );
      //   this.jobsPosts.forEach(async (job) => {
      //     job.postAttachmentArray = BusinessPostAttachmentsData.filter(
      //       (val: any) => val.post_id === job.id && val.status === "1"
      //     );
      //     job["businessProfile"] = this.businessLists.find(
      //       (val) => val.id === job.business_id
      //     );
      //     job.businessProfile.image =
      //       environment.apiUrl + job.businessProfile.logo_url;
      //     job.business_name = job.businessProfile.name;
      //     const matchPerc = await this.commonService.calculateMatchPercV1_1(
      //       candidate,
      //       job.businessProfile,
      //       job
      //     );
      //     job.match_perc = matchPerc;
      //     job.businessPostsCandidate = BusinessPostCandidateData.find(
      //       (val: any) =>
      //         val.post_id === job.id && val.user_id === this.userInfo.id
      //     );
      //   });
      //   this.sendDataToParent.emit(this.jobsPosts);
      // });

      this.CRUDService.getPosts(this.userInfo.id).subscribe((data: any) => {
        this.jobsPosts = data.filter((val) => val.status === '1')
        this.jobsPosts.forEach((job) => {
          job.postAttachmentArray = BusinessPostAttachmentsData.filter(
            (val: any) => val.post_id === job.id && val.status === '1'
          )
          job.businessProfile = this.businessLists.find((val) => val.id === job.business_id)
          job.businessProfile.image = environment.apiUrl + job.businessProfile.logo_url
          job.business_name = job.businessProfile.name
          // job.showMoreFlag = false;
          let postCandidateData = BusinessPostCandidateData.find(
            (val: any) => val.post_id === job.id && val.user_id === this.userInfo.id
          )
          if (
            postCandidateData.round_no == '1' &&
            postCandidateData.meeting_id !== null &&
            postCandidateData.meeting_id !== ''
          ) {
            job.scheduled = true
          } else {
            job.scheduled = false
          }
          job.businessPostsCandidate = postCandidateData
        })
        this.jobsPosts = this.jobsPosts.filter(
          (e) => e?.businessPostsCandidate?.action_status !== null
        )
        this.sendDataToParent.emit(this.jobsPosts)
      })
    })
  }

  async getJobInfo(post: any) {
    return new Promise<void>(async (resolve) => {
      await this.roleService
        .getRoleCandidate({ role_id: post.id, user_id: this.userInfo.id })
        .toPromise()
        .then(async (res) => {
          this.businessPostCandidateInfo = res?.data?.application_meta
          let data = await this.roleService.getEventsForRole(post.id).toPromise()
          this.businessPostSlots = data.data.items.filter(
            (e) => e.round_no == this.businessPostCandidateInfo?.round_no && e.status == '1'
          )
          resolve()
        })
        .catch((err) => {
          resolve()
        })
        .finally(() => {
          this.SpinnerService.hide()
        })
    })
  }

  async viewJob(post: any) {
    await this.getJobInfo(post)
    this.router.navigateByUrl('/dashboards/view-job', {
      state: {
        roleInfo: post,
        businessPostCandidateInfo: this.businessPostCandidateInfo
      }
    })
  }

  // async apply(post: any, isReschedule: any) {
  //   await this.getJobInfo(post);
  //   if (isReschedule) {
  //     post.businessPostsCandidate.action_status = 4;
  //     post.startDate = moment(
  //       post.businessPostsCandidate.slot_start_time
  //     ).format("YYYY-MM-DD hh:mm:ss");
  //     post.endDate = moment(post.businessPostsCandidate.slot_end_time).format(
  //       "YYYY-MM-DD"
  //     );
  //     post.event_id = post.businessPostsCandidate.id;
  //     const json = {
  //       title: post.title,
  //       extendedProps: post,
  //     };
  //     let dialogRef2: any = this.dialog.open(EventDetailComponent, {
  //       panelClass: "event-details-dialog",
  //       // height: '80vh',
  //       width: "80vh",
  //       data: {
  //         dateSlots: json,
  //       },
  //     });
  //     dialogRef2.afterClosed().subscribe((result: string) => {
  //       this.loadPosts();
  //       dialogRef2 = Object.create(null);
  //       this.refresh.next();
  //     });
  //   } else {
  //     post.businessPostsCandidate.action_status = 4;
  //     this.dialogRef = this.dialog.open(ScheduleEventNewComponent, {
  //       panelClass: "calendar-event-dialog",
  //       data: {
  //         business_info: post.businessProfile,
  //         job_info: post,
  //         availability: this.businessPostSlots,
  //         businessPostCandidateInfo: post.businessPostsCandidate,
  //         isReschedule: isReschedule,
  //       },
  //     });

  //     this.dialogRef.afterClosed().subscribe((res) => {
  //       this.loadPosts();
  //       if (!res) {
  //         return;
  //       } else {
  //         this.openModal();
  //       }
  //       this.dialogRef = Object.create(null);
  //       this.refresh.next();
  //     });
  //   }
  // }

  async apply(post, isReschedule: boolean) {
    const applyResponse = await this.roleService.applyToRole(post.id).toPromise()
    if (applyResponse.success) {
      this.toastr.success('Role Applied Successfully')
      this.loadPosts()
      this.openModal()
    } else {
      this.toastr.error('Failed to apply for the role')
    }
    return
    const createBusinessPostCandidate = async () => {
      let BusinessPostCandidates: any = {}
      if (!post?.businessPostsCandidate) {
        BusinessPostCandidates.post_id = post?.id
        BusinessPostCandidates.user_id = this.candidateProfile.user_id
        BusinessPostCandidates.round_no = 1
        BusinessPostCandidates.status = 0
        BusinessPostCandidates.action_status = 0
        BusinessPostCandidates.match_perc = this.candidateProfile?.match_perc
      } else {
        BusinessPostCandidates = post?.businessPostsCandidate
        BusinessPostCandidates.post_id = post?.id
        BusinessPostCandidates.round_no = 1
        BusinessPostCandidates.status = 0
        BusinessPostCandidates.action_status = 0
        BusinessPostCandidates.match_perc = this.candidateProfile?.match_perc
      }

      let response = await this.commonService.saveRecord(
        'BusinessPostCandidates',
        BusinessPostCandidates
      )
      if (response.success == true || response.success == 'true') {
        await this.loadPosts()
      }
      return response
    }
    await this.getJobInfo(post)
    let response = await createBusinessPostCandidate()
    if (response?.success == true || response.success == 'true') {
      this.openModal()
      const mailBody = {
        name: this.userInfo?.first_name,
        business_name: post?.businessProfile?.name,
        role_title: post?.title,
        to: this.userInfo?.email_id,
        host: environment.appRoot
      }
      await this.CRUDService.appliedForRoleMail(mailBody).toPromise()
    } else if (response?.success == false) {
      this.toastr.warning(response?.message)
    }
    // if (isReschedule) {
    //   post.businessPostsCandidate.action_status = 4;
    //   post.startDate = moment(
    //     post.businessPostsCandidate.slot_start_time
    //   ).format("YYYY-MM-DD hh:mm:ss");
    //   post.endDate = moment(post.businessPostsCandidate.slot_end_time).format(
    //     "YYYY-MM-DD"
    //   );
    //   post.event_id = post.businessPostsCandidate.id;
    //   const json = {
    //     title: post?.title,
    //     extendedProps: post,
    //   };
    //   let dialogRef2: any = this.dialog.open(EventDetailComponent, {
    //     panelClass: "event-details-dialog",
    //     // height: '80vh',
    //     width: "80vh",
    //     data: {
    //       dateSlots: json,
    //     },
    //   });
    //   dialogRef2.afterClosed().subscribe((result: string) => {
    //     dialogRef2 = Object.create(null);
    //     this.refresh.next();
    //   });
    // } else {
    //   // this.jobDetails.businessPostsCandidate.action_status = 4;
    //   let data: any = await this.CRUDService.getDataByField(
    //     "BusinessPostSlots",
    //     "post_id",
    //     post.id
    //   ).toPromise();
    //   this.businessPostSlots = data;
    //   this.businessPostSlots = await this.businessPostSlots.filter((value) => {
    //     return new Date(value.slot_date).getTime() > new Date().getTime();
    //   });
    //   if (!this.businessPostSlots.length) {
    //     let response = await createBusinessPostCandidate();
    //     if (response?.success == "true") {
    //       this.openModal();
    //     } else if (response?.success == "false") {
    //       this.toastr.warning(response?.message);
    //     }
    //   } else {
    //     this.dialogRef = this.dialog.open(ScheduleEventNewComponent, {
    //       panelClass: "calendar-event-dialog",
    //       data: {
    //         business_info: post.businessProfile,
    //         job_info: post,
    //         availability: this.businessPostSlots,
    //         businessPostCandidateInfo: post.businessPostsCandidate,
    //         isReschedule: isReschedule,
    //       },
    //     });

    //     this.dialogRef.afterClosed().subscribe((res) => {
    //       if (!res) {
    //         return;
    //       } else {
    //         if (res.action == "book") {
    //           post.scheduled = true;
    //           this.loadPosts();
    //           this.openModal();
    //         }
    //       }
    //       this.dialogRef = Object.create(null);
    //       this.refresh.next();
    //     });
    //   }
    // }
  }

  openModal() {
    this.dialog.open(RoleAppliedComponent, {
      panelClass: 'role-applied-modal',
      width: '55vw',
      maxHeight: '80vh',
      autoFocus: 'false'
    })
  }

  // showMoreFn(post) {
  //   post.showMoreFlag = !post.showMoreFlag;
  // }

  employmentTypeFn(employmentType) {
    let str = employmentType
    str = str?.replaceAll?.(';', ', ')
    return str
  }

  remoteWorkValue(remote_work) {
    let remoteWork = ''
    if (remote_work === 'Y') {
      remoteWork = 'Yes'
    } else {
      remoteWork = 'No'
    }
    return remoteWork
  }

  roleBenefits(roleBenefits) {
    let benefits = getArrayFromString(roleBenefits)
    return benefits
  }

  roleSoftSkills(roleSoftSkills) {
    let softSkills = getArrayFromString(roleSoftSkills)
    return softSkills
  }

  roleExp(roleExp) {
    let exp = getArrayFromString(roleExp)
    return exp
  }

  roleSkills(roleSkills) {
    let skills = getArrayFromString(roleSkills)
    return skills
  }

  viewSalary(salaryCode) {
    let selectedSalary = this.salaryArray.find((val) => val.code === salaryCode)
    return selectedSalary?.description
  }
  // sendDataToParent()

  openFeedbackModal(targetModal: any, post: any) {
    this.response = post.response
    this.postData = post
    if (typeof this.response == 'undefined') {
      const json = {
        rating: null,
        feedback: null,
        post_id: post.id,
        id: null,
        candidate_id: this.candidateProfile.id,
        remarks: null,
        liked_yn: null
      }
      this.response = json
      post.response = json
    }
    this.bsModalRef = this.modalService.show(targetModal)
  }

  closeModal() {
    this.modalService.hide()
  }

  async submit() {
    this.postData.businessPostsCandidate.action_status = 5
    this.commonService.saveRecord('BusinessPostCandidates', this.postData.businessPostsCandidate)
    let data: any = await this.commonService.saveRecord(
      'BusinessPostResponses',
      this.postData.response
    )
    if (data.success == true) {
      this.response.id = data[0].id
    }
    this.modalService.hide()
  }

  async viewTimeSlots(post) {
    await this.getJobInfo(post)
    this.dialogRef = this.dialog.open(ScheduleEventNewComponent, {
      panelClass: 'calendar-event-dialog',
      data: {
        business_info: post.businessProfile,
        job_info: post,
        availability: this.businessPostSlots,
        businessPostCandidateInfo: post.businessPostsCandidate,
        isReschedule: false
      },
      autoFocus: false
    })

    this.dialogRef.afterClosed().subscribe(async (res) => {
      if (!res) {
        return
      } else {
        if (res.action == 'book') {
          post.scheduled = true
          this.loadPosts()
          // this.openModal();
        }
      }
      this.dialogRef = Object.create(null)
    })
  }

  // async accept(isReschedule, post) {
  //   await this.getJobInfo(post);
  //   this.dialogRef = this.dialog.open(ScheduleEventNewComponent, {
  //     panelClass: "calendar-event-dialog",
  //     data: {
  //       business_info: post.businessProfile,
  //       job_info: post,
  //       availability: this.businessPostSlots,
  //       businessPostCandidateInfo: post.businessPostsCandidate,
  //       isReschedule: isReschedule,
  //     },
  //   });

  //   this.dialogRef.afterClosed().subscribe((res) => {
  //     if (!res) {
  //       return;
  //     } else {
  //       if (res.action == "book") {
  //         post.scheduled = true;
  //         this.loadPosts();
  //         this.openModal();
  //       }
  //     }
  //     this.dialogRef = Object.create(null);
  //     this.refresh.next();
  //   });
  // }

  // async reject(post) {
  //   await this.getJobInfo(post);
  //   this.businessPostCandidateInfo.status = 2;
  //   this.businessPostCandidateInfo.action_status = 3;
  //   var initialState = "rejectByCandidate";

  //   const dialogRef3 = this.dialog.open(ReasonModalComponent, {
  //     data: { initialState },
  //     panelClass: "event-details-dialog",
  //     width: "30vw",
  //     autoFocus: false,
  //   });

  //   dialogRef3.afterClosed().subscribe(async (res) => {
  //     this.businessPostCandidateInfo.remarks = res.reason.reason;
  //     let response = await this.commonService.updateRecord(
  //       "BusinessPostCandidates",
  //       this.businessPostCandidateInfo
  //     );
  //     if (response.success == "true") {
  //       this.toastr.success("Role Rejected");
  //       this.loadPosts();
  //     } else {
  //       this.toastr.error("Failed to Reject The Role");
  //     }
  //   });
  // }

  openAcceptConnectionModal(post) {
    const dialog = this.dialog.open(AcceptConnectionComponent, {
      panelClass: 'role-model-post',
      width: '55vw',
      autoFocus: false,
      data: post?.business_name
    })

    dialog.afterClosed().subscribe(async (data) => {
      if (data) {
        await this.getJobInfo(post)
        if (data.status === 'rejected') {
          const response = await this.roleService
            .updateRoleCandidateStatus({
              role_id: post.id,
              user_id: this.candidateProfile.user_id,
              status: ROLE_CANDIDATE_STATUS.REJECT_CONNECTION,
              remarks: data?.reason
            })
            .toPromise()
          this.businessPostCandidateInfo.status = 2
          this.businessPostCandidateInfo.action_status = 3
          this.businessPostCandidateInfo.remarks = data?.reason
          // let response = await this.commonService.saveRecord(
          //   "BusinessPostCandidates",
          //   this.businessPostCandidateInfo
          // );
          if (response.success) {
            this.refresh.next()
            post.businessPostsCandidate.action_status = 3
            await this.loadPosts()
            this.toastr.success('Connection Rejected')
            const user = []
            user.push(post?.created_by)
            // TODO: create notification from backend
            this.commonService.addAppNotification(
              `The candidate ${this.candidateProfile?.name} doesn’t want to meet.`,
              'A candidate in your shortlist has left feedback, their status will show as “Not Interested”',
              user,
              'BUSINESS',
              post.id
            )
          } else {
            this.toastr.error('Something went wrong, please try again later')
            this.refresh.next()
          }
        } else if (data.status === 'accepted') {
          const response = await this.roleService
            .updateRoleCandidateStatus({
              role_id: post.id,
              user_id: this.candidateProfile.user_id,
              status: ROLE_CANDIDATE_STATUS.ACCEPT_CONNECTION
            })
            .toPromise()
          this.businessPostCandidateInfo.status = 1
          this.businessPostCandidateInfo.action_status = 5
          // let response = await this.commonService.saveRecord(
          //   "BusinessPostCandidates",
          //   this.businessPostCandidateInfo
          // );
          if (response.success) {
            await this.loadPosts()
            post.businessPostsCandidate.action_status = 5
            this.refresh.next()
          } else {
            this.toastr.error('Something went wrong, please try again later')
            this.refresh.next()
          }
        }
      }
    })
  }

  addEllipsis(text, limit) {
    if (text.length <= limit) {
      return text
    } else {
      return text.substring(0, limit) + '...'
    }
  }

  trackByFn(index: number, item: any) {
    return item?.id
  }

  ngOnDestroy(): void {
    if (this.eventSubscription) {
      this.eventSubscription.unsubscribe()
    }
  }
}
