import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { environment } from 'src/environments/environment'
import { CRUDService } from 'src/app/crud.service'
@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})
export class HelpComponent implements OnInit {
  role: any
  previousPath: any
  notificationIcon: boolean = false
  helpArr: any
  topic: any
  content: any
  constructor(
    private router: Router,
    private CRUDService: CRUDService
  ) {}
  async ngOnInit(): Promise<void> {
    this.previousPath = '/dashboards/setting/help'
    this.role = localStorage.getItem('role')
    let allData: any = await this.CRUDService.getAllData('AppInfomation').toPromise()
    allData = allData.sort((a, b) => a.display_seq - b.display_seq)
    let helpdata = allData.filter((x) => x.category === 'Upplft Help')[0]
    this.topic = helpdata.topic
    this.content = helpdata.content
    this.notificationIcon = environment.notificationShow
  }
  closeHelp() {
    if (this.role === 'candidate') {
      this.router.navigate(['/dashboards/candidate-settings'])
    } else if (this.role === 'business') {
      this.router.navigate(['/dashboards/business-settings'])
    } else if (this.role === 'partner') {
      this.router.navigate(['/dashboards/partner-settings'])
    } else {
      this.router.navigate(['/dashboards/candidate-settings'])
    }
  }
}
