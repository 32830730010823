<!-- ============================================================== -->
<!-- sidebar -->
<!-- ============================================================== -->
<div style="padding: 12px 0px; text-align: center">
  <img
    alt="Lift"
    src="assets/images/upplft/Upplft_logo.png"
    class="rocketLogo"
    style="width: 75%; margin-bottom: 44px; margin-top: 50px"
  />
  <!-- User profile image -->
  <div class="profile-img"></div>
  <!-- User profile text-->
  <!-- ============================================================== -->
  <!-- Profile - style you can find in header.scss -->
  <!-- ============================================================== -->
</div>

<mat-nav-list id="mat-nav-item-menu" appAccordion>
  <mat-list-item
    appAccordionLink
    *ngFor="let menuitem of menuItems.getMenuitem(userType)"
    routerLinkActive="selected"
    group="{{ menuitem.state }}"
    (click)="scrollToTop()"
  >
    <a
      class=""
      appAccordionToggle
      (click)="redirect(menuitem)"
      *ngIf="menuitem.type === 'link'"
      (click)="handleNotify()"
    >
      <mat-icon>{{ menuitem.icon }}</mat-icon>
      <span>{{ menuitem.name | translate }}</span>
      <span fxFlex></span>
      <span class="label label-{{ badge.type }}" *ngFor="let badge of menuitem.badge">{{
        badge.value
      }}</span>
    </a>

    <a class="" appAccordionToggle href="{{ menuitem.state }}" *ngIf="menuitem.type === 'extLink'">
      <mat-icon>{{ menuitem.icon }}</mat-icon>
      <span>{{ menuitem.name | translate }}</span>
      <span fxFlex></span>
      <span class="label label-{{ badge.type }}" *ngFor="let badge of menuitem.badge">{{
        badge.value
      }}</span>
    </a>

    <a
      class=""
      appAccordionToggle
      href="{{ menuitem.state }}"
      target="_blank"
      *ngIf="menuitem.type === 'extTabLink'"
    >
      <mat-icon>{{ menuitem.icon }}</mat-icon>
      <span>{{ menuitem.name | translate }}</span>
      <span fxFlex></span>
      <span class="label label-{{ badge.type }}" *ngFor="let badge of menuitem.badge">{{
        badge.value
      }}</span>
    </a>

    <a class="" appAccordionToggle href="javascript:;" *ngIf="menuitem.type === 'sub'">
      <mat-icon>{{ menuitem.icon }}</mat-icon>
      <span>{{ menuitem.name | translate }}</span>
      <span fxFlex></span>
      <span class="label label-{{ badge.type }}" *ngFor="let badge of menuitem.badge">{{
        badge.value
      }}</span>
      <mat-icon class="dd-icon">keyboard_arrow_down</mat-icon>
    </a>

    <mat-nav-list class="sub-item" *ngIf="menuitem.type === 'sub'">
      <mat-list-item
        *ngFor="let childitem of menuitem.children; let j = index; let i = childitem"
        routerLinkActive="selected"
      >
        <a
          [routerLink]="['/', menuitem.state, childitem.state]"
          *ngIf="childitem.type === 'link'"
          class="relative"
          routerLinkActive="selected"
          (click)="itemSelect[i] = j"
          (click)="handleNotify()"
          >{{ childitem.name | translate }}</a
        >
      </mat-list-item>

      <mat-list-item *ngFor="let childitem of menuitem.children; let j = index; let i = childitem">
        <a
          class=""
          href="javascript: void(0);"
          *ngIf="childitem.type === 'subchild'"
          (click)="addExpandClass(childitem.name)"
          [ngClass]="{ selected: showMenu === childitem.name }"
        >
          <span>{{ childitem.name | translate }}</span>
          <span fxFlex></span>
          <mat-icon class="dd-icon">keyboard_arrow_down</mat-icon>
        </a>

        <mat-nav-list class="child-sub-item" *ngIf="childitem.type === 'subchild'">
          <mat-list-item *ngFor="let child of childitem.subchildren" routerLinkActive="selected">
            <a
              [routerLink]="['/', menuitem.state, childitem.state, child.state]"
              routerLinkActive="selected"
              >{{ child.name | translate }}</a
            >
          </mat-list-item>
        </mat-nav-list>
      </mat-list-item>
    </mat-nav-list>

    <div class="saperator text-muted" *ngIf="menuitem.type === 'seperator'">
      <mat-divider [inset]="true"></mat-divider>
    </div>
  </mat-list-item>
</mat-nav-list>

<ng-template #logoutModal class="modal-content">
  <div class="modal-header">
    <h4 class="modal-title">Confirmation</h4>
    <button type="button" class="close" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">Are you sure want to Logout?</div>
  <div class="modal-footer">
    <button mat-raised-button class="confirm-button" (click)="logout()">Yes</button>
    <button mat-raised-button class="cancle-button" (click)="closeModal()">No</button>
  </div>
</ng-template>
