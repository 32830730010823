import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable, of, throwError } from 'rxjs'
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http'
import { environment } from '../../../environments/environment'
import { catchError, finalize, map, tap } from 'rxjs/operators'
import { Router } from '@angular/router'
import { ToastrService } from 'ngx-toastr'
import { NewSkillset, Skillset, SkillsetListResponse } from 'src/app/core/models/skillset'
import { ApiResponseNoData } from 'src/app/core/models/base'

@Injectable()
export class SkillsetService {
  constructor(
    private http: HttpClient,
    private router: Router,
    private toastr: ToastrService
  ) {}

  getFilteredSkillset(
    limit: number = 50,
    offset: number = 0,
    q: string = '',
    sort: string = 'id',
    order: string = 'asc'
  ) {
    return this.http
      .get<SkillsetListResponse>(`${environment.apiUrl}/v1/skillset`, {
        params: new HttpParams()
          .set('limit', limit.toString())
          .set('offset', offset.toString())
          .set('q', q)
          .set('sort', sort)
          .set('order', order)
      })
      .pipe(
        map(
          (res) => {
            return res
          },
          (err) => {
            console.log('values map list', err)
          }
        )
      )
  }

  addNewSkillSet(skillSet: NewSkillset) {
    return this.http.post<ApiResponseNoData>(`${environment.apiUrl}/v1/skillset`, skillSet).pipe(
      map((res) => {
        this.toastr.success('Values Map added successfully')
        return res
      })
    )
  }

  updateSkilSet(skillSet: NewSkillset) {
    return this.http
      .put<ApiResponseNoData>(`${environment.apiUrl}/v1/skillset/${skillSet.id}`, skillSet)
      .pipe(
        map((res) => {
          this.toastr.success('Values Map updated successfully')
          return res
        })
      )
  }

  deleteSkillSet(id: number) {
    return this.http.delete<ApiResponseNoData>(`${environment.apiUrl}/v1/skillset/${id}`).pipe(
      map((res) => {
        this.toastr.success('Skillset deleted successfully')
        return res
      })
    )
  }
}
