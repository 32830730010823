import { Component, EventEmitter, OnInit, Output } from '@angular/core'
import { Router } from '@angular/router'
import { BsModalService } from 'ngx-bootstrap/modal'
declare var $: any

@Component({
  selector: 'app-deacrivate-confirmation',
  templateUrl: './deacrivate-confirmation.component.html',
  styleUrls: ['./deacrivate-confirmation.component.scss']
})
export class DeacrivateConfirmationComponent implements OnInit {
  constructor(
    private modalService: BsModalService,
    private router: Router
  ) {}
  @Output() messageEvent = new EventEmitter<boolean>()
  ngOnInit(): void {
    $('.modal-content').on('contextmenu', function () {
      // Display the message
      return false
    })
  }
  deactivate() {
    this.messageEvent.emit(true)
    this.modalService.hide()
    sessionStorage.clear()
    this.router.navigate(['/home'])
  }
  closeModal() {
    this.messageEvent.emit(false)
    this.modalService.hide()
  }
}
