import { Component, Inject, ViewChild, ElementRef, HostListener } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog'
import { FormBuilder, FormControl, FormGroup } from '@angular/forms'
import { EgretCalendarEvent } from '../event.model'
import { DateSlots, TimeSlots, TimeSlotsList } from '../event-utils'
import * as moment from 'moment'
import { DeleteConfirmationComponent } from 'src/app/dashboards/modals/delete-confirmation/delete-confirmation.component'
import { ToastrService } from 'ngx-toastr'
import { RoleService } from 'src/app/shared/services/role.service'

@Component({
  selector: 'app-calendar-event-dialog',
  templateUrl: './calendar-event-dialog.component.html',
  styleUrls: ['./calendar-event-dialog.component.scss']
  // encapsulation: ViewEncapsulation.None
})
export class CalendarEventDialogComponent {
  event: any
  dateEvent: any
  dialogTitle: string
  eventForm: FormGroup
  action: any
  post_id: any
  calendarEvents: Event[]
  dateSlots: DateSlots[] = []
  previousDateSlots: DateSlots[] = []
  timeSlotsList: TimeSlots[]
  changed: boolean = false
  events: any[] = []
  currentDate = new Date()
  daysSelected: any[] = []

  @ViewChild('slotStartTime', { read: ElementRef }) slotStartTime: ElementRef
  @ViewChild('slotEndTime', { read: ElementRef }) slotEndTime: ElementRef
  @ViewChild('startTimeMatSelect') startTimeMatSelect: any
  @ViewChild('endTimeMatSelect') endTimeMatSelect: any

  @HostListener('document:click', ['$event'])
  public documentClick(event: any): void {
    if (!this.slotStartTime.nativeElement.contains(event.target)) {
      this.startTimeMatSelect.close()
    }
    if (!this.slotEndTime.nativeElement.contains(event.target)) {
      this.endTimeMatSelect.close()
    }
  }

  constructor(
    public dialogRef: MatDialogRef<CalendarEventDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private formBuilder: FormBuilder,
    private roleService: RoleService,
    private dialog: MatDialog,
    private toastr: ToastrService
  ) {
    this.events = this.data.dateSlots
    let loadedDate: any = new Date()
    if (data.startDate != null) {
      loadedDate = new Date(data.startDate)
    }
    if (data.endDate != null) {
      let endDate = new Date(data.endDate)
      // var now = new Date();
      // var daysOfYear = [];
      for (var d = loadedDate; d <= endDate; d.setDate(d.getDate() + 1)) {
        loadedDate = d
        const date =
          loadedDate.getFullYear() +
          '-' +
          ('00' + (loadedDate.getMonth() + 1)).slice(-2) +
          '-' +
          ('00' + loadedDate.getDate()).slice(-2)

        this.daysSelected.push(date)
      }
    }
    this.event = data.event
    this.action = data.action
    this.post_id = data.post_id

    if (this.action === 'edit') {
      this.dialogTitle = this.event.title
    } else {
      this.dialogTitle = 'Manage Schedule'
      this.event = new EgretCalendarEvent({
        start: data.startDate,
        end: data.endDate
      })
    }
    this.timeSlotsList = TimeSlotsList
    this.eventForm = this.buildEventForm(this.event)
    this.loadSlots(data.startDate)
  }

  loadSlots(selectedDate: any) {
    this.dateSlots = []
    if (
      this.event &&
      this.event?.extendedProps?.selectedStartTime &&
      this.event?.extendedProps?.selectedEndTime
    ) {
      this.dateSlots.push({
        selectedStartTime: this.event.extendedProps.selectedStartTime,
        selectedEndTime: this.event.extendedProps.selectedEndTime
      })
    }
    selectedDate = new Date(selectedDate)
    // const date =
    //   selectedDate.getFullYear() +
    //   "-" +
    //   ("00" + (selectedDate.getMonth() + 1)).slice(-2) +
    //   "-" +
    //   ("00" + selectedDate.getDate()).slice(-2);
    if (this.daysSelected.length == 1) {
      this.previousDateSlots = this.events.filter(
        (val) => moment(val.startDate).format('YYYY-MM-DD') === this.daysSelected[0]
      )
    } else {
      this.previousDateSlots = []
    }
    // slots.forEach(slot => {
    //   let dateSlot: DateSlots = { selectedStartTime: slot.selectedStartTime, selectedEndTime: slot.selectedEndTime };
    //   this.previousDateSlots.push(dateSlot);
    // });
    if (this.dateSlots.length === 0) {
      let dateSlot: DateSlots = {
        selectedStartTime: '0900',
        selectedEndTime: '1700'
      }
      this.dateSlots.push(dateSlot)
    }
  }

  buildEventForm(event: any): any {
    return new FormGroup({
      _id: new FormControl(event._id),
      title: new FormControl(event.title),
      start: new FormControl(event.start),
      end: new FormControl(event.end),
      allDay: new FormControl(event.allDay),
      color: this.formBuilder.group({
        primary: new FormControl(event.color?.primary),
        secondary: new FormControl(event.color?.secondary)
      }),
      meta: this.formBuilder.group({
        location: new FormControl(event.meta?.location),
        notes: new FormControl(event.meta?.notes)
      }),
      draggable: new FormControl(true)
    })
  }

  isSelected = (event: any) => {
    let date
    if (event?._isAMomentObject) {
      date = event.format('YYYY-MM-DD')
    } else {
      date =
        event.getFullYear() +
        '-' +
        ('00' + (event.getMonth() + 1)).slice(-2) +
        '-' +
        ('00' + event.getDate()).slice(-2)
    }
    return this.daysSelected.find((x) => x == date) ? 'selected' : null
  }

  select(event: any, calendar: any) {
    let date
    if (event?._isAMomentObject) {
      date = event.format('YYYY-MM-DD')
    } else {
      date =
        event.getFullYear() +
        '-' +
        ('00' + (event.getMonth() + 1)).slice(-2) +
        '-' +
        ('00' + event.getDate()).slice(-2)
    }
    const index = this.daysSelected.findIndex((x) => x == date)
    if (index < 0) {
      this.daysSelected.push(date)
    } else {
      this.daysSelected.splice(index, 1)
    }
    this.loadSlots(event)
    this.changed = true
    calendar.updateTodaysDate()
  }

  addSlot() {
    this.changed = true
    this.dateSlots.push({ selectedStartTime: '0830', selectedEndTime: '0900' })
  }

  async removeSlot(slot: any, isPreviousDateSlots) {
    this.changed = true
    if (!isPreviousDateSlots) {
      this.dateSlots = this.dateSlots.filter((val) => val !== slot)
      if (this.dateSlots.length === 0) {
        let dateSlot: DateSlots = {
          selectedStartTime: '0900',
          selectedEndTime: '1700'
        }
        this.dateSlots.push(dateSlot)
      }
    } else {
      const dialogRef = this.dialog.open(DeleteConfirmationComponent, {
        id: 'deleteDialog',
        autoFocus: false
      })

      dialogRef.afterClosed().subscribe(async (result) => {
        if (result === 'Delete') {
          // const businessPostSlot: any = await this.CRUDService.getDataByField(
          //   "BusinessPostSlots",
          //   "id",
          //   slot.id
          // ).toPromise();
          // businessPostSlot[0].status = 2;
          const result = await this.roleService.deleteEventById(slot.id).toPromise()
          if (result.success) {
            this.toastr.success('Event removed successfully')
            const index = this.previousDateSlots.findIndex((value: any) => {
              return value.id === slot.id
            })
            if (index !== -1) {
              this.previousDateSlots.splice(index, 1)
            }
          } else {
            this.toastr.warning('Something went wrong, please try again later')
          }
        }
      })
    }
  }

  save() {
    let events: any[] = []
    if (this.daysSelected.length === 0) {
      this.toastr.error('Please select at least one date')
      return
    }
    this.daysSelected.forEach((date) => {
      let event: any = {}
      let timeslots: any[] = []
      this.dateSlots.forEach((slot) => {
        event.date = date
        let selectedStartTime = TimeSlotsList.find((val) => slot.selectedStartTime === val.value)
        let selectedEndTime = TimeSlotsList.find((val) => slot.selectedEndTime === val.value)
        timeslots.push({ selectedStartTime, selectedEndTime })
        event.timeslots = timeslots
      })
      events.push(event)
    })

    this.dialogRef.close({ action: 'save', changed: true, events })
  }

  cancel() {
    this.dialogRef.close({})
  }
}
