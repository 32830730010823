import { Component, OnInit } from '@angular/core'
import { BsModalService } from 'ngx-bootstrap/modal'
import 'babel-polyfill'
import { CommonService } from 'src/app/common.service'
import { getSessionValue } from '../../../common-utils'
declare var $: any
@Component({
  selector: 'app-notification-permission',
  templateUrl: './notification-permission.component.html',
  styleUrls: ['./notification-permission.component.scss']
})
export class NotificationPermissionComponent implements OnInit {
  userInfo: any
  deviceToken: any
  pushSubscribersDetails: any
  constructor(
    private bsmodalService: BsModalService,
    private commonService: CommonService
  ) {}

  async ngOnInit() {
    $('.modal-content').on('contextmenu', function () {
      // Display the message
      return false
    })
    this.userInfo = getSessionValue('USER_INFO')

    this.deviceToken = ''
    if (this.userInfo) {
      this.pushSubscribersDetails = {
        status: 1,
        user_id: this.userInfo.id,
        device_id: this.deviceToken,
        tag: 'Defalut',
        logged_in_yn: 'Y',
        user_consent_yn: 'Y',
        updated_at: null,
        updated_by: null
      }
    }
  }
  closeModal() {
    this.bsmodalService.hide()
  }
  confirm() {
    /* Pushy.register({ appId: '61bdc73cede468275d5d4014' }).then(async function (deviceToken: any) {
      self.deviceToken = deviceToken
      self.bsmodalService.hide();
      self.pushSubscribersDetails['device_id'] = self.deviceToken;
      self.pushSubscribersDetails['user_consent_yn'] = 'Y';
      sessionStorage.setItem('device_id', deviceToken);
      self.UpdatePushSubscribers();
      const json = {to:deviceToken, message: "hello 3", title: "title text", url: "/", image:"https://images.unsplash.com/photo-1593642633279-1796119d5482?ixlib=rb-1.2.1&ixid=MnwxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=688&q=80" }
      let response :any = await self.CrudService.sendPushNotification(json).toPromise();
    }).catch(function (err: any) {
      console.error(err);
      self.bsmodalService.hide();
      self.pushSubscribersDetails['device_id'] = '';
      self.pushSubscribersDetails['user_consent_yn'] = 'N';
      self.UpdatePushSubscribers();
    }); */
  }
  async UpdatePushSubscribers() {
    await this.commonService.updateRecord('PushSubscribers', this.pushSubscribersDetails)
  }
}
