import { Injectable } from '@angular/core'
import { HttpClient, HttpParams } from '@angular/common/http'
import { environment } from '../../../environments/environment'
import { map } from 'rxjs/operators'
import { ApiResponse, PaginationParams, ApiListResponse } from 'src/app/core/models/base'
import {
  AllRolesResponse,
  BusinessPostRole,
  RolePersonalityRanking
} from 'src/app/core/models/business'
import { ROLE_CANDIDATE_STATUS } from 'src/app/core/models/enums'
import {
  InterviewSlotsStatsResponse,
  InterviewTrendResponse,
  RoleBasicStatsResponse,
  RoleDetailedStatsResponse
} from 'src/app/core/models/role'

@Injectable()
export class RoleService {
  constructor(private http: HttpClient) {}

  private apiRoot = environment.apiUrl + '/v1'
  private appRoot = environment.appRoot

  postARole(data) {
    return this.http.post<ApiResponse<BusinessPostRole>>(`${this.apiRoot}/role`, data).pipe(
      map(
        (res) => {
          return res
        },
        (err) => {
          console.log('Post a role', err)
        }
      )
    )
  }

  updateARolePost(data) {
    return this.http
      .put<ApiResponse<BusinessPostRole>>(`${this.apiRoot}/role/${data.id}`, data)
      .pipe(
        map(
          (res) => {
            return res
          },
          (err) => {
            console.log('Post a role', err)
          }
        )
      )
  }

  getRolesByBusinessId(id: number | string) {
    return this.http
      .get<ApiResponse<BusinessPostRole[]>>(`${this.apiRoot}/role/business/${id}`)
      .pipe(
        map(
          (res) => {
            return res
          },
          (err) => {
            console.log('Get Role', err)
          }
        )
      )
  }

  getAllRoles({
    limit = 50,
    offset = 0,
    order = 'asc',
    sort = 'created_at',
    q = '',
    status = [1, 3, 4]
  }: PaginationParams<BusinessPostRole>) {
    return this.http
      .get<ApiResponse<AllRolesResponse>>(`${this.apiRoot}/role`, {
        params: new HttpParams()
          .set('limit', limit.toString())
          .set('offset', offset.toString())
          .set('order', order)
          .set('sort', sort)
          .set('q', q)
          .set('status', status.join(','))
      })
      .pipe(
        map(
          (res) => {
            return res
          },
          (err) => {
            console.log('candidate onboarding', err)
          }
        )
      )
  }

  getRoleById(id: number | string) {
    return this.http.get<ApiResponse<BusinessPostRole>>(`${this.apiRoot}/posts/${id}`).pipe(
      map(
        (res) => {
          return res
        },
        (err) => {
          console.log('Get Role', err)
        }
      )
    )
  }

  getRoleAttachmentsByRoleId(id: number | string) {
    return this.http.get<ApiListResponse<any>>(`${this.apiRoot}/posts/${id}/attachments`).pipe(
      map(
        (res) => {
          return res
        },
        (err) => {
          console.log('Get Role attachments', err)
        }
      )
    )
  }

  createAttachment(postId: string, data: any) {
    return this.http
      .post<ApiResponse<any>>(`${this.apiRoot}/posts/${postId}/attachments`, data)
      .pipe(
        map(
          (res) => {
            return res
          },
          (err) => {
            console.log('Post a role attachment', err)
          }
        )
      )
  }

  updateAttachment(postId: string, attachmentId: string, data: any) {
    return this.http
      .put<ApiResponse<any>>(`${this.apiRoot}/posts/${postId}/attachments/${attachmentId}`, data)
      .pipe(
        map(
          (res) => {
            return res
          },
          (err) => {
            console.log('Update a role attachment', err)
          }
        )
      )
  }

  getCandidateRoleMatches(q: string = '', limit: number = 20, offset: number = 0) {
    return this.http
      .get<ApiResponse<any>>(`${this.apiRoot}/posts/my-matches`, {
        params: new HttpParams()
          .set('q', q)
          .set('limit', limit.toString())
          .set('offset', offset.toString())
      })
      .pipe(
        map(
          (res) => {
            return res
          },
          (err) => {
            console.log('Get Role Candidates', err)
          }
        )
      )
  }

  getCandidatesByRole(role_id: number | string) {
    return this.http.get<ApiResponse<any>>(`${this.apiRoot}/posts/${role_id}/candidates`).pipe(
      map(
        (res) => {
          return res
        },
        (err) => {
          console.log('Get Role Candidates', err)
        }
      )
    )
  }

  getRoleCandidateProxyRelation(id: number | string) {
    return this.http
      .get<ApiResponse<any>>(`${this.apiRoot}/posts/from-candidate-proxy-table/${id}`)
      .pipe(
        map(
          (res) => {
            return res
          },
          (err) => {
            console.log('Get Role Candidate Proxy', err)
          }
        )
      )
  }

  getRoleCandidateProxyRelationList(args: { post_id?: number | string }) {
    return this.http
      .get<ApiResponse<any>>(`${this.apiRoot}/posts/from-candidate-proxy-table`, {
        params: new HttpParams().set('postId', args.post_id?.toString())
      })
      .pipe(
        map(
          (res) => {
            return res
          },
          (err) => {
            console.log('Get Role Candidate Proxy', err)
          }
        )
      )
  }

  getEventsForRole(role_id: number | string) {
    return this.http.get<ApiListResponse<any>>(`${this.apiRoot}/posts/${role_id}/events`).pipe(
      map(
        (res) => {
          return res
        },
        (err) => {
          console.log('Get slots for role', err)
        }
      )
    )
  }

  createEventForRole(role_id: number | string, data) {
    return this.http.post<ApiResponse<any>>(`${this.apiRoot}/posts/${role_id}/events`, data).pipe(
      map(
        (res) => {
          return res
        },
        (err) => {
          console.log('Create event', err)
        }
      )
    )
  }

  createEvent(data) {
    return this.http.post<ApiResponse<any>>(`${this.apiRoot}/posts/events`, data).pipe(
      map(
        (res) => {
          return res
        },
        (err) => {
          console.log('Create event', err)
        }
      )
    )
  }

  updateRoleEventById(role_id: number | string, event_id: number | string, data) {
    return this.http
      .put<ApiResponse<any>>(`${this.apiRoot}/posts/${role_id}/events/${event_id}`, data)
      .pipe(
        map(
          (res) => {
            return res
          },
          (err) => {
            console.log('Update event', err)
          }
        )
      )
  }

  deleteEventById(event_id: number | string) {
    return this.http.delete<ApiResponse<any>>(`${this.apiRoot}/posts/events/${event_id}`).pipe(
      map(
        (res) => {
          return res
        },
        (err) => {
          console.log('Delete event', err)
        }
      )
    )
  }

  getAssessmentRoundsForRole(role_id: number | string) {
    return this.http.get<ApiListResponse<any>>(`${this.apiRoot}/posts/${role_id}/rounds`).pipe(
      map(
        (res) => {
          return res
        },
        (err) => {
          console.log('Get rounds for role', err)
        }
      )
    )
  }

  getAssessmentRoundForRole(role_id: number | string, round_id: number | string) {
    return this.http
      .get<ApiResponse<any>>(`${this.apiRoot}/posts/${role_id}/rounds/${round_id}`)
      .pipe(
        map(
          (res) => {
            return res
          },
          (err) => {
            console.log('Get round for role', err)
          }
        )
      )
  }

  createAssessmentRoundForRole(role_id: number | string, data) {
    return this.http.post<ApiResponse<any>>(`${this.apiRoot}/posts/${role_id}/rounds`, data).pipe(
      map(
        (res) => {
          return res
        },
        (err) => {
          console.log('create round for role', err)
        }
      )
    )
  }

  updateAssessmentRoundForRole(role_id: number | string, round_id: number | string, data) {
    return this.http
      .put<ApiResponse<any>>(`${this.apiRoot}/posts/${role_id}/rounds/${round_id}`, data)
      .pipe(
        map(
          (res) => {
            return res
          },
          (err) => {
            console.log('update round for rolee', err)
          }
        )
      )
  }

  deleteAssessmentRoundForRole(role_id: number | string, round_id: number | string) {
    return this.http
      .delete<ApiResponse<any>>(`${this.apiRoot}/posts/${role_id}/rounds/${round_id}`)
      .pipe(
        map(
          (res) => {
            return res
          },
          (err) => {
            console.log('delete round for role', err)
          }
        )
      )
  }

  getCandidateForRole(role_id: number | string, user_id: number | string) {
    return this.http
      .get<ApiListResponse<any>>(`${this.apiRoot}/posts/${role_id}/candidates/${user_id}`)
      .pipe(
        map(
          (res) => {
            return res
          },
          (err) => {
            console.log('Get Role Candidate', err)
          }
        )
      )
  }

  applyToRole(role_id: number) {
    return this.http
      .post<ApiResponse<any>>(
        `${this.apiRoot}/posts/${role_id}/apply`,
        {},
        {
          headers: {
            'X-Frontend-Host': this.appRoot
          }
        }
      )
      .pipe(
        map(
          (res) => {
            return res
          },
          (err) => {
            console.log('Apply To Role', err)
          }
        )
      )
  }

  acceptConnectionToRole(role_id: number, user_id: number) {
    return this.http
      .post<ApiResponse<any>>(
        `${this.apiRoot}/posts/${role_id}/candidates/${user_id}/accept-connection`,
        {},
        {
          headers: {
            'X-Frontend-Host': this.appRoot
          }
        }
      )
      .pipe(
        map(
          (res) => {
            return res
          },
          (err) => {
            console.log('Apply To Role', err)
          }
        )
      )
  }

  shortlistCandidate(role_id: number, user_id: number, remarks: string = '') {
    return this.http
      .post<ApiResponse<any>>(
        `${this.apiRoot}/posts/${role_id}/candidates/${user_id}/shortlist`,
        {
          remarks: remarks
        },
        {
          headers: {
            'X-Frontend-Host': this.appRoot
          }
        }
      )
      .pipe(
        map(
          (res) => {
            return res
          },
          (err) => {
            console.log('Shortlist Role', err)
          }
        )
      )
  }

  updateRoleCandidateStatus(params: {
    role_id: number
    user_id: number
    status: ROLE_CANDIDATE_STATUS
    remarks?: string
    round_no?: number
    meeting_id?: number
  }) {
    return this.http
      .put<ApiResponse<any>>(
        `${this.apiRoot}/posts/${params.role_id}/candidates/${params.user_id}/status`,
        {
          remarks: params.remarks,
          status: params.status,
          round_no: params.round_no
        },
        {
          headers: {
            'X-Frontend-Host': this.appRoot
          }
        }
      )
      .pipe(
        map(
          (res) => {
            return res
          },
          (err) => {
            console.log('Update candidate status', err)
          }
        )
      )
  }

  getRoleCandidates(params: { role_id: number }) {
    return this.http
      .get<ApiListResponse<any>>(`${this.apiRoot}/posts/${params.role_id}/candidates`, {
        headers: {
          'X-Frontend-Host': this.appRoot
        }
      })
      .pipe(
        map(
          (res) => {
            return res
          },
          (err) => {
            console.log('get role candidate', err)
          }
        )
      )
  }

  getRoleCandidate(params: { role_id: number; user_id: number }) {
    return this.http
      .get<ApiResponse<any>>(
        `${this.apiRoot}/posts/${params.role_id}/candidates/${params.user_id}`,
        {
          headers: {
            'X-Frontend-Host': this.appRoot
          }
        }
      )
      .pipe(
        map(
          (res) => {
            return res
          },
          (err) => {
            console.log('get role candidate', err)
          }
        )
      )
  }

  updateRoleCandidate(params: { role_id: number; user_id: number; body: any }) {
    return this.http
      .put<ApiResponse<any>>(
        `${this.apiRoot}/posts/${params.role_id}/candidates/${params.user_id}`,
        params.body,
        {
          headers: {
            'X-Frontend-Host': this.appRoot
          }
        }
      )
      .pipe(
        map(
          (res) => {
            return res
          },
          (err) => {
            console.log('Update candidate', err)
          }
        )
      )
  }

  savePersonalityRanking(data) {
    return this.http
      .post<ApiResponse<RolePersonalityRanking>>(`${this.apiRoot}/role/personality-ranking`, data)
      .pipe(
        map(
          (res) => {
            return res
          },
          (err) => {
            console.log('Personality Ranking', err)
          }
        )
      )
  }

  updatePersonalityRanking(data) {
    return this.http
      .put<
        ApiResponse<RolePersonalityRanking>
      >(`${this.apiRoot}/role/${data.post_id}/personality-ranking`, data)
      .pipe(
        map(
          (res) => {
            return res
          },
          (err) => {
            console.log('Personality Ranking', err)
          }
        )
      )
  }

  getPersonalityRankingByRoleId(role_id: number | string) {
    return this.http
      .get<
        ApiResponse<RolePersonalityRanking>
      >(`${this.apiRoot}/role/personality-ranking/${role_id}`)
      .pipe(
        map(
          (res) => {
            return res
          },
          (err) => {
            console.log('Personality Ranking', err)
          }
        )
      )
  }

  getRolesBasicStats() {
    return this.http.get<ApiResponse<RoleBasicStatsResponse>>(`${this.apiRoot}/role/stats`).pipe(
      map(
        (res) => {
          return res
        },
        (err) => {
          console.log('Roles Basic Stats', err)
        }
      )
    )
  }

  getInterviewTrendStats() {
    return this.http
      .get<ApiResponse<InterviewTrendResponse>>(`${this.apiRoot}/role/interview/stats`)
      .pipe(
        map(
          (res) => {
            return res
          },
          (err) => {
            console.log('Interview Trend Stats', err)
          }
        )
      )
  }

  getRoleDetailedStats(year: number, month?: number) {
    let params = new HttpParams().set('year', year.toString())

    if (month !== undefined && month !== null) {
      params = params.set('month', month.toString())
    }

    return this.http
      .get<ApiResponse<RoleDetailedStatsResponse[]>>(`${this.apiRoot}/role/stats/detailed`, {
        params
      })
      .pipe(
        map(
          (res) => res,
          (err) => {
            console.log('Role detailed stats', err)
            return err // ensure the error is returned or handled appropriately
          }
        )
      )
  }

  getInterviewSlotsDetailedStats(year: number, month?: number) {
    let params = new HttpParams().set('year', year.toString())

    if (month !== undefined && month !== null) {
      params = params.set('month', month.toString())
    }

    return this.http
      .get<ApiResponse<InterviewSlotsStatsResponse[]>>(`${this.apiRoot}/role/slots/stats`, {
        params
      })
      .pipe(
        map(
          (res) => res,
          (err) => {
            console.log('Interview slots stats', err)
            return err // ensure the error is returned or handled appropriately
          }
        )
      )
  }
}
