<div class="modal-content">
  <div class="modal-header">
    <h6 class="circularBold signUpHeading">Remarks</h6>
    <button
      type="button"
      class="close ml-2"
      aria-label="Close"
      data-dismiss="modal"
      (click)="closeModal()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="reasonForm">
      <!-- <mat-form-field class="example-full-width" appearance="fill">
                <input type="text" formControlName="channelName" matInput placeholder="Channel Name">
            </mat-form-field> -->
      <div fxLayout="row wrap" class="align-items-center m-t-15 m-b-15" *ngIf="!showInput">
        <div fxFlex.gt-md="25" fxFlex.gt-lg="25" fxFlex="100">
          <div class="profile-form language-profile">
            <mat-form-field appearance="fill">
              <mat-select placeholder="Select option" formControlName="reason">
                <mat-option *ngFor="let reason of reasonList" [value]="reason">
                  {{ reason }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div
            class="profile-form language-profile"
            *ngIf="reasonForm.controls['reason'].value.toLowerCase() === 'other'"
          >
            <mat-form-field class="example-full-width" appearance="fill">
              <input
                type="text"
                formControlName="otherReason"
                matInput
                placeholder="Other Reason"
              />
            </mat-form-field>
          </div>
        </div>
      </div>
      <div fxLayout="row wrap" class="align-items-center m-t-15 m-b-15" *ngIf="showInput">
        <div fxFlex.gt-md="50" fxFlex.gt-lg="50" fxFlex="100">
          <div class="profile-form language-profile">
            <mat-form-field class="example-full-width" appearance="fill">
              <input type="text" formControlName="businessReason" matInput placeholder="Remarks" />
            </mat-form-field>
          </div>
        </div>
      </div>
      <div>
        <button mat-raised-button class="like-btn" (click)="reasonSubmit()">Submit</button>
      </div>
    </form>
  </div>
</div>
