export enum VALUE_GROUP {
  'SOFT_SKILLS' = 'SOFT_SKILLS',
  'INDUSTRY' = 'INDUSTRY',
  'COMP_VALUES' = 'COMP_VALUES',
  'SHORTLIST_REASON' = 'SHORTLIST_REASON',
  'INDUSTRY_OLD' = 'INDUSTRY_OLD',
  'JOB_ROLES' = 'JOB_ROLES',
  'ROLES' = 'ROLES',
  'YESNO3' = 'YESNO3',
  'SOFT_SKILLS_DEL' = 'SOFT_SKILLS_DEL',
  'SALARY' = 'SALARY',
  'FRUSTRATIONS' = 'FRUSTRATIONS',
  'AVAILABILITY' = 'AVAILABILITY',
  'PERSONALITY_FACTORS' = 'PERSONALITY_FACTORS',
  'BUSINESS' = 'BUSINESS',
  'SIGNUP_REASON' = 'SIGNUP_REASON',
  'CULTURE_FACTORS' = 'CULTURE_FACTORS',
  'SOFT_SKILLS_OLD' = 'SOFT_SKILLS_OLD',
  'INDUSTRY_DEL' = 'INDUSTRY_DEL',
  'VALUES_DEL' = 'VALUES_DEL',
  'LOCATION' = 'LOCATION',
  'COMP_VALUES_DEL_2' = 'COMP_VALUES_DEL_2',
  'SIZE' = 'SIZE',
  'COMP_VALUES_DEL' = 'COMP_VALUES_DEL',
  'OTHER_EXPERIENCE' = 'OTHER_EXPERIENCE',
  'PERSONALITY' = 'PERSONALITY',
  'PERSONALITY_OPTIONS' = 'PERSONALITY_OPTIONS',
  'DAY_RATE' = 'DAY_RATE',
  'BENEFITS' = 'BENEFITS',
  'DOMAIN_EXP' = 'DOMAIN_EXP',
  'VALUES_OLD' = 'VALUES_OLD',
  'LOOKING_FOR' = 'LOOKING_FOR',
  'LANGUAGES' = 'LANGUAGES',
  'EDUCATION' = 'EDUCATION',
  'RESIDENCY' = 'RESIDENCY',
  'DEACTIVATE_REASON' = 'DEACTIVATE_REASON',
  'COUNTRY' = 'COUNTRY',
  'CONTACT_PREFERENCE' = 'CONTACT_PREFERENCE',
  'LANGUAGE_PROFICIENCY' = 'LANGUAGE_PROFICIENCY',
  'VALUES' = 'VALUES',
  'COMP_CULTURE' = 'COMP_CULTURE',
  'REJECT_REASON' = 'REJECT_REASON',
  'DOMAIN' = 'DOMAIN',
  'SKILLS' = 'SKILLS',
  'POST_ROLES' = 'POST_ROLES',
  'EXPERIENCE' = 'EXPERIENCE',
  'CULTURE' = 'CULTURE',
  'INCENTIVES' = 'INCENTIVES'
}

export enum ROLE_CANDIDATE_STATUS {
  REINSTATE = 'REINSTATE', // status = 0, action_status = 0

  APPLIED = 'APPLIED', // status = 0, action_status = 0

  SHORTLIST_CANDIDATE = 'SHORTLIST_CANDIDATE', // status = 1, action_status = 1

  REJECT_CANDIDATE = 'REJECT_CANDIDATE', // status = 2, action_status = 2

  // Not Interested from Candidate
  // Notification to Business on Candidate Rejecting Connection
  // Title: The candidate {candidateName} doesn’t want to meet.,
  // Message: A candidate in your shortlist has left feedback, their status will show as "Not Interested"
  REJECT_CONNECTION = 'REJECT_CONNECTION', // status = 2, action_status = 3

  MEETING_SCHEDULED = 'MEETING_SCHEDULED', // status = 1, action_status = 4

  ACCEPT_CONNECTION = 'ACCEPT_CONNECTION', // status = 1, action_status = 5

  MEETING_COMPLETED = 'MEETING_COMPLETED', // status = 2, action_status = 5

  INTERVIEWING = 'INTERVIEWING', // status = 1, action_status = 6

  OFFER = 'OFFER', // status = 1, action_status = 7

  HIRED = 'HIRED' // status = 1, action_status = 8
}
