import { Routes } from '@angular/router'

import { FullComponent } from './layouts/full/full.component'
import { AppBlankComponent } from './layouts/blank/blank.component'
import { ViewJobComponent } from './dashboards/dashboard-components'
import { ViewCandidateProfileComponent } from './view-candidate-profile/view-candidate-profile.component'
import { HomeComponent } from './authentication/home/home.component'

export const AppRoutes: Routes = [
  {
    path: '',
    component: FullComponent,
    children: [
      // {
      //   path: "",
      //   // redirectTo: "/authentication/login-option",
      //   // pathMatch: "full",
      //   component: HomeComponent,
      // },
      {
        path: 'home',
        redirectTo: '/authentication/home',
        pathMatch: 'full'
      },
      {
        path: 'dashboard',
        redirectTo: '/dashboards/dashboard1',
        pathMatch: 'full'
      },
      {
        path: 'dashboards',
        loadChildren: () => import('./dashboards/dashboards.module').then((m) => m.DashboardsModule)
      },
      {
        path: 'apps',
        loadChildren: () => import('./apps/apps.module').then((m) => m.AppsModule)
      },
      {
        path: 'candidate_home',
        redirectTo: '/dashboards/candidate',
        pathMatch: 'full'
      },
      {
        path: 'candidate_work',
        redirectTo: '/dashboards/work',
        pathMatch: 'full'
      },
      {
        path: 'candidate_network',
        redirectTo: '/dashboards/network',
        pathMatch: 'full'
      },
      {
        path: 'candidate_messages',
        redirectTo: '/dashboards/chat',
        pathMatch: 'full'
      },
      {
        path: 'candidate_calendar',
        redirectTo: '/scheduler',
        pathMatch: 'full'
      },
      {
        path: 'candidate_settings',
        redirectTo: '/dashboards/candidate-settings',
        pathMatch: 'full'
      },
      {
        path: 'messages',
        redirectTo: '/dashboards/chat',
        pathMatch: 'full'
      },
      {
        path: 'faq_setup',
        redirectTo: '/dashboards/faq-setup',
        pathMatch: 'full'
      },

      {
        path: 'business_home',
        redirectTo: '/dashboards/business',
        pathMatch: 'full'
      },
      {
        path: 'business_pipeline',
        redirectTo: '/dashboards/pipeline',
        pathMatch: 'full'
      },
      {
        path: 'business_roles',
        redirectTo: '/dashboards/roles',
        pathMatch: 'full'
      },
      {
        path: 'business_network',
        redirectTo: '/dashboards/network',
        pathMatch: 'full'
      },
      {
        path: 'business_messages',
        redirectTo: '/dashboards/chat',
        pathMatch: 'full'
      },
      {
        path: 'business_calendar',
        redirectTo: '/scheduler',
        pathMatch: 'full'
      },
      {
        path: 'business_settings',
        redirectTo: 'dashboards/business-settings',
        pathMatch: 'full'
      },
      {
        path: 'admin',
        redirectTo: '/dashboards/admin',
        pathMatch: 'full'
      },
      {
        path: 'businesses',
        redirectTo: '/dashboards/businesses',
        pathMatch: 'full'
      },
      {
        path: 'candidates',
        redirectTo: '/dashboards/candidates',
        pathMatch: 'full'
      },
      {
        path: 'talent-partners',
        redirectTo: '/dashboards/talent-partners',
        pathMatch: 'full'
      },
      {
        path: 'role-details',
        redirectTo: '/dashboards/role-details',
        pathMatch: 'full'
      },
      {
        path: 'business_home',
        redirectTo: '/dashboards/business',
        pathMatch: 'full'
      },
      {
        path: 'schedule',
        redirectTo: '/schedule-calendar',
        pathMatch: 'full'
      },
      {
        path: 'users',
        redirectTo: '/dashboards/users-details',
        pathMatch: 'full'
      },
      {
        path: 'scheduler',
        loadChildren: () => import('./scheduler/scheduler.module').then((m) => m.SchedulerModule)
      },
      {
        path: 'partner_home',
        redirectTo: '/dashboards/partner',
        pathMatch: 'full'
      },
      {
        path: 'active_roles',
        redirectTo: '/dashboards/active-roles',
        pathMatch: 'full'
      },
      {
        path: 'partner_settings',
        redirectTo: 'dashboards/partner-settings',
        pathMatch: 'full'
      }
    ]
  },
  {
    path: '',
    component: AppBlankComponent,
    children: [
      {
        path: 'authentication',
        loadChildren: () =>
          import('./authentication/authentication.module').then((m) => m.AuthenticationModule)
      },
      {
        path: 'calendar',
        loadChildren: () => import('./scheduler/scheduler.module').then((m) => m.SchedulerModule)
      },
      {
        path: 'view-job/:roleToken',
        component: ViewJobComponent
      },
      {
        path: 'view-candidate/:profileToken',
        component: ViewCandidateProfileComponent
      }
    ]
  },
  {
    path: 'profile-builders',
    loadChildren: () =>
      import('./profile-builder/profile-builder.module').then((m) => m.ProfileBuilderModule)
  },
  // {
  //   path: "personality-builder",
  //   loadChildren: () =>
  //     import("./personality-builder/personality-builder.module").then(
  //       (m) => m.PersonalityBuilderModule
  //     ),
  // },
  {
    path: '**',
    redirectTo: 'authentication/404'
  }
]
