import { BrowserModule } from '@angular/platform-browser'
import { NgModule, Injectable } from '@angular/core'
import { RouterModule } from '@angular/router'
import {
  SocialAuthService,
  SocialLoginModule,
  SocialAuthServiceConfig,
  GoogleLoginProvider
} from 'angularx-social-login'
import { ReactiveFormsModule } from '@angular/forms'
import { FormsModule } from '@angular/forms'
import {
  HttpClientModule,
  HttpClient,
  HTTP_INTERCEPTORS,
  HttpRequest,
  HttpHandler,
  HttpInterceptor
} from '@angular/common/http'
import { DatePipe } from '@angular/common'
import { AppRoutes } from './app.routing'
import { AppComponent } from './app.component'

import { FlexLayoutModule } from '@angular/flex-layout'
import { FullComponent } from './layouts/full/full.component'
import { AppBlankComponent } from './layouts/blank/blank.component'

import { VerticalAppHeaderComponent } from './layouts/full/vertical-header/vertical-header.component'
import { VerticalAppSidebarComponent } from './layouts/full/vertical-sidebar/vertical-sidebar.component'
import { HorizontalAppHeaderComponent } from './layouts/full/horizontal-header/horizontal-header.component'
import { HorizontalAppSidebarComponent } from './layouts/full/horizontal-sidebar/horizontal-sidebar.component'

import { AppBreadcrumbComponent } from './layouts/full/breadcrumb/breadcrumb.component'

import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { MaterialModule } from './material-module'
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown'
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar'
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar'
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar'
import { mailService, mailGlobalVariable } from './apps/mailbox/mail.service'
import { SharedModule } from './shared/shared.module'
import { SpinnerComponent } from './shared/spinner.component'

import { TranslateLoader, TranslateModule } from '@ngx-translate/core'
import { TranslateHttpLoader } from '@ngx-translate/http-loader'
import { ProfileBuilderComponent } from './profile-builder/profile-builder.component'
import { ToastrModule } from 'ngx-toastr'
import { ServiceWorkerModule, SwRegistrationOptions } from '@angular/service-worker'
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core'
import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS
} from '@angular/material-moment-adapter'

import { BackButtonDisableModule } from 'angular-disable-browser-back-button'
import { MatTableModule } from '@angular/material/table'
import { MatInputModule } from '@angular/material/input'
import { MatTableFilterModule } from 'mat-table-filter'
import { PersonalityBuilderComponent } from './personality-builder/personality-builder.component'
import { ViewCandidateProfileComponent } from './view-candidate-profile/view-candidate-profile.component'
import { CultureBuilderComponent } from './culture-builder/culture-builder.component'
import { AuthService } from './shared/auth/auth.service'
import { ValuesMapService } from './shared/services/values-map.service'
import { CandidateService } from './shared/services/candidate.service'
import { SkillsetService } from './shared/services/skillset.service'
import { BusinessService } from './shared/services/business.service'
import { RoleService } from 'src/app/shared/services/role.service'
import { UserService } from './shared/services/user.service'
import { AppInfoService } from './shared/services/app-info.service'
import { S3Service } from './shared/services/s3.service'
import { AuthGuard, provideAuth0, AuthModule, AuthHttpInterceptor } from '@auth0/auth0-angular'
import { environment as env } from '../environments/environment'
import { MeetingService } from './shared/services/meeting.service'
export function HttpLoaderFactory(http: HttpClient): any {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json')
}

@Injectable()
export class NoCacheHeadersInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const authReq = req.clone({
      setHeaders: {
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache'
      }
    })
    return next.handle(authReq)
  }
}

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelSpeed: 2,
  wheelPropagation: true
}
export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY'
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  }
}
@NgModule({
  declarations: [
    AppComponent,
    FullComponent,
    VerticalAppHeaderComponent,
    SpinnerComponent,
    AppBlankComponent,
    VerticalAppSidebarComponent,
    AppBreadcrumbComponent,
    HorizontalAppHeaderComponent,
    HorizontalAppSidebarComponent,
    ProfileBuilderComponent,
    PersonalityBuilderComponent,
    ViewCandidateProfileComponent,
    CultureBuilderComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MaterialModule,
    ReactiveFormsModule,
    FormsModule,
    FlexLayoutModule,
    HttpClientModule,
    PerfectScrollbarModule,
    SharedModule,
    NgMultiSelectDropDownModule.forRoot(),
    RouterModule.forRoot(AppRoutes, { relativeLinkResolution: 'legacy' }),
    HttpClientModule,
    SocialLoginModule,
    AuthModule.forRoot({
      ...env.auth,
      cacheLocation: 'localstorage',
      skipRedirectCallback: true,
      httpInterceptor: {
        ...env.httpInterceptor
      }
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    ToastrModule.forRoot({
      timeOut: 2000,
      positionClass: 'toast-top-right',
      preventDuplicates: true
    }),
    ServiceWorkerModule.register('ngsw-worker.js'),
    BackButtonDisableModule.forRoot(),
    MatTableModule,
    MatInputModule,
    MatTableFilterModule
  ],
  exports: [MatTableModule, MatInputModule],
  providers: [
    provideAuth0({
      ...env.auth,
      useRefreshTokens: true,
      cacheLocation: 'localstorage',
      skipRedirectCallback: true,
      httpInterceptor: {
        ...env.httpInterceptor
      }
    }),
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              // '812579407283-6mu6cme038s8auspuin3cl1r9bapesnk.apps.googleusercontent.com'
              '513958655090-iesccbvdhajcfai3jfno7lfd9l7uc0f3.apps.googleusercontent.com'
            )
          }
        ]
      } as SocialAuthServiceConfig
    },
    AuthService,
    ValuesMapService,
    SkillsetService,
    CandidateService,
    BusinessService,
    RoleService,
    UserService,
    S3Service,
    AuthGuard,
    SocialAuthService,
    mailService,
    mailGlobalVariable,
    DatePipe,
    BusinessService,
    RoleService,
    AppInfoService,
    MeetingService,
    {
      provide: SwRegistrationOptions,
      useFactory: () => ({ enabled: false })
    },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: NoCacheHeadersInterceptor,
      multi: true
    },
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: JwtInterceptor,
    //   multi: true,
    // },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
