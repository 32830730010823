import { NullTemplateVisitor } from '@angular/compiler'
import { Component, OnInit, ViewChild } from '@angular/core'
import { MatDialog, MatDialogRef } from '@angular/material/dialog'
import { getSessionValue, getArrayStringValue, getDefaultProfileImage } from 'src/app/common-utils'
import { CommonService } from 'src/app/common.service'
import { CRUDService } from 'src/app/crud.service'
import { environment } from '../../../../environments/environment'
import { CandidateProfileViewComponent } from '../candidate-profile-view/candidate-profile-view.component'
import { MatSelect } from '@angular/material/select'
import { Router } from '@angular/router'
import { ToastrService } from 'ngx-toastr'
import { CandidateService } from 'src/app/shared/services/candidate.service'
import { ValuesMapService } from 'src/app/shared/services/values-map.service'

@Component({
  selector: 'app-candidate-profile-overview-verified',
  templateUrl: './candidate-profile-overview-verified.component.html',
  styleUrls: ['./candidate-profile-overview-verified.component.scss']
})
export class CandidateProfileOverviewVerifiedComponent implements OnInit {
  roleValue: any
  roleArray: any = []
  userInfo: any
  candidateProfile: any
  apiUrl: any
  open_to_work: any
  open_to_work_check: any
  looking_for: any
  profileImage
  @ViewChild(MatSelect) matSelect: MatSelect
  dialogRef: MatDialogRef<CandidateProfileViewComponent> = Object.create(NullTemplateVisitor)
  isHidden = false
  constructor(
    private dialog: MatDialog,
    private valuesMapService: ValuesMapService,
    private candidateService: CandidateService,
    private CRUDService: CRUDService,
    private commonService: CommonService,
    private router: Router,
    private toastr: ToastrService
  ) {}

  async ngOnInit() {
    this.apiUrl = environment.apiUrl
    let candidateProfile = getSessionValue('CANDIDATE_PROFILE')
    const data = await this.candidateService.getSelf().toPromise()
    this.candidateProfile = data.data
    //this.candidateProfile.looking_for = this.candidateProfile.looking_for?.split(';');
    this.looking_for = this.candidateProfile.looking_for?.split(';')
    this.open_to_work_check = this.candidateProfile.open_to_work === '1' ? true : false
    this.open_to_work = this.candidateProfile.open_to_work
    this.valuesMapService.getFilteredValuesMap(100, 0, '', 'LOOKING_FOR').subscribe((data) => {
      let names = data.data.items.map(function (item) {
        return item['description']
      })
      this.roleArray = names
    })
    this.profileImage = getDefaultProfileImage(this.candidateProfile)
  }
  async changeWorkStatus(event: any) {
    // this.candidateProfile.open_to_work = event.checked ? "1" : "0";
    this.open_to_work_check = event.checked
    if (event.checked) {
      this.candidateProfile.open_to_work = '1'
    } else {
      this.candidateProfile.open_to_work = '0'
    }
    let response = await this.candidateService.updateSelf(this.candidateProfile).toPromise()
    if (response.success) {
      this.candidateProfile.profile_completion = response.data.profile_completion
      sessionStorage.setItem('CANDIDATE_PROFILE', JSON.stringify(this.candidateProfile))
      this.toastr.success('Candidate Profile Updated')
      // this._router.navigate(['/dashboards/candidate'])
    }
  }

  async changelookingFor() {
    this.candidateProfile.looking_for = getArrayStringValue(this.looking_for)
    let response = await this.candidateService.updateSelf(this.candidateProfile).toPromise()
    if (response.success) {
      sessionStorage.setItem('CANDIDATE_PROFILE', JSON.stringify(this.candidateProfile))
      this.candidateProfile = response.data
      this.toastr.success('Candidate Profile Updated')
      // this._router.navigate(['/dashboards/candidate'])
    }
  }
  openViewProfile() {
    this.dialogRef = this.dialog.open(CandidateProfileViewComponent, {
      panelClass: 'mat-candidate-profile'
    })

    this.dialogRef.afterClosed().subscribe(async (result) => {
      // TODO: implement types
      let data = await this.candidateService.getSelf().toPromise()
      if (data.success) {
        this.candidateProfile = data.data
        this.looking_for = this.candidateProfile.looking_for?.split(';')
        this.open_to_work_check = this.candidateProfile.open_to_work === '1' ? true : false
        this.open_to_work = this.candidateProfile.open_to_work
      }
    })
  }

  openEditProfile() {
    this.router.navigate(['/profile-builders/edit-candidate-profile'])
  }

  ngAfterViewInit() {
    this.matSelect?.openedChange.subscribe((opened) => {
      if (opened) {
        this.matSelect.panel.nativeElement.addEventListener('mouseleave', () => {
          this.matSelect.close()
        })
      }
    })
  }
}
