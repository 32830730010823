import { Injectable } from '@angular/core'

export interface BadgeItem {
  type: string
  value: string
}
export interface Saperator {
  name: string
  type?: string
}
export interface SubChildren {
  state: string
  name: string
  type?: string
}
export interface ChildrenItems {
  state: string
  name: string
  type?: string
  child?: SubChildren[]
}

export interface Menu {
  state: string
  name: string
  type: string
  icon: string
  badge?: BadgeItem[]
  saperator?: Saperator[]
  children?: ChildrenItems[]
}

const CANDIDATE_MENUITEMS = [
  {
    state: 'candidate_home',
    name: 'Home',
    type: 'link',
    icon: 'home'
  },
  {
    state: 'candidate_work',
    name: 'Matches',
    type: 'link',
    icon: 'folder'
  },
  /* {
    state: 'candidate_network',
    name: 'Network',
    type: 'link',
    icon: 'people',
  }, */
  // {
  //   state: 'candidate_messages',
  //   name: 'Messages',
  //   type: 'link',
  //   icon: 'bubble_chart',
  // },
  {
    state: 'candidate_calendar',
    name: 'Calendar',
    type: 'link',
    icon: 'av_timer'
  },
  {
    state: '',
    name: '',
    type: 'seperator',
    icon: 'av_timer'
  },
  {
    state: 'candidate_settings',
    name: 'Settings',
    type: 'link',
    icon: 'settings'
  },
  {
    state: 'logout',
    name: 'Log Out',
    type: 'link',
    icon: 'logout'
  }
]

const BUSINESS_MENUITEMS = [
  {
    state: 'business_roles',
    name: 'Roles',
    type: 'link',
    icon: 'folder'
  },
  /* {
    state: "business_home",
    name: "Home",
    type: "link",
    icon: "home",
  }, */
  {
    state: 'business_pipeline',
    name: 'Pipeline',
    type: 'link',
    icon: 'business_center'
  },
  //{
  //  state: 'business_network',
  //  name: 'Network',
  //  type: 'link',
  //  icon: 'people',
  //},
  // {
  //   state: 'candidates',
  //   name: 'Manage Candidates',
  //   type: 'link',
  //   icon: 'folder',
  // },
  // {
  //   state: 'business_messages',
  //   name: 'Messages',
  //   type: 'link',
  //   icon: 'bubble_chart',
  // },
  {
    state: 'business_calendar',
    name: 'Calendar',
    type: 'link',
    icon: 'av_timer'
  },
  {
    state: '',
    name: '',
    type: 'seperator',
    icon: 'av_timer'
  },
  {
    state: 'business_settings',
    name: 'Settings',
    type: 'link',
    icon: 'settings'
  },
  {
    state: 'logout',
    name: 'Log Out',
    type: 'link',
    icon: 'logout'
  }
]

const TALENT_PARTNER_MENUITEMS = [
  {
    state: 'partner_home',
    name: 'Home',
    type: 'link',
    icon: 'home'
  },
  {
    state: 'active_roles',
    name: 'Roles',
    type: 'link',
    icon: 'folder'
  },
  // {
  //   state: 'business_calendar',
  //   name: 'Calendar',
  //   type: 'link',
  //   icon: 'av_timer',
  // },
  {
    state: '',
    name: '',
    type: 'seperator',
    icon: 'av_timer'
  },
  {
    state: 'partner_settings',
    name: 'Settings',
    type: 'link',
    icon: 'settings'
  },
  {
    state: 'logout',
    name: 'Log Out',
    type: 'link',
    icon: 'logout'
  }
]

const ADMIN_MENUITEMS = [
  {
    state: 'admin',
    name: 'Home',
    type: 'link',
    icon: 'folder'
  },
  // {
  //   state: 'dashboards/statistics',
  //   name: 'Statistics',
  //   type: 'link',
  //   icon: 'folder',
  // },
  /* {
    state: 'users',
    name: 'Manage Users',
    type: 'link',
    icon: 'folder',
  }, */
  {
    state: 'dashboards/businesses',
    name: 'Manage Businesses',
    type: 'link',
    icon: 'folder'
  },
  {
    state: 'candidates',
    name: 'Manage Candidates',
    type: 'link',
    icon: 'folder'
  },
  {
    state: 'talent-partners',
    name: 'Manage Talent Partners',
    type: 'link',
    icon: 'folder'
  },
  {
    state: 'role-details',
    name: 'Manage Job Postings',
    type: 'link',
    icon: 'folder'
  },
  {
    state: 'dashboards/values-list',
    name: 'Manage Value Sets',
    type: 'link',
    icon: 'folder'
  },
  {
    state: 'dashboards/skills',
    name: 'Manage Skills',
    type: 'link',
    icon: 'folder'
  },
  {
    state: 'dashboards/new-interests',
    name: 'New Interests',
    type: 'link',
    icon: 'folder'
  },
  /* {
    state: 'business_messages',
    name: 'Messages',
    type: 'link',
    icon: 'bubble_chart',
  }, */
  /* {
    state: 'business_calendar',
    name: 'Calendar',
    type: 'link',
    icon: 'av_timer',
  }, */
  {
    state: '',
    name: '',
    type: 'seperator',
    icon: 'av_timer'
  },
  // {
  //   state: 'candidate_settings',
  //   name: 'Settings',
  //   type: 'link',
  //   icon: 'settings',
  // },
  {
    state: 'faq_setup',
    name: 'FAQ Setup',
    type: 'link',
    icon: 'settings'
  },
  {
    state: 'logout',
    name: 'Log Out',
    type: 'link',
    icon: 'logout'
  }
]

@Injectable()
export class MenuItems {
  loginType: any

  getMenuitem(type: any): Menu[] {
    if (type?.toString() == '1') {
      return CANDIDATE_MENUITEMS
    } else if (type?.toString() == '2') {
      return BUSINESS_MENUITEMS
    } else if (type?.toString() == '3') {
      return ADMIN_MENUITEMS
    } else if (type?.toString() == '4') {
      return TALENT_PARTNER_MENUITEMS
    } else {
      return CANDIDATE_MENUITEMS
    }

    /* if (this.loginType === '1'){
      return CANDIDATE_MENUITEMS;
    }else if (this.loginType === '2'){
      return BUSINESS_MENUITEMS;
    } */
  }
}
