import { Component, OnInit, ViewChild } from '@angular/core'
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal'
import { CRUDService } from 'src/app/crud.service'
import { environment } from 'src/environments/environment'
import { MatPaginator } from '@angular/material/paginator'
import { ReasonModalComponent } from '../reason-modal/reason-modal.component'
import { CommonService } from 'src/app/common.service'
import { ScheduleCalendarComponent } from 'src/app/scheduler/schedule-calendar/schedule-calendar.component'
import { MatDialog, MatDialogRef } from '@angular/material/dialog'
import { NullTemplateVisitor } from '@angular/compiler'
import * as moment from 'moment'
import { EventDetailComponent } from 'src/app/scheduler/schedule-calendar/event-detail/event-detail.component'
import { ViewCandidatesComponent } from '../view-candidates/view-candidates.component'
import { getArrayFromString, getDefaultProfileImage } from 'src/app/common-utils'
import { ShortlistDetailsComponent } from '../shortlist-details/shortlist-details.component'

import { Subject } from 'rxjs'
import { CandidatesNextRoundComponent } from '../../../dashboards/modals/candidates-next-round/candidates-next-round.component'
import { ToastrService } from 'ngx-toastr'
import { RoleService } from 'src/app/shared/services/role.service'

@Component({
  selector: 'app-roles-candidate-data',
  templateUrl: './roles-candidate-data.component.html',
  styleUrls: ['./roles-candidate-data.component.scss']
})
export class RolesCandidateDataComponent implements OnInit {
  displayedColumns: string[] = ['type', 'image', 'name', 'culture', 'location']
  candidateData: any = []
  status
  roleDetails: any
  title: any
  bsModalRef: BsModalRef
  dialogRef1: MatDialogRef<ViewCandidatesComponent> = Object.create(NullTemplateVisitor)

  dialogRef2: MatDialogRef<ShortlistDetailsComponent> = Object.create(NullTemplateVisitor)
  dialogRef3: MatDialogRef<CandidatesNextRoundComponent> = Object.create(NullTemplateVisitor)

  dialogRef: MatDialogRef<ReasonModalComponent> = Object.create(NullTemplateVisitor)
  showNamePhotoFlag: boolean

  event: any = []
  json: any[] = []
  refresh: Subject<any> = new Subject()
  candidateId: any
  businessProfile: any
  viewSpiritAnimal: boolean

  constructor(
    private bsModalService: BsModalService,
    private CRUDService: CRUDService,
    private dialog: MatDialog,
    public options: ModalOptions,
    private commonService: CommonService,
    private modalRef: BsModalRef,
    private toastr: ToastrService,
    private roleService: RoleService
  ) {}

  ngOnInit(): void {
    this.status = this.options.initialState.status
    this.roleDetails = this.options.initialState.roleDetails
    this.businessProfile = this.options.initialState.businessProfile
    this.title = this.options.initialState.title
    this.viewSpiritAnimal = environment.viewSpiritAnimal
    this.getCandidateData()
    this.showNameFlag
  }
  @ViewChild(MatPaginator) paginator: MatPaginator

  ngAfterViewInit() {
    this.candidateData.paginator = this.paginator
  }
  closeModal() {
    // this.bsModalService.hide();
    this.modalRef.hide()
    //this.bsModalRef.close();
    // this.mailSent = true;
  }

  openOffer() {
    let initialState = 'Offer'
    this.dialogRef2 = this.dialog.open(ShortlistDetailsComponent, {
      data: { initialState },
      panelClass: 'event-details-dialog',
      width: '30vw'
    })
    this.dialogRef2.afterClosed().subscribe(async (res) => {
      /*  data.businessPostCandidate.remarks = res.reason;
      let response = await this.commonService.updateRecord(
         "BusinessPostCandidates",
         data?.businessPostCandidate
       );
       if (response.success == true || response.success == "true") {
         this._snackBar.open("offer Details Updated successfully", "Close", {
           horizontalPosition: "end",
           verticalPosition: "top",
           duration: 5000,
         });
       }*/
    })
  }

  async getCandidateData() {
    this.candidateData = []
    // let CandidateList: any = await this.CRUDService.getAllData(
    //   "CandidateProfile"
    // ).toPromise();
    // let BusinessPostCandidates: any = await this.CRUDService.getAllData(
    //   "BusinessPostCandidates"
    // ).toPromise();
    const { data } = await this.roleService.getCandidatesByRole(this.roleDetails.id).toPromise()
    let actedCandidates = data.items
    if (this.status == '1') {
      actedCandidates = actedCandidates.filter(
        (val) =>
          val.application_meta.status == '1' &&
          (val.application_meta.action_status == '1' || val.application_meta.action_status == '5')
      )
    } else if (this.status == '2') {
      actedCandidates = actedCandidates.filter(
        (val) => val.status == '2' && (val.action_status == '2' || val.action_status == '3')
      )
    } else if (this.status == '3') {
      actedCandidates = actedCandidates.filter(
        (val) => val.status == '1' && val.action_status == '6'
      )
    } else if (this.status == '4') {
      actedCandidates = actedCandidates.filter(
        (val) => val.status == '1' && val.action_status == '7'
      )
    } else {
      actedCandidates = actedCandidates.filter(
        (val) => val.status == '1' && (val.action_status == '1' || val.action_status == '5')
      )
    }
    const roleEvents = await this.roleService.getEventsForRole(this.roleDetails.id).toPromise()
    await actedCandidates.forEach(async (candidate) => {
      let candidateInfo = candidate
      candidateInfo.image_url = getDefaultProfileImage(candidateInfo)
      candidateInfo.isChecked = true
      candidateInfo.match_perc = candidate.application_meta.match_perc
      candidateInfo.eventData = candidate
      candidateInfo.personality = candidateInfo?.personality?.replaceAll(';', ', ')
      candidateInfo.businessData = roleEvents

      const matchPerc = await this.commonService.calculateMatchPercV1_1(
        candidateInfo,
        this.businessProfile,
        this.roleDetails
      )
      candidateInfo.matchPerc = matchPerc

      this.candidateData.push(candidateInfo)

      this.candidateId = candidateInfo.eventData.id
    })
    this.candidateData.map((data: any) => {
      /* if (data.image_url) {
        data.image_url = data.image_url
      } */
      if (data.status === '1') {
        data.isChecked = true
      } else {
        data.isChecked = false
      }
    })
  }

  async toggleReject(candidate: any) {
    // this.SpinnerService.show();
    let BusinessPostCandidates: any = candidate.eventData
    BusinessPostCandidates.post_id = this.roleDetails.id
    BusinessPostCandidates.user_id = candidate.user_id
    BusinessPostCandidates.round_no = 1
    BusinessPostCandidates.status = 2
    var initialState = 'reject'
    this.dialogRef = this.dialog.open(ReasonModalComponent, {
      data: { initialState },
      panelClass: 'event-details-dialog',
      width: '30vw'
    })
    this.dialogRef.afterClosed().subscribe(async (res) => {
      BusinessPostCandidates.remarks = res?.reason
      BusinessPostCandidates.action_status = 2
      //data.businessPostCandidate.remarks = res.reason;
      let response = await this.commonService.updateRecord(
        'BusinessPostCandidates',
        BusinessPostCandidates
      )
      if (response.success == true || response.success == 'true') {
        this.toastr.success('Candidate rejected')
      }
    })
  }
  viewCalendarModal() {
    const initialState: any = { data: this.roleDetails, type: 'role' }
    // const modalConfig = {
    //   animated: true,
    //   keyboard: true,
    //   backdrop: true,
    //   ignoreBackdropClick: false,
    // };
    this.bsModalRef = this.bsModalService.show(ScheduleCalendarComponent, {
      ariaLabelledBy: 'modal-basic-title',
      class: 'event-modal',
      backdrop: 'static',
      initialState
    })
  }
  openViewProfile(data) {
    const initialState: any = { data: data, type: 'view-candidate' }
    this.bsModalRef = this.bsModalService.show(ViewCandidatesComponent, {
      ariaLabelledBy: 'modal-basic-title',
      class: 'modal-height modal-lg view-candidates-modal',
      backdrop: 'static',
      initialState
    })
  }
  viewSchedule(post) {
    post.businessData[0].startDate = post.eventData['slot_start_time']
      ? moment(post.eventData['slot_start_time']).format('YYYY-MM-DD hh:mm:ss')
      : ''
    post.businessData[0].endDate = post.eventData['slot_end_time']
      ? moment(post.eventData['slot_end_time']).format('YYYY-MM-DD')
      : ''
    const json = {
      title: post.businessData[0].title,
      extendedProps: post.businessData[0]
    }
    let dialogRef2: any = this.dialog.open(EventDetailComponent, {
      panelClass: 'event-details-dialog',
      width: '80vh',
      data: {
        dateSlots: json
      }
    })
    dialogRef2.afterClosed().subscribe((result: string) => {
      dialogRef2 = Object.create(null)
      // this.refresh.next();
    })
  }

  openNextSchedule(post) {
    this.dialogRef3 = this.dialog.open(CandidatesNextRoundComponent, {
      panelClass: 'event-details-dialog',
      width: '80vh',
      data: { roleId: post?.id }
    })
    this.dialogRef3.afterClosed().subscribe(async (res) => {
      // const startDateTime = `${
      //   res?.msg?.slotDate.split("T")[0]
      // } ${res?.msg?.slotTime?.substr(0, 2)}:${res?.msg?.slotTime?.substr(
      //   2,
      //   4
      // )}:00`;
      // let enddate = new Date(startDateTime);
      // const endTime = new Date(enddate.getTime() + (30 * 60000));
      // const endDateTime = `${res?.msg?.slotDate} ${endTime.getHours()}:${endTime.getMinutes()}:00`
      // const slotStartTime = [];
      // slotStartTime.push(Number(res?.msg?.slotTime.slice(0, 2)));
      // slotStartTime.push(Number(res?.msg?.slotTime.slice(2)));
      // let date = new Date(res?.msg?.slotDate);
      // date.setHours(slotStartTime[0], slotStartTime[1]);
      let BusinessPostCandidates: any = {}
      BusinessPostCandidates.remarks = res?.msg?.remarks
      BusinessPostCandidates.round_no = res?.msg?.roundNo
      // BusinessPostCandidates.slot_start_time = startDateTime;
      BusinessPostCandidates.id = this.candidateId
      BusinessPostCandidates.meeting_id = null
      BusinessPostCandidates.status = 1
      BusinessPostCandidates.action_status = 6
      let response = await this.commonService.saveRecord(
        'BusinessPostCandidates',
        BusinessPostCandidates
      )

      if (response.success == true || response.success == 'true') {
        this.toastr.success('Meeting with the candidate scheduled')
        const userDetails = await this.CRUDService.getDataByField(
          'Users',
          'id',
          post?.user_id
        ).toPromise()
        const body = {
          name: post?.name,
          business_name: this.businessProfile?.name,
          role_title: post?.title,
          round_name: res?.msg?.roundName,
          to: userDetails[0]?.email_id,
          host: environment.appRoot
        }
        await this.CRUDService.nextInterviewRoundMail(body).toPromise()
        await this.getCandidateData()
      } else {
        this.toastr.error('Something went wrong, please try again later')
      }
    })
  }

  showNameFlag(data) {
    let showFlag: boolean
    if (data.eventData.action_status === 4) {
      this.showNamePhotoFlag = true
      showFlag = true
    }
    return showFlag
  }

  statusFn(data) {
    let result: any
    if (data?.status == 1 && data?.action_status == 1) {
      result = 'Shortlisted'
    } else if (data?.status == 2 && data?.action_status == 2) {
      result = 'Rejected (By Client)'
    } else if (data?.status == 2 && data?.action_status == 3) {
      result = 'Not Interested'
    } else if (data?.status == 2 && data?.action_status == 4) {
      result = 'Meeting Scheduled'
    } else if (data?.status == 2 && data?.action_status == 5) {
      result = 'Meeting Complete'
    } else if (data?.status == 1 && data?.action_status == 5) {
      result = 'Connection Accepted'
    } else if (data?.status == 1 && data?.action_status == 6) {
      result = 'Interviewing'
    } else if (data?.status == 1 && data?.action_status == 7) {
      result = 'Offered'
    } else if (data?.status == 1 && data?.action_status == 8) {
      result = 'Hired'
    }
    return result
  }

  cultureFn(data) {
    let dataArray = getArrayFromString(data)
    return dataArray
  }

  skillsFn(data) {
    let dataArray = getArrayFromString(data)
    return dataArray
  }
}
