<div class="" style="margin-top: 30px !important">
  <div fxLayout="row wrap" class="body">
    <div
      class="row contents text-center mx-auto col-sm-12 col-md-12 col-lg-12"
      style="justify-content: center"
    >
      <div class="row col-12">
        <div class="col-12 text-center">
          <div class="loginBoxHeader text-center">
            <p style="margin-bottom: 0">
              <span style="font-size: 27px">Email</span>
            </p>
          </div>
        </div>

        <div class="col-12 contents">
          <form [formGroup]="resendLimkEmailForm" noValidate class="mt-4" autocomplete="off">
            <mat-form-field
              class="example-full-width"
              appearance="fill"
              [ngClass]="{ 'is-invalid': checkEmailFlag && !resendLimkEmailForm.valid }"
            >
              <input
                type="text"
                matInput
                placeholder="Email"
                formControlName="email"
                (input)="checkEmailFlag = true"
              />
            </mat-form-field>
            <div class="" *ngIf="checkEmailFlag && !resendLimkEmailForm.valid">
              <span
                *ngIf="
                  resendLimkEmailForm.controls.email.errors?.pattern ||
                  resendLimkEmailForm.controls.email.errors?.maxLength
                "
                class="error-class"
                class="error-class"
                >This email is invalid</span
              >
            </div>
            <div *ngIf="showError" class="error-class" class="error-class">
              Email address not registered
            </div>
            <button
              mat-raised-button
              class="send-btn mt-3"
              [disabled]="!resendLimkEmailForm.valid"
              (click)="onSubmit()"
            >
              Send
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
