import { MediaMatcher } from '@angular/cdk/layout'
import { ActivatedRoute, Router } from '@angular/router'
import { ChangeDetectorRef, Component, OnDestroy, ViewChild } from '@angular/core'
import { MenuItems } from '../../shared/menu-items/menu-items'

import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar'
import { AuthService as ApiAuthService } from 'src/app/shared/auth/auth.service'
import { AuthService } from '@auth0/auth0-angular'
import { UserService } from 'src/app/shared/services/user.service'
import { CandidateService } from 'src/app/shared/services/candidate.service'
import { BusinessService } from 'src/app/shared/services/business.service'
import { USER_TYPE } from 'src/app/core/models/user'
import { CommonService } from 'src/app/common.service'
import { NgxSpinnerService } from 'ngx-spinner'
import { ToastrService } from 'ngx-toastr'
import { MatDialog, MatDialogRef } from '@angular/material/dialog'
import { ResendLinkEmailComponent } from 'src/app/authentication/resend-link-email/resend-link-email.component'
import { NullTemplateVisitor } from '@angular/compiler'
import { getQueryVariable } from 'src/app/common-utils'

/** @title Responsive sidenav */
@Component({
  selector: 'app-full-layout',
  templateUrl: 'full.component.html',
  styleUrls: []
})
export class FullComponent implements OnDestroy {
  @ViewChild('snav') snav: any
  mobileQuery: MediaQueryList

  dir = 'ltr'
  dark = false
  minisidebar = false
  boxed = false
  horizontal = false

  green = false
  blue = false
  danger = false
  showHide = false
  url = ''
  sidebarOpened = false
  status = false
  isEmailVerified = true
  dialogRef: MatDialogRef<ResendLinkEmailComponent> = Object.create(NullTemplateVisitor)

  public showSearch = false
  public config: PerfectScrollbarConfigInterface = {}
  private _mobileQueryListener: () => void

  constructor(
    public router: Router,
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    private activatedRoute: ActivatedRoute,
    public menuItems: MenuItems,
    private dialog: MatDialog,
    private auth: AuthService,
    private authService: ApiAuthService,
    private userService: UserService,
    private candidateService: CandidateService,
    private businessService: BusinessService,
    private commonService: CommonService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService
  ) {
    this.mobileQuery = media.matchMedia('(min-width: 1100px)')
    this._mobileQueryListener = () => changeDetectorRef.detectChanges()
    this.mobileQuery.addListener(this._mobileQueryListener)
    this.dark = false
  }
  ngOnInit() {
    console.log('FullComponent ngOnInit')
    this.checkAuth()
  }

  async checkAuth() {
    // check authentication and parse successfull callback
    try {
      console.log('checkAuth')
      this.auth.isAuthenticated$.subscribe((isAuthenticated) => {
        if (!isAuthenticated) {
          const query = window.location.search
          console.log('query', query)
          const shouldParseResult = query.includes('code=') && query.includes('state=')
          if (shouldParseResult) {
            console.log('> Parsing redirect')
            try {
              this.auth.handleRedirectCallback().subscribe((result) => {
                console.log('Logged in!')
              })
            } catch (err) {
              console.log('Error parsing redirect:', err)
            }
            // window.history.replaceState({}, document.title, "/");
          } else {
            // when not authenticated we want user to redirect to login page
            // this.authService.loginWithRedirect({
            //   authorizationParams: { redirect_uri: window.location.origin },
            // });
            const error = getQueryVariable('error')
            const error_description = getQueryVariable('error_description')
            if (error) {
              this.handleErrors(error, error_description)
              return
            } else {
              // in unknown case redirect to login option page
              this.router.navigate(['/authentication/login-option'])
            }
          }
        } else {
          this.handlePostLoginAndNavigation()
        }
      })
    } catch (error) {
      console.error('error', error)
    }
  }

  async handleErrors(error: any, error_description: any) {
    // check email not verified error
    this.auth.logout({ logoutParams: { returnTo: undefined } })
    if (error === 'access_denied') {
      this.isEmailVerified = false
      this.toastr.error(error_description || 'Please verify your email to continue')
    }
  }

  resendLink() {
    this.dialogRef = this.dialog.open(ResendLinkEmailComponent, {})
  }

  async handlePostLoginAndNavigation() {
    // handle post login actions and navigate to dashboard

    this.auth.user$.subscribe(async (user) => {
      // TODO: handle user.email_verified === false case

      // check and sync auth0 user with backend user in background
      this.checkAndSyncAuth0UserAndBackendUser(user)
      // handle fetch user data from backend
      const userType = user?.app_user_type as USER_TYPE
      const userRes = await this.userService.getSelf().toPromise()
      const userInfo = userRes?.data
      this.checkAndSyncEmailVerifiedStatus(user, userInfo)
      // handle pendo initialize
      // pendo.initialize({
      //   visitor: {
      //     id: this.userInfo.email,
      //     name: `${this.userInfo.first_name} ${this.userInfo.last_name}`,
      //     email: this.userInfo.email,
      //     role: role,
      //   },
      //   account: { id: environment.pendoAccountId, name: "Upplft" },
      // });
      localStorage.setItem(
        'currentAdmin',
        JSON.stringify({
          user: userInfo,
          tokens: {
            access: { expires: '', token: '' },
            refresh: { expires: '', token: '' }
          }
        })
      )
      this.authService.currentAdminSubject.next({
        user: userInfo,
        tokens: {
          access: { expires: '', token: '' },
          refresh: { expires: '', token: '' }
        }
      })

      sessionStorage.setItem('USER_INFO', JSON.stringify(userInfo))
      localStorage.setItem('USER_INFO', JSON.stringify(userInfo))

      if (userType === USER_TYPE.CANDIDATE) {
        localStorage.setItem('role', 'candidate')
        // save candidate profile
        const candidateRes = await this.candidateService.getSelf().toPromise()
        const candidateProfile = candidateRes?.data
        sessionStorage.setItem('CANDIDATE_PROFILE', JSON.stringify(candidateProfile))
        localStorage.setItem('CANDIDATE_PROFILE', JSON.stringify(candidateProfile))
      } else if (userType === USER_TYPE.BUSINESS) {
        localStorage.setItem('role', 'business')

        // save business profile
        const businessRes = await this.businessService.getBusiness(userInfo?.group_id).toPromise()
        const businessProfile = businessRes?.data
        sessionStorage.setItem('BUSINESS_PROFILE', JSON.stringify(businessProfile))
        localStorage.setItem('BUSINESS_PROFILE', JSON.stringify(businessProfile))
      } else if (userType === USER_TYPE.ADMIN) {
        localStorage.setItem('role', 'admin')
      } else if (userType === USER_TYPE.PARTNER) {
        localStorage.setItem('role', 'partner')
      }

      // redirect to onboarding for candidate
      const is_auth0_fields_synced_local = !!localStorage.getItem('is_auth0_fields_synced')
      const is_auth0_fields_synced =
        user?.is_auth0_fields_synced === true
          ? true
          : is_auth0_fields_synced_local == true
            ? true
            : false
      if (userType === USER_TYPE.CANDIDATE && !is_auth0_fields_synced) {
        localStorage.setItem('is_auth0_fields_synced', 'true')
        this.router.navigate(['/profile-builders/candidate-profile'])
      } else {
        this.redirect(userType)
      }

      console.log('user', user)
    })
  }

  /**
   * Sync email verified status between auth0 and backend user
   * @param auth0User
   * @param user
   * @returns
   */
  async checkAndSyncEmailVerifiedStatus(auth0User: any, user: any) {
    if (auth0User?.email_verified === user?.is_email_verified) return

    const is_email_verified =
      auth0User?.email_verified === true ? true : user?.is_email_verified === true ? true : false
    this.userService
      .updateSelf({ ...user, is_email_verified: is_email_verified })
      .toPromise()
      .then((res) => {
        console.log('updated email verified status', res)
      })
      .catch((error) => {
        console.error('error updating email verified status', error)
      })
  }

  async checkAndSyncAuth0UserAndBackendUser(auth0User: any) {
    // Sync auth0 user with backend user
    const is_auth0_fields_synced = !!auth0User?.is_auth0_fields_synced
    if (!is_auth0_fields_synced) {
      // call the sync api in background
      this.userService
        .syncSelfWithAuth0()
        .toPromise()
        .then((res) => {
          console.log('synced with auth0', res)
        })
        .catch((error) => {
          console.error('error syncing user with auth0', error)
        })
    }
  }

  redirect(loginType: any) {
    const redirectTo = JSON.parse(sessionStorage.getItem('REDIRECT'))

    if (loginType == USER_TYPE.CANDIDATE) {
      this.commonService.changeData('candidate!!!!')
      if (redirectTo) {
        this.router.navigate([redirectTo?.url])
      } else {
        this.router.navigate(['/dashboards/candidate'])
      }
    } else if (loginType == USER_TYPE.BUSINESS) {
      this.commonService.changeData('business!!!!')
      this.router.navigate(['/dashboards/business'])
      if (redirectTo) {
        sessionStorage.removeItem('REDIRECT')
      }
    } else if (loginType == USER_TYPE.ADMIN) {
      this.commonService.changeData('admin!!!!')
      this.router.navigate(['/dashboards/admin'])
      if (redirectTo) {
        sessionStorage.removeItem('REDIRECT')
      }
    } else if (loginType == USER_TYPE.PARTNER) {
      this.commonService.changeData('talentPartner!!!!')
      this.router.navigate(['/dashboards/partner'])
      if (redirectTo) {
        sessionStorage.removeItem('REDIRECT')
      }
    } else {
      this.router.navigate(['/authentication/login-option'])
      if (redirectTo) {
        sessionStorage.removeItem('REDIRECT')
      }
    }
  }

  ngOnDestroy(): void {
    // tslint:disable-next-line: deprecation
    this.mobileQuery.removeListener(this._mobileQueryListener)
  }

  clickEvent(): void {
    this.status = !this.status
  }

  darkClick() {
    const body = document.getElementsByTagName('body')[0]
    body.classList.toggle('dark')
  }
  close(reason) {
    this.snav.toggle()
    this.sidebarOpened = false
  }
}
