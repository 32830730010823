import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal'
import { CRUDService } from 'src/app/crud.service'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { TimeSlots, TimeSlotsList } from '../../../scheduler/schedule-calendar/event-utils'
import { RoleService } from 'src/app/shared/services/role.service'

@Component({
  selector: 'app-candidates-next-round',
  templateUrl: './candidates-next-round.component.html',
  styleUrls: ['./candidates-next-round.component.scss']
})
export class CandidatesNextRoundComponent implements OnInit {
  nextRoundForm: FormGroup
  remarks: any
  title: any
  bsModalRef: BsModalRef
  showInput: boolean = false
  roundDetails: any
  roundList: any
  slotDate: any
  @Output() messageEvent = new EventEmitter<string>()
  selectedStartTime: any
  timeSlotsList: TimeSlots[]
  selectedEndTime: any
  submitted: boolean = false
  minDate = new Date()

  constructor(
    public dialogRef: MatDialogRef<CandidatesNextRoundComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private _bsModalRef: BsModalRef,
    public options: ModalOptions,
    private CRUDService: CRUDService,
    private roleService: RoleService,
    private fb: FormBuilder
  ) {
    this.timeSlotsList = TimeSlotsList
  }
  async ngOnInit(): Promise<void> {
    this.nextRoundForm = this.fb.group({
      roundNo: ['', [Validators.required]],
      // slotDate: ["", [Validators.required]],
      remarks: ['']
      // startTime: ["", [Validators.required]],
    })
    const data = await this.roleService.getAssessmentRoundsForRole(this.data?.roleId).toPromise()
    this.roundList = data.data.items.filter((value) => {
      return value.status == '1'
    })
    this.roundList.sort((a, b) => {
      return Number(a.round_no) - Number(b.round_no)
    })
  }

  changeRound() {}

  closeModal() {
    this._bsModalRef.hide()
    this.dialogRef.close()
    // this.bsModalService.hide();
  }
  submit() {
    this.submitted = true
    if (this.nextRoundForm.valid) {
      let msg: any
      msg = {
        remarks: this.nextRoundForm.controls.remarks.value,
        roundNo: this.nextRoundForm.controls.roundNo.value,
        roundName: this.roundList.find(
          (e) => e.round_no === this.nextRoundForm.controls.roundNo.value
        )?.round_name
        // slotDate: new Date(
        //   this.nextRoundForm.controls.slotDate.value
        // ).toISOString(),
        // slotTime: this.nextRoundForm.controls.startTime.value,
      }
      this.messageEvent.emit(msg)
      this.dialogRef.close({ msg })
      this._bsModalRef.hide()
    }
  }
}
