<div class="postsBox profile-overview col-12">
  <div class="row d-flex">
    <div class="profile-div pl-2">
      <img class="profile-img" src="{{ profileImage }}" />
    </div>
    <div class="pl-2" style="display: grid">
      <label class="profile-label">{{ candidateProfile?.name }}</label>
      <button class="skip-btn" (click)="openViewProfile()" style="color: #0c2149">
        View Profile
      </button>
      <!-- <button class="skip-btn" (click)="openEditProfile()" style="color:#0c2149; margin-top: 0.5rem;">Edit
                Profile</button> -->
    </div>
  </div>
  <div class="mt-2">
    <mat-label class="roles-available">{{
      open_to_work_check ? 'Available for work' : 'Not available for work'
    }}</mat-label>

    <mat-slide-toggle [(ngModel)]="open_to_work_check" (change)="changeWorkStatus($event)">
    </mat-slide-toggle>
  </div>
  <div></div>
  <div class="mt-2">
    <label class="looking-label"
      >Profile status -
      {{ candidateProfile?.profile_completion ? candidateProfile?.profile_completion : 0 }}%</label
    >

    <mat-progress-bar
      class="mt-1"
      [ngClass]="{
        'yellow-class': candidateProfile?.profile_completion < 25,
        'blue-class':
          candidateProfile?.profile_completion >= 25 && candidateProfile?.profile_completion < 50,
        'orange-class':
          candidateProfile?.profile_completion >= 50 && candidateProfile?.profile_completion < 75,
        'pink-class':
          candidateProfile?.profile_completion >= 75 && candidateProfile?.profile_completion <= 100
      }"
      mode="determinate"
      [value]="candidateProfile?.profile_completion"
      (mouseenter)="isHidden = !isHidden"
      (mouseleave)="isHidden = !isHidden"
    ></mat-progress-bar>
    <ng-container *ngIf="isHidden && candidateProfile?.profile_completion < 75">
      <div style="color: blue">
        <br />
        To increase your chances of being invited fill in as many as you can
      </div>
    </ng-container>
  </div>
  <div class="mt-2">
    <mat-label class="roles-available">I'm looking for</mat-label>
    <!-- <label class="looking-label">I'm looking for</label> -->
    <!-- <mat-form-field appearance="fill" class="mt-1"> -->
    <mat-form-field appearance="fill" class="mt-1">
      <mat-select
        [(ngModel)]="looking_for"
        disableOptionCentering
        panelClass="myPanelClass"
        #matSelect
        name="business"
        placeholder="Select option"
        (selectionChange)="changelookingFor()"
        multiple
      >
        <!-- <mat-select-trigger>
        <span *ngIf="looking_for?.length > 1" class="example-additional-selection">
        {{looking_for[0].substr(0,20)}}... and {{looking_for?.length-1}} more
        </span>
        <span *ngIf="looking_for?.length < 2" class="example-additional-selection">
          {{looking_for ? looking_for[0] : ''}}
        </span>
    </mat-select-trigger> -->

        <div>
          <mat-option *ngFor="let data of roleArray" [value]="data">
            {{ data }}
          </mat-option>
        </div>
      </mat-select>
    </mat-form-field>
    <!-- <mat-select [(ngModel)]="candidateProfile.work_type" name="business" placeholder="Select option">
                <mat-option *ngFor="let role of roleArray" [value]="role.description">
                    {{role?.description}}
                </mat-option>
            </mat-select> -->
    <!-- </mat-form-field> -->
  </div>
  <!-- <div class="details-div mt-3">
        <mat-chip-list aria-label="Culture Selection">
            <mat-chip *ngFor="let culture of looking_for; let i=index" class="chip-looking-for">
                {{culture}}
            </mat-chip>
        </mat-chip-list>
    </div> -->
  <!-- <div class="mt-2">
        <label class="looking-label">Profile status - 50%</label>
        <mat-progress-bar class="mt-1" mode="determinate" value="50"></mat-progress-bar>
    </div> -->
</div>
