import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { environment } from 'src/environments/environment'
import { getSessionValue } from 'src/app/common-utils'
import { CommonService } from 'src/app/common.service'
import { UserService } from 'src/app/shared/services/user.service'
import { BusinessService } from 'src/app/shared/services/business.service'
import { ToastrService } from 'ngx-toastr'
import { ValuesMapService } from 'src/app/shared/services/values-map.service'

@Component({
  selector: 'app-business-account',
  templateUrl: './business-account.component.html',
  styleUrls: ['./business-account.component.scss']
})
export class BusinessAccountComponent implements OnInit {
  businessProfileForm: FormGroup
  editFormFlag: boolean = false
  previousPath: any
  notificationIcon: boolean = false
  userInfo: any
  businessInfo: any
  userData: any
  submitted: boolean = false
  locationList: string[]
  initialFormValues: any

  constructor(
    private fb: FormBuilder,
    private userService: UserService,
    private businessService: BusinessService,
    private toastr: ToastrService,
    private valuesMapService: ValuesMapService
  ) {}

  ngOnInit(): void {
    this.previousPath = '/dashboards/business-account'

    this.businessProfileForm = this.fb.group({
      legalEntity: ['', Validators.required],
      companyAddress: ['', Validators.required],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: [
        '',
        [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,3}$')]
      ],
      phonenumber: ['', Validators.required],
      roleInBusiness: ['', Validators.required],
      typeOfBusiness: ['', Validators.required],
      ABN: ['', Validators.required],
      website: ['', Validators.required]
    })

    this.notificationIcon = environment.notificationShow
    this.valuesMapService.getFilteredValuesMap(100, 0, '', 'LOCATION').subscribe(({ data }) => {
      const sortedItem = data.items.sort((a, b) => a.display_order - b.display_order)
      const names = sortedItem.map(function (item) {
        return item.description
      })
      this.locationList = names
    })
    this.patchFormValue()
    this.initialFormValues = this.businessProfileForm.value
  }

  patchFormValue() {
    this.userInfo = getSessionValue('USER_INFO')
    this.businessInfo = getSessionValue('BUSINESS_PROFILE')

    const data = {
      legalEntity: this.businessInfo?.name,
      companyAddress: this.businessInfo?.business_address,
      firstName: this.userInfo?.first_name,
      lastName: this.userInfo?.last_name,
      email: this.businessInfo?.email,
      phonenumber: this.businessInfo?.phone_number,
      roleInBusiness: this.userInfo?.title,
      typeOfBusiness: this.businessInfo?.business_type,
      ABN: this.businessInfo?.business_abn,
      website: this.businessInfo?.website
    }

    this.businessProfileForm.patchValue(data)
  }

  editBusinessForm() {
    this.editFormFlag = true
    // this.businessProfileForm.controls?.dob?.setValue(new Date(1996, 10, 13));
  }

  getChangedValues(currentValues, initialValues) {
    const changes = {}
    Object.keys(currentValues).forEach((key) => {
      if (currentValues[key] !== initialValues[key]) {
        changes[key] = currentValues[key]
      }
    })
    return changes
  }

  get f() {
    return this.businessProfileForm.controls
  }

  displayFormErrors() {
    Object.keys(this.businessProfileForm.controls).forEach((key) => {
      const controlErrors = this.businessProfileForm.get(key).errors
      if (controlErrors) {
        Object.keys(controlErrors).forEach((errorKey) => {
          this.toastr.error(`${key} can not be empty!`)
        })
      }
    })
  }

  async submit() {
    this.submitted = true
    if (this.businessProfileForm.valid) {
      const changedUserValues = this.getChangedValues(
        this.businessProfileForm.value,
        this.initialFormValues
      )
      const changedBusinessValues = this.getChangedValues(
        this.businessProfileForm.value,
        this.initialFormValues
      )

      if (
        Object.keys(changedUserValues).length === 0 &&
        Object.keys(changedBusinessValues).length === 0
      ) {
        this.toastr.info('No changes detected.')
        return
      }

      this.userInfo.email_id = this.businessProfileForm.value.email
      this.userInfo.first_name = this.businessProfileForm.value.firstName
      this.userInfo.last_name = this.businessProfileForm.value.lastName
      this.userInfo.title = this.businessProfileForm.value.roleInBusiness

      this.businessInfo.business_address = this.businessProfileForm.value.companyAddress
      this.businessInfo.name = this.businessProfileForm.value.legalEntity
      this.businessInfo.email = this.businessProfileForm.value.email
      this.businessInfo.phone_number = this.businessProfileForm.value.phone_number
      this.businessInfo.business_abn = this.businessProfileForm.value.ABN
      this.businessInfo.website = this.businessProfileForm.value.website
      this.businessInfo.business_type = this.businessProfileForm.value.typeOfBusiness

      try {
        const [updateUserResponse, updateBusinessResponse] = await Promise.all([
          this.userService.updateSelf(this.userInfo).toPromise(),
          this.businessService.updateSelf(this.businessInfo).toPromise()
        ])

        if (updateUserResponse.success) {
          sessionStorage.setItem('USER_INFO', JSON.stringify(updateUserResponse.data))
          this.toastr.success('User Info Updated.')
        } else {
          this.toastr.error("User info couldn't be updated.")
        }

        if (updateBusinessResponse.success) {
          sessionStorage.setItem('BUSINESS_PROFILE', JSON.stringify(updateBusinessResponse.data))
        } else {
          this.toastr.error("Business info couldn't be updated.")
        }

        this.editFormFlag =
          updateUserResponse.success && updateBusinessResponse.success ? false : this.editFormFlag
      } catch (error) {
        this.toastr.error('An error occurred while updating.')
      }
    } else {
      this.displayFormErrors()
    }
  }
}
