<div class="ease-in">
  <div class="demo-app" *ngIf="!bookSlotClicked && isSlotsAvailable">
    <div class="demo-app-sidebar" style="display: flex; align-items: center">
      <div class="demo-app-sidebar-section">
        <div *ngIf="calendarView">
          <h4 class="m-t-0 p-l-5">Select the date to view Availability</h4>
          <div (click)="$event.stopPropagation()">
            <mat-calendar
              #calendar
              (selectedChange)="select($event, calendar)"
              [dateClass]="isSelected"
            >
            </mat-calendar>
          </div>
        </div>
        <div>
          <div fxLayout="row wrap">
            <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
              <div class="m-t-0 head d-flex" style="flex-wrap: wrap !important">
                Interview Schedule with&nbsp;
                <div style="font-weight: bold; color: #ec3b83">{{ businessInfo.name }}</div>
              </div>
            </div>
          </div>

          <div fxLayout="row wrap">
            <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
              <table>
                <tbody>
                  <tr>
                    <td class="details-head">
                      Role : <span style="color: #ec3b83">{{ jobInfo.title }}</span>
                    </td>
                  </tr>
                  <tr>
                    <td class="details-head">
                      Interview Round :
                      <span style="color: #ec3b83">{{ businessPostCandidateInfo?.round_no }}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="demo-app-main" style="width: 70%">
      <div fxLayout="row-reverse" fxLayoutGap="30px" (click)="closeModal()" class="cursor-pointer">
        <mat-icon aria-hidden="false" aria-label="close">close</mat-icon>
      </div>
      <div fxLayout="row wrap">
        <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
          <h4 class="m-t-0 p-l-5">
            Available Slots Between {{ fromDate | date: 'longDate' }} and
            {{ toDate | date: 'longDate' }}
          </h4>
        </div>
        <div style="display: flex">
          <button
            mat-raised-button
            class="primaryButton"
            [disabled]="currentWeek"
            title="Current Week"
            (click)="currentWeekSlots()"
          >
            <mat-icon>first_page</mat-icon>
          </button>
          <button
            mat-raised-button
            class="primaryButton"
            [disabled]="currentWeek"
            title="Previous Week"
            (click)="previous()"
          >
            <mat-icon>arrow_backward</mat-icon>
          </button>
          <button mat-raised-button class="primaryButton" title="Next Week" (click)="next()">
            <mat-icon>arrow_forward</mat-icon>
          </button>
        </div>
      </div>
      <div [style.margin-top.em]="1" *ngIf="displaySlots.length !== 0">
        <mat-accordion>
          <mat-expansion-panel
            id="accordion"
            *ngFor="let date of displaySlots; first as isFirst"
            [expanded]="isFirst"
            hideToggle
          >
            <mat-expansion-panel-header>
              <mat-panel-title>
                {{ date.date | date: 'fullDate' }} - {{ date.slots.length }} slots available
              </mat-panel-title>
            </mat-expansion-panel-header>
            <ul class="scheduleSlots">
              <div *ngFor="let slot of date.slots; let index = index" class="uldiv">
                <div
                  [ngClass]="{
                    primaryButton:
                      slot.viewValue == selectedSlot.viewValue &&
                      this.selectedDate == date.date &&
                      visibleIndex != index,
                    secondaryButton:
                      slot.viewValue != selectedSlot.viewValue || this.selectedDate != date.date,
                    greyButton:
                      slot.viewValue == selectedSlot.viewValue &&
                      this.selectedDate == date.date &&
                      visibleIndex === index
                  }"
                  (click)="dateSlotSelected(slot, date.date, index)"
                >
                  {{ slot.viewValue }}
                </div>
                <div *ngIf="visibleIndex === index && this.selectedDate == date.date">
                  <button
                    mat-raised-button
                    class="book-button"
                    [disabled]="eventForm.invalid"
                    aria-label="BOOK"
                    (click)="bookSlot(date.date, slot)"
                  >
                    Book {{ slot.viewValue }}
                  </button>
                </div>
              </div>
            </ul>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
      <div *ngIf="displaySlots.length === 0">
        <h4 class="m-t-5 p-l-5">
          Interview Slots not available for the job currently. You will be notified when the slots
          are updated
        </h4>
      </div>
    </div>
  </div>
  <div *ngIf="bookSlotClicked && isSlotsAvailable">
    <div class="demo-app">
      <div class="demo-app-sidebar">
        <div class="demo-app-sidebar-section">
          <div>
            <div fxLayout="row wrap">
              <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
                <div (click)="backTo()" class="cursor-pointer">
                  <mat-icon aria-hidden="false" aria-label="nav_back">arrow_back</mat-icon>
                </div>
              </div>
            </div>
            <div fxLayout="row wrap" style="margin-top: 50%">
              <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100" class="left-detail">
                <!-- <div class="user-name">UserName</div> -->
                <div class="m-t-0 p-l-5 head d-flex">
                  Session with
                  <div style="font-weight: bold; color: #ec3b83; margin-left: 5px">
                    {{ businessInfo.name }}
                  </div>
                </div>
                <div class="timer">
                  <mat-icon class="mr-2" aria-hidden="false" aria-label="timer">schedule</mat-icon>
                  30 min
                </div>
                <div class="event">
                  <!-- {{selectedStartTime}} -->
                  <mat-icon aria-hidden="false" aria-label="event" class="mr-2">event</mat-icon>
                  {{ selectedSlot.viewValue }},
                  {{ selectedDate | date: 'fullDate' }}
                </div>
                <div class="public">
                  <mat-icon aria-hidden="false" aria-label="public" class="mr-2">public</mat-icon>
                  {{ jobInfo.location }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="demo-app-main" style="width: 50%">
        <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100" style="padding: 2em">
          <div fxLayout="row wrap">
            <div class="user-name">Select Meeting Type</div>
          </div>

          <div fxLayout="row wrap">
            <div
              [ngClass]="selectedMeetingType == 'teams' ? 'primaryButton' : 'secondaryButton'"
              (click)="selectedMeetingType = 'teams'"
            >
              Teams
            </div>
            <div
              [ngClass]="selectedMeetingType == 'zoom' ? 'primaryButton' : 'secondaryButton'"
              (click)="selectedMeetingType = 'zoom'"
            >
              Zoom
            </div>
            <div
              [ngClass]="selectedMeetingType == 'meet' ? 'primaryButton' : 'secondaryButton'"
              (click)="selectedMeetingType = 'meet'"
            >
              Meet
            </div>
          </div>

          <br />
          <mat-divider></mat-divider>
          <div fxLayout="row wrap" class="buttonPanel">
            <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100" fxLayoutGap="20px">
              <button
                mat-raised-button
                (click)="cancel()"
                [disabled]="eventForm.invalid"
                aria-label="CANCEL"
                style="color: white; background: #282828"
              >
                Cancel
              </button>
              <button
                mat-raised-button
                style="color: white; background: #ec3b83"
                (click)="book()"
                [disabled]="eventForm.invalid"
                aria-label="BOOK"
              >
                Book
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="!isSlotsAvailable" class="no-slots">
    <button mat-icon-button class="close-button" (click)="closeModal()">
      <i class="fa fa-times"></i>
    </button>
    <div class="description">
      <p class="desc-text">Looks like there aren’t any times available yet to meet.</p>
      <p class="desc-text">
        Check back soon or keep an eye out for a notification for when slots are made available.
      </p>
    </div>
  </div>
</div>
<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
  <p style="color: white">Please Wait.</p>
</ngx-spinner>
