import { Component, Inject, OnInit, ViewChild } from '@angular/core'
import { MatPaginator } from '@angular/material/paginator'
import { MatSort } from '@angular/material/sort'
import { MatTableDataSource } from '@angular/material/table'
import { CommonService } from 'src/app/common.service'
import { getArrayFromString } from 'src/app/common-utils'
import { NgxSpinnerService } from 'ngx-spinner'
import { BsModalRef } from 'ngx-bootstrap/modal'
import { ReasonModalComponent } from '../reason-modal/reason-modal.component'
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { ToastrService } from 'ngx-toastr'
import { SkillsetService } from 'src/app/shared/services/skillset.service'
import { ValuesMapService } from 'src/app/shared/services/values-map.service'
@Component({
  selector: 'app-candidates',
  templateUrl: './candidates.component.html',
  styleUrls: ['./candidates.component.scss']
})
export class CandidatesComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator
  @ViewChild(MatSort) sort: MatSort
  candidatesData: any[] = []
  displayedColumns: string[] = [
    'id',
    'name',
    'email_id',
    'primary_phone',
    'match_perc',
    'profile_completion',
    'action'
  ]
  dataSource: MatTableDataSource<any>
  candidatesProfileData: any = []
  candidatesType: string
  businessProfile: any
  roleDetails: any
  skillsArray: any
  businessPostCandidates: any = []
  bsModalRef: BsModalRef
  constructor(
    @Inject(MAT_DIALOG_DATA) private dialogData: any,
    private commonService: CommonService,
    private SpinnerService: NgxSpinnerService,
    private dialogRef: MatDialogRef<CandidatesComponent>,
    private dialogRef2: MatDialogRef<ReasonModalComponent>,
    public dialog: MatDialog,
    private toastr: ToastrService,
    private skillsetService: SkillsetService,
    private valuesMapService: ValuesMapService
  ) {}
  async ngOnInit() {
    this.businessPostCandidates = this.dialogData?.businessPostCandidates
    this.candidatesType = this.dialogData?.candidate
    this.businessProfile = this.dialogData?.businessProfile
    this.roleDetails = this.dialogData?.roleDetails
    await this.loadData()
  }
  public doFilter = (value: string) => {
    // this.rolesData.filter = value.trim().toLocaleLowerCase();
  }
  async loadData() {
    this.SpinnerService.show()
    const { data: skillsData } = await this.skillsetService
      .getFilteredSkillset(100, 0, '')
      .toPromise()
    let names = skillsData.items.map(function (item) {
      if (item['status'] == 1) {
        return item['category']
      }
    })
    names = [...new Set(names)]
    this.skillsArray = names
    this.roleDetails.skillsArray = this.getSourceList(this.roleDetails.skills, this.skillsArray)
    console.log('this.roleDetails.skillsArray', this.roleDetails)
    const { data: valuesData } = await this.valuesMapService
      .getFilteredValuesMap(50, 0, '', 'VALUES')
      .toPromise()
    this.roleDetails.cultureArray = valuesData.items
    const { data: salary_list } = await this.valuesMapService
      .getFilteredValuesMap(50, 0, '', 'SALARY')
      .toPromise()
    sessionStorage.setItem('salary_list', JSON.stringify(salary_list.items))
    const data = await this.commonService.loadCandidates(
      this.businessProfile,
      this.roleDetails,
      this.candidatesType
    )
    this.candidatesData = data
    // this.businessPostCandidates = await this.crudService
    //   .getDataByField("BusinessPostCandidates", "post_id", this.roleDetails.id)
    //   .toPromise();
    /* for (let value of data) {
      value.profileDetails = this.candidatesProfileData.find(
        (ele) => ele.userId == value.user_id
      );
      value.businessPostCandidate = this.businessPostCandidates.find((ele) => {
        return (
          ele.user_id == value.user_id && ele.post_id == this.roleDetails?.id
        );
      });
      value.email_id = this.candidatesProfileData.find(
        (ele) => ele.userId == value.user_id
      )?.email_id;
      value.primary_phone = this.candidatesProfileData.find(
        (ele) => ele.userId == value.user_id
      )?.primary_phone;
    } */
    this.dataSource = new MatTableDataSource<any>([])
    this.dataSource.data = this.candidatesData
    this.dataSource.paginator = this.paginator
    // this.sort.disableClear = true;
    this.dataSource.sort = this.sort
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'createdDate':
          return new Date(item.created_at)
        case 'profileCompletion':
          return Number(item.profile_completion)
        default:
          return item[property]
      }
    }
    this.SpinnerService.hide()
  }
  closeModal() {
    this.dialogRef.close()
  }
  async shortlistCandidate(data) {
    if (data?.businessPostCandidate) {
      data.businessPostCandidate.status = 1
      data.businessPostCandidate.action_status = 1
      data.businessPostCandidate.applied_by = 'admin'

      var initialState = 'accept'
      this.dialogRef2 = this.dialog.open(ReasonModalComponent, {
        data: { initialState },
        panelClass: 'event-details-dialog',
        width: '30vw'
      })
      this.dialogRef2.afterClosed().subscribe(async (res) => {
        data.businessPostCandidate.remarks = res.reason
        let response = await this.commonService.updateRecord(
          'BusinessPostCandidates',
          data?.businessPostCandidate
        )
        if (response.success == true || response.success == 'true') {
          this.toastr.success('Candidate shortlisted successfully')
        }
      })
    } else {
      let BusinessPostCandidates: any = {}
      BusinessPostCandidates.post_id = this.roleDetails?.id
      BusinessPostCandidates.user_id = data?.user_id
      BusinessPostCandidates.round_no = 1
      BusinessPostCandidates.status = 1
      BusinessPostCandidates.action_status = 1
      BusinessPostCandidates.match_perc = data?.match_perc
      BusinessPostCandidates.applied_by = 'admin'
      // BusinessPostCandidates.profile_completion = data?.profile_completion;
      var initialState = 'accept'
      /*  this.bsModalRef = this.bsModalService.show(ReasonModalComponent,
          { ariaLabelledBy: 'modal-basic-title', class: 'modal-md reason-modal', backdrop: 'static', initialState });
        this.bsModalRef.content.messageEvent.subscribe(async (data) => {*/
      // this.SpinnerService.show();
      this.dialogRef2 = this.dialog.open(ReasonModalComponent, {
        data: { initialState },
        panelClass: 'event-details-dialog',
        width: '30vw'
      })
      this.dialogRef2.afterClosed().subscribe(async (res) => {
        BusinessPostCandidates.remarks = data.reason
        let response = await this.commonService.updateRecord(
          'BusinessPostCandidates',
          BusinessPostCandidates
        )
        data.businessPostCandidate = BusinessPostCandidates
        if (response.success == true || response.success == 'true') {
          this.toastr.success('Candidate shortlisted successfully')
        }
      })
    }
  }
  async rejectCandidate(data) {
    if (data?.businessPostCandidate?.status != 2) {
      data.businessPostCandidate.status = 2
      data.businessPostCandidate.action_status = 2
      data.businessPostCandidate.reject_by = 'admin'
      var initialState = 'reject'
      this.dialogRef2 = this.dialog.open(ReasonModalComponent, {
        data: { initialState },
        panelClass: 'event-details-dialog',
        width: '30vw'
      })
      this.dialogRef2.afterClosed().subscribe(async (res) => {
        data.businessPostCandidate.remarks = res.reason
        let response = await this.commonService.updateRecord(
          'BusinessPostCandidates',
          data.businessPostCandidate
        )
        if (response.success == true || response.success == 'true') {
          this.toastr.success('Candidate rejected')
        }
      })
    }
  }
  getSourceList(value: any, array: any[]): any[] {
    let list: any[] = []
    if (typeof value !== 'undefined') {
      let arrayValue: any[] = getArrayFromString(value)
      arrayValue.forEach((element) => {
        let item: any = array.find((val) => val === element)
        list.push(item)
      })
    }
    return list
  }
}
