<div class="container ease-in">
  <div class="position-relative">
    <button mat-icon-button class="close-button" (click)="closeModal()">
      <i class="fa fa-times"></i>
    </button>
  </div>
  <div class="d-flex justify-content-between">
    <div class="d-flex flex-column">
      <div class="mb-2 d-flex flex-column">
        <div class="mb-2">
          <label class="font-17"
            ><b>{{ header }}</b></label
          >
        </div>
        <ng-container *ngIf="cultureTestInfo">
          <div>
            <label class="font-17 mb-0">
              Your Prefered Team Culture: <b>{{ cultureTestInfo?.name }}</b>
            </label>
          </div>
          <div>
            <label class="font-13 mb-0">
              <b>Thrust: </b>{{ cultureTestInfo?.description?.thrusts }}
            </label>
          </div>
          <div>
            <label class="font-13 mb-0">
              <b>Means: </b>{{ cultureTestInfo?.description?.means }}
            </label>
          </div>
          <div>
            <label class="font-13 mb-0">
              <b>Ends: </b>{{ cultureTestInfo?.description?.ends }}
            </label>
          </div>
        </ng-container>
        <div *ngIf="!cultureTestInfo" class="mb-2">
          <label class="font-13 mb-0"> The culture questionnaire of role is not updated. </label>
        </div>
      </div>
      <!-- <div *ngIf="isMatchChart">
                <div>
                    <label class="font-17 mb-0">
                        Candidate Primary Archetype: <b>{{candidateCultureArchetype?.description?.name}}</b>
                    </label>
                </div>
                <div>
                    <label class="font-13 mb-0">
                        <b>Facets: </b>{{candidateCultureArchetype?.description?.Facets}}
                    </label>
                </div>
            </div> -->
    </div>
    <div>
      <img
        *ngIf="cultureTestInfo?.name"
        class="personality-img"
        src="'../../../../assets/images/cultures/{{ cultureTestInfo?.name }}.jpeg"
      />
    </div>
  </div>
  <div class="d-flex details-div">
    <div class="d-flex buttons-div">
      <button
        *ngFor="let button of cultureButtons"
        mat-button
        class="personality-button"
        (click)="viewCultureDetails(button)"
      >
        {{ button.name }}
      </button>
      <div class="d-flex flex-column cursor-default pt-2" *ngIf="isMatchChart">
        <div class="d-flex align-items-center justify-content-end">
          <span class="pr-1">Team</span>
          <span class="legend-marker"></span>
        </div>
        <div class="d-flex align-items-center justify-content-end">
          <span class="pr-1">Candidate</span>
          <span class="legend-marker second-marker"></span>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-center chart-div flex-column">
      <div class="d-flex justify-content-evenly chart-top-labels">
        <ng-container *ngFor="let name of xAxisLabels; let i = index">
          <label *ngIf="i < 2" [style.textAlign]="i === 1 ? 'end' : 'start'">{{ name }}</label>
        </ng-container>
      </div>
      <div id="chart">
        <!-- <apx-chart [series]="chartOptions.series" [chart]="chartOptions.chart" [xaxis]="chartOptions.xaxis"
                    [yaxis]="chartOptions.yaxis" [stroke]="chartOptions.stroke" [markers]="chartOptions.markers"
                    [fill]="chartOptions.fill" [plotOptions]="chartOptions.plotOptions"
                    [responsive]="chartOptions.responsive" [legend]="chartOptions.legend"></apx-chart> -->
      </div>
      <div class="d-flex flex-row-reverse justify-content-evenly chart-bottom-labels">
        <label
          *ngFor="let name of xAxisLabels.slice(2); let i = index"
          [style.textAlign]="i === 0 ? 'end' : 'start'"
          >{{ name }}</label
        >
      </div>
    </div>
  </div>
</div>

<ng-template #cultureDetails>
  <div class="template-container ease-in">
    <div class="d-flex justify-content-end position-relative mb-3">
      <mat-dialog-actions>
        <button mat-icon-button class="template-close-button" (click)="closeTemplateModal()">
          <i class="fa fa-times"></i>
        </button>
      </mat-dialog-actions>
    </div>
    <div class="d-flex justify-content-between">
      <div>
        <div class="mb-2">
          <label
            ><b>{{ selectedCulture?.name }}</b></label
          >
        </div>
        <div class="mb-2">
          <label><b>Thrust: </b>{{ selectedCulture?.description?.thrusts }}</label>
        </div>
        <div class="mb-2">
          <label><b>Means: </b>{{ selectedCulture?.description?.means }}</label>
        </div>
        <div class="mb-2">
          <label><b>Ends: </b>{{ selectedCulture?.description?.ends }}</label>
        </div>
      </div>
      <div>
        <img
          class="personality-img"
          src="'../../../../assets/images/cultures/{{ selectedCulture?.name }}.jpeg"
        />
      </div>
    </div>
    <div>
      <ul>
        <li *ngFor="let extra of selectedCulture?.description?.extras">{{ extra }}</li>
      </ul>
    </div>
  </div>
</ng-template>
