import { Injectable } from '@angular/core'
import * as S3 from 'aws-sdk/clients/s3'
import { Observable } from 'rxjs'
import { NgxSpinnerService } from 'ngx-spinner'
import { environment } from 'src/environments/environment'

@Injectable({
  providedIn: 'root'
})
export class S3Service {
  bucket = new S3({
    accessKeyId: environment.awsS3Config.accessKeyId,
    secretAccessKey: environment.awsS3Config.secretAccessKey,
    region: environment.awsS3Config.region
  })
  file: any
  constructor(private spinner: NgxSpinnerService) {}
  async uploadFile(
    file: File,
    path?: string,
    spinner?: string
  ): Promise<S3.ManagedUpload.SendData> {
    console.log('file', file)
    let random = Math.floor(new Date().getTime() / 1000)
    if (spinner) this.spinner.show(spinner)

    let filelocation = ''
    const contentType = file.type

    const params = {
      Bucket: `${environment.awsS3Config.bucket}/` + path,
      Key: `${random}_${file.name}`,
      Body: file,
      ContentType: contentType
    }
    var options = { partSize: 100 * 1024 * 1024, queueSize: 1 }
    await this.bucket
      .upload(params, options)
      .promise()
      .then((res) => {
        console.log(res)
        this.file = res
      })
      .finally(() => {
        if (spinner) this.spinner.hide(spinner)
      })
    return this.file
  }
  delete(fileurl: string, path: string) {
    let index = fileurl.lastIndexOf('/') //getting the index of '/' from last
    let filename = fileurl.slice(index + 1, fileurl.length) //geting the file name from s3 bucket url
    console.log(fileurl)
    console.log(filename)
    const params = {
      Bucket: `${environment.awsS3Config.bucket}/` + path,
      Key: filename
    }
    this.bucket
      .deleteObject(params)
      .promise()
      .then((res) => {
        console.log(res)
      })
  }
}
