import { ChangeDetectorRef, Component, OnDestroy, Output, EventEmitter, Input } from '@angular/core'
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar'
import { MediaMatcher } from '@angular/cdk/layout'
import { MenuItems } from '../../../shared/menu-items/menu-items'
import { getLoggedInUser } from 'src/app/common-utils'
import { Router } from '@angular/router'
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal'
import { LogoutConfirmationComponent } from 'src/app/dashboards/modals/logout-confirmation/logout-confirmation.component'
import { AuthService } from '@auth0/auth0-angular'

@Component({
  selector: 'app-vertical-sidebar',
  templateUrl: './vertical-sidebar.component.html',
  styleUrls: []
})
export class VerticalAppSidebarComponent implements OnDestroy {
  public config: PerfectScrollbarConfigInterface = {}
  mobileQuery: MediaQueryList
  bsModalRef: BsModalRef
  @Input() showClass: boolean = false
  @Input() mode: any
  @Output() notify: EventEmitter<boolean> = new EventEmitter<boolean>()

  private _mobileQueryListener: () => void
  status = true
  showMenu = ''
  itemSelect: number[] = []
  parentIndex = 0
  childIndex = 0
  userType = 1

  addExpandClass(element: any) {
    if (element === this.showMenu) {
      this.showMenu = '0'
    } else {
      this.showMenu = element
    }
  }

  subclickEvent(): void {
    this.status = true
  }
  scrollToTop(): void {
    document.querySelector('.page-wrapper')?.scroll({
      top: 0,
      left: 0
    })
  }

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    public menuItems: MenuItems,
    private router: Router,
    private modalService: BsModalService,
    private authService: AuthService
  ) {
    this.mobileQuery = media.matchMedia('(min-width: 768px)')
    this._mobileQueryListener = () => changeDetectorRef.detectChanges()
    // tslint:disable-next-line: deprecation
    this.mobileQuery.addListener(this._mobileQueryListener)
  }

  async ngOnInit() {
    this.authService.user$.subscribe(async (user) => {
      if (user) {
        this.userType = user?.app_user_type
      }
    })
  }

  ngOnDestroy(): void {
    // tslint:disable-next-line: deprecation
    this.mobileQuery.removeListener(this._mobileQueryListener)
  }

  handleNotify() {
    if (window.innerWidth < 1024) {
      this.notify.emit(!this.showClass)
      // this.notifyOutside.emit('component')
    }
  }
  redirect(menuitem: any) {
    if (menuitem.state === 'logout') {
      this.openModal()
    } else {
      this.router.navigate(['/' + menuitem.state])
    }
  }
  openModal() {
    this.bsModalRef = this.modalService.show(LogoutConfirmationComponent)
  }
}
