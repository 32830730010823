import { Component, Inject, OnInit } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { ToastrService } from 'ngx-toastr'

@Component({
  selector: 'app-role-post-success-modal',
  templateUrl: './role-post-success-modal.component.html',
  styleUrls: ['./role-post-success-modal.component.scss']
})
export class RolePostSuccessModalComponent implements OnInit {
  roleLink: string
  isCopied: boolean = false

  constructor(
    private dialogRef: MatDialogRef<RolePostSuccessModalComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.roleLink = this.data.roleLink
  }

  closeModal() {
    this.dialogRef.close()
  }

  copyLink(event) {
    this.isCopied = event
    this.toastr.success('Link copied successfully')
  }
}
