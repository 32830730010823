import { Component, Inject, OnInit } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { Router } from '@angular/router'
import { getSessionValue } from 'src/app/common-utils'
import { CommonService } from 'src/app/common.service'
declare var $: any
@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss']
})
export class ConfirmationModalComponent implements OnInit {
  // isConfirm: Subject<boolean> = new Subject<boolean>();
  title: any = 'Save Confirmation'
  description: any = "Are you sure you want to leave, your details won't be saved?"
  constructor(
    // private modalService: BsModalService,
    private router: Router,
    private commonService: CommonService,
    @Inject(MAT_DIALOG_DATA) private data,
    private dialogRef: MatDialogRef<ConfirmationModalComponent>
  ) {
    // private bsModalRef: BsModalRef<ConfirmationModalComponent>,
    // private options: ModalOptions
  }
  userInfo: any
  ngOnInit(): void {
    $('.modal-content').on('contextmenu', function () {
      // Display the message
      return false
    })
    this.userInfo = getSessionValue('USER_INFO')

    if (this.data) {
      this.description = this.data.description
      this.title = this.data.title
    } else {
      this.title = 'Save Confirmation'
      this.description = "Are you sure you want to leave, your details won't be saved?"
    }
  }
  leave() {
    // this.redirect(this.userInfo.user_type);
    // this.isConfirm.next(true);
    // this.bsModalRef.hide();
    this.dialogRef.close(true)
  }
  closeModal() {
    // this.isConfirm.next(false);
    // this.bsModalRef.hide();
    this.dialogRef.close(false)
  }
  redirect(loginType: any) {
    if (loginType === '1') {
      this.router.navigate(['/authentication/login-option'])
      //this.commonService.changeData('candidate!!!!');
      //this.router.navigate(['/dashboards/candidate']);
    } else if (loginType === '2') {
      this.commonService.changeData('business!!!!')
      this.router.navigate(['/dashboards/business'])
    } else if (loginType === '3') {
      this.commonService.changeData('admin!!!!')
      this.router.navigate(['/dashboards/admin'])
    } else {
      this.router.navigate(['/authentication/login-option'])
    }
  }
}
