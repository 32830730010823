import { Component, Inject, OnInit, TemplateRef, ViewChild } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog'
import {
  ApexAxisChartSeries,
  ApexChart,
  ApexXAxis,
  ChartComponent,
  ApexYAxis,
  ApexStroke,
  ApexMarkers,
  ApexFill,
  ApexPlotOptions,
  ApexResponsive,
  ApexLegend
} from 'ng-apexcharts'

export type ChartOptions = {
  series: ApexAxisChartSeries
  chart: ApexChart
  xaxis: ApexXAxis
  yaxis: ApexYAxis[]
  stroke: ApexStroke
  markers: ApexMarkers
  fill: ApexFill
  plotOptions: ApexPlotOptions
  responsive: ApexResponsive[]
  legend: ApexLegend
}

@Component({
  selector: 'app-my-score',
  templateUrl: './my-score.component.html',
  styleUrls: ['./my-score.component.scss']
})
export class MyScoreComponent implements OnInit {
  @ViewChild('chart') chart: ChartComponent
  @ViewChild('personalityDetails') personalityDetails: TemplateRef<any>
  public chartOptions: Partial<ChartOptions>
  personalityTestInfo
  personalityFactors
  personalityButtons = []
  selectedPersonality
  header: string = 'Personality Structure'
  isClientScore: boolean = false
  isMatchChart: boolean = false
  candidatePersonalityArchetype
  rolePersonalityArchetype
  candidatePersonalityFactors
  rolePersonalityFactors

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private dialogRef: MatDialogRef<MyScoreComponent>,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.personalityTestInfo = this.data?.personalityTestInfo
    this.personalityFactors = this.data?.personalityFactors
    if (this.data?.header) {
      this.header = this.data?.header
    }
    if (this.data?.isClientScore) {
      this.isClientScore = this.data.isClientScore
    }
    if (this.data?.isMatchChart) {
      this.isMatchChart = this.data?.isMatchChart
      this.candidatePersonalityArchetype = this.data?.candidatePersonalityArchetype
      this.candidatePersonalityFactors = this.data?.candidatePersonalityFactors
    }
    this.createChart()
  }

  createChart() {
    // this.personalityFactors.sort((a, b) => {
    //   return Number(a?.count) - Number(b?.count);
    // });

    // this.personalityFactors.forEach((value, index) => {
    //   value["count"] = (6 - index) + 1;
    // });

    this.personalityFactors.sort((a, b) => {
      return Number(a?.display_order) - Number(b?.display_order)
    })

    const names = []
    const counts = []
    this.personalityFactors.forEach((value) => {
      const name = value.description.Name.split(' ')[0]
      names.push(name)
      counts.push(value?.count)
      const obj = {
        name: name,
        value: value
      }
      this.personalityButtons.push(obj)
    })

    this.chartOptions = {
      series: [
        {
          name: 'Series 1',
          data: counts,
          color: '#ec3b83'
        }
      ],
      chart: {
        type: 'radar',
        toolbar: {
          show: false
        },
        width: 500
      },
      xaxis: {
        categories: names,
        labels: {
          style: {
            colors: ['#0C2149', '#0C2149', '#0C2149', '#0C2149', '#0C2149', '#0C2149']
          }
        }
      },
      yaxis: [
        {
          show: false
        }
      ],
      stroke: {
        show: true,
        colors: ['#ec3b83', '#3C64B1']
      },
      markers: {
        size: 0
      },
      fill: {
        opacity: 0
      },
      plotOptions: {
        radar: {
          polygons: {
            strokeColors: '#fff',
            connectorColors: '#fff',
            fill: {
              colors: ['#D9D9D9']
            },
            strokeWidth: '0'
          }
        }
      },
      responsive: [
        {
          breakpoint: 930,
          options: {
            chart: {
              width: 450
            }
          }
        },
        {
          breakpoint: 1200,
          options: {
            chart: {
              width: 450
            }
          }
        },
        {
          breakpoint: 1440,
          options: {
            chart: {
              width: 500
            }
          }
        }
      ],
      legend: {
        markers: {
          height: 3,
          width: 30,
          radius: 3
        }
      }
    }

    if (this.isClientScore) {
      this.chartOptions.yaxis['max'] = 50
    }

    if (this?.isMatchChart) {
      this.candidatePersonalityFactors.sort((a, b) => {
        return Number(a?.display_order) - Number(b?.display_order)
      })

      let candidateCounts = this.candidatePersonalityFactors.map((value) => {
        return Number(value?.count)
      })

      this.chartOptions.series[0].name = 'Role'

      this.chartOptions.series.push({
        name: 'Candidate',
        data: candidateCounts,
        color: '#3C64B1'
      })
    }
  }

  viewPersonalityDetails(personality) {
    this.selectedPersonality = personality.value
    this.dialog.open(this.personalityDetails, {
      autoFocus: false,
      width: '45vw',
      id: 'personality-details'
    })
  }

  closeModal() {
    this.dialogRef.close()
  }

  closeTemplateModal() {
    const dialogRef = this.dialog.getDialogById('personality-details')
    dialogRef.close()
  }
}
