import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable, of, throwError } from 'rxjs'
import { HttpBackend, HttpClient, HttpParams, HttpResponse } from '@angular/common/http'
import { environment } from '../../../environments/environment'
import {
  CheckEmailExistResponse,
  LoginResponse,
  TokenResponse,
  UserWithToken
} from '../../core/models/auth'
import { catchError, finalize, map, tap } from 'rxjs/operators'
import { Router } from '@angular/router'
import { UserLogin } from '../../core/models/auth'
import { ToastrService } from 'ngx-toastr'
import { UserRegister } from 'src/app/core/models/user'
import { ApiResponse } from 'src/app/core/models/base'

@Injectable()
export class AuthService {
  private readonly AUTH_TOKEN = 'AUTH_TOKEN'
  private readonly REFRESH_TOKEN = 'REFRESH_TOKEN'

  public currentAdminSubject: BehaviorSubject<UserWithToken>
  public currentAdmin: Observable<UserWithToken>
  private httpWithoutInterceptor: HttpClient

  constructor(
    private http: HttpClient,
    private handler: HttpBackend,
    private router: Router,
    private toastr: ToastrService
  ) {
    this.httpWithoutInterceptor = new HttpClient(handler)
    this.currentAdminSubject = new BehaviorSubject<UserWithToken>(
      JSON.parse(localStorage.getItem('currentAdmin'))
    )
    this.currentAdmin = this.currentAdminSubject.asObservable()
  }

  public get currentAdminValue(): UserWithToken {
    return this.currentAdminSubject.value
  }

  signinUser(userLogin: UserLogin) {
    return this.http.post<LoginResponse>(`${environment.apiUrl}/v1/auth/login`, userLogin).pipe(
      map((result) => {
        console.log('Login Response', result)
        if (result) {
          const userWithToken = UserWithToken.fromLoginResponse(result)
          localStorage.setItem('currentAdmin', JSON.stringify(userWithToken))
          this.currentAdminSubject.next(userWithToken)
          return userWithToken
        } else {
          return null
        }
      })
    )
  }

  logout() {
    /*  let logout = new Object({
       "refresh": this.currentAdminValue.auth_token
     }); */
    let logout = {
      refresh: `${this.currentAdminValue.tokens.refresh.token}`
    }
    console.log(logout)
    //http://3.25.135.118/api/auth/logout
    return this.http.post(`${environment.apiUrl}auth/logout`, logout).pipe(
      map(
        (res) => {
          // localStorage.removeItem("currentAdmin");
          // this.currentAdminSubject.next(null);
          // this.router.navigate(["/login"]);
        },
        (err) => {
          console.log('ERROR LOGOUT', err)
        }
      ),
      finalize(() => {
        localStorage.removeItem('currentAdmin')
        this.currentAdminSubject.next(null)
        this.router.navigate(['/login'])
      })
    )
    localStorage.removeItem('currentAdmin')
    this.currentAdminSubject.next(null)
    this.router.navigate(['/login'])
  }

  refreshToken() {
    console.log('refreshing token')
    let refreshTokenBody = {
      token: `${this.currentAdminValue.tokens.refresh.token}`
    }
    console.log(refreshTokenBody)
    return this.http
      .post<TokenResponse>(`${environment.apiUrl}auth/refresh-tokens`, refreshTokenBody)
      .pipe(
        map(
          (result) => {
            console.log('Token Response', result)
            if (result) {
              const admin = this.currentAdminValue
              admin.tokens = result.data
              this.currentAdminSubject.next(admin)
              localStorage.setItem('currentAdmin', JSON.stringify(admin))
              return admin
            }
          },
          (err) => {
            console.log('ERROR REFRESH', err)
          }
        )
      )
  }
  reset(email) {
    const opts = { params: new HttpParams({ fromString: `email=${email}` }) }
    //http://3.25.135.118/api/auth/forgot_password?email=admin@wundar.co
    return this.httpWithoutInterceptor
      .get<any>(`${environment.apiUrl}auth/forgot_password`, opts)
      .pipe(
        map(
          (res) => {
            return res
          },
          (err) => {
            console.log('RegisterERROR', err)
          }
        )
      )
  }
  register(userRegister: UserRegister) {
    return this.http
      .post<LoginResponse>(`${environment.apiUrl}/v1/auth/register`, userRegister)
      .pipe(
        map(
          (res) => {
            console.log('Register Response', res)
            if (res) {
              const userWithToken = UserWithToken.fromLoginResponse(res)
              localStorage.setItem('currentAdmin', JSON.stringify(userWithToken))
              this.currentAdminSubject.next(userWithToken)
              return res
            } else {
              return null
            }
          },
          (err) => {
            console.log('signUp', err)
          }
        )
      )
  }

  checkEmailExists(email: string) {
    return this.httpWithoutInterceptor
      .post<CheckEmailExistResponse>(`${environment.apiUrl}/v1/auth/check-email`, {
        email
      })
      .pipe(
        map(
          (res) => {
            return res
          },
          (err) => {
            console.log('check-email', err)
          }
        )
      )
  }

  sendVerificationEmail(email: string, host: string) {
    return this.httpWithoutInterceptor
      .post<ApiResponse<{}>>(`${environment.apiUrl}/v1/auth/send-verification-email`, {
        email,
        host
      })
      .pipe(
        map(
          (res) => {
            return res
          },
          (err) => {
            console.log('send-verification-email', err)
          }
        )
      )
  }

  verifyEmail(token: string) {
    return this.httpWithoutInterceptor
      .get<ApiResponse<{}>>(`${environment.apiUrl}/v1/auth/verify-email/${token}`)
      .pipe(
        map(
          (res) => {
            return res
          },
          (err) => {
            console.log('verify-email-email', err)
          }
        )
      )
  }

  verifyResetPasswordToken(token: string) {
    return this.httpWithoutInterceptor
      .get<ApiResponse<{}>>(`${environment.apiUrl}/v1/auth/verify-reset-password-token/${token}`)
      .pipe(
        map(
          (res) => {
            return res
          },
          (err) => {
            console.log('verify-reset-password-token', err)
          }
        )
      )
  }

  requestPasswordReset(email: string, host: string) {
    return this.httpWithoutInterceptor
      .post<ApiResponse<{}>>(`${environment.apiUrl}/v1/auth/reset-password`, {
        email,
        host
      })
      .pipe(
        map(
          (res) => {
            return res
          },
          (err) => {
            console.log('request-reset-password', err)
          }
        )
      )
  }

  resetPassword(password: string, token: string, host: string) {
    return this.httpWithoutInterceptor
      .post<ApiResponse<{}>>(`${environment.apiUrl}/v1/auth/reset-password/${token}`, {
        password,
        host
      })
      .pipe(
        map(
          (res) => {
            return res
          },
          (err) => {
            console.log('reset-password', err)
          }
        )
      )
  }

  changePassword(old_password: string, new_password: string) {
    return this.http
      .put<ApiResponse<{}>>(`${environment.apiUrl}/v1/users/me/change-password`, {
        old_password,
        new_password
      })
      .pipe(
        map(
          (res) => {
            return res
          },
          (err) => {
            console.log('change-password', err)
          }
        )
      )
  }

  isAuthTokenValid() {
    // check if jwt token is valid
    const jwtToken = this.currentAdminValue.tokens.access.token
    if (jwtToken) {
      try {
        const [, payload] = jwtToken.split('.')
        const { exp: expires } = JSON.parse(window.atob(payload))
        if (typeof expires === 'number') {
          return !(Date.now() > expires * 1000)
        }
      } catch {
        return false
      }
    }
    return false
  }

  isRefreshTokenValid() {
    // check if jwt token is vallid
    const jwtToken = this.currentAdminValue.tokens.refresh.token
    if (jwtToken) {
      try {
        const [, payload] = jwtToken.split('.')
        const { exp: expires } = JSON.parse(window.atob(payload))
        if (typeof expires === 'number') {
          return !(Date.now() > expires * 1000)
        }
      } catch {
        return false
      }
    }
    return false
  }
}
