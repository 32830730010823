import { Component, Inject, OnInit } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'

@Component({
  selector: 'app-personality-notification',
  templateUrl: './personality-notification.component.html',
  styleUrls: ['./personality-notification.component.scss']
})
export class PersonalityNotificationComponent implements OnInit {
  header: string
  description1
  description2
  buttons: string[] = ['Yes, Continue', 'Exit Anyway']
  isCLoseIcon: boolean = false
  constructor(
    private dialogRef: MatDialogRef<PersonalityNotificationComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    this.header = this.data?.header
    this.description1 = this.data?.description1
    this.description2 = this.data?.description2
    if (this.data?.buttons) {
      this.buttons = this.data?.buttons
    }
    this.isCLoseIcon = this.data?.isCLoseIcon ? this.data?.isCLoseIcon : false
  }

  ngOnInit(): void {}

  closeModal(result) {
    this.dialogRef.close(result)
  }
}
