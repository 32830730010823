import { Component, Inject, OnInit, Optional } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog'
import { ToastrService } from 'ngx-toastr'
import { getSessionValue } from 'src/app/common-utils'
import { CommonService } from 'src/app/common.service'
import { CRUDService } from 'src/app/crud.service'
import { PersonalityNotificationComponent } from 'src/app/dashboards/modals/personality-notification/personality-notification.component'
import { ValuesMapService } from '../shared/services/values-map.service'
import { CandidateService } from '../shared/services/candidate.service'

@Component({
  selector: 'app-personality-builder',
  templateUrl: './personality-builder.component.html',
  styleUrls: ['./personality-builder.component.scss']
})
export class PersonalityBuilderComponent implements OnInit {
  trayColorArray = [
    { border: '#2CB49E', background: '#2cb49e1a', color: '#2CB49E' },
    { border: '#FFDA53', background: '#ffda531a', color: '#87742F' },
    { border: '#FB4F21', background: '#fb4f211a', color: '#FB4F21' },
    { border: '#EC3B83', background: '#ec3b831a', color: '#EC3B83' },
    { border: '#D29CFE', background: '#d29cfe1a', color: '#D29CFE' },
    { border: '#3E60FF', background: '#3e60ff1a', color: '#3E60FF' }
  ]
  shortTestOptionsArray = [
    {
      id: 1,
      option1: 'Sincere, honest, modest',
      option2: 'Brave, independent, self-assured'
    },
    {
      id: 2,
      option1: 'Sincere, honest, modest',
      option2: 'Outgoing, lively, sociable'
    },
    {
      id: 3,
      option1: 'Sincere, honest, modest',
      option2: 'Patient, tolerant, flexible'
    },
    {
      id: 4,
      option1: 'Sincere, honest, modest',
      option2: 'Organised, diligent, thorough'
    },
    {
      id: 5,
      option1: 'Sincere, honest, modest',
      option2: 'Creative, unconventional, innovative'
    },
    {
      id: 6,
      option1: 'Brave, independent, self-assured',
      option2: 'Outgoing, lively, sociable'
    },
    {
      id: 7,
      option1: 'Brave, independent, self-assured',
      option2: 'Patient, tolerant, flexible'
    },
    {
      id: 8,
      option1: 'Brave, independent, self-assured',
      option2: 'Organised, diligent, thorough'
    },
    {
      id: 9,
      option1: 'Brave, independent, self-assured',
      option2: 'Creative, unconventional, innovative'
    },
    {
      id: 10,
      option1: 'Outgoing, lively, sociable',
      option2: 'Patient, tolerant, flexible'
    },
    {
      id: 11,
      option1: 'Outgoing, lively, sociable',
      option2: 'Organised, diligent, thorough'
    },
    {
      id: 12,
      option1: 'Outgoing, lively, sociable',
      option2: 'Creative, unconventional, innovative'
    },
    {
      id: 13,
      option1: 'Patient, tolerant, flexible',
      option2: 'Organised, diligent, thorough'
    },
    {
      id: 14,
      option1: 'Patient, tolerant, flexible',
      option2: 'Creative, unconventional, innovative'
    },
    {
      id: 15,
      option1: 'Organised, diligent, thorough',
      option2: 'Creative, unconventional, innovative'
    }
  ]
  longTestQuestionsArray = [
    {
      id: 1,
      question: 'I can look at a painting for a long time.',
      code: 'O',
      count: 0,
      scoring: 'standard'
    },
    {
      id: 2,
      question: 'I make sure that things are in the right spot.',
      code: 'C',
      count: 0,
      scoring: 'standard'
    },
    {
      id: 3,
      question: 'I remain unfriendly to someone who was mean to me.',
      code: 'A',
      count: 0,
      scoring: 'reverse'
    },
    {
      id: 4,
      question: 'Nobody likes talking with me.',
      code: 'X',
      count: 0,
      scoring: 'reverse'
    },
    {
      id: 5,
      question: 'I am afraid of feeling pain.',
      code: 'E',
      count: 0,
      scoring: 'standard'
    },
    {
      id: 6,
      question: 'I find it difficult to lie.',
      code: 'H',
      count: 0,
      scoring: 'standard'
    },
    {
      id: 7,
      question: 'I think science is boring.',
      code: 'O',
      count: 0,
      scoring: 'reverse'
    },
    {
      id: 8,
      question: 'I postpone complicated tasks as long as possible.',
      code: 'C',
      count: 0,
      scoring: 'standard'
    },
    {
      id: 9,
      question: 'I often express criticism.',
      code: 'A',
      count: 0,
      scoring: 'reverse'
    },
    {
      id: 10,
      question: 'I easily approach strangers.',
      code: 'X',
      count: 0,
      scoring: 'standard'
    },
    {
      id: 11,
      question: 'I worry less than others.',
      code: 'E',
      count: 0,
      scoring: 'reverse'
    },
    {
      id: 12,
      question: 'I would like to know how to make lots of money in a dishonest manner.',
      code: 'H',
      count: 0,
      scoring: 'reverse'
    },
    {
      id: 13,
      question: 'I have a lot of imagination.',
      code: 'O',
      count: 0,
      scoring: 'standard'
    },
    {
      id: 14,
      question: 'I work very precisely.',
      code: 'C',
      count: 0,
      scoring: 'standard'
    },
    {
      id: 15,
      question: 'I tend to quickly agree with others.',
      code: 'A',
      count: 0,
      scoring: 'standard'
    },
    {
      id: 16,
      question: 'I like to talk with others.',
      code: 'X',
      count: 0,
      scoring: 'standard'
    },
    {
      id: 17,
      question: 'I can easily overcome difficulties on my own.',
      code: 'E',
      count: 0,
      scoring: 'reverse'
    },
    {
      id: 18,
      question: 'I want to be famous.',
      code: 'H',
      count: 0,
      scoring: 'reverse'
    },
    {
      id: 19,
      question: 'I like people with strange ideas.',
      code: 'O',
      count: 0,
      scoring: 'standard'
    },
    {
      id: 20,
      question: 'I often do things without really thinking.',
      code: 'C',
      count: 0,
      scoring: 'reverse'
    },
    {
      id: 21,
      question: "Even when I'm treated badly, I remain calm.",
      code: 'A',
      count: 0,
      scoring: 'standard'
    },
    {
      id: 22,
      question: 'I am seldom cheerful.',
      code: 'X',
      count: 0,
      scoring: 'reverse'
    },
    {
      id: 23,
      question: 'I have to cry during sad or romantic movies.',
      code: 'E',
      count: 0,
      scoring: 'standard'
    },
    {
      id: 24,
      question: 'I am entitled to special treatment.',
      code: 'H',
      count: 0,
      scoring: 'reverse'
    }
  ]
  progressbarColorArr = [
    '#66ff66',
    '#1aff1a',
    '#00cc00',
    '#008000',
    '#007300',
    '#006600',
    '#005900',
    '#004d00'
  ]

  trayColor
  option
  selectedOptions = []
  selectedOptionIds = []
  personalityProgress: number = 0
  testType: string
  personalityFactors
  candidateProfile
  userInfo
  isShortTest: boolean = true

  constructor(
    private dialog: MatDialog,
    @Optional() @Inject(MAT_DIALOG_DATA) public data,
    private commonService: CommonService,
    private CRUDService: CRUDService,
    private toastr: ToastrService,
    private valuesMapService: ValuesMapService,
    private candidateService: CandidateService,
    private dialogRef: MatDialogRef<PersonalityBuilderComponent>
  ) {}

  async ngOnInit() {
    this.candidateProfile = getSessionValue('CANDIDATE_PROFILE')
    if (!this.candidateProfile) {
      this.candidateProfile = this.data?.candidateProfile
    }
    // this.userInfo = getSessionValue("USER_INFO");
    this.candidateProfile['personality_test_info'] = {
      H: 0,
      E: 0,
      X: 0,
      A: 0,
      C: 0,
      O: 0,
      test_type: ''
    }
    this.isShortTest = this.data?.testType === 'short-test' ? true : false
    this.getTrayColor()
    this.getOption()
    await this.getPersonalityFactors()
  }

  getRandomItem(arr) {
    const randomIndex = Math.floor(Math.random() * arr.length)
    const item = arr[randomIndex]
    return item
  }

  getTrayColor() {
    this.trayColor = this.getRandomItem(this.trayColorArray)
  }

  getOption() {
    if (this.isShortTest) {
      this.option = this.getRandomItem(this.shortTestOptionsArray)
    } else {
      this.option = this.getRandomItem(this.longTestQuestionsArray)
    }
    if (this.selectedOptionIds.includes(this.option.id)) {
      this.getOption()
    } else {
      this.selectedOptionIds.push(this.option.id)
    }
  }

  async getPersonalityFactors() {
    const personalityFactorsRes = await this.valuesMapService
      .getFilteredValuesMap(100, 0, '', 'PERSONALITY_FACTORS')
      .toPromise()
    this.personalityFactors = personalityFactorsRes.data.items
    this.personalityFactors.forEach((ele) => {
      ele['description'] = JSON.parse(ele['description'].replace('"Adjectives"', ', "Adjectives"'))
      ele['count'] = 0
    })
  }

  selectBox(option) {
    const arrLength = this.shortTestOptionsArray.length
    if (this.selectedOptions.length < arrLength) {
      this.selectedOptions.push(option)
      this.personalityProgress += 6.67
      this.setProgressbarColor()

      const optionArr = option?.toLowerCase().split(', ')

      this.personalityFactors.forEach((value) => {
        const adjectives = value?.description?.Adjectives?.toLowerCase()
        if (
          adjectives.includes(optionArr[0]) ||
          adjectives.includes(optionArr[1]) ||
          adjectives.includes(optionArr[2])
        ) {
          value.count += 1
          const key = value?.code
          this.candidateProfile.personality_test_info[key] = value.count.toString()
        }
      })

      if (this.selectedOptionIds.length < arrLength) {
        this.getTrayColor()
        this.getOption()
      }

      if (this.selectedOptions.length === arrLength) {
        this.submitDetails()
      }
    }
  }

  setProgressbarColor() {
    const progressbar: any = document.getElementById('progress-bar')
    if (this.personalityProgress < 12) {
      progressbar.style.setProperty('--progressBarColor', this.progressbarColorArr[0])
    } else if (this.personalityProgress < 25) {
      progressbar.style.setProperty('--progressBarColor', this.progressbarColorArr[1])
    } else if (this.personalityProgress < 37) {
      progressbar.style.setProperty('--progressBarColor', this.progressbarColorArr[2])
    } else if (this.personalityProgress < 50) {
      progressbar.style.setProperty('--progressBarColor', this.progressbarColorArr[3])
    } else if (this.personalityProgress < 62) {
      progressbar.style.setProperty('--progressBarColor', this.progressbarColorArr[4])
    } else if (this.personalityProgress < 75) {
      progressbar.style.setProperty('--progressBarColor', this.progressbarColorArr[5])
    } else if (this.personalityProgress < 87) {
      progressbar.style.setProperty('--progressBarColor', this.progressbarColorArr[6])
    } else {
      progressbar.style.setProperty('--progressBarColor', this.progressbarColorArr[7])
    }
  }

  previousOption() {
    this.selectedOptionIds.pop()
    if (this.isShortTest) {
      this.option = this.shortTestOptionsArray.find((value) => {
        return value?.id === this.selectedOptionIds[this.selectedOptionIds.length - 1]
      })
    } else {
      this.option = this.longTestQuestionsArray.find((value) => {
        return value?.id === this.selectedOptionIds[this.selectedOptionIds.length - 1]
      })
    }
    let removedOption = this.selectedOptions.pop()
    this.isShortTest
      ? (this.personalityProgress -= 100 / this.shortTestOptionsArray.length)
      : (this.personalityProgress -= 100 / this.longTestQuestionsArray.length)

    if (this.isShortTest) {
      removedOption = removedOption.toLowerCase().split(', ')
      const personalityIndex = this.personalityFactors.findIndex((value) => {
        const adjectives = value?.description?.Adjectives?.toLowerCase()
        return (
          adjectives.includes(removedOption[0]) ||
          adjectives.includes(removedOption[1]) ||
          adjectives.includes(removedOption[2])
        )
      })

      this.personalityFactors[personalityIndex].count -= 1
      const key = this.personalityFactors[personalityIndex]?.code
      this.candidateProfile.personality_test_info[key] =
        this.personalityFactors[personalityIndex].count
    } else {
      this.candidateProfile.personality_test_info[removedOption?.code] -= removedOption?.count
    }
    this.setProgressbarColor()
  }

  leavePersonalityBuilder() {
    const dialogRef = this.dialog.open(PersonalityNotificationComponent, {
      panelClass: 'personality-notification',
      width: '55vw',
      autoFocus: 'false',
      data: {
        header: 'Wait, are you sure you want to leave?',
        description1:
          'To help us better match you to roles we’d like to understand your personality type. Your progress won’t be saved, but you can complete this test at a later date from your profile.',
        description2:
          'Are you sure you don’t have time to quickly complete the Personality Questionnaire?',
        buttons: ['No, Continue', 'Exit Anyway']
      }
    })

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'continue') {
        return
      } else if (result === 'exit') {
        this.closeModal()
      }
    })
  }

  nextQuestion(option, value) {
    const arrLength = this.longTestQuestionsArray.length
    if (this.selectedOptions.length < arrLength) {
      this.selectedOptions.push(option)
      this.personalityProgress += 100 / arrLength
      this.setProgressbarColor()

      if (option.scoring === 'standard') {
        const key = option?.code
        this.candidateProfile.personality_test_info[key] += value
        option.count = value
      } else if (option.scoring === 'reverse') {
        const key = option?.code
        if (value === 1) {
          this.candidateProfile.personality_test_info[key] += 5
          option.count = 5
        } else if (value === 2) {
          this.candidateProfile.personality_test_info[key] += 4
          option.count = 4
        } else if (value === 3) {
          this.candidateProfile.personality_test_info[key] += 3
          option.count = 3
        } else if (value === 4) {
          this.candidateProfile.personality_test_info[key] += 2
          option.count = 2
        } else if (value === 5) {
          this.candidateProfile.personality_test_info[key] += 1
          option.count = 1
        }
      }

      if (this.selectedOptionIds.length < arrLength) {
        this.getOption()
      }

      if (this.selectedOptions.length === arrLength) {
        this.submitDetails()
      }
    }
  }

  closeModal() {
    this.dialogRef.close()
  }

  async submitDetails() {
    this.candidateProfile.personality_test_info['test_type'] = this.isShortTest ? 'S' : 'L'

    let personalityArchetype
    if (this.isShortTest) {
      this.personalityFactors.sort((a, b) => {
        return b?.count - a?.count
      })
      personalityArchetype = this.personalityFactors[0].code
    } else {
      const arr = Object.keys(this.candidateProfile?.personality_test_info).map((key) => {
        const obj = {
          key: key,
          count: this.candidateProfile?.personality_test_info[key]
        }
        return obj
      })
      arr.sort((a, b) => {
        return b?.count - a?.count
      })
      personalityArchetype = arr[0]?.key
    }

    this.candidateProfile['personality_archetype'] = personalityArchetype

    const candidateProfile = {
      id: this.candidateProfile.id,
      personality_test_info: JSON.stringify(this.candidateProfile.personality_test_info),
      personality_archetype: this.candidateProfile.personality_archetype
    }

    const result = await this.candidateService
      .updateById(candidateProfile.id, candidateProfile)
      .toPromise()
    if (result.success) {
      sessionStorage.setItem('CANDIDATE_PROFILE', JSON.stringify(result.data))
      this.dialogRef.close({
        archetypeCode: this.candidateProfile.personality_archetype,
        data: result.data
      })
      this.toastr.success('Personality test completed successfully')
    } else {
      this.closeModal()
      this.toastr.warning('Something went wrong, please try again later')
    }
  }
}
