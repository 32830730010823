<div
  class="body ease-in"
  fxLayout="row wrap"
  [fxLayoutAlign]="viewJobByTokenFlag ? 'center' : 'none'"
>
  <div
    [fxFlex.gt-lg]="viewJobByTokenFlag ? 55 : 100"
    [fxFlex.gt-md]="viewJobByTokenFlag ? 55 : 100"
    [fxFlex.gt-xs]="viewJobByTokenFlag ? 55 : 100"
    [fxFlex]="viewJobByTokenFlag ? 55 : 100"
  >
    <div class="row col-12 content-box">
      <div
        [ngClass]="
          viewJobByTokenFlag
            ? 'col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12'
            : 'col-lg-8 col-md-10 col-sm-12 col-xs-12 col-12'
        "
        class="postBoxCard"
      >
        <div *ngIf="!viewJobByTokenFlag" class="postsBox postsBoxDiv">
          <div class="row col-12 pr-0 text-center">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 pl-0 pr-0 search">
              <mat-form-field appearance="fill">
                <mat-icon matPrefix class="search-icon">search</mat-icon>
                <input type="text" aria-label="Number" matInput matInput placeholder="Search" />
              </mat-form-field>
            </div>
          </div>
          <div class="row col-12 pr-0 text-center mt-2">
            <div class="col-xs-12 col-sm-12 col-md-9 col-lg-9 pl-0 pr-0 search">
              <mat-form-field appearance="fill" class="mt-1">
                <mat-select
                  [(ngModel)]="locationValue"
                  name="business"
                  placeholder="Select Location"
                  class="pl-3 pr-3 font-14"
                >
                  <mat-option *ngFor="let state of locationArray" [value]="state.value">
                    {{ state.viewValue }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 pl-2 pr-0 search">
              <button mat-raised-button class="search-btn">Search</button>
            </div>
          </div>
        </div>
        <hr />
        <!-- <hr> -->
        <div class="postsBox job mt-3">
          <div class="role-main-div">
            <div class="role-heading row d-flex" style="justify-content: space-between">
              <div
                class="d-flex justify-content-between col-sm-12 col-md-12 col-lg-12 col-xl-12 col-12"
              >
                <div class="d-flex">
                  <div class="profile-div pl-2">
                    <img
                      class="profile-img"
                      src="{{ jobDetails?.businessProfile?.logo_url }}"
                      onerror="this.onerror=null;this.src='assets/images/business/business_logo.png'"
                    />
                  </div>
                  <div class="pl-3">
                    <h3 class="profile-label">
                      {{ jobDetails?.businessProfile?.name }}
                    </h3>
                    <label class="role-text">{{ jobDetails?.title }}</label>
                  </div>
                </div>
                <div class="button-div">
                  <button
                    mat-button
                    class="secondaryButton mb-2"
                    (click)="viewBusinessProfile(jobDetails?.business_id)"
                  >
                    Company Profile
                  </button>
                  <button
                    *ngIf="
                      !jobDetails?.businessPostsCandidate ||
                      jobDetails?.businessPostsCandidate?.action_status == null
                    "
                    mat-raised-button
                    class="add-post-role-btn mb-2"
                    (click)="apply(false)"
                  >
                    Apply
                    <!-- <img src="../../../../assets/images/work-action icon/u_clipboard-notes.svg" style="height: 15px" /> -->
                  </button>
                  <button
                    class="add-post-role-btn applied-button w-100 mb-2"
                    mat-raised-button
                    *ngIf="
                      jobDetails?.businessPostsCandidate?.action_status == '0' &&
                      jobDetails?.businessPostsCandidate?.status == '0'
                    "
                  >
                    Applied
                  </button>
                  <button
                    class="shortlisted-button w-100 mb-2"
                    mat-button
                    *ngIf="
                      (jobDetails?.businessPostsCandidate?.action_status == '1' ||
                        jobDetails?.businessPostsCandidate?.action_status == '5') &&
                      jobDetails?.businessPostsCandidate?.status == '1'
                    "
                  >
                    Shortlisted
                  </button>
                  <button
                    class="rejectedButton w-100 mb-2"
                    mat-button
                    *ngIf="
                      (jobDetails?.businessPostsCandidate?.action_status == '2' ||
                        jobDetails?.businessPostsCandidate?.action_status == '3') &&
                      jobDetails?.businessPostsCandidate?.status == '2'
                    "
                  >
                    Rejected
                  </button>
                  <button
                    class="add-post-role-btn mb-2 w-100"
                    mat-raised-button
                    (click)="openAcceptConnectionModal()"
                    *ngIf="
                      jobDetails?.businessPostsCandidate?.action_status == '1' &&
                      jobDetails?.businessPostsCandidate?.status == '1'
                    "
                  >
                    Accept Connection?
                  </button>
                  <button
                    class="add-post-role-btn mb-2 w-100"
                    mat-raised-button
                    (click)="viewTimeSlots()"
                    *ngIf="
                      (jobDetails?.businessPostsCandidate?.action_status == '5' ||
                        (jobDetails?.businessPostsCandidate?.action_status == '6' &&
                          !jobDetails?.businessPostsCandidate?.meeting_id)) &&
                      jobDetails?.businessPostsCandidate?.status == '1'
                    "
                  >
                    View Timeslots
                  </button>
                  <button
                    class="assessmentButton mb-2 w-100"
                    mat-raised-button
                    *ngIf="
                      jobDetails?.businessPostsCandidate?.action_status == '6' &&
                      jobDetails?.businessPostsCandidate?.status == '1' &&
                      jobDetails?.businessPostsCandidate?.meeting_id
                    "
                  >
                    Assessment
                  </button>
                </div>
              </div>
              <div class="header-btn col-sm-12 col-md-12 col-lg-12 col-xl-12 col-12">
                <button
                  class="apply-button"
                  *ngIf="jobDetails?.businessPostsCandidate?.action_status == '4'"
                >
                  <mat-icon>check_circle</mat-icon>
                </button>
              </div>
            </div>
            <form [formGroup]="jobDetailsForm" autocomplete="off">
              <div>
                <div class="details-div mt-3">
                  <label class="title">Role Type</label>
                  <mat-label class="title-value">
                    {{ jobDetails?.role_type }}
                  </mat-label>
                </div>
                <div class="details-div mt-3">
                  <label class="title">Status</label>
                  <mat-label class="title-value">
                    {{ getStatusDesc(jobDetails?.status) }}
                  </mat-label>
                </div>
                <div class="details-div mt-3">
                  <label class="title">Employment Type</label>
                  <mat-label class="title-value">
                    {{ changeString(jobDetails?.employment_type) }}
                  </mat-label>
                </div>
                <div class="details-div mt-3">
                  <label class="title">Start Date</label>
                  <mat-label class="title-value">
                    {{ jobDetails?.start_by }}
                  </mat-label>
                </div>
                <div class="details-div mt-3">
                  <label class="title">Remote Work</label>
                  <mat-label class="title-value">
                    {{ remoteWorkValue() }}
                  </mat-label>
                </div>
                <div class="details-div mt-3">
                  <label class="title">Location</label>
                  <mat-label class="title-value">
                    {{ changeString(jobDetails?.location) }}
                  </mat-label>
                </div>
                <div class="details-div mt-3">
                  <label class="title">Role Description</label>
                  <mat-label class="title-value" [innerText]="jobDetails?.description"> </mat-label>
                </div>
                <div class="details-div mt-3">
                  <label class="title">Salary (base)</label>
                  <mat-label class="title-value">
                    {{ jobDetails?.salary }}
                  </mat-label>
                </div>
                <div class="details-div mt-3">
                  <label class="title">Day-rate (exc super)</label>
                  <mat-label class="title-value">{{ jobDetails?.day_rate }} </mat-label>
                </div>
                <div class="details-div mt-3">
                  <div>
                    <label class="title">Personality</label>
                  </div>
                  <div *ngIf="personalityTypeDetails" class="personality-container">
                    <div class="d-flex justify-content-between">
                      <div>
                        <div class="mb-2 personality-archetype">
                          <label
                            ><b>{{ personalityTypeDetails?.description?.Name }}</b></label
                          >
                        </div>
                        <div class="mb-2 personality-details">
                          <label
                            ><b>Facets: </b>{{ personalityTypeDetails?.description?.Facets }}</label
                          >
                        </div>
                        <div class="mb-2 personality-details">
                          <label
                            ><b>Adjectives: </b
                            >{{ personalityTypeDetails?.description?.Adjectives }}</label
                          >
                        </div>
                      </div>
                      <div>
                        <!-- <img class="personality-img" src="assets/images/upplft/personality-1.png"> -->
                        <img
                          class="personality-img"
                          src="assets/images/archetypes/{{ personalityTypeDetails.code }}.png"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="details-div mt-3">
                  <div>
                    <label class="title">Culture</label>
                  </div>
                  <div *ngIf="cultureInfo" class="personality-container">
                    <div class="d-flex justify-content-between">
                      <div>
                        <div class="mb-2 personality-archetype">
                          <label
                            ><b>{{ cultureInfo?.name }}</b></label
                          >
                        </div>
                        <div class="mb-2 personality-details">
                          <label><b>Thrust: </b>{{ cultureInfo?.description?.thrusts }}</label>
                        </div>
                        <div class="mb-2 personality-details">
                          <label><b>Means: </b>{{ cultureInfo?.description?.means }}</label>
                        </div>
                        <div class="mb-2 personality-details">
                          <label><b>Ends: </b>{{ cultureInfo?.description?.ends }}</label>
                        </div>
                      </div>
                      <div>
                        <img
                          class="personality-img"
                          src="assets/images/cultures/{{ cultureInfo.name }}.jpeg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="details-div mt-3">
                  <label class="title">Benefits</label>
                  <mat-chip-list aria-label="Benefits Selection" class="mt-2">
                    <mat-chip *ngFor="let benefits of jobDetailsForm.controls.benefits.value">
                      {{ benefits }}</mat-chip
                    >
                  </mat-chip-list>
                </div>
                <div class="details-div mt-3">
                  <label class="title">Soft Skills</label>
                  <mat-chip-list aria-label="Soft Skills Selection" class="mt-2">
                    <mat-chip *ngFor="let softSkill of jobDetailsForm.controls.softSkills.value">
                      {{ softSkill }}</mat-chip
                    >
                  </mat-chip-list>
                </div>
                <div class="details-div mt-3">
                  <label class="title">Domain Experience</label>
                  <mat-chip-list aria-label="Domain Experience Selection" class="mt-2">
                    <mat-chip *ngFor="let exp of jobDetailsForm.controls.experience.value">
                      {{ exp }}</mat-chip
                    >
                  </mat-chip-list>
                </div>
                <div class="details-div mt-3">
                  <label class="title mb-1">Required Skills</label>

                  <mat-chip-list aria-label="Skills Selection" class="mt-2">
                    <mat-chip *ngFor="let skill of jobDetailsForm.controls.skills.value"
                      >{{ skill }}
                    </mat-chip>
                  </mat-chip-list>
                </div>
              </div>
              <div
                class="details-div mt-3"
                *ngFor="let attachment of jobDetails?.postAttachmentArray"
              >
                <label for="file-upload1" class="custom-file-upload mx-auto">
                  <img
                    class="post-img"
                    *ngIf="attachment.file_type == 'image'"
                    src="{{ attachment.file_path }}"
                  />
                  <mat-icon
                    *ngIf="editDataFlag && attachment.file_type == 'image'"
                    class="mat-plus"
                    (click)="showImageUpload(attachment)"
                    >add_circle
                  </mat-icon>
                </label>
                <label for="file-upload2" class="custom-file-upload mx-auto">
                  <video
                    class="post-img mt-2"
                    *ngIf="attachment.file_type == 'video'"
                    src="{{ attachment.file_path }}"
                    controls
                  ></video>
                  <mat-icon
                    *ngIf="editDataFlag && attachment.file_type == 'video'"
                    class="mat-plus"
                    (click)="showImageUpload(attachment)"
                    >add_circle
                  </mat-icon>
                </label>
                <audio
                  controls
                  *ngIf="attachment.file_type == 'audio'"
                  src="{{ attachment.file_path }}"
                ></audio>
                <label *ngIf="attachment.file_type == 'doc'">{{ attachment.file_name }}</label>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div
        *ngIf="!viewJobByTokenFlag"
        class="col-lg-4 col-md-2 col-sm-12 col-xs-12 col-2 clear-btn"
      >
        <div class="col-lg-1 col-md-1 col-sm-12 col-xs-12 mb-3 pl-0">
          <button
            mat-raised-button
            class="close-btn postsBox"
            style="border-radius: 20px"
            routerLink="/dashboards/work"
          >
            <mat-icon class="font-18" style="color: black">clear</mat-icon>
          </button>
        </div>
        <!-- <app-work-action></app-work-action> -->
      </div>
    </div>
  </div>
</div>
<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
  <p style="color: white">Please Wait.</p>
</ngx-spinner>
