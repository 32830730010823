import { environment } from 'src/environments/environment'

// let eventGuid = 0;
// const TODAY_STR = new Date().toISOString().replace(/T.*$/, ""); // YYYY-MM-DD of today

export function getLoggedInUser() {
  let info: any
  info = localStorage.getItem('USER_INFO')
  if (info !== '') {
    info = JSON.parse(info)
  } else {
    info = {}
  }
  return info
}

export function getSessionValue(input: any) {
  let info: any
  info = sessionStorage.getItem(input)
  if (info !== '') {
    info = JSON.parse(info)
  } else {
    info = {}
  }
  return info
}

export function getArrayFromString(input: any) {
  let stringArr: any[] = []
  let returnArray: any[] = []
  if (input !== null && input !== '') {
    stringArr = input?.split(';')
  }
  stringArr?.forEach((element) => {
    returnArray.push(element.trim())
  })
  return returnArray
}

export function getDateWithoutTimeString(inputDate: Date) {
  const date =
    inputDate.getFullYear() +
    '-' +
    ('00' + (inputDate.getMonth() + 1)).slice(-2) +
    '-' +
    ('00' + inputDate.getDate()).slice(-2)
  return date
}

export function getDateWithoutTime(inputDate: Date) {
  const date = new Date(inputDate.getFullYear(), inputDate.getMonth(), inputDate.getDate())
  return date
}

export function getToken() {
  let userInfo = getSessionValue('USER_INFO')
  if (userInfo !== null) {
    if (typeof userInfo.token !== 'undefined') {
      return userInfo.token
    } else {
      return ''
    }
  } else {
    return ''
  }
}

export function getArrayStringValue(input: any[]) {
  let string: any = ''
  if (typeof input != 'undefined') {
    if (input !== null) {
      input.forEach((element) => {
        if (string !== '' && string !== null) {
          string = string + ';' + element.trim()
        } else {
          string = element.trim()
        }
      })
    }
  }
  return string
}

export function getArrayFromStringNew(input: any) {
  let stringArr: any[] = []
  let returnArray: any[] = []
  if (typeof input != 'undefined') {
    if (input !== null && input !== '') {
      stringArr = input.split(';')
    }
    stringArr.forEach((element) => {
      returnArray.push(element.trim())
    })
  }
  return returnArray
}

export function getPersonalityTypeArray() {
  // let personalityTypeArray = [
  //   {
  //     name: "Dog",
  //     image_name: "dog.png",
  //     description: "Perspective, Supportive, Optimistic",
  //     long_desc:
  //       "Executives are representatives of tradition and order, utilizing their understanding of what is right, wrong and socially acceptable to bring families and communities together. Embracing the values of honesty, dedication and dignity.",
  //   },
  //   {
  //     name: "Dolphin",
  //     image_name: "dolphin.png",
  //     description: "Enthusiastic, Creative, Engaging",
  //     long_desc:
  //       "Executives are representatives of tradition and order, utilizing their understanding of what is right, wrong and socially acceptable to bring families and communities together. Embracing the values of honesty, dedication and dignity.",
  //   },
  //   {
  //     name: "Narwhal",
  //     image_name: "narwhal.png",
  //     description: "Imaginative, Idealistic, Compassionate",
  //     long_desc:
  //       "Executives are representatives of tradition and order, utilizing their understanding of what is right, wrong and socially acceptable to bring families and communities together. Embracing the values of honesty, dedication and dignity.",
  //   },
  //   {
  //     name: "Sea horse",
  //     image_name: "seahorse.png",
  //     description: "Mysterious, Intutive, Nuturing",
  //     long_desc:
  //       "Executives are representatives of tradition and order, utilizing their understanding of what is right, wrong and socially acceptable to bring families and communities together. Embracing the values of honesty, dedication and dignity.",
  //   },
  //   {
  //     name: "Bear",
  //     image_name: "bear.png",
  //     description: "Powerful, Direct, Outgoing",
  //     long_desc:
  //       "Executives are representatives of tradition and order, utilizing their understanding of what is right, wrong and socially acceptable to bring families and communities together. Embracing the values of honesty, dedication and dignity.",
  //   },
  //   {
  //     name: "Octopus",
  //     image_name: "octopus.png",
  //     description: "Intellectual, Perspective, Strategic",
  //     long_desc:
  //       "Executives are representatives of tradition and order, utilizing their understanding of what is right, wrong and socially acceptable to bring families and communities together. Embracing the values of honesty, dedication and dignity.",
  //   },
  //   {
  //     name: "Fox",
  //     image_name: "fox.png",
  //     description: "cooperative, Devoted, Consenrentious",
  //     long_desc:
  //       "Executives are representatives of tradition and order, utilizing their understanding of what is right, wrong and socially acceptable to bring families and communities together. Embracing the values of honesty, dedication and dignity.",
  //   },
  //   {
  //     name: "Owl",
  //     image_name: "owl.png",
  //     description: "Original, Aloof, Philosophical",
  //     long_desc:
  //       "Executives are representatives of tradition and order, utilizing their understanding of what is right, wrong and socially acceptable to bring families and communities together. Embracing the values of honesty, dedication and dignity.",
  //   },
  //   {
  //     name: "Whale",
  //     image_name: "whale.png",
  //     description: "Cooperative, Devoted, Consenrentious",
  //     long_desc:
  //       "Executives are representatives of tradition and order, utilizing their understanding of what is right, wrong and socially acceptable to bring families and communities together. Embracing the values of honesty, dedication and dignity.",
  //   },
  //   {
  //     name: "Meircat",
  //     image_name: "meircat.png",
  //     description: "Confident, Organised, Social",
  //     long_desc:
  //       "Executives are representatives of tradition and order, utilizing their understanding of what is right, wrong and socially acceptable to bring families and communities together. Embracing the values of honesty, dedication and dignity.",
  //   },
  //   {
  //     name: "Elephant",
  //     image_name: "elephant.png",
  //     description: "Reliable, Loyal, Principled",
  //     long_desc:
  //       "Executives are representatives of tradition and order, utilizing their understanding of what is right, wrong and socially acceptable to bring families and communities together. Embracing the values of honesty, dedication and dignity.",
  //   },
  //   {
  //     name: "Ant",
  //     image_name: "ant.png",
  //     description: "Dependent, Productive, Meticulous",
  //     long_desc:
  //       "Executives are representatives of tradition and order, utilizing their understanding of what is right, wrong and socially acceptable to bring families and communities together. Embracing the values of honesty, dedication and dignity.",
  //   },
  //   {
  //     name: "Otter",
  //     image_name: "otter.png",
  //     description: "Charming. Energetic, Fun loving",
  //     long_desc:
  //       "Executives are representatives of tradition and order, utilizing their understanding of what is right, wrong and socially acceptable to bring families and communities together. Embracing the values of honesty, dedication and dignity.",
  //   },
  //   {
  //     name: "Beaver",
  //     image_name: "beaver.png",
  //     description: "Energetic, Playful, Adaptive",
  //     long_desc:
  //       "Executives are representatives of tradition and order, utilizing their understanding of what is right, wrong and socially acceptable to bring families and communities together. Embracing the values of honesty, dedication and dignity.",
  //   },
  //   {
  //     name: "Panda",
  //     image_name: "panda.png",
  //     description: "Spontaneous, Gentle, Present",
  //     long_desc:
  //       "Executives are representatives of tradition and order, utilizing their understanding of what is right, wrong and socially acceptable to bring families and communities together. Embracing the values of honesty, dedication and dignity.",
  //   },
  //   {
  //     name: "Honey bee",
  //     image_name: "honeybee.png",
  //     description: "Perspective, Supportive, Optimistic",
  //     long_desc:
  //       "Executives are representatives of tradition and order, utilizing their understanding of what is right, wrong and socially acceptable to bring families and communities together. Embracing the values of honesty, dedication and dignity.",
  //   },
  // ];
  return getPersonalityTypeArray
}

export function getDefaultProfileImage(candidate) {
  let imageUrl: string = ''
  if (candidate?.image_url) {
    imageUrl = candidate?.image_url
  } else {
    if (candidate?.gender === 'He/Him/His') {
      imageUrl = 'assets/images/pronouns/HeHim.png'
    } else if (candidate?.gender === 'She/Her/Hers') {
      imageUrl = 'assets/images/pronouns/SheHer.png'
    } else if (candidate?.gender === 'Zi/Zir/Zirs') {
      imageUrl = 'assets/images/pronouns/ZiZir.png'
    } else if (candidate?.gender === 'Them/They/Theirs') {
      imageUrl = 'assets/images/pronouns/TheyThem.png'
    } else {
      const images = ['HeHim', 'SheHer', 'ZiZir', 'TheyThem']
      const random = images[Math.floor(Math.random() * images.length)]
      imageUrl = `assets/images/pronouns/${random}.png`
    }
  }
  return imageUrl
}

export function getQueryVariable(variable) {
  var query = window.location.search.substring(1)
  var vars = query.split('&')
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split('=')
    if (decodeURIComponent(pair[0]) == variable) {
      return decodeURIComponent(pair[1])
    }
  }
  console.log('Query variable %s not found', variable)
}
