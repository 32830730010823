import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core'
import { CRUDService } from 'src/app/crud.service'
import { CommonService } from 'src/app/common.service'
import { environment } from '../../../../environments/environment'
import { getSessionValue, getArrayStringValue, getDefaultProfileImage } from '../../../common-utils'
import { NgxSpinnerService } from 'ngx-spinner'
import { Observable, Subscription } from 'rxjs'
import { OtherInfoValueComponent } from '../other-info-value/other-info-value.component'
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal'
import { ToastrService } from 'ngx-toastr'
import { CandidateService } from 'src/app/shared/services/candidate.service'
import { ValuesMapService } from 'src/app/shared/services/values-map.service'
import { SkillsetService } from 'src/app/shared/services/skillset.service'
import { UserService } from 'src/app/shared/services/user.service'
import { S3Service } from 'src/app/shared/services/s3.service'

@Component({
  selector: 'app-candidate-profile-detail',
  templateUrl: './candidate-profile-detail.component.html',
  styleUrls: ['./candidate-profile-detail.component.scss']
})
export class CandidateProfileDetailComponent implements OnInit, OnDestroy {
  status: boolean = false
  roleArray: any = []
  roleValue: any
  profileData: any
  editFlag = true
  gender: any
  genderArray: any = []
  educationData: any = []
  education: any
  maritalStatusData: any = []
  maritalStatus: any
  jobData: any = []
  selectedJob: any[] = []
  location: any
  locationData: any = []
  // language: any;
  rolesInterested: any = []
  interestedBusiness: any
  interestedIndustry: any
  languageData: any = []
  salary: any
  salaryData: any = []
  dayRateExpData: any = []
  dayRate: any
  otherExpectations: any
  otherExpData: any[] = []
  profileImage: any
  userInfo: any
  work_type: any
  lookingForArray: any[] = []
  lookingForValue: any
  apiUrl: any
  selectedSalary: any
  name: any
  linkedin_url: any
  identity: any
  identityValue: any = false
  contact_preference: any = []
  identityValueDesc: any
  // isVaccinated: boolean = false;
  showVisa: boolean = false
  // vaccinatedDesc: any;
  residencyData: any = []
  residencyStatus: any
  availability: string
  contactPreferenceData: any = []
  open_to_work: any
  open_to_work_check: any
  private eventSubscription: Subscription
  bsModalRef: BsModalRef
  rolesDisplay: any
  otherRolesValue: any
  englishProficiency: any
  @ViewChild('matSelect') matSelect: any
  @Input() public candidateProfileId: any
  @Input() events: Observable<any>
  @Input() public showPencilIcon: any
  @Output() sendDataToParent = new EventEmitter<boolean>()
  @Output() isAllFieldsFilled = new EventEmitter<boolean>()
  visaDetails: any
  businessData: any[] = []
  industryData: any[] = []
  clonedIndustryData: any
  industryDataArray: any[]
  profilecompData: any = []

  salarySug: boolean = false
  dayRateSug: boolean = false
  locationSug: boolean = false
  contactPreferSug: boolean = false
  roleIntSug: boolean = false
  salSugMsg: any
  profileCompSugglist: any = []
  dayRateMsg: any
  locationMsg: any
  contactMsg: any
  roleIntMsg: any
  profileValidSugg: boolean = false
  cvName
  pfName
  cvUrl
  pfUrl
  githubLinks: string[] = []
  urls: string[] = []
  availabilityData: string[] = ['Immediate', '2 weeks or less', '4 weeks or less', 'Over 4 weeks']
  isAdmin: boolean = false

  constructor(
    private CRUDService: CRUDService,
    private userService: UserService,
    private candidateService: CandidateService,
    private valuesMapService: ValuesMapService,
    private s3Service: S3Service,
    private skillsetService: SkillsetService,
    private modalService: BsModalService,
    private commonService: CommonService,
    private spinnerService: NgxSpinnerService,
    private toastr: ToastrService
  ) {}

  async ngOnInit(): Promise<void> {
    this.apiUrl = environment.apiUrl
    this.userInfo = getSessionValue('USER_INFO')
    this.isAdmin = this.userInfo?.user_type == 3
    this.profileData = getSessionValue('CANDIDATE_PROFILE')

    this.maritalStatusData = ['Married', 'Single']
    //this.profileValidSugg = environment.profile_status;
    this.genderArray = [
      'She/Her/Hers',
      'He/Him/His',
      'Them/They/Theirs',
      'Zi/Zir/Zirs',
      'I prefer not to say'
    ]
    this.valuesMapService.getFilteredValuesMap(100, 0, '', 'LOCATION').subscribe((res) => {
      const data = res.data.items.sort((a, b) => a.display_order - b.display_order)
      const names = data.map(function (item) {
        return item.description
      })
      this.locationData = names
      // ['Sydney', 'Melbourne', 'Adelaide', 'Brisbane', 'Canberra', 'Perth', 'Darwin', 'Hobart', 'Cairns', 'Rural', 'Remote'];
      // this.educationFilteredData = names
    })

    if (this.userInfo?.user_type != '1' && !this.isAdmin) {
      this.editFlag = false
    }
    // const perc = this.commonService.calcProfileComplPerc(this.profileData);
    // this.profileData.profileData = perc;
    // this.languageData = ['English', 'Spanish', 'French']
    this.valuesMapService
      .getFilteredValuesMap(100, 0, '', 'LANGUAGE_PROFICIENCY')
      .subscribe((data) => {
        const names = data.data.items.map(function (item) {
          return item.description
        })
        this.languageData = names
      })

    this.valuesMapService.getFilteredValuesMap(100, 0, '', 'SALARY').subscribe((res) => {
      let data = res.data.items
      data.sort((a, b) => a.display_order - b.display_order)
      const names = data.map(function (item) {
        return item.description
      })
      this.salaryData = names
    })

    this.valuesMapService.getFilteredValuesMap(100, 0, '', 'DAY_RATE').subscribe((res) => {
      let data = res.data.items
      data = data.sort((a, b) => a.display_order - b.display_order)
      const names = data.map(function (item) {
        return item.description
      })
      this.dayRateExpData = names
    })

    this.valuesMapService.getFilteredValuesMap(100, 0, '', 'INCENTIVES').subscribe((res) => {
      let data = res.data.items
      data = data.sort((a, b) => a.display_order - b.display_order)
      //data = data.sort((a, b) => (a.description > b.description ? 1 : -1));
      let names = data.map(function (item) {
        return item['description']
      })
      // this.clonedOtherExpData = data;
      this.otherExpData = names
      // this.otherExperienceList = data;
    })

    this.valuesMapService.getFilteredValuesMap(100, 0, '', 'ROLES').subscribe((res) => {
      let data = res.data.items
      data = data.sort((a, b) => a.display_order - b.display_order)
      const names = data.map(function (item) {
        return item.description
      })
      this.roleArray = names
    })
    this.valuesMapService.getFilteredValuesMap(100, 0, '', 'LOOKING_FOR').subscribe((res) => {
      let data = res.data.items
      data = data.sort((a, b) => a.display_order - b.display_order)
      const names = data.map(function (item) {
        return item.description
      })
      this.lookingForArray = names
    })
    this.valuesMapService.getFilteredValuesMap(100, 0, '', 'EDUCATION').subscribe((res) => {
      let data = res.data.items
      data = data.sort((a, b) => a.display_order - b.display_order)
      const names = data.map(function (item) {
        return item.description
      })
      this.educationData = names
      // this.educationData = data;
    })
    this.valuesMapService
      .getFilteredValuesMap(100, 0, '', 'CONTACT_PREFERENCE')
      .subscribe((res) => {
        let data = res.data.items
        data = data.sort((a, b) => a.display_order - b.display_order)
        const names = data.map(function (item) {
          return item.description
        })
        this.contactPreferenceData = names
        // this.educationData = data;
      })
    this.valuesMapService.getFilteredValuesMap(100, 0, '', 'RESIDENCY').subscribe((res) => {
      let data = res.data.items
      data = data.sort((a, b) => a.display_order - b.display_order)
      const names = data.map(function (item) {
        return item.description
      })
      this.residencyData = names
      // this.educationFilteredData = names
    })

    this.valuesMapService.getFilteredValuesMap(100, 0, '', 'SIZE').subscribe((res) => {
      let data = res.data.items
      data = data.sort((a, b) => a.display_order - b.display_order)
      let names = data.map(function (item: any) {
        return item['description']
      })
      this.businessData = names
    })

    this.valuesMapService.getFilteredValuesMap(100, 0, '', 'INDUSTRY').subscribe((res) => {
      let data = res.data.items
      data = data.sort((a, b) => a.display_order - b.display_order)
      //data = data.sort((a, b) => (a.description > b.description ? 1 : -1));
      let names = data.map(function (item) {
        return item['description']
      })
      this.clonedIndustryData = data
      this.industryDataArray = [...names]
      this.industryData = names
    })

    this.valuesMapService
      .getFilteredValuesMap(100, 0, '', 'Profile_Comp_Sugg')
      .subscribe((data) => {
        const names2 = data.data.items.map(function (item) {
          return item
        })

        this.profileCompSugglist = names2

        if (this.salary == null || this.salary == '' || this.salary == undefined) {
          this.salarySug = true

          let salMsg = this.profileCompSugglist.filter((e) => e.code == 'salary')
          this.salSugMsg = salMsg[0]?.description
        } else {
          this.salarySug = false
        }

        if (this.dayRate == null || this.dayRate == '' || this.dayRate == undefined) {
          this.dayRateSug = true
          let dayMsg = this.profileCompSugglist.filter((e) => e.code == 'dayRate')
          this.dayRateMsg = dayMsg[0]?.description
        } else {
          this.dayRateSug = false
        }

        if (this.location == '' || this.location == null || this.location == undefined) {
          this.locationSug = true
          let locationMsg = this.profileCompSugglist.filter((e) => e.code == 'location')
          this.locationMsg = locationMsg[0]?.description
        } else {
          this.locationSug = false
        }

        //var contact = this.contact_preference;
        if (
          this.contact_preference == null ||
          this.contact_preference == undefined ||
          this.contact_preference == ''
        ) {
          this.contactPreferSug = true
          let contactMsg = this.profileCompSugglist.filter((e) => e.code == 'contact')

          this.contactMsg = contactMsg[0]?.description
        } else {
          this.contactPreferSug = false
        }

        // var role = this.rolesInterested[0];

        if (
          this.rolesInterested == '' ||
          this.rolesInterested == null ||
          this.rolesInterested == undefined
        ) {
          this.roleIntSug = true
          let roleIntMsg = this.profileCompSugglist.filter((e) => e.code == 'interested')

          this.roleIntMsg = roleIntMsg[0]?.description
        } else {
          this.roleIntSug = false
        }
      })
    this.loadProfileInfo()
  }

  async loadProfileInfo() {
    // const rolesInterested = this.profileData?.roles_interested?.split(';')
    // const rolesArray = await rolesInterested.map((element: any) => {
    //   if (element.includes('Other (not listed)')) {
    //     this.otherRolesValue = element.split('-')[1]
    //     element = element.split('-')[0]
    //     return element;
    //   } else {
    //     return element
    //   }
    // })

    const data = this.isAdmin
      ? await this.candidateService.getById(this.candidateProfileId).toPromise()
      : await this.candidateService.getSelf().toPromise()

    this.profileData = data.data

    if (this.isAdmin) {
      const result = await this.userService.getById(this.profileData.user_id)?.toPromise()
      this.userInfo = result.data
    }

    let isExpValid = false
    if (this.profileData.currently_employed_yn == 'Y') {
      if (
        this.profileData[`experience_1_compname`] &&
        this.profileData[`experience_1_role`] &&
        this.profileData[`experience_1_from`]
      ) {
        isExpValid = true
      }
    } else {
      for (let i = 1; i < 4; i++) {
        if (
          this.profileData[`experience_${i}_compname`] &&
          this.profileData[`experience_${i}_role`] &&
          this.profileData[`experience_${i}_from`] &&
          this.profileData[`experience_${i}_to`]
        ) {
          isExpValid = true
          break
        }
      }
    }
    if (
      // this.profileData.gender &&
      // this.profileData.education &&
      this.profileData.roles_interested &&
      this.profileData.location &&
      // this.profileData.english_proficiency &&
      this.profileData.salary &&
      this.profileData.day_rate &&
      this.profileData.residency_status &&
      this.profileData.availability &&
      this.profileData.domain_exp &&
      this.profileData.culture &&
      // this.profileData.contact_preference &&
      // this.profileData.business_size &&
      // this.profileData.industry_interest &&
      this.profileData.looking_for &&
      isExpValid
    ) {
      this.isAllFieldsFilled.emit(true)
    } else {
      this.isAllFieldsFilled.emit(false)
    }
    this.gender = this.profileData?.gender
    this.education = this.profileData?.education
    this.maritalStatus = this.profileData?.marital_status
    this.selectedJob = this.profileData?.current_job?.split(';')

    this.interestedBusiness = this.profileData?.business_size?.split(';')
    this.interestedIndustry = this.profileData?.industry_interest?.split(';')

    this.rolesDisplay = this.profileData?.roles_interested?.split(';')

    this.rolesInterested = this.profileData?.roles_interested?.split(';')
    this.location = this.profileData?.location
    this.salary = this.profileData?.salary
    this.dayRate = this.profileData?.day_rate
    this.otherExpectations = this.profileData?.other_expectations?.split(';')
    this.contact_preference = this.profileData?.contact_preference?.split(';')

    this.work_type = this.profileData?.work_type
    this.lookingForValue = this.profileData?.looking_for?.split(';')
    this.residencyStatus = this.profileData?.residency_status
    this.availability = this.profileData?.availability
    if (this.residencyStatus === 'Valid Working Visa') {
      this.visaDetails = this.profileData?.visa_type
      this.showVisa = true
    } else if (this.residencyStatus === 'I need sponsorship/ Transfer') {
      this.visaDetails = this.profileData?.visa_type
      this.showVisa = true
    } else {
      this.visaDetails = ''
      this.showVisa = false
    }
    this.linkedin_url = this.profileData?.linkedin_url
    this.name = this.profileData?.name
    this.identity = this.profileData?.indigenous_identity
    this.englishProficiency = this.profileData?.english_proficiency
    this.open_to_work_check = this.profileData?.open_to_work === '1' ? true : false
    this.open_to_work = this.profileData?.open_to_work
    if (this.profileData?.indigenous_identity === 'Y') {
      this.identityValue = true
      this.identityValueDesc = 'Yes'
    } else {
      this.identityValue = false
      this.identityValueDesc = 'No'
    }
    if (this.profileData?.github_url) {
      this.githubLinks = this.profileData?.github_url.split(';')
    }
    if (this.profileData?.web_url) {
      this.urls = this.profileData?.web_url.split(';')
    }
    if (this.profileData?.cv_url) {
      const cvData = JSON.parse(this.profileData?.cv_url)
      this.cvName = cvData?.name
      this.cvUrl = cvData?.url
    }
    if (this.profileData?.cv_url) {
      const cvData = JSON.parse(this.profileData?.cv_url)
      this.cvName = cvData?.name
      this.cvUrl = cvData?.url
    }
    if (this.profileData?.portfolio_url) {
      const portfolioData = JSON.parse(this.profileData?.portfolio_url)
      this.pfName = portfolioData?.name
      this.pfUrl = portfolioData?.url
    }
    // if (this.profileData?.vaccinated_yn === "Y") {
    //   this.isVaccinated = true;
    //   this.vaccinatedDesc = "Yes";
    // } else {
    //   this.isVaccinated = false;
    //   this.vaccinatedDesc = "No";
    // }

    // this.profileData.profile_completion = this.profileData?.profile_completion;
    this.profileImage = getDefaultProfileImage(this.profileData)
    // this.eventSubscription = this.events.subscribe(async () => {
    //   this.submitData();
    // });
  }
  setSelection(event: any) {
    this.identity = event
    if (event) {
      this.identityValueDesc = 'Yes'
      this.identityValue = true
    } else {
      this.identityValue = false
      this.identityValueDesc = 'No'
    }
  }
  // setSelect(event: any) {
  //   this.identity = event.source.value;
  //   this.isVaccinated = event.source.value;
  //   if (event.checked) {
  //     this.vaccinatedDesc = 'Yes';
  //     this.isVaccinated = true;
  //   } else {
  //     this.isVaccinated = false;
  //     this.vaccinatedDesc = 'No';
  //   }
  // }
  getResStatus(status: any) {
    if (status === 'Valid Working Visa') {
      this.showVisa = true
    } else if (status === 'I need sponsorship/ Transfer') {
      this.showVisa = true
    } else {
      this.showVisa = false
    }
  }
  editData() {
    this.editFlag = true
    this.sendDataToParent.emit(this.editFlag)
  }
  async submitData() {
    this.eventSubscription.unsubscribe()
    // if (this.status === true) {
    // const data = await this.candidateService.getSelf().toPromise();
    const profileData = this.profileData
    profileData.image_url = this.profileData?.image_url
    profileData.gender = this.gender
    profileData.education = this.education
    profileData.marital_status = this.maritalStatus
    profileData.current_job = getArrayStringValue(this.selectedJob)
    profileData.roles_interested = getArrayStringValue(this.rolesInterested)
    profileData.business_size = getArrayStringValue(this.interestedBusiness)
    profileData.industry_interest = getArrayStringValue(this.interestedIndustry)
    profileData.location = this.location
    // profileData.roles_interested = getArrayStringValue(this.rolesDisplay);
    // this.profileData.language = this.language;
    profileData.salary = this.salary
    profileData.day_rate = this.dayRate
    profileData.other_expectations = getArrayStringValue(this.otherExpectations)
    profileData.work_type = this.work_type
    profileData.name = this.name
    profileData.linkedin_url = this.linkedin_url
    profileData.residency_status = this.residencyStatus
    profileData.availability = this.availability
    profileData.visa_type = this.visaDetails
    profileData.english_proficiency = this.englishProficiency
    if (this.identityValue == true) {
      profileData.indigenous_identity = 'Y'
    } else {
      profileData.indigenous_identity = 'N'
    }
    // if (this.isVaccinated == true) {
    //   profileData.vaccinated_yn = "Y";
    // } else {
    //   profileData.vaccinated_yn = "N";
    // }
    profileData.contact_preference = getArrayStringValue(this.contact_preference)
    profileData.looking_for = getArrayStringValue(this.lookingForValue)
    profileData.open_to_work = this.open_to_work

    profileData.cv_url = this.cvUrl
      ? JSON.stringify({
          url: this.cvUrl,
          name: this.cvName
        })
      : null
    profileData.portfolio_url = this.pfUrl
      ? JSON.stringify({
          url: this.pfUrl,
          name: this.pfName
        })
      : null
    profileData.github_url = getArrayStringValue(this.githubLinks)
    profileData.web_url = getArrayStringValue(this.urls)

    const response = this.isAdmin
      ? await this.candidateService.updateById(this.candidateProfileId, profileData).toPromise()
      : await this.candidateService.updateSelf(profileData).toPromise()
    //this.profileData.profile_completion = this.commonService.calcProfileComplPerc(profileData);
    if (response.success) {
      const userInfo = getSessionValue('USER_INFO')
      if (userInfo?.user_type == '1') {
        sessionStorage.setItem('CANDIDATE_PROFILE', JSON.stringify(response.data))
      }

      let body = {
        email: this.userInfo?.email_id,
        firstname: this.userInfo?.first_name,
        lastname: this.userInfo?.last_name,
        phone: this.userInfo?.primary_phone,
        job_family: response.data[0]?.roles_interested || '',
        profile_completion: response.data[0]?.profile_completion || 0,
        salary_expectations: response.data[0]?.salary || '',
        day_rate: response.data[0]?.day_rate || '',
        communication_preference: response.data[0]?.contact_preference?.replace(/;/g, ', ') || '',
        pesonality: response.data[0]?.personality_archetype || '',
        type: 'Candidate'
      }
      if (this.userInfo?.hubspotId) {
        body['hubspotId'] = this.userInfo?.hubspotId
      } else {
        body['id'] = this.userInfo?.id
      }
      // await this.updateHubspotCandidate(body);
      this.profileData.profile_completion = response.data.profile_completion
      this.toastr.success('Candidate profile updated')

      //sessionStorage.setItem('CANDIDATE_PROFILE', JSON.stringify(this.profileData));
      /* this._snackBar.open('Candidate Profile Updated', '', {
        horizontalPosition: 'end',
        verticalPosition: 'top',
        duration: 3000
      }); */
      await this.loadProfileInfo()
      // this._router.navigate(['/dashboards/candidate'])
    } else {
      this.sendDataToParent.emit(false)
    }
    this.status = false
    // }
  }
  async uploadImageProfile(event: any) {
    await this.spinnerService.show()
    if (
      event.addedFiles &&
      event.addedFiles[0] &&
      (event.addedFiles[0].type == 'image/jpeg' ||
        event.addedFiles[0].type == 'image/png' ||
        event.addedFiles[0].type == 'image/gif' ||
        event.addedFiles[0].type == 'image/webp')
    ) {
      const file = event.addedFiles[0]
      await this.spinnerService.show()
      if (file.size <= environment.imageDocSize) {
        await this.s3Service
          .uploadFile(file, `candidates/${this.candidateProfileId}`)
          .then(async (res) => {
            this.spinnerService.hide()
            this.profileImage = res.Location
            this.profileData.image_url = res.Location
            const response = this.isAdmin
              ? await this.candidateService
                  .updateById(this.profileData.id, this.profileData)
                  .toPromise()
              : await this.candidateService.updateSelf(this.profileData).toPromise()
            this.spinnerService.show()
            if (response.success) {
              sessionStorage.removeItem('CANDIDATE_PROFILE')
              sessionStorage.setItem('CANDIDATE_PROFILE', JSON.stringify(this.profileData))
              this.toastr.success('Candidate Profile Updated')
              this.spinnerService.hide()
            }
          })
          .catch((err) => {
            this.spinnerService.hide()
            this.toastr.error(err.message)
          })
      } else {
        this.spinnerService.hide()
        this.toastr.warning(environment.fileErrorMessage)
      }
    } else {
      this.spinnerService.hide()
      this.toastr.warning(environment.imageErrorMessage)
    }
  }

  async uploadDocuments(event: any, docType?: string) {
    if (docType === 'CV') {
      if (
        event.target.files[0] &&
        (event.target.files[0]?.type == 'application/pdf' ||
          event.target.files[0]?.type ==
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
          event.target.files[0]?.type == 'application/msword')
      ) {
        const file = event.target.files[0]
        await this.spinnerService.show()
        if (file.size <= environment.imageDocSize) {
          const response = await this.uploadFile(file, docType)
          if (response.success == 1) {
            this.toastr.success('CV uploaded successfully')
            this.cvName = file.name
          }
        } else {
          this.spinnerService.hide()
          this.toastr.warning(environment.fileErrorMessage)
        }
      } else {
        this.spinnerService.hide()
        this.toastr.warning('Only documents with doc, docx and pdf formats are allowed')
      }
    } else if (docType === 'PF') {
      if (
        event.target.files[0] &&
        (event.target.files[0]?.type == 'application/pdf' ||
          event.target.files[0]?.type ==
            'application/vnd.openxmlformats-officedocument.presentationml.presentation' ||
          event.target.files[0]?.type == 'application/vnd.ms-powerpoint')
      ) {
        const file = event.target.files[0]
        await this.spinnerService.show()
        if (file.size <= environment.imageDocSize) {
          const response = await this.uploadFile(file, docType)
          if (response.success == 1) {
            this.toastr.success('Portfolio uploaded successfully')
            this.pfName = file.name
          }
        } else {
          this.spinnerService.hide()
          this.toastr.warning(environment.fileErrorMessage)
        }
      } else {
        this.spinnerService.hide()
        this.toastr.warning('Only documents with ppt, pptx and pdf formats are allowed')
      }
    }
  }

  async uploadFile(file: any, docType: string = null) {
    await this.spinnerService.show()

    await this.s3Service
      .uploadFile(file, `candidates/${this.candidateProfileId}`)
      .then(async (res) => {
        if (docType === 'CV') {
          this.cvUrl = res.Location
        } else if (docType === 'PF') {
          this.pfUrl = res.Location
        }
      })
      .finally(() => {
        this.spinnerService.hide()
      })

    return { success: 1 }
  }

  changeWorkStatus(event: any) {
    this.open_to_work_check = event.checked
    if (event.checked) {
      this.open_to_work = '1'
    } else {
      this.open_to_work = '0'
    }
  }
  redirectToLinkedIn() {
    if (!this.linkedin_url.includes('http://') && !this.linkedin_url.includes('https://')) {
      window.open('https://' + this.linkedin_url, '_blank')
      return
    }
    window.open(this.linkedin_url, '_blank')
  }

  changedropValue() {
    if (this.contact_preference.includes("I don't mind")) {
      this.contact_preference = []
      this.contact_preference = ["I don't mind"]
    }
  }
  ngAfterViewInit() {
    this.matSelect?.openedChange.subscribe((opened) => {
      if (opened) {
        this.matSelect.panel.nativeElement.addEventListener('mouseleave', () => {
          this.matSelect.close()
        })
      }
    })
  }
  openedChange(opened: boolean) {}
  changeData(data, event) {
    if (data === 'Other (not listed)' && event) {
      const initialState: any = {
        data: this.otherRolesValue
      }
      this.bsModalRef = this.modalService.show(OtherInfoValueComponent, {
        ariaLabelledBy: 'modal-basic-title',
        class: 'modal-md show-modal',
        backdrop: 'static',
        initialState
      })
      this.bsModalRef.content.messageEvent.subscribe(async (data1) => {
        // if (this.otherRolesValue !== undefined && this.otherRolesValue !== null && this.otherRolesValue !== '') {
        // data = data1;
        this.rolesInterested.splice(this.rolesInterested.indexOf(data), 1)
        this.rolesDisplay.push(data1)
        // }
      })
    } else {
      if (event) {
        this.rolesDisplay.push(data)
      } else {
        this.rolesDisplay.splice(this.rolesDisplay.indexOf(data), 1)
      }
    }
  }

  async updateHubspotCandidate(body) {
    // TODO: Hubspot integration
    return new Promise<void>(async (resolve) => {
      if (body?.hubspotId) {
        await this.CRUDService.updateCandidateHubspot(body).toPromise()
        resolve()
      } else {
        const result: any = await this.CRUDService.createCandidateHubspot(body).toPromise()

        if (result.success == true || result.success == 'true') {
          const userDetails = this.isAdmin
            ? await this.userService.getById(this.userInfo.id).toPromise()
            : await this.userService.getSelf().toPromise()

          sessionStorage.setItem('USER_INFO', JSON.stringify(userDetails.data))
        }
        resolve()
      }
    })
  }

  addGithubLink(link) {
    if (this.githubLinks.includes(link)) {
      this.toastr.warning('Link already exists')
    } else {
      this.githubLinks.push(link)
    }
  }

  removeGithubLink(index) {
    this.githubLinks.splice(index, 1)
  }

  addUrl(link) {
    if (this.urls.includes(link)) {
      this.toastr.warning('URL already exists')
    } else {
      this.urls.push(link)
    }
  }

  getData() {
    const profileData: any = {}
    profileData.image_url = this.profileData?.image_url
    profileData.gender = this.gender
    profileData.education = this.education
    profileData.marital_status = this.maritalStatus
    profileData.current_job = getArrayStringValue(this.selectedJob)
    profileData.roles_interested = getArrayStringValue(this.rolesInterested)
    profileData.business_size = getArrayStringValue(this.interestedBusiness)
    profileData.industry_interest = getArrayStringValue(this.interestedIndustry)
    profileData.location = this.location
    profileData.salary = this.salary
    profileData.day_rate = this.dayRate
    profileData.other_expectations = getArrayStringValue(this.otherExpectations)
    profileData.work_type = this.work_type
    profileData.name = this.name
    profileData.linkedin_url = this.linkedin_url
    profileData.residency_status = this.residencyStatus
    profileData.availability = this.availability
    profileData.visa_type = this.visaDetails
    profileData.english_proficiency = this.englishProficiency
    if (this.identityValue == true) {
      profileData.indigenous_identity = 'Y'
    } else {
      profileData.indigenous_identity = 'N'
    }
    profileData.contact_preference = getArrayStringValue(this.contact_preference)
    profileData.looking_for = getArrayStringValue(this.lookingForValue)
    profileData.open_to_work = this.open_to_work

    profileData.cv_url = this.cvUrl
      ? JSON.stringify({
          url: this.cvUrl,
          name: this.cvName
        })
      : null
    profileData.portfolio_url = this.pfUrl
      ? JSON.stringify({
          url: this.pfUrl,
          name: this.pfName
        })
      : null
    profileData.github_url = getArrayStringValue(this.githubLinks)
    profileData.web_url = getArrayStringValue(this.urls)
    return profileData
  }

  removeUrl(index) {
    this.urls.splice(index, 1)
  }

  removeCV() {
    this.cvName = null
    this.cvUrl = null
  }

  removePortfolio() {
    this.pfName = null
    this.pfUrl = null
  }

  download(url) {
    if (url) {
      const newUrl = url
      window.open(newUrl, '_blank')
    }
  }

  noDocToastr(docType) {
    this.toastr.warning(`No ${docType} uploaded`)
  }

  ngOnDestroy() {
    this.eventSubscription.unsubscribe()
  }
}
