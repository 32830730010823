import { EventInput } from '@fullcalendar/angular'

let eventGuid = 0
const TODAY_STR = new Date().toISOString().replace(/T.*$/, '') // YYYY-MM-DD of today

export const INITIAL_EVENTS: EventInput[] = [
  {
    id: createEventId(),
    title: 'All-day event',
    start: TODAY_STR
  },
  {
    id: createEventId(),
    title: 'Timed event',
    start: TODAY_STR + 'T12:00:00'
  }
]

export function createEventId() {
  return String(eventGuid++)
}

export interface DialogData {
  action?: any
  event?: any
  startDate?: Date
  endDate?: Date
}

export interface EventInfo {
  event_id?: any
  meeting_date?: any
  start_time?: any
  duration?: any
}

export interface TimeSlots {
  value: string
  viewValue: string
}

export interface DateSlots {
  selectedStartTime?: string
  selectedEndTime?: string
}

export interface Event {
  date?: string
  slots?: DateSlots[]
}

export function getDateWithoutTimeString(inputDate: Date) {
  const date =
    inputDate.getFullYear() +
    '-' +
    ('00' + (inputDate.getMonth() + 1)).slice(-2) +
    '-' +
    ('00' + inputDate.getDate()).slice(-2)
  return date
}

export function getDateWithoutTime(inputDate: Date) {
  const date = new Date(inputDate.getFullYear(), inputDate.getMonth(), inputDate.getDate())
  return date
}

export const TimeSlotsList: TimeSlots[] = [
  { value: '0730', viewValue: '07:30am' },
  { value: '0800', viewValue: '08:00am' },
  { value: '0830', viewValue: '08:30am' },
  { value: '0900', viewValue: '09:00am' },
  { value: '0930', viewValue: '09:30am' },
  { value: '1000', viewValue: '10:00am' },
  { value: '1030', viewValue: '10:30am' },
  { value: '1100', viewValue: '11:00am' },
  { value: '1130', viewValue: '11:30am' },
  { value: '1200', viewValue: '12:00pm' },
  { value: '1230', viewValue: '12:30pm' },
  { value: '1300', viewValue: '01:00pm' },
  { value: '1330', viewValue: '01:30pm' },
  { value: '1400', viewValue: '02:00pm' },
  { value: '1430', viewValue: '02:30pm' },
  { value: '1500', viewValue: '03:00pm' },
  { value: '1530', viewValue: '03:30pm' },
  { value: '1600', viewValue: '04:00pm' },
  { value: '1630', viewValue: '04:30pm' },
  { value: '1700', viewValue: '05:00pm' },
  { value: '1730', viewValue: '05:30pm' },
  { value: '1800', viewValue: '06:00pm' },
  { value: '1830', viewValue: '06:30pm' },
  { value: '1900', viewValue: '07:00pm' },
  { value: '1930', viewValue: '07:30pm' }
]

export const scheudledEvents: EventInfo[] = [
  { event_id: '10000001', start_time: '1000', meeting_date: '20-11-2021', duration: '30' },
  { event_id: '10000002', start_time: '1500', meeting_date: '20-11-2021', duration: '30' },
  { event_id: '10000003', start_time: '1230', meeting_date: '21-11-2021', duration: '60' },
  { event_id: '10000004', start_time: '1300', meeting_date: '21-11-2021', duration: '30' },
  { event_id: '10000005', start_time: '0900', meeting_date: '23-11-2021', duration: '60' }
]
