import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { ToastrService } from 'ngx-toastr'

import { environment } from 'src/environments/environment'
import { CRUDService } from 'src/app/crud.service'
import { getSessionValue } from 'src/app/common-utils'
import { CommonService } from './../../../common.service'
import { UserService } from 'src/app/shared/services/user.service'
import { CandidateService } from 'src/app/shared/services/candidate.service'
import { CustomValidators } from 'ngx-custom-validators'
@Component({
  selector: 'app-candidate-account',
  templateUrl: './candidate-account.component.html',
  styleUrls: ['./candidate-account.component.scss']
})
export class CandidateAccountComponent implements OnInit {
  candidateProfileForm: FormGroup
  editFormFlag: boolean = false
  previousPath: any
  notificationIcon: boolean = false
  userData: any
  userInfo: any = []
  candidateInfo: any = []
  firstName: any
  lastName: any
  email: any
  phonenumber: any
  country: any
  dob: string = ''
  gender: any
  canid: any
  profileData: any
  submitted: boolean = false

  constructor(
    private fb: FormBuilder,
    private CRUDService: CRUDService,
    private commonService: CommonService,
    private userService: UserService,
    private candidateService: CandidateService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.previousPath = '/dashboards/setting/candidate-account'
    this.userInfo = getSessionValue('USER_INFO')

    this.candidateProfileForm = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, CustomValidators.email]],
      phonenumber: ['', Validators.required],
      country: ['', Validators.required],
      dob: ['', Validators.required],
      gender: ['', Validators.required]
    })
    this.notificationIcon = environment.notificationShow
    this.getCanData()
  }

  async getCanData() {
    this.userInfo = getSessionValue('USER_INFO')
    this.candidateInfo = getSessionValue('CANDIDATE_PROFILE')

    this.firstName = this.userInfo.first_name
    this.lastName = this.userInfo.last_name

    this.email = this.userInfo.email
    this.phonenumber = this.userInfo.primary_phone
    this.country = this.userInfo.country
    this.dob = this.userInfo.dob

    this.canid = this.candidateInfo.id
    this.gender = this.candidateInfo.gender
  }

  get f() {
    return this.candidateProfileForm.controls
  }

  async submit() {
    this.submitted = true

    if (this.candidateProfileForm.valid) {
      this.userInfo.email_id = this.candidateProfileForm.value.email
      this.userInfo.first_name = this.candidateProfileForm.value.firstName
      this.userInfo.last_name = this.candidateProfileForm.value.lastName
      this.userInfo.primary_phone = this.candidateProfileForm.value.phonenumber
      this.userInfo.country = this.candidateProfileForm.value.country
      this.userInfo.dob = this.candidateProfileForm.value.dob

      this.candidateInfo.gender = this.candidateProfileForm.value.gender

      try {
        const [updateUserResponse, updateCandidateResponse] = await Promise.all([
          this.userService.updateSelf(this.userInfo).toPromise(),
          this.candidateService.updateSelf(this.candidateInfo).toPromise()
        ])

        if (updateUserResponse.success) {
          sessionStorage.setItem('USER_INFO', JSON.stringify(updateUserResponse.data))
          this.toastr.success('User Info Updated.')
        } else {
          this.toastr.error("User info couldn't be updated.")
        }

        if (updateCandidateResponse.success) {
          sessionStorage.setItem('CANDIDATE_PROFILE', JSON.stringify(updateCandidateResponse.data))
        } else {
          this.toastr.error("Candidate info couldn't be updated.")
        }

        this.editFormFlag =
          updateUserResponse.success && updateCandidateResponse.success ? false : this.editFormFlag
      } catch (error) {
        this.toastr.error('An error occurred while updating.')
      }
    }
  }

  editCandidateForm() {
    this.editFormFlag = true
  }

  async updateHubspotCandidate(body) {
    if (body?.hubspotId) {
      await this.CRUDService.updateCandidateHubspot(body).toPromise()
    } else {
      const result: any = await this.CRUDService.createCandidateHubspot(body).toPromise()

      if (result.success == true) {
        const userDetails = await this.CRUDService.getDataByField(
          'Users',
          'id',
          this.userInfo?.id
        ).toPromise()

        sessionStorage.setItem('USER_INFO', JSON.stringify(userDetails[0]))
      }
    }
  }
}
