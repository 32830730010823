<div class="modal-content">
  <div class="modal-header">
    <h6 class="circularBold signUpHeading">Profile Info</h6>
    <button
      type="button"
      class="close ml-2"
      aria-label="Close"
      data-dismiss="modal"
      (click)="closeModal()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="profileForm" autocomplete="off">
      <mat-form-field appearance="fill" class="loginInputNewDropdown">
        <input
          matInput
          [matDatepicker]="picker"
          [formControl]="profileForm.controls['dob']"
          style="font-weight: 500; font-size: 16px"
          placeholder="DOB (DD/MM/YYYY)"
          [min]="maxDate"
          [max]="minDate"
        />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
        <div *ngIf="picker.errors">{{ picker }}</div>
      </mat-form-field>
      <div fxLayout="row wrap" class="align-items-center m-t-15 m-b-15">
        <div fxFlex.gt-md="50" fxFlex.gt-lg="50" fxFlex="100">
          <div class="profile-form language-profile">
            <mat-form-field class="example-full-width" appearance="fill">
              <input
                class="example-full-width loginInputNew"
                type="email"
                matInput
                [formControl]="profileForm.controls['email']"
                style="width: 100%"
                placeholder="Email"
              />
            </mat-form-field>
          </div>
        </div>
      </div>
      <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
        <div
          *ngIf="
            submitted && profileForm.controls.email.dirty && profileForm.controls['email'].errors
          "
          class="alert error-message"
        >
          <span *ngIf="profileForm.controls['email'].errors.required"> Please enter email </span>
          <span *ngIf="profileForm.controls['email'].errors.email"> Enter Valid email Id </span>
          <span *ngIf="profileForm.controls['email'].errors.maxlength">
            Please Enter
            {{ profileForm.controls['email'].errors.maxlength.requiredLength }} characters
          </span>
        </div>
      </div>
      <div fxLayout="row wrap" class="align-items-center m-t-15 m-b-15">
        <div fxFlex.gt-md="25" fxFlex.gt-lg="25" fxFlex="100">
          <div class="profile-form language-profile">
            <ngx-intl-tel-input
              [cssClass]="'custom input-intl'"
              [enableAutoCountrySelect]="true"
              [enablePlaceholder]="false"
              [separateDialCode]="true"
              [searchCountryFlag]="true"
              placeholder="Phone"
              [selectedCountryISO]="CountryISO.Australia"
              [selectFirstCountry]="true"
              [minLength]="6"
              [maxLength]="12"
              style="text-align: center"
              [phoneValidation]="true"
              name="phone"
              [formControl]="profileForm.controls['mobile']"
              (input)="checkNumberValidity(profileForm.controls.mobile.value)"
            >
            </ngx-intl-tel-input>
            <div
              *ngIf="invalidPhoneNumber && profileForm.value.mobile !== null"
              class="alert error-message"
            >
              <span> Please enter a valid phone number </span>
            </div>
          </div>
        </div>
      </div>
      <div style="text-align: right">
        <button mat-raised-button class="like-btn" (click)="profileSubmit()">Submit</button>
      </div>
    </form>
  </div>
</div>
