import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable, of, throwError } from 'rxjs'
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http'
import { environment } from '../../../environments/environment'
import { catchError, finalize, map, tap } from 'rxjs/operators'
import { Router } from '@angular/router'
import { ToastrService } from 'ngx-toastr'
import {
  ValuesMapListResponse,
  ValuesMap,
  ValuesMapGroupsResponse,
  NewValuesMap
} from 'src/app/core/models/values-map'
import { ApiResponse, ApiResponseNoData } from 'src/app/core/models/base'
// import { StateCreator, ZustandBaseService } from "ngx-zustand";

interface ValuesMapState {
  valuesMaps: ValuesMap[]
  loadValuesMap: () => Promise<void>
  getValuesMapList: (value_group: string) => Promise<ValuesMap[]>
}

@Injectable()
export class ValuesMapService {
  // initStore(): StateCreator<ValuesMapState> {
  //   return (set, get) => ({
  //     valuesMaps: [],
  //     getValuesMapList: async (value_group: string) => {
  //       const isEmpty = get().valuesMaps.length === 0;
  //       if (isEmpty) {
  //         await get().loadValuesMap();
  //       }
  //       return get().valuesMaps.filter((v) => v.value_group === value_group);
  //     },
  //     loadValuesMap: async () => {
  //       this.getFilteredValuesMap(10000, 0).subscribe((res) => {
  //         set({ valuesMaps: res.data.items });
  //       });
  //     },
  //   });
  // }

  constructor(
    private http: HttpClient,
    private router: Router,
    private toastr: ToastrService
  ) {
    // super();
  }

  getFilteredValuesMap(
    limit: number = 50,
    offset: number = 0,
    q: string = '',
    value_group = undefined
  ) {
    return this.http
      .get<ValuesMapListResponse>(`${environment.apiUrl}/v1/values-map`, {
        params: new HttpParams()
          .set('limit', limit.toString())
          .set('offset', offset.toString())
          .set('q', q)
          .set('value_group', value_group)
      })
      .pipe(
        map(
          (res) => {
            return res
          },
          (err) => {
            console.log('values map list', err)
          }
        )
      )
  }

  getValuesMapGroups() {
    return this.http
      .get<ValuesMapGroupsResponse>(`${environment.apiUrl}/v1/values-map/groups`)
      .pipe(
        map(
          (res) => {
            return res
          },
          (err) => {
            console.log('values map list', err)
          }
        )
      )
  }

  addNewValuesMap(valuesMap: NewValuesMap) {
    return this.http.post<ApiResponseNoData>(`${environment.apiUrl}/v1/values-map`, valuesMap).pipe(
      map((res) => {
        this.toastr.success('Values Map added successfully')
        return res
      })
    )
  }

  updateValueMap(valuesMap: NewValuesMap) {
    return this.http
      .put<ApiResponseNoData>(`${environment.apiUrl}/v1/values-map/${valuesMap.id}`, valuesMap)
      .pipe(
        map((res) => {
          this.toastr.success('Values Map updated successfully')
          return res
        })
      )
  }

  deleteValueMap(id: number) {
    return this.http.delete<ApiResponseNoData>(`${environment.apiUrl}/v1/values-map/${id}`).pipe(
      map((res) => {
        this.toastr.success('Values Map deleted successfully')
        return res
      })
    )
  }

  deleteValueMapGroup(value_group: string) {
    return this.http
      .delete<ApiResponseNoData>(`${environment.apiUrl}/v1/values-map/values-group/${value_group}`)
      .pipe(
        map((res) => {
          this.toastr.success('Values Map Group deleted successfully')
          return res
        })
      )
  }
}
