<button
  mat-icon-button
  color="warn"
  aria-label="Example icon button with a heart icon"
  matTooltip="Notifications"
  (click)="showNotification()"
  [class.border]="notificationCount !== '0'"
>
  <span
    *ngIf="notificationCount !== '0'"
    matBadge="{{ notificationCount }}"
    matBadgeOverlap="false"
  >
    <mat-icon class="bell-icon">notifications</mat-icon>
  </span>
  <mat-icon *ngIf="notificationCount === '0'">notifications</mat-icon>
</button>
