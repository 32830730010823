<div>
  <div class="close-dialog-div">
    <button
      type="button"
      class="close"
      aria-label="Close"
      data-dismiss="modal"
      (click)="closeModal()"
    >
      <span class="close-icon" aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-header">
    <h1 class="circularBold signUpHeading">Congratulations, your role is live on Upplft!</h1>
  </div>
  <div class="modal-body">
    <div
      fxLayout="row wrap"
      class="text-center"
      style="margin-top: 10px; margin-left: 5%; margin-right: 5%"
    >
      <div
        fxFlex.gt-sm="100"
        fxFlex.gt-xs="100"
        fxFlex="100"
        style="margin-top: 10px; display: flex"
      >
        <p class="description-text">
          The next step, whilst we match your role to our current candidtes is to post the role
          across the usual channels (e.g. LinkedIN, Seek, Website).
          <br />
          <br />
          We then match all applicants that apply for your role, taking the pain away from filtering
          through hundreads of candidates to a single shortlist.
        </p>
      </div>

      <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100" style="margin-top: 15px">
        <mat-form-field appearance="fill" style="width: 70%">
          <input matInput type="text" disabled [value]="roleLink" />
          <button mat-button mat-icon-button matSuffix>
            <mat-icon
              [cdkCopyToClipboard]="roleLink"
              (cdkCopyToClipboardCopied)="copyLink($event)"
              [matTooltip]="isCopied ? 'Copied' : 'Copy'"
              matTooltipPosition="above"
              matSuffix
              style="cursor: pointer"
            >
              content_copy
            </mat-icon>
          </button>
        </mat-form-field>
      </div>

      <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
        <button
          matRipple
          mat-button
          class="btn-lg m-t-20 m-b-20 loginButton"
          style="width: 70%"
          [cdkCopyToClipboard]="roleLink"
          (cdkCopyToClipboardCopied)="copyLink($event)"
        >
          Copy Link
        </button>
      </div>
    </div>
  </div>
</div>
