<div class="body" fxLayout="row wrap">
  <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
    <div class="row col-12 pl-0">
      <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12">
        <div class="postsBox">
          <div class="row col-12 text-center pr-0">
            <div
              [ngClass]="
                notificationIcon
                  ? 'col-xs-12 col-sm-12 col-md-11 col-lg-11 pl-0 pr-0 search'
                  : 'col-xs-12 col-sm-12 col-md-12 col-lg-12 pl-0 pr-0 search'
              "
            >
              <app-header-search-bar></app-header-search-bar>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-1 col-lg-1" *ngIf="notificationIcon">
              <app-header-notification-button [previousPath]="previousPath">
              </app-header-notification-button>
            </div>
          </div>
        </div>
        <hr />
        <div class="postsBox pl-4 pr-4 ease-in">
          <div class="mt-3 mb-3">
            <i
              class="fa fa-angle-left back-icon"
              aria-hidden="true"
              (click)="closeAboutUpplft()"
            ></i>
            <label class="heading-text font-18">{{ topic }}</label>
          </div>
          <p class="text-justify p-3" [innerHTML]="content"></p>
        </div>
      </div>
      <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
        <app-candidate-profile-overview-verified
          *ngIf="role === 'candidate'"
          style="margin-top: 90px"
        >
        </app-candidate-profile-overview-verified>
        <app-profile-overview-verified *ngIf="role === 'business'"></app-profile-overview-verified>
        <app-partner-profile-oberview-verified
          *ngIf="role === 'partner'"
        ></app-partner-profile-oberview-verified>
      </div>
    </div>
  </div>
</div>
