<div class="modal-content ease-in">
  <div class="modal-header">
    <h6 class="circularBold signUpHeading">Post a Role</h6>
    <!-- <mat-select class="skip-btn select-btn" name="role" placeholder="Anyone">
            <mat-option value="1">
                Anyone
            </mat-option>
            <mat-option value="2">
                Private
            </mat-option>
        </mat-select> -->
    <!-- <select class="form-control skip-btn select-btn">  
            <option>Select Website</option>  
            <option>Choose Website</option>  
        </select>   -->
    <!-- <div class="dropdown skip-btn select-btn">
            <button class="dropdown-toggle dropdown-btn d-flex align-item-center" type="button" data-toggle="dropdown">
                <div>{{option}}</div>
                <div class="triangle"></div>
            </button>
            <div class="dropdown-menu">
                <a class="dropdown-item" *ngFor="let option of DropdownOptions"
                    (click)="selectOption(option)">{{option}}</a>
            </div>
        </div> -->
    <div class="d-flex">
      <div *ngIf="userType == 3">
        <button
          mat-raised-button
          class="candidate-btn"
          (click)="viewShortlistedCandidates(roleDetails)"
        >
          Shortlisted Candidates
        </button>
        <button mat-raised-button class="candidate-btn" (click)="viewRegisteredCandidates()">
          Registered Candidates
        </button>
        <button mat-raised-button class="candidate-btn" (click)="viewAppliedCandidates()">
          Applied Candidates
        </button>
      </div>
      <span class="dropdown" *ngIf="roleDetails?.id">
        <button
          mat-raised-button
          class="dropdown-toggle edit-post-role-btn"
          id="dropdownMenuButton"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <mat-icon>more_vert</mat-icon>
        </button>
        <div
          class="dropdown-menu"
          aria-labelledby="dropdownMenuButton"
          *ngIf="roleDetails?.status != '' && roleDetails?.status != nul"
        >
          <a *ngIf="roleDetails?.status != '1'" class="dropdown-item" (click)="reOpenRole()"
            >Re-Open Role</a
          >
          <a *ngIf="roleDetails?.status != '3'" class="dropdown-item" (click)="holdPosting()"
            >Hold Role</a
          >
          <a *ngIf="roleDetails?.status != '4'" class="dropdown-item" (click)="closePosting()"
            >Close Role</a
          >
          <a
            *ngIf="roleDetails?.status != '2'"
            class="dropdown-item"
            (click)="openDeleteModal(deleteModal, 'role')"
            >Delete Role</a
          >
        </div>
      </span>
      <button
        type="button"
        class="close ml-2"
        aria-label="Close"
        data-dismiss="modal"
        (click)="closeModal()"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>
  <div class="modal-body">
    <ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
      <p style="color: white">Please Wait.</p>
    </ngx-spinner>
    <form [formGroup]="postRoleForm" autocomplete="off">
      <div class="d-flex mb-3">
        <img
          src="{{ businessProfile?.logo_url }}"
          class="post-job-img profile-img"
          onerror="this.onerror=null;this.src='assets/images/business/business_logo.png'"
        />
        <label class="ml-3">{{ businessProfile?.name }}</label>
      </div>
      <mat-form-field appearance="fill" class="required-container">
        <input type="text" formControlName="role" matInput [(ngModel)]="roleTitle" />
        <div class="placeholder">Role Title <span>*</span></div>
      </mat-form-field>
      <div *ngIf="submitted && postRoleForm.controls['role'].errors" class="alert error-message">
        <span *ngIf="postRoleForm.controls['role'].errors.required"> Please Enter Role Title </span>
        <span *ngIf="postRoleForm.controls['role'].errors.maxlength">
          Please Enter
          {{ postRoleForm.controls['role'].errors.maxlength.requiredLength }}
          characters
        </span>
      </div>

      <!-- Role Type -->

      <div
        class="d-flex row wrap col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pl-0 pr-0 role-type-div"
      >
        <div class="col-12 col-sm-6 col-lg-6 col-md-6 pl-0 pr-1 role-type-select">
          <mat-form-field appearance="fill" class="required-container">
            <mat-select
              formControlName="roleType"
              name="roleType"
              [(ngModel)]="saveRoleType"
              #roleType
              (selectionChange)="selectRoleType($event.value)"
              class="required-select"
            >
              <mat-option *ngFor="let roleType of roleTypeArray" [value]="roleType">
                {{ roleType }}
              </mat-option>
            </mat-select>
            <div class="placeholder">Role Type <span>*</span></div>
          </mat-form-field>
          <div
            *ngIf="submitted && postRoleForm.controls['roleType'].errors"
            class="alert error-message"
          >
            <span *ngIf="postRoleForm.controls['roleType'].errors.required">
              Please Select Your Role Type
            </span>
          </div>
        </div>
        <div class="col-12 col-sm-6 col-lg-6 col-md-6 pl-1 pr-0 employment-type-select">
          <mat-form-field appearance="fill" class="select-mat required-container">
            <mat-select
              [(ngModel)]="saveEmploymentType"
              name="employmentType"
              formControlName="employmentType"
              (selectionChange)="onEmpTypeChange($event.value)"
              multiple
            >
              <mat-option
                *ngFor="let employmentType of employmentTypeArray"
                [value]="employmentType"
              >
                {{ employmentType }}
              </mat-option>
            </mat-select>
            <div class="placeholder">Employment Type <span>*</span></div>
          </mat-form-field>
          <div
            *ngIf="submitted && postRoleForm.controls['employmentType'].errors"
            class="alert error-message"
          >
            <span *ngIf="postRoleForm.controls['employmentType'].errors.required">
              Please Select Your Employment Type
            </span>
          </div>
        </div>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mx-auto" *ngIf="otherRoleTypeFlag">
        <mat-form-field class="example-full-width" appearance="fill">
          <input
            type="text"
            formControlName="roleType"
            matInput
            placeholder="Other Role Type"
            [(ngModel)]="saveRoleType"
          />
        </mat-form-field>
      </div>

      <mat-form-field appearance="fill" class="select-mat required-container">
        <mat-select [(ngModel)]="location" name="location" formControlName="location" multiple>
          <mat-option
            *ngFor="let location of locationData"
            [value]="location"
            [disabled]="disableLocation(location)"
          >
            {{ location }}
          </mat-option>
        </mat-select>
        <div class="placeholder">Location <span>*</span></div>
      </mat-form-field>
      <div
        *ngIf="submitted && postRoleForm.controls['location'].errors"
        class="alert error-message"
      >
        <span *ngIf="postRoleForm.controls['location'].errors.required">
          Please Select a Location
        </span>
      </div>
      <!-- <mat-form-field class="example-full-width" appearance="fill">
                <input type="text" formControlName="location" matInput placeholder="Location">
            </mat-form-field> -->

      <!-- Start Date and Remote Work? -->

      <mat-form-field appearance="fill" class="select-mat required-container">
        <mat-select [(ngModel)]="selectstart_by" name="start_by" formControlName="start_by">
          <mat-option *ngFor="let start_by of start_byOptions" [value]="start_by">
            {{ start_by }}
          </mat-option>
        </mat-select>
        <div class="placeholder">Preferred Start Date <span>*</span></div>
      </mat-form-field>
      <div
        *ngIf="submitted && postRoleForm.controls['start_by'].errors"
        class="alert error-message"
      >
        <span *ngIf="postRoleForm.controls['start_by'].errors.required">
          Please Select a Start Date
        </span>
      </div>

      <div class="remote-checkbox">
        <mat-checkbox formControlName="remoteWork"> Remote </mat-checkbox>
        <mat-checkbox style="margin-left: 15px" formControlName="hybrid"> Hybrid </mat-checkbox>
      </div>
      <!-- Role Description -->

      <mat-form-field appearance="fill" class="required-container">
        <textarea
          matInput
          rows="6"
          formControlName="roleDescription"
          [(ngModel)]="description"
        ></textarea>
        <div class="placeholder mt-7">
          Pitch your company and role, consider including - What is the company doing that makes it
          attractive to work at? Why has the role been created? What delivery or outcome is
          expected? Why would a candidate want to work at your company? Team Size / Structure?
          <span>*</span>
        </div>
      </mat-form-field>
      <div
        *ngIf="submitted && postRoleForm.controls['roleDescription'].errors"
        class="alert error-message"
      >
        <span *ngIf="postRoleForm.controls['roleDescription'].errors.required">
          Please Describe a Role
        </span>
        <span *ngIf="postRoleForm.controls['roleDescription'].errors.maxlength">
          Please Enter
          {{ postRoleForm.controls['roleDescription'].errors.maxlength.requiredLength }}
          characters
        </span>
      </div>

      <!-- Salary & DayRate -->

      <div class="d-flex row wrap col-12 pl-0 pr-0">
        <div class="col-sm-12 col-lg-6 col-md-12 pr-1 d-flex salary-div" *ngIf="showSalarySlider">
          <div class="custom-slider" style="width: 100%">
            <mat-label class="salary-label">Salary (base)</mat-label>
            <ngx-slider
              class="slider"
              [(value)]="min"
              [(highValue)]="max"
              [options]="sliderOptions"
              (userChange)="rangeChange($event)"
            >
            </ngx-slider>
          </div>

          <!-- <lib-ngx-mat-range-slider minColor="accent" maxColor="accent" min="50000" max="200000" formControlName="salary" value="60000"></lib-ngx-mat-range-slider> -->
          <!-- <mat-label>Salary</mat-label>
                        <mat-slider 
                        formControlName="salary" 
                        class="ml-5"
                        thumbLabel
                        [displayWith]="formatLabel"
                        tickInterval="10000"
                        step="10000"
                        min="500000"
                        max="2000000"
                        aria-label="units"
                        [(ngModel)]="salary"
                        >

                        </mat-slider> -->

          <!-- <mat-select formControlName="salary" name="salary" placeholder="Salary">
                            <mat-option *ngFor="let salary of salaryArray" [value]="salary.code">
                                {{salary.description}}
                            </mat-option>
                        </mat-select> -->
        </div>
        <div
          class="col-sm-12 col-lg-6 col-md-12 pr-0 d-flex day-rate-div"
          [ngClass]="showSalarySlider ? 'pl-1' : 'col-lg-12 pl-0'"
        >
          <mat-form-field appearance="fill">
            <mat-select
              formControlName="dayRate"
              name="role"
              placeholder="Day-rate (exc super)"
              [(ngModel)]="dayRate"
            >
              <mat-select-trigger class="select-trigger">
                <span class="text-truncate">
                  {{ dayRate }}
                </span>
                <mat-icon
                  *ngIf="dayRate"
                  class="mat-select-close-icon"
                  (click)="dayRate = null; $event.stopPropagation()"
                  >close</mat-icon
                >
              </mat-select-trigger>
              <mat-option *ngFor="let item of dayRateArray" [value]="item">
                {{ item }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="d-flex row wrap col-12 pl-0 pr-0">
        <div class="col-10 col-sm-11 col-lg-11 col-md-10 pl-0 pr-1">
          <mat-form-field appearance="fill">
            <mat-select
              formControlName="benefits"
              name="benefits"
              placeholder="Benefits"
              [(ngModel)]="saveBenefits"
              (selectionChange)="selectBenefits()"
            >
              <mat-option *ngFor="let benefits of benefitsArray" [value]="benefits">
                {{ benefits }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-2 col-sm-1 col-lg-1 col-md-2 pr-0 pl-1">
          <button
            mat-raised-button
            class="upload-btn"
            [disabled]="saveBenefits == null || saveBenefits == ''"
            (click)="addBenefits()"
          >
            +
          </button>
        </div>
      </div>

      <div
        class="row wrap col-xs-12 col-sm-12 col-md-12 col-lg-12 pl-0 pr-0"
        *ngIf="otherBenefitsFlag"
      >
        <mat-form-field class="example-full-width" appearance="fill">
          <input
            type="text"
            formControlName="otherBen"
            matInput
            placeholder="Other Benefits"
            [(ngModel)]="otherbenfits"
          />
        </mat-form-field>
      </div>
      <div class="row wrap col-xs-12 col-sm-12 col-lg-12 col-md-12 pl-0 pr-0">
        <mat-chip-list aria-label="Benefits Selection">
          <mat-chip *ngFor="let benefits of addedBenefitsArray; let i = index"
            >{{ benefits }}
            <i class="fa fa-times pl-2" (click)="removeBenefits(i)"></i>
          </mat-chip>
        </mat-chip-list>
      </div>

      <!-- Soft Skills -->

      <div class="d-flex row wrap col-12 pl-0 pr-0 mt-2">
        <div class="col-10 col-sm-11 col-lg-11 col-md-10 pl-0 pr-1">
          <mat-form-field appearance="fill">
            <mat-select
              formControlName="softSkills"
              name="softSkills"
              placeholder="Soft Skills (up to 10)"
              [(ngModel)]="saveSoftSkill"
              [disabled]="addedSoftSkillsArray.length === 10 || addedSoftSkillsArray.length > 10"
            >
              <mat-option *ngFor="let softSkill of softSkillsArray" [value]="softSkill">
                {{ softSkill }}
              </mat-option>
            </mat-select>
            <div
              *ngIf="submitted && postRoleForm.controls['softSkills'].errors"
              class="alert error-message"
            >
              <span *ngIf="postRoleForm.controls['softSkills'].errors.required">
                Please Select Soft Skills
              </span>
            </div>
          </mat-form-field>
        </div>
        <div class="col-2 col-sm-1 col-lg-1 col-md-2 pr-0 pl-1">
          <button
            mat-raised-button
            class="upload-btn"
            [disabled]="
              saveSoftSkill == null ||
              saveSoftSkill == '' ||
              addedSkillsArray.length === 10 ||
              addedSkillsArray.length > 10
            "
            (click)="addSoftSkills()"
          >
            +
          </button>
        </div>
      </div>
      <div class="d-flex row wrap col-xs-12 col-sm-12 col-lg-12 col-md-12 pl-0 pr-0">
        <mat-chip-list aria-label="Soft Skills Selection">
          <mat-chip *ngFor="let softSkills of addedSoftSkillsArray; let i = index">
            {{ softSkills }}
            <i class="fa fa-times pl-2" (click)="removeSoftSkills(i)"></i>
          </mat-chip>
        </mat-chip-list>
      </div>

      <div class="d-flex row wrap col-12 pl-0 pr-0 mt-2 skill-field">
        <div class="col-12 col-sm-6 col-lg-6 col-md-5 pl-0 pr-1">
          <mat-form-field appearance="fill">
            <mat-icon matPrefix class="search-icon">search</mat-icon>
            <input
              type="text"
              [matAutocomplete]="domain"
              matInput
              placeholder="Add Domain"
              [(ngModel)]="selectedDomain"
              (keyup)="valueChange()"
              (click)="valueChange()"
              [disabled]="addedDomainAndExpArray.length === 5 || addedDomainAndExpArray.length > 5"
              formControlName="domain"
            />

            <mat-autocomplete autoActiveFirstOption #domain="matAutocomplete">
              <mat-option *ngFor="let domain of domainFilteredValue" [value]="domain">
                {{ domain }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
        <div class="col-10 col-sm-5 col-lg-5 col-md-5 pl-1 pr-1 add-experience-div">
          <mat-form-field appearance="fill">
            <mat-icon matPrefix class="search-icon">search</mat-icon>
            <input
              type="text"
              [matAutocomplete]="exp"
              matInput
              placeholder="Add Experience (Years)"
              [(ngModel)]="selectedExp"
              (keyup)="valueChange()"
              (click)="valueChange()"
              [disabled]="addedDomainAndExpArray.length === 5 || addedDomainAndExpArray.length > 5"
              formControlName="experience"
            />

            <mat-autocomplete autoActiveFirstOption #exp="matAutocomplete">
              <mat-option *ngFor="let exp of expArray" [value]="exp">
                {{ exp }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>

          <!-- <mat-form-field appearance="fill" class="ml-2">
                        <mat-select placeholder="Select Relevant Experience"
                            formControlName="experience" [(ngModel)]="selectedExp">
                            <mat-option *ngFor="let exp of expArray" [value]="exp">
                                {{exp}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field> -->
        </div>

        <div class="col-2 col-sm-1 col-lg-1 col-md-2 pr-0 pl-1">
          <button
            mat-raised-button
            class="upload-btn"
            [disabled]="
              selectedDomain == null ||
              selectedDomain == '' ||
              selectedExp == null ||
              selectedExp == '' ||
              addedDomainAndExpArray.length === 5 ||
              addedDomainAndExpArray.length > 5
            "
            (click)="addDomainExp()"
          >
            +
          </button>
        </div>
      </div>

      <div class="d-flex row wrap col-xs-12 col-sm-12 col-lg-12 col-md-12 pl-0 pr-0">
        <mat-chip-list aria-label="Domain and Experience Selection">
          <mat-chip *ngFor="let item of addedDomainAndExpArray; let i = index">
            {{ item }}
            <i class="fa fa-times pl-2" (click)="removeDomainAndExp(i)"></i>
          </mat-chip>
        </mat-chip-list>
      </div>

      <div class="d-flex row wrap col-12 pl-0 pr-0 mt-2">
        <div class="col-10 col-sm-11 col-lg-11 col-md-10 pl-0 pr-1">
          <mat-form-field appearance="fill" class="skill-field">
            <mat-icon matPrefix class="search-icon">search</mat-icon>
            <input
              type="text"
              aria-label="Number"
              [matAutocomplete]="auto"
              matInput
              placeholder="Add up to 10 technical skills"
              [(ngModel)]="saveSkills"
              [ngModelOptions]="{ standalone: true }"
              (keyup)="companyValueChange()"
              (click)="companyValueChange()"
              [disabled]="addedSkillsArray.length === 10 || addedSkillsArray.length > 10"
            />
            <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
              <mat-option
                *ngFor="let option of companyFilteredOptions"
                [value]="option"
                (click)="companyValue = option; companyValueChange()"
              >
                {{ option }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
          <!-- <mat-form-field appearance="fill">
                        <mat-select formControlName="skills" name="skills" placeholder="Add 10 required skills" [(ngModel)] = "saveSkills">
                            <mat-option *ngFor="let skills of skillsArray" [value]="skills">
                                {{skills}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field> -->
        </div>
        <div class="col-2 col-sm-1 col-lg-1 col-md-2 pr-0 pl-1">
          <button
            mat-raised-button
            class="upload-btn"
            [disabled]="
              saveSkills == null ||
              saveSkills == '' ||
              addedSkillsArray.length === 10 ||
              addedSkillsArray.length > 10
            "
            (click)="addSkills(saveSkills)"
          >
            +
          </button>
        </div>
      </div>
      <div class="d-flex row wrap col-xs-12 col-sm-12 col-lg-12 col-md-12 pl-0 pr-0">
        <mat-chip-list aria-label="Skills Selection">
          <mat-chip *ngFor="let skill of addedSkillsArray; let i = index"
            >{{ skill }}
            <i class="fa fa-times pl-2" (click)="removeSkills(i)"></i>
          </mat-chip>
        </mat-chip-list>
      </div>
      <div>
        <div class="personality-header">
          <label>Personality</label>
          <div>
            <button
              *ngIf="!personalityTypeDetails?.length"
              mat-button
              class="personality-button"
              (click)="openPersonalityRankSelectionModal()"
            >
              Select
            </button>
            <button
              *ngIf="personalityTypeDetails?.length"
              mat-button
              class="personality-button"
              (click)="openScoreModal()"
            >
              View
            </button>
            <button
              *ngIf="personalityTypeDetails?.length"
              mat-button
              class="personality-button update-button ml-2"
              (click)="openPersonalityRankSelectionModal('update')"
            >
              Update
            </button>
          </div>
        </div>
        <div *ngIf="!personalityTypeDetails?.length">
          <p class="description-text">Let us know what you're looking for in a candidate.</p>
          <p class="description-text">
            To quickly fill out your preferences, please choose Select.
          </p>
        </div>
        <div *ngIf="personalityTypeDetails?.length" class="personality-container">
          <div class="d-flex justify-content-between">
            <div>
              <div class="mb-2 personality-archetype">
                <label
                  ><b>{{ personalityTypeData?.description?.Name }}</b></label
                >
              </div>
              <div class="mb-2 personality-details">
                <label><b>Facets: </b>{{ personalityTypeData?.description?.Facets }}</label>
              </div>
              <div class="mb-2 personality-details">
                <label><b>Adjectives: </b>{{ personalityTypeData?.description?.Adjectives }}</label>
              </div>
              <div class="personality-details">
                <label
                  >To review your choices click 'View' or to revise your choices, click
                  'Update'</label
                >
              </div>
            </div>
            <div>
              <!-- <img class="personality-img" src="assets/images/upplft/personality-1.png"> -->
              <img
                class="personality-img"
                src="'../../../../assets/images/archetypes/{{
                  personalityTypeDetails[0]?.archetype_code
                }}.png"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="pt-4">
        <div class="personality-header">
          <label>Team Culture</label>
          <div>
            <button
              *ngIf="!cultureDetails?.length"
              mat-button
              class="personality-button"
              (click)="openCultureBuilder()"
            >
              Select
            </button>
            <button
              *ngIf="cultureDetails?.length"
              mat-button
              class="personality-button"
              (click)="openCultureScoreModal()"
            >
              View
            </button>
            <button
              *ngIf="cultureDetails?.length"
              mat-button
              class="personality-button update-button ml-2"
              (click)="openCultureBuilder()"
            >
              Update
            </button>
          </div>
        </div>
        <div *ngIf="!cultureDetails?.length">
          <p class="description-text">Let us know the teams culture so we can match a candidate.</p>
          <p class="description-text">
            To quickly fill out your preferences, please choose Select.
          </p>
        </div>
        <div *ngIf="cultureDetails?.length" class="personality-container">
          <div class="d-flex justify-content-between">
            <div>
              <div class="mb-2 personality-archetype">
                <label
                  ><b>{{ cultureInfo?.name }}</b></label
                >
              </div>
              <div class="mb-2 personality-details">
                <label> <b>Thrust: </b>{{ cultureInfo?.description?.thrusts }} </label>
              </div>
              <div class="mb-2 personality-details">
                <label> <b>Means: </b>{{ cultureInfo?.description?.means }} </label>
              </div>
              <div class="mb-2 personality-details">
                <label> <b>Ends: </b>{{ cultureInfo?.description?.ends }} </label>
              </div>
              <div class="personality-details">
                <label>
                  To review your choices click 'View' or to revise your choices, click 'Update'
                </label>
              </div>
            </div>
            <div>
              <!-- <img class="personality-img" src="assets/images/upplft/personality-1.png"> -->
              <img
                class="personality-img"
                src="'../../../../assets/images/cultures/{{ cultureInfo?.name }}.jpeg"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="mt-2">
        <div *ngIf="imageSrc !== null && imageSrc.length !== 0" class="row">
          <div *ngFor="let image of imageSrc; let i = index" class="column image-section">
            <i
              class="fa fa-window-close pl-2"
              aria-hidden="true"
              (click)="removeImg(i); removeAttachment(image)"
              style="position: absolute; right: 16px; top: 15px; color: #ec3b83"
            ></i>
            <img
              style="margin-bottom: 10px; z-index: -1"
              class="w-100"
              src="{{ image.file_url }}"
            />
          </div>
        </div>
        <div *ngIf="videoUrl !== undefined && videoUrl.length !== 0" class="row">
          <div *ngFor="let video of videoUrl; let i = index" class="column video-section">
            <i
              class="fa fa-window-close pl-2"
              aria-hidden="true"
              (click)="removeVideo(i); removeAttachment(video)"
              style="position: absolute; right: 0px; top: 0px; color: #ec3b83"
            ></i>
            <video
              style="border-radius: 3px"
              class="w-100"
              src="{{ video.file_url }}"
              controls
            ></video>
          </div>
        </div>
        <div *ngIf="audioUrl !== undefined && audioUrl.length !== 0" class="row">
          <div *ngFor="let audio of audioUrl; let i = index" class="column audio-section">
            <i
              class="fa fa-window-close pl-2"
              aria-hidden="true"
              (click)="removeAudio(i); removeAttachment(audio)"
              style="position: absolute; right: 0px; top: 0px; color: #ec3b83"
            ></i>
            <audio class="w-100" controls src="{{ audio.file_url }}"></audio>
          </div>
        </div>
        <div *ngIf="docUrl !== undefined && docUrl.length !== 0">
          <mat-chip-list aria-label="Docs Selection">
            <mat-chip
              *ngFor="let doc of docUrl; let i = index"
              (click)="downloadFile(doc.file_url)"
            >
              {{ doc.file_name }}
              <i class="fa fa-times pl-2" (click)="removeDoc(i); removeAttachment(doc)"></i>
            </mat-chip>
          </mat-chip-list>
          <!-- <label *ngFor="let doc of docUrl" (click)="downloadFile(doc.url)" class="file-css">
                        {{doc.file_name}}
                    <i class="fa fa-times pl-2" (click)="removeSkills(i);"></i>

                    </label> -->
        </div>
      </div>
      <div class="d-flex col-12 pl-0 pr-0 mt-5">
        <div class="col-sm-9 col-lg-9 col-md-9 pl-0 pr-1 d-flex">
          <div class="custom-file-upload" style="margin-right: 15px" [matTooltip]="'Image'">
            <ngx-dropzone
              class="dropzone cursor-pointer"
              [multiple]="false"
              (change)="uploadImageDropProfile($event, 'image')"
              accept="image/jpeg, image/gif, image/png, image/webp"
            >
              <img src="../../../../assets/profile-builder/Image_2.svg" />
            </ngx-dropzone>
          </div>
          <!-- <label for="image-upload" class="custom-file-upload">
                        <img id="blah" src="../../../../assets/profile-builder/Image_2.svg">
                    </label>
                    <input id="image-upload" type="file" accept="image/*"
                        (change)="uploadImageProfile($event,'image')" /> -->

          <label
            for="video-upload"
            class="custom-file-upload cursor-pointer"
            [matTooltip]="'Video'"
          >
            <img id="blah" src="../../../../assets/profile-builder/Play.svg" />
          </label>
          <input
            id="video-upload"
            type="file"
            accept="video/*"
            (change)="uploadAudioVideoDoc($event, 'video')"
          />

          <label
            for="audio-upload"
            class="custom-file-upload cursor-pointer"
            [matTooltip]="'Audio'"
          >
            <img id="blah" src="../../../../assets/profile-builder/Voice_2.svg" />
          </label>
          <input
            id="audio-upload"
            type="file"
            accept="audio/mp3,audio/*;capture=microphone"
            (change)="uploadAudioVideoDoc($event, 'audio')"
          />

          <label
            for="doc-upload"
            class="custom-file-upload cursor-pointer"
            [matTooltip]="'Document'"
          >
            <img
              id="blah"
              src="../../../../assets/profile-builder/Document.svg"
              accept=".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf"
            />
          </label>
          <input id="doc-upload" type="file" (change)="uploadAudioVideoDoc($event, 'doc')" />
        </div>
        <div class="col-sm-3 col-lg-3 col-md-3 pr-0 pl-1">
          <button
            mat-raised-button
            class="like-btn"
            (click)="submitPostRole()"
            [disabled]="!postRoleForm.valid"
          >
            Post
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
<ng-template #deleteModal class="modal-content">
  <div class="delete-modal-header">
    <h4 class="modal-title">Confirmation</h4>
    <button type="button" class="close" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">Are you sure want to delete {{ target }}?</div>
  <div class="modal-footer">
    <button mat-raised-button class="confirm-button" (click)="deleteRole()">Yes</button>
    <button mat-raised-button class="cancle-button" (click)="closeModal()">No</button>
  </div>
</ng-template>
<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
  <p style="color: white">Please Wait.</p>
</ngx-spinner>
