<div class="ease-in">
  <div class="close-dialog-div">
    <button
      type="button"
      class="close"
      aria-label="Close"
      data-dismiss="modal"
      (click)="closeModal()"
    >
      <span class="close-icon" aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-header">
    <h1 *ngIf="!rejectConnection" class="circularBold signUpHeading">
      Do you want to connect with {{ companyName }}?
    </h1>
    <h1 *ngIf="rejectConnection" class="circularBold signUpHeading">
      Please tell us why you don't want to connect
    </h1>
  </div>
  <div class="modal-body">
    <div
      fxLayout="row wrap"
      class="text-center"
      style="margin-top: 10px; margin-left: 5%; margin-right: 5%"
    >
      <div
        fxFlex.gt-sm="100"
        fxFlex.gt-xs="100"
        fxFlex="100"
        style="margin-top: 10px; display: flex"
      >
        <p *ngIf="!rejectConnection" class="description-text">
          Once you accept the connection, you can book a time slot to meet the team.
          <br />
          <br />
          But if the roles not for you, it's ok, {{ companyName }} have not been shown any
          identifiable information and will just be informed that a Candidate isn't interested in
          connecting.
          <br />
          <br />
          And in the background we will keep matching you to roles.
        </p>
        <p *ngIf="rejectConnection" class="description-text">
          If you let us know why you weren't interested in the role we can use that information to
          provide better matches in the future.
        </p>
      </div>

      <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
        <div class="d-flex justify-content-end">
          <button
            *ngIf="!rejectConnection"
            matRipple
            mat-button
            (click)="acceptConnection()"
            class="btn-lg m-t-10 m-b-10 mr-2 loginButton"
          >
            Yes
          </button>
          <button
            *ngIf="!rejectConnection"
            matRipple
            mat-button
            (click)="rejectConnection = true"
            class="btn-lg m-t-10 m-b-10 loginButton reject-button"
          >
            No Thanks
          </button>
        </div>
        <div>
          <mat-form-field *ngIf="rejectConnection" appearance="fill">
            <mat-select placeholder="Select Option" [(ngModel)]="selectedReason">
              <mat-option *ngFor="let reason of rejectReason" [value]="reason">
                {{ reason }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <button
            *ngIf="rejectConnection"
            matRipple
            mat-button
            (click)="reject()"
            class="btn-lg m-t-10 m-b-10 loginButton float-right"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
