import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { Router } from '@angular/router'
import { BsModalRef } from 'ngx-bootstrap/modal'
import { CRUDService } from 'src/app/crud.service'
import { getSessionValue } from 'src/app/common-utils'
import { environment } from 'src/environments/environment'
import { ToastrService } from 'ngx-toastr'
import { AuthService } from 'src/app/shared/auth/auth.service'
import { CandidateService } from 'src/app/shared/services/candidate.service'

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  role: any
  changePasswordForm: FormGroup
  bsModalRef: BsModalRef
  notificationCount: any
  previousPath: any
  passwordFlag = false
  passwordHide = true
  currentpasswordHide = true
  confirmPasswordHide = true
  submitted = false
  userInfo: any
  invalidCurrentPassword = false
  max = 7
  min = 0
  showTicks = false
  step = 1
  thumbLabel = false
  vertical = false
  tickInterval = 1
  samePassword = false
  value: any = 0
  autoTicks = false
  disabled = false
  invert = false
  notificationIcon: boolean = false
  color = ['#F62424', '#FFB573', '#FFB573', '#00AB8B', '#00AB8B']
  constructor(
    private formbuilder: FormBuilder,
    private router: Router,
    private CrudService: CRUDService,
    private authService: AuthService,
    private candidateService: CandidateService,
    private toastr: ToastrService
  ) {}

  async ngOnInit(): Promise<void> {
    this.previousPath = '/dashboards/setting/change-password'
    this.userInfo = getSessionValue('USER_INFO')
    this.role = localStorage.getItem('role')
    this.changePasswordForm = this.formbuilder.group({
      currentpassword: ['', [Validators.required]],
      password: [
        '',
        [
          Validators.required,
          Validators.pattern(
            '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-zd$@$!%*?&].{7,}'
          )
        ]
      ],
      confirmPassword: ['', [Validators.required]]
      // email: ['', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,3}$')]],
    })
    let userRes = await this.candidateService.getSelf().toPromise()
    this.userInfo = userRes.data
    this.notificationIcon = environment.notificationShow
  }
  closeChangePaassword() {
    if (this.role === 'candidate') {
      this.router.navigate(['/dashboards/candidate-settings'])
    } else if (this.role === 'business') {
      this.router.navigate(['/dashboards/business-settings'])
    } else if (this.role === 'partner') {
      this.router.navigate(['/dashboards/partner-settings'])
    } else {
      this.router.navigate(['/dashboards/candidate-settings'])
    }
  }
  checkPassword() {
    if (
      this.changePasswordForm.controls.password.value ==
      this.changePasswordForm.controls.currentpassword.value
    ) {
      this.samePassword = true
    } else {
      this.samePassword = false
    }

    if (
      this.changePasswordForm.controls.password.value !==
      this.changePasswordForm.controls.confirmPassword.value
    ) {
      this.passwordFlag = true
    } else {
      this.passwordFlag = false
    }
  }

  validateCurrentPassword() {
    this.invalidCurrentPassword = true
    const currentPassword = this.changePasswordForm.controls.currentpassword.value
    if (this.userInfo.password === currentPassword) {
      this.invalidCurrentPassword = false
    }
  }

  async submitResetPassword() {
    this.submitted = true
    // Note: this is not secure way to verify current password
    // this.validateCurrentPassword();
    if (this.changePasswordForm.valid && !this.passwordFlag) {
      this.userInfo.password = this.changePasswordForm.controls.password.value
      let response
      try {
        response = await this.authService
          .changePassword(
            this.changePasswordForm.controls.currentpassword.value,
            this.changePasswordForm.controls.password.value
          )
          .toPromise()
      } catch (error) {
        console.log(error)
        response = { success: false }
      }
      if (response.success) {
        this.toastr.success(
          'Your credentials have been updated. You will be redirected to login shortly.'
        )
        setTimeout(() => {
          this.router.navigate(['/authentication/login-option'])
          sessionStorage.clear()
          localStorage.clear()
        }, 2000)
      } else {
        this.toastr.error('Wrong password provided')
      }
    }
  }

  charLength(val) {
    if (val.length > 7) {
      return true
    } else {
      return false
    }
  }
  lowercase(val) {
    let regex = /^(?=.*[a-z]).+$/ // Lowercase character pattern
    if (regex.test(val)) {
      return true
    } else {
      return false
    }
  }
  uppercase(val: any) {
    let regex = /^(?=.*[A-Z]).+$/ // Uppercase character pattern
    if (regex.test(val)) {
      return true
    } else {
      return false
    }
  }
  special(val) {
    let regex = /^(?=.*[0-9_\W]).+$/ // Special character or number pattern
    if (regex.test(val)) {
      return true
    } else {
      return false
    }
  }
  showErrorAndSlider(event: any) {
    this.thumbLabel = true
    this.submitted = true
    const val = event?.target?.value
    let valueFlag = 0
    if (!val.trim()) {
      valueFlag = 0
      this.thumbLabel = false
      this.value = 0
    } else {
      this.thumbLabel = true
      if (this.lowercase(val)) {
        valueFlag += 1
      }
      if (this.uppercase(val)) {
        valueFlag += 1
      }
      if (this.special(val)) {
        valueFlag += 1
      }
      if (this.charLength(val)) {
        valueFlag += 1
      }
      if (this.charLength(val) && this.lowercase(val) && this.special(val) && this.uppercase(val)) {
        if (event.target.value) {
          if (event.target.value.length > 8) {
            valueFlag += 1
          }
          if (event.target.value.length > 10) {
            valueFlag += 1
          }
          if (event.target.value.length > 12) {
            valueFlag += 1
          }
        }
      }
    }
    this.value = valueFlag
  }
  formatLabel(value: number): string {
    let valueString: any
    if (value < 3) {
      valueString = 'Poor'
    } else if (value == 4) {
      valueString = 'Weak'
    } else if (value == 5) {
      valueString = 'Good'
    } else if (value == 6) {
      valueString = 'Strong'
    } else if (value == 7) {
      valueString = 'Very Strong'
    }
    this.value = value
    return valueString
  }
  getSliderTickInterval() {
    if (this.showTicks) {
      return this.autoTicks ? 'auto' : this.tickInterval
    }

    return 0
  }
}
