<mat-form-field appearance="fill">
  <mat-icon matPrefix class="search-icon">search</mat-icon>
  <input
    type="text"
    aria-label="Number"
    matInput
    placeholder="Search"
    (keyup)="searchData($event)"
  />
</mat-form-field>
