import { Optional, PaginationParams } from './base'

export class User {
  id?: number
  email?: string
  username?: string
  first_name?: string
  last_name?: string
  password?: string
  image_url?: string
  phone?: string
  status: Optional<string | number> //1 - active, 0 - inactive - 5 - setup password
  is_email_verified?: boolean
  user_type?: USER_TYPE
  group_id?: number
  login_type?: string
  created_at?: string
  updated_at?: string
  isChecked?: boolean

  public constructor(init?: Partial<User>) {
    Object.assign(this, init)
  }
}

export class UserRegister {
  user_type: USER_TYPE.CANDIDATE | USER_TYPE.BUSINESS
  email: string
  username: string
  password: string
  first_name?: string
  last_name?: string
  country?: string
  dob?: string
}

export class UserResponse extends User {}

export enum USER_TYPE {
  CANDIDATE = 1,
  BUSINESS = 2,
  ADMIN = 3,
  PARTNER = 4
}
export interface GetUsersParams extends PaginationParams<User> {
  user_type?: number
  is_email_verified?: boolean
}
export interface AllUsersResponse {
  items: User[]
  total: number
}
