import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { CRUDService } from '../crud.service'
import { BsModalService } from 'ngx-bootstrap/modal'
import { ViewCandidatesComponent } from '../dashboards/modals/view-candidates/view-candidates.component'
import { ToastrService } from 'ngx-toastr'
import { CommonService } from '../common.service'
import { CandidateService } from '../shared/services/candidate.service'

@Component({
  selector: 'app-view-candidate-profile',
  templateUrl: './view-candidate-profile.component.html',
  styleUrls: ['./view-candidate-profile.component.scss']
})
export class ViewCandidateProfileComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private crudService: CRUDService,
    private candidateService: CandidateService,
    private bsModalService: BsModalService,
    private router: Router,
    private toastr: ToastrService,
    private commonService: CommonService
  ) {}

  ngOnInit(): void {
    let profileId = this.route.snapshot.paramMap.get('profileToken')
    profileId = this.commonService.decryptData(decodeURIComponent(profileId))
    if (profileId) {
      this.loadCandidateProfile(profileId)
    } else {
      this.toastr.error('Invalid candidate profile id')
      // this.router.navigate(["/authentication/login-option"]);
    }
  }

  async loadCandidateProfile(profileId) {
    const response = await this.candidateService.getById(profileId).toPromise()

    if (response?.success) {
      this.openViewCandidatesModal(response.data)
    } else {
      this.toastr.error('Something went wrong, please try again later')
      this.router.navigate(['/authentication/login-option'])
    }
  }

  openViewCandidatesModal(candidateData) {
    const initialState: any = {
      data: candidateData,
      type: 'view-candidate-by-token'
    }
    const bsModalRef = this.bsModalService.show(ViewCandidatesComponent, {
      ariaLabelledBy: 'modal-basic-title',
      class: 'modal-height modal-lg view-candidates-modal',
      backdrop: 'static',
      initialState
    })
    bsModalRef.onHidden.subscribe(async () => {
      this.router.navigate(['/authentication/login-option'])
    })
  }
}
