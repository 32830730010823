<div class="body" fxLayout="row wrap">
  <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
    <div class="row col-12 pl-0">
      <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12">
        <div class="postsBox notifyBox">
          <div class="row col-12 text-center pr-0">
            <div
              [ngClass]="
                notificationIcon
                  ? 'col-xs-12 col-sm-12 col-md-11 col-lg-11 pl-0 pr-0 search'
                  : 'col-xs-12 col-sm-12 col-md-12 col-lg-12 pl-0 pr-0 search'
              "
            >
              <app-header-search-bar
                [data]="clonedCandidateSettings"
                (sendDataToParent)="eventFromChild($event)"
                [keysArray]="keysArray"
              ></app-header-search-bar>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-1 col-lg-1" *ngIf="notificationIcon">
              <app-header-notification-button [previousPath]="previousPath" class="bell-btn">
              </app-header-notification-button>
            </div>
          </div>
        </div>
        <hr />
        <div class="postsBox pl-4 pr-4 contant-box ease-in">
          <div class="mt-3 mb-3">
            <label class="setting-heading-text font-18">Settings</label>
          </div>
          <ng-container *ngFor="let setting of candidateSettings">
            <div class="accordian-style mb-2 cursor-pointer" [routerLink]="setting?.link">
              <div
                class="d-flex col-12"
                style="justify-content: space-between; align-items: center"
              >
                <div>
                  <label class="setting-title mb-0">{{ setting?.name }}</label>
                </div>
                <div class="">
                  <i
                    class="fa fa-angle-right font-18"
                    aria-hidden="true"
                    style="font-weight: 600"
                  ></i>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
      <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 pro-fix">
        <app-candidate-profile-overview-verified></app-candidate-profile-overview-verified>
      </div>
    </div>
  </div>
</div>
<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
  <p style="color: white">Please Wait.</p>
</ngx-spinner>
