import { ApiResponse } from './base'
import { UserResponse } from './user'

export class UserLogin {
  email: string
  password: string

  public constructor(init?: Partial<UserLogin>) {
    Object.assign(this, init)
  }
}

export class TokenItem {
  token: string
  expires: string
}

export class Tokens {
  access: TokenItem
  refresh: TokenItem
}

export class TokenResponse extends ApiResponse<Tokens> {}

export class LoginResponse extends ApiResponse<{
  user: UserResponse
  tokens: Tokens
}> {}

export class UserWithToken {
  user: UserResponse
  tokens: Tokens

  public static fromLoginResponse(loginResponse: LoginResponse): UserWithToken {
    return {
      user: loginResponse.data.user,
      tokens: loginResponse.data.tokens
    }
  }
}

export class CheckEmailExistResponse extends ApiResponse<{ exists: boolean }> {}
