import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core'
import { FormBuilder, FormGroup } from '@angular/forms'
import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'

@Component({
  selector: 'app-shortlist-details',
  templateUrl: './shortlist-details.component.html',
  styleUrls: ['./shortlist-details.component.scss']
})
export class ShortlistDetailsComponent implements OnInit {
  detailsForm: FormGroup
  details: any
  type: any
  title: any
  bsModalRef: BsModalRef
  showInput: boolean = false
  @Output() messageEvent = new EventEmitter<string>()
  constructor(
    public dialogRef: MatDialogRef<ShortlistDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private _bsModalRef: BsModalRef,
    public options: ModalOptions,
    private fb: FormBuilder
  ) {}
  ngOnInit(): void {
    this.type = this.options?.initialState?.type
    var type = this.data?.initialState
    this.detailsForm = this.fb.group({
      details: ['']
    })
    if (type === 'Offer' || this.type === 'Offer') {
      this.title = 'Offer'
    }
  }
  closeModal() {
    this._bsModalRef.hide()
    this.dialogRef.close()
    // this.bsModalService.hide();
  }
  submit() {
    let msg: any
    if (
      this.detailsForm.controls.details.value !== undefined &&
      this.detailsForm.controls.details.value !== null &&
      this.detailsForm.controls.details.value !== ''
    ) {
      msg = {
        details: this.detailsForm.controls.details.value
      }
    }
    this.messageEvent.emit(msg)
    this.dialogRef?.close({ details: msg })
    this.bsModalRef?.hide()
  }
}
