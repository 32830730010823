<div class="container ease-in">
  <div class="header-section">
    <button mat-icon-button class="icon-button" (click)="closeDialog()">
      <i class="fa fa-arrow-left"></i>
    </button>
    <h2 class="title">{{ title }}</h2>
    <button mat-icon-button class="icon-button" (click)="leaveCultureBuilder()">
      <i class="fa fa-times"></i>
    </button>
  </div>
  <div class="body-section">
    <div cdkDropListGroup>
      <div class="example-container">
        <div
          cdkDropList
          [cdkDropListData]="cultureDetails"
          [cdkDropListSortingDisabled]="true"
          class="example-list personality"
          id="cultureConatiner"
          (cdkDropListDropped)="dropped($event)"
          (cdkDropListExited)="exited($event)"
          (cdkDropListEntered)="entered()"
        >
          <ng-container *ngFor="let tray of cultureDetails">
            <div
              class="personality-box example-box"
              [style.border]="tray?.disabled ? '2px solid #97979733' : tray?.border"
              [style.background]="tray?.disabled ? '#ced3db33' : tray?.background"
              [style.color]="tray?.disabled ? '#2632381a' : tray?.color"
              [cdkDragDisabled]="tray?.disabled"
              [cdkDragData]="tray"
              cdkDrag
            >
              {{ tray?.name }}
            </div>
          </ng-container>
        </div>
      </div>
      <div class="personality pt-30 rank-container">
        <ng-container *ngFor="let tray of selectedCulture; let index = index">
          <div
            cdkDropList
            id="rankContainer"
            [cdkDropListData]="tray"
            [cdkDropListSortingDisabled]="true"
            (cdkDropListDropped)="drop($event, index)"
            class="example-list"
          >
            <div
              class="personality-box example-box"
              [style.border]="tray?.border"
              [style.background]="tray?.background"
              [style.color]="tray?.color"
              [cdkDragDisabled]="tray?.type === 'rank'"
              cdkDrag
              [cdkDragData]="tray"
            >
              {{ tray?.name }}
            </div>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="modal-actions">
      <button
        *ngIf="selectedCultureCount >= 2"
        mat-button
        class="submit-button"
        (click)="submitCultureRank()"
      >
        Confirm
      </button>
    </div>
  </div>
</div>
