<div class="body" fxLayout="row wrap">
  <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
    <div class="row col-12 pl-0">
      <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12">
        <div class="postsBox">
          <div class="row col-12 text-center pr-0">
            <div
              [ngClass]="
                notificationIcon
                  ? 'col-xs-12 col-sm-12 col-md-11 col-lg-11 pl-0 pr-0 search'
                  : 'col-xs-12 col-sm-12 col-md-12 col-lg-12 pl-0 pr-0 search'
              "
            >
              <app-header-search-bar></app-header-search-bar>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-1 col-lg-1" *ngIf="notificationIcon">
              <app-header-notification-button [previousPath]="previousPath">
              </app-header-notification-button>
            </div>
          </div>
        </div>
        <hr />
        <div class="postsBox pl-4 pr-4 ease-in">
          <div class="d-flex mt-3 mb-3" style="justify-content: space-between">
            <div class="">
              <i
                class="fa fa-angle-left back-icon"
                aria-hidden="true"
                routerLink="/dashboards/candidate-settings"
              ></i>
              <label class="heading-text font-18">Candidate Account</label>
            </div>
            <div class="pr-2">
              <img
                src="../../../../assets/images/chat-icon/Edit.svg"
                style="width: 15px; height: 15px"
                (click)="editCandidateForm()"
              />
            </div>
          </div>
          <form [formGroup]="candidateProfileForm" noValidate autocomplete="off">
            <div class="row form-group">
              <div class="col-lg-6 col-md-6 col-sm-12">
                <label class="form-label">First Name</label>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-12 form-value">
                <mat-label class="example-full-width" *ngIf="!editFormFlag">
                  {{ firstName }}
                </mat-label>
                <mat-form-field class="example-full-width" appearance="fill" *ngIf="editFormFlag">
                  <input
                    type="text"
                    matInput
                    placeholder="First Name"
                    [(ngModel)]="firstName"
                    formControlName="firstName"
                  />
                </mat-form-field>
                <div
                  *ngIf="submitted && f?.firstName.dirty && f['firstName'].errors"
                  class="alert error-message"
                >
                  <span *ngIf="f['firstName'].errors.required"> Please enter First Name </span>
                </div>
              </div>
            </div>
            <div class="row form-group mt-2">
              <div class="col-lg-6 col-md-6 col-sm-12">
                <label class="form-label">Last Name</label>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-12 form-value">
                <mat-label class="example-full-width" *ngIf="!editFormFlag">
                  {{ lastName }}
                </mat-label>
                <mat-form-field class="example-full-width" appearance="fill" *ngIf="editFormFlag">
                  <input
                    type="text"
                    matInput
                    placeholder="Last Name"
                    [(ngModel)]="lastName"
                    formControlName="lastName"
                  />
                </mat-form-field>
                <div
                  *ngIf="submitted && f?.lastName.dirty && f['lastName'].errors"
                  class="alert error-message"
                >
                  <span *ngIf="f['lastName'].errors.required"> Please enter Last Name </span>
                </div>
              </div>
            </div>
            <div class="row form-group mt-2">
              <div class="col-lg-6 col-md-6 col-sm-12">
                <label class="form-label">Email</label>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-12 form-value">
                <mat-label class="example-full-width" *ngIf="!editFormFlag">
                  {{ email }}
                </mat-label>
                <mat-form-field class="example-full-width" appearance="fill" *ngIf="editFormFlag">
                  <input
                    type="text"
                    matInput
                    placeholder="Email"
                    [(ngModel)]="email"
                    formControlName="email"
                  />
                </mat-form-field>
                <div *ngIf="submitted && f?.email.errors" class="alert error-message">
                  <span *ngIf="f['email'].errors.required"> Please enter Email </span>
                  <span *ngIf="f['email'].errors.email"> Enter Valid email Id </span>
                </div>
              </div>
            </div>
            <div class="row form-group mt-2">
              <div class="col-lg-6 col-md-6 col-sm-12">
                <label class="form-label">Phone Number</label>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-12 form-value">
                <mat-label class="example-full-width" *ngIf="!editFormFlag">
                  {{ phonenumber }}
                </mat-label>
                <mat-form-field class="example-full-width" appearance="fill" *ngIf="editFormFlag">
                  <input
                    type="text"
                    matInput
                    placeholder="Phone Number"
                    [(ngModel)]="phonenumber"
                    formControlName="phonenumber"
                  />
                </mat-form-field>
                <div
                  *ngIf="submitted && f?.phonenumber.dirty && f['phonenumber'].errors"
                  class="alert error-message"
                >
                  <span *ngIf="f['phonenumber'].errors.required"> Please Enter Phone Number </span>
                </div>
              </div>
            </div>
            <div class="row form-group mt-2">
              <div class="col-lg-6 col-md-6 col-sm-12">
                <label class="form-label">Country</label>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-12 form-value">
                <mat-label class="example-full-width" *ngIf="!editFormFlag">
                  {{ country }}
                </mat-label>
                <mat-form-field class="example-full-width" appearance="fill" *ngIf="editFormFlag">
                  <mat-select name="satus" formControlName="country" [(ngModel)]="country">
                    <mat-option value="australia"> Australia</mat-option>
                    <mat-option value="india"> India </mat-option>
                    <mat-option value="africa"> Africa </mat-option>
                  </mat-select>
                </mat-form-field>
                <div
                  *ngIf="submitted && f?.country.dirty && f['country'].errors"
                  class="alert error-message"
                >
                  <span *ngIf="f['country'].errors.required"> Please Enter Country </span>
                </div>
              </div>
            </div>
            <div class="row form-group mt-2">
              <div class="col-lg-6 col-md-6 col-sm-12">
                <label class="form-label">DOB</label>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-12 form-value">
                <mat-label class="example-full-width" *ngIf="!editFormFlag">
                  {{ dob | date: 'longDate' }}
                </mat-label>
                <mat-form-field class="example-full-width" appearance="fill" *ngIf="editFormFlag">
                  <!-- <input type="text" matInput placeholder="DOB" formControlName="dob"> -->
                  <input
                    matInput
                    [matDatepicker]="picker"
                    formControlName="dob"
                    [(ngModel)]="dob"
                    placeholder="DD/MM/YYYY"
                    appDateFormat
                  />
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker disabled="false"></mat-datepicker>
                </mat-form-field>
                <div
                  *ngIf="submitted && f?.dob.dirty && f['dob'].errors"
                  class="alert error-message"
                >
                  <span *ngIf="f['dob'].errors.required"> Please Enter DOB </span>
                </div>
              </div>
            </div>
            <div class="row form-group mt-2">
              <div class="col-lg-6 col-md-6 col-sm-12">
                <label class="form-label"
                  >At Upplft we care about identity so please tell us your preferred pronoun?</label
                >
              </div>
              <div class="col-lg-6 col-md-6 col-sm-12 form-value">
                <mat-label class="example-full-width" *ngIf="!editFormFlag">
                  {{ gender }}
                </mat-label>
                <mat-form-field class="example-full-width" appearance="fill" *ngIf="editFormFlag">
                  <mat-select name="satus" formControlName="gender" [(ngModel)]="gender">
                    <mat-option value="She/Her/Hers"> She/Her/Hers </mat-option>
                    <mat-option value="He/Him/His"> He/Him/His </mat-option>
                    <mat-option value="Zi/Zir/Zirs"> Zi/Zir/Zirs </mat-option>
                    <mat-option value="Them/They/Theirs"> Them/They/Theirs </mat-option>
                    <mat-option value="I prefer not to say"> I prefer not to say </mat-option>
                  </mat-select>
                </mat-form-field>
                <div
                  *ngIf="submitted && f?.gender.dirty && f['gender'].errors"
                  class="alert error-message"
                >
                  <span *ngIf="f['gender'].errors.required"> Please Enter Gender </span>
                </div>
              </div>
            </div>

            <div
              class="col-6 mx-auto"
              style="margin-top: 10px; text-align: center"
              *ngIf="editFormFlag"
            >
              <button mat-raised-button class="search-btn" (click)="submit()">Submit</button>
            </div>
          </form>
        </div>
      </div>
      <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
        <app-candidate-profile-overview-verified style="margin-top: 90x">
        </app-candidate-profile-overview-verified>
      </div>
    </div>
  </div>
</div>
