<div class="body" fxLayout="row wrap">
  <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
    <div class="row col-12 pl-0">
      <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12">
        <div class="postsBox">
          <div class="row col-12 text-center pr-0">
            <div
              [ngClass]="
                notificationIcon
                  ? 'col-xs-12 col-sm-12 col-md-11 col-lg-11 pl-0 pr-0 search'
                  : 'col-xs-12 col-sm-12 col-md-12 col-lg-12 pl-0 pr-0 search'
              "
            >
              <app-header-search-bar></app-header-search-bar>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-1 col-lg-1" *ngIf="notificationIcon">
              <app-header-notification-button [previousPath]="previousPath">
              </app-header-notification-button>
            </div>
          </div>
        </div>
        <hr />
        <div class="postsBox pl-4 pr-4 ease-in">
          <div class="mt-3 mb-3">
            <i class="fa fa-angle-left back-icon" aria-hidden="true" (click)="closeFAQS()"></i>
            <label class="heading-text font-18">FAQ'S</label>
          </div>
          <div class="">
            <mat-accordion *ngFor="let quesAns of questionAnswerArr">
              <mat-expansion-panel hideToggle class="accordian-style mb-2">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <div class="col-12 pr-0 pl-0 d-flex justify-space-bw">
                      <div>
                        <mat-label class="mat-label-css font-14">{{ quesAns.topic }}? </mat-label>
                      </div>
                      <div><i class="fa fa-angle-down angle-down-icon" aria-hidden="true"></i></div>
                    </div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <mat-divider class="mb-2"></mat-divider>
                <div>
                  <div class="row col-12 pl-0 pr-0 mt-3 disabledDiv">
                    <div class="col-12 designation-label disabledDiv">
                      <!-- <ckeditor id="editor-id" [editor]="editor" [data]="quesAns?.content"
                                                (ready)="onReady($event)"></ckeditor> -->
                      <div [innerHTML]="quesAns?.content"></div>
                    </div>
                  </div>
                </div>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 profile-div">
        <app-candidate-profile-overview-verified
          *ngIf="role === 'candidate'"
          style="margin-top: 90x"
        >
        </app-candidate-profile-overview-verified>
        <app-partner-profile-overview-verified *ngIf="role === 'partner'" style="margin-top: 90x">
        </app-partner-profile-overview-verified>
        <app-profile-overview-verified *ngIf="role === 'business'"></app-profile-overview-verified>
      </div>
    </div>
  </div>
</div>
