<div *ngIf="loaded" class="modal-content content-modal">
  <carousel
    id="myCarousel"
    [itemsPerSlide]="itemsPerSlide"
    [singleSlideOffset]="singleSlideOffset"
    [interval]="false"
    [noWrap]="noWrap"
    *ngIf="candidates.length > 0"
    [showIndicators]="showIndicator"
    [(activeSlide)]="activeSlideIndex"
  >
    <slide
      *ngFor="let candidate of candidates; trackBy: trackByFn; let index = index"
      class="ease-in"
    >
      <div class="modal-content" style="height: auto !important" *ngIf="index === activeSlideIndex">
        <div class="modal-header" style="margin-top: -5px">
          <div>
            <div class="details-div d-flex">
              <div class="pl-3 d-flex" *ngIf="!candidate?.showCandidateProfile">
                <label class="candidate-id">#{{ candidate?.id }}</label>
                <span class="primary-color pl-2" (click)="toggleFavorite(candidate)">
                  <i
                    [class]="
                      candidate.BusinessPostCandidate?.favourite_yn == 'Y'
                        ? 'fa fa-heart'
                        : 'fa fa-heart-o'
                    "
                  ></i>
                </span>
              </div>
              <div class="d-flex align-items-center" *ngIf="candidate?.showCandidateProfile">
                <img
                  class="animal-img"
                  style="
                    width: 75px;
                    height: 75px;
                    object-fit: contain;
                    border-radius: 50%;
                    float: right;
                  "
                  src="{{ candidate.image_url }}"
                  onerror="this.onerror=null;this.src='../../../assets/images/background/login-register.jpg'"
                />
                <div class="pl-3">
                  <div>
                    <!-- <label class="title">Name</label> -->
                    <div class="d-flex">
                      <label class="candidate-id">#{{ candidate?.id }}</label>
                      <span class="primary-color pl-2" (click)="toggleFavorite(candidate)">
                        <i
                          [class]="
                            candidate.BusinessPostCandidate?.favourite_yn == 'Y'
                              ? 'fa fa-heart'
                              : 'fa fa-heart-o'
                          "
                        ></i>
                      </span>
                    </div>
                    <label
                      *ngIf="candidate.name"
                      class="title-value p-0"
                      style="
                        background: white;
                        width: 100%;
                        padding-left: 0px !important;
                        font-weight: bold !important;
                        font-size: 25px;
                        padding-top: 0px !important;
                      "
                      >{{ candidate.name }}</label
                    >
                  </div>
                  <div class="d-flex align-items-center">
                    <!-- <label class="title">Gender</label> -->
                    <!-- <label *ngIf="candidate.gender" class="title-value p-0" style="
                        background: white;
                        width: 100%;
                        padding-left: 0px !important;
                        font-weight: bold !important;
                      ">{{ candidate.gender }}</label> -->
                    <mat-icon
                      *ngIf="candidate?.linkedin_url"
                      class="doc-icons"
                      matTooltip="Linked In"
                      (click)="openUrl(candidate?.linkedin_url)"
                      ><i class="fa fa-linkedin-square"></i
                    ></mat-icon>
                    <mat-icon
                      *ngIf="candidate?.cv_url"
                      class="doc-icons"
                      matTooltip="CV"
                      style="font-size: 21px !important; margin-right: 5px"
                      (click)="download(candidate?.cv_url)"
                      ><i class="fa fa-file-text-o"></i
                    ></mat-icon>
                    <ng-container *ngIf="candidate?.web_url?.length">
                      <mat-icon
                        class="doc-icons"
                        matTooltip="Web URL"
                        (click)="
                          candidate?.web_url?.length == 1
                            ? openUrl(candidate?.web_url[0])
                            : urlSelect.open()
                        "
                        >language</mat-icon
                      >
                      <mat-select #urlSelect class="invisible w-0" [panelClass]="'url-selection'">
                        <mat-select-trigger class="invisible"></mat-select-trigger>
                        <mat-option *ngFor="let url of candidate?.web_url" (click)="openUrl(url)">{{
                          url
                        }}</mat-option>
                      </mat-select>
                    </ng-container>
                    <mat-icon
                      *ngIf="candidate?.portfolio_url"
                      class="doc-icons"
                      matTooltip="Portfolio"
                      (click)="download(candidate?.portfolio_url)"
                      >photo_library</mat-icon
                    >
                    <ng-container *ngIf="candidate?.github_url?.length">
                      <mat-icon
                        class="doc-icons"
                        matTooltip="Github"
                        (click)="
                          candidate?.github_url?.length == 1
                            ? openUrl(candidate?.github_url[0])
                            : githubSelect.open()
                        "
                        ><i class="fa fa-github"></i
                      ></mat-icon>
                      <mat-select
                        #githubSelect
                        class="invisible w-0"
                        [panelClass]="'url-selection'"
                      >
                        <mat-select-trigger class="invisible"></mat-select-trigger>
                        <mat-option
                          *ngFor="let url of candidate?.github_url"
                          (click)="openUrl(url)"
                          >{{ url }}</mat-option
                        >
                      </mat-select>
                    </ng-container>
                    <mat-icon
                      class="doc-icons"
                      matTooltip="Copy Link"
                      [cdkCopyToClipboard]="candidate?.profileLink"
                      >description</mat-icon
                    >
                  </div>
                </div>
                <div class="partner-badge">
                  <!-- <img *ngIf="candidate?.BusinessPostCandidate?.shortlist_type === 'Premium'"
                    src="assets/images/upplft/talent_partner_badge.png"> -->
                  <span *ngIf="candidate?.BusinessPostCandidate?.shortlist_type === 'Premium'"
                    >Premium</span
                  >
                </div>
              </div>
            </div>
          </div>
          <div
            class=""
            style="
              display: flex;
              flex-wrap: nowrap;
              justify-content: space-around;
              flex-direction: row;
              align-content: center;
              align-items: center;
            "
            *ngIf="!isViewCandidateByToken"
          >
            <div class="match-div">
              <label class="profile-completion"
                >{{ candidate.match_perc | number: '1.0-0' }}%</label
              >
              <label class="match-label">Match</label>
              <label *ngIf="showDataEle" class="out-of-candidate"
                >Candidate : {{ index + 1 }} out of {{ candidates.length }}</label
              >
            </div>
          </div>
        </div>
        <div style="padding-left: 15px; padding-right: 40px">
          <hr class="new1" />
        </div>
        <div class="modal-body">
          <div [ngClass]="showDataEle ? 'role-main-div' : 'show-candidate-profile'">
            <div>
              <div class="details-div" *ngIf="candidate?.personality_test_info">
                <label class="title">Personality Type</label>
                <div class="d-flex personality-type justify-content-between">
                  <div class="pl-3 mt-2">
                    <div>
                      <div class="heading col-12 p-0 mb-2">
                        <label class="personality-header"
                          >Archetype:
                          <b>{{ candidate?.personalityTestInfo?.description?.Name }}</b></label
                        >
                      </div>
                    </div>
                    <div>
                      <div class="heading col-12 p-0 mb-2">
                        <label class="personality-header"
                          ><b>Facets: </b
                          >{{ candidate?.personalityTestInfo?.description?.Facets }}</label
                        >
                      </div>
                    </div>
                    <div>
                      <div class="heading col-12 p-0 mb-2">
                        <label class="personality-header"
                          ><b>Adjectives: </b
                          >{{ candidate?.personalityTestInfo?.description?.Adjectives }}</label
                        >
                      </div>
                    </div>
                  </div>
                  <div class="mt-2">
                    <img
                      class="personality-img"
                      src="'../../../../assets/images/archetypes/{{
                        candidate.personality_archetype
                      }}.png"
                    />
                  </div>
                  <div class="pr-2 mt-2" *ngIf="!isViewCandidateByToken">
                    <button
                      mat-button
                      class="alignment-btn"
                      (click)="openAlignmentModal(candidate, 'personality')"
                    >
                      View Alignment
                    </button>
                  </div>
                </div>
              </div>
              <div class="details-div" *ngIf="!candidate?.personality_test_info">
                <label class="title">Personality Type</label>
                <label class="title-value" style="background: white; width: 100%">
                  Candidate is yet to take personality test
                </label>
              </div>

              <div
                class="details-div mt-3"
                *ngIf="candidate?.culture && isStringifiedJson(candidate?.culture)"
              >
                <label class="title">Culture</label>
                <div class="d-flex personality-type justify-content-between">
                  <div class="pl-3 mt-2">
                    <div>
                      <div class="heading col-12 p-0 mb-2">
                        <label class="personality-header">
                          <b>{{ candidate?.cultureTestInfo?.name }}</b></label
                        >
                      </div>
                    </div>
                    <div>
                      <div class="heading col-12 p-0 mb-2">
                        <label class="personality-header">
                          <b>Thrust: </b>{{ candidate?.cultureTestInfo?.description?.thrusts }}
                        </label>
                      </div>
                    </div>
                    <div>
                      <div class="heading col-12 p-0 mb-2">
                        <label class="personality-header">
                          <b>Means: </b>
                          {{ candidate?.cultureTestInfo?.description?.means }}
                        </label>
                      </div>
                    </div>
                    <div>
                      <div class="heading col-12 p-0 mb-2">
                        <label class="personality-header">
                          <b>Ends: </b>
                          {{ candidate?.cultureTestInfo?.description?.ends }}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="mt-2">
                    <img
                      class="personality-img"
                      src="'../../../../assets/images/cultures/{{
                        candidate?.cultureTestInfo?.name
                      }}.jpeg"
                    />
                  </div>
                  <div class="pr-2 mt-2" *ngIf="!isViewCandidateByToken">
                    <button
                      mat-button
                      class="alignment-btn"
                      (click)="openAlignmentModal(candidate, 'culture')"
                    >
                      View Alignment
                    </button>
                  </div>
                </div>
              </div>
              <div
                class="details-div mt-3"
                *ngIf="!candidate?.culture || !isStringifiedJson(candidate?.culture)"
              >
                <label class="title">Culture</label>
                <label class="title-value" style="background: white; width: 100%">
                  Candidate is yet to take culture quiz
                </label>
              </div>

              <div class="details-div mt-3">
                <label class="title">Bio</label>
                <label
                  *ngIf="candidate.bio"
                  class="title-value"
                  style="background: white; width: 100%"
                >
                  {{ candidate.bio }}
                </label>
              </div>
              <div class="details-div mt-3">
                <label class="title">Interested In</label>
                <mat-chip-list aria-label="Skills Selection" *ngIf="candidate.roles_interested">
                  <mat-chip *ngFor="let interest of candidate.roles_interested" class="chipactive">
                    {{ interest }}</mat-chip
                  >
                </mat-chip-list>
              </div>
              <div class="details-div mt-3">
                <label class="title">Looking For</label>
                <mat-chip-list aria-label="Skills Selection" *ngIf="candidate.looking_for">
                  <mat-chip *ngFor="let skill of candidate.looking_for" class="chipactive"
                    >{{ skill }}
                  </mat-chip>
                </mat-chip-list>
              </div>
              <div class="details-div mt-3">
                <label class="title">Domain Experience</label>
                <mat-chip-list aria-label="Skills Selection" *ngIf="candidate.domain_exp">
                  <mat-chip *ngFor="let skill of candidate.domain_exp" class="chipactive">
                    {{ domainExp(skill) }}
                  </mat-chip>
                </mat-chip-list>
              </div>
              <div class="details-div mt-3">
                <label class="title">Skills</label>
                <mat-chip-list aria-label="Skills Selection" *ngIf="candidate.technical_skills">
                  <mat-chip *ngFor="let skill of candidate.technical_skills" class="chipactive">
                    {{ skill }}</mat-chip
                  >
                </mat-chip-list>
              </div>
              <div class="details-div mt-3">
                <label class="title">Soft Skills</label>
                <mat-chip-list aria-label="Skills Selection" *ngIf="candidate.skillsList">
                  <mat-chip *ngFor="let skill of candidate.skillsList" class="chipactive">
                    {{ skill.description }}</mat-chip
                  >
                </mat-chip-list>
              </div>
              <div class="details-div mt-3">
                <label class="title">Achievements</label>
                <label class="title-value" style="background: white" *ngIf="candidate.goals">
                  {{ candidate.goals }}
                </label>
              </div>
              <div class="details-div mt-3">
                <label class="title">Benefits</label>
                <mat-chip-list aria-label="Benefits Selection" *ngIf="candidate.benefitsList">
                  <mat-chip
                    *ngFor="let benefit of candidate.benefitsList"
                    [ngClass]="benefit.active ? 'chipactive' : ''"
                    >{{ benefit.description }}</mat-chip
                  >
                </mat-chip-list>
              </div>
              <div class="details-div mt-3">
                <label class="title">Personality: {{ personalityFn(candidate.personality) }}</label>
                <div *ngIf="candidate.personality_description">
                  <label class="title-value" style="background: white"
                    >{{ candidate.personality_description }}
                  </label>
                </div>
              </div>
              <div class="details-div mt-3">
                <label class="title">Values</label>
                <mat-chip-list aria-label="Skills Selection" *ngIf="candidate.valuesList">
                  <mat-chip
                    *ngFor="let values of candidate.valuesList"
                    [ngClass]="values.active ? 'chipactive' : ''"
                    >{{ values.description }}
                  </mat-chip>
                </mat-chip-list>
              </div>
              <div *ngIf="viewSpiritAnimal" class="details-div mt-3">
                <label class="title">Spirit Animal</label>
                <label
                  *ngIf="candidate.personality_type"
                  class="title-value"
                  style="background: white"
                  >{{ candidate.personality_type }} - <b>{{ candidate.personality_desc }}</b></label
                >

                <div *ngIf="candidate.personality_desc" class="d-flex">
                  <img
                    class="animal-img"
                    style="width: 130px"
                    src="'../../../../assets/personality/{{
                      candidate.personality_type.toLowerCase()
                    }}.png"
                  />

                  <label
                    class="title-value"
                    style="
                      background: white;
                      text-align: justify;
                      margin-top: auto;
                      margin-bottom: auto;
                    "
                  >
                    {{ personalityDesc(candidate.personality_type) }}
                  </label>
                </div>
              </div>
              <div class="details-div mt-3">
                <label class="title">Experience</label>
                <div class="row col-12 pr-0">
                  <div
                    class="timeline-steps aos-init aos-animate timeline-display"
                    data-aos="fade-up"
                  >
                    <div class="timeline-step col-4">
                      <div
                        class="timeline-content"
                        data-toggle="popover"
                        data-trigger="hover"
                        data-placement="top"
                        title=""
                        data-content="And here's some amazing content. It's very engaging. Right?"
                        data-original-title="2005"
                      >
                        <div class="img-download inner-circle">
                          <label for="file-upload3" class="custom-file-upload mx-auto">
                            <img
                              id="blah3s"
                              [src]="
                                candidate?.showCandidateProfile
                                  ? candidate?.experience_3_img_url
                                  : 'assets/images/business/business_logo.png'
                              "
                              onerror="this.onerror=null;this.src='assets/images/business/business_logo.png'"
                            />
                          </label>
                        </div>
                        <p class="h6 mt-5 mb-1 company-name">
                          {{
                            candidate?.showCandidateProfile
                              ? candidate?.experience_3_compname
                              : '******'
                          }}
                        </p>
                        <p class="h6 mb-1 company-details">
                          {{ candidate?.experience_3_role }}
                        </p>
                        <p class="h6 mb-0 mb-lg-0 company-details">
                          {{ candidate?.experience_3_from }} -
                          {{ candidate?.experience_3_to }}
                        </p>
                      </div>
                    </div>
                    <div *ngIf="candidate.currentlyEmployed">This is their current role</div>
                    <!-- <div *ngIf="!candidate.currentlyEmployed">
                                                This is not their current role
                                            </div> -->
                    <div class="line"></div>
                    <div class="timeline-step col-4">
                      <div
                        class="timeline-content"
                        data-toggle="popover"
                        data-trigger="hover"
                        data-placement="top"
                        title=""
                        data-content="And here's some amazing content. It's very engaging. Right?"
                        data-original-title="2004"
                      >
                        <div class="img-download inner-circle">
                          <label for="file-upload2" class="custom-file-upload mx-auto">
                            <img
                              id="blah2"
                              [src]="
                                candidate?.showCandidateProfile
                                  ? candidate?.experience_2_img_url
                                  : 'assets/images/business/business_logo.png'
                              "
                              onerror="this.onerror=null;this.src='assets/images/business/business_logo.png'"
                            />
                          </label>
                        </div>
                        <p class="h6 mt-5 mb-1 company-name">
                          {{
                            candidate?.showCandidateProfile
                              ? candidate?.experience_2_compname
                              : '******'
                          }}
                        </p>
                        <p class="h6 mb-1 company-details">
                          {{ candidate?.experience_2_role }}
                        </p>
                        <p class="h6 mb-0 mb-lg-0 company-details">
                          {{ candidate?.experience_2_from }} -
                          {{ candidate?.experience_2_to }}
                        </p>
                      </div>
                    </div>
                    <div class="timeline-step col-4">
                      <div
                        class="timeline-content"
                        data-toggle="popover"
                        data-trigger="hover"
                        data-placement="top"
                        title=""
                        data-content="And here's some amazing content. It's very engaging. Right?"
                        data-original-title="2003"
                      >
                        <div class="img-download inner-circle">
                          <label for="file-upload1" class="custom-file-upload mx-auto">
                            <img
                              id="blah1"
                              [src]="
                                candidate?.showCandidateProfile
                                  ? candidate?.experience_1_img_url
                                  : 'assets/images/business/business_logo.png'
                              "
                              onerror="this.onerror=null;this.src='assets/images/business/business_logo.png'"
                            />
                          </label>
                        </div>
                        <p class="h6 mt-5 mb-1 company-name">
                          {{
                            candidate?.showCandidateProfile
                              ? candidate?.experience_1_compname
                              : '******'
                          }}
                        </p>
                        <p class="h6 mb-1 company-details">
                          {{ candidate?.experience_1_role }}
                        </p>
                        <p class="h6 mb-0 mb-lg-0 company-details">
                          {{ candidate?.experience_1_from }} -
                          {{ candidate?.experience_1_to }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="details-div mt-3 language-div">
                <label class="title">Languages Known</label>
                <mat-chip-list
                  aria-label="Skills Selection"
                  *ngIf="candidate.languages && candidate.languages.length > 0"
                >
                  <mat-chip *ngFor="let skill of candidate.languages" class="chipactive"
                    >{{ skill }}
                  </mat-chip>
                </mat-chip-list>
              </div>
              <div class="details-div mt-3">
                <label class="title">Frustrations</label>
                <mat-chip-list
                  aria-label="Skills Selection"
                  *ngIf="candidate.frustrations && candidate.frustrations.length > 0"
                >
                  <mat-chip *ngFor="let frustrations of candidate.frustrations" class="chipactive">
                    {{ frustrations }}
                  </mat-chip>
                </mat-chip-list>
              </div>
              <div class="details-div mt-3"></div>
              <div class="details-div mt-3"></div>
              <div class="details-div mt-3"></div>
              <div class="details-div mt-3"></div>
              <!-- <div class="details-div mt-3">
                                <label class="title">Personality</label>
                                <mat-chip-list aria-label="Benefits Selection" *ngIf="candidate.personality">
                                    <mat-chip *ngFor="let value of candidate.personality" class="chipactive">
                                        {{value}}
                                    </mat-chip>
                                </mat-chip-list>
                            </div> -->
            </div>
          </div>
        </div>
        <div class="buttons-section" *ngIf="candidate.showButtonSection">
          <div>
            <button
              mat-raised-button
              *ngIf="showDataEle"
              class="add-post-role-btn applicant-btn shortListButton"
              (click)="toggleMatches()"
            >
              {{ isViewAllApplicants ? 'View Matches' : 'View all Applicants' }}
            </button>
          </div>
          <div style="text-align: right" *ngIf="showDataEle">
            <button
              mat-raised-button
              class="add-post-role-btn shortList-btn shortListButton"
              (click)="toggleShortList(candidate)"
            >
              Shortlist
            </button>
            <button
              mat-raised-button
              class="add-post-role-btn reject-btn shortListButton"
              (click)="toggleReject(candidate)"
            >
              Reject
            </button>
            <button
              mat-raised-button
              class="add-post-role-btn feedback-btn shortListButton"
              (click)="openFeedbackModal(FeedbackModal, candidate)"
            >
              Feedback
            </button>
          </div>
        </div>
        <div class="slider-div" *ngIf="isViewAllApplicants && showDataEle"></div>
        <div class="slider-div" *ngIf="!isViewAllApplicants && showDataEle">
          <span class="slider-span">0%</span>
          <mat-slider
            [(ngModel)]="matchPercentage"
            thumbLabel
            min="0"
            max="100"
            step="1"
            class="match-slider"
            [displayWith]="formatLabel"
            (valueChange)="onChangeMatchPerc($event)"
          >
          </mat-slider>
          <span class="slider-span">100%</span>
        </div>
      </div>
    </slide>
    <button
      type="button"
      class="close"
      aria-label="Close"
      data-dismiss="modal"
      (click)="closeModal()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </carousel>
  <!-- <div *ngIf="shortlistedCandidates.length > 0 && candidates.length == 0" style="margin-top:50%; text-align: center;">
        No more candidates that meet the criteria at the moment.
        <button type="button" class="close" aria-label="Close" data-dismiss="modal" (click)="closeModal()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div *ngIf="shortlistedCandidates.length == 0 && candidates.length == 0"
        style="margin-top:50%; text-align: center;">
        There are currently no candidates that meet the minimum role requirements at the moment.
        <button type="button" class="close" aria-label="Close" data-dismiss="modal" (click)="closeModal()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div *ngIf="candidates.length == 0" style="margin-top:35%; text-align: center;">
        <p>Looks like we need to work a bit harder to find you a match. Leave it with us and we will notify you know
            when
            we have a match.</p>

        <p>Good things come to those who wait. - English Proverb.</p>
        <button type="button" class="close" aria-label="Close" data-dismiss="modal" (click)="closeModal()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
 -->
  <ng-container *ngIf="candidates.length < 1 && loaded && roleDetails.display_candidates == 'Y'">
    <div class="no-candidate-section ease-in">
      <div>
        <div class="header">
          <label *ngIf="isViewAllApplicants" class="circularBold signUpHeading h-auto"
            >Looks like we're waiting for applications!</label
          >
          <label *ngIf="!isViewAllApplicants" class="circularBold signUpHeading h-auto"
            >Looks like there's no candidates that meet this match percentage!</label
          >
        </div>
        <div fxLayout="row wrap">
          <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100" class="description-section">
            <p *ngIf="isViewAllApplicants" class="description-text">
              Looks like we're waiting for candidates to apply for your role, let us know if we can
              help.
              <br />
            </p>
            <p *ngIf="!isViewAllApplicants" class="description-text">
              If there's no matches at this level, you can change the view by scrolling the match
              percentage up and down the scale.
              <br />
            </p>
            <p *ngIf="isViewAllApplicants" class="description-text">
              <span class="description-text italics"
                >"Things may came to those who wait, but only the things left by those who
                hustle...".</span
              >
            </p>
            <p *ngIf="!isViewAllApplicants" class="description-text">
              <span class="description-text italics"
                >“Trade your expectations for appreciation and your whole life will change.”</span
              >
            </p>
          </div>
        </div>
        <button
          type="button"
          class="close"
          aria-label="Close"
          data-dismiss="modal"
          (click)="closeModal()"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </div>
    <div class="buttons-section">
      <div>
        <button
          mat-raised-button
          *ngIf="showDataEle"
          class="add-post-role-btn shortListButton"
          (click)="toggleMatches()"
          style="background: #ec3b83; color: white; border-radius: 20px; height: 36px; width: 165px"
        >
          {{ isViewAllApplicants ? 'View Matches' : 'View all Applicants' }}
        </button>
      </div>
    </div>
    <div class="slider-div" *ngIf="isViewAllApplicants && showDataEle">
      <span></span>
    </div>
    <div class="slider-div" *ngIf="!isViewAllApplicants && showDataEle">
      <span class="slider-span">0%</span>
      <mat-slider
        [(ngModel)]="matchPercentage"
        thumbLabel
        min="0"
        max="100"
        step="1"
        class="match-slider"
        [displayWith]="formatLabel"
        (valueChange)="onChangeMatchPerc($event)"
      >
      </mat-slider>
      <span class="slider-span">100%</span>
    </div>
  </ng-container>

  <ng-container *ngIf="candidates.length < 1 && loaded && roleDetails.display_candidates != 'Y'">
    <div class="no-candidate-section ease-in justify-content-center">
      <div class="header">
        <label class="circularBold signUpHeading h-auto">This role is not approved yet!</label>
      </div>
      <button
        type="button"
        class="close"
        aria-label="Close"
        data-dismiss="modal"
        (click)="closeModal()"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </ng-container>

  <div style="position: relative" *ngIf="showDataEle">
    <div class="groupbtn" matBadge="{{ shortlistedCandidates.length }}" matBadgePosition="before">
      <mat-icon class="groupicon">groups</mat-icon>
    </div>
  </div>
</div>

<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
  <p style="color: white">Please Wait.</p>
</ngx-spinner>

<ng-template #FeedbackModal class="modal-content" id="feedbackModal">
  <div class="modal-header">
    <h4 class="modal-title">Feedback</h4>
    <button
      mat-icon-button
      class="feedback-modal-close-btn"
      aria-label="Close"
      (click)="closeFeedbackModal()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body feedback-modal">
    <div>
      <div>
        <label>Rating</label>
      </div>
      <div class="rate">
        <input
          type="radio"
          id="star5"
          name="rate"
          value="5"
          [(ngModel)]="postResponse.rating"
          (click)="postResponse.rating = 5"
        />
        <label for="star5" title="text">5 stars</label>
        <input
          type="radio"
          id="star4"
          name="rate"
          value="4"
          [(ngModel)]="postResponse.rating"
          (click)="postResponse.rating = 4"
        />
        <label for="star4" title="text">4 stars</label>
        <input
          type="radio"
          id="star3"
          name="rate"
          value="3"
          [(ngModel)]="postResponse.rating"
          (click)="postResponse.rating = 3"
        />
        <label for="star3" title="text">3 stars</label>
        <input
          type="radio"
          id="star2"
          name="rate"
          value="2"
          [(ngModel)]="postResponse.rating"
          (click)="postResponse.rating = 2"
        />
        <label for="star2" title="text">2 stars</label>
        <input
          type="radio"
          id="star1"
          name="rate"
          value="1"
          [(ngModel)]="postResponse.rating"
          (click)="postResponse.rating = 1"
        />
        <label for="star1" title="text">1 star</label>
      </div>
    </div>
    <div>
      <textarea
        rows="5"
        class="title-value"
        matInput
        placeholder="Feedback"
        [(ngModel)]="postResponse.feedback"
      ></textarea>
    </div>
  </div>
  <div class="modal-footer">
    <button mat-raised-button class="like-btn" (click)="submit()" data-dismiss="myModal">
      Submit
    </button>
  </div>
</ng-template>
