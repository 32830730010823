import { Component, Inject, Output, EventEmitter } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { FormBuilder, FormControl, FormGroup } from '@angular/forms'
import { EgretCalendarEvent } from '../event.model'
import { DateSlots, TimeSlots, TimeSlotsList, getDateWithoutTime } from '../event-utils'
import { addDays, subDays } from 'date-fns'
import { NgxSpinnerService } from 'ngx-spinner'
import { getLoggedInUser, getArrayFromString } from 'src/app/common-utils'
import { CommonService } from 'src/app/common.service'
import { ToastrService } from 'ngx-toastr'
import { RoleService } from 'src/app/shared/services/role.service'

@Component({
  selector: 'app-schedule-event-new',
  templateUrl: './schedule-event-new.component.html',
  styleUrls: ['./schedule-event-new.component.scss']
  // encapsulation: ViewEncapsulation.None
})
export class ScheduleEventNewComponent {
  @Output() navBack = new EventEmitter<any>()
  event: any
  jobInfo: any
  businessPostCandidateInfo: any
  events: any[] = []
  dateEvent: any
  dialogTitle: string
  eventForm: FormGroup
  action: any
  calendarEvents: Event[]
  dateSlots: DateSlots[] = []
  timeSlotsList: TimeSlots[]
  availableDays: any[] = []
  selectedDateSlots: any[] = []
  availability: any[] = []
  calendarApi: any
  selectedSlot: any = {}
  calendarView: any = false
  selectedMeetingType: any = 'Teams'
  displaySlots: any[] = []
  availabilityBreakUp: any[] = []
  fromDate: Date
  toDate: Date
  currentDate: Date
  currentWeek: boolean = true
  selectedDate: any
  selectedEndTime: any
  selectedStartTime: any
  meetingDate: Date
  visibleIndex = -1
  bookSlotClicked = false
  userInfo: any
  businessInfo: any
  isReschedule = false
  isSlotsAvailable: boolean = false

  constructor(
    private SpinnerService: NgxSpinnerService,
    public dialogRef: MatDialogRef<ScheduleEventNewComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private formBuilder: FormBuilder,
    private CommonService: CommonService,
    private roleService: RoleService,
    private toastr: ToastrService
  ) {
    this.userInfo = getLoggedInUser()
    this.businessInfo = this.data.business_info
    this.jobInfo = this.data.job_info
    this.availability = this.data.availability
    this.businessPostCandidateInfo = this.data.businessPostCandidateInfo
    this.timeSlotsList = TimeSlotsList
    this.isReschedule = this.data.isReschedule

    this.currentDate = getDateWithoutTime(new Date())
    this.availability.forEach((slot: any) => {
      const loadedDate = new Date(slot.slot_date)
      const date =
        loadedDate.getFullYear() +
        '-' +
        ('00' + (loadedDate.getMonth() + 1)).slice(-2) +
        '-' +
        ('00' + loadedDate.getDate()).slice(-2)
      this.availableDays.push(date)
      let slots = this.getDaySlots(slot.time_slots)
      this.availabilityBreakUp.push({ date, slots })
    })

    this.event = new EgretCalendarEvent({
      start: this.data.startDate,
      end: this.data.endDate
    })
    this.currentWeekSlots()
    if (this.displaySlots.length !== 0) {
      this.displaySlots[0].slots[0].class = 'primaryButton'
      this.selectedSlot = this.displaySlots[0].slots[0]
      this.selectedDate = this.displaySlots[0].date

      this.meetingDate = new Date(this.selectedDate)
      this.selectedStartTime = this.selectedSlot.value
      this.selectedEndTime = this.selectedSlot.value
    }
    this.eventForm = this.buildEventForm(this.event)
  }
  closeModal() {
    this.dialogRef.close()
  }

  filterDays() {
    this.displaySlots = this.availabilityBreakUp.filter(
      (val) =>
        getDateWithoutTime(new Date(val.date)) >= this.fromDate &&
        getDateWithoutTime(new Date(val.date)) <= this.toDate
    )

    const availableDaySlot = this.availabilityBreakUp.filter(
      (e) => getDateWithoutTime(new Date(e.date)) >= getDateWithoutTime(new Date())
    )
    availableDaySlot.length ? (this.isSlotsAvailable = true) : (this.isSlotsAvailable = false)
    if (this.fromDate.toISOString() === this.currentDate.toISOString()) {
      this.currentWeek = true
    } else {
      this.currentWeek = false
    }
  }

  next() {
    this.fromDate = this.toDate
    this.toDate = addDays(this.fromDate, 7)
    this.filterDays()
  }

  previous() {
    this.toDate = this.fromDate
    this.fromDate = subDays(this.toDate, 7)
    this.filterDays()
  }

  currentWeekSlots() {
    this.fromDate = getDateWithoutTime(new Date())
    this.toDate = addDays(this.fromDate, 7)
    this.filterDays()
  }

  buildEventForm(event: any): any {
    return new FormGroup({
      _id: new FormControl(event._id),
      title: new FormControl(event.title),
      start: new FormControl(event.start),
      end: new FormControl(event.end),
      allDay: new FormControl(event.allDay),
      color: this.formBuilder.group({
        primary: new FormControl(event.color.primary),
        secondary: new FormControl(event.color.secondary)
      }),
      meta: this.formBuilder.group({
        location: new FormControl(event.meta.location),
        notes: new FormControl(event.meta.notes)
      }),
      draggable: new FormControl(true)
    })
  }

  isSelected = (event: any) => {
    const date =
      event.getFullYear() +
      '-' +
      ('00' + (event.getMonth() + 1)).slice(-2) +
      '-' +
      ('00' + event.getDate()).slice(-2)
    return this.availableDays.find((x: string) => x == date) ? 'selected' : null
  }

  select(event: any, calendar: any) {
    const date =
      event.getFullYear() +
      '-' +
      ('00' + (event.getMonth() + 1)).slice(-2) +
      '-' +
      ('00' + event.getDate()).slice(-2)
    const index = this.availableDays.findIndex((x: string) => x == date)
    if (index < 0) {
      this.toastr.warning('Slots not available for the selected date')
    } else {
      this.fetchDaySlots(new Date(date))
    }
  }

  requestNewSlot() {
    this.dateSlots.push({ selectedStartTime: '0830', selectedEndTime: '0900' })
  }

  removeSlot(slot: any) {
    this.dateSlots = this.dateSlots.filter((val: any) => val !== slot)
  }

  bookSlot(date: any, slot: any) {
    this.selectedDate = new Date(date)
    this.selectedSlot = slot
    this.bookSlotClicked = true
  }

  cancel() {
    this.dialogRef.close({})
  }

  callback() {}

  fetchDaySlots(selectedDate: any) {
    this.selectedDateSlots = []
    const date =
      selectedDate.getFullYear() +
      '-' +
      ('00' + (selectedDate.getMonth() + 1)).slice(-2) +
      '-' +
      ('00' + selectedDate.getDate()).slice(-2)
    const dateSlots: any[] = this.events.filter((x) => x.start === date)
    dateSlots.forEach((slots) => {
      const availableSlots: any = this.timeSlotsList.filter(
        (val) =>
          parseInt(val.value) > parseInt(slots.selectedStartTime) &&
          parseInt(val.value) <= parseInt(slots.selectedEndTime)
      )
      this.selectedDateSlots = [...this.selectedDateSlots, ...availableSlots]
    })

    this.selectedSlot = this.selectedDateSlots[0]
    this.selectedDateSlots[0].class = 'primaryButton'
  }

  getDaySlots(timeSlots: any) {
    let selectedDateSlots: any[] = []
    let slotsArr: any[] = getArrayFromString(timeSlots)
    slotsArr.forEach((timeSlot) => {
      const timeRange: any[] = timeSlot.split(' - ')
      const startTime: any = timeRange[0].trim()
      const selectedStartTime: any = this.timeSlotsList.find((val) => val.value === startTime)
      const endTime: any = timeRange[1].trim()
      const selectedEndTime: any = this.timeSlotsList.find((val) => val.value === endTime)

      let availableSlots: any[] = this.timeSlotsList?.filter(
        (val) =>
          parseInt(val.value) > parseInt(selectedStartTime?.value) &&
          parseInt(val.value) <= parseInt(selectedEndTime?.value)
      )
      availableSlots.forEach((element) => {
        element.class = ''
      })
      selectedDateSlots = [...selectedDateSlots, ...availableSlots]
    })
    return selectedDateSlots
  }

  slotSelected(slot: any) {
    const replaceSlot: any = this.selectedDateSlots.findIndex(
      (val) => val.viewValue === this.selectedSlot.viewValue
    )
    if (replaceSlot >= 0) {
      this.selectedDateSlots[replaceSlot].class = 'secondaryButton'
    }
    this.selectedSlot = slot
    const updateSlot: any = this.selectedDateSlots.findIndex(
      (val) => val.viewValue === this.selectedSlot.viewValue
    )
    this.selectedDateSlots[updateSlot].class = 'primaryButton'
  }

  async book() {
    this.SpinnerService.show()
    if (this.isReschedule) {
      // TODO: Add the logic to cancel the meeting
      await this.CommonService.cancelTeamsMeeting(this.businessPostCandidateInfo)
      this.businessPostCandidateInfo.meeting_id = ''
      this.businessPostCandidateInfo.action_status = 5
      await this.roleService.updateRoleCandidate({
        role_id: this.jobInfo.id,
        user_id: this.businessPostCandidateInfo.user_id,
        body: this.businessPostCandidateInfo
      })
    }
    this.bookSlotClicked = true
    let startDate = new Date(this.selectedDate)
    const startDateTime =
      startDate.getFullYear() +
      '-' +
      ('00' + (startDate.getMonth() + 1)).slice(-2) +
      '-' +
      ('00' + startDate.getDate()).slice(-2) +
      ' ' +
      this.selectedSlot.value.substr(0, 2) +
      ':' +
      this.selectedSlot.value.substr(2, 4) +
      ':00'
    let enddate = new Date(startDateTime)
    const endTime = new Date(enddate.getTime() + 30 * 60000)
    const endDateTime =
      endTime.getFullYear() +
      '-' +
      ('00' + (endTime.getMonth() + 1)).slice(-2) +
      '-' +
      ('00' + endTime.getDate()).slice(-2) +
      ' ' +
      endTime.getHours() +
      ':' +
      ('0' + endTime.getMinutes()).slice(-2) +
      ':00'

    // TODO: Add the logic to schedule the meeting
    const result: any = await this.CommonService.scheduleTeamsMeeting(
      startDateTime,
      endDateTime,
      this.businessPostCandidateInfo,
      this.businessInfo,
      this.jobInfo
    )
    if (result.success == true) {
      // if (false) {
      // TODO: Implement the logic to send the notification
      // await this.CommonService.addAppNotification(
      //   "Event schedule",
      //   "Event schedule by " +
      //     this.userInfo.first_name +
      //     " " +
      //     this.userInfo.last_name +
      //     " for " +
      //     this.businessInfo.name,
      //   [this.businessInfo.id],
      //   "SCHEDULE",
      //   this.businessPostCandidateInfo.user_id
      // );
      this.toastr.success(
        'Meeting Request has been sent. You will be mailed with the details of the meeting upon confirmation by the Business'
      )
      this.dialogRef.close({
        action: 'book',
        selectedStartTime: this.selectedStartTime,
        selectedDate: this.selectedDate
      })
      this.SpinnerService.hide()
    } else {
      this.toastr.warning('The meeting link will be updated later')
      const businessPostCandidate = { ...this.businessPostCandidateInfo }
      businessPostCandidate.slot_start_time = startDateTime
      businessPostCandidate.slot_end_time = endDateTime
      businessPostCandidate.meeting_id = null
      businessPostCandidate.status = 1
      businessPostCandidate.action_status = 6
      await this.roleService
        .updateRoleCandidate({
          role_id: this.jobInfo.id,
          user_id: this.businessPostCandidateInfo.user_id,
          body: businessPostCandidate
        })
        .toPromise()
      // await this.CommonService.saveRecord(
      //   "BusinessPostCandidates",
      //   businessPostCandidate
      // );
      this.dialogRef.close()
      this.SpinnerService.hide()
    }
  }

  backTo() {
    this.bookSlotClicked = false
  }

  bookCancel() {
    this.visibleIndex = -1
  }

  dateSlotSelected(slot: any, selectedDate: any, ind: any) {
    if (this.visibleIndex === ind) {
      this.visibleIndex = -1
    } else {
      this.visibleIndex = ind
    }
    this.displaySlots.forEach((dateSlots) => {
      let slots: any[] = dateSlots.slots
      const replaceSlot: any = slots.findIndex(
        (val) => val.viewValue === this.selectedSlot.viewValue
      )
      if (replaceSlot >= 0) {
        slots[replaceSlot].class = 'secondaryButton'
      }
      this.selectedSlot = slot
      const updateSlot: any = slots.findIndex(
        (val) => val.viewValue === this.selectedSlot.viewValue && selectedDate === dateSlots.date
      )
      if (updateSlot >= 0) {
        slots[updateSlot].class = 'primaryButton'
      }
    })
    this.selectedDate = selectedDate
    this.meetingDate = new Date(this.selectedDate)
    this.selectedStartTime = this.selectedSlot.viewValue
    this.selectedEndTime = this.selectedSlot.viewValue
  }

  toggleView() {
    this.calendarView = !this.calendarView
  }
}
