<div class="body" fxLayout="row wrap">
  <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
    <div class="row col-12 pl-0">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <!--New Table-->
        <div fxLayout fxLayoutAlign="center center">
          <button
            type="button"
            class="close ml-2"
            aria-label="Close"
            data-dismiss="modal"
            (click)="closeModal()"
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <mat-form-field fxFlex="40%">
            <input
              matInput
              type="text"
              (keyup)="doFilter($event.target.value)"
              placeholder="Search"
            />
          </mat-form-field>
        </div>
        <table
          mat-table
          matTableFilter
          [dataSource]="dataSource"
          class="lessons-table mat-elevation-z8"
          matSort
        >
          <ng-container matColumnDef="id">
            <th
              mat-header-cell
              *matHeaderCellDef
              style="width: 7rem"
              class="table-header"
              mat-sort-header
            >
              Id
            </th>
            <td mat-cell style="width: 7rem" *matCellDef="let data">
              {{ data.id }}
            </td>
          </ng-container>

          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef class="table-header" mat-sort-header>Name</th>
            <td mat-cell *matCellDef="let data">{{ data.name }}</td>
          </ng-container>

          <ng-container matColumnDef="email_id">
            <th mat-header-cell *matHeaderCellDef class="table-header" mat-sort-header>Email</th>
            <td mat-cell *matCellDef="let data">{{ data?.email }}</td>
          </ng-container>

          <ng-container matColumnDef="primary_phone">
            <th mat-header-cell *matHeaderCellDef class="table-header" mat-sort-header>
              Phone Number
            </th>
            <td mat-cell *matCellDef="let data">{{ data?.primary_phone }}</td>
          </ng-container>

          <ng-container matColumnDef="match_perc">
            <th mat-header-cell *matHeaderCellDef class="table-header" mat-sort-header>
              Match Percentage
            </th>
            <td mat-cell *matCellDef="let data">{{ data?.match_perc || 0 }}%</td>
          </ng-container>

          <ng-container matColumnDef="profile_completion">
            <th mat-header-cell *matHeaderCellDef class="table-header" mat-sort-header>
              Profile Completion %
            </th>
            <td mat-cell *matCellDef="let data">{{ data?.profile_completion || 0 }}%</td>
          </ng-container>

          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef class="table-header" style="text-align: center">
              Action
            </th>
            <td mat-cell *matCellDef="let data" style="text-align: center">
              <button
                [disabled]="data?.businessPostCandidate?.action_status == 1"
                *ngIf="candidatesType == 'registerd_candidates'"
                mat-raised-button
                class="action-button"
                (click)="shortlistCandidate(data)"
              >
                {{ data?.application_meta?.action_status == 1 ? 'Shortlisted' : 'Shortlist' }}
              </button>
              <button
                [disabled]="data?.application_meta.status == 2"
                *ngIf="candidatesType == 'applied_candidates'"
                mat-raised-button
                class="action-button"
                (click)="rejectCandidate(data)"
              >
                {{ data?.application_meta?.action_status == 2 ? 'Rejected' : 'Reject' }}
              </button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
        <mat-paginator [pageSize]="10" [pageSizeOptions]="[10, 20, 30, 50]"> </mat-paginator>
      </div>
    </div>
  </div>
</div>
<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
  <p style="color: white">Please Wait.</p>
</ngx-spinner>
