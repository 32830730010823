import { Component, EventEmitter, OnInit, Output } from '@angular/core'
import { MatDialogRef } from '@angular/material/dialog'

declare var $: any

@Component({
  selector: 'app-field-alert',
  templateUrl: './field-alert.component.html',
  styleUrls: ['./field-alert.component.scss']
})
export class FieldAlertComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<FieldAlertComponent>) {}
  @Output() messageEvent = new EventEmitter<string>()

  ngOnInit(): void {
    $('.modal-content').on('contextmenu', function () {
      // Display the message
      return false
    })
  }
  proceed() {
    let msg = 'Proceed'
    this.messageEvent.emit(msg)
    this.dialogRef.close(msg)
  }

  cancel() {
    let msg = 'Cancel'
    this.messageEvent.emit(msg)
    this.dialogRef.close(msg)
  }

  closeModal() {
    let msg = 'Cancel'
    this.dialogRef.close(msg)
  }
}
