import { Component, OnInit } from '@angular/core'
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal'
import { getSessionValue, getArrayFromString, getDefaultProfileImage } from 'src/app/common-utils'
import { cloneDeep } from 'lodash'
import { CRUDService } from 'src/app/crud.service'
import { CommonService } from 'src/app/common.service'
import { NgxSpinnerService } from 'ngx-spinner'
import { ReasonModalComponent } from '../reason-modal/reason-modal.component'
import { environment } from 'src/environments/environment'

import { MatDialog, MatDialogRef } from '@angular/material/dialog'
import { NullTemplateVisitor } from '@angular/compiler'
import { MyScoreComponent } from '../my-score/my-score.component'
import { ToastrService } from 'ngx-toastr'
import { CultureScoreComponent } from '../culture-score/culture-score.component'
import { CandidateService } from 'src/app/shared/services/candidate.service'
import { ValuesMapService } from 'src/app/shared/services/values-map.service'
import { SkillsetService } from 'src/app/shared/services/skillset.service'
import { RoleService } from 'src/app/shared/services/role.service'
import { UserService } from 'src/app/shared/services/user.service'
import { ROLE_CANDIDATE_STATUS } from 'src/app/core/models/enums'

declare var $: any

@Component({
  selector: 'app-view-candidates',
  templateUrl: './view-candidates.component.html',
  styleUrls: ['./view-candidates.component.scss']
})
export class ViewCandidatesComponent implements OnInit {
  shortlist: any
  itemsPerSlide = 1
  singleSlideOffset = false
  noWrap = true
  benefitsArray: any[] = []
  skillsArray: any[] = []
  candidates: any[] = []
  userInfo: any
  businessProfile: any
  valuesList: any[] = []
  shortlistedCandidates: any
  appliedCandidates: any = []
  rejectedCandidates: any
  bsModalRef: BsModalRef
  selectedValue: any
  loaded = false
  actedCandidatedList: any
  cultureList: any = []
  cultureArray: any = []
  roleDetails: any = {}
  showDataEle: boolean = true
  showCandidateName: boolean = false
  showCandidateProfile: boolean = false
  showIndicator = true
  matchPercentage: number = 0
  allCandidates = []
  isViewAllApplicants: boolean = false
  isViewCandidateProfile: boolean = false
  viewSpiritAnimal: boolean
  roleDetail: any[] = [
    {
      name: 'Type',
      value: 'looking_for',
      percentage: 4
    },

    {
      name: 'Category',
      value: 'roles_interested',
      percentage: 4
    },

    {
      name: 'Skill Selector',
      value: 'technical_skills',
      percentage: 6
    },

    {
      name: 'Salary',
      value: 'salary',
      percentage: 1
    },

    {
      name: 'Day Rate',
      value: 'day_rate',
      percentage: 1
    },

    {
      name: 'Incentives',
      value: 'other_expectations',
      percentage: 1
    },

    {
      name: 'Leadership Role',
      value: '',
      percentage: 1
    },

    {
      name: 'Location',
      value: 'location',
      percentage: 1
    },

    {
      name: 'Remote Working',
      value: '',
      percentage: 1
    }
  ]
  roleValueAray: any[] = [
    'looking_for',
    'roles_interested',
    'technical_skills',
    'salary',
    'day_rate',
    'other_expectations',
    'location'
  ]
  role_completion: number = 0

  businessDetail: any[] = [
    {
      name: 'Industry',
      value: 'industry_interest',
      percentage: 45
    },
    {
      name: 'Business Size',
      value: 'business_size',
      percentage: 40
    }
    // {
    //   name: 'Hiring Manager',
    //   value: '',
    //   percentage: 5
    // }
  ]
  businessValueArray: any[] = ['industry_interest', 'business_size']
  business_completion: number = 0

  valueDetail: any[] = [
    {
      name: 'Values Selection',
      value: 'culture',
      percentage: 50
    }
  ]
  valueArray: any[] = ['culture']
  value_completion: number = 0

  miscellaneousDetail: any[] = [
    // {
    //   name: "COVID Vaccinated",
    //   value: "vaccinated_yn",
    //   percentage: 5,
    // },
  ]
  miscellaneousArray: any[] = []
  miscellaneous_completion: number = 0
  profileCompletion: any = 0
  selectedPersonalityType: any
  personalityTypeArray = [
    {
      name: 'Dog',
      image_name: 'dog.png',
      description: 'Perceptive, Supportive, Optimistic',
      long_desc:
        'Dogs are idealist organisers, driven to implement their vision of what is best for humanity. They often act as catalysts for human growth because of their ability to see potential in other people and their charisma in persuading others to their ideas.'
    },
    {
      name: 'Dolphin',
      image_name: 'dolphin.png',
      description: 'Enthusiastic, Creative, Engaging',
      long_desc:
        'Dolphins are creators with a focus on possibilities and a contagious enthusiasm for new ideas, people and activities. Energetic, warm, and passionate, ENFPs love to help other people explore their creative potential.'
    },
    {
      name: 'Narwhal',
      image_name: 'narwhal.png',
      description: 'Imaginative, Idealist, Compassionate',
      long_desc:
        'Narwhals are imaginative idealists, guided by their own core values and beliefs. To a Healer, possibilities are paramount; the reality of the moment is only of passing concern. They see potential for a better future, and pursue truth and meaning with their own flair.'
    },
    {
      name: 'Seahorse',
      image_name: 'seahorse.png',
      description: 'Mysterious, Intuitive, Nurturing',
      long_desc:
        'Seahorses are creative nurturers with a strong sense of personal integrity and a drive to help others realise their potential. Creative and dedicated, they have a talent for helping others with original solutions to their personal challenges.'
    },
    {
      name: 'Bear',
      image_name: 'bear.png',
      description: 'Powerful, Direct, Outgoing',
      long_desc:
        'Bears are strategic leaders, motivated to organise change. They are quick to see inefficiency and conceptualise new solutions, and enjoy developing long-range plans to accomplish their vision. They excel at logical reasoning and are usually articulate and quick-witted.'
    },
    {
      name: 'Octopus',
      image_name: 'octopus.png',
      description: 'Intellectual, Perceptive, Strategic',
      long_desc:
        'Octopus are analytical problem-solvers, eager to improve systems and processes with their innovative ideas. They have a talent for seeing possibilities for improvement, whether at work, at home, or in themselves.'
    },
    {
      name: 'Fox',
      image_name: 'fox.png',
      description: 'Clever, Curious, Quick',
      long_desc:
        'Foxes are inspired innovators, motivated to find new solutions to intellectually challenging problems. They are curious and clever, and seek to comprehend the people, systems, and principles that surround them.'
    },
    {
      name: 'Owl',
      image_name: 'owl.png',
      description: 'Original, Aloof, Philosophical',
      long_desc:
        'Owls are philosophical innovators, fascinated by logical analysis, systems, and design. They are preoccupied with theory, and search for the universal law behind everything they see. They want to understand the unifying themes of life, in all their complexity.'
    },
    {
      name: 'Whale',
      image_name: 'whale.png',
      description: 'Cooperative, Devoted, Conscientious',
      long_desc:
        'Executives are representatives of tradition and order, utilizing their understanding of what is right, wrong and socially acceptable to bring families and communities together. Embracing the values of honesty, dedication and dignity.'
    },
    {
      name: 'Meerkat',
      image_name: 'meerkat.png',
      description: 'Confident, Organised, Social',
      long_desc:
        'Meerkats are hardworking traditionalists, eager to take charge in organising projects and people. Orderly, rule-abiding, and conscientious, Meerkats like to get things done, and tend to go about projects in a systematic, methodical way.'
    },
    {
      name: 'Elephant',
      image_name: 'elephant.png',
      description: 'Reliable, Loyal, Principled',
      long_desc:
        'Elephants are industrious caretakers, loyal to traditions and organisations. They are practical, compassionate, and caring, and are motivated to provide for others and protect them from the perils of life.'
    },
    {
      name: 'Ant',
      image_name: 'ant.png',
      description: 'Dependable, Productive, Meticulous',
      long_desc:
        'Ants are responsible organisers, driven to create and enforce order within systems and institutions. They are neat and orderly, inside and out, and tend to have a procedure for everything they do.'
    },
    {
      name: 'Otter',
      image_name: 'otter.png',
      description: 'Charming, Energetic, Fun-Loving',
      long_desc:
        'Otters are vivacious entertainers who charm and engage those around them. They are spontaneous, energetic, and fun-loving, and take pleasure in the things around them: food, clothes, nature, animals, and especially people.'
    },
    {
      name: 'Beaver',
      image_name: 'beaver.png',
      description: 'Energetic, Playful, Adaptive',
      long_desc:
        'Beavers are energetic thrill-seekers who are at their best when putting out fires, whether literal or metaphorical. They bring a sense of dynamic energy to their interactions with others and the world around them.'
    },
    {
      name: 'Panda',
      image_name: 'panda.png',
      description: 'Spontaneous, Gentle, Present',
      long_desc:
        'Pandas are gentle caretakers who live in the present moment and enjoy their surroundings with cheerful, low-key enthusiasm. They are flexible and spontaneous, and like to go with the flow to enjoy what life has to offer.'
    },
    {
      name: 'Honey Bee',
      image_name: 'honeybee.png',
      description: 'Masterful, Responsive, Constructive',
      long_desc:
        'Bees are observant artisans with an understanding of mechanics and an interest in troubleshooting. They approach their environments with a flexible logic, looking for practical solutions to the problems at hand.'
    }
  ]
  personalityFactors
  cultureFactors
  clientCultureFactors = []
  clientCultureTestInfo
  rolePersonalityRankingData
  _activeSlideIndex: number = 0
  candidateId
  apiUrl: string = environment.apiUrl
  postResponses: any
  postResponse: any = {
    feedback: null,
    postId: null,
    rating: null,
    candidate_id: null
  }
  isViewCandidateByToken: boolean = false

  dialogRef: MatDialogRef<ReasonModalComponent> = Object.create(NullTemplateVisitor)
  displayCandidates: boolean = false
  constructor(
    private dialog: MatDialog,
    private CRUDService: CRUDService,
    private candidateService: CandidateService,
    private valueMapService: ValuesMapService,
    private skillsetService: SkillsetService,
    private userService: UserService,
    private roleService: RoleService,
    private bsModalService: BsModalService,
    private bsModalRef1: BsModalRef<ViewCandidatesComponent>,
    private SpinnerService: NgxSpinnerService,
    private options: ModalOptions,
    private commonService: CommonService,
    private toastr: ToastrService
  ) {
    if (typeof this.options.initialState !== 'undefined') {
      if (this.options.initialState.type !== 'view-candidate-by-token') {
        if (this.options.initialState.type !== 'view-candidate') {
          if (this.options.initialState.type !== 'candidates') {
            this.userInfo = getSessionValue('USER_INFO')
            this.businessProfile = getSessionValue('BUSINESS_PROFILE')
            this.roleDetails = this.options.initialState.data
            this.candidateId = this.options.initialState?.candidateId
            if (this.roleDetails.display_candidates == 'Y') {
              this.displayCandidates = true
            }
            this.valuesList = getArrayFromString(this.businessProfile.company_values)
          } else {
            this.userInfo = getSessionValue('USER_INFO')
            this.businessProfile = getSessionValue('BUSINESS_PROFILE')
            this.roleDetails = this.options.initialState.data
            if (this.roleDetails.display_candidates == 'Y') {
              this.displayCandidates = true
            }
            this.valuesList = getArrayFromString(this.businessProfile.company_values)
          }
        } else {
          this.showDataEle = false
          const optionData: any = this.options.initialState.data
          this.userInfo = getSessionValue('USER_INFO')
          this.businessProfile = getSessionValue('BUSINESS_PROFILE')
          this.roleDetails = optionData?.businessData?.[0]
          this.valuesList = getArrayFromString(this.businessProfile?.company_values)
          if (
            (optionData?.eventData?.status == '0' && optionData?.eventData?.action_status == '0') ||
            (optionData?.eventData?.status == '1' && optionData?.eventData?.action_status == '5')
          ) {
            this.showCandidateProfile = true
          } else {
            this.showCandidateProfile = false
          }
        }
      } else {
        this.isViewCandidateByToken = true
        this.showDataEle = false
      }
    }
  }

  async ngOnInit(): Promise<void> {
    this.viewSpiritAnimal = environment.viewSpiritAnimal
    $('.modal-content').on('contextmenu', function () {
      return false
    })
    // this.roleDetails = history.state.roleInfo;

    this.SpinnerService.show()
    await this.loadValueMapList()
    if (!this.isViewCandidateByToken) {
      await this.loadRolePersonalityRanking()
      const candidatesRes = await this.roleService
        .getRoleCandidates({ role_id: this.roleDetails.id })
        .toPromise()
      this.actedCandidatedList = candidatesRes.data.items
      this.shortlistedCandidates = this.actedCandidatedList.filter(
        (val) => val.status == '1' && val.action_status == '1'
      )
      this.rejectedCandidates = this.actedCandidatedList.filter((val) => val.status == '2')

      if (this.options.initialState.type !== 'view-candidate') {
        if (this.displayCandidates) {
          if (this.candidateId || this.options.initialState.type === 'candidates') {
            this.matchPercentage = 0
          } else {
            this.matchPercentage = 0
          }
          await this.loadCandidates()
          await this.onChangeMatchPerc(this.matchPercentage)
        }
      } else {
        this.candidates = []
        const candidate: any = this.options.initialState.data
        if (
          (candidate?.eventData?.status == '0' && candidate?.eventData?.action_status == '0') ||
          (candidate?.eventData?.status == '1' && candidate?.eventData?.action_status == '5')
        ) {
          this.showCandidateProfile = true
        } else {
          this.showCandidateProfile = false
        }
        let candidateInfo = await this.getCandidateData(candidate)
        this.candidates.push(candidateInfo)
      }

      this.postResponses = []
      this.SpinnerService.hide()
    } else {
      let candidateInfo = await this.getCandidateData(this.options.initialState.data)
      candidateInfo['showCandidateProfile'] = true
      this.candidates.push(candidateInfo)
      this.SpinnerService.hide()
    }
    this.loaded = true
  }

  get activeSlideIndex() {
    return this._activeSlideIndex
  }

  set activeSlideIndex(newIndex: number) {
    if (this._activeSlideIndex !== newIndex) {
      const businessPostCandidate = this.candidates[newIndex]?.BusinessPostCandidate
      if (businessPostCandidate) {
        if (JSON.stringify(businessPostCandidate) !== '{}') {
          if (businessPostCandidate?.seen_yn !== 'Y') {
            businessPostCandidate.seen_yn = 'Y'
            this.roleService.updateRoleCandidate({
              role_id: this.roleDetails.id,
              user_id: businessPostCandidate?.user_id,
              body: businessPostCandidate
            })
          }
        } else {
          businessPostCandidate['post_id'] = this.roleDetails.id
          businessPostCandidate['user_id'] = this.candidates[newIndex]?.user_id
          businessPostCandidate['match_perc'] = this.candidates[newIndex].match_perc
          businessPostCandidate['seen_yn'] = 'Y'
          businessPostCandidate['status'] = 0
          this.roleService.updateRoleCandidate({
            role_id: this.roleDetails.id,
            user_id: businessPostCandidate?.user_id,
            body: businessPostCandidate
          })
        }
      }
      this._activeSlideIndex = newIndex
    }

    if (newIndex === 0) {
      const businessPostCandidate = this.candidates[0]?.BusinessPostCandidate
      if (businessPostCandidate) {
        if (JSON.stringify(businessPostCandidate) !== '{}') {
          if (businessPostCandidate?.seen_yn !== 'Y') {
            businessPostCandidate.seen_yn = 'Y'
            this.roleService.updateRoleCandidate({
              role_id: this.roleDetails.id,
              user_id: businessPostCandidate?.user_id,
              body: businessPostCandidate
            })
          }
        } else {
          businessPostCandidate['post_id'] = this.roleDetails.id
          businessPostCandidate['user_id'] = this.candidates[newIndex]?.user_id
          businessPostCandidate['match_perc'] = this.candidates[newIndex].match_perc
          businessPostCandidate['seen_yn'] = 'Y'
          businessPostCandidate['status'] = 0
          this.roleService.updateRoleCandidate({
            role_id: this.roleDetails.id,
            user_id: businessPostCandidate?.user_id,
            body: businessPostCandidate
          })
        }
      }
    }
  }

  async loadValueMapList() {
    const personalityFactors = await this.valueMapService
      .getFilteredValuesMap(100, 0, '', 'PERSONALITY_FACTORS')
      .toPromise()
    this.personalityFactors = personalityFactors.data.items
    this.personalityFactors.forEach((ele) => {
      ele['description'] = JSON.parse(ele['description']?.replace('"Adjectives"', ', "Adjectives"'))
    })

    const cultureFactors = await this.valueMapService
      .getFilteredValuesMap(100, 0, '', 'CULTURE_FACTORS')
      .toPromise()
    this.cultureFactors = cultureFactors.data.items
    this.cultureFactors.forEach((ele) => {
      if (ele?.description) {
        ele['description'] = JSON.parse(ele['description'])
      }
    })
    this.loadClientCultureFactors()

    const benefits = await this.valueMapService
      .getFilteredValuesMap(100, 0, '', 'BENEFITS')
      .toPromise()
    this.roleDetails.benefitsArray = benefits.data.items || []

    const values = await this.valueMapService.getFilteredValuesMap(100, 0, '', 'VALUES').toPromise()
    this.cultureArray = values.data.items || []
    this.roleDetails.cultureArray = this.cultureArray

    let domain_exp_list = await this.valueMapService
      .getFilteredValuesMap(100, 0, '', 'DOMAIN_EXP')
      .toPromise()
    let salary_list = await this.valueMapService
      .getFilteredValuesMap(100, 0, '', 'SALARY')
      .toPromise()
    sessionStorage.setItem('domain_exp_list', JSON.stringify(domain_exp_list.data.items))
    sessionStorage.setItem('salary_list', JSON.stringify(salary_list.data.items))

    let skillData = await this.skillsetService.getFilteredSkillset(2000, 0, '').toPromise()
    const names = skillData.data.items.map(function (item: any) {
      return item.category
    })
    this.skillsArray = names

    this.roleDetails.skillsArray = this.getSourceList(this.roleDetails.skills, this.skillsArray)
  }

  loadClientCultureFactors() {
    if (
      this.roleDetails?.culture_profile &&
      this.isStringifiedJson(this.roleDetails?.culture_profile)
    ) {
      this.clientCultureFactors = cloneDeep(this.cultureFactors)
      if (this.roleDetails.culture_profile) {
        let cultureData = JSON.parse(this.roleDetails.culture_profile)
        cultureData = cultureData.sort((a, b) => a.rank - b.rank)
        this.clientCultureTestInfo = this.cultureFactors.find(
          (e) => e.description.name === cultureData[0]?.name
        )?.description

        this.clientCultureFactors.forEach((value) => {
          const culture = cultureData.find((e) => e.name === value?.description?.name)
          if (culture) {
            value['count'] = culture.score
          }
        })
      }
    }
  }

  async loadRolePersonalityRanking() {
    try {
      const data = await this.roleService
        .getPersonalityRankingByRoleId(this.roleDetails?.id)
        .toPromise()

      if (data?.data) {
        this.rolePersonalityRankingData = data.data
      }
    } catch (e) {
      console.error(e)
    }
  }

  async loadCandidates() {
    let data = []
    const res = await this.roleService
      .getRoleCandidates({ role_id: this.roleDetails.id })
      .toPromise()
    data = res.data.items
    data = data.filter((val) => val.experience_1_compname != this.businessProfile.name)
    const candidateIds: any = this.options.initialState?.candidatesIds
    for (let candidate of data) {
      if (candidate.currenly_employed_yn == 'Y') {
        candidate.currentlyEmployed = true
      } else {
        candidate.currentlyEmployed = false
      }
      const index = this.actedCandidatedList.findIndex(
        (val: any) => val.user_id === candidate.user_id
      )
      candidate.applied = false
      candidate.BusinessPostCandidate = {}
      if (index > -1) {
        candidate.applied = true
        candidate.BusinessPostCandidate = this.actedCandidatedList[index]
      }

      if (candidateIds?.length > 0) {
        if (candidateIds.includes(candidate.id)) {
          let candidateInfo = await this.getCandidateData(candidate)
          if (this.roleDetails?.bias === true) {
            if (
              candidateInfo.BusinessPostCandidate.shortlist_type == 'Applied' ||
              candidateInfo.BusinessPostCandidate.shortlist_type == 'Premium'
            ) {
              candidateInfo['showCandidateProfile'] = true
            } else if (
              candidateInfo.BusinessPostCandidate.action_status == '5' ||
              candidateInfo.BusinessPostCandidate.action_status == '0'
            ) {
              candidateInfo['showCandidateProfile'] = true
            } else {
              candidateInfo['showCandidateProfile'] = false
            }
          } else if (this.roleDetails?.bias === false) {
            setTimeout(() => {
              candidateInfo['showCandidateProfile'] = false
            }, 0)
          }
          this.allCandidates.push(candidateInfo)
        }
      } else {
        let candidateInfo = await this.getCandidateData(candidate)
        this.allCandidates.push(candidateInfo)
      }
    }
  }

  async getCandidateData(candidate): Promise<any> {
    if (candidate.currenly_employed_yn == 'Y') {
      candidate.currentlyEmployed = true
    } else {
      candidate.currentlyEmployed = false
    }
    this.role_completion = 0
    this.business_completion = 0
    this.value_completion = 0
    this.miscellaneous_completion = 0
    this.profileCompletion = 0

    if (!this.isViewCandidateByToken) {
      const matchPerc = await this.commonService.calculateMatchPercV1_1(
        candidate,
        this.businessProfile,
        this.roleDetails
      )
      candidate.match_perc = matchPerc
    }
    candidate.skillsList = null
    if (typeof candidate.skills !== 'undefined') {
      if (candidate.skills !== null) {
        candidate.skillsList = this.getActiveInactiveSkillsList(
          candidate.skills,
          this.roleDetails.skillsArray
        )
      }
    }
    candidate.benefitsList = null
    if (typeof candidate.other_expectations !== 'undefined') {
      if (candidate.other_expectations !== null) {
        candidate.benefitsList = this.getActiveInactiveList(
          candidate.other_expectations,
          this.roleDetails?.benefitsArray
        )
      }
    }
    candidate.valuesList = null
    if (typeof candidate.candidate_values !== 'undefined') {
      if (candidate.candidate_values !== null) {
        candidate.valuesList = this.getActiveInactiveListForOther(
          candidate.candidate_values,
          this.valuesList
        )
      }
    }

    candidate.technical_skills = Array.isArray(candidate?.technical_skills)
      ? candidate?.technical_skills
      : candidate.technical_skills?.split(';')
    candidate.frustrations = Array.isArray(candidate?.frustrations)
      ? candidate?.frustrations
      : candidate.frustrations?.split(';')
    candidate.personality = Array.isArray(candidate?.personality)
      ? candidate?.personality
      : candidate.personality?.split(';')
    candidate.languages = Array.isArray(candidate?.languages)
      ? candidate?.languages
      : candidate.languages?.split(';')
    candidate.domain_exp = Array.isArray(candidate?.domain_exp)
      ? candidate?.domain_exp
      : candidate.domain_exp?.split(';')
    candidate.industry_interest = Array.isArray(candidate?.industry_interest)
      ? candidate?.industry_interest
      : candidate.industry_interest?.split(';')
    candidate.roles_interested = Array.isArray(candidate?.roles_interested)
      ? candidate?.roles_interested
      : candidate.roles_interested?.split(';')
    candidate.looking_for = Array.isArray(candidate?.looking_for)
      ? candidate?.looking_for
      : candidate.looking_for?.split(';')
    candidate.personalityTestInfo = this.personalityFactors?.find((value) => {
      return value.code === candidate.personality_archetype
    })
    if (candidate?.culture && this.isStringifiedJson(candidate?.culture)) {
      let cultureData = JSON.parse(candidate?.culture)
      cultureData = cultureData.sort((a, b) => a.rank - b.rank)
      candidate.cultureTestInfo = this.cultureFactors?.find((value) => {
        return value?.description?.name === cultureData[0]?.name
      })?.description

      const candidateCultureFactors = cloneDeep(this.cultureFactors)
      candidateCultureFactors.forEach((value) => {
        const culture = cultureData.find((e) => e.name === value.description.name)
        value['count'] = culture.score
      })

      candidate.candidateCultureFactors = candidateCultureFactors
    }
    candidate.image_url = getDefaultProfileImage(candidate)
    candidate['github_url'] = candidate?.github_url ? candidate?.github_url?.split(';') : []
    candidate['web_url'] = candidate?.web_url ? candidate?.web_url?.split(';') : []
    candidate['profileLink'] = `${environment.appRoot}/view-candidate/${encodeURIComponent(
      this.commonService.encryptData(candidate?.id)
    )}`
    /* if (candidate.match_perc >= this.matchPercentage) {
      this.candidates.push(candidate);
    } */
    return candidate
  }

  async toggleShortList(candidate: any) {
    let BusinessPostCandidates: any = {}
    if (candidate.applied) {
      BusinessPostCandidates = candidate.BusinessPostCandidate
      BusinessPostCandidates.action_status = 1
      BusinessPostCandidates.status = 1
      BusinessPostCandidates.match_perc = candidate.match_perc
      BusinessPostCandidates.round_no = 1
    } else {
      BusinessPostCandidates.post_id = this.roleDetails.id
      BusinessPostCandidates.user_id = candidate.user_id
      BusinessPostCandidates.round_no = 1
      BusinessPostCandidates.status = 1
      BusinessPostCandidates.action_status = 1
      BusinessPostCandidates.match_perc = candidate.match_perc
      BusinessPostCandidates.shortlist_type = 'Matched'
    }

    // const initialState: any = {
    //   type: 'accept'
    // }

    let initialState = 'accept'
    this.dialogRef = this.dialog.open(ReasonModalComponent, {
      data: { initialState },
      panelClass: 'event-details-dialog',
      width: '30vw',
      autoFocus: false
    })

    this.dialogRef.afterClosed().subscribe(async (res) => {
      BusinessPostCandidates.remarks = res?.reason
      const users: any[] = []
      users.push(candidate.user_id)
      await this.roleService
        .shortlistCandidate(this.roleDetails.id, candidate.user_id, BusinessPostCandidates.remarks)
        .toPromise()
      // TODO: add app notification probably from backend
      // this.commonService.addAppNotification(
      //   "New Job Posting by " + this.businessProfile.name,
      //   "You have been shortlisted for the role " + this.roleDetails.title,
      //   users,
      //   "ROLE",
      //   this.roleDetails.id
      // );
      candidate.action_status = '1'
      //this.allCandidates = this.allCandidates.filter((e) => e.status == '1' && e.action_staus == '1');
      await this.reloadList(candidate)
      this.SpinnerService.hide()
      const user = await this.userService.getById(candidate.user_id).toPromise()

      const body: any = {
        name: user.data.first_name,
        business_name: this.businessProfile.name,
        token: '',
        to: user.data.email
      }

      // body.cc = ['pri@upplft.com', 'pri@upplft.com'];
      if (environment.test) {
      } else {
        body.bcc = ['neil@upplft.com', 'christian@upplft.com', 'adam@upplft.com']
      }

      // TODO: mails from backend
      // this.CRUDService.shortlistMailer(body).subscribe((data: any) => {});
    })

    /*
    this.bsModalRef = this.bsModalService.show(ReasonModalComponent,
      { ariaLabelledBy: 'modal-basic-title', class: 'modal-md reason-modal',
      backdrop: 'static', initialState });
    this.bsModalRef.content.messageEvent.subscribe(async (data) => {
      // this.SpinnerService.show();

      BusinessPostCandidates.remarks = data.reason;
      let users: any[] = [];
      users.push(candidate.user_id);
      let response = await this.commonService.updateRecord('BusinessPostCandidates', BusinessPostCandidates);
      let Businessdata = await this.CRUDService.getAllData('BusinessPostCandidates').toPromise();
      this.commonService.addAppNotification('New Job Posting by ' + this.businessProfile.name, 'You have been invited to apply for the Role of ' + this.roleDetails.title,
        users,
        'ROLE', this.roleDetails.id);
      await this.reloadList(candidate);
      this.SpinnerService.hide();
      let user = await this.CRUDService.getDataByField(
        'users',
        'id',
        candidate.user_id
      ).toPromise();

      let body: any = {
        name: user[0].first_name,
        business_name: this.businessProfile.name,
        token: user[0].token,
        to: user[0].email_id,
      };

      //body.cc = ['pri@upplft.com', 'pri@upplft.com'];
      if (environment.test) {
      } else {
        body.bcc = [
          'neil@upplft.com',
          'christian@upplft.com',
          'adam@upplft.com',
        ];
      }

      this.CRUDService.shortlistMailer(body).subscribe((data: any) => {
      });
    });*/
  }

  async toggleReject(candidate: any) {
    let BusinessPostCandidates: any = {}
    if (candidate.applied) {
      BusinessPostCandidates = candidate.BusinessPostCandidate
      BusinessPostCandidates.action_status = 2
      BusinessPostCandidates.match_perc = candidate.match_perc
      BusinessPostCandidates.status = 2
      BusinessPostCandidates.round_no = 1
    } else {
      BusinessPostCandidates.post_id = this.roleDetails.id
      BusinessPostCandidates.user_id = candidate.user_id
      BusinessPostCandidates.round_no = 1
      BusinessPostCandidates.status = 2
      BusinessPostCandidates.action_status = 2
      BusinessPostCandidates.match_perc = candidate.match_perc
    }

    let initialState = 'reject'
    this.dialogRef = this.dialog.open(ReasonModalComponent, {
      data: { initialState },
      panelClass: 'event-details-dialog',
      width: '30vw'
    })

    this.dialogRef.afterClosed().subscribe(async (res) => {
      BusinessPostCandidates.remarks = res?.reason
      await this.roleService
        .updateRoleCandidateStatus({
          role_id: this.roleDetails.id,
          user_id: candidate.user_id,
          status: ROLE_CANDIDATE_STATUS.REJECT_CANDIDATE,
          remarks: res?.reason
        })
        .toPromise()
      await this.reloadList(candidate)
      this.SpinnerService.hide()
    })
  }

  async reloadList(candidate) {
    const index = this.candidates.findIndex((val) => val.id === candidate.id)
    this.candidates[index].action_status = '1'
    this.candidates[index].applied = true
    this.actedCandidatedList.push(this.candidates[index])
    const index_all = this.allCandidates.findIndex((val) => val.id === candidate.id)
    this.allCandidates[index_all].action_status = '1'
    this.allCandidates[index_all].applied = true
    // this.allCandidates.splice(index, 1);
    this.shortlistedCandidates = this.actedCandidatedList.filter(
      (val) => val.status == '1' && val.action_status == '1'
    )
    this.rejectedCandidates = this.actedCandidatedList.filter((val) => val.status == '2')
    this.onChangeMatchPerc(this.matchPercentage)
    /*  = await this.CRUDService.getDataByField(
      'BusinessPostCandidates',
      'post_id',
      this.roleDetails.id
    ).toPromise(); */
  }

  getActiveInactiveList(value: any, array: any[]): any[] {
    const arrayValue: any[] = getArrayFromString(value)
    const valueList: any[] = []
    array.forEach((element) => {
      const value: any = {}
      const index = arrayValue.findIndex(
        (val) => val.toLowerCase() == element.description.toLowerCase()
      )
      value.description = element.description
      if (index > -1) {
        value.active = true
      } else {
        value.active = false
      }
      valueList.push(value)
    })
    return valueList
  }

  getActiveInactiveSkillsList(value: any, array: any[]): any[] {
    const arrayValue: any[] = getArrayFromString(value)
    const valueList: any[] = []
    arrayValue.forEach((element) => {
      const value: any = {}
      const index = array.findIndex((val) => val == element)
      value.description = element
      if (index > -1) {
        value.active = true
      } else {
        value.active = false
      }
      valueList.push(value)
    })

    return valueList
  }

  getActiveInactiveListForOther(value: any, array: any[]): any[] {
    const arrayValue: any[] = getArrayFromString(value)
    const valueList: any[] = []
    array.forEach((element) => {
      const value: any = {}
      const index = arrayValue.findIndex((val) => val == element)
      value.description = element
      if (index > -1) {
        value.active = true
      } else {
        value.active = false
      }
      valueList.push(value)
    })

    return valueList
  }

  getSourceList(value: any, array: any[]): any[] {
    const list: any[] = []
    if (typeof value !== 'undefined') {
      const arrayValue: any[] = getArrayFromString(value)
      arrayValue.forEach((element) => {
        const item: any = array.find((val) => val === element)
        list.push(item)
      })
    }
    return list
  }

  getList(value: any, array: any[]): any[] {
    // const list: any[] = [];
    if (typeof value !== 'undefined') {
      const arrayValue: any[] = getArrayFromString(value)
      arrayValue.forEach((element) => {
        const item: any = {}
        item.description = element
        const index = array.findIndex((val) => val.description === element)
        if (index > -1) {
          array[index].active = true
          // item.active = true;
        } else {
          // item.active = false;
        }
        // list.push(item);
      })
    }
    return array
  }

  openFeedbackModal(targetModal: any, data: any) {
    const postResponse = this.postResponses.find((value) => value.candidate_id == data.id)
    if (postResponse) {
      this.postResponse = postResponse
    } else {
      this.postResponse = {
        feedback: null,
        post_id: this.roleDetails?.id,
        rating: null,
        candidate_id: data?.id
      }
    }
    this.bsModalRef = this.bsModalService.show(targetModal)
  }
  closeModal() {
    this.bsModalRef1.hide()
    // this.bsModalService.hide();
  }
  closeFeedbackModal() {
    this.bsModalRef.hide()
  }
  async submit() {
    const result: any = await this.commonService.saveRecord(
      'BusinessPostResponses',
      this.postResponse
    )
    if (result.success == true || result.success == 'true') {
      this.toastr.success('Feedback submitted successfully')
      this.postResponses.push(result.data[0])
      this.bsModalRef.hide()
    } else {
      this.toastr.warning('Something went wrong, please try again later')
      this.bsModalRef.hide()
    }
  }

  domainExp(skill: any) {
    const index = skill.indexOf('-')
    if (index > skill.length - 1) {
      return skill
    }
    return skill.substring(0, index) + ' - ' + skill.substring(index + 1)
  }

  personalityFn(value) {
    const str = value?.toString()
    const newStr = str?.replace(/,/g, ', ')
    return newStr
  }

  personalityDesc(value) {
    if (value !== null && value != '') {
      const type = this.personalityTypeArray.find((val) => val.name == value)
      this.selectedPersonalityType = type
    } else {
      this.selectedPersonalityType = this.personalityTypeArray[0]
    }
    return this.selectedPersonalityType.long_desc
  }

  calcRoleCompletion(candidate) {
    this.roleValueAray.map((value) => {
      if (candidate[value] !== undefined && candidate[value] !== null && candidate[value] !== '') {
        const obj = this.roleDetail.filter((e) => e.value === value)
        this.role_completion += obj[0].percentage
      }
    })
    this.profileCompletion += this.role_completion

    this.calcBusinessCompletion(candidate)
  }

  calcBusinessCompletion(candidate) {
    this.businessValueArray.map((value) => {
      if (candidate[value] !== undefined && candidate[value] !== null && candidate[value] !== '') {
        const obj = this.businessDetail.filter((e) => e.value === value)
        this.business_completion += obj[0].percentage
      }
    })
    this.profileCompletion += this.business_completion
    this.calcValueCompletion(candidate)
  }

  calcValueCompletion(candidate) {
    this.valueArray.map((value) => {
      if (candidate[value] !== undefined && candidate[value] !== null && candidate[value] !== '') {
        const obj = this.valueDetail.filter((e) => e.value === value)
        this.value_completion += obj[0].percentage
      }
    })
    this.profileCompletion += this.value_completion

    this.calcMiscellaneousCompletion(candidate)
  }

  calcMiscellaneousCompletion(candidate) {
    this.miscellaneousArray.map((value) => {
      if (candidate[value] !== undefined && candidate[value] !== null && candidate[value] !== '') {
        const obj = this.miscellaneousDetail.filter((e) => e.value === value)
        this.miscellaneous_completion += obj[0].percentage
      }
    })
    this.profileCompletion += this.miscellaneous_completion
  }

  formatLabel(value: number): string {
    return `${value}%`
  }

  async onChangeMatchPerc(event) {
    this.candidates = []
    if (this.isViewAllApplicants) {
      await this.viewAppliedCandidates()
    } else {
      this.matchPercentage = event
      this.viewMatches()
    }
  }

  toggleMatches() {
    this.isViewAllApplicants = !this.isViewAllApplicants
    this.onChangeMatchPerc(this.matchPercentage)
  }

  async viewAppliedCandidates() {
    let appliedCandidates = this.actedCandidatedList.filter(
      (val) => val.status == '0' && val.action_status == '0'
    )
    const appliedCandidatesIds = appliedCandidates.map((value) => {
      return value.user_id
    })
    this.candidates = this.allCandidates.filter((e) => appliedCandidatesIds.includes(e.user_id))
  }

  viewMatches() {
    const appliedCandidates = this.actedCandidatedList.filter((value) => {
      return (
        value.action_status == '1' ||
        value.action_status == '5' ||
        value.action_status == '6' ||
        value.action_status == '7' ||
        value.action_status == '8' ||
        value.action_status == '2' ||
        value.action_status == '3'
      )
    })
    const actedCandidatesIds = appliedCandidates.map((value) => {
      return value.user_id
    })
    if (this.options.initialState.type !== 'candidates') {
      this.candidates = []
      this.candidates = this.allCandidates.filter(
        (e) =>
          (!actedCandidatesIds.includes(e.user_id) || e.id == this.candidateId) &&
          e.match_perc >= this.matchPercentage
      )

      setTimeout(() => {
        this.candidates.forEach((value) => {
          value['showCandidateProfile'] = this.isProfileShown(value)
          value['showButtonSection'] = this.isButtonSectionShown(value)
        })
      }, 500)
    } else {
      this.candidates = this.allCandidates.filter((e) => e.match_perc >= this.matchPercentage)

      setTimeout(() => {
        this.candidates.forEach((value) => {
          value['showCandidateProfile'] = this.isProfileShown(value)
          value['showButtonSection'] = this.isButtonSectionShown(value)
        })
      }, 500)
    }
    this.candidates.sort(function (x, y) {
      return y.match_perc - x.match_perc
    })

    setTimeout(() => {
      const index = this.candidates.findIndex((value) => {
        return value.id == this.candidateId
      })
      if (index !== -1) {
        this.activeSlideIndex = index
      } else {
        this.activeSlideIndex = 0
      }
    }, 1000)
  }

  isProfileShown(candidate) {
    const isBiased = this.roleDetails?.bias || false
    const status = candidate?.BusinessPostCandidate?.status
    const actionStatus = candidate?.BusinessPostCandidate?.application_meta?.action_status
    if (status == 0 && (actionStatus == 0 || actionStatus == null)) {
      return isBiased
    }
    if (status == 1) {
      if (actionStatus == 0 || actionStatus == 1 || actionStatus == null) {
        return isBiased
      }
    }
    return true
  }

  isButtonSectionShown(candidate) {
    const status = candidate?.BusinessPostCandidate?.status
    const actionStatus = candidate?.BusinessPostCandidate?.application_meta?.action_status
    if (actionStatus == 0 || actionStatus == null) {
      return true
    }
    if (status == 0) {
      return true
    }
    return false
  }

  openAlignmentModal(candidate, type) {
    if (type === 'personality') {
      const personalityFactorsClone = JSON.parse(JSON.stringify(this.personalityFactors))
      const candidatePersonalityArchetype = candidate.personalityTestInfo
      const candidatePersonalityTestInfo = JSON.parse(candidate.personality_test_info)
      const candidatePersonalityFactors = []
      personalityFactorsClone.forEach((value) => {
        value['count'] = candidatePersonalityTestInfo[value?.code]
        candidatePersonalityFactors.push(value)
      })

      candidatePersonalityFactors.sort((a, b) => {
        return Number(b?.count) - Number(a?.count)
      })
      candidatePersonalityFactors.forEach((value, index) => {
        value['count'] = 6 - index
      })

      let roleArchetype
      for (let i = 1; i <= 6; i++) {
        if (this.rolePersonalityRankingData?.[`rank_${i}_trait`]) {
          roleArchetype = this.rolePersonalityRankingData[`rank_${i}_trait`]
          break
        }
      }

      const rolePersonalityRankArchetype = personalityFactorsClone.find((value) => {
        return value.code === roleArchetype
      })
      const rolePersonalityFactors = []
      this.personalityFactors.forEach((value) => {
        if (value?.code === this.rolePersonalityRankingData?.rank_1_trait) {
          value['count'] = 6
        } else if (value?.code === this.rolePersonalityRankingData?.rank_2_trait) {
          value['count'] = 5
        } else if (value?.code === this.rolePersonalityRankingData?.rank_3_trait) {
          value['count'] = 4
        } else if (value?.code === this.rolePersonalityRankingData?.rank_4_trait) {
          value['count'] = 3
        } else if (value?.code === this.rolePersonalityRankingData?.rank_5_trait) {
          value['count'] = 2
        } else if (value?.code === this.rolePersonalityRankingData?.rank_6_trait) {
          value['count'] = 1
        } else {
          value['count'] = 0
        }
        rolePersonalityFactors.push(value)
      })

      this.dialog.open(MyScoreComponent, {
        panelClass: 'my-score-modal',
        autoFocus: false,
        data: {
          candidatePersonalityArchetype: candidatePersonalityArchetype,
          personalityTestInfo: rolePersonalityRankArchetype,
          candidatePersonalityFactors: candidatePersonalityFactors,
          personalityFactors: rolePersonalityFactors,
          header: 'Personality Match',
          isMatchChart: true
        }
      })
    } else {
      let cultureFactors = [...this.clientCultureFactors]

      if (!cultureFactors?.length) {
        cultureFactors = this.cultureFactors.map((e) => {
          e['count'] = 0
          return e
        })
      }

      this.dialog.open(CultureScoreComponent, {
        panelClass: 'my-score-modal',
        autoFocus: false,
        data: {
          cultureTestInfo: this.clientCultureTestInfo,
          cultureFactors: cultureFactors,
          candidateCultureFactors: candidate?.candidateCultureFactors || [],
          header: 'Culture',
          isMatchChart: true
        }
      })
    }
  }

  openUrl(url) {
    if (url) {
      let newUrl = url
      if (!newUrl.includes('http://') && !newUrl.includes('https://')) {
        newUrl = 'http://' + newUrl
      }
      window.open(newUrl, '_blank')
    }
  }

  download(url) {
    if (url) {
      const data = JSON.parse(url)
      if (data?.url) {
        const newUrl = data?.url
        window.open(newUrl, '_blank')
      }
    }
  }

  async toggleFavorite(candidate) {
    if (JSON.stringify(candidate.BusinessPostCandidate) !== '{}') {
      if (candidate.BusinessPostCandidate['favourite_yn'] == 'Y') {
        candidate.BusinessPostCandidate['favourite_yn'] = 'N'
      } else {
        candidate.BusinessPostCandidate['favourite_yn'] = 'Y'
      }

      await this.roleService.updateRoleCandidate({
        role_id: this.roleDetails.id,
        user_id: candidate.user_id,
        body: candidate.BusinessPostCandidate
      })
    } else {
      const businessPostCandidate = {}
      businessPostCandidate['post_id'] = this.roleDetails.id
      businessPostCandidate['user_id'] = candidate.user_id
      businessPostCandidate['match_perc'] = candidate.match_perc
      businessPostCandidate['favourite_yn'] = 'Y'
      businessPostCandidate['status'] = 0
      candidate['BusinessPostCandidate'] = businessPostCandidate

      const result: any = await this.roleService.updateRoleCandidate({
        role_id: this.roleDetails.id,
        user_id: candidate.user_id,
        body: businessPostCandidate
      })
      if (result.success == true) {
        candidate['BusinessPostCandidate'] = result.data[0]
      }
    }
  }

  isStringifiedJson(str: string): boolean {
    try {
      JSON.parse(str)
      return true
    } catch (error) {
      return false
    }
  }

  trackByFn(index: number, item: any): any {
    return item.id
  }
}
