import { Component, OnInit, ViewChild } from '@angular/core'
import {
  CalendarOptions,
  DateSelectArg,
  EventClickArg,
  EventApi,
  FullCalendarComponent
} from '@fullcalendar/angular'
import { getDateWithoutTime } from './event-utils'
import { MatDialogRef, MatDialogConfig, MatDialog } from '@angular/material/dialog'
import { CalendarEventDialogComponent } from './calendar-event-dialog/calendar-event-dialog.component'
import { NullTemplateVisitor } from '@angular/compiler'
import { Subject } from 'rxjs'
import { TimeSlotsList } from './event-utils'
import { ModalOptions, BsModalService, BsModalRef } from 'ngx-bootstrap/modal'
import { getSessionValue, getArrayFromString } from 'src/app/common-utils'
import { NgxSpinnerService } from 'ngx-spinner'
import { NotificationsComponent } from 'src/app/dashboards/dashboard-components'
import * as moment from 'moment'
import { EventDetailComponent } from './event-detail/event-detail.component'
declare var $: any
import { ToastrService } from 'ngx-toastr'
import { environment } from 'src/environments/environment'
import { RoleService } from 'src/app/shared/services/role.service'
import { BusinessService } from 'src/app/shared/services/business.service'
import { CandidateService } from 'src/app/shared/services/candidate.service'
import { DateClickArg } from '@fullcalendar/interaction'
import { CandidatesNextRoundComponent } from 'src/app/dashboards/modals/candidates-next-round/candidates-next-round.component'

@Component({
  selector: 'app-schedule-calendar',
  templateUrl: './schedule-calendar.component.html',
  styleUrls: ['./schedule-calendar.component.scss']
})
export class ScheduleCalendarComponent implements OnInit {
  @ViewChild('calendar') calendarComponent: FullCalendarComponent

  viewAs: string
  timeSlotsList: any[] = []
  enableCloseButton: boolean = false
  showProfileView: boolean = false
  dateSlots: any[] = []
  roleDetails: any
  userInfo: any
  CandidateProfile: any
  businessProfile: any
  availability_slots: any
  dialogRef2: MatDialogRef<CalendarEventDialogComponent> = Object.create(NullTemplateVisitor)
  lastCloseResult = ''
  events: any[] = []
  calendarVisible: any = 'calendar'
  calendarApi: any
  displaySlots: any[] = []
  availabilityBreakUp: any[] = []
  fromDate: Date
  toDate: Date
  currentDate: Date = new Date()
  currentWeek = true
  currentEvents: EventApi[] = []
  bsModalRef: BsModalRef
  notificationCount: any
  eventData: any = []
  refresh: Subject<any> = new Subject()
  roleData: any
  type: any
  roundDetails: any
  businessPosts: any = []
  displayedColumns: string[] = [
    'round_no',
    'round_name',
    'startdate',
    'enddate',
    'interviewer_name'
  ]
  dataSource: any

  // calendarPlugins = [interactionPlugin];
  constructor(
    public dialog: MatDialog,
    private roleService: RoleService,
    private businessService: BusinessService,
    private candidateService: CandidateService,
    private options: ModalOptions,
    private bsModalService: BsModalService,
    private SpinnerService: NgxSpinnerService,
    private toastr: ToastrService
  ) {
    this.timeSlotsList = TimeSlotsList
  }

  calendarOptions: CalendarOptions = {
    headerToolbar: {
      left: 'prev today next',
      center: 'title',
      right: 'dayGridMonth dayGridWeek dayGridDay'
    },
    buttonText: {
      today: 'Today',
      month: 'Month',
      week: 'Week',
      day: 'Day',
      list: 'List'
    },
    themeSystem: 'standard',
    eventColor: '#2c3e50',
    events: this.events,
    initialView: 'dayGridMonth',
    initialEvents: this.events, // alternatively, use the `events` setting to fetch from a feed
    weekends: true,
    editable: true,
    selectable: true,
    selectMirror: true,
    dayMaxEvents: true,
    // select: this.handleDateSelect.bind(this),
    eventClick: this.handleEventClick.bind(this),
    eventsSet: this.handleEvents.bind(this)
    // dateClick: this.handleDateSelect.bind(this)
    // plugins:[dayGridPlugin,interactionPlugin]
  }

  config: MatDialogConfig = {
    disableClose: false,
    width: '100%',
    height: '',
    position: {
      top: '',
      bottom: '',
      left: '',
      right: ''
    },
    data: {
      action: '',
      event: []
    }
  }

  handleCalendarToggle() {
    this.calendarVisible = !this.calendarVisible
  }

  handleWeekendsToggle() {
    const { calendarOptions } = this
    calendarOptions.weekends = !calendarOptions.weekends
  }

  handleDateSelect(selectInfo: DateClickArg) {
    if (moment().isBefore(selectInfo.date, 'day') || moment().isSame(selectInfo.date)) {
      const dialogRef = this.dialog.open(CandidatesNextRoundComponent, {
        panelClass: 'event-details-dialog',
        width: '80vh',
        autoFocus: false,
        data: { roleId: this.roleDetails?.id }
      })
      dialogRef.afterClosed().subscribe(async (res) => {
        if (res) {
          this.roleDetails.assessment_rounds = res?.msg?.roundNo || 1
          this.calendarApi = selectInfo.view.calendar
          this.addEvent(selectInfo.date, selectInfo.date)
        }
      })
    }

    /* const title = prompt('Please enter a new title for your event');
    const calendarApi = selectInfo.view.calendar;

    calendarApi.unselect(); // clear date selection

    if (title) {
      calendarApi.addEvent({
        id: createEventId(),
        title,
        start: selectInfo.startStr,
        end: selectInfo.endStr,
        allDay: selectInfo.allDay
      });
    } */
  }

  /* handleEventClick(clickInfo: EventClickArg) {
    if (confirm(`Are you sure you want to delete the event '${clickInfo.event.title}'`)) {
      clickInfo.event.remove();
    }
  } */

  handleEvents(events: EventApi[]) {
    this.currentEvents = events
  }
  async ngOnInit(): Promise<void> {
    if (environment.calendarMonthRestriction) {
      this.calendarOptions['validRange'] = {
        start: moment().subtract(1, 'month').format('YYYY-MM-DD'),
        end: moment().add(3, 'month').format('YYYY-MM-DD')
      }
    }
    if (this.options.initialState?.type) {
      this.calendarOptions.dateClick = this.handleDateSelect.bind(this)
    }
    this.userInfo = sessionStorage.getItem('USER_INFO')
    if (this.userInfo) {
      this.userInfo = JSON.parse(this.userInfo)
    } else {
      this.userInfo = null
    }
    if (this.userInfo?.user_type == '1') {
      this.showProfileView = true
      this.CandidateProfile = sessionStorage.getItem('CANDIDATE_PROFILE')
      if (this.CandidateProfile) {
        this.CandidateProfile = JSON.parse(this.CandidateProfile)
      }
    }
    this.userInfo = sessionStorage.getItem('USER_INFO')
    if (this.userInfo) {
      this.userInfo = JSON.parse(this.userInfo)
    } else {
      this.userInfo = null
    }
    if (this.userInfo?.user_type == '1') {
      this.showProfileView = true
      this.CandidateProfile = sessionStorage.getItem('CANDIDATE_PROFILE')
      if (this.CandidateProfile) {
        this.CandidateProfile = JSON.parse(this.CandidateProfile)
      }
    }
    if (this.userInfo?.user_type == '2') {
      this.businessProfile = getSessionValue('BUSINESS_PROFILE')
      let businessPostsRes = await this.roleService
        .getRolesByBusinessId(this.businessProfile?.id)
        .toPromise()
      this.businessPosts = businessPostsRes.data
      this.businessPosts = this.businessPosts.filter((value) => {
        return value.status == '1'
      })
    }
    $('.modal-content').on('contextmenu', function () {
      // Display the message
      return false
    })

    /* this.notificationCount = sessionStorage.getItem('notification_count');
    this.subscription = this.CommonService.currentMessage.subscribe((message: any) => {
      this.notificationCount = sessionStorage.getItem('notification_count');
    }) */
    this.eventData = []
    if (typeof this.options.initialState !== 'undefined') {
      this.roleDetails = this.options.initialState.data
    }
    await this.loadCalendarEvents()
    // await this.loadTiles();
  }

  async loadTiles() {
    // Can't see what this function does, may delete
    window.dispatchEvent(new Event('resize'))
    this.currentDate = getDateWithoutTime(new Date())
    this.timeSlotsList = TimeSlotsList
    await this.loadEvents()
    // setTimeout(async ()=> {await this.loadTiles()}, 5000);
  }

  //This is called by loadTiles
  //This function also fetches the events for the calendar like loadCalendarEvents
  //However it doesn't affect the visability of the calendar at all
  //May delete along with the 2 load functions called in this function
  async loadEvents() {
    if (this.userInfo?.user_type == '1') {
      this.viewAs = 'Candidate'
      let data = await this.candidateService.getByUserId(this.userInfo.id).toPromise()
      this.CandidateProfile = data.data
      await this.loadCandidateSchedule()
    } else if (this.userInfo?.user_type == '2') {
      this.businessProfile = getSessionValue('BUSINESS_PROFILE')
      this.viewAs = 'Business'
      this.enableCloseButton = true
      if (typeof this.options.initialState !== 'undefined') {
        this.roleDetails = this.options.initialState.data
        if (this.roleDetails?.id !== null) {
          this.viewAs = 'Role'
        }
        await this.loadRoleSchedule()
      } else {
        this.enableCloseButton = true
        await this.loadBusinessSchedule()
      }
    }
  }

  async loadCandidateSchedule() {
    this.eventData = []
    let events = await this.candidateService.getAllCandidateEvents(this.userInfo?.id).toPromise()
    events.data.items.forEach((event) => {
      event.event_id = parseInt(event.id)
      event.start = event.startDate
      event.end = event.endDate
      event.type = 'SCHEDULE'
      event.color = '#ec3b83'
      // event.startDate= event.startDate,
      // event.endDate= event.endDate,
      this.eventData.push(event)
    })
  }

  async loadBusinessSchedule() {
    return new Promise<void>(async (resolve) => {
      this.eventData = []
      // const calendarApi = this.calendarComponent.getApi();
      // const date: any = calendarApi.getDate().toISOString();
      let events = await this.businessService.getAllBusinessEvents().toPromise()
      if (events?.data.items.length > 0) {
        for (let [index, event] of events.data.items.entries()) {
          if (moment(event.startDate) >= moment()) {
            const candidateData: any = await this.getCandidateData(event.id)
            event.title = candidateData?.name
          }
          event.event_id = parseInt(event.id)
          event.start = event.startDate
          event.end = event.endDate
          event.type = 'SCHEDULE'
          event.color = '#ec3b83'
          this.eventData.push(event)
          if (index == events?.data.items.length - 1) {
            resolve()
          }
        }
      } else {
        resolve()
      }
    })
  }

  async loadRoleSchedule() {
    return new Promise<void>(async (resolve) => {
      this.eventData = []
      let events = await this.roleService.getEventsForRole(this.roleDetails?.id).toPromise()
      if (events?.data.items.length > 0) {
        for (let [index, event] of events.data.items.entries()) {
          // const candidateData = await this.candidateService
          //   .getById(event.candidate_id)
          //   .toPromise();
          event.title = event.candidate.name
          event.event_id = parseInt(event.id)
          event.start = event?.post_candidate?.slot_start_time
          event.end = event?.post_candidate?.slot_end_time
          event.type = 'SCHEDULE'
          event.color = '#ec3b83'
          this.eventData.push(event)
          if (index === events.data.items.length - 1) {
            resolve()
          }
        }
      } else {
        resolve()
      }
    })
  }

  async loadCalendarEvents() {
    return new Promise<void>(async (resolve) => {
      this.SpinnerService.show()
      this.events = []
      if (this.roleDetails?.id) {
        let availability_slots = await this.roleService
          .getEventsForRole(this.roleDetails?.id)
          .toPromise()
        this.availability_slots = availability_slots.data.items.filter((value) => {
          return value.status == '1'
        })
        this.SpinnerService.hide()
      } else if (this.businessProfile) {
        let availability_slots = await this.businessService.getAllBusinessEvents().toPromise()
        this.availability_slots = availability_slots.data.items.filter((value) => {
          return value.status == '1'
        })
        this.SpinnerService.hide()
      } else {
        if (this.userInfo?.user_type == '1') {
          let availability_slots = await this.candidateService
            .getCandidateBookedTimeslots()
            .toPromise()
          this.availability_slots = availability_slots.data.items.filter((value) => {
            return value.status == 1
          })
          this.SpinnerService.hide()
        }
      }

      this.availability_slots?.forEach((slot: any) => {
        const post = this.businessPosts.find((value) => {
          return value.id === slot?.post_id
        })
        if (this.userInfo?.user_type != '1') {
          // Business User
          const loadedDate = new Date(slot.slot_date)
          const date =
            loadedDate.getFullYear() +
            '-' +
            ('00' + (loadedDate.getMonth() + 1)).slice(-2) +
            '-' +
            ('00' + loadedDate.getDate()).slice(-2)
          let slotsArr: any[] = getArrayFromString(slot.time_slots)
          slotsArr.forEach((timeSlot) => {
            const timeRange: any[] = timeSlot.split(' - ')
            const startTime = timeRange[0].trim()
            const selectedStartTime = this.timeSlotsList.find((val) => val.value === startTime)
            const endTime = timeRange[1].trim()
            const startDateTime = this.getCalendarDate(slot.slot_date, startTime)
            const endDateTime = this.getCalendarDate(slot.slot_date, endTime)
            const selectedEndTime = this.timeSlotsList.find((val) => val.value === endTime)
            this.events.push({
              id: slot.id,
              slot_id: slot?.id,
              post_id: slot.post_id,
              title: this.roleDetails
                ? selectedStartTime?.viewValue + ' - ' + selectedEndTime?.viewValue
                : post?.title
                  ? post?.title
                  : selectedStartTime?.viewValue + ' - ' + selectedEndTime?.viewValue,
              start: date,
              end: date,
              startDate: startDateTime.toUTCString(),
              endDate: endDateTime.toUTCString(),
              selectedStartTime: selectedStartTime?.value,
              selectedEndTime: selectedEndTime?.value,
              type: 'SLOT'
            })
          })
          slot.slot_date_str = date
        } else {
          // Candidate User
          const date = slot.slot_start_time.slice(0, 10)

          const startTime = new Date(slot.slot_start_time)
            .toLocaleTimeString('en-AU', {
              hour: '2-digit',
              minute: '2-digit',
              hour12: true
            })
            .replace(' ', '')
          const endTime = new Date(slot.slot_end_time)
            .toLocaleTimeString('en-AU', {
              hour: '2-digit',
              minute: '2-digit',
              hour12: true
            })
            .replace(' ', '')
          const selectedStartTime = this.timeSlotsList.find((val) => val.viewValue === startTime)
          const selectedEndTime = this.timeSlotsList.find((val) => val.viewValue === endTime)

          this.events.push({
            id: slot.id,
            slot_id: slot?.id,
            post_id: slot.post_id,
            title: slot.post?.title ? slot.post.title : startTime + ' - ' + endTime,
            start: date,
            end: date,
            startDate: new Date(slot.slot_start_time).toUTCString(),
            endDate: new Date(slot.slot_end_time).toUTCString(),
            selectedStartTime: selectedStartTime.value,
            selectedEndTime: selectedEndTime.value,
            type: 'SLOT'
          })
        }
      })
      this.calendarOptions.events = this.events

      resolve()
    })
  }

  loadListView() {
    // this.events.forEach((slot: any) => {
    //   const daysOfYear = [];
    //   const loadedDate = new Date(slot.start);
    //   const date =
    //     loadedDate.getFullYear() +
    //     "-" +
    //     ("00" + (loadedDate.getMonth() + 1)).slice(-2) +
    //     "-" +
    //     ("00" + loadedDate.getDate()).slice(-2);
    //   const slots = this.getDaySlots(loadedDate);
    //   let index = this.availabilityBreakUp.findIndex(
    //     (val) => val.date === date
    //   );
    //   if (index > -1) {
    //     let newSlots = this.availabilityBreakUp[index];
    //     slots.concat(newSlots);
    //     this.availabilityBreakUp[index] = { date, slots };
    //   } else {
    //     this.availabilityBreakUp.push({ date, slots });
    //   }
    // });
    // this.currentWeekSlots();
  }

  changeCalendarView() {
    this.calendarOptions.events = this.eventData
    this.calendarOptions.initialEvents = this.eventData
    this.calendarOptions.events = this.events
  }

  getDaySlots(selectedDate: any) {
    let selectedDateSlots: any[] = []
    const date =
      selectedDate.getFullYear() +
      '-' +
      ('00' + (selectedDate.getMonth() + 1)).slice(-2) +
      '-' +
      ('00' + selectedDate.getDate()).slice(-2)
    const dateSlots: any[] = this.events.filter((x) => x.start === date)
    dateSlots.forEach((slots) => {
      const availableSlots: any[] = this.timeSlotsList.filter(
        (val) =>
          parseInt(val.value) >= parseInt(slots.selectedStartTime) &&
          parseInt(val.value) < parseInt(slots.selectedEndTime)
      )
      availableSlots.forEach((element) => {
        element.class = ''
      })
      selectedDateSlots = [...selectedDateSlots, ...availableSlots]
    })
    return selectedDateSlots
  }

  async handleEventClick(clickInfo: EventClickArg) {
    this.SpinnerService.show()
    let roleDetails
    if (!this.roleDetails) {
      let record = this.availability_slots?.find((val: any) => val.id == clickInfo.event.id)
      if (record) {
        roleDetails = record.post
      }
      /* else {
        if (clickInfo.event._def?.extendedProps?.type === "SCHEDULE") {
          // TODO: get post data
          const id = clickInfo.event._def?.extendedProps?.event_id;
          const businessPostCandidate = await this.roleService
            .getRoleCandidateProxyRelation(id)
            .toPromise();
          const data = await this.roleService
            .getRoleById(businessPostCandidate.data.post_id)
            .toPromise();
          roleDetails = data.data;
        }
      } */
    } else {
      roleDetails = this.roleDetails
    }
    // this.addEvent(record?.slot_date, record?.slot_date);
    /* this.config.data = { clickInfo };*/
    let businessData
    if (clickInfo.event._def?.extendedProps?.type === 'SCHEDULE') {
      if (this.viewAs === 'Candidate') {
        businessData = await this.getBusinessData(clickInfo.event._def?.extendedProps?.event_id)
      }
    }
    this.SpinnerService.hide()
    let dialogRef: any = this.dialog.open(EventDetailComponent, {
      panelClass: 'event-details-dialog',
      width: '80vh',
      data: {
        dateSlots: clickInfo.event._def,
        roleDetails: roleDetails ? roleDetails : null,
        // candidateData: candidateData ? candidateData : null,
        businessData: businessData ? businessData : null
      },
      autoFocus: false
    })
    dialogRef.afterClosed().subscribe(async (result: any) => {
      if (result.updatedEvent !== undefined && result.updatedEvent !== null) {
        this.save(result.updatedEvent, clickInfo.event._def.extendedProps?.slot_id)
      } else if (result === 'cancelEvent') {
        // const data = await this.CRUDService.getDataByField(
        //   "BusinessPostSlots",
        //   "id",
        //   clickInfo.event._def.publicId
        // ).toPromise();
        // data[0].status = 2;
        const result = await this.roleService
          .deleteEventById(clickInfo.event._def.publicId)
          .toPromise()
        if (result.success) {
          this.toastr.success('Event cacelled successfully')
          await this.loadCalendarEvents()
          await this.loadTiles()
        } else {
          this.toastr.warning('Something went wrong, please try again later')
        }
      } else {
        this.lastCloseResult = result
        dialogRef = Object.create(null)
        this.refresh.next()
      }
      const dialogRef1 = this.dialog.getDialogById('updateScheduleDialog')
      if (dialogRef1) {
        dialogRef1.afterClosed().subscribe(async (data) => {
          if (data === 'closed') {
            await this.loadCalendarEvents()
            await this.loadTiles()
          }
        })
      }
    })
  }

  async getCandidateData(id) {
    return new Promise(async (resolve) => {
      const businessPostCandidate = await this.roleService
        .getRoleCandidateProxyRelation(id)
        .toPromise()
      const candidateProfile = await this.candidateService
        .getByUserId(businessPostCandidate.data?.user_id)
        .toPromise()
      resolve(candidateProfile.data)
    })
  }

  async getBusinessData(id) {
    return new Promise(async (resolve) => {
      const businessPostCandidate = await this.roleService
        .getRoleCandidateProxyRelation(id)
        .toPromise()
      const businessPost = await this.roleService
        .getRoleById(businessPostCandidate.data.post_id)
        .toPromise()
      const businessProfile = await this.businessService
        .getBusiness(businessPost.data.business_id)
        .toPromise()
      resolve(businessProfile.data)
    })
  }

  addEvent(startDate: any, endDate: any) {
    this.dialogRef2 = this.dialog.open(CalendarEventDialogComponent, {
      panelClass: 'calendar-event-dialog',
      data: {
        dateSlots: this.events,
        post_id: this.roleDetails?.id,
        action: 'add',
        startDate,
        endDate
      },
      autoFocus: false
    })
    this.dialogRef2.afterClosed().subscribe(async (res) => {
      if (res) {
        if (res.changed) {
          this.save(res.events)
        } else if (JSON.stringify(res) === '{}') {
          await this.loadCalendarEvents()
          await this.loadTiles()
        }
      }
      const responseEvent = res.event
      // responseEvent.actions = this.actions;
      this.currentEvents.push(responseEvent)
      this.dialogRef2 = Object.create(null)
      this.refresh.next()
    })
  }
  dateSlotSelected(slot: any, date: any) {}

  async save(dates_slots: any[], id = null) {
    dates_slots.forEach(async (dateSlot, dateIndex) => {
      let businessPostSlot: any = {}
      let time_slots = ''
      dateSlot.timeslots.forEach((timeslot: any) => {
        if (time_slots === '') {
          time_slots = timeslot.selectedStartTime.value + ' - ' + timeslot.selectedEndTime.value
        } else {
          time_slots =
            time_slots +
            ', ' +
            timeslot.selectedStartTime.value +
            ' - ' +
            timeslot.selectedEndTime.value
        }
      })
      const slot = this.availability_slots.find((e) => e.id === id)
      // businessPostSlot = this.availability_slots?.find(
      //   (val: any) => val.slot_date_str === dateSlot.date
      // );
      // if (typeof businessPostSlot !== "undefined") {
      // } else {
      let postSlot: any = {}
      postSlot['id'] = slot?.id || null
      postSlot['post_id'] = this.roleDetails?.id
      postSlot['business_id'] = this.businessProfile?.id
      postSlot['title'] = this.roleDetails?.title
      if (this.viewAs == 'Candidate') {
        postSlot['candiadte_id'] = this.CandidateProfile.id
        postSlot['post_id'] = 1
        postSlot['business_id'] = null
      } else if (this.viewAs == 'Business') {
        postSlot['business_id'] = this.businessProfile.id
        postSlot['candidate_id'] = null
        postSlot['post_id'] = slot?.post_id || null
        postSlot['id'] = slot?.id || null
      }
      postSlot['round_no'] = this.roleDetails?.assessment_rounds
      postSlot['slot_date'] = dateSlot.date
      businessPostSlot = postSlot
      // }
      time_slots.split(',').forEach(async (slot, timeIndex) => {
        businessPostSlot.time_slots = slot
        await this.roleService.createEvent(businessPostSlot).toPromise()

        if (
          dateIndex === dates_slots.length - 1 &&
          timeIndex === time_slots.split(',').length - 1
        ) {
          await this.loadCalendarEvents()
          await this.loadTiles()
        }
      })
    })
    //this.calendarOptions.events = this.events;

    /* this.calendarApi.addEvent({
      id: createEventId(),
      title: timeslot.selectedStartTime.viewValue + ' - ' + timeslot.selectedEndTime.viewValue,
      start: date.date,
      end: date.date,
      startStr: startDateTime.toUTCString(),
      endStr: endDateTime.toUTCString(),
    });
 
    this.events.push({
      id: createEventId(),
      title: timeslot.selectedStartTime.viewValue + ' - ' + timeslot.selectedEndTime.viewValue,
      start: date.date,
      end: date.date,
      // allDay : false,
      startStr: startDateTime.toUTCString(),
      endStr: endDateTime.toUTCString(),
      selectedStartTime: timeslot.selectedStartTime.value,
      selectedEndTime: timeslot.selectedEndTime.value
    }); */
    /*     const { calendarOptions } = this; */
    //this.bsModalService.hide();
    this.toastr.success('Availability slots updated')
  }

  getCalendarDate(dateStr: any, time: string) {
    const inputDate = new Date(dateStr)
    const dateTime = new Date(
      inputDate.getFullYear(),
      inputDate.getMonth(),
      inputDate.getDate(),
      parseInt(time.substring(0, 2)),
      parseInt(time.substring(2, 4)),
      0
    )
    return dateTime
  }

  /* showList() {
    this.calendarVisible = !this.calendarVisible;
  }

  showCalendar() {
    this.calendarVisible = !this.calendarVisible;
  } */

  addSlot() {
    this.dateSlots.push({ selectedStartTime: '0830', selectedEndTime: '0900' })
  }

  removeSlot(slot: any) {
    this.dateSlots = this.dateSlots.filter((val) => val !== slot)
  }

  // filterDays() {
  //   this.displaySlots = this.availabilityBreakUp.filter(
  //     (val) =>
  //       getDateWithoutTime(new Date(val.date)) >= this.fromDate &&
  //       getDateWithoutTime(new Date(val.date)) <= this.toDate
  //   );
  //   this.currentDate = new Date();
  //   if (this.fromDate.toISOString() === this.currentDate.toISOString()) {
  //     this.currentWeek = true;
  //   } else {
  //     this.currentWeek = false;
  //   }
  // }

  // next() {
  //   this.fromDate = this.toDate;
  //   this.toDate = addDays(this.fromDate, 7);
  //   this.filterDays();
  // }

  // previous() {
  //   this.toDate = this.fromDate;
  //   this.fromDate = subDays(this.toDate, 7);
  //   this.filterDays();
  // }

  // currentWeekSlots() {
  //   this.fromDate = getDateWithoutTime(new Date());
  //   this.toDate = addDays(this.fromDate, 7);
  //   this.filterDays();
  // }

  editSlot(date: any) {
    this.addEvent(date, date)
  }

  closeModal() {
    this.bsModalService.hide()
  }
  showNotification(path: any) {
    localStorage.setItem('previous_path', path)
    this.bsModalRef = this.bsModalService.show(NotificationsComponent, {
      ariaLabelledBy: 'modal-basic-title',
      class: 'modal-lg',
      backdrop: 'static'
    })
    this.bsModalRef.content.event?.subscribe((res: any) => {})
    // this.router.navigate(['/dashboards/notifications'])
    // routerLink="/dashboards/notifications"
  }
}
