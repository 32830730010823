import { Component, EventEmitter, OnInit, Output } from '@angular/core'
import { MatDialog, MatDialogRef } from '@angular/material/dialog'
import { BsModalService } from 'ngx-bootstrap/modal'
declare var $: any

@Component({
  selector: 'app-delete-confirmation',
  templateUrl: './delete-confirmation.component.html',
  styleUrls: ['./delete-confirmation.component.scss']
})
export class DeleteConfirmationComponent implements OnInit {
  dialogRef: MatDialogRef<DeleteConfirmationComponent>

  constructor(
    private modalService: BsModalService,
    private dialog: MatDialog
  ) {}
  @Output() messageEvent = new EventEmitter<string>()

  ngOnInit(): void {
    $('.modal-content').on('contextmenu', function () {
      // Display the message
      return false
    })

    this.dialogRef = this.dialog.getDialogById('deleteDialog')
  }
  delete() {
    let msg = 'Delete'
    if (this.dialogRef) {
      this.dialogRef.close(msg)
    } else {
      this.messageEvent.emit(msg)
      this.modalService.hide()
    }
  }

  cancel() {
    let msg = 'Cancel'
    if (this.dialogRef) {
      this.dialogRef.close(msg)
    } else {
      this.messageEvent.emit(msg)
      this.modalService.hide()
    }
  }

  closeModal() {
    this.modalService.hide()
  }
}
