import { Injectable } from '@angular/core'
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http'
import { environment } from '../../../environments/environment'
import { catchError, finalize, map, tap } from 'rxjs/operators'
import { Router } from '@angular/router'
import { ToastrService } from 'ngx-toastr'

import { ApiResponse } from 'src/app/core/models/base'

@Injectable()
export class MeetingService {
  constructor(
    private http: HttpClient,
    private router: Router,
    private toastr: ToastrService
  ) {}

  scheduleTeamsMeeting(teamsMeetingBody: any) {
    return this.http
      .post<
        ApiResponse<{ id: string }>
      >(`${environment.apiUrl}/v1/meetings/microsoft-teams`, teamsMeetingBody)
      .pipe(
        map(
          (res) => {
            return res
          },
          (err) => {
            console.log('teams meeting create', err)
          }
        )
      )
  }

  cancelTeamsMeeting(meetingId: string) {
    return this.http
      .post<
        ApiResponse<{}>
      >(`${environment.apiUrl}/v1/meetings/microsoft-teams/${meetingId}/cancel`, {})
      .pipe(
        map(
          (res) => {
            return res
          },
          (err) => {
            console.log('teams meeting cancel', err)
          }
        )
      )
  }
}
