import { Component, OnInit } from '@angular/core'
import { environment } from '../../../../environments/environment'
import { cloneDeep } from 'lodash'

@Component({
  selector: 'app-business-setting',
  templateUrl: './business-setting.component.html',
  styleUrls: ['./business-setting.component.scss']
})
export class BusinessSettingComponent implements OnInit {
  previousPath: any
  showBody: any = false
  notificationIcon: boolean = false
  businessSettings = [
    { name: 'Business Account', link: '/dashboards/business-account' },
    { name: 'About Upplft', link: '/dashboards/about-upplft' },
    { name: 'Change Password', link: '/dashboards/change-password' },
    { name: 'Help', link: '/dashboards/help' },
    { name: "FAQ's", link: '/dashboards/faqs' },
    { name: 'Deactivate Account', link: '/dashboards/deactivate-account' }
  ]
  keysArray: string[] = ['name']
  clonedBusinessSettings

  constructor() {}

  ngOnInit(): void {
    this.previousPath = '/dashboards/business-settings'
    this.showBody = environment.menuShowHide
    this.notificationIcon = environment.notificationShow
    this.clonedBusinessSettings = cloneDeep(this.businessSettings)
  }

  eventFromChild(data) {
    this.businessSettings = data
  }
}
