import { Injectable } from '@angular/core'
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http'
import { environment } from '../../../environments/environment'
import { catchError, finalize, map, tap } from 'rxjs/operators'
import { Router } from '@angular/router'
import { LoginResponse, UserLogin } from '../../core/models/auth'
import { ToastrService } from 'ngx-toastr'
import { User, AllUsersResponse, GetUsersParams } from 'src/app/core/models/user'
import { ApiResponse } from 'src/app/core/models/base'

@Injectable()
export class UserService {
  constructor(
    private http: HttpClient,
    private router: Router,
    private toastr: ToastrService
  ) {}

  // TODo: implement types
  updateSelf(userUpdate: any) {
    return this.http.put<ApiResponse<User>>(`${environment.apiUrl}/v1/users/me`, userUpdate).pipe(
      map(
        (res) => {
          return res
        },
        (err) => {
          console.log('user update', err)
        }
      )
    )
  }

  // sync user data with auth0
  syncSelfWithAuth0() {
    return this.http
      .post<ApiResponse<{}>>(`${environment.apiUrl}/v1/users/me/sync-with-auth0`, {})
      .pipe(
        map(
          (res) => {
            return res
          },
          (err) => {
            console.log('user sync with auth0', err)
          }
        )
      )
  }

  // TODo: implement types
  getSelf() {
    return this.http.get<ApiResponse<User>>(`${environment.apiUrl}/v1/users/me`).pipe(
      map(
        (res) => {
          return res
        },
        (err) => {
          console.log('user get', err)
        }
      )
    )
  }
  // TODo: implement types
  getById(id: number) {
    return this.http.get<ApiResponse<User>>(`${environment.apiUrl}/v1/users/${id}`).pipe(
      map(
        (res) => {
          return res
        },
        (err) => {
          console.log('user get', err)
        }
      )
    )
  }

  addUser(user: User) {
    return this.http.post<ApiResponse<User>>(`${environment.apiUrl}/v1/users`, user).pipe(
      map(
        (res) => {
          return res
        },
        (err) => {
          console.log('user add', err)
        }
      )
    )
  }

  updateUser(user: User) {
    return this.http.put<ApiResponse<User>>(`${environment.apiUrl}/v1/users/${user.id}`, user).pipe(
      map(
        (res) => {
          return res
        },
        (err) => {
          console.log('user update', err)
        }
      )
    )
  }

  getUsers({
    limit = 50,
    offset = 0,
    order = 'asc',
    sort = 'created_at',
    q = '',
    status = [0, 1],
    user_type
  }: GetUsersParams) {
    return this.http
      .get<ApiResponse<AllUsersResponse>>(`${environment.apiUrl}/v1/users`, {
        params: new HttpParams()
          .set('limit', limit)
          .set('offset', offset)
          .set('order', order)
          .set('sort', sort)
          .set('q', q)
          .set('status', status.join(','))
          .set('user_type', user_type)
      })
      .pipe(
        map(
          (res) => {
            return res
          },
          (err) => {
            console.log('user get', err)
          }
        )
      )
  }
}
