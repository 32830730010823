import { Component, Input, OnInit } from '@angular/core'
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal'
import { CommonService } from 'src/app/common.service'
import { NotificationsComponent } from '../notifications/notifications.component'

@Component({
  selector: 'app-header-notification-button',
  templateUrl: './header-notification-button.component.html',
  styleUrls: ['./header-notification-button.component.scss']
})
export class HeaderNotificationButtonComponent implements OnInit {
  bsModalRef: BsModalRef
  notificationCount: any
  @Input() previousPath: any
  constructor(
    private modalService: BsModalService,
    private commonService: CommonService
  ) {
    // this.commonService.getUnreadCount();
  }

  ngOnInit(): void {
    this.notificationCount = sessionStorage.getItem('notification_count')
    this.commonService.currentMessage.subscribe((message: any) => {
      this.notificationCount = sessionStorage.getItem('notification_count')
    })
  }

  showNotification() {
    localStorage.setItem('previous_path', this.previousPath)
    this.bsModalRef = this.modalService.show(NotificationsComponent, {
      ariaLabelledBy: 'modal-basic-title',
      class: 'modal-lg',
      backdrop: 'static'
    })
    this.bsModalRef.content.event?.subscribe((res: any) => {})
  }
}
