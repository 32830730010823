import { Component, OnInit } from '@angular/core'
import { MatDialogRef } from '@angular/material/dialog'

@Component({
  selector: 'app-role-applied',
  templateUrl: './role-applied.component.html',
  styleUrls: ['./role-applied.component.scss']
})
export class RoleAppliedComponent implements OnInit {
  constructor(private dialogRef: MatDialogRef<RoleAppliedComponent>) {}

  ngOnInit(): void {}

  closeModal() {
    this.dialogRef.close()
    // this.router.navigate(['/dashboards/candidate']);
  }
}
