<div class="modal-content">
  <div class="modal-header">
    <h6 class="circularBold signUpHeading">Delete Confirmation</h6>
    <button
      type="button"
      class="close ml-2"
      aria-label="Close"
      data-dismiss="modal"
      (click)="closeModal()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">Are you sure that you want to Delete?</div>
  <div class="modal-footer">
    <button mat-raised-button class="confirm-button" (click)="delete()">Yes</button>

    <button mat-raised-button class="cancle-button" (click)="cancel()">No</button>
  </div>
</div>
