<div class="modal-header">
  <h6 class="circularBold signUpHeading">{{ title }}</h6>
  <button
    type="button"
    class="close ml-2"
    aria-label="Close"
    data-dismiss="modal"
    (click)="closeDialog()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="body" fxLayout="row wrap">
  <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
    <!-- <div class="row col-12 pl-0"> -->
    <div class="role-main-div">
      <div class="details-div mt-3">
        <label class="title-value">Scheduled Meeting Date</label>
        <mat-label class="example-full-width title">
          {{ eventInfo?.slot_start_time | date: 'dd/MM/yyyy' }}
        </mat-label>
      </div>
      <div class="details-div mt-3">
        <label class="title-value">Duration</label>
        <mat-label class="example-full-width title">
          {{ duration }}
        </mat-label>
      </div>
      <div class="details-div mt-3">
        <label class="title-value">Role</label>
        <mat-label class="example-full-width title">
          {{ roleInfo?.title }}
        </mat-label>
      </div>
      <div class="details-div mt-3">
        <label class="title-value">Description</label>
        <mat-label class="example-full-width title">
          {{ roleInfo?.description }}
        </mat-label>
      </div>
      <!-- <h4 class="m-t-0 p-l-5">Scheduled Meeting Date : {{eventInfo?.slot_start_time | date :
							'medium'}}
						</h4>
						<h4 class="m-t-0 p-l-5">Duration : {{duration}}</h4>
						<h4 class="m-t-0 p-l-5">Role: {{roleInfo?.title}}</h4>
						<h4 class="m-t-0 p-l-5">Description : {{roleInfo?.description}}</h4> -->
      <div class="details-div mt-3 text-center" style="display: flex; justify-content: center">
        <button (click)="confirm()" mat-raised-button class="book-button upload-btn">
          {{ linkText }}
        </button>
      </div>
    </div>
  </div>
</div>
