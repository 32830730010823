import { Component, EventEmitter, OnInit, Output, Inject } from '@angular/core'
import { Router } from '@angular/router'
import { AuthService } from '@auth0/auth0-angular'
import { BsModalService } from 'ngx-bootstrap/modal'
import { DOCUMENT } from '@angular/common'
declare var $: any
@Component({
  selector: 'app-logout-confirmation',
  templateUrl: './logout-confirmation.component.html',
  styleUrls: ['./logout-confirmation.component.scss']
})
export class LogoutConfirmationComponent implements OnInit {
  constructor(
    private modalService: BsModalService,
    private router: Router,
    private authService: AuthService,
    @Inject(DOCUMENT) private doc: Document
  ) {}
  @Output() messageEvent = new EventEmitter<boolean>()
  ngOnInit(): void {
    $('.modal-content').on('contextmenu', function () {
      // Display the message
      return false
    })
  }
  logout() {
    this.messageEvent.emit(true)
    this.modalService.hide()
    sessionStorage.clear()
    localStorage.clear()
    this.authService.logout({
      logoutParams: {
        returnTo: `${window.location.origin}/authentication/login-option`
      }
    })
    // this.router.navigate(["/home"]);
  }
  closeModal() {
    this.messageEvent.emit(false)
    this.modalService.hide()
  }
}
