import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core'
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal'
import { NgxSpinnerService } from 'ngx-spinner'
import { ToastrService } from 'ngx-toastr'
import { getSessionValue } from 'src/app/common-utils'
import { CommonService } from 'src/app/common.service'
import { CRUDService } from 'src/app/crud.service'
import { environment } from 'src/environments/environment'
import { RolesCandidateDataComponent } from '../../modals/roles-candidate-data/roles-candidate-data.component'
import { Subscription, Observable } from 'rxjs'
import { ValuesMapService } from 'src/app/shared/services/values-map.service'
import { BusinessService } from 'src/app/shared/services/business.service'
import { S3Service } from 'src/app/shared/services/s3.service'

@Component({
  selector: 'app-verified-business-profile',
  templateUrl: './verified-business-profile.component.html',
  styleUrls: ['./verified-business-profile.component.scss']
})
export class VerifiedBusinessProfileComponent implements OnInit, OnDestroy {
  @Input() isEditFlag: boolean = true
  roleValue: any
  roleArray: any = []
  contactPerson: any
  profileData: any
  editFlag: boolean = false
  @Input() businessProfileId: any
  @Input() events: Observable<any>
  technology: any = ''
  technologyArray: any = []
  industryArray: any = []
  locationArray: any = []
  industry: any
  location: any
  publicCompany: any
  businessProfile: any
  apiUrl: any
  profileImage: any = 'assets/images/business/business_logo.png'
  publicCompanyDesc: any = ''
  // businessProfileId: any;
  name: any
  userInfo: any
  roles_available = false
  peopleList: any[] = []
  roles_available_check = false
  bsModalRef: BsModalRef
  website: any
  websiteFlag: boolean = true
  linkedInFlag: boolean = true
  linkedinurl: any
  facebookUrl: any
  facebookFlag: boolean = true
  twitterUrl: any
  twitterFlag: boolean = true
  youTubeUrl: any
  youTubeFlag: boolean = true
  instagramUrl: any
  instagramFlag: boolean = true
  tiktokUrl: any
  contactNumber: any
  emailId: any
  profileLogo: string

  private eventSubscription: Subscription
  tiktokFlag: boolean = true
  locationData: any
  constructor(
    private CRUDService: CRUDService,
    private valuesMapService: ValuesMapService,
    private businessService: BusinessService,
    private s3Service: S3Service,
    private bsModalService: BsModalService,
    private toastr: ToastrService,
    private cdr: ChangeDetectorRef,
    private commonService: CommonService,
    private spinnerService: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.spinnerService.show()
    this.apiUrl = environment.apiUrl
    this.valuesMapService.getFilteredValuesMap(100, 0, '', 'ROLES').subscribe(({ data }) => {
      let names = data.items.map(function (item) {
        return item.description
      })
      this.roleArray = names
    })
    this.valuesMapService.getFilteredValuesMap(100, 0, '', 'SIZE').subscribe(({ data }) => {
      let names = data.items.map(function (item) {
        return item.description
      })
      this.technologyArray = names
    })

    this.valuesMapService.getFilteredValuesMap(100, 0, '', 'INDUSTRY').subscribe(({ data }) => {
      const sortedItems = data.items.sort((a, b) => a.display_order - b.display_order)
      let names = sortedItems.map(function (item) {
        return item.description
      })
      this.industryArray = names
    })
    this.valuesMapService.getFilteredValuesMap(100, 0, '', 'LOCATION').subscribe(({ data }) => {
      const sortedItems = data.items.sort((a, b) => a.display_order - b.display_order)
      const names = sortedItems.map(function (item) {
        return item.description
      })
      this.locationData = names
    })
    this.locationArray = ['Australia', 'New Zealand']
    this.userInfo = getSessionValue('USER_INFO')
    if (this.businessProfileId) {
      // this.businessProfileId = this.businessProfileId;
    } else {
      this.businessProfile = getSessionValue('BUSINESS_PROFILE')
      this.businessProfileId = this.businessProfile?.id
    }
    this.businessService.getBusiness(this.businessProfileId).subscribe(({ data }) => {
      this.profileData = data

      this.industry = this.profileData.industry
      this.location = this.profileData.location
      this.website = this.profileData.website
      this.linkedinurl = this.profileData.linkedin_url

      this.contactPerson = this.profileData.contact_person
      this.contactNumber = this.profileData.phone_number
      this.emailId = this.profileData.email

      if (this.profileData?.logo_url) {
        this.profileImage = this.profileData.logo_url
      }
      this.spinnerService.hide()
      this.technology = this.profileData.business_size
      this.publicCompany = this.profileData.trading_code === 'Yes' ? true : false
      this.publicCompanyDesc = this.profileData.trading_code
      this.name = this.profileData.name

      this.roles_available_check = this.profileData.roles_available === '1' ? true : false
      this.roles_available = this.businessProfile?.roles_available === '1' ? true : false

      this.peopleList.push(this.businessProfile?.leadership_1_fullname)
      this.peopleList.push(this.businessProfile?.leadership_2_fullname)
      this.peopleList.push(this.businessProfile?.leadership_3_fullname)
    })
    this.eventSubscription = this.events.subscribe(async () => {
      const data = await this.businessService.getBusiness(this.businessProfileId).toPromise()
      this.profileData = data.data
      this.submitData()
    })
  }
  ngOnChanges() {
    //this.technology = getSessionValue('USER_INFO').business_type;
    this.cdr.detectChanges()
  }
  editData() {
    this.editFlag = true
  }

  async submitData() {
    if (
      this.websiteFlag &&
      this.linkedInFlag &&
      this.facebookFlag &&
      this.twitterFlag &&
      this.youTubeFlag &&
      this.instagramFlag &&
      this.tiktokFlag
    ) {
      this.spinnerService.show()
      this.profileData.roles_available = this.roles_available === true ? '1' : '2'
      this.profileData.location = this.location
      this.profileData.industry = this.industry
      this.profileData.business_size = this.technology
      this.profileData.trading_code = this.publicCompanyDesc
      this.profileData.name = this.name

      this.profileData.website = this.website
      this.profileData.linkedin_url = this.linkedinurl
      this.profileData.facebook_url = this.facebookUrl
      this.profileData.twitter_url = this.twitterUrl
      this.profileData.youtube_url = this.youTubeUrl
      this.profileData.instagram_url = this.instagramUrl
      this.profileData.tiktok_url = this.tiktokUrl
      this.profileData.phone_number = this.contactNumber
      this.profileData.email = this.emailId
      this.profileData.contact_person = this.contactPerson
      this.profileData.status = undefined

      let response = await this.businessService.update(this.profileData).toPromise()
      if (response.success) {
        const userData = getSessionValue('USER_INFO')
        if (userData?.user_type == '2') {
          sessionStorage.removeItem('BUSINESS_PROFILE')
          sessionStorage.setItem('BUSINESS_PROFILE', JSON.stringify(this.profileData))
        }
        this.updateHubspotClient(this.profileData)
        this.toastr.success('Business Profile Updated')
        this.editFlag = !this.editFlag
        this.spinnerService.hide()
      } else {
      }
    } else {
      const validateAray: any = []
      if (!this.websiteFlag) {
        validateAray.push('Website')
      }
      if (!this.linkedInFlag) {
        validateAray.push('LinkedIn')
      }
      if (!this.facebookFlag) {
        validateAray.push('Facebook')
      }
      if (!this.twitterFlag) {
        validateAray.push('Twitter')
      }
      if (!this.youTubeFlag) {
        validateAray.push('YouTube')
      }
      if (!this.instagramFlag) {
        validateAray.push('Instagram')
      }
      if (!this.tiktokFlag) {
        validateAray.push('Tiktok')
      }
      // validateAray.push(!this.websiteFlag ? 'Website' ? !this.linkedInFlag ? 'LinkedIn' ? !this.twitterFlag ? 'Twitter' ? !this.youTubeFlag ? 'YouTube' : '')
      // if (!this.websiteFlag)
      this.toastr.error('Please Enter Valid ' + ' ' + validateAray.toString() + ' URL')
    }
  }
  async uploadImageProfile(event: any) {
    await this.spinnerService.show()
    if (
      event.addedFiles &&
      event.addedFiles[0] &&
      (event.addedFiles[0].type == 'image/jpeg' ||
        event.addedFiles[0].type == 'image/png' ||
        event.addedFiles[0].type == 'image/gif' ||
        event.addedFiles[0].type == 'image/webp')
    ) {
      await this.spinnerService.show()
      const file = event.addedFiles[0]
      if (file.size <= environment.imageDocSize) {
        await this.s3Service
          .uploadFile(file, `business/${this.businessProfileId}/${this.userInfo.id}`)
          .then(async (res) => {
            this.profileData.logo_url = res.Location
            const response = await this.businessService
              .update(this.profileData)
              .toPromise()
              .then(() => {
                sessionStorage.setItem('BUSINESS_PROFILE', JSON.stringify(this.profileData))
                this.toastr.success('Business Profile Updated')
              })
              .catch(() => {
                this.toastr.error('Error while updating business profile')
              })
              .finally(() => {
                this.spinnerService.hide()
              })
          })
          .finally(() => {
            this.spinnerService.hide()
          })
      } else {
        this.spinnerService.hide()
        this.toastr.warning(environment.fileErrorMessage)
      }
    } else {
      this.toastr.warning(environment.imageErrorMessage)
      this.spinnerService.hide()
    }
  }

  async changeRolesStatus(event: any) {
    // this.candidateProfile.open_to_work = event.checked ? "1" : "0";
    this.roles_available = event.checked
    if (event.checked) {
      this.businessProfile.roles_available = '1'
    } else {
      this.businessProfile.roles_available = '2'
    }
    /* let response = await this.commonService.updateRecord('BusinessProfile', this.businessProfile);
    if (response.success) {
      sessionStorage.setItem('BUSINESS_PROFILE', JSON.stringify(this.businessProfile))
      this._snackBar.open('Business Profile Updated', '', {
        horizontalPosition: 'end',
        verticalPosition: 'top',
        duration: 3000
      });
      // this._router.navigate(['/dashboards/candidate'])
    } */
  }
  openViewProfile(value: any) {
    const initialState: any = {
      status: value
    }
    this.bsModalRef = this.bsModalService.show(RolesCandidateDataComponent, {
      ariaLabelledBy: 'modal-basic-title',
      class: 'modal-height modal-xl candidate-data',
      backdrop: 'static',
      initialState
    })
  }
  setSelection(event: any) {
    this.publicCompany = event
    this.publicCompany = event
    if (event) {
      this.publicCompanyDesc = 'Yes'
      this.publicCompany = true
    } else {
      this.publicCompany = false
      this.publicCompanyDesc = 'No'
    }
  }
  isUrlValid() {
    if (this.website !== '' && this.website !== undefined && this.website !== null) {
      var res = this.website.match(
        /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
      )
      if (res == null) {
        this.websiteFlag = false
        return false
      } else {
        this.websiteFlag = true
        return true
      }
    } else {
      this.websiteFlag = true
    }
  }
  validLinkedInUrl() {
    if (this.linkedinurl !== '' && this.linkedinurl !== undefined && this.linkedinurl !== null) {
      // var res = this.linkedinurl.match(/https:\/\/www\.linkedin\.com\/[a-z]{1,5}\/[a-zA-Z0-9-]{5,30}/g);
      var res = this.linkedinurl.match(
        /(https?)?:?(\/\/)?(([w]{3}||\w\w)\.)?linkedin.com(\w+:{0,1}\w*@)?(\S+)(:([0-9])+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/
      )
      if (res == null) {
        this.linkedInFlag = false
        return false
      } else {
        this.linkedInFlag = true
        return true
      }
    } else {
      this.linkedInFlag = true
    }
  }
  validFacebookUrl() {
    if (this.facebookUrl !== '' && this.facebookUrl !== undefined && this.facebookUrl !== null) {
      var res = this.facebookUrl.match(
        /(?:https?:\/\/)?(?:www\.)?facebook\.com\/.(?:(?:\w)*#!\/)?(?:pages\/)?(?:[\w\-]*\/)*([\w\-\.]*)/
      )
      if (res == null) {
        this.facebookFlag = false
        return false
      } else {
        this.facebookFlag = true
        return true
      }
    } else {
      this.facebookFlag = true
    }
  }
  validTwitterUrl() {
    if (this.twitterUrl !== '' && this.twitterUrl !== undefined && this.twitterUrl !== null) {
      var res = this.twitterUrl.match(/http(?:s)?:\/\/(?:www\.)?twitter\.com\/([a-zA-Z0-9_]+)/)
      if (res == null) {
        this.twitterFlag = false
        return false
      } else {
        this.twitterFlag = true
        return true
      }
    } else {
      this.twitterFlag = true
    }
  }
  validYouTubeUrl() {
    if (this.youTubeUrl !== '' && this.youTubeUrl !== undefined && this.youTubeUrl !== null) {
      var res = this.youTubeUrl.match(
        /http(?:s?):\/\/(?:www\.)?youtu(?:be\.com\/watch\?v=|\.be\/)([\w\-\_]*)(&(amp;)?‌​[\w\?‌​=]*)?/
      )
      if (res == null) {
        this.youTubeFlag = false
        return false
      } else {
        this.youTubeFlag = true
        return true
      }
    } else {
      this.youTubeFlag = true
    }
  }
  validInstagramUrl() {
    if (this.instagramUrl !== '' && this.instagramUrl !== undefined && this.instagramUrl !== null) {
      var res = this.instagramUrl.match(/(https?)?:?(www)?instagram\.com\/[a-z]/)
      if (res == null) {
        this.instagramFlag = false
        return false
      } else {
        this.instagramFlag = true
        return true
      }
    } else {
      this.instagramFlag = true
    }
  }
  validTiktokUrl() {
    if (this.tiktokUrl !== '' && this.tiktokUrl !== undefined && this.tiktokUrl !== null) {
      var res = this.tiktokUrl.match(/(https?)?:?(www)?tiktok\.com\/[a-z]/)
      if (res == null) {
        this.tiktokFlag = false
        return false
      } else {
        this.tiktokFlag = true
        return true
      }
    } else {
      this.tiktokFlag = true
    }
  }
  redirectToUrl(url: any) {
    if (url !== '' && url !== undefined && url !== null) {
      window.open(url, '_blank')
    } else {
      this.toastr.warning('Url is not available')
    }
  }

  async updateHubspotClient(businessProfile) {
    if (businessProfile?.hubspotId) {
      const body = {
        hubspotId: businessProfile?.hubspotId,
        email: businessProfile.email,
        name: businessProfile.name,
        first_name: this.userInfo?.first_name,
        last_name: this.userInfo?.last_name,
        phone: businessProfile.phone_number
      }
      await this.CRUDService.updateClientHubspot(body).toPromise()
    } else {
      const body = {
        id: businessProfile?.id,
        email: businessProfile.email,
        name: businessProfile.name,
        first_name: this.userInfo?.first_name,
        last_name: this.userInfo?.last_name,
        phone: businessProfile.phone_number
      }
      const response: any = await this.CRUDService.createClientHubspot(body).toPromise()

      if (response.success === true) {
        const businessData: any = await this.CRUDService.getDataByField(
          'BusinessProfile',
          'id',
          businessProfile?.id
        ).toPromise()

        sessionStorage.setItem('BUSINESS_PROFILE', JSON.stringify(businessData[0]))
      }
    }
  }

  ngOnDestroy() {
    if (this.eventSubscription) {
      this.eventSubscription.unsubscribe()
    }
  }
}
