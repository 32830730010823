import { Component, Inject, OnInit } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'

@Component({
  selector: 'app-accept-connection',
  templateUrl: './accept-connection.component.html',
  styleUrls: ['./accept-connection.component.scss']
})
export class AcceptConnectionComponent implements OnInit {
  rejectConnection: boolean = false
  companyName: string
  rejectReason = [
    'I like my current job!',
    'I’m not interested in the company',
    'I not interested in the role',
    'It doesn’t align to my expectations (salary, benefits)',
    'It doesn’t align to my values or culture expectations'
  ]
  selectedReason: string
  constructor(
    private dialogRef: MatDialogRef<AcceptConnectionComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  ngOnInit(): void {
    this.companyName = this.data
  }

  closeModal() {
    this.dialogRef.close()
  }

  acceptConnection() {
    this.dialogRef.close({ status: 'accepted' })
  }

  reject() {
    this.dialogRef.close({ status: 'rejected', reason: this.selectedReason })
  }
}
