<div class="postsBox pt-4 pb-4 leftBox" style="width: 100%">
  <!-- <div class="mt-2" style="flex-wrap: nowrap; float: right; margin-right:1%; cursor: pointer">
         <img src="../../../../assets/images/chat-icon/Edit.svg" class="icon-css" *ngIf="!editFlag && showPencilIcon" (click)="editData()"
            style="cursor: pointer">
        <mat-icon *ngIf="editFlag" (click)="submitData();editFlag = !editFlag">
            check_circle_outline
        </mat-icon> 
        <button (click)="submitData()"
             mat-raised-button class="saved-btn"
             >Save<i class="fa fa-bookmark-o ml-2"
            aria-hidden="true"></i></button> 
             <button *ngIf="!editFlag && showPencilIcon" (click)="editData()"
             mat-raised-button class="saved-btn"
             >Edit<i class="fa fa-bookmark-o ml-2"
            aria-hidden="true"></i></button> 
    </div> -->
  <div class="d-flex" style="flex-wrap: nowrap">
    <div class="profile-div pl-2">
      <ngx-dropzone
        *ngIf="editFlag"
        class="dropzone"
        [multiple]="false"
        (change)="uploadImageProfile($event)"
        accept="image/jpeg, image/gif, image/png, image/webp"
      >
        <img class="profile-img" src="{{ profileImage }}" />
      </ngx-dropzone>
      <img *ngIf="!editFlag" class="profile-img" src="{{ profileImage }}" />
    </div>
    <!-- <div class="profile-div pl-2">
            <label for="profile-upload" class="custom-file-upload mx-auto">
                <img class="profile-img" src="{{profileImage}}" *ngIf="!editFlag" style="cursor: inherit"
                    onerror="this.onerror=null;this.src='../../../assets/images/background/login-register.jpg'" />
                <img class="profile-img" src="{{profileImage}}" *ngIf="editFlag" style="cursor: pointer"
                    onerror="this.onerror=null;this.src='../../../assets/images/background/login-register.jpg'" />
            </label>
            <input id="profile-upload" *ngIf="editFlag" type="file"
                accept="image/png, image/jpeg, image/webp, image/gif" (change)="uploadImageProfile($event);" />
        </div> -->
    <div class="pl-2" style="width: 100%">
      <div>
        <label class="profile-label" *ngIf="!editFlag">{{ profileData?.name }}</label>
        <div *ngIf="editFlag">
          <mat-form-field class="example-full-width" appearance="fill">
            <input type="text" matInput placeholder="Name" [(ngModel)]="name" />
          </mat-form-field>
        </div>
      </div>
      <div>
        <!-- <label class="linked-label" *ngIf="!editFlag">{{linkedin_url}}</label> -->
        <div *ngIf="editFlag" class="mt-2">
          <mat-form-field class="example-full-width" appearance="fill">
            <input type="text" matInput placeholder="Linkedin Url" [(ngModel)]="linkedin_url" />
          </mat-form-field>
        </div>
      </div>
      <!-- <label class="profile-label">{{profileData?.name}}</label> -->
      <!-- <button *ngIf="profileData.linkedin_url" mat-raised-button class="skip-btn mt-2"
                (click)="redirectToLinkedIn()">View Profile</button> -->
      <img
        (click)="redirectToLinkedIn()"
        *ngIf="linkedin_url && !editFlag"
        style="width: 25px; height: 25px; cursor: pointer"
        src="assets/images/upplft/linkedin_favicon.png"
      />
    </div>
  </div>
  <div class="mt-2">
    <mat-label class="roles-available">{{
      open_to_work_check
        ? 'Available for work'
        : 'Not available for
            work'
    }}</mat-label>
    <mat-slide-toggle
      [disabled]="!editFlag"
      [checked]="open_to_work_check"
      (change)="changeWorkStatus($event)"
    ></mat-slide-toggle>
  </div>
  <div class="mt-2" *ngIf="editFlag">
    <!-- {{lookingForValue}} -->
    <!-- <label class="looking-label">I'm looking for</label> -->
    <mat-form-field appearance="fill" class="mt-1">
      <mat-select
        #matSelect
        disableOptionCentering
        panelClass="myPanelClass"
        [(ngModel)]="lookingForValue"
        name="business"
        placeholder="I am looking for"
        class="custom-placeholder"
        multiple
      >
        <div (mouseleave)="matSelect.close()">
          <mat-option *ngFor="let data of lookingForArray" [value]="data">
            {{ data }}
          </mat-option>
        </div>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="details-div mt-3" *ngIf="!editFlag">
    <mat-chip-list aria-label="Culture Selection">
      <mat-chip *ngFor="let culture of lookingForValue; let i = index" class="chip-looking-for"
        >{{ culture }}
        <!-- <i class="fa fa-times pl-2" *ngIf="editFlag"></i> -->
      </mat-chip>
    </mat-chip-list>
  </div>
  <!-- <div class="mt-2" *ngIf="editFlag">
        <mat-form-field appearance="fill" class="mt-1">
            <mat-select #matSelect disableOptionCentering panelClass="myPanelClass" [(ngModel)]="rolesInterested"
                name="business" placeholder="Interested In" multiple>
                <div (mouseleave)="matSelect.close()">
                    <mat-option #matOption *ngFor="let data of roleArray" [value]="data"
                        (click)="changeData(data,matOption.selected)">
                        {{data}}
                    </mat-option>
                </div>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="details-div mt-3" *ngIf="!editFlag">
        <mat-chip-list aria-label="Culture Selection">
            <mat-chip *ngFor="let culture of rolesDisplay;let i=index" class="chip-looking-for">{{culture}}
                <i class="fa fa-times pl-2" *ngIf="editFlag"></i>
            </mat-chip>
        </mat-chip-list>
    </div> -->
  <div class="mt-2">
    <label class="looking-label"
      >Profile status -
      {{ profileData?.profile_completion ? profileData?.profile_completion : 0 }}%</label
    >
    <mat-progress-bar
      class="mt-1"
      [ngClass]="{
        'yellow-class': profileData?.profile_completion < 25,
        'blue-class': profileData?.profile_completion >= 25 && profileData?.profile_completion < 50,
        'orange-class':
          profileData?.profile_completion >= 50 && profileData?.profile_completion < 75,
        'pink-class':
          profileData?.profile_completion >= 75 && profileData?.profile_completion <= 100
      }"
      mode="determinate"
      [value]="profileData?.profile_completion"
    ></mat-progress-bar>
  </div>
  <div class="mt-3">
    <div
      class="d-flex justify-content-between"
      [ngClass]="editFlag ? 'align-items-center' : 'align-items-start'"
    >
      <!-- <div style="
            width: 100%;
            "> -->
      <label class="looking-label w-50">Pronoun</label>
      <!-- </div> -->
      <div class="w-50">
        <label class="looking-label looking-label-value" *ngIf="!editFlag">{{ gender }}</label>
        <div *ngIf="editFlag">
          <mat-form-field appearance="fill" class="select-mat">
            <mat-select
              #matSelect
              [(ngModel)]="gender"
              name="gender"
              placeholder="Select option"
              disableOptionCentering
              panelClass="myPanelClass2"
              (ngModelChange)="status = true"
            >
              <div (mouseleave)="matSelect.close()">
                <mat-option *ngFor="let gender of genderArray" [value]="gender">
                  {{ gender }}
                </mat-option>
              </div>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div
      class="d-flex justify-content-between"
      [ngClass]="editFlag ? 'align-items-center' : 'align-items-start'"
    >
      <label class="looking-label w-50">Education </label>
      <div class="w-50">
        <label class="looking-label looking-label-value" *ngIf="!editFlag">{{ education }}</label>
        <div *ngIf="editFlag">
          <mat-form-field appearance="fill">
            <mat-select
              #matSelect
              [(ngModel)]="education"
              name="education"
              placeholder="Select option"
              disableOptionCentering
              panelClass="myPanelClass2"
              (ngModelChange)="status = true"
            >
              <div (mouseleave)="matSelect.close()">
                <mat-option *ngFor="let education of educationData" [value]="education">
                  {{ education }}
                </mat-option>
              </div>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
    <!-- <div class="d-flex" style="justify-content: space-between;align-items: center;">
            <div><label class="looking-label">Status</label></div>
            <div>
                <label class="looking-label looking-label-value" *ngIf="!editFlag"> {{maritalStatus}}
                </label>
                <div *ngIf="editFlag">
                    <mat-form-field appearance="fill" class="select-mat">
                        <mat-select [(ngModel)]="maritalStatus" name="maritalStatus" placeholder="Select option">
                            <mat-option *ngFor="let maritalStatus of maritalStatusData" [value]="maritalStatus">
                                {{maritalStatus}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </div> -->
    <div
      class="d-flex justify-content-between"
      [ngClass]="editFlag ? 'align-items-center' : 'align-items-start'"
    >
      <label class="looking-label w-50">Interested In<em class="primaryColor">*</em></label>
      <!-- <mat-icon [ngStyle]="{'color':'red'}" *ngIf="roleIntSug"  matTooltipClass="mat-tooltip" [matTooltipPosition]="'right'"  matTooltip={{roleIntMsg}}>info</mat-icon> -->
      <div class="w-50">
        <label class="looking-label looking-label-value" *ngIf="!editFlag">{{
          rolesInterested
        }}</label>
        <div *ngIf="editFlag">
          <mat-form-field appearance="fill" class="select-mat">
            <mat-select
              #matSelect
              [(ngModel)]="rolesInterested"
              name="job"
              placeholder="Select option"
              panelClass="myPanelClass2"
              multiple
            >
              <div (mouseleave)="matSelect.close()">
                <mat-option *ngFor="let job of roleArray" [value]="job">
                  {{ job }}
                </mat-option>
              </div>
            </mat-select>
          </mat-form-field>
          <!-- <div *ngIf="roleIntSug" class="alert error-message">
                        {{roleIntMsg}}
                    </div> -->
        </div>
      </div>
    </div>
    <div
      class="d-flex justify-content-between"
      [ngClass]="editFlag ? 'align-items-center' : 'align-items-start'"
    >
      <label class="looking-label w-50">Location<em class="primaryColor">*</em></label>
      <!-- <mat-icon [ngStyle]="{'color':'red'}" *ngIf="locationSug"  matTooltipClass="mat-tooltip" [matTooltipPosition]="'right'"  matTooltip={{locationMsg}}>info</mat-icon> -->
      <div class="w-50">
        <label class="looking-label looking-label-value" *ngIf="!editFlag">{{ location }}</label>
        <div *ngIf="editFlag">
          <mat-form-field appearance="fill" class="select-mat">
            <mat-select
              #matSelect
              [(ngModel)]="location"
              name="location"
              placeholder="Select option"
              disableOptionCentering
              panelClass="myPanelClass2"
              (ngModelChange)="status = true"
            >
              <div (mouseleave)="matSelect.close()">
                <mat-option *ngFor="let location of locationData" [value]="location">
                  {{ location }}
                </mat-option>
              </div>
            </mat-select>
          </mat-form-field>
          <!-- <div  *ngIf="locationSug" class="alert error-message">
                        {{locationMsg}}
                    </div> -->
        </div>
      </div>
    </div>
    <div
      class="d-flex justify-content-between"
      [ngClass]="editFlag ? 'align-items-center' : 'align-items-start'"
    >
      <label class="looking-label w-50">English Proficiency</label>
      <div class="w-50">
        <label class="looking-label looking-label-value" *ngIf="!editFlag">{{
          englishProficiency
        }}</label>
        <div *ngIf="editFlag">
          <mat-form-field appearance="fill" class="select-mat">
            <mat-select
              #matSelect
              [(ngModel)]="englishProficiency"
              name="englishProficiency"
              placeholder="Select option"
              disableOptionCentering
              panelClass="myPanelClass2"
              (ngModelChange)="status = true"
            >
              <div (mouseleave)="matSelect.close()">
                <mat-option *ngFor="let proficiency of languageData" [value]="proficiency">
                  {{ proficiency }}
                </mat-option>
              </div>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
    <!-- <div class="d-flex" style="justify-content: space-between;align-items: center;">
            <div><label class="looking-label">Open to</label></div>
            <div>
                <label class="looking-label looking-label-value" *ngIf="!editFlag">{{work_type}}</label>
                <div *ngIf="editFlag">
                    <mat-form-field appearance="fill" class="select-mat">
                        <mat-select [(ngModel)]="work_type" name="salary" placeholder="Select option">
                            <mat-option *ngFor="let item of lookingForArray" [value]="item">
                                {{item}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </div> -->
    <div
      class="d-flex justify-content-between"
      [ngClass]="editFlag ? 'align-items-center' : 'align-items-start'"
    >
      <label class="looking-label w-50">Salary (base)<em class="primaryColor">*</em></label>
      <!-- <mat-icon *ngIf="salarySug" [ngStyle]="{'color':'red'}"  matTooltipClass="mat-tooltip" [matTooltipPosition]="'right'"  matTooltip={{salSugMsg}}>info</mat-icon> -->

      <div class="w-50">
        <label class="looking-label looking-label-value" *ngIf="!editFlag">{{ salary }}</label>
        <div *ngIf="editFlag">
          <mat-form-field appearance="fill" class="select-mat">
            <mat-select
              #matSelect
              [(ngModel)]="salary"
              name="salary"
              placeholder="Select option"
              disableOptionCentering
              panelClass="myPanelClass2"
              (ngModelChange)="status = true"
            >
              <div (mouseleave)="matSelect.close()">
                <mat-option *ngFor="let salary of salaryData" [value]="salary">
                  {{ salary }}
                </mat-option>
              </div>
            </mat-select>
          </mat-form-field>
          <!--    <div *ngIf="salarySug" class="alert error-message">
                        {{salSugMsg}}
                    </div> -->
        </div>
      </div>
    </div>
    <div
      class="d-flex justify-content-between"
      [ngClass]="editFlag ? 'align-items-center' : 'align-items-start'"
    >
      <label class="looking-label w-50">Day-rate (exc super)<em class="primaryColor">*</em></label>
      <!-- <mat-icon *ngIf="dayRateSug" [ngStyle]="{'color':'red'}"  matTooltipClass="mat-tooltip" [matTooltipPosition]="'right'"  matTooltip={{dayRateMsg}}>info</mat-icon> -->
      <div class="w-50">
        <label class="looking-label looking-label-value" *ngIf="!editFlag">{{ dayRate }}</label>
        <div *ngIf="editFlag" class="select-mat">
          <mat-form-field appearance="fill">
            <mat-select
              #matSelect
              [(ngModel)]="dayRate"
              name="dayRate"
              placeholder="Select option"
              disableOptionCentering
              panelClass="myPanelClass2"
              (ngModelChange)="status = true"
            >
              <div (mouseleave)="matSelect.close()">
                <mat-option *ngFor="let dayRate of dayRateExpData" [value]="dayRate">
                  {{ dayRate }}
                </mat-option>
              </div>
            </mat-select>
          </mat-form-field>
          <!-- <div *ngIf="dayRateSug" class="alert error-message">
                       {{dayRateMsg}}
                    </div> -->
        </div>
      </div>
    </div>
    <div
      class="d-flex justify-content-between"
      [ngClass]="editFlag ? 'align-items-center' : 'align-items-start'"
    >
      <label class="looking-label w-50">Other Expectations</label>
      <!-- <mat-icon *ngIf="dayRateSug" [ngStyle]="{'color':'red'}"  matTooltipClass="mat-tooltip" [matTooltipPosition]="'right'"  matTooltip={{dayRateMsg}}>info</mat-icon> -->
      <div class="w-50">
        <label class="looking-label looking-label-value" *ngIf="!editFlag">{{
          otherExpectations
        }}</label>
        <div *ngIf="editFlag" class="select-mat">
          <mat-form-field appearance="fill">
            <mat-select
              #matSelect
              [(ngModel)]="otherExpectations"
              name="otherExpectations"
              placeholder="Select option"
              panelClass="myPanelClass2"
              (ngModelChange)="status = true"
              multiple
            >
              <div (mouseleave)="matSelect.close()">
                <mat-option *ngFor="let expectation of otherExpData" [value]="expectation">
                  {{ expectation }}
                </mat-option>
              </div>
            </mat-select>
          </mat-form-field>
          <!-- <div *ngIf="dayRateSug" class="alert error-message">
                       {{dayRateMsg}}
                    </div> -->
        </div>
      </div>
    </div>
    <div
      class="d-flex justify-content-between"
      [ngClass]="editFlag ? 'align-items-center' : 'align-items-start'"
    >
      <label class="looking-label w-50">Residency Status<em class="primaryColor">*</em></label>
      <div class="w-50">
        <label class="looking-label looking-label-value" *ngIf="!editFlag">{{
          residencyStatus
        }}</label>
        <div *ngIf="editFlag" class="select-mat">
          <mat-form-field appearance="fill">
            <mat-select
              #matSelect
              [(ngModel)]="residencyStatus"
              name="residencyStatus"
              placeholder="Select option"
              disableOptionCentering
              panelClass="myPanelClass2"
            >
              <div (mouseleave)="matSelect.close()">
                <mat-option
                  *ngFor="let status of residencyData"
                  [value]="status"
                  (click)="getResStatus(status)"
                >
                  {{ status }}
                </mat-option>
              </div>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div
      class="d-flex justify-content-between"
      [ngClass]="editFlag ? 'align-items-center' : 'align-items-start'"
      *ngIf="showVisa"
    >
      <label class="looking-label w-50">Enter Visa Type </label>
      <div class="w-50">
        <div *ngIf="editFlag" class="select-mat">
          <mat-form-field class="example-full-width" appearance="fill">
            <input type="text" matInput placeholder="Enter Visa Type" [(ngModel)]="visaDetails" />
          </mat-form-field>
        </div>
      </div>
    </div>
    <div
      class="d-flex justify-content-between"
      [ngClass]="editFlag ? 'align-items-center' : 'align-items-start'"
    >
      <label class="looking-label w-50">Availability<em class="primaryColor">*</em></label>
      <div class="w-50">
        <label class="looking-label looking-label-value" *ngIf="!editFlag">{{
          availability
        }}</label>
        <div *ngIf="editFlag" class="select-mat">
          <mat-form-field appearance="fill">
            <mat-select
              #matSelect
              [(ngModel)]="availability"
              name="availability"
              placeholder="Select option"
              disableOptionCentering
              panelClass="myPanelClass2"
            >
              <div (mouseleave)="matSelect.close()">
                <mat-option *ngFor="let option of availabilityData" [value]="option">
                  {{ option }}
                </mat-option>
              </div>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="d-flex checkbox-label">
      <label class="looking-label w-50">Indigenous identity</label>
      <div class="w-50">
        <mat-checkbox
          *ngIf="!editFlag"
          class="looking-label looking-label-value"
          [(ngModel)]="identityValue"
          [disabled]="true"
          title="I identify as Aboriginal or Torres Strait Islander origin"
          (ngModelChange)="status = true"
        >
        </mat-checkbox>
        <div *ngIf="editFlag" class="select-mat">
          <section class="example-section" style="text-align: start">
            <mat-checkbox
              class="example-margin"
              [checked]="identityValue"
              (ngModelChange)="setSelection($event); status = true"
              [(ngModel)]="identityValue"
              >I identify as Aboriginal or Torres Strait Islander origin
            </mat-checkbox>
          </section>
        </div>
      </div>
    </div>
    <!-- <div class="d-flex" style="justify-content: space-between;align-items: center;">
            <div style="
            width: 100%;
            "><label class="looking-label">COVID Vaccinated</label></div>
            <div style="
            width: 100%;
            text-align: end;
           ">
                <mat-checkbox *ngIf="!editFlag" class="looking-label looking-label-value" [(ngModel)]="isVaccinated"
                    [disabled]="true" (ngModelChange)="status=true">
                </mat-checkbox>
                <div *ngIf="editFlag" class="select-mat">
                    <section class="example-section" style="text-align:start">
                        <mat-checkbox class="example-margin" [checked]="isVaccinated" [(ngModel)]="isVaccinated">
                        </mat-checkbox>
                    </section>
                </div>
            </div>
        </div> -->
    <div
      class="d-flex justify-content-between"
      [ngClass]="editFlag ? 'align-items-center' : 'align-items-start'"
    >
      <label class="looking-label w-50">Contact Preference</label>
      <!-- <mat-icon *ngIf="contactPreferSug" [ngStyle]="{'color':'red'}"  matTooltipClass="mat-tooltip" [matTooltipPosition]="'right'"  matTooltip={{contactMsg}}>info</mat-icon>-->
      <div class="w-50">
        <label class="looking-label looking-label-value" *ngIf="!editFlag">
          <mat-icon
            *ngIf="
              contact_preference?.includes('Phone') || contact_preference?.includes('I don\'t mind')
            "
            matTooltip="Phone"
          >
            phone</mat-icon
          >
          <mat-icon
            *ngIf="
              contact_preference?.includes('Email') || contact_preference?.includes('I don\'t mind')
            "
            matTooltip="Email"
          >
            mail</mat-icon
          >
          <mat-icon
            *ngIf="
              contact_preference?.includes('Text') || contact_preference?.includes('I don\'t mind')
            "
            matTooltip="Text"
          >
            sms</mat-icon
          >
          <!-- {{contact_preference}} -->
        </label>
        <div *ngIf="editFlag" class="select-mat">
          <mat-form-field appearance="fill">
            <mat-select
              #matSelect
              disableOptionCentering
              panelClass="myPanelClass3 myPanelClass2"
              [(ngModel)]="contact_preference"
              name="dayRate"
              placeholder="Select option"
              (ngModelChange)="status = true"
              multiple
              (selectionChange)="changedropValue()"
            >
              <div (mouseleave)="matSelect.close()">
                <mat-option
                  *ngFor="let dayRate of contactPreferenceData"
                  [value]="dayRate"
                  [disabled]="
                    contact_preference?.toString() === 'I don\'t mind' &&
                    dayRate !== 'I don\'t mind'
                  "
                >
                  {{ dayRate }}
                </mat-option>
              </div>
            </mat-select>
          </mat-form-field>
          <!-- <div *ngIf="contactPreferSug" class="alert error-message">
                        {{contactMsg}}
                     </div> -->
        </div>
      </div>
    </div>

    <div
      class="d-flex justify-content-between"
      [ngClass]="editFlag ? 'align-items-center' : 'align-items-start'"
    >
      <label class="looking-label w-50">Interested Business Size</label>
      <div class="w-50">
        <label class="looking-label looking-label-value" *ngIf="!editFlag">{{
          interestedBusiness
        }}</label>

        <div *ngIf="editFlag">
          <mat-form-field appearance="fill" class="select-mat">
            <mat-select
              #matSelect
              disableOptionCentering
              [(ngModel)]="interestedBusiness"
              name="business"
              placeholder="Select option"
              panelClass="myPanelClass2 myPanelClass3"
              multiple
            >
              <div (mouseleave)="matSelect.close()">
                <mat-option *ngFor="let business of businessData" [value]="business">
                  {{ business }}
                </mat-option>
              </div>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>

    <div
      class="d-flex justify-content-between"
      [ngClass]="editFlag ? 'align-items-center' : 'align-items-start'"
    >
      <label class="looking-label w-50">Interested Industry</label>
      <div class="w-50">
        <label class="looking-label looking-label-value" *ngIf="!editFlag">{{
          interestedIndustry
        }}</label>

        <div *ngIf="editFlag">
          <mat-form-field appearance="fill" class="select-mat">
            <mat-select
              #matSelect
              [(ngModel)]="interestedIndustry"
              name="industry"
              placeholder="Select option"
              panelClass="myPanelClass2 myPanelClass3"
              multiple
            >
              <div (mouseleave)="matSelect.close()">
                <mat-option *ngFor="let industry of industryData" [value]="industry">
                  {{ industry }}
                </mat-option>
              </div>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="mt-4 text-center">
        <button mat-raised-button class="contact-info-btn">Contact Information</button>
    </div> -->
  <!-- </div> -->
</div>
<div class="postsBox mt-3">
  <div class="mt-2">
    <div class="profile-header">
      <div class="heading col-12 p-0 justify-content-between">
        <label class="profile-heading-text font-18 mb-0">
          Upload your CV, Portfolio, Website, Personal Projects or Github URL
        </label>
      </div>
    </div>
    <div class="d-flex flex-column">
      <div class="d-flex pb-3 align-items-center">
        <mat-icon class="upload-doc-icons">description</mat-icon>
        <mat-form-field appearance="fill" class="upload-doc-form-field">
          <input
            matInput
            readonly
            [(ngModel)]="cvName"
            placeholder="CV Upload (PDF, Word, Docs)"
            [title]="cvName"
            (click)="editFlag ? cvUpload.click() : null"
          />
          <mat-icon
            *ngIf="cvName && cvUrl && editFlag"
            class="mr-1 cursor-pointer"
            (click)="removeCV(); cvUpload.value = null"
            >close</mat-icon
          >
        </mat-form-field>
        <label
          class="m-0 ml-3 cursor-pointer doc-for-label"
          (click)="
            editFlag
              ? cvName && cvUrl
                ? download(cvUrl)
                : cvUpload.click()
              : cvName && cvUrl
                ? download(cvUrl)
                : noDocToastr('CV')
          "
        >
          <img src="assets/profile-builder/Document.svg" />
        </label>
        <input
          #cvUpload
          id="cv-upload"
          type="file"
          accept=".doc, .docx, .pdf"
          (change)="uploadDocuments($event, 'CV')"
        />
      </div>
      <div class="d-flex pb-3 align-items-center">
        <mat-icon class="upload-doc-icons">photo_library</mat-icon>
        <mat-form-field appearance="fill" class="upload-doc-form-field">
          <input
            matInput
            readonly
            [(ngModel)]="pfName"
            placeholder="Portfolio Upload (PDF, Powerpoint)"
            [title]="pfName"
            (click)="editFlag ? pfUpload.click() : null"
          />
          <mat-icon
            *ngIf="pfName && pfUrl && editFlag"
            class="mr-1 cursor-pointer"
            (click)="removePortfolio(); pfUpload.value = null"
            >close</mat-icon
          >
        </mat-form-field>
        <label
          class="m-0 ml-3 cursor-pointer doc-for-label"
          (click)="
            editFlag
              ? pfName && pfUrl
                ? download(pfUrl)
                : pfUpload.click()
              : pfName && pfUrl
                ? download(pfUrl)
                : noDocToastr('portfolio')
          "
        >
          <img src="assets/profile-builder/Document.svg" />
        </label>
        <input
          #pfUpload
          id="pf-upload"
          type="file"
          accept=".pdf, .ppt, .pptx"
          (change)="uploadDocuments($event, 'PF')"
        />
      </div>
      <div *ngIf="editFlag" class="d-flex pb-3 align-items-center">
        <mat-icon class="upload-doc-icons"><i class="fa fa-github"></i></mat-icon>
        <mat-form-field appearance="fill" class="upload-doc-form-field">
          <input #githubUrl matInput placeholder="Paste Link Here" />
        </mat-form-field>
        <button
          mat-icon-button
          class="doc-add-button ml-3"
          [disabled]="!githubUrl.value"
          (click)="addGithubLink(githubUrl.value); githubUrl.value = null"
        >
          <mat-icon>add</mat-icon>
        </button>
      </div>
      <div
        [ngClass]="editFlag ? 'links-chip-list' : ''"
        class="d-flex pb-3 justify-content-start"
        *ngIf="githubLinks?.length"
      >
        <mat-icon *ngIf="!editFlag" class="upload-doc-icons" style="width: 35px !important"
          ><i class="fa fa-github"></i
        ></mat-icon>
        <mat-chip-list aria-label="Github Links" class="links-list">
          <span *ngFor="let link of githubLinks; let i = index">
            <mat-chip *ngIf="link">
              <span class="text-truncate" [title]="link">{{ link }}</span>
              <mat-icon *ngIf="editFlag" (click)="removeGithubLink(i)" class="cursor-pointer"
                >close</mat-icon
              >
            </mat-chip>
          </span>
        </mat-chip-list>
      </div>
      <div *ngIf="editFlag" class="d-flex pb-3 align-items-center">
        <mat-icon class="upload-doc-icons">language</mat-icon>
        <mat-form-field appearance="fill" class="upload-doc-form-field">
          <input #url matInput placeholder="Paste URL Here" />
        </mat-form-field>
        <button
          mat-icon-button
          class="doc-add-button ml-3"
          [disabled]="!url.value"
          (click)="addUrl(url.value); url.value = null"
        >
          <mat-icon>add</mat-icon>
        </button>
      </div>
      <div
        [ngClass]="editFlag ? 'links-chip-list' : ''"
        class="d-flex pb-3 justify-content-start"
        *ngIf="urls?.length"
      >
        <mat-icon *ngIf="!editFlag" class="upload-doc-icons" style="width: 35px !important"
          >language</mat-icon
        >
        <mat-chip-list aria-label="Github Links" class="links-list">
          <span *ngFor="let link of urls; let i = index">
            <mat-chip *ngIf="link">
              <span class="text-truncate" [title]="link">{{ link }}</span>
              <mat-icon *ngIf="editFlag" (click)="removeUrl(i)" class="cursor-pointer"
                >close</mat-icon
              >
            </mat-chip>
          </span>
        </mat-chip-list>
      </div>
    </div>
  </div>
</div>
<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
  <p style="color: white">Please Wait.</p>
</ngx-spinner>
