import { Component, Inject, OnInit, Optional } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog'
import { PersonalityNotificationComponent } from '../personality-notification/personality-notification.component'
import { CdkDragDrop } from '@angular/cdk/drag-drop'

@Component({
  selector: 'app-personality-rank',
  templateUrl: './personality-rank.component.html',
  styleUrls: ['./personality-rank.component.scss']
})
export class PersonalityRankComponent implements OnInit {
  personalityDetails = [
    {
      name: 'Sincere, honest, loyal, humble',
      color: '#2CB49E',
      background: '#2cb49e1a',
      border: '2px solid #2CB49E',
      type: 'personality',
      archetype_code: 'H',
      disabled: false
    },
    {
      name: 'Brave, independent, stable',
      border: '2px solid #FFDA53',
      background: '#ffda531a',
      color: '#87742F',
      type: 'personality',
      archetype_code: 'E',
      disabled: false
    },
    {
      name: 'Outgoing, lively, sociable',
      border: '2px solid #FB4F21',
      background: '#fb4f211a',
      color: '#FB4F21',
      type: 'personality',
      archetype_code: 'X',
      disabled: false
    },
    {
      name: 'Patient, tolerant, agreeable',
      border: '2px solid #EC3B83',
      background: '#ec3b831a',
      color: '#EC3B83',
      type: 'personality',
      archetype_code: 'A',
      disabled: false
    },
    {
      name: 'Organised, diligent, thorough',
      border: '2px solid #D29CFE',
      background: '#d29cfe1a',
      color: '#D29CFE',
      type: 'personality',
      archetype_code: 'C',
      disabled: false
    },
    {
      name: 'Creative, unconventional, innovative',
      border: '2px solid #3E60FF',
      background: '#3e60ff1a',
      color: '#3E60FF',
      type: 'personality',
      archetype_code: 'O',
      disabled: false
    }
  ]
  selectedPersonality: any[] = [
    {
      name: '1',
      color: '#6D7A92',
      background: '#6d7a921a',
      border: '2px dashed #6D7A92',
      type: 'rank'
    },
    {
      name: '2',
      color: '#6D7A92',
      background: '#6d7a921a',
      border: '2px dashed #6D7A92',
      type: 'rank'
    },
    {
      name: '3',
      color: '#6D7A92',
      background: '#6d7a921a',
      border: '2px dashed #6D7A92',
      type: 'rank'
    },
    {
      name: '4',
      color: '#6D7A92',
      background: '#6d7a921a',
      border: '2px dashed #6D7A92',
      type: 'rank'
    },
    {
      name: '5',
      color: '#6D7A92',
      background: '#6d7a921a',
      border: '2px dashed #6D7A92',
      type: 'rank'
    },
    {
      name: '6',
      color: '#6D7A92',
      background: '#6d7a921a',
      border: '2px dashed #6D7A92',
      type: 'rank'
    }
  ]
  option
  personalityProgress
  selectedPersonalityCount: number = 0

  constructor(
    private dialogRef: MatDialogRef<PersonalityRankComponent>,
    private dialog: MatDialog,
    @Optional() @Inject(MAT_DIALOG_DATA) private data
  ) {}

  ngOnInit(): void {
    if (this.data?.length) {
      this.loadSelectedPersonality()
    }
  }

  loadSelectedPersonality() {
    this.data.forEach((value) => {
      const index = this.personalityDetails.findIndex((ele) => {
        return ele?.archetype_code == value?.archetype_code
      })
      if (index !== -1) {
        this.selectedPersonality[value?.rank - 1] = this.personalityDetails[index]
      }
      this.personalityDetails[index].disabled = true
    })
    this.getPersonalityCount()
  }

  leavePersonalityBuilder() {
    const dialogRef = this.dialog.open(PersonalityNotificationComponent, {
      panelClass: 'personality-notification',
      width: '55vw',
      autoFocus: 'false',
      data: {
        header: 'Wait, are you sure you want to leave?',
        description1:
          'If you leave we won’t match personality for this role and your progress won’t be saved, but you can complete this at any time from the role description.',
        description2:
          'Are you sure you don’t have time to quickly complete this part of the role post?'
      }
    })

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'continue') {
        return
      } else if (result === 'exit') {
        this.dialogRef.close('close')
      }
    })
  }

  getPersonalityCount() {
    let count = 0
    this.selectedPersonality.forEach((value) => {
      if (value?.type === 'personality') {
        count += 1
      }
    })
    this.selectedPersonalityCount = count
    return count
  }

  closeDialog() {
    this.dialogRef.close()
  }

  exited(event: any) {
    if (event.item.dropContainer.id !== 'rankContainer') {
      const currentIdx = this.personalityDetails?.findIndex((f) => f.name === event.item.data.name)
      this.personalityDetails.splice(currentIdx + 1, 0, {
        ...event.item.data,
        temp: true
      })
    }
  }

  entered() {
    this.personalityDetails = this.personalityDetails.filter((f) => !f['temp'])
  }

  drop(event: CdkDragDrop<any>, index) {
    if (event.previousContainer.id === event.container.id) {
      const selectedPersonalityIndex = this.selectedPersonality.findIndex((value) => {
        return value.archetype_code === event.previousContainer.data.archetype_code
      })
      if (this.selectedPersonality[index].type !== 'personality') {
        const obj = {
          name: (selectedPersonalityIndex + 1).toString(),
          color: '#6D7A92',
          background: '#6d7a921a',
          border: '2px dashed #6D7A92',
          type: 'rank'
        }
        this.selectedPersonality.splice(selectedPersonalityIndex, 1, obj)
        this.selectedPersonality.splice(index, 1, event.previousContainer.data)
      } else {
        const arrCopy = [...this.selectedPersonality]
        this.selectedPersonality.splice(index, 1, arrCopy[selectedPersonalityIndex])
        this.selectedPersonality.splice(selectedPersonalityIndex, 1, arrCopy[index])
      }
    } else {
      const index = this.selectedPersonality.findIndex((value) => {
        return value.name === event.container.data.name
      })

      let alreadyPresentPersonality
      if (this.selectedPersonality[index]?.type === 'personality') {
        alreadyPresentPersonality = this.selectedPersonality[index]
      }

      if (
        !this.selectedPersonality.includes(this.personalityDetails[event.previousIndex]) &&
        index !== -1
      ) {
        this.selectedPersonality.splice(index, 1, this.personalityDetails[event.previousIndex])
        this.personalityDetails[event.previousIndex].disabled = true
        if (alreadyPresentPersonality) {
          const index = this.personalityDetails.findIndex(
            (e) => e.name === alreadyPresentPersonality.name
          )
          if (index !== -1) {
            this.personalityDetails[index].disabled = false
          }
        }
      }
      this.getPersonalityCount()
      if (event.previousContainer.data) {
        this.personalityDetails = this.personalityDetails.filter((f) => !f['temp'])
      }
    }
  }

  dropped(event) {
    if (event.previousContainer.id === 'rankContainer') {
      const index = this.selectedPersonality.findIndex((value) => {
        return value.archetype_code === event.item.data.archetype_code
      })
      const obj = {
        name: (index + 1).toString(),
        color: '#6D7A92',
        background: '#6d7a921a',
        border: '2px dashed #6D7A92',
        type: 'rank'
      }
      this.selectedPersonality.splice(index, 1, obj)
      const currentIndex = this.personalityDetails.findIndex((value) => {
        return value.archetype_code === event.item.data.archetype_code
      })
      this.personalityDetails[currentIndex].disabled = false
      this.getPersonalityCount()
    }
  }

  submitPersonalityRank() {
    const selectedPersonality = []
    this.selectedPersonality.forEach((value, index) => {
      if (value?.type === 'personality') {
        selectedPersonality.push({
          name: value?.name,
          rank: index + 1,
          archetype_code: value?.archetype_code
        })
      }
    })
    selectedPersonality.sort((a, b) => {
      return a?.rank - b?.rank
    })
    this.dialogRef.close(selectedPersonality)
  }
}
