import { Directive, HostListener } from '@angular/core'

@Directive({
  selector: '[appDateFormat]'
})
export class DateFormatDirective {
  constructor() {}

  @HostListener('input', ['$event'])
  onInput(event: Event): void {
    const input = event.target as HTMLInputElement
    let value = input.value

    const cursorPosition = input.selectionStart || 0

    // Handle the backspace key press
    if (event['inputType'] === 'deleteContentBackward') {
      return
    }

    // prevent from changing date if Date, Month or Year is edited
    if (cursorPosition === 3) {
      value = value.slice(0, 2) + value.slice(3)
    }
    if (cursorPosition === 6) {
      value = value.slice(0, 5) + value.slice(6)
    }
    if (cursorPosition === 11) {
      value = value.slice(0, 10) + value.slice(11)
    }

    // Add '/' after DD and MM
    if (value.length === 2 || value.length === 5) {
      value += '/'
    }
    input.value = value

    // Select Date if more than 2 character inputted in DD
    if (cursorPosition === 3) {
      input.setSelectionRange(0, 2)
    }
    // Select Month if more than 2 character inputted in MM
    if (cursorPosition === 6) {
      input.setSelectionRange(3, 5)
    }
    // Select Year if more than 4 character inputted in YYYY
    if (cursorPosition > 10) {
      input.setSelectionRange(6, input.value.length)
    }

    // Limit the input to 10 characters (DD/MM/YYYY)
    if (value.length > 10) {
      input.value = value.slice(0, 10)
    }
  }
}
