<div class="container ease-in">
  <div class="position-relative">
    <button mat-icon-button class="close-button" (click)="closeModal()">
      <i class="fa fa-times"></i>
    </button>
  </div>
  <div class="d-flex justify-content-between mb-2">
    <div class="d-flex flex-column">
      <div class="mb-2">
        <div class="mb-2">
          <label class="font-17"
            ><b>{{ header }}</b></label
          >
        </div>
        <ng-container *ngIf="personalityTestInfo">
          <div>
            <label class="font-17 mb-0"
              >Your Primary Archetype:
              <b>{{ personalityTestInfo?.description?.Name }}</b>
            </label>
          </div>
          <div>
            <label class="font-13 mb-0"
              ><b>Facets: </b>{{ personalityTestInfo?.description?.Facets }}</label
            >
          </div>
          <div *ngIf="!isMatchChart">
            <label class="font-13 mb-0">
              <b>Adjectives: </b>{{ personalityTestInfo?.description?.Adjectives }}
            </label>
          </div>
        </ng-container>
        <div *ngIf="!personalityTestInfo">
          <label class="font-14 mb-0">
            The personality questionnaire of role is not updated.
          </label>
        </div>
      </div>
      <div *ngIf="isMatchChart">
        <div>
          <label class="font-17 mb-0"
            >Candidate Primary Archetype:
            <b>{{ candidatePersonalityArchetype?.description?.Name }}</b>
          </label>
        </div>
        <div>
          <label class="font-13 mb-0"
            ><b>Facets: </b>{{ candidatePersonalityArchetype?.description?.Facets }}
          </label>
        </div>
      </div>
    </div>
    <div>
      <!-- <img class="personality-img" src="assets/images/upplft/personality-1.png"> -->
      <img
        *ngIf="personalityTestInfo?.code"
        class="personality-img"
        src="'../../../../assets/images/archetypes/{{ personalityTestInfo?.code }}.png"
      />
    </div>
  </div>
  <div class="d-flex details-div">
    <div class="d-flex buttons-div">
      <button
        *ngFor="let button of personalityButtons"
        mat-button
        class="personality-button"
        (click)="viewPersonalityDetails(button)"
      >
        {{ button.name }}
      </button>
      <!-- <button mat-button class="personality-button">Emotionality</button>
            <button mat-button class="personality-button">Openness</button>
            <button mat-button class="personality-button">Agreeableness</button>
            <button mat-button class="personality-button">Conscientiousness</button>
            <button mat-button class="personality-button">Extraversion</button> -->
    </div>
    <div class="d-flex justify-content-center chart-div">
      <!-- <div> -->
      <div id="chart">
        <apx-chart
          [series]="chartOptions.series"
          [chart]="chartOptions.chart"
          [xaxis]="chartOptions.xaxis"
          [yaxis]="chartOptions.yaxis"
          [stroke]="chartOptions.stroke"
          [markers]="chartOptions.markers"
          [fill]="chartOptions.fill"
          [plotOptions]="chartOptions.plotOptions"
          [responsive]="chartOptions.responsive"
          [legend]="chartOptions.legend"
        ></apx-chart>
      </div>
      <!-- </div> -->
    </div>
  </div>
</div>

<ng-template #personalityDetails>
  <div class="template-container ease-in">
    <div class="d-flex justify-content-end position-relative mb-3">
      <mat-dialog-actions>
        <button mat-icon-button class="template-close-button" (click)="closeTemplateModal()">
          <i class="fa fa-times"></i>
        </button>
      </mat-dialog-actions>
    </div>
    <div class="d-flex">
      <div>
        <div class="mb-2">
          <label
            ><b>{{ selectedPersonality?.description?.Name }}</b></label
          >
        </div>
        <div class="mb-2">
          <label><b>Facets: </b>{{ selectedPersonality?.description?.Facets }}</label>
        </div>
        <div class="mb-2">
          <label><b>Adjectives: </b>{{ selectedPersonality?.description?.Adjectives }}</label>
        </div>
      </div>
      <div>
        <!-- <img class="personality-img" src="assets/images/upplft/personality-1.png"> -->
        <img
          class="personality-img"
          src="'../../../../assets/images/archetypes/{{ selectedPersonality.code }}.png"
        />
      </div>
    </div>
  </div>
</ng-template>
