<div class="body ease-in" fxLayout="row wrap">
  <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
    <div class="row pr-0 pl-0">
      <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12">
        <div class="postsBox">
          <div class="row col-12 pr-0 text-center align-notification-icon">
            <div
              [ngClass]="
                notificationIcon
                  ? 'col-10 col-xs-10 col-sm-10 col-md-11 col-lg-11 pl-0 pr-0 search'
                  : 'col-xs-12 col-sm-12 col-md-12 col-lg-12 pl-0 pr-0 search'
              "
            >
              <app-header-search-bar></app-header-search-bar>
            </div>
            <div class="col-2 col-xs-2 col-sm-2 col-md-1 col-lg-1" *ngIf="notificationIcon">
              <app-header-notification-button [previousPath]="previousPath">
              </app-header-notification-button>
            </div>
          </div>
        </div>
        <hr />
        <div class="postsBox mt-3">
          <div class="role-main-div">
            <div class="role-heading" *ngIf="userInfo?.user_type != '4'">
              <div class="d-flex">
                <div>
                  <img
                    src="{{ businessProfile?.logo_url }}"
                    class="profile-img post-job-img"
                    onerror="this.onerror=null;this.src='assets/images/business/business_logo.png'"
                  />
                  <label class="ml-3 businessTitle">{{ businessProfile?.name }}</label>
                </div>
                <div class="d-flex ml-2 pt-3">
                  <span>
                    <button
                      mat-raised-button
                      class="edit-post-role-btn mb-1 mr-1"
                      [disabled]="!allowEditing"
                      (click)="openPostRoleModal()"
                      *ngIf="!editDataFlag"
                    >
                      <mat-icon>edit</mat-icon>
                    </button>
                  </span>
                  <span>
                    <button
                      mat-raised-button
                      class="edit-post-role-btn mb-1 mr-1"
                      *ngIf="!editDataFlag"
                    >
                      <mat-icon
                        class="copy-link"
                        [cdkCopyToClipboard]="roleDetails.role_link"
                        (cdkCopyToClipboardCopied)="copySuccess($event, roleDetails)"
                        [matTooltip]="roleDetails.isCopied ? 'Copied' : 'Copy'"
                        matTooltipPosition="above"
                      >
                        file_copy
                      </mat-icon>
                    </button>
                  </span>
                  <!-- <button mat-raised-button class="edit-post-role-btn mr-1" [disabled]="!allowEditing"
                                        (click)="saveRole()" *ngIf="editDataFlag">
                                        <mat-icon>check_circle_outline</mat-icon>
                                    </button> -->
                  <span class="dropdown mr-1 mb-1">
                    <button
                      mat-raised-button
                      class="dropdown-toggle edit-post-role-btn more-btn"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <mat-icon>more_vert</mat-icon>
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                      <a
                        *ngIf="roleDetails.status != '1'"
                        class="dropdown-item"
                        (click)="reOpenRole()"
                        >Re-Open Role</a
                      >
                      <a
                        *ngIf="roleDetails.status != '3'"
                        class="dropdown-item"
                        (click)="holdPosting()"
                        >Hold Role</a
                      >
                      <a
                        *ngIf="roleDetails.status != '4'"
                        class="dropdown-item"
                        (click)="closePosting()"
                        >Close Role</a
                      >
                      <a
                        *ngIf="roleDetails.status != '2'"
                        class="dropdown-item"
                        (click)="openDeleteModal(deleteModal, 'role')"
                        >Delete Role</a
                      >
                    </div>
                  </span>
                </div>
              </div>
              <div class="d-flex flex-column pt-3">
                <button
                  mat-raised-button
                  class="add-post-role-btn mb-2 mr-1"
                  [disabled]="!allowEditing"
                  (click)="viewCandidateModal()"
                >
                  View Candidates
                </button>
                <button
                  mat-raised-button
                  class="add-post-role-btn mb-2 mr-1"
                  [disabled]="!allowEditing"
                  (click)="viewCalendarModal()"
                >
                  Set Availabilty
                </button>
                <button
                  mat-raised-button
                  class="add-post-role-btn mb-2 mr-1"
                  [disabled]="!allowEditing"
                  (click)="viewRecruitmentModal()"
                >
                  Recruitment Process
                </button>
              </div>
            </div>
            <div class="d-flex justify-content-end" *ngIf="userInfo?.user_type == '4'">
              <span>
                <button
                  mat-raised-button
                  class="edit-post-role-btn mb-1 mr-1"
                  *ngIf="!editDataFlag"
                >
                  <mat-icon
                    class="copy-link"
                    [cdkCopyToClipboard]="roleDetails.role_link"
                    (cdkCopyToClipboardCopied)="copySuccess($event, roleDetails)"
                    [matTooltip]="roleDetails.isCopied ? 'Copied' : 'Copy'"
                    matTooltipPosition="above"
                  >
                    file_copy
                  </mat-icon>
                </button>
              </span>
            </div>
            <div
              [ngClass]="userInfo?.user_type != '4' ? 'role-details-div' : ''"
              [style.margin-top]="userInfo?.user_type == '4' ? '-35px' : ''"
            >
              <div class="details-div max-40">
                <label class="title">Role</label>
                <mat-label class="example-full-width title-value" *ngIf="!editDataFlag">
                  {{ roleDetails?.title }}
                </mat-label>
                <!-- <mat-form-field class="example-full-width" appearance="fill" *ngIf="editDataFlag">
                                    <input type="text" class="title-value" matInput placeholder="Role"
                                        [(ngModel)]="role">
                                </mat-form-field> -->
              </div>
              <div class="details-div max-40 mt-3">
                <label class="title">Status</label>
                <mat-label class="example-full-width title-value" *ngIf="!editDataFlag">
                  {{ roleStatusDesc }}
                </mat-label>
                <!-- <mat-label class="example-full-width title-value" *ngIf="editDataFlag">
                                    {{roleStatusDesc}}
                                </mat-label> -->
              </div>
              <div class="details-div mt-3">
                <label class="title">Role Type</label>
                <mat-label class="example-full-width title-value" *ngIf="!editDataFlag">
                  {{ roleDetails?.role_type }}
                </mat-label>
                <!-- <mat-form-field appearance="fill" class="example-full-width" *ngIf="editDataFlag">
                                    <mat-select [(ngModel)]="roleType" name="role" placeholder="Role Type" (selectionChange)="selectRoleType($event.value)">
                                        <mat-option *ngFor="let item of roleTypeArray" [value]="item">
                                            {{item}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field> -->

                <!-- <mat-form-field class="example-full-width" appearance="fill" *ngIf="otherRoleTypeFlag">
                                    <input type="text" matInput placeholder="Other Role Type" [(ngModel)]="roleType">
                                </mat-form-field> -->
              </div>
              <div class="details-div mt-3">
                <label class="title">Employment Type</label>
                <mat-label class="example-full-width title-value" *ngIf="!editDataFlag">
                  {{ changeString(roleDetails?.employment_type) }}
                </mat-label>
                <!-- <mat-form-field class="example-full-width" appearance="fill" *ngIf="editDataFlag">
                                    <mat-select [(ngModel)]="employmentType" name="role" placeholder="Employment Type" multiple>
                                        <mat-option *ngFor="let item of employmentTypeArray" [value]="item">
                                            {{item}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field> -->
              </div>
              <div class="details-div mt-3">
                <label class="title">Location</label>
                <mat-label class="example-full-width title-value" *ngIf="!editDataFlag">
                  {{ changeString(roleDetails?.location) }}
                </mat-label>
                <!-- <mat-form-field class="example-full-width" appearance="fill" *ngIf="editDataFlag">
                                    <input type="text" class="title-value" matInput placeholder="Location"
                                        [(ngModel)]="location">
                                </mat-form-field> -->
              </div>

              <div class="details-div mt-3">
                <label class="title">Start Date</label>
                <mat-label class="example-full-width title-value" *ngIf="!editDataFlag">
                  {{ roleDetails?.start_by }}
                </mat-label>
                <!-- <mat-form-field class="example-full-width" appearance="fill" *ngIf="editDataFlag">
                                    <mat-select [(ngModel)]="startDate" name="startDate" placeholder="Preferred Start Date">
                                    <mat-option *ngFor="let startDate of startDateArray" [value]="startDate">
                                        {{startDate}}
                                    </mat-option>
                                </mat-select>
                                </mat-form-field> -->
              </div>

              <div class="details-div mt-3">
                <label class="title">Remote Work</label>
                <mat-label class="example-full-width title-value" *ngIf="!editDataFlag">
                  {{ remoteWorkValue() }}
                </mat-label>

                <!-- <mat-checkbox (change)="remoteWork()" *ngIf="editDataFlag" [(ngModel)]="isChecked">
                                    Remote Work Available?
                                </mat-checkbox> -->
              </div>

              <div class="details-div mt-3">
                <label class="title">Role Description</label>
                <mat-label
                  class="example-full-width title-value"
                  *ngIf="!editDataFlag"
                  [innerText]="roleDetails?.description"
                >
                </mat-label>
                <!-- <mat-form-field class="example-full-width" appearance="fill" *ngIf="editDataFlag">
                                    <textarea cdkTextareaAutosize class="title-value" matInput placeholder="Description"
                                        [(ngModel)]="description"></textarea>
                                </mat-form-field> -->
              </div>
              <div class="details-div mt-3">
                <label class="title">Salary (base)</label>
                <mat-label class="example-full-width title-value" *ngIf="!editDataFlag">
                  {{ this.roleDetails?.salary }}
                </mat-label>

                <!-- <mat-slider *ngIf="editDataFlag" 
                                thumbLabel
                                [displayWith]="formatLabel"
                                tickInterval="10000"
                                step="10000"
                                min="500000"
                                max="2000000"
                                aria-label="units"
                                [(ngModel)]="salary">
                                </mat-slider> -->
                <!-- <mat-form-field class="example-full-width" appearance="fill" *ngIf="editDataFlag">
                                    <mat-select [(ngModel)]="salary" name="role" placeholder="Salary">
                                        <mat-option *ngFor="let salary of salaryArray" [value]="salary.code">
                                            {{salary.description}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field> -->
              </div>
              <!-- <div class="details-div mt-3">
                                <label class="title">Day Rate</label>
                                <mat-label class="example-full-width title-value" *ngIf="!editDataFlag">
                                    {{roleDetails?.day_rate}}
                                </mat-label>
                                <mat-form-field class="example-full-width" appearance="fill" *ngIf="editDataFlag">
                                    <input type="text" class="title-value" matInput placeholder="Day Rate"
                                        [(ngModel)]="day_rate">
                                </mat-form-field>
                            </div> -->
              <div class="details-div mt-3">
                <label class="title">Day-rate (exc super)</label>
                <mat-label class="example-full-width title-value" *ngIf="!editDataFlag">
                  {{ selectedDayRate }}
                </mat-label>
                <!-- <mat-form-field class="example-full-width" appearance="fill" *ngIf="editDataFlag">
                                    <mat-select [(ngModel)]="day_rate" name="role" placeholder="Day Rate">
                                        <mat-option *ngFor="let item of dayRateArray" [value]="item">
                                            {{item}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field> -->
              </div>
              <div class="details-div mt-3">
                <label class="title">Assessment Round</label>
                <mat-label class="example-full-width title-value" *ngIf="!editDataFlag">
                  {{ roleDetails?.assessment_rounds }}
                </mat-label>
                <!-- <mat-form-field class="example-full-width" appearance="fill" *ngIf="editDataFlag">
                                    <mat-select [(ngModel)]="assessment_rounds" name="role"
                                        placeholder="Assessment Round">
                                        <mat-option *ngFor="let item of assessmentRoundArray" [value]="item">
                                            {{item}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field> -->
              </div>
              <div class="details-div mt-3">
                <div>
                  <label class="title">Personality</label>
                </div>
                <div *ngIf="personalityTypeDetails" class="personality-container">
                  <div class="d-flex justify-content-between">
                    <div>
                      <div class="mb-2 personality-archetype">
                        <label
                          ><b>{{ personalityTypeDetails?.description?.Name }}</b></label
                        >
                      </div>
                      <div class="mb-2 personality-details">
                        <label
                          ><b>Facets: </b>{{ personalityTypeDetails?.description?.Facets }}</label
                        >
                      </div>
                      <div class="mb-2 personality-details">
                        <label
                          ><b>Adjectives: </b
                          >{{ personalityTypeDetails?.description?.Adjectives }}</label
                        >
                      </div>
                    </div>
                    <div>
                      <!-- <img class="personality-img" src="assets/images/upplft/personality-1.png"> -->
                      <img
                        class="personality-img"
                        src="assets/images/archetypes/{{ personalityTypeDetails.code }}.png"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="details-div mt-3">
                <div>
                  <label class="title">Culture</label>
                </div>
                <div *ngIf="cultureInfo" class="personality-container">
                  <div class="d-flex justify-content-between">
                    <div>
                      <div class="mb-2 personality-archetype">
                        <label
                          ><b>{{ cultureInfo?.name }}</b></label
                        >
                      </div>
                      <div class="mb-2 personality-details">
                        <label><b>Thrust: </b>{{ cultureInfo?.description?.thrusts }}</label>
                      </div>
                      <div class="mb-2 personality-details">
                        <label><b>Means: </b>{{ cultureInfo?.description?.means }}</label>
                      </div>
                      <div class="mb-2 personality-details">
                        <label><b>Ends: </b>{{ cultureInfo?.description?.ends }}</label>
                      </div>
                    </div>
                    <div>
                      <!-- <img class="personality-img" src="assets/images/upplft/personality-1.png"> -->
                      <img
                        class="personality-img"
                        src="assets/images/cultures/{{ cultureInfo.name }}.jpeg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="details-div mt-3">
                <label class="title">Benefits</label>
                <!-- <mat-form-field appearance="fill" *ngIf="editDataFlag">
                                    <mat-select [(ngModel)]="benefits" name="benefits" placeholder="Benefits" multiple>
                                        <mat-option *ngFor="let benefits of benefitsArray"
                                            [value]="benefits">
                                            {{benefits}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field> -->
                <mat-chip-list aria-label="Benefits Selection" class="mt-2">
                  <mat-chip *ngFor="let benefits of benefits">{{ benefits }}</mat-chip>
                </mat-chip-list>
              </div>

              <div class="details-div mt-3">
                <label class="title">Soft Skills</label>
                <!-- <mat-form-field appearance="fill" *ngIf="editDataFlag">
                                    <mat-select [(ngModel)]="softSkills" name="softSkills" placeholder="Soft Skills" multiple>
                                        <mat-option *ngFor="let softSkills of softSkillsArray"
                                            [value]="softSkills">
                                            {{softSkills}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field> -->
                <mat-chip-list aria-label="Soft Skills Selection" class="mt-2">
                  <mat-chip *ngFor="let softSkill of softSkills">{{ softSkill }}</mat-chip>
                </mat-chip-list>
              </div>

              <div class="details-div mt-3">
                <label class="title">Domain Experience</label>
                <!-- <mat-form-field appearance="fill" *ngIf="editDataFlag">
                                    <mat-select [(ngModel)]="domain" name="domain" placeholder="Domain">
                                        <mat-option *ngFor="let domain of domainArray"
                                            [value]="domain">
                                            {{domain}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field> -->
                <!-- <mat-form-field appearance="fill" *ngIf="editDataFlag" class="mt-1">
                                    <input type="text" class="title-value" matInput placeholder="Experience"
                                    [(ngModel)]="domainExp" (keydown.enter)="addDomainExp()">
                                </mat-form-field> -->
                <mat-chip-list aria-label="Soft Skills Selection" class="mt-2">
                  <mat-chip *ngFor="let exp of experience">{{ exp }}</mat-chip>
                </mat-chip-list>
              </div>

              <div class="details-div mt-3">
                <label class="title">Required Skills</label>
                <!-- <mat-form-field appearance="fill" *ngIf="editDataFlag">
                                    <mat-select [(ngModel)]="skills" name="skills" placeholder="Skills" multiple>
                                        <mat-option *ngFor="let skills of skillsArray" [value]="skills">
                                            {{skills}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field> -->
                <mat-chip-list aria-label="Skills Selection" class="mt-2">
                  <mat-chip *ngFor="let skill of skills">{{ skill }}</mat-chip>
                </mat-chip-list>
              </div>
              <div class="details-div mt-3" *ngFor="let attachment of postAttachmentArray">
                <label
                  for="file-upload1"
                  class="custom-file-upload"
                  (click)="showImageUpload(attachment)"
                >
                  <img
                    class="post-img"
                    *ngIf="attachment.file_type == 'image'"
                    src="{{ attachment.file_path }}"
                  />
                  <!-- <mat-icon *ngIf="editDataFlag && attachment.file_type == 'image'" class="mat-plus">
                                        add_circle
                                    </mat-icon> -->
                </label>
                <!-- <input id="file-upload1" type="file" *ngIf="editDataFlag"
                                    (change)="uploadImageProfile($event,'image','1');" accept="image/*" />
                                <mat-icon *ngIf="editDataFlag && attachment.file_type == 'image' "
                                    class="mat-plus mat-del"
                                    (click)="openDeleteModal1(deleteModal,'attachment',attachment)">
                                    remove_circle
                                </mat-icon> -->
                <label
                  for="file-upload2"
                  class="custom-file-upload"
                  (click)="showImageUpload(attachment)"
                >
                  <video
                    class="post-img mt-2"
                    *ngIf="attachment.file_type == 'video'"
                    src="{{ attachment.file_path }}"
                    controls
                  ></video>
                  <!-- <mat-icon *ngIf="editDataFlag && attachment.file_type == 'video' " class="mat-plus">
                                        add_circle
                                    </mat-icon> -->
                </label>
                <!-- <input id="file-upload2" type="file" *ngIf="editDataFlag"
                                    (change)="uploadImageProfile($event,'video','1');" accept="video/*" />
                                <mat-icon *ngIf="editDataFlag && attachment.file_type == 'video' "
                                    class="mat-plus mat-del"
                                    (click)="openDeleteModal1(deleteModal,'attachment',attachment)">
                                    remove_circle
                                </mat-icon> -->
                <label
                  for="file-upload3"
                  class="custom-file-upload"
                  (click)="showImageUpload(attachment)"
                >
                  <audio
                    controls
                    *ngIf="attachment.file_type == 'audio'"
                    src="{{ attachment.file_path }}"
                  ></audio>
                  <!-- <mat-icon *ngIf="editDataFlag && attachment.file_type == 'audio' " class="mat-plus">
                                        add_circle
                                    </mat-icon> -->
                </label>
                <!-- <input id="file-upload3" type="file" *ngIf="editDataFlag"
                                    (change)="uploadImageProfile($event,'audio','1');"
                                    accept="audio/mp3,audio/*;capture=microphone" />
                                <mat-icon *ngIf="editDataFlag && attachment.file_type == 'audio' "
                                    class="mat-plus mat-del"
                                    (click)="openDeleteModal1(deleteModal,'attachment',attachment)">
                                    remove_circle
                                </mat-icon> -->
                <label
                  for="file-upload4"
                  class="custom-file-upload"
                  (click)="showImageUpload(attachment)"
                >
                  <label
                    *ngIf="attachment.file_type == 'doc'"
                    (click)="downloadFile(attachment.file_path)"
                    class="file-css"
                    >{{ attachment.file_name }}</label
                  >
                  <!-- <mat-icon *ngIf="editDataFlag && attachment.file_type == 'doc' " class="mat-plus">
                                        add_circle
                                    </mat-icon> -->
                </label>
                <!-- <input id="file-upload4" type="file" *ngIf="editDataFlag"
                                    (change)="uploadImageProfile($event,'doc','1');"
                                    accept=".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf" /> -->
                <!-- <label *ngIf="attachment.file_type == 'doc'" (click)="downloadFile(attachment.file_path)">{{attachment.file_name}}</label> -->
                <!-- </div> -->
                <!-- <mat-icon *ngIf="editDataFlag && attachment.file_type == 'doc' "
                                    class="mat-plus mat-del"
                                    (click)="openDeleteModal1(deleteModal,'attachment',attachment)">
                                    remove_circle
                                </mat-icon> -->
              </div>
              <!-- <div class="d-flex col-12 pl-0 pr-0 mt-5" *ngIf="editDataFlag">
                                <div class="col-sm-9 col-lg-9 col-md-9 pl-0 pr-1">
                                    <label for="image-upload" class="custom-file-upload">
                                        <img id="blah" src="../../../../assets/profile-builder/Image_2.svg">
                                    </label>
                                    <input id="image-upload" type="file" accept="image/*"
                                        (change)="uploadImageProfile($event,'image','2')" />

                                    <label for="video-upload" class="custom-file-upload">
                                        <img id="blah" src="../../../../assets/profile-builder/Play.svg" />
                                    </label>
                                    <input id="video-upload" type="file" accept="video/*"
                                        (change)="uploadImageProfile($event,'video','2')" />

                                    <label for="audio-upload" class="custom-file-upload">
                                        <img id="blah" src="../../../../assets/profile-builder/Voice_2.svg" />
                                    </label>
                                    <input id="audio-upload" type="file" accept="audio/mp3,audio/*;capture=microphone"
                                        (change)="uploadImageProfile($event,'audio','2')" />

                                    <label for="doc-upload" class="custom-file-upload">
                                        <img id="blah" src="../../../../assets/profile-builder/Document.svg"
                                            accept=".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf" />
                                    </label>
                                    <input id="doc-upload" type="file"
                                        (change)="uploadImageProfile($event,'doc','2')" />
                                </div>
                            </div> -->
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
        <app-profile-overview-verified
          *ngIf="userInfo?.user_type != '4'"
        ></app-profile-overview-verified>
        <app-partner-profile-overview-verified
          *ngIf="userInfo?.user_type == '4'"
        ></app-partner-profile-overview-verified>
        <app-role-actions *ngIf="userInfo?.user_type != '4'"></app-role-actions>
      </div>
    </div>
  </div>
</div>
<ng-template #deleteModal class="modal-content">
  <div class="modal-header">
    <h4 class="modal-title">Confirmation</h4>
    <button type="button" class="close" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">Are you sure want to delete {{ target }}?</div>
  <div class="modal-footer">
    <button mat-raised-button class="confirm-button" (click)="deleteRole()">Yes</button>
    <button mat-raised-button class="cancle-button" (click)="closeModal()">No</button>
  </div>
</ng-template>
<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
  <p style="color: white">Please Wait.</p>
</ngx-spinner>
