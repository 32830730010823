import { ChangeDetectorRef, Component, Inject, OnInit, Optional } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog'
import { CdkDragDrop } from '@angular/cdk/drag-drop'
import { PersonalityNotificationComponent } from '../dashboards/modals/personality-notification/personality-notification.component'

@Component({
  selector: 'app-culture-builder',
  templateUrl: './culture-builder.component.html',
  styleUrls: ['./culture-builder.component.scss']
})
export class CultureBuilderComponent implements OnInit {
  cultureDetails = [
    {
      name: 'Consistent, efficient, controlled, optimised',
      color: '#2CB49E',
      background: '#2cb49e1a',
      border: '2px solid #2CB49E',
      type: 'culture',
      cultureType: 'Hierarchy',
      disabled: false
    },
    {
      name: 'Creative, adaptable, agile, innovative',
      border: '2px solid #FB4F21',
      background: '#fb4f211a',
      color: '#FB4F21',
      type: 'culture',
      cultureType: 'Adhocracy',
      disabled: false
    },
    {
      name: 'Competitive, productive, ambitious, goal focused',
      border: '2px solid #EC3B83',
      background: '#ec3b831a',
      color: '#EC3B83',
      type: 'culture',
      cultureType: 'Market',
      disabled: false
    },
    {
      name: 'Collaborative, empowering, participatory, cohesive',
      border: '2px solid #3E60FF',
      background: '#3e60ff1a',
      color: '#3E60FF',
      type: 'culture',
      cultureType: 'Clan',
      disabled: false
    }
  ]
  selectedCulture: any[] = [
    {
      name: '1',
      color: '#6D7A92',
      background: '#6d7a921a',
      border: '2px dashed #6D7A92',
      type: 'rank'
    },
    {
      name: '2',
      color: '#6D7A92',
      background: '#6d7a921a',
      border: '2px dashed #6D7A92',
      type: 'rank'
    },
    {
      name: '3',
      color: '#6D7A92',
      background: '#6d7a921a',
      border: '2px dashed #6D7A92',
      type: 'rank'
    },
    {
      name: '4',
      color: '#6D7A92',
      background: '#6d7a921a',
      border: '2px dashed #6D7A92',
      type: 'rank'
    }
  ]
  option
  selectedCultureCount: number = 0
  title

  constructor(
    private dialogRef: MatDialogRef<CultureBuilderComponent>,
    private dialog: MatDialog,
    @Optional() @Inject(MAT_DIALOG_DATA) private data,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.cdr.detectChanges()
    if (this.data.type === 'candidate') {
      this.title =
        'Thinking about the type of workplace where you will thrive, please rank each of the following workplace cultures in terms of most suitable for you to least suitable for you'
    } else {
      this.title =
        'Which of the following best describes the culture of the team in which this candidate will be working in? Please rank from most to least like (1 - 4)'
    }

    if (this.data?.selectedCultures && this.data?.selectedCultures?.length) {
      this.loadSelectedCulture()
    }
  }

  loadSelectedCulture() {
    this.data?.selectedCultures.forEach((value) => {
      const index = this.cultureDetails.findIndex((ele) => {
        return ele?.cultureType == value?.name
      })
      if (index !== -1) {
        this.selectedCulture[value?.rank - 1] = this.cultureDetails[index]
        this.cultureDetails[index].disabled = true
      }
    })
    this.getCultureCount()
  }

  leaveCultureBuilder() {
    const dialogRef = this.dialog.open(PersonalityNotificationComponent, {
      panelClass: 'personality-notification',
      width: '55vw',
      autoFocus: 'false',
      data: {
        header: 'Wait, are you sure you want to leave?',
        description1:
          "If you leave we won't match personality for this role and your progress won't be saved, but you can complete this at any time from the role description.",
        description2:
          "Are you sure you don't have time to quickly complete this part of the role post?"
      }
    })

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'continue') {
        return
      } else if (result === 'exit') {
        this.dialogRef.close()
      }
    })
  }

  getCultureCount() {
    let count = 0
    this.selectedCulture.forEach((value) => {
      if (value?.type === 'culture') {
        count += 1
      }
    })
    this.selectedCultureCount = count
    return count
  }

  closeDialog() {
    this.dialogRef.close()
  }

  exited(event: any) {
    if (event.item.dropContainer.id !== 'rankContainer') {
      const currentIdx = this.cultureDetails?.findIndex((f) => f.name === event.item.data.name)
      this.cultureDetails.splice(currentIdx + 1, 0, {
        ...event.item.data,
        temp: true
      })
    }
  }

  entered() {
    this.cultureDetails = this.cultureDetails.filter((f) => !f['temp'])
  }

  drop(event: CdkDragDrop<any>, index) {
    console.log(event)
    if (event.previousContainer.id === event.container.id) {
      const selectedCultureIndex = this.selectedCulture.findIndex((value) => {
        return value.cultureType === event.previousContainer.data.cultureType
      })
      if (this.selectedCulture[index].type !== 'culture') {
        const obj = {
          name: (selectedCultureIndex + 1).toString(),
          color: '#6D7A92',
          background: '#6d7a921a',
          border: '2px dashed #6D7A92',
          type: 'rank'
        }
        this.selectedCulture.splice(selectedCultureIndex, 1, obj)
        this.selectedCulture.splice(index, 1, event.previousContainer.data)
      } else {
        const arrCopy = [...this.selectedCulture]
        this.selectedCulture.splice(index, 1, arrCopy[selectedCultureIndex])
        this.selectedCulture.splice(selectedCultureIndex, 1, arrCopy[index])
      }
    } else {
      const index = this.selectedCulture.findIndex((value) => {
        return value.name === event.container.data.name
      })

      let alreadyPresentCulture
      if (this.selectedCulture[index]?.type === 'culture') {
        alreadyPresentCulture = this.selectedCulture[index]
      }

      if (
        !this.selectedCulture.includes(this.cultureDetails[event.previousIndex]) &&
        index !== -1
      ) {
        this.selectedCulture.splice(index, 1, this.cultureDetails[event.previousIndex])
        this.cultureDetails[event.previousIndex].disabled = true
        if (alreadyPresentCulture) {
          const index = this.cultureDetails.findIndex((e) => e.name === alreadyPresentCulture.name)
          if (index !== -1) {
            this.cultureDetails[index].disabled = false
          }
        }
      }
      this.getCultureCount()
      if (event.previousContainer.data) {
        this.cultureDetails = this.cultureDetails.filter((f) => !f['temp'])
      }
    }
  }

  dropped(event) {
    if (event.previousContainer.id === 'rankContainer') {
      const index = this.selectedCulture.findIndex((value) => {
        return value.cultureType === event.item.data.cultureType
      })
      const obj = {
        name: (index + 1).toString(),
        color: '#6D7A92',
        background: '#6d7a921a',
        border: '2px dashed #6D7A92',
        type: 'rank'
      }
      this.selectedCulture.splice(index, 1, obj)
      const currentIndex = this.cultureDetails.findIndex((value) => {
        return value.cultureType === event.item.data.cultureType
      })
      this.cultureDetails[currentIndex].disabled = false
      this.getCultureCount()
    }
  }

  submitCultureRank() {
    const selectedCulture = []
    this.selectedCulture.forEach((value, index) => {
      if (value?.type === 'culture') {
        selectedCulture.push({
          measures: value?.name,
          rank: index + 1,
          score: 4 - index,
          name: value?.cultureType
        })
      }
    })
    selectedCulture.sort((a, b) => {
      return a?.rank - b?.rank
    })
    this.dialogRef.close(selectedCulture)
  }
}
