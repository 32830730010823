<div class="body ease-in" fxLayout="row wrap" *ngIf="!loading">
  <form [formGroup]="profileForm" class="w-100">
    <div fxFlex.gt-lg="98" fxFlex.gt-md="98" fxFlex.gt-xs="98" fxFlex="98">
      <div class="row col-12 pl-0 d-flex">
        <div class="col-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 mb-2">
          <app-candidate-profile-detail
            [(candidateProfileId)]="candidateProfileId"
            [(showPencilIcon)]="showPencilIcon"
            [events]="eventSubject.asObservable()"
            (sendDataToParent)="eventFromChild($event)"
            (isAllFieldsFilled)="isAllFieldsFilled = $event"
          >
          </app-candidate-profile-detail>
        </div>
        <div class="col-8 col-lg-8 col-md-8 col-sm-12 col-xs-12">
          <div class="mt-3 sticky-top">
            <div class="d-flex justify-content-end">
              <button *ngIf="editAllFlag" mat-raised-button class="save-btn" (click)="submitData()">
                Save
                <i class="fa fa-bookmark-o ml-2" aria-hidden="true"></i>
              </button>
            </div>
          </div>

          <div class="postsBox mt-3" *ngIf="!isPersonalityTestCompleted">
            <div class="pl-3 pr-3 mt-2" *ngIf="editAllFlag">
              <div class="profile-header">
                <div class="heading col-12 p-0 justify-content-between align-items-start">
                  <div>
                    <label class="profile-heading-text font-18 mb-3">
                      Looks like you haven't taken our Personality Questionnaire!
                    </label>
                  </div>
                  <!-- <div>
                                        <button *ngIf="editAllFlag && !isPersonalityTestCompleted" mat-raised-button
                                            class="save-btn" (click)="submitData();">
                                            Save
                                            <i class="fa fa-bookmark-o ml-2" aria-hidden="true"></i>
                                        </button>
                                    </div> -->
                </div>
              </div>
              <div>
                <div>
                  <p class="description-text">
                    It takes less than 10 minutes and gives you an insight into your own personality
                    and helps you to be matched more accurately to roles!
                  </p>
                </div>
                <div class="ml-2 take-test-buttons">
                  <div class="d-flex flex-column align-items-center">
                    <button
                      mat-button
                      class="test-button"
                      (click)="takePersonalityTest('short-test')"
                    >
                      Take the short one!
                    </button>
                    <span class="description-text">3 minutes</span>
                  </div>
                  <div class="d-flex flex-column align-items-center">
                    <button
                      mat-button
                      class="test-button"
                      (click)="takePersonalityTest('long-test')"
                    >
                      Take the long one!
                    </button>
                    <span class="description-text">8 minutes</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="pl-3 pr-3 mt-2" *ngIf="!editAllFlag">
              <div class="profile-header">
                <div class="heading col-12 p-0 justify-content-between align-items-start">
                  <div>
                    <label class="profile-heading-text font-18 mb-3">
                      Candidate is yet to take personality test
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="postsBox mt-3 d-flex justify-content-between"
            *ngIf="isPersonalityTestCompleted"
          >
            <div class="pl-3 mt-2" class="personality-data">
              <div>
                <div class="heading col-12 p-0 mb-2">
                  <label class="personality-header"
                    >Your Archetype: <b>{{ personalityTestInfo?.description?.Name }}</b></label
                  >
                </div>
              </div>
              <div>
                <div class="heading col-12 p-0 mb-2">
                  <label class="personality-header"
                    ><b>Facets: </b>{{ personalityTestInfo?.description?.Facets }}</label
                  >
                </div>
              </div>
              <div>
                <div class="heading col-12 p-0 mb-2">
                  <label class="personality-header"
                    ><b>Adjectives: </b>{{ personalityTestInfo?.description?.Adjectives }}</label
                  >
                </div>
              </div>
              <div>
                <div class="heading col-12 p-0 mb-2">
                  <label class="personality-header"
                    >Click “My Score” below to read the other Archetypes.</label
                  >
                </div>
              </div>
              <div>
                <div class="col-12 p-0 mb-2 d-flex personality-buttons">
                  <button
                    mat-button
                    class="test-button w-275"
                    (click)="openPersonalityScoreModal()"
                  >
                    My Score
                  </button>
                  <button
                    *ngIf="editAllFlag"
                    mat-button
                    class="test-button w-275 mx-3"
                    (click)="retakeTest()"
                  >
                    Re-take
                  </button>
                </div>
              </div>
            </div>
            <div class="mt-2">
              <img
                class="personality-img"
                src="'../../../../assets/images/archetypes/{{
                  candidateProfile.personality_archetype
                }}.png"
              />
            </div>
            <!-- <div class="pr-3 mt-2">
                                <button *ngIf="editAllFlag" mat-raised-button class="save-btn"
                                    (click)="submitData();">Save<i class="fa fa-bookmark-o ml-2"
                                        aria-hidden="true"></i></button>
                            </div> -->
          </div>

          <div class="postsBox mt-3" *ngIf="!isCultureQuizCompleted">
            <div class="pl-3 pr-3 mt-2" *ngIf="editAllFlag">
              <div class="profile-header">
                <div class="heading col-12 p-0 justify-content-between align-items-start">
                  <div>
                    <label class="profile-heading-text font-18 mb-3">
                      Looks like you haven't taken our Culture Questionnaire!
                    </label>
                  </div>
                </div>
              </div>
              <div>
                <div>
                  <p class="description-text">
                    It takes less than 3 minutes and gives you an insight into the type of culture
                    where you'll thrive and helps you to be matched more accurately to roles!
                  </p>
                </div>
                <div class="ml-2 take-test-buttons">
                  <div class="d-flex flex-column align-items-center">
                    <button mat-button class="test-button" (click)="takeCultureQuiz()">
                      Take test
                    </button>
                    <span class="description-text">3 minutes</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="pl-3 pr-3 mt-2" *ngIf="!editAllFlag">
              <div class="profile-header">
                <div class="heading col-12 p-0 justify-content-between align-items-start">
                  <div>
                    <label class="profile-heading-text font-18 mb-3">
                      Candidate is yet to take culture quiz
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="postsBox mt-3 d-flex justify-content-between" *ngIf="isCultureQuizCompleted">
            <div class="pl-3 mt-2 personality-data">
              <div>
                <div class="heading col-12 p-0 mb-2">
                  <label class="personality-header">
                    Your Prefered Team Culture:
                    <b>{{ cultureQuizInfo?.name }}</b>
                  </label>
                </div>
              </div>
              <div>
                <div class="heading col-12 p-0 mb-2">
                  <label class="personality-header">
                    <b>Thrust: </b>{{ cultureQuizInfo?.description?.thrusts }}
                  </label>
                </div>
              </div>
              <div>
                <div class="heading col-12 p-0 mb-2">
                  <label class="personality-header">
                    <b>Means: </b> {{ cultureQuizInfo?.description?.means }}
                  </label>
                </div>
              </div>
              <div>
                <div class="heading col-12 p-0 mb-2">
                  <label class="personality-header">
                    <b>Ends: </b> {{ cultureQuizInfo?.description?.ends }}
                  </label>
                </div>
              </div>
              <!-- <div>
                                <div class="heading col-12 p-0 mb-2">
                                    <label class="personality-header">Click “My Score” below to read the other
                                        Archetypes.</label>
                                </div>
                            </div> -->
              <div>
                <div class="col-12 p-0 mb-2 d-flex personality-buttons">
                  <button mat-button class="test-button w-275" (click)="openCultureScoreModal()">
                    My Score
                  </button>
                  <button
                    *ngIf="editAllFlag"
                    mat-button
                    class="test-button w-275 mx-3"
                    (click)="takeCultureQuiz()"
                  >
                    Re-take
                  </button>
                </div>
              </div>
            </div>
            <div class="mt-2">
              <img
                class="personality-img"
                src="'../../../../assets/images/cultures/{{ cultureQuizInfo?.name }}.jpeg"
              />
            </div>
          </div>

          <div class="postsBox" *ngIf="viewSpiritAnimal">
            <div class="row profile-header font-18 mt-2">
              <div class="col-12" *ngIf="editAllFlag" style="z-index: 1">
                <label class="profile-heading-text mb-0">Spirit Animal</label>
                <!-- <button *ngIf="editAllFlag" mat-raised-button class="save-btn"
                                    (click)="submitData();">Save<i class="fa fa-bookmark-o ml-2"
                                        aria-hidden="true"></i></button> -->
              </div>
              <div class="col-12 text-center">
                <div class="text-center">
                  <div *ngIf="editAllFlag">
                    <div>
                      <mat-icon
                        class="left-arrow"
                        style="margin-right: 20px; color: #ec3b83; font-size: 40px"
                        (click)="changePersonlityType('previous')"
                      >
                        arrow_circle_left
                      </mat-icon>
                      <img
                        class="animal-img"
                        src="'../../../../assets/personality/{{
                          selectedPersonalityType.image_name
                        }}"
                      />
                      <mat-icon
                        class="right-arrow"
                        style="margin-left: 20px; color: #ec3b83; font-size: 40px"
                        (click)="changePersonlityType('next')"
                      >
                        arrow_circle_right
                      </mat-icon>
                    </div>
                    <div style="margin-top: -50px">
                      <label
                        class="description-text"
                        style="color: #ec3b83; font-size: 30px; font-family: 'MONTSERRAT'"
                        >{{ selectedPersonalityType.name }}</label
                      >
                    </div>
                    <div>
                      <label class="description-text">{{
                        selectedPersonalityType.description
                      }}</label>
                      <label class="description-text">{{
                        selectedPersonalityType.long_desc
                      }}</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row profile-header font-18 mt-2">
              <div class="col-12" *ngIf="!editAllFlag">
                <label class="profile-heading-text mb-0"
                  >Spirit Animal: {{ selectedPersonalityType.name }} -
                  {{ selectedPersonalityType.description }}</label
                >
                <button
                  mat-raised-button
                  class="saved-btn"
                  (click)="
                    editAllFlag = true;
                    editTechnicalSkill();
                    editSkill();
                    editValues();
                    editExperience();
                    editLanguages();
                    editFrustrations();
                    editPersonality()
                  "
                >
                  Edit<i class="fa fa-bookmark-o ml-2" aria-hidden="true"></i>
                </button>
              </div>
              <div style="margin-top: 50px" class="col-12" *ngIf="!editAllFlag"></div>
              <div class="col-12" style="display: flex" *ngIf="!editAllFlag">
                <label
                  class="description-text"
                  style="color: #0c2149; font-size: 14px; padding-top: 3px"
                >
                  {{ selectedPersonalityType.long_desc }}
                </label>
                <img
                  class="animal-img"
                  style="width: 130px; float: right; margin-top: -50px"
                  src="'../../../../assets/personality/{{ selectedPersonalityType.image_name }}"
                />
              </div>
            </div>
          </div>
          <!-- <div class="postsBox mt-3">
                        <div class="pl-3 pr-3 mt-2">
                            <div class="profile-header">
                                <div class="heading col-12 p-0 justify-content-between">
                                    <label class="profile-heading-text font-18 mb-0">
                                        Upload your CV, Portfolio, Website, Personal Projects or Github URL
                                    </label>
                                </div>
                            </div>
                            <div class="d-flex flex-column">
                                <div class="d-flex pb-3 align-items-center">
                                    <mat-icon class="upload-doc-icons">description</mat-icon>
                                    <mat-form-field appearance="fill" class="upload-doc-form-field">
                                        <input matInput readonly formControlName="cv_name"
                                            placeholder="CV Upload (PDF, Word, Docs)"
                                            [title]="profileForm.get('cv_name').value" (click)="cvUpload.click()" />
                                        <mat-icon *ngIf="profileForm.get('cv_name').value && cvUrl" class="mr-1 cursor-pointer"
                                            (click)="removeCV(); cvUpload.value = null">close</mat-icon>
                                    </mat-form-field>
                                    <label for="cv-upload" class="m-0 ml-3 cursor-pointer doc-for-label">
                                        <img src="assets/profile-builder/Document.svg" />
                                    </label>
                                    <input #cvUpload id="cv-upload" type="file" accept=".doc, .docx, .pdf"
                                        (change)="uploadImageProfile($event,'docUrl', 'CV')" />
                                </div>
                                <div class="d-flex pb-3 align-items-center">
                                    <mat-icon class="upload-doc-icons">photo_library</mat-icon>
                                    <mat-form-field appearance="fill" class="upload-doc-form-field">
                                        <input matInput readonly formControlName="portfolio_name"
                                            placeholder="Portfolio Upload (PDF, Powerpoint)"
                                            [title]="profileForm.get('portfolio_name').value"
                                            (click)="pfUpload.click()" />
                                        <mat-icon *ngIf="profileForm.get('portfolio_name').value && pfUrl"
                                            class="mr-1 cursor-pointer"
                                            (click)="removePortfolio(); pfUpload.value = null">close</mat-icon>
                                    </mat-form-field>
                                    <label for="pf-upload" class="m-0 ml-3 cursor-pointer doc-for-label">
                                        <img src="assets/profile-builder/Document.svg" />
                                    </label>
                                    <input #pfUpload id="pf-upload" type="file" accept=".pdf, .ppt, .pptx"
                                        (change)="uploadImageProfile($event,'docUrl', 'PF')" />
                                </div>
                                <div class="d-flex pb-3 align-items-center">
                                    <mat-icon class="upload-doc-icons"><i class="fa fa-github"></i></mat-icon>
                                    <mat-form-field appearance="fill" class="upload-doc-form-field">
                                        <input #githubUrl matInput placeholder="Paste Link Here" />
                                    </mat-form-field>
                                    <button mat-icon-button class="doc-add-button ml-3" [disabled]="!githubUrl.value"
                                        (click)="addGithubLink(githubUrl.value); githubUrl.value = null">
                                        <mat-icon>add</mat-icon>
                                    </button>
                                </div>
                                <div class="d-flex pb-3 justify-content-start links-chip-list"
                                    *ngIf="githubLinks?.length">
                                    <mat-chip-list aria-label="Github Links" class="links-list">
                                        <span *ngFor="let link of githubLinks; let i = index">
                                            <mat-chip *ngIf="link">
                                                <span class="text-truncate" [title]="link">{{ link }}</span>
                                                <mat-icon (click)="removeGithubLink(i)"
                                                    class="cursor-pointer">close</mat-icon>
                                            </mat-chip>
                                        </span>
                                    </mat-chip-list>
                                </div>
                                <div class="d-flex pb-3 align-items-center">
                                    <mat-icon class="upload-doc-icons">language</mat-icon>
                                    <mat-form-field appearance="fill" class="upload-doc-form-field">
                                        <input #url matInput placeholder="Paste URL Here" />
                                    </mat-form-field>
                                    <button mat-icon-button class="doc-add-button ml-3" [disabled]="!url.value"
                                        (click)="addUrl(url.value); url.value = null">
                                        <mat-icon>add</mat-icon>
                                    </button>
                                </div>
                                <div class="d-flex pb-3 justify-content-start links-chip-list" *ngIf="urls?.length">
                                    <mat-chip-list aria-label="Github Links" class="links-list">
                                        <span *ngFor="let link of urls; let i = index">
                                            <mat-chip *ngIf="link">
                                                <span class="text-truncate" [title]="link">{{ link }}</span>
                                                <mat-icon (click)="removeUrl(i)" class="cursor-pointer">close</mat-icon>
                                            </mat-chip>
                                        </span>
                                    </mat-chip-list>
                                </div>
                            </div>
                        </div>
                    </div> -->
          <div class="postsBox mt-3">
            <div class="pl-3 pr-3 mt-2">
              <div class="profile-header">
                <div class="heading col-12 p-0 justify-content-between">
                  <label class="profile-heading-text font-18 mb-0">Pitch Yourself</label>
                  <!--
                                    <img src="../../../../assets/images/chat-icon/Edit.svg" class="icon-css"
                                        *ngIf="!editBioFlag && showPencilIcon" (click)="editBio()">
                                        <mat-icon *ngIf="editBioFlag" (click)="submitData();editBioFlag = !editBioFlag">
                                        check_circle_outline
                                    </mat-icon>
                                    -->
                </div>
              </div>
              <div
                *ngIf="!seeMoreBioFalg"
                class="font-14 description-text word-break-css"
                color="#0C2149"
              >
                {{ !editAllFlag ? profileData?.bio?.slice(0, 150) : '' }}
                <span *ngIf="profileData !== undefined">
                  <b *ngIf="!editAllFlag && profileData?.bio && profileData?.bio?.length > 150"
                    >...</b
                  >
                </span>
                <a
                  *ngIf="profileData?.bio?.length > 150 && !editAllFlag"
                  class="see_more"
                  (click)="seeMore('bio')"
                  >See more</a
                >
                <mat-form-field appearance="fill" *ngIf="editAllFlag">
                  <textarea
                    matInput
                    rows="5"
                    [(ngModel)]="bio"
                    formControlName="seeMreBio"
                    (change)="flag = true"
                    (ngModelChange)="detectChange('bio', $event)"
                    (keypress)="checklength($event)"
                  ></textarea>
                </mat-form-field>
              </div>
              <div
                *ngIf="seeMoreBioFalg"
                class="col-12 heading font-14 description-text"
                color="#0C2149"
              >
                <span *ngIf="!editAllFlag" class="word-break-css"
                  >{{ profileData?.bio }}
                  <a class="see_more" (click)="seeMore('bio')">See less</a></span
                >
                <mat-form-field appearance="fill" *ngIf="editAllFlag">
                  <textarea
                    matInput
                    rows="5"
                    [(ngModel)]="bio"
                    (change)="flag = true"
                    formControlName="seeLessBio"
                    (ngModelChange)="detectChange('bio', $event)"
                    (keypress)="checklength($event)"
                    onpaste="return false"
                  ></textarea>
                </mat-form-field>
              </div>
              <div *ngIf="editAllFlag">
                <div class="row col-12 pl-0 pr-0 pill-div">
                  <mat-form-field appearance="fill" class="col-xs-12 col-sm-12 col-md-10 col-lg-10">
                    <input
                      type="text"
                      aria-label="Number"
                      maxlength="20"
                      matInput
                      formControlName="biotag"
                      placeholder="creative, socialbutterfly, pingpongdemon"
                      [(ngModel)]="hashtag"
                    />
                  </mat-form-field>
                  <button
                    mat-raised-button
                    class="upload-btn col-sm-12 col-lg-1 col-md-1"
                    (click)="addHashTags(hashtag)"
                    [disabled]="disbleHashtagAddition"
                  >
                    +
                  </button>
                </div>
              </div>
              <div class="details-div mt-3">
                <mat-chip-list aria-label="Company culture selection">
                  <span *ngFor="let alias of hashtagArray; let i = index">
                    <mat-chip *ngIf="alias"
                      >{{ alias
                      }}<i
                        *ngIf="editAllFlag"
                        class="fa fa-times pl-2"
                        (click)="removeHashtags(alias, i)"
                      ></i>
                    </mat-chip>
                  </span>
                </mat-chip-list>
              </div>
            </div>
            <!-- </div> -->
          </div>
          <div class="postsBox mt-3">
            <div class="pl-3 pr-3 mt-2">
              <div class="profile-header">
                <div class="heading">
                  <label class="profile-heading-text font-18 mb-0"
                    >Domain Experience/Product Expertise<em class="primaryColor">*</em></label
                  >
                </div>
              </div>
              <div *ngIf="editAllFlag">
                <div class="row d-flex">
                  <div
                    class="domain-div col-12 col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 pr-1"
                  >
                    <mat-form-field appearance="fill">
                      <mat-icon matPrefix class="search-icon">search</mat-icon>
                      <input
                        type="text"
                        [matAutocomplete]="domain"
                        matInput
                        placeholder="Add Domain"
                        [(ngModel)]="selectedDomain"
                        (keyup)="valueChange()"
                        (click)="valueChange()"
                        formControlName="selectedDomain"
                        [disabled]="
                          addedDomainAndExpArray.length === 5 || addedDomainAndExpArray.length > 5
                        "
                      />
                      <mat-autocomplete autoActiveFirstOption #domain="matAutocomplete">
                        <mat-option *ngFor="let domain of domainFilteredValue" [value]="domain">
                          {{ domain }}
                        </mat-option>
                      </mat-autocomplete>
                    </mat-form-field>
                  </div>
                  <div
                    class="exp-div col-12 col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 pr-1 pl-1"
                  >
                    <mat-form-field appearance="fill">
                      <mat-icon matPrefix class="search-icon">search</mat-icon>
                      <input
                        type="text"
                        [matAutocomplete]="exp"
                        matInput
                        placeholder="Add Experience (Years)"
                        [(ngModel)]="selectedExp"
                        (keyup)="valueChange()"
                        (click)="valueChange()"
                        formControlName="selectedExp"
                        [disabled]="
                          addedDomainAndExpArray.length === 5 || addedDomainAndExpArray.length > 5
                        "
                      />
                      <mat-autocomplete autoActiveFirstOption #exp="matAutocomplete">
                        <mat-option *ngFor="let exp of expArray" [value]="exp">
                          {{ exp }}
                        </mat-option>
                      </mat-autocomplete>
                    </mat-form-field>
                  </div>
                  <div
                    class="add-button-div col-12 col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2 pl-1"
                  >
                    <button
                      mat-raised-button
                      class="upload-btn"
                      [disabled]="
                        selectedDomain == null ||
                        selectedDomain == '' ||
                        selectedExp == null ||
                        selectedExp == '' ||
                        addedDomainAndExpArray.length === 5 ||
                        addedDomainAndExpArray.length > 5
                      "
                      (click)="addDomainExp()"
                    >
                      +
                    </button>
                  </div>
                </div>
              </div>
              <div class="details-div mt-3">
                <mat-chip-list aria-label="Domain-Exp Selection">
                  <mat-chip
                    *ngFor="let exp of addedDomainAndExpArray; let i = index"
                    class="chip-btn"
                  >
                    {{ exp }}
                    <i
                      class="fa fa-times pl-2"
                      *ngIf="editAllFlag"
                      (click)="removeDomainAndExp(i); flag = true"
                    ></i>
                  </mat-chip>
                </mat-chip-list>
              </div>
            </div>
          </div>
          <div class="postsBox mt-3">
            <div class="pl-3 pr-3 mt-2">
              <div class="profile-header">
                <div class="heading">
                  <label class="profile-heading-text font-18 mb-0"> Technical Skills</label>
                </div>
              </div>
              <div class="" *ngIf="editAllFlag">
                <div class="row">
                  <mat-form-field appearance="fill" class="col-xs-12 col-sm-12 col-md-10 col-lg-10">
                    <mat-icon matPrefix class="search-icon">search</mat-icon>
                    <input
                      type="text"
                      aria-label="Number"
                      [matAutocomplete]="auto"
                      matInput
                      placeholder="Type answers..."
                      formControlName="technicalSkills"
                      [ngModelOptions]="{ standalone: true }"
                      (keyup)="companyValueChange()"
                      (click)="companyValueChange()"
                    />
                    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                      <mat-option
                        *ngFor="let option of companyFilteredOptions"
                        [value]="option"
                        (click)="
                          profileForm.controls.technicalSkills.setValue(option);
                          companyValueChange()
                        "
                      >
                        {{ option }}
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>
                  <button
                    mat-raised-button
                    class="upload-btn col-sm-12 col-lg-1 col-md-1"
                    (click)="addTechnicalSkill()"
                    [disabled]="disableAddCompanyValue"
                  >
                    +
                  </button>
                </div>
              </div>
              <div class="details-div mt-3">
                <mat-chip-list aria-label="Technical Skills">
                  <mat-chip
                    *ngFor="let technicalSkill of selectedTechnicalSkill; let i = index"
                    class="chip-btn"
                  >
                    {{ technicalSkill }}
                    <i
                      *ngIf="editAllFlag"
                      class="fa fa-times pl-2"
                      (click)="removeTechnicalSkill(i)"
                    ></i>
                  </mat-chip>
                </mat-chip-list>
              </div>
            </div>
          </div>
          <div class="postsBox mt-3">
            <div class="pl-3 pr-3 mt-2">
              <div class="profile-header">
                <div class="heading">
                  <label class="profile-heading-text font-18 mb-0"> Soft Skills</label>
                  <!--
                                    <img src="../../../../assets/images/chat-icon/Edit.svg" (click)="editSkill()"
                                        *ngIf="!editSkillFlag && showPencilIcon" class="icon-css">
                                    <mat-icon *ngIf="editSkillFlag" (click)="submitData();editSkillFlag = !editSkillFlag">
                                        check_circle_outline
                                    </mat-icon>
                                    -->
                  <!-- <img src="../../../../assets/images/chat-icon/Show.svg" class="icon-css"
                                        style="width: 20px; height: 20px;"> -->
                </div>
              </div>
              <div *ngIf="editAllFlag">
                <div class="row">
                  <mat-form-field appearance="fill" class="col-xs-12 col-sm-12 col-md-10 col-lg-10">
                    <mat-icon matPrefix class="search-icon">search</mat-icon>
                    <input
                      type="text"
                      id="searchBtn"
                      aria-label="Number"
                      matInput
                      formControlName="skillValue"
                      [matAutocomplete]="auto"
                      matInput
                      placeholder="You can select up to 10"
                      [(ngModel)]="skillValue"
                      (input)="checkSkillValidity()"
                      (click)="checkSkillValidity()"
                      (select)="checkSkillValidity()"
                      [disabled]="skillsArray.length > 9"
                      (keyup)="checkSkillValidity()"
                    />
                    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                      <mat-option
                        *ngFor="let option of skillFilteredOptions"
                        [value]="option"
                        (click)="skillValue = option; checkSkillValidity()"
                      >
                        {{ option }}
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>
                  <!-- <button mat-raised-button class="upload-btn col-sm-12 col-lg-2 col-md-2"
                                        (click)="addSkillData(skillValue);flag=true" [disabled]="skillsArray?.length > 9">+</button> -->
                  <button
                    mat-raised-button
                    class="upload-btn col-sm-12 col-lg-1 col-md-1"
                    (click)="addSkillData(skillValue); flag = true; checkSkillValidity()"
                    [disabled]="skillsArray.length > 9 || disableAddSkill"
                  >
                    +
                  </button>
                </div>
              </div>
              <div class="details-div mt-3">
                <mat-chip-list aria-label="Skills Value Selection">
                  <mat-chip *ngFor="let skills of skillsArray; let i = index" class="chip-btn">
                    {{ skills }}
                    <i
                      class="fa fa-times pl-2"
                      *ngIf="editAllFlag"
                      (click)="removeSkills(i); flag = true"
                    ></i>
                  </mat-chip>
                </mat-chip-list>
              </div>
            </div>
          </div>
          <div class="postsBox mt-3">
            <div class="pl-3 pr-3 mt-2">
              <div class="profile-header">
                <div class="heading">
                  <label class="profile-heading-text font-18 mb-0"
                    >Values<em class="primaryColor">*</em></label
                  >
                  <!--
                                    <img src="../../../../assets/images/chat-icon/Edit.svg" (click)="editCulture()"
                                        *ngIf="!editCultureFlag && showPencilIcon" class="icon-css">
                                    <mat-icon *ngIf="editCultureFlag"
                                        (click)="submitData();editCultureFlag = !editCultureFlag">
                                        check_circle_outline
                                    </mat-icon>
                                    -->
                  <!-- <img src="../../../../assets/images/chat-icon/Show.svg" class="icon-css"
                                        style="width: 20px; height: 20px;"> -->
                </div>
              </div>
              <div *ngIf="editAllFlag">
                <div class="row">
                  <mat-form-field appearance="fill" class="col-xs-12 col-sm-12 col-md-10 col-lg-10">
                    <mat-icon matPrefix class="search-icon">search</mat-icon>
                    <input
                      type="text"
                      aria-label="Number"
                      matInput
                      [matAutocomplete]="auto"
                      matInput
                      placeholder="Select at least 5 (up to 10)"
                      [(ngModel)]="values"
                      formControlName="values"
                      (keyup)="candidateValuesChange()"
                      [disabled]="valuesArr.length > 9"
                      (click)="candidateValuesChange()"
                    />
                    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                      <mat-option
                        *ngFor="let option of valuesFilteredOptions"
                        [value]="option"
                        (click)="values = option; candidateValuesChange()"
                      >
                        {{ option }}
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>
                  <button
                    mat-raised-button
                    class="upload-btn col-sm-12 col-lg-1 col-md-1"
                    (click)="addValues(values); flag = true; candidateValuesChange()"
                    [disabled]="valuesArr?.length > 9 || disableAddValues"
                  >
                    +
                  </button>
                </div>
              </div>
              <div class="details-div mt-3">
                <mat-chip-list aria-label="Values Selection">
                  <mat-chip *ngFor="let value of valuesArr; let i = index" class="chip-btn">
                    {{ value }}
                    <i
                      class="fa fa-times pl-2"
                      *ngIf="editAllFlag"
                      (click)="removeValues(i); flag = true"
                    ></i>
                  </mat-chip>
                </mat-chip-list>
              </div>
            </div>
          </div>
          <div class="postsBox mt-3">
            <div class="pl-3 pr-3 mt-2">
              <div class="row profile-header font-18 mt-2">
                <div class="col-12 heading">
                  <label class="profile-heading-text mb-0">
                    Achievements - Personal/Professional
                  </label>
                </div>
              </div>
              <div
                *ngIf="!seeMoreGoalsFalg"
                class="font-14 description-text word-break-css"
                color="#0C2149"
              >
                {{ !editAllFlag ? profileData?.goals?.slice(0, 150) : ''
                }}<span *ngIf="profileData !== undefined"
                  ><b *ngIf="!editAllFlag && profileData?.goals && profileData?.goals?.length > 150"
                    >...</b
                  ></span
                ><a
                  *ngIf="profileData?.goals?.length > 150 && !editAllFlag"
                  class="see_more"
                  (click)="seeMore('goals')"
                  >See more</a
                >
                <mat-form-field appearance="fill" *ngIf="editAllFlag">
                  <textarea
                    matInput
                    rows="5"
                    [(ngModel)]="goals"
                    (change)="flag = true"
                    formControlName="goals"
                    (ngModelChange)="detectChange('goals', $event)"
                    (keypress)="checklength($event)"
                    placeholder="List some of or your personal professional achievements e.g. Climbed mount Everest, Completed a project XYZ"
                  ></textarea>
                </mat-form-field>
              </div>
              <div
                *ngIf="seeMoreGoalsFalg"
                class="col-12 heading font-14 description-text"
                color="#0C2149"
              >
                <span *ngIf="!editAllFlag" class="word-break-css"
                  >{{ profileData?.goals }}
                  <a class="see_more" (click)="seeMore('goals')">See less</a></span
                >
                <mat-form-field appearance="fill" *ngIf="editAllFlag">
                  <textarea
                    matInput
                    rows="5"
                    [(ngModel)]="goals"
                    (change)="flag = true"
                    formControlName="goals2"
                    (ngModelChange)="detectChange('goals', $event)"
                    (keypress)="checklength($event)"
                  ></textarea>
                </mat-form-field>
              </div>
              <!-- <div *ngIf="!editGoalFlag && profileData?.goals">
                                <div [innerHTML]="profileData?.goals"></div>
                            </div>
                            <div class="" *ngIf="editGoalFlag">
                                <div class="row col-12 pl-0 pr-0 pill-div">
                                    <div class="NgxEditor__Wrapper">
                                        <div class="editor">
                                            <ngx-editor-menu [editor]="editor" [toolbar]="toolbar">
                                            </ngx-editor-menu>
                                            <ngx-editor [editor]="editor" [(ngModel)]="goals" name="goals" ngDefaultControl
                                                (ngModelChange)="detectChange('goals', $event)"
                                                placeholder="List some of or your one true career goal e.g. �I want to be a CEO� or �I want to be the next Mike Cannon-Brookes">
                                            </ngx-editor>
                                        </div>
                                        <mat-form-field appearance="fill" *ngIf="editGoalFlag" style="margin-left: 12px;">
                                            <textarea matInput rows="5" [(ngModel)]="goals" name="goals" (ngModelChange)="detectChange('goals', $event)" placeholder="List some of or your one true career goal e.g. �I want to be a CEO� or �I want to be the next Mike Cannon-Brookes"></textarea>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div> -->
            </div>
          </div>
          <div class="postsBox mt-3">
            <div class="pl-3 pr-3 mt-2 experienceDiv">
              <div class="profile-header">
                <div class="heading flex-column align-items-start">
                  <label class="profile-heading-text font-18 mb-0"
                    >Experience<em class="primaryColor">*</em></label
                  >
                  <label class="sub-heading-text"
                    >Please add your last 3 roles, latest role furthest right.</label
                  >
                </div>
              </div>
              <div class="details-div mt-3 row">
                <div class="row col-12 pr-0">
                  <div
                    class="timeline-steps aos-init aos-animate timeline-display"
                    data-aos="fade-up"
                  >
                    <div class="timeline-step col-4">
                      <div
                        class="timeline-content timeline-align"
                        data-toggle="popover"
                        data-trigger="hover"
                        data-placement="top"
                        title=""
                        data-content="And here's some amazing content. It's very engaging. Right?"
                        data-original-title="2005"
                      >
                        <div class="img-download inner-circle">
                          <div class="custom-file-upload mx-auto">
                            <ngx-dropzone
                              *ngIf="editAllFlag"
                              id="uploadImage1"
                              class="dropzone"
                              clickable="true"
                              [multiple]="false"
                              (change)="uploadImageProfile($event, 'imageProfile3')"
                              accept="image/jpeg, image/gif, image/png, image/webp"
                            >
                              <img
                                id="blah1"
                                src="{{ resImageProfile3 }}"
                                onerror="this.onerror=null;this.src='../../../assets/images/background/login-register.jpg'"
                              />
                            </ngx-dropzone>
                            <img
                              *ngIf="!editAllFlag"
                              id="blah1"
                              src="{{ resImageProfile3 }}"
                              onerror="this.onerror=null;this.src='../../../assets/images/background/login-register.jpg'"
                            />
                          </div>
                          <!-- <label for="file-upload3" class="custom-file-upload mx-auto">
                                                        <img id="blah3s" src="{{resImageProfile3}}"
                                                            onerror="this.onerror=null;this.src='../../../assets/images/background/login-register.jpg'">
                                                    </label>
                                                    <input id="file-upload3" type="file"
                                                        accept="image/jpeg, image/gif, image/png, image/webp"
                                                        *ngIf="editAllFlag"
                                                        (change)="uploadImageProfile($event,'imageProfile3');" /> -->
                          <label for="file-upload3">
                            <mat-icon
                              *ngIf="editAllFlag"
                              (click)="uploadExp1Image('uploadImage1')"
                              class="mat-plus"
                              >add_circle
                            </mat-icon>
                          </label>
                        </div>
                        <p class="h6 mt-5 mb-1 company-name" *ngIf="!editAllFlag">
                          {{ experienceCompanyName3 }}
                        </p>
                        <mat-form-field
                          class="example-full-width mt-5"
                          *ngIf="editAllFlag"
                          appearance="fill"
                        >
                          <input
                            type="text"
                            matInput
                            placeholder="Company Name"
                            formControlName="experienceCompanyName3"
                            [(ngModel)]="experienceCompanyName3"
                          />
                        </mat-form-field>
                        <p class="h6 mb-1 company-details" *ngIf="!editAllFlag">
                          {{ companyDesignation3 }}
                        </p>
                        <mat-form-field
                          class="example-full-width"
                          *ngIf="editAllFlag"
                          appearance="fill"
                        >
                          <input
                            type="text"
                            matInput
                            placeholder="Role"
                            formControlName="companyDesignation3"
                            [(ngModel)]="companyDesignation3"
                          />
                        </mat-form-field>
                        <p class="h6 mb-0 mb-lg-0 company-details" *ngIf="!editAllFlag">
                          <!-- {{startMonth1}} {{startYear1}} - {{endMonth1}} {{endYear1}} -->
                          {{ experience3 }}
                        </p>
                        <!-- <mat-form-field class="example-full-width" *ngIf="editAllFlag"
                                                    appearance="fill">
                                                    <mat-date-range-input [rangePicker]="picker" [max]="maxDate">
                                                        <input matStartDate [(ngModel)]="experience3From"
                                                            placeholder="Start date">
                                                        <input matEndDate [(ngModel)]="experience3To"
                                                            placeholder="End date">
                                                    </mat-date-range-input>
                                                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                                    <mat-date-range-picker #picker></mat-date-range-picker>
                                                </mat-form-field> -->
                        <div class="date-text">
                          <!-- (monthSelected)="chosenExperience3MonthHandler($event, startDate)" -->
                          <mat-form-field
                            appearance="fill"
                            *ngIf="editAllFlag"
                            style="margin-right: 5px"
                          >
                            <mat-select
                              placeholder="Start Year"
                              [(ngModel)]="experience3From"
                              formControlName="experience3From"
                            >
                              <mat-option *ngFor="let item of yearDropdown" value="{{ item }}">
                                {{ item }}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                          <!-- <mat-form-field appearance="fill" *ngIf="editAllFlag"
                                                        style="margin-right: 5px;">
                                                        <input matInput [(ngModel)]="experience3From"
                                                            [matDatepicker]="startDate" placeholder="Start Date">
                                                        <mat-datepicker-toggle matSuffix [for]="startDate">
                                                        </mat-datepicker-toggle>
                                                        <mat-datepicker #startDate startView="multi-year"
                                                            (yearSelected)="chosenExperience3YearHandler($event, startDate)"
                                                            panelClass="example-month-picker">
                                                            <mat-datepicker-actions>
                                                                <button mat-button matDatepickerCancel>X</button>
                                                            </mat-datepicker-actions>
                                                        </mat-datepicker>
                                                    </mat-form-field> -->
                          <!-- (monthSelected)="chosenExperience3EndMonthHandler($event, endDate)" -->
                          <mat-form-field
                            appearance="fill"
                            *ngIf="editAllFlag"
                            style="margin-right: 5px"
                          >
                            <mat-select
                              placeholder="End Year"
                              [(ngModel)]="experience3To"
                              formControlName="experience3To"
                            >
                              <mat-option value="present">Present</mat-option>
                              <mat-option *ngFor="let item of yearDropdown" value="{{ item }}">
                                {{ item }}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                          <!-- <mat-form-field appearance="fill" *ngIf="editAllFlag">
                                                        <input matInput [(ngModel)]="experience3To"
                                                            [matDatepicker]="endDate" placeholder="End Date">
                                                        <mat-datepicker-toggle matSuffix [for]="endDate">
                                                        </mat-datepicker-toggle>
                                                        <mat-datepicker #endDate startView="multi-year"
                                                            (yearSelected)="chosenExperience3EndYearHandler($event, endDate)"
                                                            panelClass="example-month-picker">
                                                            <mat-datepicker-actions>
                                                                <button mat-button matDatepickerCancel>X</button>
                                                            </mat-datepicker-actions>
                                                        </mat-datepicker>
                                                    </mat-form-field> -->
                        </div>
                      </div>
                    </div>
                    <div class="timeline-step col-4">
                      <div
                        class="timeline-content timeline-align"
                        data-toggle="popover"
                        data-trigger="hover"
                        data-placement="top"
                        title=""
                        data-content="And here's some amazing content. It's very engaging. Right?"
                        data-original-title="2004"
                      >
                        <div class="img-download inner-circle">
                          <div class="custom-file-upload mx-auto">
                            <ngx-dropzone
                              *ngIf="editAllFlag"
                              id="uploadImage2"
                              class="dropzone"
                              [multiple]="false"
                              (change)="uploadImageProfile($event, 'imageProfile2')"
                              accept="image/jpeg, image/gif, image/png, image/webp"
                            >
                              <img
                                id="blah1"
                                src="{{ resImageProfile2 }}"
                                onerror="this.onerror=null;this.src='../../../assets/images/background/login-register.jpg'"
                              />
                            </ngx-dropzone>
                            <img
                              *ngIf="!editAllFlag"
                              id="blah1"
                              src="{{ resImageProfile2 }}"
                              onerror="this.onerror=null;this.src='../../../assets/images/background/login-register.jpg'"
                            />
                          </div>
                          <!-- <label for="file-upload2" class="custom-file-upload mx-auto">
                                                        <img id="blah2" src="{{resImageProfile2}}"
                                                            onerror="this.onerror=null;this.src='../../../assets/images/background/login-register.jpg'">
                                                    </label>
                                                    <input id="file-upload2" type="file"
                                                        accept="image/jpeg, image/gif, image/png, image/webp"
                                                        *ngIf="editAllFlag"
                                                        (change)="uploadImageProfile($event,'imageProfile2');" /> -->
                          <label for="file-upload2">
                            <mat-icon
                              *ngIf="editAllFlag"
                              class="mat-plus"
                              (click)="uploadExp1Image('uploadImage2')"
                              >add_circle
                            </mat-icon>
                          </label>
                        </div>
                        <p class="h6 mt-5 mb-1 company-name" *ngIf="!editAllFlag">
                          {{ experienceCompanyName2 }}
                        </p>
                        <mat-form-field
                          class="example-full-width mt-5"
                          *ngIf="editAllFlag"
                          appearance="fill"
                        >
                          <input
                            type="text"
                            matInput
                            placeholder="Company Name"
                            formControlName="experienceCompanyName2"
                            [(ngModel)]="experienceCompanyName2"
                          />
                        </mat-form-field>
                        <p class="h6 mb-1 company-details" *ngIf="!editAllFlag">
                          {{ companyDesignation2 }}
                        </p>
                        <mat-form-field
                          class="example-full-width"
                          *ngIf="editAllFlag"
                          appearance="fill"
                        >
                          <input
                            type="text"
                            matInput
                            placeholder="Role"
                            formControlName="companyDesignation2"
                            [(ngModel)]="companyDesignation2"
                          />
                        </mat-form-field>
                        <p class="h6 mb-0 mb-lg-0 company-details" *ngIf="!editAllFlag">
                          {{ experience2 }}
                        </p>
                        <div class="date-text">
                          <!-- (monthSelected)="chosenExperience2MonthHandler($event, startDate)" -->
                          <!-- <mat-form-field appearance="fill" *ngIf="editAllFlag"
                                                        style="margin-right: 5px;">
                                                        <input matInput [(ngModel)]="experience2From"
                                                            [matDatepicker]="startDate" placeholder="Start Date">
                                                        <mat-datepicker-toggle matSuffix [for]="startDate">
                                                        </mat-datepicker-toggle>
                                                        <mat-datepicker #startDate startView="multi-year"
                                                            (yearSelected)="chosenExperience2YearHandler($event, startDate)"
                                                            panelClass="example-month-picker">
                                                            <mat-datepicker-actions>
                                                                <button mat-button matDatepickerCancel>X</button>
                                                            </mat-datepicker-actions>
                                                        </mat-datepicker>
                                                    </mat-form-field> -->
                          <mat-form-field
                            appearance="fill"
                            *ngIf="editAllFlag"
                            style="margin-right: 5px"
                          >
                            <mat-select
                              placeholder="Start Year"
                              [(ngModel)]="experience2From"
                              formControlName="experienceFrom2"
                            >
                              <mat-option *ngFor="let item of yearDropdown" value="{{ item }}">
                                {{ item }}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                          <!-- (monthSelected)="chosenExperience2EndMonthHandler($event, endDate)" -->
                          <mat-form-field appearance="fill" *ngIf="editAllFlag">
                            <mat-select
                              placeholder="End Year"
                              [(ngModel)]="experience2To"
                              formControlName="experienceTo2"
                            >
                              <mat-option value="present">Present</mat-option>
                              <mat-option *ngFor="let item of yearDropdown" value="{{ item }}">
                                {{ item }}
                              </mat-option>
                            </mat-select>
                            <!-- <input matInput [(ngModel)]="experience2To"
                                                            [matDatepicker]="endDate" placeholder="End Date">
                                                        <mat-datepicker-toggle matSuffix [for]="endDate">
                                                        </mat-datepicker-toggle>
                                                        <mat-datepicker #endDate startView="multi-year"
                                                            (yearSelected)="chosenExperience2EndYearHandler($event, endDate)"
                                                            panelClass="example-month-picker">
                                                            <mat-datepicker-actions>
                                                                <button mat-button matDatepickerCancel>X</button>
                                                            </mat-datepicker-actions>
                                                        </mat-datepicker> -->
                          </mat-form-field>
                        </div>
                        <!-- <mat-form-field class="example-full-width" *ngIf="editAllFlag"
                                                    appearance="fill">
                                                    <mat-date-range-input [rangePicker]="picker" [max]="maxDate">
                                                        <input matStartDate [(ngModel)]="experience2From"
                                                            placeholder="Start date">
                                                        <input matEndDate [(ngModel)]="experience2To"
                                                            placeholder="End date">
                                                    </mat-date-range-input>
                                                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                                    <mat-date-range-picker #picker></mat-date-range-picker>
                                                </mat-form-field> -->
                      </div>
                    </div>
                    <div class="line"></div>
                    <div class="timeline-step col-4">
                      <div
                        class="timeline-content timeline-align"
                        data-toggle="popover"
                        data-trigger="hover"
                        data-placement="top"
                        title=""
                        data-content="And here's some amazing content. It's very engaging. Right?"
                        data-original-title="2003"
                      >
                        <div class="img-download inner-circle">
                          <div class="custom-file-upload mx-auto">
                            <ngx-dropzone
                              *ngIf="editAllFlag"
                              id="uploadImage3"
                              class="dropzone"
                              [multiple]="false"
                              (change)="uploadImageProfile($event, 'imageProfile1')"
                              accept="image/jpeg, image/gif, image/png, image/webp"
                            >
                              <img
                                id="blah1"
                                src="{{ resImageProfile1 }}"
                                onerror="this.onerror=null;this.src='../../../assets/images/background/login-register.jpg'"
                              />
                            </ngx-dropzone>
                            <img
                              *ngIf="!editAllFlag"
                              id="blah1"
                              src="{{ resImageProfile1 }}"
                              onerror="this.onerror=null;this.src='../../../assets/images/background/login-register.jpg'"
                            />
                          </div>
                          <!-- <label for="file-upload1" class="custom-file-upload mx-auto">
                                                        <img id="blah1" src="{{resImageProfile1}}"
                                                            onerror="this.onerror=null;this.src='../../../assets/images/background/login-register.jpg'">
                                                    </label>
                                                    <input id="file-upload1" type="file"
                                                        accept="image/jpeg, image/gif, image/png, image/webp"
                                                        *ngIf="editAllFlag"
                                                        (change)="uploadImageProfile($event,'imageProfile1');" /> -->
                          <label for="file-upload1">
                            <mat-icon
                              *ngIf="editAllFlag"
                              class="mat-plus"
                              (click)="uploadExp1Image('uploadImage3')"
                              >add_circle
                            </mat-icon>
                          </label>
                        </div>
                        <p class="h6 mt-5 mb-1 company-name" *ngIf="!editAllFlag">
                          {{ experienceCompanyName1 }}
                        </p>
                        <mat-form-field
                          class="example-full-width mt-5"
                          *ngIf="editAllFlag"
                          appearance="fill"
                        >
                          <input
                            type="text"
                            matInput
                            placeholder="Company Name"
                            formControlName="experienceCompanyName1"
                            [(ngModel)]="experienceCompanyName1"
                          />
                        </mat-form-field>
                        <p class="h6 mb-1 company-details" *ngIf="!editAllFlag">
                          {{ companyDesignation1 }}
                        </p>
                        <mat-form-field
                          class="example-full-width"
                          *ngIf="editAllFlag"
                          appearance="fill"
                        >
                          <input
                            type="text"
                            matInput
                            placeholder="Role"
                            formControlName="companyDesignation1"
                            [(ngModel)]="companyDesignation1"
                          />
                        </mat-form-field>
                        <p
                          class="h6 mb-0 mb-lg-0 company-details"
                          *ngIf="!editAllFlag && experience1"
                        >
                          {{ experience1 }}
                        </p>
                        <div class="date-text">
                          <!-- (monthSelected)="chosenMonthHandler($event, startDate)" -->
                          <!-- <mat-form-field appearance="fill">
                                                        <mat-select placeholder="Start Year" formControlName="startDate" (selectionChange)="selectStartYear(startDate,email)">
                                                            <mat-option
                                                                *ngFor="let item of yearDropdown" [value]="item">
                                                                {{item}}
                                                            </mat-option>
                                                        </mat-select>
                                                    </mat-form-field> -->
                          <mat-form-field
                            appearance="fill"
                            *ngIf="editAllFlag"
                            style="margin-right: 5px"
                          >
                            <mat-select
                              placeholder="Start Year"
                              [(ngModel)]="experience1From"
                              formControlName="experienceFrom1"
                            >
                              <mat-option *ngFor="let item of yearDropdown" value="{{ item }}">
                                {{ item }}
                              </mat-option>
                            </mat-select>
                            <!-- <input matInput [(ngModel)]="experience1From"
                                                            [matDatepicker]="startDate" placeholder="Start Date">
                                                        <mat-datepicker-toggle matSuffix [for]="startDate">
                                                        </mat-datepicker-toggle>
                                                        <mat-datepicker #startDate startView="multi-year"
                                                            (yearSelected)="chosenYearHandler($event, startDate)"
                                                            panelClass="example-month-picker">
                                                            <mat-datepicker-actions>
                                                                <button mat-button matDatepickerCancel>X</button>
                                                            </mat-datepicker-actions>
                                                        </mat-datepicker> -->
                          </mat-form-field>
                          <!-- (monthSelected)="chosenEndMonthHandler($event, endDate)" -->
                          <mat-form-field appearance="fill" *ngIf="editAllFlag">
                            <mat-select
                              placeholder="End Year"
                              [(ngModel)]="experience1To"
                              formControlName="experienceTo1"
                            >
                              <mat-option value="present">Present</mat-option>
                              <mat-option *ngFor="let item of yearDropdown" value="{{ item }}">
                                {{ item }}
                              </mat-option>
                            </mat-select>
                            <!-- <input matInput [(ngModel)]="experience1To"
                                                            [matDatepicker]="endDate" placeholder="End Date">
                                                        <mat-datepicker-toggle matSuffix [for]="endDate">
                                                        </mat-datepicker-toggle>
                                                        <mat-datepicker #endDate startView="multi-year"
                                                            (yearSelected)="chosenEndYearHandler($event, endDate)"
                                                            panelClass="example-month-picker">
                                                            <mat-datepicker-actions>
                                                                <button mat-button matDatepickerCancel>X</button>
                                                            </mat-datepicker-actions>
                                                        </mat-datepicker> -->
                          </mat-form-field>
                        </div>
                        <!-- <mat-form-field appearance="fill" *ngIf="editAllFlag">
                                                    <mat-date-range-input [rangePicker]="picker" [max]="maxDate">
                                                        <input matStartDate [(ngModel)]="experience1From"
                                                            placeholder="Start date">
                                                        <input matEndDate [(ngModel)]="experience1To"
                                                            placeholder="End date">
                                                    </mat-date-range-input>
                                                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                                    <mat-date-range-picker #picker startView="year"></mat-date-range-picker>
                                                </mat-form-field> -->
                      </div>
                      <div class="currentRoleCheckbox">
                        <mat-checkbox
                          formControlName="currentlyEmployed"
                          [(ngModel)]="currentlyEmployed"
                          [disabled]="!editAllFlag"
                          >This is my current role
                        </mat-checkbox>
                      </div>
                    </div>
                    <!-- <div class="line"></div> -->
                  </div>
                </div>
              </div>
              <!-- <div class="currentRoleCheckbox">
                                    <mat-checkbox formControlName="currentlyEmployed" [(ngModel)]="currentlyEmployed">This
                                        is my current role
                                    </mat-checkbox>
                                </div> -->
            </div>
          </div>
          <div class="postsBox mt-3">
            <div class="pl-3 pr-3 mt-2">
              <div class="profile-header">
                <div class="heading">
                  <label class="profile-heading-text font-18 mb-0">Proficiency in Language</label>
                </div>
              </div>
              <div class="text-center">
                <div class="profile-form row d-flex flex-wrap">
                  <mat-form-field
                    appearance="fill"
                    class="pr-2 col-xl-6 col-lg-6 col-md-6 col-sm-12 language-field"
                    *ngIf="editAllFlag"
                  >
                    <mat-select
                      placeholder="Select Language"
                      [(ngModel)]="selectedLanguage"
                      formControlName="selectedLanguage"
                      (selectionChange)="changeLanguage()"
                    >
                      <mat-option
                        *ngFor="let language of languagesData"
                        [value]="language"
                        (click)="selectedLanguage = language"
                      >
                        {{ language }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <mat-form-field
                    appearance="fill"
                    class="pr-2 col-xl-6 col-lg-6 col-md-6 col-sm-12 language-field"
                    *ngIf="editAllFlag"
                  >
                    <mat-select
                      class="select-proficiency"
                      placeholder="Select Language Proficiency"
                      [(ngModel)]="selectedProficiency"
                      formControlName="selectedProficiency"
                    >
                      <mat-option
                        *ngFor="let proficiency of languagesProficiencyData"
                        (click)="selectedProfiency = proficiency"
                        [value]="proficiency"
                      >
                        {{ proficiency }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div
                  class="col-xs-12 col-sm-12 col-md-10 col-lg-10 mx-auto mt-3 add-language-btn"
                  *ngIf="editAllFlag"
                >
                  <button
                    class="btn profile-btn"
                    (click)="addLanguages()"
                    [disabled]="!(selectedLanguage && selectedProficiency)"
                  >
                    Add Language
                  </button>
                </div>
                <div class="details-div mt-3">
                  <mat-chip-list aria-label="Languages Proficiency Selection">
                    <mat-chip
                      *ngFor="let data of selectedLanguageData; let i = index"
                      class="chip-btn languageChip"
                    >
                      {{ data }}
                      <i
                        class="fa fa-times pl-2"
                        *ngIf="editAllFlag"
                        (click)="removeLanguages(i); flag = true"
                      ></i>
                    </mat-chip>
                  </mat-chip-list>
                </div>
              </div>
            </div>
          </div>
          <div class="postsBox mt-3">
            <div class="pl-3 pr-3 mt-2">
              <div class="profile-header">
                <div class="heading">
                  <label class="profile-heading-text font-18 mb-0">Hobbies/Interests</label>
                </div>
              </div>
              <div class="" *ngIf="editAllFlag">
                <div class="row">
                  <mat-form-field appearance="fill" class="col-xs-12 col-sm-12 col-md-10 col-lg-10">
                    <input
                      type="text"
                      aria-label="Number"
                      matInput
                      formControlName="hobbies"
                      maxlength="30"
                      placeholder="Type answers (e.g. Reading Books, Vlogging, Exploring gadgets)"
                    />
                  </mat-form-field>
                  <button
                    mat-raised-button
                    class="upload-btn col-sm-12 col-lg-1 col-md-1"
                    (click)="addHobbies()"
                    [disabled]="
                      !(
                        profileForm.controls.hobbies.value != null &&
                        profileForm.controls.hobbies.value != ''
                      )
                    "
                  >
                    +
                  </button>
                </div>
              </div>
              <div class="details-div mt-3">
                <mat-chip-list aria-label="Hobbies Selection">
                  <mat-chip *ngFor="let hobbies of hobbiesArray; let i = index" class="chip-btn">
                    {{ hobbies }}
                    <i
                      class="fa fa-times pl-2"
                      *ngIf="editAllFlag"
                      (click)="removeHobbies(i); flag = true"
                    ></i>
                  </mat-chip>
                </mat-chip-list>
              </div>
            </div>
          </div>
          <div class="postsBox mt-3">
            <div class="pl-3 pr-3 mt-2">
              <div class="profile-header">
                <div class="heading">
                  <label class="profile-heading-text font-18 mb-0">Frustrations</label>
                </div>
              </div>
              <div class="" *ngIf="editAllFlag">
                <div class="row">
                  <mat-form-field appearance="fill" class="col-xs-12 col-sm-12 col-md-10 col-lg-10">
                    <input
                      type="text"
                      aria-label="Number"
                      matInput
                      [matAutocomplete]="frustration"
                      matInput
                      formControlName="frustrations"
                      placeholder="Type answers (e.g. Lack of direction, small talk, mayonnaise in sandwiches)"
                      [(ngModel)]="frustrations"
                      (input)="checkfrustrationsValidity()"
                      (click)="checkfrustrationsValidity()"
                      maxlength="30"
                      (select)="checkfrustrationsValidity()"
                      (keyup)="checkfrustrationsValidity()"
                    />
                    <mat-autocomplete #frustration="matAutocomplete">
                      <mat-option
                        *ngFor="let option of frustrationsFilteredOptions"
                        [value]="option"
                      >
                        {{ option }}
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>
                  <button
                    mat-raised-button
                    class="upload-btn col-sm-12 col-lg-1 col-md-1"
                    (click)="addFrustrations(); checkfrustrationsValidity(); flag = true"
                    [disabled]="frustrationsArray?.length > 9"
                  >
                    +
                  </button>
                </div>
              </div>
              <div class="details-div mt-3">
                <mat-chip-list aria-label="Frustrations Selection">
                  <mat-chip
                    *ngFor="let frustrations of frustrationsArray; let i = index"
                    class="chip-btn"
                  >
                    {{ frustrations }}
                    <i
                      class="fa fa-times pl-2"
                      *ngIf="editAllFlag"
                      (click)="removefrustrations(i); flag = true"
                    ></i>
                  </mat-chip>
                </mat-chip-list>
              </div>
            </div>
          </div>
          <div class="postsBox mt-3">
            <div class="pl-3 pr-3 mt-2">
              <div class="profile-header">
                <div class="heading">
                  <label class="profile-heading-text font-18 mb-0">Other Qualifications</label>
                </div>
              </div>
              <div class="" *ngIf="editAllFlag">
                <div class="row">
                  <mat-form-field appearance="fill" class="col-xs-12 col-sm-12 col-md-10 col-lg-10">
                    <mat-icon matPrefix class="search-icon">search</mat-icon>
                    <input
                      type="text"
                      matInput
                      formControlName="qualification"
                      placeholder="i.e. Certified Project Manager, Agile Scrum Master, AWS Certified, Qualified Lawyer/Accountant, Scuba Diver, Skydive Instructor, Football Coach"
                    />
                  </mat-form-field>
                  <button
                    mat-raised-button
                    class="upload-btn col-sm-12 col-lg-1 col-md-1"
                    (click)="addQualification()"
                    [disabled]="
                      !(
                        profileForm.controls.qualification.value != null &&
                        profileForm.controls.qualification.value != ''
                      )
                    "
                  >
                    +
                  </button>
                </div>
              </div>
              <div class="details-div mt-3">
                <mat-chip-list aria-label="Qualifications">
                  <mat-chip
                    *ngFor="let qualification of qualificationsArray; let i = index"
                    class="chip-btn"
                  >
                    {{ qualification }}
                    <i
                      *ngIf="editAllFlag"
                      class="fa fa-times pl-2"
                      (click)="removeQualification(i)"
                    ></i>
                  </mat-chip>
                </mat-chip-list>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div fxFlex.gt-lg="2" fxFlex.gt-md="2" fxFlex.gt-xs="2" fxFlex="2" style="display: block">
      <button
        type="button"
        class="close-btn"
        aria-label="Close"
        data-dismiss="modal"
        style="position: absolute; width: 50px; height: 50px; margin-left: -28px; border: none"
        (click)="cancel()"
      >
        <span aria-hidden="true" style="font-size: 30px">&times;</span>
      </button>
    </div>
  </form>
</div>
<ng-template #reTakeTestModal>
  <div class="ease-in">
    <div class="pl-3 pr-3">
      <div class="profile-header">
        <div class="heading col-12 p-0 justify-content-between align-items-start">
          <label class="profile-heading-text font-14 mb-3 mr-0"
            >Please select an option to re-take the Personality Questionnaire</label
          >
        </div>
        <div class="position-relative">
          <button mat-icon-button class="retake-test-close-button" (click)="closeRetakeModal()">
            <i class="fa fa-times"></i>
          </button>
        </div>
      </div>
      <div class="ml-2 take-test-buttons">
        <div class="d-flex flex-column align-items-center">
          <button
            mat-button
            class="test-button"
            (click)="takePersonalityTest('short-test'); closeRetakeModal()"
          >
            Take the short one!
          </button>
          <span class="description-text">3 minutes</span>
        </div>
        <div class="d-flex flex-column align-items-center">
          <button
            mat-button
            class="test-button"
            (click)="takePersonalityTest('long-test'); closeRetakeModal()"
          >
            Take the long one!
          </button>
          <span class="description-text">8 minutes</span>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
  <p style="color: white">Please Wait.</p>
</ngx-spinner>
