<div class="modal-header">
  <h6 class="circularBold signUpHeading ml-4">Recruitment Process</h6>
  <button
    type="button"
    class="close"
    aria-label="Close"
    data-dismiss="modal"
    (click)="closeModal()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="col-lg-6">
  <button mat-raised-button (click)="saveRecords()" class="savebtn" matTooltip="Save">
    <mat-icon style="font-size: 20px; margin-top: 2px; margin-left: -10px">check </mat-icon>Save
  </button>
  <button mat-raised-button class="savebtn" matTooltip="New" (click)="addNewRecords()">
    <mat-icon style="font-size: 20px; margin-top: 2px; margin-left: -10px">add</mat-icon>
    New
  </button>
</div>

<table
  mat-table
  [dataSource]="processData"
  class="mat-elevation-z8"
  style="width: 95%; margin: 10px"
>
  <ng-container matColumnDef="round_no">
    <th mat-header-cell *matHeaderCellDef class="matThClass">Round No</th>
    <td mat-cell *matCellDef="let element" class="matTdClass">
      <input
        #roundNo
        matInput
        type="number"
        class="form-control"
        (input)="element.changed = true"
        [(ngModel)]="element.round_no"
      />
    </td>
  </ng-container>

  <ng-container matColumnDef="round_name">
    <th mat-header-cell *matHeaderCellDef class="matThClass">Round Name</th>
    <td mat-cell *matCellDef="let element" class="matTdDescription">
      <input
        #roundName
        matInput
        type="text"
        class="form-control"
        (input)="element.changed = true"
        [(ngModel)]="element.round_name"
      />
    </td>
  </ng-container>

  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef class="matThClass">Action</th>
    <td mat-cell *matCellDef="let element; let i = index" class="matTdStatus">
      <mat-icon class="delete-record" (click)="deleteRecords(element, i)" matTooltip="delete"
        >delete
      </mat-icon>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>
