<div class="ease-in">
  <div class="modal-header">
    <h6 class="circularBold signUpHeading">Add Event</h6>
    <button
      type="button"
      class="close ml-2"
      aria-label="Close"
      data-dismiss="modal"
      (click)="cancel()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="demo-app">
    <div class="demo-app-sidebar">
      <div class="demo-app-sidebar-section">
        <h4 class="m-t-0 p-l-5 date-tag">Select the date(s) you want to assign specific hours</h4>
        <div (click)="$event.stopPropagation()">
          <mat-calendar
            #calendar
            (selectedChange)="select($event, calendar)"
            [dateClass]="isSelected"
            [minDate]="currentDate"
          >
          </mat-calendar>
        </div>
      </div>
    </div>

    <div class="demo-app-main">
      <div fxLayout="row wrap">
        <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
          <h4 class="m-t-0 p-l-5 date-tag">What hours are you available?</h4>
        </div>
      </div>

      <mat-list-item *ngFor="let slot of dateSlots">
        <div fxLayout="row wrap" style="align-items: center; margin-top: 10px">
          <div #slotStartTime fxFlex.gt-sm="35" fxFlex.gt-xs="100" fxFlex="100">
            <mat-select
              #startTimeMatSelect
              [(ngModel)]="slot.selectedStartTime"
              name="startTime"
              (selectionChange)="changed = true"
              appearance="fill"
            >
              <mat-option *ngFor="let time of timeSlotsList" [value]="time.value">
                {{ time.viewValue }}
              </mat-option>
            </mat-select>
          </div>
          <div fxFlex.gt-sm="5" fxFlex.gt-xs="100" fxFlex="100" class="time-dash text-center">
            -
          </div>
          <div #slotEndTime fxFlex.gt-sm="35" fxFlex.gt-xs="100" fxFlex="100">
            <mat-select
              #endTimeMatSelect
              [(ngModel)]="slot.selectedEndTime"
              name="endTime"
              appearance="fill"
              class="dropdown"
            >
              <div>
                <mat-option *ngFor="let time of timeSlotsList" [value]="time.value">
                  {{ time.viewValue }}
                </mat-option>
              </div>
            </mat-select>
          </div>
          <div fxFlex.gt-sm="25" fxFlex.gt-xs="100" fxFlex="100">
            <button
              mat-icon-button
              class="addButton"
              (click)="removeSlot(slot, false)"
              [disabled]="dateSlots.length < 2"
              aria-label="Remove"
            >
              <mat-icon>delete</mat-icon>
            </button>
            <button mat-icon-button class="addButton" (click)="addSlot()" aria-label="ADD">
              <mat-icon>add</mat-icon>
            </button>
          </div>
        </div>
      </mat-list-item>

      <div fxLayout="row wrap" class="buttonPanel">
        <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
          <button
            mat-raised-button
            (click)="cancel()"
            class="mr-2 secondary secondary-btn"
            [disabled]="eventForm.invalid"
            aria-label="CANCEL"
          >
            Cancel
          </button>
          <button
            mat-raised-button
            class="primary"
            (click)="save()"
            [disabled]="eventForm.invalid"
            aria-label="SAVE"
          >
            Save
          </button>
        </div>
      </div>
      <ng-container *ngIf="previousDateSlots.length">
        <mat-list-item *ngFor="let slot of previousDateSlots">
          <div fxLayout="row wrap" style="align-items: center; margin-top: 10px">
            <div #slotStartTime fxFlex.gt-sm="35" fxFlex.gt-xs="100" fxFlex="100">
              <mat-select
                disabled
                #startTimeMatSelect
                [(ngModel)]="slot.selectedStartTime"
                name="startTime"
                (selectionChange)="changed = true"
                appearance="fill"
              >
                <mat-option *ngFor="let time of timeSlotsList" [value]="time.value">
                  {{ time.viewValue }}
                </mat-option>
              </mat-select>
            </div>
            <div fxFlex.gt-sm="5" fxFlex.gt-xs="100" fxFlex="100" class="time-dash text-center">
              -
            </div>
            <div #slotEndTime fxFlex.gt-sm="35" fxFlex.gt-xs="100" fxFlex="100">
              <mat-select
                disabled
                #endTimeMatSelect
                [(ngModel)]="slot.selectedEndTime"
                name="endTime"
                appearance="fill"
                class="dropdown"
              >
                <div>
                  <mat-option *ngFor="let time of timeSlotsList" [value]="time.value">
                    {{ time.viewValue }}
                  </mat-option>
                </div>
              </mat-select>
            </div>
            <div fxFlex.gt-sm="25" fxFlex.gt-xs="100" fxFlex="100">
              <button
                mat-icon-button
                class="addButton ml-2"
                (click)="removeSlot(slot, true)"
                [disabled]="!previousDateSlots?.length"
                aria-label="Remove"
              >
                <mat-icon>delete</mat-icon>
              </button>
            </div>
          </div>
        </mat-list-item>
      </ng-container>
    </div>
  </div>
</div>
