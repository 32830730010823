import { Component, EventEmitter, OnInit, TemplateRef } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal'
import { CRUDService } from 'src/app/crud.service'
import { getSessionValue, getArrayStringValue, getArrayFromString } from 'src/app/common-utils'
import { CommonService } from 'src/app/common.service'
import { Router } from '@angular/router'
import { NgxSpinnerService } from 'ngx-spinner'
import { environment } from '../../../../environments/environment'
import { ToastrService } from 'ngx-toastr'
import { Options, LabelType } from '@angular-slider/ngx-slider'
import { CandidatesComponent } from '../candidates/candidates.component'
import { MatDialog, MatDialogRef } from '@angular/material/dialog'
import { RolePostSuccessModalComponent } from '../role-post-success-modal/role-post-success-modal.component'
import { PersonalityRankComponent } from '../personality-rank/personality-rank.component'
import { RolesCandidateDataComponent } from '../roles-candidate-data/roles-candidate-data.component'
import { MyScoreComponent } from '../my-score/my-score.component'
import { CultureBuilderComponent } from 'src/app/culture-builder/culture-builder.component'
import { CultureScoreComponent } from '../culture-score/culture-score.component'
import { ValuesMapService } from 'src/app/shared/services/values-map.service'
import { BusinessService } from 'src/app/shared/services/business.service'
import { SkillsetService } from 'src/app/shared/services/skillset.service'
import { RoleService } from 'src/app/shared/services/role.service'
import { S3Service } from 'src/app/shared/services/s3.service'

declare var $: any

@Component({
  selector: 'app-post-role',
  templateUrl: './post-role.component.html',
  styleUrls: ['./post-role.component.scss']
})
export class PostRoleComponent implements OnInit {
  rolePostSuccessDialog: TemplateRef<any>

  location: any
  locationData: any = []
  postRoleForm: FormGroup
  roleArray: any = []
  salaryArray: any = []
  benefitsArray: string[] = []
  skillsArray: string[] = []
  addbenefits: any
  addskills: any
  saveBenefits: any = ''
  saveSkills: any = ''
  skills: any = []
  benefits: any = []
  dayRateArray: any = []
  addedBenefitsArray: string[] = []
  myarray = []
  searchBenefitsValue: string[] = []
  searchSkillsValue: string[] = []
  addedSkillsArray: string[] = []
  imageSrc: any[] = []
  resImageSrc: any[] = []
  videoUrl: any[] = []
  resVideoUrl: any[] = []
  audioUrl: any[] = []
  resAudioUrl: any[] = []
  docUrl: any[] = []
  resDocUrl: any[] = []
  list: any[] = []
  userInfo: any
  businessProfile: any
  apiUrl: any = environment.apiUrl
  filesList: any[] = []
  submitted: boolean = false
  storeData: any
  worksheet: any
  DropdownOptions: any
  option: any
  companyFilteredOptions: any = []
  searchCompanyValue: string[] = []
  otherBenefitsFlag: boolean = false
  otherbenfits: any
  allowEditing: boolean = true

  roleTitle: any

  saveRoleType: any
  roleTypeArray: any
  otherRoleTypeFlag: boolean
  otherRoleType: any

  saveEmploymentType: any
  // employmentTypeArray: any = [
  //   "Part-Time",
  //   "Full-Time",
  //   "Contracting",
  //   "Casual",
  //   "Side-hustle",
  //   "Advisory or Board Role",
  // ];
  employmentTypeArray: any = []

  selectstart_by: any
  start_byOptions: any = [
    'Immediate/ASAP',
    'Within next 30 days',
    'When I find the right person',
    "We're in no rush"
  ]

  saveSoftSkill: any
  softSkillsArray: any = []
  addedSoftSkillsArray: any = []

  selectedDomain: any
  selectedExp: any
  domainArray: any = []
  expArray: any = []
  addedDomainAndExpArray: any = []
  domainFilteredValue: any = []

  description: any
  salary: any
  dayRate: any

  roleDetails: any
  id: any
  ceil: number = 250
  postArray: any
  min: number = 90
  max: number = 130
  sliderOptions: Options = {
    floor: 50,
    ceil: this.ceil,
    step: 10,
    hideLimitLabels: true,
    translate: (value: number, label: LabelType): string => {
      switch (label) {
        case LabelType.Low:
          return '$' + value
        case LabelType.High:
          if (value > this.ceil - 1) {
            return '$' + value + '+'
          } else {
            return '$' + value
          }
        default:
          return '$' + value
      }
    }
  }

  postAttachmentArray: any
  postAttachmentId: any
  roleStatusDesc: string
  bsModalRef1: BsModalRef
  target: any
  userType: any
  businessPostCandidates: any
  dialogRef: MatDialogRef<RolePostSuccessModalComponent>
  businessPosts: any
  removedFiles = []
  personalityTypeDetails
  cultureDetails
  cultureInfo
  personalityTypeData
  personalityFactors
  cultureFactors
  rolePersonalityRankingId
  showSalarySlider: boolean = true

  public event: EventEmitter<any> = new EventEmitter()
  constructor(
    private fb: FormBuilder,
    private bsModalService: BsModalService,
    private CRUDService: CRUDService,
    private commonService: CommonService,
    private router: Router,
    private valuesMapService: ValuesMapService,
    private businessService: BusinessService,
    private roleService: RoleService,
    private skillsetService: SkillsetService,
    private s3Service: S3Service,
    private SpinnerService: NgxSpinnerService,
    private toastr: ToastrService,
    private options: ModalOptions,
    private dialog: MatDialog,
    private bsModalRef: BsModalRef<PostRoleComponent>
  ) {}

  async ngOnInit(): Promise<void> {
    this.salary = '$' + this.min + 'k' + '-' + '$' + this.max + 'k'
    this.postRoleForm = this.fb.group({
      role: ['', [Validators.required, Validators.maxLength(120)]],
      roleType: ['', [Validators.required]],
      employmentType: ['', [Validators.required]],
      location: ['', [Validators.required, Validators.maxLength(120)]],
      start_by: ['', [Validators.required]],
      remoteWork: [false],
      roleDescription: ['', [Validators.required, Validators.maxLength(5000)]],
      salary: [this.salary],
      dayRate: [''],
      benefits: [''],
      softSkills: [''],
      domain: [''],
      experience: [''],
      skills: [''],
      otherBen: [''],
      hybrid: [false]
    })

    this.roleDetails = this.options.initialState.roleDetails
    this.personalityTypeDetails = this.options?.initialState?.personalityTypeDetails
      ? this.options?.initialState?.personalityTypeDetails
      : []
    if (this.roleDetails && this.roleDetails?.culture_profile) {
      const isStringifiedJson = this.isStringifiedJson(this.roleDetails?.culture_profile)
      if (isStringifiedJson) {
        this.cultureDetails = JSON.parse(this.roleDetails?.culture_profile)
      }
    }
    $('.modal-content').on('contextmenu', function () {
      // Display the message
      return false
    })
    this.DropdownOptions = ['Anyone', 'Private']
    //this.locationData = ['Sydney', 'Melbourne', 'Adelaide', 'Brisbane', 'Canberra', 'Perth', 'Darwin', 'Hobart', 'Cairns', 'Rural', 'Remote'];
    this.valuesMapService.getFilteredValuesMap(100, 0, '', 'LOCATION').subscribe((res) => {
      const sortedItem = res.data?.items?.sort((a, b) => a.display_order - b.display_order)
      const names = sortedItem.map(function (item) {
        return item.description
      })
      this.locationData = names
    })

    this.option = this.DropdownOptions[0]
    this.userInfo = getSessionValue('USER_INFO')
    this.userType = this.userInfo.user_type

    this.valuesMapService
      .getFilteredValuesMap(100, 0, '', 'PERSONALITY_FACTORS')
      .subscribe((res) => {
        this.personalityFactors = res.data?.items
        this.personalityFactors.forEach((ele) => {
          ele['description'] = JSON.parse(
            ele['description']?.replace('"Adjectives"', ', "Adjectives"')
          )
        })
        this.loadRoleTypeData()
      })

    this.valuesMapService.getFilteredValuesMap(100, 0, '', 'CULTURE_FACTORS').subscribe((res) => {
      this.cultureFactors = res.data?.items
      this.cultureFactors.forEach((ele) => {
        ele['description'] = JSON.parse(ele['description'])
      })
      this.loadCultureData()
    })

    if (this.roleDetails?.id) {
      let businessdata = await this.businessService
        .getBusiness(this.roleDetails.business_id)
        .toPromise()
      this.businessProfile = businessdata.data
    } else {
      this.businessProfile = getSessionValue('BUSINESS_PROFILE')
    }
    this.SpinnerService.show()

    this.valuesMapService.getFilteredValuesMap(100, 0, '', 'POST_ROLES').subscribe((res) => {
      this.roleArray = res.data?.items
    })
    this.valuesMapService.getFilteredValuesMap(100, 0, '', 'SALARY').subscribe((res) => {
      this.salaryArray = res.data?.items
    })
    this.valuesMapService.getFilteredValuesMap(100, 0, '', 'LOOKING_FOR').subscribe((res) => {
      const sortedItems = res.data?.items.sort((a, b) => a.display_order - b.display_order)
      let names = sortedItems
        .filter((e) => e.code !== '7')
        .map(function (item: any) {
          return item['description']
        })
      this.employmentTypeArray = names
    })
    this.valuesMapService.getFilteredValuesMap(100, 0, '', 'INCENTIVES').subscribe((res) => {
      const sortedItems = res.data.items.sort((a, b) => (a.description > b.description ? 1 : -1))
      let names = sortedItems.map(function (item: any) {
        return item['description']
      })
      this.benefitsArray = names
      this.searchBenefitsValue = names
    })
    this.skillsetService.getFilteredSkillset(2000, 0, '').subscribe((res) => {
      // data = data.sort((a, b) => (a.description > b.description ? 1 : -1));
      let names = res.data?.items.map(function (item) {
        /* return item['category'] + '-' + item['sub_category']; */
        if (item['status'] == 1) {
          return item['category']
        }
      })
      names = [...new Set(names)]
      this.skillsArray = names
      this.searchSkillsValue = names
    })
    this.valuesMapService.getFilteredValuesMap(100, 0, '', 'DAY_RATE').subscribe((res) => {
      let names = res.data?.items.map(function (item) {
        return item['description']
      })
      this.dayRateArray = names
    })

    this.valuesMapService.getFilteredValuesMap(100, 0, '', 'ROLES').subscribe((res) => {
      const sortedItems = res.data?.items.sort((a, b) => a.display_order - b.display_order)
      let names = sortedItems.map(function (item: any) {
        return item['description']
      })
      this.roleTypeArray = names
    })

    this.valuesMapService.getFilteredValuesMap(100, 0, '', 'SOFT_SKILLS').subscribe((res) => {
      const sortedItems = res.data?.items.sort((a, b) => a.display_order - b.display_order)
      //data = data.sort((a, b) => (a.description > b.description ? 1 : -1));
      let names = sortedItems.map(function (item: any) {
        return item['description']
      })
      this.softSkillsArray = names
    })

    this.valuesMapService.getFilteredValuesMap(100, 0, '', 'DOMAIN').subscribe((res) => {
      const sortedItems = res.data?.items.sort((a, b) => a.display_order - b.display_order)
      //data = data.sort((a, b) => (a.description > b.description ? 1 : -1));
      let names = sortedItems.map(function (item: any) {
        return item['description']
      })
      this.domainArray = names
    })

    this.valuesMapService.getFilteredValuesMap(100, 0, '', 'DOMAIN_EXP').subscribe((res) => {
      const sortedItems = res.data?.items.sort((a, b) => a.display_order - b.display_order)
      let names = sortedItems.map(function (item: any) {
        return item['description']
      })
      this.expArray = names
    })

    // this.postAttachmentArray = await this.CRUDService.getDataByField('BusinessPostAttachments', 'post_id', this.roleDetails.id).toPromise();

    // this.CRUDService.getDataByField("BusinessPosts", 'business_id', "55").subscribe((data:any) => {
    // })

    this.SpinnerService.hide()
    if (typeof this.roleDetails !== 'undefined') {
      this.saveSoftskill()
      this.loadData()
      this.changeSalary()
      this.getBusinessPostAttachments()
    }

    /* if (this.userType == 3) {
      // Question: Can admin post a role?
      this.CRUDService.getAllData("BusinessPostCandidates").subscribe(
        (data) => {
          this.businessPostCandidates = data;
        }
      );
    } */
  }

  // addBenefits(saveBenefits: any) {
  //   this.addedBenefitsArray?.push(this.saveBenefits);
  //   let index = this.benefitsArray.findIndex(val => (val === this.saveBenefits));
  //   this.saveBenefits = null;
  //   this.benefitsArray.splice(index, 1);
  // }

  async loadRoleTypeData() {
    if (this.roleDetails?.id && !this.personalityTypeDetails.length) {
      const data = await this.roleService
        .getPersonalityRankingByRoleId(this.roleDetails?.id)
        .toPromise()
      const personalityTypeDetails = data.data
      this.rolePersonalityRankingId = personalityTypeDetails?.id
      for (let x in personalityTypeDetails) {
        if (x !== 'id' && x !== 'post_id') {
          if (personalityTypeDetails[x]) {
            const obj = {
              archetype_code: personalityTypeDetails[x],
              rank: Number(x.split('_')[1])
            }
            this.personalityTypeDetails.push(obj)
          }
        }
      }
    }

    if (this.personalityTypeDetails.length) {
      this.personalityTypeDetails.sort((a, b) => {
        return a?.rank - b?.rank
      })
      this.personalityTypeData = this.personalityFactors?.find((value) => {
        return value.code === this.personalityTypeDetails[0]?.archetype_code
      })
    }
  }

  loadCultureData() {
    if (this.roleDetails && this.cultureDetails) {
      this.cultureDetails = this.cultureDetails.sort((a, b) => a.rank - b.rank)
      this.cultureInfo = this.cultureFactors.find(
        (e) => e.description.name === this.cultureDetails[0]?.name
      )?.description
      this.cultureDetails.forEach((value, i) => {
        const index = this.cultureFactors.findIndex((e) => e?.description?.name === value?.name)
        if (index !== -1) {
          this.cultureFactors[index]['count'] = 4 - i
        }
      })
    }
  }

  loadData() {
    this.id = this.roleDetails?.id
    this.roleTitle = this.roleDetails?.title
    this.saveRoleType = this.roleDetails?.role_type
    this.saveEmploymentType = getArrayFromString(this.roleDetails?.employment_type)
    this.location = getArrayFromString(this.roleDetails?.location)
    this.selectstart_by = this.roleDetails?.start_by
    this.description = this.roleDetails?.description
    this.salary = this.roleDetails?.salary || this.salary
    this.dayRate = this.roleDetails?.day_rate
    this.addedBenefitsArray = getArrayFromString(this.roleDetails?.benefits)
    this.addedSoftSkillsArray = getArrayFromString(this.roleDetails?.soft_skills)
    this.addedDomainAndExpArray = getArrayFromString(this.roleDetails?.experience)
    this.addedSkillsArray = getArrayFromString(this.roleDetails?.skills)
    this.postRoleForm
      .get('remoteWork')
      .setValue(this.roleDetails?.remote_work === 'Y' ? true : false)
    this.postRoleForm.get('hybrid').setValue(this.roleDetails?.hybrid_yn === 'Y' ? true : false)
    this.postRoleForm.get('employmentType').setValue(this.saveEmploymentType)
    if (
      this.saveEmploymentType?.length === 1 &&
      this.saveEmploymentType[0] === 'Contracting Role'
    ) {
      this.showSalarySlider = false
    }
  }

  removeBenefits(index: any) {
    let value = this.addedBenefitsArray[index]
    this.benefitsArray.push(value)
    this.benefitsArray = this.benefitsArray.sort((a, b) => (a > b ? 1 : -1))
    this.addedBenefitsArray.splice(index, 1)
  }

  addSkills(saveSkills: any) {
    if (this.addedSkillsArray.length < 10) {
      if (
        saveSkills &&
        !this.addedSkillsArray?.includes(this.saveSkills.trim()) &&
        this.saveSkills !== null &&
        this.saveSkills !== ''
      ) {
        this.addedSkillsArray?.push(this.saveSkills.trim())
        let index = this.skillsArray.findIndex((val) => val == this.saveSkills)
        this.saveSkills = ''
        this.skillsArray.splice(index, 1)
      } else if (this.addedSkillsArray?.includes(this.saveSkills.trim())) {
        this.toastr.warning('Already added in list')
        this.saveSkills = ''
      } else {
        // this.addedSkillsArray = [];
        this.addedSkillsArray?.push(this.saveSkills)
        let index = this.skillsArray.findIndex((val) => val == this.saveSkills)
        this.addskills = null
        this.skillsArray.splice(index, 1)
        this.saveSkills = ''
      }
    } else {
      this.saveSkills = ''
      this.toastr.warning('Only 10 skills are allowed')
    }
  }
  removeSkills(index: any) {
    let value = this.addedSkillsArray[index]
    this.skillsArray.push(value)
    this.skillsArray = this.skillsArray.sort((a, b) => (a > b ? 1 : -1))
    this.addedSkillsArray.splice(index, 1)
  }

  //   addBenefits(saveBenefits: any) {
  //     if (saveBenefits && !this.addedBenefitsArray?.includes(this.benefits) && this.saveBenefits !== null && this.saveBenefits !== "") {
  //       this.addedBenefitsArray?.push(this.saveBenefits);
  //       let index = this.benefitsArray.findIndex(val => (val == this.saveBenefits));
  //       this.saveBenefits = "";
  //       this.benefitsArray.splice(index, 1);
  //     }
  //   else{
  // this.addedBenefitsArray = [];
  // this.addedBenefitsArray?.push(this.saveBenefits);
  // let index = this.benefitsArray.findIndex(val => (val == this.saveBenefits));
  // this.addbenefits = "";
  // this.benefitsArray.splice(index, 1);
  //   }
  // }

  // removeBenefits(index: any) {
  // let value = this.addedBenefitsArray[index];
  // this.benefitsArray.push(value);
  // this.benefitsArray = this.benefitsArray.sort((a, b) => (a > b ? 1 : -1));
  // this.addedBenefitsArray.splice(index, 1);
  // }

  openPersonalityRankSelectionModal(status = null) {
    const dialogRef = this.dialog.open(PersonalityRankComponent, {
      panelClass: 'personality-rank-modal',
      maxWidth: '80vw',
      maxHeight: '90vh',
      autoFocus: 'false',
      data: status == 'update' ? this.personalityTypeDetails : []
    })

    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        if (data !== 'close') {
          this.personalityTypeDetails = data
          this.loadRoleTypeData()
        } else {
          this.bsModalRef.hide()
        }
      }
    })
  }

  openCultureBuilder() {
    const dialogRef = this.dialog.open(CultureBuilderComponent, {
      panelClass: 'culture-rank-modal',
      maxWidth: '70vw',
      maxHeight: '90vh',
      autoFocus: 'false',
      data: {
        type: 'client',
        selectedCultures: this.cultureDetails || null
      }
    })

    dialogRef.afterClosed().subscribe(async (data) => {
      if (data) {
        this.cultureDetails = data
        this.cultureInfo = this.cultureFactors.find(
          (e) => e.description.name === data[0]?.name
        )?.description
        this.cultureDetails.forEach((value, i) => {
          const index = this.cultureFactors.findIndex((e) => e?.description?.name === value?.name)
          if (index !== -1) {
            this.cultureFactors[index]['count'] = 4 - i
          }
        })
      }
    })
  }

  openScoreModal() {
    this.personalityFactors.forEach((value) => {
      const personality = this.personalityTypeDetails.find((ele) => {
        return ele.archetype_code === value.code
      })
      if (personality?.rank === 1) {
        value['count'] = 6
      } else if (personality?.rank === 2) {
        value['count'] = 5
      } else if (personality?.rank === 3) {
        value['count'] = 4
      } else if (personality?.rank === 4) {
        value['count'] = 3
      } else if (personality?.rank === 5) {
        value['count'] = 2
      } else if (personality?.rank === 6) {
        value['count'] = 1
      } else {
        value['count'] = 0
      }
    })
    this.dialog.open(MyScoreComponent, {
      panelClass: 'my-score-modal',
      autoFocus: false,
      data: {
        personalityTestInfo: this.personalityTypeData,
        personalityFactors: this.personalityFactors,
        header: "What You're Looking for in a Candidate",
        isClientScore: true
      }
    })
  }
  openCultureScoreModal() {
    const cultureFactors = [...this.cultureFactors]
    cultureFactors.sort((a, b) => {
      return Number(b?.count) - Number(a?.count)
    })

    this.dialog.open(CultureScoreComponent, {
      panelClass: 'my-score-modal',
      autoFocus: false,
      data: {
        cultureTestInfo: this.cultureInfo,
        cultureFactors: cultureFactors,
        header: 'Culture'
      }
    })
  }

  submitPostRole() {
    this.submitted = true
    if (this.postRoleForm.valid) {
      this.SpinnerService.show()
      const postFormJson = {
        roleTitle: this.postRoleForm.controls.role.value,
        roleType: this.postRoleForm.controls.roleType.value,
        employmentType: getArrayStringValue(this.postRoleForm.controls.employmentType.value),
        location: getArrayStringValue(this.postRoleForm.controls.location.value),
        start_by: this.selectstart_by,
        remoteWork: this.postRoleForm.get('remoteWork')?.value ? 'Y' : 'N',
        roleDescription: this.postRoleForm.controls.roleDescription.value,
        // salary: this.postRoleForm.controls.salary.value,
        salary:
          this.postRoleForm.controls.employmentType?.value?.length === 1 &&
          this.postRoleForm.controls.employmentType?.value[0] === 'Contracting Role'
            ? null
            : this.salary,
        dayRate: this.postRoleForm.controls.dayRate.value,
        benefits: getArrayStringValue(this.addedBenefitsArray),
        softSkills: getArrayStringValue(this.addedSoftSkillsArray),
        experience: getArrayStringValue(this.addedDomainAndExpArray),
        skills: getArrayStringValue(this.addedSkillsArray),
        hybrid_yn: this.postRoleForm.get('hybrid')?.value ? 'Y' : 'N',
        cultureProfile: JSON.stringify(this.cultureDetails),
        display_candidates: this.roleDetails?.display_candidates || 'N'
      }
      this.saveRecord(postFormJson)
    }
  }

  closeModal() {
    this.bsModalService.hide()
  }

  async saveRecord(formValues: any): Promise<any> {
    let post = {
      id: null,
      created_at: new Date(),
      created_by: this.userInfo.id,
      updated_at: null,
      updated_by: null,
      status: 1,
      business_id: this.businessProfile.id,
      post_type: 2,
      visibility: 1,
      display_order: 1,
      title: formValues.roleTitle,
      role_type: formValues.roleType,
      employment_type: formValues.employmentType,
      location: formValues.location,
      start_by: formValues.start_by,
      remote_work: formValues.remoteWork,
      hybrid_yn: formValues.hybrid_yn,
      description: formValues.roleDescription,
      salary: formValues.salary,
      hashtags: formValues.hashtags,
      day_rate: formValues.dayRate,
      benefits: formValues.benefits,
      soft_skills: formValues.softSkills,
      experience: formValues.experience,
      skills: formValues.skills,
      assessment_rounds: 1,
      culture_profile: formValues.cultureProfile,
      display_candidates: formValues.display_candidates
    }

    let response
    if (this.id !== undefined && this.id !== null && this.id !== '') {
      //Update Record
      post.id = this.id
      await this.roleService.updateARolePost(post).subscribe({
        next: async (data) => {
          response = data.data
          this.postAttachmentId = post?.id
          await updatePostAttachment()
          this.updatePersonalityRank(post?.id)
          this.event.emit('submit')
          this.bsModalService.hide()
        },
        error: (error) => {
          console.error(error)
        }
      })
    } else {
      //Insert Record
      await this.roleService.postARole(post).subscribe({
        next: async (data) => {
          response = data.data
          this.postAttachmentId = data?.data?.id
          await updatePostAttachment()
          this.updatePersonalityRank(data?.data?.id)
          this.showSuccessModal(data.data)
          this.event.emit('submit')
          this.bsModalService.hide()
        },
        error: (error) => {
          console.error(error)
        }
      })
    }

    let updatePostAttachment = async () => {
      if (this.postAttachmentId) {
        post.id = this.postAttachmentId
        const postAttachmentJson: any = {
          status: 1,
          post_id: Number(this.postAttachmentId),
          display_order: 1
          // updated_at: null,
          // updated_by: null,
        }
        if (this.filesList.length > 0) {
          this.filesList?.map(async (data: any) => {
            postAttachmentJson['file_name'] = data.file_name
            postAttachmentJson['file_path'] = data.file_path
            postAttachmentJson['file_type'] = data.file_type
            data.post_id = this.postAttachmentId
            data.display_order = 1
            await this.roleService
              .createAttachment(this.postAttachmentId, postAttachmentJson)
              .toPromise()
          })
        }

        if (this.removedFiles.length > 0) {
          this.removedFiles.forEach(async (value) => {
            if (value.status == 3) {
              await this.roleService.updateAttachment(value.post_id, value.id, value).toPromise()
            }
          })
        }

        // this.SpinnerService.hide();

        // this.bsModalService.hide();

        if (typeof this.roleDetails !== 'undefined') {
          // this.SpinnerService.show();
          this.bsModalService.hide()
          setTimeout(() => {
            this.toastr.success('Role updated')
            this.SpinnerService.hide()
          }, 1500)
          // this.router.navigate(["/dashboards/view-role"], {
          //   state: { roleInfo: post, edit: true },
          // });
        } else {
          /**
          await this.CRUDService.getDataByField(
            "BusinessPosts",
            "business_id",
            this.businessProfile.id
          ).subscribe((data: any) => {
            if (data.length > 0) {
              this.bsModalService.hide();
              this.SpinnerService.hide();

              const createdBusinessPost = data.find((value) => {
                return value.title === this.postRoleForm.get("role").value;
              });

              this.dialogRef = this.dialog.open(RolePostSuccessModalComponent, {
                panelClass: "role-model-post",
                width: "45vw",
                autoFocus: "false",
                data: {
                  roleLink: `${environment.appRoot.replace("www.", "")}${
                    environment.isOldRoleLink
                      ? "/view-job/"
                      : "/authentication/apply-job/"
                  }${createdBusinessPost.token}`,
                },
              });

              this.dialogRef.afterClosed().subscribe(() => {
                this.router.navigate(["/dashboards/roles"]);
              });
              //this.sendMailToUser(createdBusinessPost);
            }
          });
           */
        }
      } else {
        this.toastr.error(response.message)
        this.SpinnerService.hide()
      }
    }
  }

  updatePersonalityRank(postId) {
    const rolePersonalityRanking = {
      id: this.rolePersonalityRankingId,
      post_id: postId ? postId : this.roleDetails?.id,
      rank_1_trait: '',
      rank_2_trait: '',
      rank_3_trait: '',
      rank_4_trait: '',
      rank_5_trait: '',
      rank_6_trait: ''
    }

    this.personalityTypeDetails.forEach((value) => {
      rolePersonalityRanking[`rank_${value.rank}_trait`] = value.archetype_code
    })

    if (!this.rolePersonalityRankingId) {
      this.roleService.savePersonalityRanking(rolePersonalityRanking).toPromise()
    } else {
      this.roleService.updatePersonalityRanking(rolePersonalityRanking).toPromise()
    }
  }

  showSuccessModal(data) {
    this.dialogRef = this.dialog.open(RolePostSuccessModalComponent, {
      panelClass: 'role-model-post',
      width: '45vw',
      autoFocus: 'false',
      data: {
        roleLink: `${environment.appRoot.replace('www.', '')}${
          environment.isOldRoleLink ? '/view-job/' : '/authentication/apply-job/'
        }${data.id}`
      }
    })
    this.dialogRef.afterClosed().subscribe(() => {
      this.router.navigate(['/dashboards/roles'])
    })
  }

  async sendMailToUser(roleDetails) {
    const profileData = JSON.parse(sessionStorage.getItem('USER_INFO'))
    const businessData = JSON.parse(sessionStorage.getItem('BUSINESS_PROFILE'))

    let body: any = {
      link: `${environment.appRoot.replace('www.', '')}${
        environment.isOldRoleLink ? '/view-job/' : '/authentication/apply-job/'
      }${roleDetails.id}`,
      to: profileData.email,
      name: businessData.name
    }

    if (environment.test) {
      body.cc = ['pri@upplft.com', 'pri@upplft.com']
    } else {
      body.bcc = ['neil@upplft.com', 'christian@upplft.com', 'adam@upplft.com']
    }

    await this.CRUDService.sendMail(body).subscribe((data: any) => {
      if (data.success) {
        this.toastr.success('Mail sent successfully')
      }
    })
  }

  async uploadImageProfile(event: any, srcUrl: any) {
    this.SpinnerService.show()
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0]
      await this.SpinnerService.show()
      if (file.size <= environment.imageDocSize && (srcUrl === 'image' || srcUrl === 'doc')) {
        await this.uploadFile(file, srcUrl)
      } else if (
        file.size <= environment.audioVideoSize &&
        (srcUrl === 'video' || srcUrl === 'audio')
      ) {
        await this.uploadFile(file, srcUrl)
      } else {
        await this.SpinnerService.hide()
        this.toastr.warning(environment.fileErrorMessage)
      }
    }
  }
  async uploadImageDropProfile(event: any, srcUrl: any) {
    this.SpinnerService.show()
    if (
      event.addedFiles &&
      event.addedFiles[0] &&
      (event.addedFiles[0].type == 'image/jpeg' ||
        event.addedFiles[0].type == 'image/png' ||
        event.addedFiles[0].type == 'image/gif' ||
        event.addedFiles[0].type == 'image/webp')
    ) {
      const file = event.addedFiles[0]
      await this.SpinnerService.show()
      if (file.size <= environment.imageDocSize && (srcUrl === 'image' || srcUrl === 'doc')) {
        if (srcUrl === 'image') {
          await this.uploadFile(file, srcUrl)
        } else if (srcUrl === 'doc') {
          await this.uploadAudioVideoDoc(file, srcUrl)
        }
      } else if (
        file.size <= environment.audioVideoSize &&
        (srcUrl === 'video' || srcUrl === 'audio')
      ) {
        await this.uploadAudioVideoDoc(file, srcUrl)
      } else {
        await this.SpinnerService.hide()
        this.toastr.warning(environment.fileErrorMessage)
      }
    } else {
      this.toastr.warning(environment.imageErrorMessage)
      this.SpinnerService.hide()
    }
  }
  async uploadFile(file: any, srcUrl: any) {
    await this.SpinnerService.show()

    await this.s3Service
      .uploadFile(file, `business/${this.businessProfile.id}/${this.userInfo.id}`)
      .then(async (res) => {
        const urlConst = res.Location
        const json = {
          file_url: urlConst
        }
        if (srcUrl === 'image') {
          this.imageSrc.push(json)
          this.resImageSrc.push(res.Location)
        } else if (srcUrl === 'video') {
          this.videoUrl.push(json)
          this.resVideoUrl.push(res.Location)
        } else if (srcUrl === 'audio') {
          this.audioUrl.push(json)
          this.resAudioUrl.push(res.Location)
        } else if (srcUrl === 'doc') {
          this.docUrl.push({ file_name: file.name, url: res.Location })
          this.resDocUrl.push(json)
          // this.readExcel(file)
        }
        const fileJson = {
          file_name: file.name,
          file_type: srcUrl,
          file_path: res.Location
        }
        this.filesList.push(fileJson)
      })
      .finally(async () => {
        await this.SpinnerService.hide()
      })
  }

  uploadAudioVideoDoc(event, srcUrl: string) {
    const file = event.target.files[0]

    this.s3Service
      .uploadFile(file, `business/${this.businessProfile.id}/${this.userInfo.id}`)
      .then((response) => {
        this.SpinnerService.hide()
        const urlConst = response.Location
        const json = {
          file_url: urlConst
        }
        if (srcUrl === 'video') {
          this.videoUrl.push(json)
          this.resVideoUrl.push(response.Location)
        } else if (srcUrl === 'audio') {
          this.audioUrl.push(json)
          this.resAudioUrl.push(response.Location)
        } else if (srcUrl === 'doc') {
          this.docUrl.push({ file_name: file.name, url: response.Location })
          this.resDocUrl.push(json)
          // this.readExcel(file)
        }

        const fileJson = {
          file_name: file.name,
          file_type: srcUrl,
          file_path: response.Location
        }
        this.filesList.push(fileJson)
      })
      .catch((error) => {
        console.error(error)
      })
      .finally(async () => {
        this.SpinnerService.hide()
      })
  }

  // readExcel(file) {
  //   let readFile = new FileReader();
  //   readFile.onload = (e) => {
  //     this.storeData = readFile.result;
  //     var data = new Uint8Array(this.storeData);
  //     var arr = new Array();
  //     for (var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
  //     var bstr = arr.join("");
  //     var workbook = XLSX.read(bstr, { type: "binary" });
  //     var first_sheet_name = workbook.SheetNames[0];
  //     this.worksheet = workbook.Sheets[first_sheet_name];
  //   }
  //   readFile.readAsArrayBuffer(file);
  // }
  downloadFile(doc: any) {
    // S3: remove legacy url prepend
    // The domain will be included in the new URL
    const route = doc
    window.open(route)
  }
  selectOption(option: any) {
    this.option = option
  }

  // function index(index: any, arg1: number) {
  //   throw new Error('Function not implemented.');
  // }
  companyValueChange() {
    // const filterValue = this.companyValue.toLowerCase();
    // this.companyFilteredOptions = this.searchCompanyValue.filter(option => option.toLowerCase().includes(filterValue));

    // let index = this.companyFilteredOptions.findIndex(
    //   (val) => val === this.saveSkills
    // );
    this.skillsArray.sort()
    // if (index > -1) {
    //   this.disableAddCompanyValue = false;
    // } else {
    //   this.disableAddCompanyValue = true;
    // }
    if (this.saveSkills) {
      this.companyFilteredOptions = this.skillsArray.filter((option) =>
        option.toLowerCase().includes(this.saveSkills.toLowerCase())
      )
    } else {
      this.companyFilteredOptions = this.skillsArray
    }
  }
  selectBenefits() {
    if (this.saveBenefits === 'Other' || this.saveBenefits === 'other') {
      this.otherBenefitsFlag = true
    } else {
      this.otherBenefitsFlag = false
    }
  }
  addBenefits() {
    // this.addedBenefitsArray?.push(this.saveBenefits);
    // let index = this.benefitsArray.findIndex(val => (val === this.saveBenefits));
    // this.saveBenefits = null;
    // this.benefitsArray.splice(index, 1);
    if (this.saveBenefits === 'Other' || this.saveBenefits === 'other') {
      if (!this.addedBenefitsArray.includes(this.otherbenfits)) {
        this.addedBenefitsArray?.push(this.otherbenfits)
        this.saveBenefits = ''
        this.otherBenefitsFlag = false
        this.otherbenfits = ''
      } else {
        this.toastr.warning('Value is already exists')
      }
    } else {
      if (!this.addedBenefitsArray.includes(this.saveBenefits)) {
        this.addedBenefitsArray?.push(this.saveBenefits)
        let index = this.benefitsArray.findIndex((val) => val === this.saveBenefits)
        this.saveBenefits = null
        this.benefitsArray.splice(index, 1)
      } else {
        this.toastr.warning('Value is already exists')
        this.saveBenefits = ''
      }
    }
  }

  selectRoleType(value) {
    if (value === 'Other (not listed)' || value === 'other (not listed)') {
      this.otherRoleTypeFlag = true
      this.saveRoleType = ''
    } else {
      this.otherRoleTypeFlag = false
    }
  }

  addSoftSkills() {
    if (!this.addedSoftSkillsArray.includes(this.saveSoftSkill)) {
      this.addedSoftSkillsArray.push(this.saveSoftSkill)
      let index = this.softSkillsArray.findIndex((val) => val === this.saveSoftSkill)
      this.softSkillsArray.splice(index, 1)
    } else {
      this.toastr.warning('Value is already exists')
      this.saveSoftSkill = ''
    }
  }

  removeSoftSkills(index: any) {
    let value = this.addedSoftSkillsArray[index]
    this.softSkillsArray.push(value)
    this.softSkillsArray = this.softSkillsArray.sort((a, b) => (a > b ? 1 : -1))
    this.addedSoftSkillsArray.splice(index, 1)
  }

  addDomainExp() {
    if (this.addedDomainAndExpArray?.includes(this.selectedDomain + '-' + this.selectedExp)) {
      this.toastr.warning('Value already exists')
      this.selectedDomain = ''
      this.selectedExp = ''
    } else {
      this.addedDomainAndExpArray.push(this.selectedDomain + '-' + this.selectedExp)
      let index = this.domainArray.findIndex((val) => val == this.selectedDomain)
      this.selectedDomain = null
      this.selectedExp = null
      this.domainArray.splice(index, 1)
    }
  }
  // let fg = this.createLanguageField();
  // this.languagesField.push(fg);

  removeDomainAndExp(index: any) {
    let value = this.addedDomainAndExpArray[index]
    let entry = value.split('-')
    let domain = entry[0]
    if (domain !== null && domain !== '') {
      this.domainArray.push(domain.trim())
    }
    this.domainArray = this.domainArray.sort((a, b) => (a > b ? 1 : -1))
    this.addedDomainAndExpArray.splice(index, 1)
    /* this.languagesField.removeAt(index)
    this.languagesArr.splice(index, 1)
    this.languagesProficiencyArr.splice(index, 1) */
  }

  valueChange() {
    // const filterValue = this.companyValue.toLowerCase();
    // this.companyFilteredOptions = this.searchCompanyValue.filter(option => option.toLowerCase().includes(filterValue));

    // let index = this.domainFilteredValue.findIndex(
    //   (val) => val === this.selectedDomain
    // );
    this.domainArray.sort()
    // if (index > -1) {
    //   this.disableAddCompanyValue = false;
    // } else {
    //   this.disableAddCompanyValue = true;
    // }
    if (this.selectedDomain) {
      this.domainFilteredValue = this.domainArray.filter((option) =>
        option.toLowerCase().includes(this.selectedDomain.toLowerCase())
      )
    } else {
      this.domainFilteredValue = this.domainArray
    }
  }

  // formatLabel(value: number) {
  //   if (value >= 10000) {
  //     return Math.round(value / 10000) + 'k';
  //   }

  //   return value;
  // }

  saveSoftskill() {
    this.saveSoftSkill = this.addedSoftSkillsArray?.[0]
  }

  rangeChange(event) {
    this.salary = '$' + event.value + 'k' + '-' + '$' + event.highValue + 'k'
  }

  changeSalary() {
    if (this.roleDetails.salary !== undefined && this.roleDetails.salary !== null) {
      let salary: any = this.roleDetails.salary.replaceAll('$', '')
      salary = salary.replaceAll('k', '')
      salary = salary.split('-')
      this.min = salary?.[0]
      this.max = salary?.[1]
    }
  }

  async getBusinessPostAttachments() {
    const attachmentsResponse = await this.roleService
      .getRoleAttachmentsByRoleId(this.roleDetails.id)
      .toPromise()

    this.postAttachmentArray = attachmentsResponse.data.items

    if (this.postAttachmentArray !== undefined && this.postAttachmentArray.length !== 0) {
      for (let i = 0; i < this.postAttachmentArray.length; i++) {
        if (
          this.postAttachmentArray[i].status !== 3 &&
          this.postAttachmentArray[i].status !== '3'
        ) {
          if (this.postAttachmentArray[i].file_type == 'image') {
            this.postAttachmentArray[i]['file_url'] = this.postAttachmentArray?.[i]?.file_path
            this.imageSrc.push(this.postAttachmentArray[i])
          } else if (this.postAttachmentArray[i].file_type == 'video') {
            this.postAttachmentArray[i]['file_url'] = this.postAttachmentArray?.[i]?.file_path
            this.videoUrl.push(this.postAttachmentArray[i])
          } else if (this.postAttachmentArray[i].file_type == 'audio') {
            this.postAttachmentArray[i]['file_url'] = this.postAttachmentArray?.[i]?.file_path
            this.audioUrl.push(this.postAttachmentArray[i])
          } else if (this.postAttachmentArray[i].file_type == 'doc') {
            this.postAttachmentArray[i]['file_url'] = this.postAttachmentArray?.[i]?.file_path
            this.docUrl.push(this.postAttachmentArray[i])
          }
        }
      }
    }
  }

  removeImg(index: any) {
    this.imageSrc.splice(index, 1)
  }

  removeDoc(index) {
    this.docUrl.splice(index, 1)
  }

  removeVideo(index) {
    this.videoUrl.splice(index, 1)
  }

  removeAudio(index) {
    this.audioUrl.splice(index, 1)
  }

  async removeAttachment(file) {
    file.status = 3
    this.removedFiles.push(file)
    // let response = await this.commonService.updateRecord(
    //   "BusinessPostAttachments",
    //   file
    // );
    // if (response.success) {
    //   this.getBusinessPostAttachments();
    // }
  }

  disableLocation(location): boolean {
    return this.location?.length >= 5 && !this.location?.find((ele) => ele == location)
  }

  getStatusDesc(status: any) {
    if (status === '1') {
      return 'Active'
    } else if (status === '4') {
      return 'Closed'
    } else if (status === '3') {
      return 'Hold'
    } else if (status === '2') {
      return 'Deleted'
    }
  }

  async reOpenRole() {
    const json = {
      title: this.roleDetails.title,
      role_type: this.roleDetails.role_type,
      employment_type: this.roleDetails.employment_type,
      start_by: this.roleDetails.start_by,
      remote_work: this.roleDetails.remote_work,
      description: this.roleDetails.description,
      location: this.roleDetails.location,
      salary: this.roleDetails.salary,
      benefits: this.roleDetails.benefits,
      soft_skills: this.roleDetails.soft_skills,
      experience: this.roleDetails.experience,
      skills: this.roleDetails.skills,
      day_rate: this.roleDetails.day_rate,
      hashtags: '',
      visibility: 1,
      status: '1',
      business_id: this.businessProfile.id,
      post_type: '2',
      assessment_rounds: '1',
      display_order: '1',
      id: this.roleDetails.id
    }
    const response = await this.roleService.updateARolePost(json).toPromise()
    if (response.success === true) {
      this.roleStatusDesc = this.getStatusDesc(json.status)
      this.roleDetails.status = json.status
      this.bsModalService.hide()
      this.toastr.success('Role reopened')
    }
  }

  async holdPosting() {
    const json = {
      title: this.roleDetails.title,
      role_type: this.roleDetails.role_type,
      employment_type: this.roleDetails.employment_type,
      start_by: this.roleDetails.start_by,
      remote_work: this.roleDetails.remote_work,
      description: this.roleDetails.description,
      location: this.roleDetails.location,
      salary: this.roleDetails.salary,
      benefits: this.roleDetails.benefits,
      soft_skills: this.roleDetails.soft_skills,
      experience: this.roleDetails.experience,
      skills: this.roleDetails.skills,
      day_rate: this.roleDetails.day_rate,
      hashtags: '',
      visibility: 1,
      status: '3',
      business_id: this.businessProfile.id,
      post_type: '2',
      assessment_rounds: '1',
      display_order: '1',
      id: this.roleDetails.id
    }
    const response = await this.roleService.updateARolePost(json).toPromise()
    if (response.success === true) {
      this.roleStatusDesc = this.getStatusDesc(json.status)
      this.roleDetails.status = json.status
      this.bsModalService.hide()
      this.toastr.success('Role put on hold')
    }
  }

  async closePosting() {
    const json = {
      title: this.roleDetails.title,
      role_type: this.roleDetails.role_type,
      employment_type: this.roleDetails.employment_type,
      start_by: this.roleDetails.start_by,
      remote_work: this.roleDetails.remote_work,
      description: this.roleDetails.description,
      location: this.roleDetails.location,
      salary: this.roleDetails.salary,
      benefits: this.roleDetails.benefits,
      soft_skills: this.roleDetails.soft_skills,
      experience: this.roleDetails.experience,
      skills: this.roleDetails.skills,
      day_rate: this.roleDetails.day_rate,
      hashtags: '',
      visibility: 1,
      status: '4',
      business_id: this.businessProfile.id,
      post_type: '2',
      assessment_rounds: '1',
      display_order: '1',
      id: this.roleDetails.id
    }
    const response = await this.roleService.updateARolePost(json).toPromise()
    if (response.success === true) {
      this.roleStatusDesc = this.getStatusDesc(json.status)
      this.roleDetails.status = json.status
      this.bsModalService.hide()
      this.toastr.success('Role closed')
      // this.router.navigate(['/dashboards/role-details']);
    }
  }

  openDeleteModal(targetModal: any, target: any) {
    this.bsModalRef1 = this.bsModalService.show(targetModal)
    this.target = target
  }

  async deleteRole() {
    if (this.target == 'role') {
      const json = {
        title: this.roleDetails.title,
        role_type: this.roleDetails.role_type,
        employment_type: this.roleDetails.employment_type,
        start_by: this.roleDetails.start_by,
        remote_work: this.roleDetails.remote_work,
        description: this.roleDetails.description,
        location: this.roleDetails.location,
        salary: this.roleDetails.salary,
        benefits: this.roleDetails.benefits,
        soft_skills: this.roleDetails.soft_skills,
        experience: this.roleDetails.experience,
        skills: this.roleDetails.skills,
        day_rate: this.roleDetails.day_rate,
        hashtags: '',
        visibility: 1,
        status: '2',
        business_id: this.businessProfile.id,
        post_type: '2',
        assessment_rounds: '1',
        display_order: '1',
        id: this.roleDetails.id
      }
      const response = await this.roleService.updateARolePost(json).toPromise()
      if (response.success === true) {
        this.roleStatusDesc = this.getStatusDesc(json.status)
        this.roleDetails.status = json.status
        this.bsModalService.hide()
        this.toastr.success('Role deleted')
        // this.router.navigate(['/dashboards/roles']);
      }
    }
    // else if (this.target == 'attachment') {
    //   this.removeAttachment();
    // }
  }

  viewShortlistedCandidates(data) {
    const initialState: any = {
      status: '1',
      roleDetails: data,
      businessProfile: this.businessProfile,
      title: 'Shortlisted Candidates'
    }

    this.bsModalService.show(RolesCandidateDataComponent, {
      ariaLabelledBy: 'modal-basic-title',
      class: 'modal-height modal-xl candidate-data',
      backdrop: 'static',
      initialState
    })
  }

  viewRegisteredCandidates() {
    // const businessPostCandidates = this.businessPostCandidates?.filter((value) => { return (!value?.action_status || value?.action_status == 1) && (value.status == 1 && value.post_id == this.roleDetails?.id) })
    this.dialog.open(CandidatesComponent, {
      panelClass: 'mat-candidate-profile',
      data: {
        businessPostCandidates: this.businessPostCandidates,
        candidate: 'registerd_candidates',
        businessProfile: this.businessProfile,
        roleDetails: this.roleDetails
      },
      autoFocus: false
    })
  }
  viewAppliedCandidates() {
    // const businessPostCandidates = this.businessPostCandidates?.filter((value) => { return value.action_status == 4 && value.status == 1 && value?.post_id == this.roleDetails?.id });
    this.dialog.open(CandidatesComponent, {
      panelClass: 'mat-candidate-profile',
      data: {
        businessPostCandidates: this.businessPostCandidates,
        candidate: 'applied_candidates',
        businessProfile: this.businessProfile,
        roleDetails: this.roleDetails
      },
      autoFocus: false
    })
  }

  onEmpTypeChange(event) {
    if (event?.length === 1 && event[0] === 'Contracting Role') {
      this.showSalarySlider = false
    } else {
      this.showSalarySlider = true
    }
  }

  isStringifiedJson(str: string): boolean {
    try {
      JSON.parse(str)
      return true
    } catch (error) {
      return false
    }
  }

  closeDialog() {
    this.dialogRef.close()
  }
}
