import { Component, OnInit } from '@angular/core'
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal'
import { CRUDService } from 'src/app/crud.service'

import { CommonService } from 'src/app/common.service'

import { ToastrService } from 'ngx-toastr'
import { RoleService } from 'src/app/shared/services/role.service'

@Component({
  selector: 'app-recruitment-process',
  templateUrl: './recruitment-process.component.html',
  styleUrls: ['./recruitment-process.component.scss']
})
export class RecruitmentProcessComponent implements OnInit {
  displayedColumns: string[] = ['round_no', 'round_name', 'actions']
  roundData: any = []
  status
  roleDetails: any
  title: any
  bsModalRef: BsModalRef
  roleData: any

  roleId: any
  processData: any
  flag = true
  addnew = false

  constructor(
    private bsModalService: BsModalService,
    private CRUDService: CRUDService,
    public options: ModalOptions,
    private commonService: CommonService,
    private roleService: RoleService,
    private toastr: ToastrService
  ) {}

  async ngOnInit(): Promise<void> {
    this.roleDetails = this.options.initialState.data
    this.roleId = this.roleDetails?.id
    this.loadData()
  }

  async saveRecords() {
    let status = true
    // let isDetailsNotFilled = false;
    let changeList = this.processData.filter((val) => val.changed == true)
    changeList.forEach(async (values, index) => {
      if (values.round_no && values.round_name) {
        let response = await this.roleService
          .createAssessmentRoundForRole(this.roleId, values)
          .toPromise()
        if (response.success) {
        } else {
          status = false
        }
      }

      if (index == changeList.length - 1) {
        if (status) {
          this.toastr.success('Round Details Added')
        } else {
          this.toastr.warning('Round Details Not Updated')
        }
      }
      this.loadData()
    })
  }

  async deleteRecords(element, index) {
    if (element.id) {
      element.status = 2
      let response = await this.roleService
        .updateAssessmentRoundForRole(this.roleId, element.id, element)
        .toPromise()
      if (response.success) {
        this.toastr.success('Round details deleted successfully')
        this.loadData()
      } else {
        this.toastr.warning('Something went wrong, please try again later')
      }
    } else {
      const data = this.processData.slice(0, index).concat(this.processData.slice(index + 1))
      this.processData = [...data]
    }
  }

  async loadData() {
    const processData = await this.roleService.getAssessmentRoundsForRole(this.roleId).toPromise()
    this.processData = processData.data.items.filter((value) => {
      return value?.status == '1'
    })
  }

  addNewRecords() {
    const newvalue = {
      created_at: null,
      created_by: null,
      round_no: null,
      round_name: null,
      role_id: this.roleId,
      id: null,
      status: 1,
      updated_at: null,
      updated_by: null
    }
    this.processData = [newvalue, ...this.processData]
  }

  closeModal() {
    this.bsModalService.hide()
  }
}
