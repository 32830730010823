import { Component, Inject, OnInit } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router'
import { CRUDService } from 'src/app/crud.service'
import { MatDialogRef, MatDialogConfig, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { ScheduleEventNewComponent } from 'src/app/scheduler/schedule-calendar/schedule-event-new/schedule-event-new.component'
import { NullTemplateVisitor } from '@angular/compiler'
import { Subject } from 'rxjs'
import { TimeSlots, TimeSlotsList } from '../schedule-calendar/event-utils'
import { CommonService } from 'src/app/common.service'
import { getLoggedInUser } from 'src/app/common-utils'
import { ToastrService } from 'ngx-toastr'
import { RoleService } from 'src/app/shared/services/role.service'
import { BusinessService } from 'src/app/shared/services/business.service'

@Component({
  selector: 'app-update-schedule',
  templateUrl: './update-schedule.component.html',
  styleUrls: ['./update-schedule.component.scss']
})
export class UpdateScheduleComponent implements OnInit {
  title: any
  action: any
  eventId: any
  eventInfo: any = {}
  meeting_date: any
  start_time: TimeSlots
  redirectTo: any = 'Login'
  duration: any
  linkText: any
  roleInfo: any
  dialogRef: MatDialogRef<ScheduleEventNewComponent> = Object.create(NullTemplateVisitor)
  refresh: Subject<any> = new Subject()
  eventDetails: any
  id: string
  // bsModalRef: BsModalRef;

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private CrudService: CRUDService,
    private roleService: RoleService,
    private businessService: BusinessService,
    private route: ActivatedRoute,
    private CommonService: CommonService,
    @Inject(MAT_DIALOG_DATA) private data,
    private dialogRef1: MatDialogRef<UpdateScheduleComponent>,
    private toastr: ToastrService
  ) {}

  async ngOnInit() {
    if (this.data?.data !== undefined && this.data !== null) {
      this.eventDetails = this.data.data
      this.id = this.eventDetails.extendedProps.event_id
      this.action = this.eventDetails.action
    }

    if (this.route.snapshot.paramMap.get('param_1')) {
      this.action = this.route.snapshot.paramMap.get('param_1')
    }
    if (this.route.snapshot.paramMap.get('param_2')) {
      this.eventId = this.route.snapshot.paramMap.get('param_2')
      this.id = this.CommonService.decryptData(decodeURIComponent(this.eventId))
    }
    let userInfo = getLoggedInUser()
    if (!userInfo) {
      this.router.navigate(['/authentication/login-option'])
      // let adminUser: any =
      //   await this.CrudService.getAdminUserInfo().toPromise();
      // sessionStorage.setItem("USER_INFO", JSON.stringify(adminUser[0]));
    } else {
      this.redirectTo = 'Scheduler'
    }
    let eventData = await this.roleService.getRoleCandidateProxyRelation(this.id).toPromise()
    this.eventInfo = eventData.data
    let postsData = await this.roleService.getRoleById(this.eventInfo.post_id).toPromise()
    //sessionStorage.setItem('USER_INFO', JSON.stringify(userData[0]));
    this.roleInfo = postsData.data
    if (this.eventInfo !== undefined) {
      this.meeting_date = new Date(this.eventInfo.slot_start_time)
      this.start_time = TimeSlotsList.find(
        (val: TimeSlots) => val.value === this.eventInfo.start_time
      )!
      this.duration = '30 Mins'
      if (this.action === 'reschedule') {
        this.title = 'Are you sure you want to Re-Schedule the meeting?'
        this.linkText = 'Pick Slot'
      } else if (this.action === 'cancel') {
        this.title = 'Are you sure, you want to cancel the Scheduled Meeting?'
        this.linkText = 'Yes, Cancel'
      } else {
        this.title = 'Unable to fetch Meeting Details. Please check the calendar for details.'
        this.linkText = 'Log In'
      }
    } else {
      this.meeting_date = 'Unknown'
      this.start_time = { viewValue: 'Unknwon', value: 'Unknown' }
      this.duration = 'Unknown'
      this.title = 'Unable to fetch Meeting Details. Please check the calendar for details.'
      this.linkText = 'Log In'
    }
  }

  confirm() {
    if (this.action === 'reschedule') {
      this.initiateReschedule()
    } else if (this.action === 'cancel') {
      this.cancel()
    } else {
    }
  }
  config: MatDialogConfig = {
    disableClose: true,
    width: '75%',
    height: '',
    position: {
      top: '',
      bottom: '',
      left: '',
      right: ''
    },
    data: {
      action: '',
      event: []
    }
  }

  async initiateReschedule() {
    this.eventInfo.action_status = 4
    let data = await this.roleService.getEventsForRole(this.roleInfo.id).toPromise()
    let businessPostSlots = data.data.items
    let businessdata = await this.businessService.getBusiness(this.roleInfo.business_id).toPromise()
    this.dialogRef = this.dialog.open(ScheduleEventNewComponent, {
      panelClass: 'calendar-event-dialog',
      data: {
        business_info: businessdata.data,
        job_info: this.roleInfo,
        availability: businessPostSlots,
        businessPostCandidateInfo: this.eventInfo,
        isReschedule: true
      },
      autoFocus: false
    })

    this.dialogRef.afterClosed().subscribe(async (res) => {
      if (!res) {
        return
      } else {
        /*  this.SpinnerService.show();
        await this.CommonService.cancelTeamsMeeting(this.eventInfo);
        this.SpinnerService.hide(); */
        if (this.redirectTo == 'Login') {
          this.router.navigate(['/authentication/login-option'])
        } else {
          this.dialogRef1.close('closed')
          // this.router.navigate(['/scheduler']);
          // this.bsModalRef = this.bsModalService.show(
          //   ScheduleCalendarComponent,
          //   {
          //     ariaLabelledBy: "modal-basic-title",
          //     class: "modal-height modal-xl candidate-data",
          //     backdrop: "static",
          //   }
          // );
        }
        //this.CommonService.cancelTeamsMeeting(this.eventInfo);
      }
      /* const dialogAction = res.action;
      const responseEvent = res.event; */
      this.dialogRef = Object.create(null)
      this.refresh.next()
    })
  }

  closeDialog() {
    this.dialogRef1.close()
  }

  async cancel() {
    const businessPostCandidate = JSON.parse(JSON.stringify(this.eventInfo))
    // TODO: cancel teams meeting
    // this.CommonService.cancelTeamsMeeting(businessPostCandidate);
    this.eventInfo.action_status = 5
    this.eventInfo.slot_start_time = ''
    this.eventInfo.slot_end_time = ''
    this.eventInfo.meeting_id = ''
    await this.roleService.updateRoleCandidate({
      role_id: this.eventInfo.role_id,
      user_id: this.eventInfo.user_id,
      body: this.eventInfo
    })
    this.toastr.success('Scheduled Meeting has ben cancelled and notifications have been sent.')
    if (this.redirectTo == 'Login') {
      this.router.navigate(['/authentication/login-option'])
    } else {
      this.dialogRef1.close('closed')
      // this.router.navigate(['/scheduler']);
      // this.bsModalRef = this.bsModalService.show(ScheduleCalendarComponent, {
      //   ariaLabelledBy: "modal-basic-title",
      //   class: "modal-height modal-xl candidate-data",
      //   backdrop: "static",
      // });
    }
  }
}
