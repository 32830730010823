<div
  class="modal-content"
  style="padding: 10px 20px; max-height: fit-content"
  oncontextmenu="return false;"
>
  <div class="modal-header">
    <h4 class="modal-title">{{ title | titlecase }}</h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      data-dismiss="modal"
      (click)="closeModal()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="body table-height" fxLayout="row wrap">
      <div
        fxFlex.gt-lg="100"
        fxFlex.gt-md="100"
        fxFlex.gt-xs="100"
        fxFlex="100"
        class="overflow-auto h-100"
      >
        <table class="table">
          <thead>
            <th>Type</th>
            <th>Name</th>
            <th>Photo</th>
            <th *ngIf="viewSpiritAnimal">Spirit Animal</th>
            <th>Personality</th>
            <th>Culture</th>
            <!-- <th>Goals</th> -->
            <th>Soft Skills</th>
            <th>Match %</th>
            <th>Status</th>
            <th>Action</th>
          </thead>
          <tbody>
            <tr *ngFor="let data of candidateData; let i = index">
              <td data-label="S.No">
                {{ data.eventData.shortlist_type ? data.eventData.shortlist_type : '-' }}
              </td>
              <td data-label="Name">
                <div *ngIf="data.eventData.action_status == 4">
                  {{ data.name ? data.name : '-' }}
                </div>
                <div *ngIf="data.eventData.action_status != 4">*************</div>
              </td>
              <td data-label="Photo">
                <div>
                  <img
                    *ngIf="data.eventData.action_status == 4"
                    class="animal-img"
                    style="width: 50px; background-color: transparent"
                    src="{{ data?.image_url }}"
                  />
                </div>
                <div *ngIf="data.eventData.action_status != 4">******************</div>
              </td>
              <td *ngIf="viewSpiritAnimal" data-label="Spirit Animal">
                <img
                  class="animal-img"
                  style="width: 130px; background-color: transparent"
                  src="'../../../../assets/personality/{{
                    data?.personality_type?.toLowerCase()
                  }}.png"
                /><br />
                {{ data.personality_desc ? data.personality_desc : +'-' }}
              </td>
              <td data-label="Personality">{{ data.personality ? data.personality : '-' }}</td>
              <td data-label="Culture">
                <mat-chip-list aria-label="Culture Selection">
                  <span *ngIf="cultureFn(data.culture).length > 0">
                    <mat-chip *ngFor="let culture of cultureFn(data.culture)">{{
                      culture
                    }}</mat-chip>
                  </span>
                  <span *ngIf="cultureFn(data.culture).length == 0">-</span>
                </mat-chip-list>
              </td>
              <!-- <td data-label="Goals" class="break-word">{{data.goals}}</td> -->
              <td data-label="Skills">
                <mat-chip-list aria-label="Skills Selection">
                  <span *ngIf="skillsFn(data.skills).length > 0">
                    <mat-chip *ngFor="let skill of skillsFn(data.skills)" class="chipactive">
                      {{ skill }}
                    </mat-chip>
                  </span>
                  <span *ngIf="skillsFn(data.skills).length == 0"> - </span>
                </mat-chip-list>
              </td>
              <td data-label="Match">
                <label class="profile-completion">{{ data.matchPerc | number: '1.0-0' }}%</label>
              </td>
              <td data-label="Status">
                {{ statusFn(data?.eventData) ? statusFn(data?.eventData) : '-' }}
              </td>
              <td data-label="Action" class="break-word">
                <button
                  mat-raised-button
                  class="add-post-role-btn shortListButton"
                  *ngIf="data.businessData?.length === 0"
                  (click)="viewCalendarModal()"
                  style="
                    background: #2cb49e;
                    color: white;
                    border-radius: 20px;
                    width: 127px;
                    height: 36px;
                  "
                >
                  Invite to meet
                </button>

                <span class="d-flex">
                  <mat-icon
                    *ngIf="data.businessData?.length !== 0"
                    color="warn"
                    matTooltip="Profile"
                    (click)="openViewProfile(data)"
                    >person</mat-icon
                  >
                  <mat-icon
                    *ngIf="data.businessData?.length !== 0"
                    color="accent"
                    matTooltip="Schedule"
                    (click)="viewSchedule(data)"
                    >calendar_today</mat-icon
                  >
                  <span
                    mat-raised-button
                    class="dropdown-toggle edit-post-role-btn"
                    matTooltip="More"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    *ngIf="data.businessData?.length !== 0"
                  >
                    <mat-icon color="primary">more_vert</mat-icon>
                  </span>
                  <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    <a class="dropdown-item" (click)="openOffer()">Offer</a>

                    <a class="dropdown-item" (click)="openNextSchedule(data)"
                      >Schedule Next Round</a
                    >

                    <a class="dropdown-item" (click)="toggleReject(data)">Reject</a>
                  </div>
                </span>

                <!-- <button mat-raised-button class="add-post-role-btn shortListButton"
                                    *ngIf="data.businessData?.length !== 0" (click)="openViewProfile(data)"
                                    style="background: #2CB49E;color: white;border-radius: 20px;width: 127px;height: 36px;">
                                    View Profile
                                </button>
                                <button mat-raised-button class="add-post-role-btn shortListButton mt-2"
                                    *ngIf="data.businessData?.length !== 0" (click)="viewSchedule(data)"
                                    style="background: #007bff;color: white;border-radius: 20px;width: 127px;height: 36px;">
                                    View Schedule
                                </button> -->

                <div></div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
<!-- <div class="modal-content" style="padding:10px 20px;" oncontextmenu="return false;">
    <div class="modal-header">
        <h4 class="modal-title">{{title | titlecase}}</h4>
        <button type="button" class="close" aria-label="Close" data-dismiss="modal" (click)="closeModal()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">

        <div class="mat-elevation-z8" style="overflow-y: scroll;height: 500px !important;overflow-x: hidden;">
            <table mat-table [dataSource]="candidateData">
 
              <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef class="matThClass"> Id </th>
                <td mat-cell *matCellDef="let element" class="matTdClass"> {{element.id}} </td>
              </ng-container>

              <ng-container matColumnDef="image">
                <th mat-header-cell *matHeaderCellDef class="matThClass"> Image </th>
                <td mat-cell *matCellDef="let element" class="matTdClass"><img *ngIf="element?.image_url" src="{{element?.image_url}}"
                    class="logo-img"></td>
              </ng-container>

              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef class="matThClass"> Name </th>
                <td mat-cell *matCellDef="let element" class="matTdClass" style="width: 20%;text-align: center;"> {{element.name}} </td>
              </ng-container>

              <ng-container matColumnDef="culture">
                <th mat-header-cell *matHeaderCellDef class="matThClass"> Culture </th>
                <td mat-cell *matCellDef="let element" class="matTdDescription"> {{element.culture}} </td>
              </ng-container>

              <ng-container matColumnDef="location">
                <th mat-header-cell *matHeaderCellDef class="matThClass"> Location </th>
                <td mat-cell *matCellDef="let element" class="matTdClass"> {{element.location}} </td>
              </ng-container>
          
              <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky:true"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
          
            <mat-paginator [pageSizeOptions]="[5, 10, 20]"
                           showFirstLastButtons 
                           aria-label="Select page of periodic elements">
            </mat-paginator>
          </div>
          
    </div>
</div>
 -->
<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
  <p style="color: white">Please Wait.</p>
</ngx-spinner>
