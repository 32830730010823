<div class="modal-content ease-in">
  <div class="modal-header">
    <h1 class="circularBold signUpHeading">Wait, we need more information!</h1>
  </div>
  <!-- <div class="modal-header">
   
        <h6 class="circularBold signUpHeading"> Confirmation</h6> -->
  <!-- <button type="button" class="close ml-2" aria-label="Close" data-dismiss="modal" (click)="closeModal()">
            <span aria-hidden="true">&times;</span>
        </button> -->
  <!-- </div> -->
  <div class="modal-body">
    <!-- Your expected salary, day rate, preferred location are important for the
    businesses to understand how best you fit the role. Are you sure you want to
    exit without providing these details? -->

    <div fxLayout="row wrap">
      <div
        fxFlex.gt-sm="100"
        fxFlex.gt-xs="100"
        fxFlex="100"
        style="margin-top: 10px; display: flex"
      >
        <p class="description-text">
          To help us better match you to roles, please update the mandatory information on your
          profile which is marked with an asterisk * e.g. Salary, Domain experience.
          <br />
          <br />
          Are you sure you don't have time to quickly update these details?
        </p>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button mat-raised-button class="confirm-button" (click)="proceed()">Update my details</button>

    <button mat-raised-button class="cancle-button" (click)="cancel()">Exit anyway</button>
  </div>
</div>
