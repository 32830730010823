<div class="body" fxLayout="row wrap">
  <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
    <div class="row col-12 pl-0">
      <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12">
        <div class="postsBox">
          <div class="row col-12 text-center pr-0">
            <div
              [ngClass]="
                notificationIcon
                  ? 'col-xs-12 col-sm-12 col-md-11 col-lg-11 pl-0 pr-0 search'
                  : 'col-xs-12 col-sm-12 col-md-12 col-lg-12 pl-0 pr-0 search'
              "
            >
              <app-header-search-bar></app-header-search-bar>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-1 col-lg-1" *ngIf="notificationIcon">
              <app-header-notification-button [previousPath]="previousPath">
              </app-header-notification-button>
            </div>
          </div>
        </div>
        <hr />
        <div class="postsBox pl-4 pr-4 ease-in">
          <div class="mt-3 mb-3">
            <i
              class="fa fa-angle-left back-icon"
              aria-hidden="true"
              (click)="closeChangePaassword()"
            ></i>
            <label class="heading-text font-18">Change Password</label>
          </div>
          <div class="row col-12">
            <div class="col-md-12 col-sm-12 col-lg-8 mx-auto">
              <form
                [formGroup]="changePasswordForm"
                noValidate
                class="mt-4"
                (ngSubmit)="submitResetPassword()"
                autocomplete="off"
              >
                <mat-form-field
                  class="example-full-width"
                  style="margin-bottom: 10px; margin-top: 10px"
                  appearance="fill"
                >
                  <input
                    type="password"
                    matInput
                    [type]="currentpasswordHide ? 'password' : 'text'"
                    placeholder="Current Password"
                    formControlName="currentpassword"
                  />
                  <button
                    mat-icon-button
                    matSuffix
                    (click)="currentpasswordHide = !currentpasswordHide"
                    [attr.aria-label]="'Hide password'"
                    [attr.aria-pressed]="currentpasswordHide"
                  >
                    <mat-icon>{{ currentpasswordHide ? 'visibility_off' : 'visibility' }}</mat-icon>
                  </button>
                </mat-form-field>
                <div *ngIf="submitted && invalidCurrentPassword" class="alert error-message">
                  <span> Invalid Password </span>
                </div>
                <mat-form-field
                  class="example-full-width"
                  appearance="fill"
                  style="margin-top: 10px"
                >
                  <input
                    type="password"
                    matInput
                    [type]="passwordHide ? 'password' : 'text'"
                    (input)="showErrorAndSlider($event); checkPassword()"
                    placeholder="New Password"
                    formControlName="password"
                  />
                  <button
                    mat-icon-button
                    matSuffix
                    (click)="passwordHide = !passwordHide"
                    [attr.aria-label]="'Hide password'"
                    [attr.aria-pressed]="passwordHide"
                  >
                    <mat-icon>{{ passwordHide ? 'visibility_off' : 'visibility' }}</mat-icon>
                  </button>
                </mat-form-field>
                <div
                  *ngIf="submitted && changePasswordForm.controls['password'].errors"
                  class="alert error-message"
                >
                  <span *ngIf="changePasswordForm.controls['password'].errors.required">
                    Please Enter Password
                  </span>
                  <span *ngIf="changePasswordForm.controls['password'].errors.maxlength">
                    Please Enter
                    {{ changePasswordForm.controls['password'].errors.maxlength.requiredLength }}
                    characters
                  </span>
                  <span *ngIf="changePasswordForm.controls['password'].errors.pattern">
                    Password should be at least 8 characters long and should contain one number, one
                    lower case and one upper case character and one special character (e.g. * $ % !
                    #)
                  </span>
                </div>
                <mat-form-field
                  class="example-full-width mt-3"
                  style="margin-bottom: 10px"
                  appearance="fill"
                  [ngClass]="{ 'is-invalid': passwordFlag }"
                  (input)="checkPassword()"
                >
                  <input
                    type="password"
                    matInput
                    [type]="confirmPasswordHide ? 'password' : 'text'"
                    placeholder="Confirm New Password"
                    formControlName="confirmPassword"
                  />
                  <button
                    mat-icon-button
                    matSuffix
                    (click)="confirmPasswordHide = !confirmPasswordHide"
                    [attr.aria-label]="'Hide password'"
                    [attr.aria-pressed]="confirmPasswordHide"
                  >
                    <mat-icon>{{ confirmPasswordHide ? 'visibility_off' : 'visibility' }}</mat-icon>
                  </button>
                </mat-form-field>
                <div
                  class="alert error-message"
                  *ngIf="
                    passwordFlag &&
                    changePasswordForm.controls['password'].value != null &&
                    changePasswordForm.controls['password'].value != '' &&
                    changePasswordForm.controls['confirmPassword'].value != '' &&
                    changePasswordForm.controls['confirmPassword'].value != null
                  "
                >
                  <span class="error-class">Passwords don't match</span>
                </div>
                <div class="alert error-message" *ngIf="samePassword">
                  <span class="error-class">New Password cant be same as old one</span>
                </div>
                <div class="passprotect">
                  <password-strength-meter
                    [password]="changePasswordForm.controls['password'].value"
                    [colors]="color"
                  ></password-strength-meter>
                </div>
                <div style="margin-top: 10px">
                  <button
                    mat-raised-button
                    class="search-btn"
                    [disabled]="passwordFlag || invalidCurrentPassword || value < 5 || samePassword"
                  >
                    Confirm
                  </button>
                </div>
                <!-- <div class="text-center" style="margin-top:10px;">
                                    <label class="example-h2">Password Strength</label>
                                    <mat-slider style="pointer-events: none; margin-top: 8%;" class="example-margin"
                                        [displayWith]="formatLabel" [disabled]="disabled" [invert]="invert" [max]="max"
                                        [min]="min" [step]="step" [thumbLabel]="thumbLabel"
                                        [tickInterval]="getSliderTickInterval()" [(ngModel)]="value"
                                        [vertical]="vertical" [ngModelOptions]="{standalone : true}"
                                        aria-labelledby="example-name-label">
                                    </mat-slider>
                                </div> -->
              </form>
              <div class="mt-3 form-footer" style="margin-top: 10px">
                <label class="font-14"><strong>Need help?</strong></label>
                <label class="contact-label font-14 pl-1"
                  ><a href="https://www.upplft.com/#contact"><strong>Contact Us</strong></a></label
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 profile-div">
        <app-candidate-profile-overview-verified
          *ngIf="role === 'candidate'"
          style="margin-top: 90x"
        >
        </app-candidate-profile-overview-verified>
        <app-profile-overview-verified *ngIf="role === 'business'"></app-profile-overview-verified>
      </div>
    </div>
  </div>
</div>
