<div class="container ease-in" *ngIf="isShortTest">
  <div class="header-section">
    <button
      mat-icon-button
      class="icon-button"
      (click)="previousOption()"
      [disabled]="selectedOptionIds.length <= 1"
    >
      <i class="fa fa-arrow-left"></i>
    </button>
    <h2 class="title">For each of the following traits, please select one that is most like you</h2>
    <button mat-icon-button class="icon-button" (click)="leavePersonalityBuilder()">
      <i class="fa fa-times"></i>
    </button>
  </div>
  <div class="body-section">
    <div class="personality">
      <div
        class="personality-box"
        [style.border]="'2px solid ' + trayColor.border"
        [style.background]="trayColor?.background"
        [style.color]="trayColor.color"
        (click)="selectBox(option.option1)"
      >
        {{ option?.option1 }}
      </div>
      <div class="tray-text">OR</div>
      <div
        class="personality-box"
        [style.border]="'2px solid ' + trayColor.border"
        [style.background]="trayColor?.background"
        [style.color]="trayColor.color"
        (click)="selectBox(option.option2)"
      >
        {{ option?.option2 }}
      </div>
    </div>
    <div class="progress-bar">
      <mat-progress-bar
        id="progress-bar"
        mode="determinate"
        [value]="personalityProgress"
        color="basic"
      ></mat-progress-bar>
    </div>
  </div>
</div>

<div class="container ease-in" *ngIf="!isShortTest">
  <div class="header-section">
    <button
      mat-icon-button
      class="icon-button"
      (click)="previousOption()"
      [disabled]="selectedOptionIds.length <= 1"
    >
      <i class="fa fa-arrow-left"></i>
    </button>
    <h2 class="long-title">
      Please indicate to what extent you agree with the following statements, using the following
      answering categories
    </h2>
    <button mat-icon-button class="icon-button" (click)="leavePersonalityBuilder()">
      <i class="fa fa-times"></i>
    </button>
  </div>
  <div class="body-section">
    <div class="personality-questions">
      <div class="question">
        {{ option?.question }}
      </div>
      <div class="slider-div">
        <mat-slider
          #slider
          id="gradient-slider"
          class="gradient-slider"
          step="1"
          min="1"
          max="5"
          value="3"
        ></mat-slider>
        <div class="d-flex">
          <label class="slider-value pl-40">Strongly Disagree</label>
          <label class="slider-value pl-40">Disagree</label>
          <label class="slider-value">Neutral (neither agree nor disagree)</label>
          <label class="slider-value">Agree</label>
          <label class="slider-value">Strongly Agree</label>
        </div>
      </div>
      <div>
        <button
          mat-button
          class="next-button"
          (click)="nextQuestion(option, slider?.value); slider.value = 3"
        >
          Next
        </button>
      </div>
    </div>
    <div class="progress-bar">
      <mat-progress-bar
        id="progress-bar"
        mode="determinate"
        [value]="personalityProgress"
      ></mat-progress-bar>
    </div>
  </div>
</div>
