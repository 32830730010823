import { Component, Inject, OnInit, TemplateRef, ViewChild } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog'
import {
  ApexAxisChartSeries,
  ApexChart,
  ApexXAxis,
  ChartComponent,
  ApexYAxis,
  ApexStroke,
  ApexMarkers,
  ApexFill,
  ApexPlotOptions,
  ApexResponsive,
  ApexLegend,
  ApexDataLabels
} from 'ng-apexcharts'
import * as ApexCharts from 'apexcharts'

export type ChartOptions = {
  series: ApexAxisChartSeries
  chart: ApexChart
  xaxis: ApexXAxis
  yaxis: ApexYAxis[]
  stroke: ApexStroke
  markers: ApexMarkers
  fill: ApexFill
  plotOptions: ApexPlotOptions
  responsive: ApexResponsive[]
  legend: ApexLegend
  dataLabels: ApexDataLabels
}

@Component({
  selector: 'app-culture-score',
  templateUrl: './culture-score.component.html',
  styleUrls: ['./culture-score.component.scss']
})
export class CultureScoreComponent implements OnInit {
  @ViewChild('chart') chart: ChartComponent
  @ViewChild('cultureDetails') cultureDetails: TemplateRef<any>
  public chartOptions: Partial<ChartOptions>
  cultureTestInfo
  cultureFactors = []
  cultureButtons = []
  selectedCulture
  header: string = 'Culture Structure'
  isClientScore: boolean = false
  isMatchChart: boolean = false
  candidateCultureInfo
  roleCultureArchetype
  candidateCultureFactors = []
  radarChart: ApexCharts
  xAxisLabels: string[] = []
  seriesData = []
  activeSeries: string

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private dialogRef: MatDialogRef<CultureScoreComponent>,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.cultureTestInfo = this.data?.cultureTestInfo
    this.cultureFactors = this.data?.cultureFactors || []
    if (this.data?.header) {
      this.header = this.data?.header
    }
    if (this.data?.isClientScore) {
      this.isClientScore = this.data.isClientScore
    }
    if (this.data?.isMatchChart) {
      this.isMatchChart = this.data?.isMatchChart
      this.candidateCultureInfo = this.data?.candidateCultureInfo
      this.candidateCultureFactors = this.data?.candidateCultureFactors || []
    }
    this.createChart()
  }

  createChart() {
    this.cultureFactors.sort((a, b) => {
      return Number(a?.display_order) - Number(b?.display_order)
    })

    const names = []
    this.xAxisLabels = []
    const counts = []
    this.cultureButtons = []
    this.cultureFactors.forEach((value) => {
      const name = value.description.name
      names.push(name)
      this.xAxisLabels.push(name)
      counts.push(value?.count || 0)
      const obj = {
        name: name,
        value: value
      }
      this.cultureButtons.push(obj)
    })

    this.chartOptions = {
      series: [
        {
          name: 'Series 1',
          data: counts,
          color: '#ec3b83'
        }
      ],
      chart: {
        type: 'radar',
        toolbar: {
          show: false
        },
        width: 500
      },
      xaxis: {
        categories: ['', '', '', ''],
        labels: {
          style: {
            colors: ['#0C2149', '#0C2149', '#0C2149', '#0C2149', '#0C2149', '#0C2149']
          }
        }
      },
      yaxis: [
        {
          show: false
        }
      ],
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        colors: ['#ec3b83', '#3C64B1']
      },
      markers: {
        size: 0
      },
      fill: {
        opacity: 0
      },
      plotOptions: {
        radar: {
          polygons: {
            strokeColors: '#fff',
            connectorColors: '#ec3b83',
            fill: {
              colors: ['#D9D9D9']
            },
            strokeWidth: '2'
          }
        }
      },
      responsive: [
        {
          breakpoint: 930,
          options: {
            chart: {
              width: 450
            }
          }
        },
        {
          breakpoint: 1200,
          options: {
            chart: {
              width: 450
            }
          }
        },
        {
          breakpoint: 1440,
          options: {
            chart: {
              width: 500
            }
          }
        }
      ],
      legend: {
        markers: {
          height: 3,
          width: 30,
          radius: 3
        }
      }
    }

    if (this.isClientScore) {
      this.chartOptions.yaxis['max'] = 50
    }

    if (this?.isMatchChart) {
      this.candidateCultureFactors.sort((a, b) => {
        return Number(a?.display_order) - Number(b?.display_order)
      })

      let candidateCounts = this.candidateCultureFactors.map((value) => {
        return Number(value?.count)
      })

      this.chartOptions.series[0].name = 'Team'

      this.chartOptions.series.push({
        name: 'Candidate',
        data: candidateCounts,
        color: '#3C64B1'
      })

      this.seriesData = this.chartOptions.series
    }

    this.radarChart = new ApexCharts(document.querySelector('#chart'), this.chartOptions)
    this.radarChart.render()
  }

  // handleLegendHover(seriesName: string) {
  //   this.activeSeries = seriesName;
  //   this.updateChart();
  // }

  // handleLegendLeave() {
  //   this.activeSeries = null;
  //   this.resetChart();
  // }

  // updateChart() {
  //   const updatedSeries = cloneDeep(this.seriesData);
  //   if (this.activeSeries !== null) {
  //     updatedSeries.forEach((series) => {
  //       series.color =
  //         series.name === this.activeSeries
  //           ? series.color
  //           : series.color + "3b";
  //     });
  //   }
  //   this.radarChart.updateSeries(updatedSeries);
  // }

  // resetChart() {
  //   this.radarChart.updateSeries(this.seriesData);
  // }

  viewCultureDetails(culture) {
    this.selectedCulture = culture.value?.description
    this.dialog.open(this.cultureDetails, {
      autoFocus: false,
      width: '45vw',
      id: 'culture-details'
    })
  }

  closeModal() {
    this.dialogRef.close()
  }

  closeTemplateModal() {
    const dialogRef = this.dialog.getDialogById('culture-details')
    dialogRef.close()
  }
}
