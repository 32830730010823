import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  OnChanges,
  SimpleChanges,
  AfterViewInit
} from '@angular/core'
import { cloneDeep } from 'lodash'

@Component({
  selector: 'app-header-search-bar',
  templateUrl: './header-search-bar.component.html',
  styleUrls: ['./header-search-bar.component.scss']
})
export class HeaderSearchBarComponent implements OnInit, AfterViewInit, OnChanges {
  constructor() {}
  @Input() public data: any
  @Input() public keysArray: any = []
  @Output() sendDataToParent = new EventEmitter<boolean>()
  searchDatas: any = []
  // keysArray: any = [];
  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    this.searchDatas = cloneDeep(this.data)
  }
  ngAfterViewInit(): void {
    setTimeout(() => {
      // this.searchDatas = [...this.data];
      // this.keysArray = this.keysArray;
    }, 1500)
  }
  searchData(event: any) {
    let data
    const dataArray = []
    if (event.target.value) {
      this.searchDatas.map((el) => {
        this.keysArray.map((ele) => {
          if (el[ele]?.toLowerCase().includes(event.target.value.toLowerCase())) {
            if (dataArray.indexOf(el) === -1) {
              dataArray.push(el)
            }
          }
        })
      })
      data = dataArray
    } else {
      data = [...this.data]
    }
    this.sendDataToParent.emit(data)
  }
}
