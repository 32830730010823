import { Component, OnInit, Inject } from '@angular/core'
import { NgxSpinnerService } from 'ngx-spinner'
import { CRUDService } from 'src/app/crud.service'
import { CommonService } from 'src/app/common.service'
import { ActivatedRoute } from '@angular/router'
import { getSessionValue, getArrayStringValue } from 'src/app/common-utils'
import { environment } from '../../../../environments/environment'
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { RolesCandidateDataComponent } from '../../modals/roles-candidate-data/roles-candidate-data.component'
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal'
import { ToastrService } from 'ngx-toastr'
import { LeadershipProfileComponent } from '../../modals/leadership-profile/leadership-profile.component'
import { Subject } from 'rxjs'
import { BusinessService } from 'src/app/shared/services/business.service'
import { ValuesMapService } from 'src/app/shared/services/values-map.service'
import { S3Service } from 'src/app/shared/services/s3.service'

@Component({
  selector: 'app-business-profile-view',
  templateUrl: './business-profile-view.component.html',
  styleUrls: ['./business-profile-view.component.scss']
})
export class BusinessProfileViewComponent implements OnInit {
  skillsArray: any = []
  aboutus: any
  editAboutUsFlag: boolean = false
  editCompanyFlag: boolean = false
  editCompanyCultureFlag: boolean = false
  leaderShipFlag: boolean = false
  locationFlag: boolean = false
  companyFilteredOptions: any = []
  searchCompanyValue: string[] = []
  searchCompanyCultureValue: string[] = []
  companyValue: any = ''
  companyCulture: any = ''
  companyCultureFilteredOptions: any = []
  companyValueArray: any[] = []
  companyCultureArray: any[] = []
  roleArray: any = []
  imageProfile1: any
  resImageProfile1: any
  userName1: any = ''
  userDesignation1: any = ''
  imageProfile2: any
  resImageProfile2: any
  userName2: any = ''
  userDesignation2: any = ''
  imageProfile3: any
  resImageProfile3: any
  userName3: any = ''
  userDesignation3: any = ''
  businessProfile: any
  businessProfileId: any
  userInfo: any
  location: any
  apiUrl: any
  flag: boolean = false
  bsModalRef: BsModalRef
  leadershipArray: any = []

  eventSubject: Subject<any> = new Subject<void>()
  locationData: any
  isEditFlag: boolean = true

  constructor(
    private CRUDService: CRUDService,
    private businessService: BusinessService,
    private valuesMapService: ValuesMapService,
    private s3Service: S3Service,
    private bsModalService: BsModalService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<BusinessProfileViewComponent>,
    private toastr: ToastrService,
    private SpinnerService: NgxSpinnerService,
    private commonService: CommonService,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private activatedRoute: ActivatedRoute
  ) {}
  async ngOnInit() {
    this.apiUrl = environment.apiUrl
    this.userInfo = getSessionValue('USER_INFO')
    this.businessProfile = getSessionValue('BUSINESS_PROFILE')
    let id = this.activatedRoute.snapshot.paramMap.get('id')
    if (typeof this.data != 'undefined') {
      if (this.data) {
        id = this.data.id
        this.isEditFlag = this.data.isEdit
      }
    }
    if (id) {
      this.businessProfileId = id
    } else {
      this.businessProfileId = this.businessProfile.id
    }
    this.loadBusinessInfo()

    this.valuesMapService.getFilteredValuesMap(100, 0, '', 'VALUES').subscribe(({ data }) => {
      let names = data.items.map(function (item) {
        return item['description']
      })
      this.searchCompanyValue = names
    })
    this.valuesMapService.getFilteredValuesMap(100, 0, '', 'CULTURE').subscribe(({ data }) => {
      let names = data.items.map(function (item) {
        return item['description']
      })
      this.searchCompanyCultureValue = names
    })

    /* not being used
    this.valuesMapService
      .getFilteredValuesMap(100, 0, "", "SKILLS")
      .subscribe(({ data }) => {
        this.skillsArray = data.items;
      });
    this.valuesMapService
      .getFilteredValuesMap(100, 0, "", "ROLES")
      .subscribe(({ data }) => {
        let names = data.items.map(function (item) {
          return item["description"];
        });
        this.roleArray = names;
      }); */
    this.valuesMapService.getFilteredValuesMap(100, 0, '', 'LOCATION').subscribe(({ data }) => {
      const sortedItem = data.items.sort((a, b) => a.display_order - b.display_order)
      const names = sortedItem.map(function (item) {
        return item.description
      })
      this.locationData = names
    })
    this.SpinnerService.hide()

    // setTimeout(async () => {
    //   /* this.editCompanyCulture();
    //   this.editCompanyValue() */
    //   this.editAboutUsFlag = true;
    //   this.editCompanyFlag = true;
    //   this.editCompanyCultureFlag = true;
    //   this.leaderShipFlag = true;
    //   this.locationFlag = true;
    //   if (this.userInfo?.user_type == "3") {
    //     const result = await this.userService
    //       .getById(this.businessProfile?.id)
    //       .toPromise();
    //     this.userInfo = result.data;
    //   }
    // }, 1000);
  }
  loadBusinessInfo() {
    this.businessService.getBusiness(this.businessProfileId).subscribe((data) => {
      this.businessProfile = data.data

      if (
        this.businessProfile?.company_culture !== null &&
        this.businessProfile.company_culture !== undefined &&
        this.businessProfile.company_culture !== ''
      ) {
        this.companyCultureArray = this.businessProfile?.company_culture?.split(';')
      }
      if (
        this.businessProfile?.company_values !== null &&
        this.businessProfile.company_values !== undefined &&
        this.businessProfile.company_values !== ''
      ) {
        this.companyValueArray = this.businessProfile?.company_values?.split(';')
      }
      this.aboutus = this.businessProfile?.objective
      this.getLeaderShipData()
      /* if (this.businessProfile?.leadership_1_img_url !== null) {
        this.imageProfile1 = this.this.businessProfile?.leadership_1_img_url;
      }
      if (this.businessProfile?.leadership_2_img_url !== null) {
        this.imageProfile2 = this.this.businessProfile?.leadership_2_img_url;
      }
      if (this.businessProfile?.leadership_3_img_url !== null) {
        this.imageProfile3 = this.this.businessProfile?.leadership_3_img_url;
      }
      this.userName1 = this.businessProfile?.leadership_1_fullname;
      this.userDesignation1 = this.businessProfile?.leadership_1_role;
      this.userName2 = this.businessProfile?.leadership_2_fullname;
      this.userDesignation2 = this.businessProfile?.leadership_2_role;
      this.userName3 = this.businessProfile?.leadership_3_fullname;
      this.userDesignation3 = this.businessProfile?.leadership_3_role; */
      this.location = this.businessProfile?.location
    })
  }
  async getLeaderShipData() {
    // await this.CRUDService.getDataByField('Users', 'group_id', this.businessProfileId).subscribe((data: any) => {
    //   this.leadershipArray = data;
    //   data?.map((ele: any) => {
    //     ele['full_name'] = ele.first_name + ' ' + (ele.last_name !== null ? ele.last_name : '')
    //   })
    //   let validatedLeadership = data.filter((ele: any) => {return ele.status !== 2 && ele.status !== '2'})
    //   this.leadershipArray = validatedLeadership;
    // });
    let data: any = await this.CRUDService.getDataByField(
      'Leadership',
      'business_id',
      this.businessProfileId
    ).toPromise()
    data = data.sort((a, b) => a.id - b.id)
    this.leadershipArray = data
  }
  editAboutUs() {
    this.editAboutUsFlag = true
  }
  editCompanyValue() {
    this.editCompanyFlag = true
    this.companyValueArray.forEach((selectedCompanyValue) => {
      let index = this.searchCompanyValue.findIndex((val) => val === selectedCompanyValue)
      if (index > -1) {
        this.searchCompanyValue.splice(index, 1)
      }
      this.companyFilteredOptions = this.searchCompanyValue
    })
  }

  disableAddCompanyValue: boolean = true

  companyValueChange() {
    // const filterValue = this.companyValue.toLowerCase();
    // this.companyFilteredOptions = this.searchCompanyValue.filter(option => option.toLowerCase().includes(filterValue));

    let index = this.companyFilteredOptions.findIndex((val) => val === this.companyValue)
    this.searchCompanyValue.sort()
    if (index > -1) {
      this.disableAddCompanyValue = false
    } else {
      this.disableAddCompanyValue = true
    }
    if (this.companyValue) {
      this.companyFilteredOptions = this.searchCompanyValue.filter((option) =>
        option.toLowerCase().includes(this.companyValue.toLowerCase())
      )
    } else {
      this.companyFilteredOptions = this.searchCompanyValue
    }
  }

  addCompanyValue(companyValue: any): void {
    for (let i = 0; i <= this.companyFilteredOptions.length; i++) {
      if (companyValue == this.companyFilteredOptions[i]) {
        if (
          this.companyValue !== null &&
          this.companyValue !== '' &&
          this.companyValue !== undefined
        ) {
          if (
            this.companyValueArray !== null &&
            this.companyValueArray !== undefined &&
            this.companyValueArray.length !== 0
          ) {
            if (!this.companyValueArray?.includes(this.companyValue)) {
              this.companyValueArray?.push(this.companyValue)
              let index = this.searchCompanyValue.findIndex((val) => val == this.companyValue)
              this.companyValue = null
              this.searchCompanyValue.splice(index, 1)
            }
          } else {
            this.companyValueArray = []
            this.companyValueArray.push(this.companyValue)
            let index = this.searchCompanyValue.findIndex((val) => val == this.companyValue)
            this.companyValue = ''
            this.searchCompanyValue.splice(index, 1)
          }
          this.flag = true
        } else {
          this.flag = false
        }
      }
    }
  }

  disableAddcompanyCulture: boolean = true

  addCompanyCulture(companyCulture: any): void {
    for (let i = 0; i <= this.companyCultureFilteredOptions.length; i++) {
      if (companyCulture == this.companyCultureFilteredOptions[i]) {
        if (
          this.companyCulture !== null &&
          this.companyCulture !== '' &&
          this.companyCulture !== undefined
        ) {
          if (
            this.companyCultureArray !== null &&
            this.companyCultureArray !== undefined &&
            this.companyCultureArray.length !== 0
          ) {
            if (!this.companyCultureArray?.includes(this.companyCulture)) {
              this.companyCultureArray?.push(this.companyCulture)
              let index = this.searchCompanyCultureValue.findIndex(
                (val) => val == this.companyCulture
              )
              this.companyCulture = null
              this.searchCompanyCultureValue.splice(index, 1)
            }
          } else {
            this.companyCultureArray = []
            this.companyCultureArray.push(this.companyCulture)
            let index = this.searchCompanyCultureValue.findIndex(
              (val) => val == this.companyCulture
            )
            this.companyCulture = ''
            this.searchCompanyCultureValue.splice(index, 1)
          }
          this.flag = true
        } else {
          this.flag = false
        }
      }
    }
  }
  companyCultureChange() {
    // const filterValue = this.companyCulture.toLowerCase();
    // this.companyCultureFilteredOptions = this.searchCompanyCultureValue.filter(option => option.toLowerCase().includes(filterValue));

    let index = this.companyCultureFilteredOptions.findIndex((val) => val === this.companyCulture)
    this.searchCompanyCultureValue.sort()
    if (index > -1) {
      this.disableAddcompanyCulture = false
    } else {
      this.disableAddcompanyCulture = true
    }
    if (this.companyCulture) {
      this.companyCultureFilteredOptions = this.searchCompanyCultureValue.filter((option) =>
        option.toLowerCase().includes(this.companyCulture.toLowerCase())
      )
    } else {
      this.companyCultureFilteredOptions = this.searchCompanyCultureValue
    }
  }

  // removeCompanyValue(companyValue: string) {
  //   this.companyValueArray.splice(this.companyValueArray.indexOf(companyValue), 1);
  // }

  removeCompanyValue(index: any) {
    let value = this.companyValueArray[index]
    let entry = value.split(' - ')
    let option = entry[0]
    this.searchCompanyValue.push(option)
    this.companyFilteredOptions = this.searchCompanyValue.sort((a, b) => (a > b ? 1 : -1))
    this.companyValueArray.splice(index, 1)
  }

  editCompanyCulture() {
    this.editCompanyCultureFlag = true
    this.companyCultureArray.forEach((selectedCompanyCultureValue) => {
      let index = this.searchCompanyCultureValue.findIndex(
        (val) => val === selectedCompanyCultureValue
      )
      if (index > -1) {
        this.searchCompanyCultureValue.splice(index, 1)
      }
      this.companyCultureFilteredOptions = this.searchCompanyCultureValue
    })
  }
  removeCompanyCultureValue(index: any) {
    let value = this.companyCultureArray[index]
    let entry = value.split(' - ')
    let option = entry[0]
    this.searchCompanyCultureValue.push(option)
    this.companyCultureFilteredOptions = this.searchCompanyCultureValue.sort((a, b) =>
      a > b ? 1 : -1
    )
    this.companyCultureArray.splice(index, 1)
  }
  // companyCultureChange() {
  //   const filterValue = this.companyCulture.toLowerCase();
  //   this.companyCultureFilteredOptions = this.searchCompanyCultureValue.filter(option => option.toLowerCase().includes(filterValue));
  // }
  // removeCompanyValue(companyValue: string) {
  //   this.companyValueArray.splice(this.companyValueArray.indexOf(companyValue), 1);
  // }
  // editCompanyCulture() {
  //   this.editCompanyCultureFlag = true;
  // }
  // removeCompanyCultureValue(cultureValue: any) {
  //   this.companyCultureArray.splice(this.companyCultureArray.indexOf(cultureValue), 1);
  // }
  editLeaderShip() {
    this.leaderShipFlag = true
  }
  editLocation() {
    this.locationFlag = true
  }
  async submitData() {
    /* if (this.userName2 == '' || this.userName2 == null || this.userName1 == '' || this.userName1 == null || this.userName3 == '' || this.userName3 == null) {
      this._snackBar.open('Please Enter Valid Info', '', {
        horizontalPosition: 'end',
        verticalPosition: 'top',
        duration: 3000
      });
    } else { */
    let businessData: any = sessionStorage.getItem('BUSINESS_PROFILE')
    if (businessData) {
      businessData = JSON.parse(businessData)
    }
    this.businessProfile.objective = this.aboutus
    this.businessProfile.company_values = getArrayStringValue(this.companyValueArray)
    this.businessProfile.company_culture = getArrayStringValue(this.companyCultureArray)
    /* this.businessProfile.leadership_1_img_url = this.resImageProfile1;
    this.businessProfile.leadership_1_fullname = this.userName1;
    this.businessProfile.leadership_1_role = this.userDesignation1;
    this.businessProfile.leadership_2_img_url = this.resImageProfile2;
    this.businessProfile.leadership_2_fullname = this.userName2;
    this.businessProfile.leadership_2_role = this.userDesignation2;
    this.businessProfile.leadership_3_img_url = this.resImageProfile3;
    this.businessProfile.leadership_3_fullname = this.userName3;
    this.businessProfile.leadership_3_role = this.userDesignation3; */
    this.businessProfile.location = this.location
    this.businessProfile.logo_url = businessData?.logo_url
    this.businessProfile.status = businessData?.status
    const response = await this.businessService.update(this.businessProfile).toPromise()
    if (response.success) {
      const userData = getSessionValue('USER_INFO')
      if (userData?.user_type == '2') {
        sessionStorage.setItem('BUSINESS_PROFILE', JSON.stringify(this.businessProfile))
      }
      if (this.leadershipArray.length > 0) {
        await this.submitLeaderShipData()
      } else {
        this.eventSubject.next()
      }
    }

    this.dialogRef.close({})
  }
  async submitLeaderShipData() {
    for (let ele of this.leadershipArray) {
      ele.business_id = this.businessProfile.id
      await this.commonService.updateRecord('Leadership', ele)
    }
    this.eventSubject.next()
  }
  async uploadImageProfile(event: any, srcUrl: any) {
    await this.SpinnerService.show()
    if (
      event.addedFiles &&
      event.addedFiles[0] &&
      (event.addedFiles[0].type == 'image/jpeg' ||
        event.addedFiles[0].type == 'image/png' ||
        event.addedFiles[0].type == 'image/gif' ||
        event.addedFiles[0].type == 'image/webp')
    ) {
      const file = event.addedFiles[0]
      await this.SpinnerService.show()
      if (file.size <= environment.imageDocSize) await this.uploadFile(file, srcUrl)
      else {
        this.SpinnerService.hide()
        this.toastr.warning(environment.fileErrorMessage)
      }
    } else {
      this.SpinnerService.hide()
      this.toastr.warning(environment.imageErrorMessage)
    }
  }
  async uploadFile(file: any, leadership: any) {
    await this.SpinnerService.show()
    await this.s3Service
      .uploadFile(file, `business/${this.businessProfileId}/${this.userInfo.id}`)
      .then(async (res) => {
        leadership.img_url = res.Location
      })
      .finally(() => {
        this.SpinnerService.hide()
      })
  }
  cancel() {
    this.dialogRef.close({})
  }
  openViewProfile(value: any) {
    const initialState: any = {
      status: value
    }
    this.bsModalRef = this.bsModalService.show(RolesCandidateDataComponent, {
      ariaLabelledBy: 'modal-basic-title',
      class: 'modal-height modal-xl candidate-data',
      backdrop: 'static',
      initialState
    })
  }
  addLeaderShip() {
    if (this.leadershipArray.length !== 10) {
      const json = {
        name: '',
        role: '',
        img_url: ''
      }
      this.leadershipArray.push(json)
    } else {
      this.toastr.warning('Only 10 Members of Leadership allowed')
    }
  }

  async removeLeaderShip(index) {
    let id = this.leadershipArray[index].id

    let index1 = this.leadershipArray.findIndex((ele) => {
      return ele.id === id
    })
    this.leadershipArray[index1].status = 2
    this.leadershipArray.splice(index, 1)
    await this.CRUDService.deleteByField('Leadership', 'id', id).toPromise()
  }
  addProfile(leadership) {
    let dialogRef2 = this.dialog.open(LeadershipProfileComponent, {
      panelClass: 'event-details-dialog',
      width: '50vw',
      data: {
        data: leadership
      }
    })
    dialogRef2.afterClosed().subscribe((result: string) => {
      dialogRef2 = Object.create(null)
      this.leaderShipFlag = false
    })
  }
}
