import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable, of, throwError } from 'rxjs'
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http'
import { environment } from '../../../environments/environment'
import { catchError, finalize, map, tap } from 'rxjs/operators'
import { Router } from '@angular/router'
import { UserLogin } from '../../core/models/auth'
import { ToastrService } from 'ngx-toastr'
import { UserRegister } from 'src/app/core/models/user'
import {
  Candidate,
  CandidateBasicStatsResponse,
  CandidateBookedTimeslotsResponse,
  CandidateDetailedStatsResponse,
  CandidateListResponse,
  CandidateRegister,
  CandidateRegisterResponse,
  GetCandidatesParams
} from 'src/app/core/models/candidate'
import { ApiListResponse, ApiResponse, PaginationParams } from 'src/app/core/models/base'

@Injectable()
export class CandidateService {
  constructor(
    private http: HttpClient,
    private router: Router,
    private toastr: ToastrService
  ) {}

  private apiRoot = environment.apiUrl + '/v1'

  getAllCandidates({
    limit = 50,
    offset = 0,
    order = 'asc',
    sort = 'created_at',
    q = '',
    status = [0, 1],
    ...rest
  }: GetCandidatesParams) {
    return this.http
      .get<CandidateListResponse>(`${environment.apiUrl}/v1/candidates`, {
        params: new HttpParams()
          .set('limit', limit.toString())
          .set('offset', offset.toString())
          .set('order', order)
          .set('sort', sort)
          .set('q', q)
          .set('status', status.join(','))
          .set('roles_interested', rest.roles_interested?.join(',') || '')
          .set('looking_for', rest.looking_for?.join(',') || '')
      })
      .pipe(
        map(
          (res) => {
            return res
          },
          (err) => {
            console.log('candidate onboarding', err)
          }
        )
      )
  }
  register(candidateRegister: CandidateRegister) {
    return this.http
      .post<CandidateRegisterResponse>(`${environment.apiUrl}/v1/candidates`, candidateRegister)
      .pipe(
        map(
          (res) => {
            return res
          },
          (err) => {
            console.log('candidate onboarding', err)
          }
        )
      )
  }
  // TODo: implement types
  updateSelf(candidateUpdate: any) {
    return this.http
      .put<ApiResponse<Candidate>>(`${environment.apiUrl}/v1/candidates/me`, candidateUpdate)
      .pipe(
        map(
          (res) => {
            return res
          },
          (err) => {
            console.log('candidate update', err)
          }
        )
      )
  }
  updateById(id: any, candidateUpdate: any) {
    return this.http
      .put<ApiResponse<Candidate>>(`${environment.apiUrl}/v1/candidates/${id}`, candidateUpdate)
      .pipe(
        map(
          (res) => {
            return res
          },
          (err) => {
            console.log('candidate update', err)
          }
        )
      )
  }
  // TODo: implement types
  getSelf() {
    return this.http.get<ApiResponse<Candidate>>(`${environment.apiUrl}/v1/candidates/me`).pipe(
      map(
        (res) => {
          return res
        },
        (err) => {
          console.log('candidate get', err)
        }
      )
    )
  }
  // TODo: implement types
  getById(id: number) {
    return this.http.get<ApiResponse<Candidate>>(`${environment.apiUrl}/v1/candidates/${id}`).pipe(
      map(
        (res) => {
          return res
        },
        (err) => {
          console.log('candidate get', err)
        }
      )
    )
  }

  // TODo: implement types
  getByUserId(id: number) {
    return this.http
      .get<ApiResponse<Candidate>>(`${environment.apiUrl}/v1/users/${id}/candidate-profile`)
      .pipe(
        map(
          (res) => {
            return res
          },
          (err) => {
            console.log('candidate get', err)
          }
        )
      )
  }

  getAllCandidateEvents(limit: number = 50, offset: number = 0, q: string = '') {
    return this.http
      .get<ApiListResponse<any>>(`${this.apiRoot}/candidates/me/events`, {
        params: new HttpParams()
          .set('limit', limit.toString())
          .set('offset', offset.toString())
          .set('q', q)
      })
      .pipe(
        map(
          (res) => {
            return res
          },
          (err) => {
            console.log('candidate events', err)
          }
        )
      )
  }
  getCandidateBookedTimeslots(limit: number = 0, offset: number = 0) {
    return this.http
      .get<ApiListResponse<CandidateBookedTimeslotsResponse>>(
        `${this.apiRoot}/candidates/me/booked-timeslots`,
        {
          params: new HttpParams().set('limit', limit.toString()).set('offset', offset.toString())
        }
      )
      .pipe(
        map(
          (res) => {
            return res
          },
          (err) => {
            console.log('candidate booked timeslots', err)
          }
        )
      )
  }
  getCandidateBasicStats() {
    return this.http
      .get<ApiResponse<CandidateBasicStatsResponse>>(`${this.apiRoot}/candidates/stats`)
      .pipe(
        map(
          (res) => {
            return res
          },
          (err) => {
            console.log('candidate basic stats', err)
          }
        )
      )
  }
  getCandidateDetailedStats(year: number, month?: number) {
    let params = new HttpParams().set('year', year.toString())

    if (month !== undefined && month !== null) {
      params = params.set('month', month.toString())
    }

    return this.http
      .get<ApiResponse<CandidateDetailedStatsResponse[]>>(
        `${this.apiRoot}/candidates/stats/detailed`,
        {
          params
        }
      )
      .pipe(
        map(
          (res) => res,
          (err) => {
            console.log('candidate detailed stats', err)
            return err // ensure the error is returned or handled appropriately
          }
        )
      )
  }
}
