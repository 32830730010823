<div class="postsBox">
  <div class="mt-2" style="flex-wrap: nowrap; float: right; margin-right: 1%; cursor: pointer">
    <!-- <img src="../../../../assets/images/chat-icon/Edit.svg" class="icon-css" *ngIf="!editFlag"
            (click)="editData()"> -->
    <!-- <mat-icon (click)="submitData();">
            check_circle_outline
        </mat-icon> -->
  </div>
  <div class="row d-flex" style="flex-wrap: nowrap">
    <div class="custom-file-upload mx-auto">
      <ngx-dropzone
        *ngIf="isEditFlag"
        class="dropzone"
        [multiple]="false"
        (change)="uploadImageProfile($event)"
        accept="image/jpeg, image/gif, image/png, image/webp"
      >
        <img
          class="profile-img"
          src="{{ profileImage }}"
          style="cursor: pointer"
          onerror="this.onerror=null;this.src='assets/images/business/business_logo.png'"
        />
      </ngx-dropzone>
      <img
        *ngIf="!isEditFlag"
        class="profile-img"
        src="{{ profileImage }}"
        onerror="this.onerror=null;this.src='assets/images/business/business_logo.png'"
      />
    </div>

    <!-- <div class="profile-div pl-2">
            <label for="file-upload" class="custom-file-upload mx-auto"> -->
    <!-- <img class="profile-img" src="{{profileData?.logo_url}}" *ngIf="!editFlag"
                    style="cursor: inherit"
                    onerror="this.onerror=null;this.src='../../../assets/images/users/Character.png'" /> -->
    <!-- <img class="profile-img" src="{{profileData?.logo_url}}" style="cursor: pointer"
                    onerror="this.onerror=null;this.src='../../../assets/images/users/Character.png'" /> -->
    <!-- <img class="profile-img"
                    [src]="profileImage || '../../../assets/images/background/login-register.jpg'"> -->
    <!-- </label>
            <input id="file-upload" type="file" accept="image/jpeg, image/gif, image/png, image/webp"
                (change)="uploadImageProfile($event);" />
        </div> -->
    <div class="pl-2">
      <!-- <label class="profile-label">{{profileData?.name}}</label> -->
      <div>
        <!-- <label class="profile-label" *ngIf="!editFlag">{{profileData?.name}}</label> -->
        <div style="width: 100%">
          <mat-form-field class="example-full-width" appearance="fill">
            <input
              [disabled]="!isEditFlag"
              type="text"
              matInput
              placeholder="Name"
              [(ngModel)]="name"
            />
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>
  <div class="mt-2">
    <mat-label class="roles-available">Hiring</mat-label>
    <mat-slide-toggle
      [disabled]="!isEditFlag"
      [checked]="roles_available_check"
      [(ngModel)]="roles_available"
      (change)="changeRolesStatus($event)"
    >
    </mat-slide-toggle>
  </div>
  <!-- <div class="mt-2">
        <label class="looking-label">We're looking for</label>
        <mat-form-field appearance="fill" class="mt-1">
            <mat-select [(ngModel)]="roleValue" name="business" placeholder="Select option">
                <mat-option *ngFor="let role of roleArray" [value]="role">
                    {{role}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div> -->
  <!-- <div class="mt-2">
        <label class="looking-label">Person of Contact</label>
        <mat-form-field appearance="fill" class="mt-1">
            <mat-select [(ngModel)]="contactPerson" name="business" placeholder="Select option">
                <mat-option *ngFor="let role of peopleList" [value]="role">
                    {{role}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div> -->
  <div class="mt-3">
    <div class="d-flex" style="justify-content: space-between">
      <div><label class="looking-label">Type of Business</label></div>

      <div>
        <!-- <label class="looking-label looking-label-value" *ngIf="!editFlag">{{technology}} </label> -->
        <div style="width: 150px">
          <mat-form-field appearance="fill" class="select-mat">
            <mat-select
              [disabled]="!isEditFlag"
              [(ngModel)]="technology"
              name="technology"
              placeholder="Select option"
            >
              <mat-option *ngFor="let technology of technologyArray" [value]="technology">
                {{ technology }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="d-flex" style="justify-content: space-between">
      <div><label class="looking-label">Industry </label></div>

      <div>
        <!-- <label class="looking-label looking-label-value" *ngIf="!editFlag">{{industry}}</label> -->
        <div style="width: 150px">
          <mat-form-field appearance="fill" class="select-mat">
            <mat-select
              [disabled]="!isEditFlag"
              [(ngModel)]="industry"
              name="industry"
              placeholder="Select option"
            >
              <mat-option *ngFor="let industry of industryArray" [value]="industry">
                {{ industry }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="d-flex" style="justify-content: space-between">
      <div><label class="looking-label">Location</label></div>

      <div>
        <!-- <label class="looking-label looking-label-value" *ngIf="!editFlag">{{location}}</label> -->
        <div style="width: 150px">
          <mat-form-field appearance="fill" class="select-mat">
            <mat-select
              #matSelect
              [disabled]="!isEditFlag"
              [(ngModel)]="location"
              name="location"
              placeholder="Select Location"
              disableOptionCentering
              panelClass="myPanelClass2"
            >
              <div (mouseleave)="matSelect.close()">
                <mat-option *ngFor="let location of locationData" [value]="location">
                  {{ location }}
                </mat-option>
              </div>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="d-flex" style="justify-content: space-between">
      <div><label class="looking-label">Website</label></div>
      <div>
        <!-- <label class="looking-label looking-label-value" *ngIf="!editFlag">{{website}}</label> -->
        <div style="width: 150px">
          <mat-form-field appearance="fill" class="select-mat">
            <input
              [disabled]="!isEditFlag"
              type="text"
              aria-label="Number"
              matInput
              placeholder="Website URL"
              (keyup)="isUrlValid()"
              [(ngModel)]="website"
            />
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="d-flex" style="justify-content: space-between">
      <div><label class="looking-label">Public Company</label></div>

      <div>
        <!-- <label class="looking-label looking-label-value" *ngIf="!editFlag">{{publicCompany}}</label> -->
        <!-- <div *ngIf="editFlag" style="width: 150px"> -->
        <!-- <mat-checkbox *ngIf="!editFlag" class="looking-label looking-label-value" [(ngModel)]="publicCompany"
                    [disabled]="true" title="I identify as Aboriginal or Torres Strait Islander origin"
                    (ngModelChange)="status=true">
                </mat-checkbox> -->
        <!-- <mat-form-field appearance="fill" class="select-mat">
                        <input type="text" aria-label="Number" matInput placeholder="Type answers..."
                            [(ngModel)]="publicCompany">
                    </mat-form-field> -->
        <!-- </div> -->
        <div class="select-mat">
          <section class="example-section" style="text-align: start">
            <mat-checkbox
              [disabled]="!isEditFlag"
              class="example-margin"
              (ngModelChange)="setSelection($event)"
              [(ngModel)]="publicCompany"
            >
            </mat-checkbox>
          </section>
        </div>
      </div>
    </div>
    <div>
      <!-- <div><label class="looking-label">Public Company</label></div> -->
      <!-- <div class="fa-icon d-flex" style="justify-content: space-between; width: 100%;" *ngIf="!editFlag">
                <i class="fa fa-linkedin-square"></i>
                <i class="fa fa-facebook-square"></i>
                <i class="fa fa-twitter-square"></i>
                <i class="fa fa-youtube-square"></i>
                <i class="fa fa-instagram"></i>
                <img src="https://img.icons8.com/ios-filled/22/000000/tiktok--v1.png" />
            </div> -->
      <div class="d-flex" style="justify-content: space-between">
        <div><label class="looking-label">LinkedIn</label></div>
        <div style="width: 150px">
          <mat-form-field appearance="fill" class="select-mat">
            <input
              [disabled]="!isEditFlag"
              type="text"
              aria-label="Number"
              matInput
              placeholder="Linkedin URL"
              (keyup)="validLinkedInUrl()"
              [(ngModel)]="linkedinurl"
            />
          </mat-form-field>
        </div>
      </div>
      <div class="d-flex" style="justify-content: space-between">
        <div><label class="looking-label">Facebook</label></div>
        <div style="width: 150px">
          <mat-form-field appearance="fill" class="select-mat">
            <input
              [disabled]="!isEditFlag"
              type="text"
              aria-label="Number"
              matInput
              placeholder="Facebook URL"
              (keyup)="validFacebookUrl()"
              [(ngModel)]="facebookUrl"
            />
          </mat-form-field>
        </div>
      </div>
      <div class="d-flex" style="justify-content: space-between">
        <div><label class="looking-label">Twitter</label></div>
        <div style="width: 150px">
          <mat-form-field appearance="fill" class="select-mat">
            <input
              [disabled]="!isEditFlag"
              type="text"
              aria-label="Number"
              matInput
              placeholder="Twitter URL"
              (keyup)="validTwitterUrl()"
              [(ngModel)]="twitterUrl"
            />
          </mat-form-field>
        </div>
      </div>
      <div class="d-flex" style="justify-content: space-between">
        <div><label class="looking-label">YouTube</label></div>
        <div style="width: 150px">
          <mat-form-field appearance="fill" class="select-mat">
            <input
              [disabled]="!isEditFlag"
              type="text"
              aria-label="Number"
              matInput
              placeholder="Youtube URL"
              (keyup)="validYouTubeUrl()"
              [(ngModel)]="youTubeUrl"
            />
          </mat-form-field>
        </div>
      </div>
      <div class="d-flex" style="justify-content: space-between">
        <div><label class="looking-label">Instagram</label></div>
        <div style="width: 150px">
          <mat-form-field appearance="fill" class="select-mat">
            <input
              [disabled]="!isEditFlag"
              type="text"
              aria-label="Number"
              matInput
              placeholder="Instagram URL"
              (keyup)="validInstagramUrl()"
              [(ngModel)]="instagramUrl"
            />
          </mat-form-field>
        </div>
      </div>
      <div class="d-flex" style="justify-content: space-between">
        <div><label class="looking-label">Tik Tok</label></div>
        <div style="width: 150px">
          <mat-form-field appearance="fill" class="select-mat">
            <input
              [disabled]="!isEditFlag"
              type="text"
              aria-label="Number"
              matInput
              placeholder="Tik Tok URL"
              (keyup)="validTiktokUrl()"
              [(ngModel)]="tiktokUrl"
            />
          </mat-form-field>
        </div>
      </div>

      <div class="d-flex" style="justify-content: space-between">
        <div><label class="looking-label">Contact Number</label></div>
        <div style="width: 150px">
          <mat-form-field appearance="fill" class="select-mat">
            <input
              [disabled]="!isEditFlag"
              type="text"
              aria-label="Number"
              matInput
              placeholder="Contact Number"
              [(ngModel)]="contactNumber"
            />
          </mat-form-field>
        </div>
      </div>

      <div class="d-flex" style="justify-content: space-between">
        <div><label class="looking-label">Email ID </label></div>
        <div style="width: 150px">
          <mat-form-field appearance="fill" class="select-mat">
            <input
              [disabled]="!isEditFlag"
              type="text"
              aria-label="Number"
              matInput
              placeholder="Email ID"
              [(ngModel)]="emailId"
            />
          </mat-form-field>
        </div>
      </div>
      <div class="d-flex" style="justify-content: space-between">
        <div><label class="looking-label">Contact Person</label></div>
        <div style="width: 150px">
          <mat-form-field appearance="fill" class="select-mat">
            <input
              [disabled]="!isEditFlag"
              type="text"
              aria-label="Number"
              matInput
              placeholder="Contact Person"
              [(ngModel)]="contactPerson"
            />
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>
  <ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
    <p style="color: white">Please Wait.</p>
  </ngx-spinner>
</div>
