import { Component, Inject, ViewChild, ElementRef, HostListener } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog'
import { FormGroup } from '@angular/forms'
import { DateSlots, TimeSlots, TimeSlotsList } from '../event-utils'
import { CalendarEventDialogComponent } from '../calendar-event-dialog/calendar-event-dialog.component'
import { getSessionValue } from 'src/app/common-utils'
import { NullTemplateVisitor } from '@angular/compiler'
import { Subject } from 'rxjs'
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal'
import { UpdateScheduleComponent } from '../../update-schedule/update-schedule.component'
import { ConfirmationModalComponent } from 'src/app/dashboards/modals/confirmation-modal/confirmation-modal.component'

@Component({
  selector: 'app-event-detail',
  templateUrl: './event-detail.component.html',
  styleUrls: ['./event-detail.component.scss']
})
export class EventDetailComponent {
  event: any
  dateEvent: any
  dialogTitle: string
  eventForm: FormGroup
  action: any
  post_id: any
  calendarEvents: Event[]
  dateSlots: DateSlots[] = []
  timeSlotsList: TimeSlots[] = TimeSlotsList
  changed: boolean = false
  events: any = {}
  userInfo: any
  dialogRef3: MatDialogRef<CalendarEventDialogComponent> = Object.create(NullTemplateVisitor)
  refresh: Subject<any> = new Subject()
  updatedEvent: any
  bsModalRef: BsModalRef
  slotTime: string
  roleDetails
  candidateData
  businessData
  viewAs: string

  @ViewChild('slotStartTime', { read: ElementRef }) slotStartTime: ElementRef
  @ViewChild('slotEndTime', { read: ElementRef }) slotEndTime: ElementRef
  @ViewChild('startTimeMatSelect') startTimeMatSelect: any
  @ViewChild('endTimeMatSelect') endTimeMatSelect: any

  @HostListener('document:click', ['$event'])
  public documentClick(event: any): void {
    if (!this.slotStartTime?.nativeElement.contains(event.target)) {
      this.startTimeMatSelect?.close()
    }
    if (!this.slotEndTime?.nativeElement.contains(event.target)) {
      this.endTimeMatSelect?.close()
    }
  }

  constructor(
    public bsModalService: BsModalService,
    public dialogRef: MatDialogRef<CalendarEventDialogComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
    this.userInfo = getSessionValue('USER_INFO')
    if (this.userInfo.user_type == '1') {
      this.viewAs = 'Candidate'
    } else if (this.userInfo.user_type == '2') {
      this.viewAs = 'Business'
    }
    this.events = this.data.dateSlots
    this.roleDetails = data?.roleDetails ? data?.roleDetails : null

    let startTime: string
    let endTime: string
    if (this.events.extendedProps.type === 'SLOT') {
      for (let time of this.timeSlotsList) {
        if (time.value === this.events.extendedProps.selectedStartTime) {
          startTime = time.viewValue
        }
        if (time.value === this.events.extendedProps.selectedEndTime) {
          endTime = time.viewValue
        }
        if (startTime && endTime) {
          this.slotTime = `${startTime} - ${endTime}`
          break
        }
      }
    } else if (this.events.extendedProps.type === 'SCHEDULE' && this.viewAs == 'Business') {
      this.candidateData = data?.candidateData
    } else if (this.events.extendedProps.type === 'SCHEDULE' && this.viewAs == 'Candidate') {
      this.businessData = data?.businessData
    }
    // this.events.extendedProps['startDateTime'] = new Date(this.events.extendedProps.slot_start_time)
  }

  closeDialog() {
    this.dialogRef.close({})
  }

  cancel(event) {
    const data = {
      title: 'Cancel Event Confimation',
      description: 'Are you sure you want to cancel this event?'
    }
    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      autoFocus: false,
      data: data
    })

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (event.extendedProps.event_id !== undefined && event.extendedProps.event_id !== null) {
          event.action = 'cancel'
          const initialState: any = { data: event }
          this.dialog.open(UpdateScheduleComponent, {
            autoFocus: false,
            panelClass: 'update-schedule-dialog',
            id: 'updateScheduleDialog',
            data: initialState
          })
          // this.bsModalRef = this.bsModalService.show(UpdateScheduleComponent, {
          //   ariaLabelledBy: "modal-basic-title",
          //   class: "update-schedule-dialog",
          //   backdrop: "static",
          //   initialState,
          // });
          this.dialogRef.close({})
        } else {
          this.dialogRef.close('cancelEvent')
        }
      } else {
        return
      }
    })
  }

  reschedule(event) {
    if (event.extendedProps.event_id !== undefined && event.extendedProps.event_id !== null) {
      // let event_id = this.commonService.encryptData(event.extendedProps.event_id);
      /* this.commonService.addAppNotification('Event Reschedule', 'Event Reschedule by ' + this.userInfo.first_name + ' ' + this.userInfo.last_name + ' for ' + event.title,
        [event.extendedProps.businessProfile ? event.extendedProps.businessProfile.id : event.extendedProps.id],
        'SCHEDULE', this.userInfo.id); */
      //   event_id = encodeURIComponent(event_id);
      //   this.router.navigate(['/calendar/reschedule/' + event_id]);
      // this.dialogRef.close({});
      event.action = 'reschedule'
      const initialState: any = { data: event }
      this.dialog.open(UpdateScheduleComponent, {
        autoFocus: false,
        panelClass: 'update-schedule-dialog',
        id: 'updateScheduleDialog',
        data: initialState
      })
      // this.bsModalRef = this.bsModalService.show(UpdateScheduleComponent, {
      //   ariaLabelledBy: "modal-basic-title",
      //   class: "update-schedule-dialog",
      //   backdrop: "static",
      //   initialState,
      // });
      this.dialogRef.close({})
    } else {
      this.updateEvent(event.extendedProps.startDate, event.extendedProps.endDate, event)
    }
  }

  updateEvent(startDate: any, endDate: any, event) {
    this.dialogRef3 = this.dialog.open(CalendarEventDialogComponent, {
      panelClass: 'calendar-event-dialog',
      data: {
        dateSlots: [this.events],
        post_id: this.roleDetails.id,
        action: 'edit',
        startDate,
        endDate,
        event
      }
    })
    this.dialogRef3.afterClosed().subscribe((res) => {
      if (!res) {
        return
      } else {
        if (res.changed) {
          this.updatedEvent = res.events
        }
      }
      // responseEvent.actions = this.actions;
      // this.currentEvents.push(responseEvent);
      this.dialogRef3 = Object.create(null)
      this.refresh.next()
      let updatedEvent = this.updatedEvent
      this.dialogRef.close({ action: 'save', changed: true, updatedEvent })
    })
  }
}
