import { Component, Inject, OnInit } from '@angular/core'
import { FormBuilder, Validators } from '@angular/forms'
import { Router } from '@angular/router'
import { BsModalRef } from 'ngx-bootstrap/modal'
import { ToastrService } from 'ngx-toastr'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { CRUDService } from 'src/app/crud.service'
import { AuthService } from 'src/app/shared/auth/auth.service'

@Component({
  selector: 'app-resend-link-email',
  templateUrl: './resend-link-email.component.html',
  styleUrls: ['./resend-link-email.component.scss']
})
export class ResendLinkEmailComponent implements OnInit {
  submitted: boolean = false

  bsModalRef: BsModalRef
  checkEmailFlag: boolean = false
  showError: boolean = false

  resendLimkEmailForm = this.fb.group({
    email: [
      '',
      [
        Validators.required,
        Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,3}$'),
        Validators.maxLength(200)
      ]
    ]
  })

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<ResendLinkEmailComponent>,
    private crudService: CRUDService,
    private authService: AuthService,
    private router: Router,
    private toastr: ToastrService
  ) {}

  async ngOnInit(): Promise<void> {}

  async onSubmit() {
    // const data: any = await this.crudService
    //   .getDataByField(
    //     "users",
    //     "email_id",
    //     this.resendLimkEmailForm.controls.email.value
    //   )
    //   .toPromise();
    let data = {}
    if (false) {
      this.showError = true
    } else {
      // if (data[0].status !== "0") {
      if (false) {
        this.toastr.warning('You are already verified, Please login with your email address')
        this.dialogRef.close()
        setTimeout(() => {
          this.router.navigateByUrl('/authentication/login/candidate', {
            state: {}
          })
        }, 1000)
      } else {
        const json = {
          email: this.resendLimkEmailForm.controls.email.value,
          // userName: data[0].first_name + " " + data[0].last_name,
          host: this.crudService.getAPPRoot() + 'authentication/activate/'
        }
        await this.authService
          .sendVerificationEmail(json.email, json.host)
          .toPromise()
          .then((data) => {
            if (data.success) {
              this.dialogRef.close()
              this.toastr.success('Mail sent successfully, Please check your mail')
              setTimeout(() => {
                this.router.navigateByUrl('/authentication/login/candidate', {
                  state: {}
                })
              }, 1000)
            } else {
              this.toastr.error('Mail not sent. Please try again later')
            }
          })
          .catch((error) => {
            this.toastr.error('Error: Mail not sent. Please try again later')

            console.log('error', error)
          })
      }
    }
  }
}
