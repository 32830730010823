import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal'
import { getSessionValue } from 'src/app/common-utils'
import { ValuesMapService } from 'src/app/shared/services/values-map.service'
import { CandidateService } from 'src/app/shared/services/candidate.service'
import { UserService } from 'src/app/shared/services/user.service'
import { environment } from 'src/environments/environment'
import { DeacrivateConfirmationComponent } from '../../modals/deacrivate-confirmation/deacrivate-confirmation.component'

@Component({
  selector: 'app-deactivate-account',
  templateUrl: './deactivate-account.component.html',
  styleUrls: ['./deactivate-account.component.scss']
})
export class DeactivateAccountComponent implements OnInit {
  role: any
  previousPath: any
  reasonList: any = []
  reason: any
  bsModalRef: BsModalRef
  notificationIcon: boolean = false
  constructor(
    private router: Router,
    private valuesService: ValuesMapService,
    private candidateService: CandidateService,
    private userService: UserService,
    private modalService: BsModalService
  ) {}

  ngOnInit(): void {
    this.previousPath = '/dashboards/setting/deactivate-account'
    this.role = localStorage.getItem('role')
    this.valuesService
      .getFilteredValuesMap(10, 0, '', 'DEACTIVATE_REASON')
      .subscribe(({ data }) => {
        const sortedData = data.items.sort((a, b) => a.display_order - b.display_order)
        let names = sortedData.map(function (item) {
          return item.description
        })
        this.reasonList = names
      })
    this.notificationIcon = environment.notificationShow
  }
  closeDeactivateAccount() {
    if (this.role === 'candidate') {
      this.router.navigate(['/dashboards/candidate-settings'])
    } else if (this.role === 'business') {
      this.router.navigate(['/dashboards/business-settings'])
    } else if (this.role === 'partner') {
      this.router.navigate(['/dashboards/partner-settings'])
    } else {
      this.router.navigate(['/dashboards/candidate-settings'])
    }
  }
  async deactivateAccount() {
    const sessionValue = getSessionValue('USER_INFO')
    this.bsModalRef = this.modalService.show(DeacrivateConfirmationComponent)
    this.bsModalRef.content.messageEvent.subscribe(async (data1) => {
      if (data1) {
        // let text = new Date().toLocaleDateString().toString().replace("/", "_");
        sessionValue.status = 4
        // sessionValue.email = sessionValue.email;
        // sessionValue.username = sessionValue.email + "_" + text;
        // sessionValue.email_id = sessionValue.email + "_" + text;
        await this.userService.updateSelf(sessionValue).toPromise()
        /* const json = {
          userId: sessionValue.id,
        };
        await this.CRUDService.sendMailForDeactivation(json).toPromise(); */
      }
    })
  }
}
