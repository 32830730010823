<div
  fxFlex.gt-lg="100"
  fxFlex.gt-md="100"
  fxFlex.gt-xs="100"
  fxFlex="100"
  class="calendar-container ease-in"
>
  <div class="row w-100 mx-0">
    <div [ngClass]="enableCloseButton || !showProfileView ? 'col-md-12 col-lg-12' : 'col-md-8'">
      <div class="d-flex postsBox flex-wrap center-btn mt-3">
        <h3 class="mb-3">Event Calendar</h3>
        <div>
          <mat-button-toggle-group
            class="toggleButton"
            [(ngModel)]="calendarVisible"
            name="selectedView"
            aria-label="Selected View"
          >
            <mat-button-toggle class="mb-2 mr-2" value="calendar" (click)="changeCalendarView()"
              >Calendar View</mat-button-toggle
            >
            <mat-button-toggle class="mb-2 mr-2" value="list">List View </mat-button-toggle>
            <!-- <mat-button-toggle class="mb-2 mr-2" value="pastEvents">Past Events </mat-button-toggle> -->
          </mat-button-toggle-group>
        </div>
        <div (click)="closeModal()" style="display: flex">
          <button class="close-btn">
            <mat-icon class="font-18">clear</mat-icon>
          </button>
        </div>
      </div>
      <div class="postsBox mt-2 mb-3 calendar-view-container" *ngIf="calendarVisible == 'calendar'">
        <full-calendar #calendar [options]="calendarOptions"></full-calendar>
      </div>

      <div
        class="postsBox mt-2 calendar-view-container mb-3"
        style="margin-top: 15px"
        *ngIf="calendarVisible == 'list'"
      >
        <app-list-view-events [roleId]="roleDetails?.id"></app-list-view-events>
        <!-- <div fxLayout="row wrap">
					<div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100" class="text-center">
						<h4 class="m-t-0 p-l-5">Available Slots Between <b>{{fromDate | date:'longDate'}} and
								{{toDate | date:'longDate'}}</b></h4>
					</div>
					<div class="row col-12 buttons-div">
						<button mat-raised-button class="primaryButton mr-2" [disabled]="currentWeek"
							title="Current Week" (click)="currentWeekSlots()">
							<mat-icon>first_page</mat-icon>
						</button>
						<button mat-raised-button class="primaryButton mr-2" [disabled]="currentWeek"
							title="Previous Week" (click)="previous()">
							<mat-icon>arrow_backward</mat-icon>
						</button>
						<button mat-raised-button class="primaryButton" title="Next Week" (click)="next()">
							<mat-icon>arrow_forward</mat-icon>
						</button>
					</div>
				</div>

				<div *ngFor="let date of displaySlots">
					{{date.date | date:'longDate'}}
					<button class="primaryButton" (click)="editSlot(date.date)" aria-label="ADD">
						<mat-icon>edit</mat-icon>
					</button>
					<ul class="scheduleSlots">
						<div *ngFor="let slot of date.slots" class="uldiv">
							<div class="secondaryButton" (click)='dateSlotSelected(slot, date.date)'>
								{{slot.viewValue}}
							</div>
						</div>
					</ul>
				</div> -->
      </div>
      <div class="postsBox mt-2 mb-2" *ngIf="calendarVisible == 'pastEvents'">
        <app-past-events></app-past-events>
      </div>
    </div>

    <div
      *ngIf="userInfo?.user_type === '1' && showProfileView"
      [ngClass]="enableCloseButton ? 'col-md-0 col-lg-0' : 'col-md-4'"
      class="mt-3"
    >
      <app-candidate-profile-overview-verified></app-candidate-profile-overview-verified>
    </div>
    <div
      *ngIf="userInfo?.user_type != '1' && showProfileView"
      [ngClass]="enableCloseButton ? 'col-md-0 col-lg-0' : 'col-md-4 pl-4'"
      class="mt-3"
    >
      <app-profile-overview-verified></app-profile-overview-verified>
    </div>

    <!-- <div class="pr-0  pl-0 calendar" *ngIf="calendarVisible=='calendar'"
			[ngClass]="enableCloseButton?'col-md-12 col-lg-12':'col-md-8'">
			<div class="postsBox" style="height: 87vh;
				overflow: auto;">
				<full-calendar #calendar [options]="calendarOptions" [plugins]="calendarPlugins"></full-calendar>
			</div>

		</div> -->
  </div>

  <!-- <div class="row pl-4 pr-4" style="height: 100vh;
				overflow: auto;" *ngIf="userInfo?.user_type === '1'">
		<div class="pr-0 mt-2 pl-0" *ngIf="calendarVisible=='calendar'"
			[ngClass]="enableCloseButton?'col-md-12 col-lg-12':'col-md-8'">
			<div class="postsBox calend">
				<full-calendar #calendar [options]="calendarOptions"></full-calendar>
			</div>

		</div>
		<div [ngClass]="enableCloseButton?'col-md-0 col-lg-0':'col-md-4'" class="res-mr">
			<app-candidate-profile-overview-verified></app-candidate-profile-overview-verified>
		</div>

	</div> -->
</div>

<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
  <p style="color: white">Please Wait.</p>
</ngx-spinner>
