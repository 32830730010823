import { Component, HostListener, Input, OnInit } from '@angular/core'
import { getSessionValue } from './common-utils'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { SocialAuthService, GoogleLoginProvider, SocialUser } from 'angularx-social-login'
import { Router, NavigationStart } from '@angular/router'
import { Observable, Subscription } from 'rxjs'
import { CommonService } from './common.service'
import { CRUDService } from './crud.service'
import { ToastrService } from 'ngx-toastr'
import 'babel-polyfill'
import 'rxjs/add/observable/interval'
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal'
import { NotificationPermissionComponent } from './dashboards/modals/notification-permission/notification-permission.component'
import { environment } from 'src/environments/environment'
import { AuthService } from './shared/auth/auth.service'
import { UserWithToken } from './core/models/auth'
import { USER_TYPE } from './core/models/user'
import { email } from 'ngx-custom-validators/src/app/email/validator'

declare let pendo: any
declare var $: any
// const config = {
//   apiKey: "AIzaSyBl9LkCRlir_W-yy0VOUIYq7zitBEOviso",
//   authDomain: "upplft.firebaseapp.com",
//   databaseURL: "https://upplft-default-rtdb.firebaseio.com",
//   projectId: "upplft",
//   storageBucket: "upplft.appspot.com",
//   messagingSenderId: "557339656709",
//   appId: "1:557339656709:web:c4b172a053c271fc5f926c",
//   measurementId: "G-DN0FTDERDL",
// };
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  loginForm: FormGroup
  socialUser: SocialUser
  isLoggedin: boolean
  subscription: Subscription
  socket: any
  notifications: any[] = []
  users: any[] = []
  userInfo: UserWithToken
  sub: any
  bsModalRef: BsModalRef
  bsModalRef1: BsModalRef
  pushSubscriber: any
  @Input() event: Observable<void>
  @HostListener('contextmenu', ['$event'])
  onRightClick(event) {
    event.preventDefault()
  }

  constructor(
    private formBuilder: FormBuilder,
    private socialAuthService: SocialAuthService,
    private router: Router,
    private authService: AuthService,
    private commonService: CommonService,
    private CrudService: CRUDService,
    private toastr: ToastrService,
    private bsmodalService: BsModalService
  ) {
    /* this.socket = this.commonService.initializationSocket();
		firebase.initializeApp(config); */
    // window.history.forward()
    // window.history.forward();
    // this.router.events.subscribe((event) => {
    //   if (event instanceof NavigationStart) {
    //     if (
    //       event.url.indexOf("/authentication") > -1 ||
    //       event.url.indexOf("/calendar") > -1 ||
    //       event.url.indexOf("/view-job") > -1 ||
    //       event.url.indexOf("/view-candidate") > -1 ||
    //       event.url.indexOf("/personality-builder") > -1
    //     ) {
    //       if (event.url.indexOf("/authentication") > -1) {
    //         sessionStorage.setItem("USER_INFO", "");
    //       }
    //     } else {
    //       if (
    //         sessionStorage.getItem("USER_INFO") === null ||
    //         sessionStorage.getItem("USER_INFO") === ""
    //       ) {
    //         router.navigate(["/authentication/login-option"]);
    //       }
    //       // if (!this.authService.currentAdminValue) {
    //       //   console.log("I stopped redirection")
    //       //   // router.navigate(["/authentication/login-option"]);
    //       // }
    //     }
    //   }
    // });
  }
  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    event.preventDefault()
    return false
  }

  // onRightClick(event) {
  // 	event.preventDefault();
  // }
  async ngOnInit() {
    $('.modal-dialog').bind('contextmenu', function () {
      return false
    })
    $('.modal-content').on('contextmenu', function () {
      // Display the message
      alert('Element 2 message')
    })
    $('.modal-dialog').contextmenu(function () {
      alert('Handler for .contextmenu() called.')
      return false
    })
    this.userInfo = getSessionValue('USER_INFO')

    if (JSON.stringify(this.userInfo) !== '{}' && JSON.stringify(this.userInfo) !== 'null') {
      let role: string = ''
      if (this.userInfo?.user?.user_type == USER_TYPE.CANDIDATE) {
        role = 'candidate'
      } else if (this.userInfo?.user?.user_type == USER_TYPE.BUSINESS) {
        role = 'business'
      } else if (this.userInfo?.user?.user_type == USER_TYPE.ADMIN) {
        role = 'admin'
      } else if (this.userInfo?.user?.user_type == USER_TYPE.PARTNER) {
        role = 'partner'
      }
      pendo.initialize({
        visitor: {
          id: this.userInfo.user?.email,
          name: `${this.userInfo?.user?.first_name} ${this.userInfo?.user?.last_name}`,
          email: this.userInfo?.user?.email,
          role: role
        },
        account: { id: environment.pendoAccountId, name: 'Upplft' }
      })
    }
    this.loginForm = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required]
    })
    /*this.socket.emit('msg', { data: '1111111111' });
		 if (this.socket !== undefined) {
			if (Object.keys(this.userInfo)?.length !== 0) {
				//this.socket?.emit('msg', { data: '1111111111' });
				this.sub = Observable.interval(1000 * 60).subscribe((val) => {
					this.commonService.getUnreadCount();
				});
				this.socket.on('getNotification', (data: any) => {
					this.getUserOfChannels(data.room);
					this.commonService.changeNotificationMessage('Hello from Sibling');
				});
				const channels: any = await this.CrudService.getDataByField(
					'ChatChannels',
					'status',
					1,
				).toPromise();
				channels.map((channel: any) => {
					this.socket.emit('join', channel.channel_id);
				});
			}
		} */
    // this.subscription = this.commonService.dataToBePassedObs.subscribe(
    //   async (message: any) => {
    //     this.userInfo = getSessionValue("USER_INFO");
    //     if (this.userInfo !== undefined && this.userInfo !== null) {
    //       if (Object.keys(this.userInfo)?.length !== 0) {
    //         // await this.getNotificationPermission();
    //         //this.commonService.getUnreadCount();
    //       }
    //     }
    //   }
    // );
  }
  // disable chat
  // async getUserOfChannels(data: any) {
  //   this.users = [];
  //   const channels: any = await this.CrudService.getDataByField(
  //     "ChatChannels",
  //     "channel_id",
  //     data.id
  //   ).toPromise();
  //   let channelUsers: any = await this.CrudService.getDataByField(
  //     "ChatChannelUsers",
  //     "chat_channel_id",
  //     channels[0].id
  //   ).toPromise();
  //   channelUsers.map((user: any) => {
  //     if (user.user_id !== this.userInfo.id) {
  //       this.users.push(user.user_id);
  //     }
  //   });
  //   this.users.map(async (user: any) => {
  //     const json = {
  //       updated_at: null,
  //       updated_by: null,
  //       status: 1,
  //       user_id: user,
  //       message: data.msg,
  //       read_status: 0,
  //       read_on: null,
  //       ref_type: "chat",
  //       ref_id: data.sender_id,
  //       title: "New Chat Notification",
  //     };
  //     await this.commonService.updateRecord("AppNotifications", json);
  //     if (data.sender_id !== this.userInfo.id) {
  //       this.commonService.getUnreadCount();
  //       if (user === this.userInfo.id) {
  //         this.toastr.success(data.msg, data.channel_name);
  //       }
  //     }
  //   });
  // }
  loginWithGoogle(): void {
    this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID)
  }

  logOut(): void {
    this.socialAuthService.signOut()
  }
  async getNotificationPermission() {
    const self = this
    if (this.userInfo) {
      let data: any = await this.CrudService.getDataByField(
        'PushSubscribers',
        'user_id',
        this.userInfo?.user?.id
      ).toPromise()
      this.pushSubscriber = data[0]
      if (data.length !== 0) {
        Notification.requestPermission(async function (status) {
          if (status === 'granted') {
            self.pushSubscriber.user_consent_yn = 'Y'
            await self.commonService.updateRecord('PushSubscribers', self.pushSubscriber)
            const json = {
              to: self.pushSubscriber.device_id,
              message: 'hello 3',
              title: 'title text',
              url: '/',
              image:
                'https://images.unsplash.com/photo-1593642633279-1796119d5482?ixlib=rb-1.2.1&ixid=MnwxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=688&q=80'
            }
            await self.CrudService.sendPushNotification(json).toPromise()
            if (self.pushSubscriber.device_id === '') {
              self.openModal()
            }
          } else if (status === 'denied') {
            self.pushSubscriber.user_consent_yn = 'N'
            await self.commonService.updateRecord('PushSubscribers', self.pushSubscriber)
          }
        })
      } else {
        this.openModal()
      }
    }
  }
  openModal() {
    this.bsModalRef1 = this.bsmodalService.show(NotificationPermissionComponent, {
      ariaLabelledBy: 'modal-basic-title',
      class: 'modal-lg',
      backdrop: 'static'
    })
  }
  // async getUnreadCount() {
  //   let data: any = await this.CrudService.getUnreadCount().toPromise();
  //   let notificationCount: any = data.filter((val: any) => (val.user_id === this.userInfo.id));
  //   if (notificationCount.length > 0) {
  //     sessionStorage.setItem('notification_count', notificationCount[0].unread_count);
  //   } else {
  //     sessionStorage.setItem('notification_count', '0');
  //   }
  // }
}
