<!-- ============================================================== -->
<!-- Main wrapper - style you can find in pages.scss -->
<!-- ============================================================== -->
<div
  class="main-container ease-in"
  [dir]="dir"
  [ngClass]="{
    minisidebar: minisidebar,
    boxed: boxed,
    danger: danger,
    blue: blue,
    green: green,
    horizontal: horizontal
  }"
>
  <!-- ============================================================== -->
  <!-- Topbar - style you can find in header.scss -->
  <!-- ============================================================== -->

  <mat-toolbar style="display: none" color="primary" class="topbar relative">
    <div class="w-100 fix-width-for-horizontal">
      <!-- ============================================================== -->
      <!-- Logo - style you can find in header.scss -->
      <!-- ============================================================== -->
      <div class="navbar-header">
        <a class="navbar-brand" href="/">
          <!-- Logo icon -->
          <b>
            <img
              src="assets/images/upplft/Upplft_logo.png"
              alt="homepage"
              class="dark-logo"
              style="width: 120px; margin-bottom: 44px; margin-top: 50px"
            />
            <!-- Light Logo icon -->
            <img
              src="assets/images/upplft/Upplft_logo.png"
              alt="homepage"
              class="light-logo"
              style="width: 120px; margin-bottom: 44px; margin-top: 50px"
            />
          </b>
        </a>
      </div>
      <span fxFlex></span>
      <!-- ============================================================== -->
      <!-- app header component - style you can find in header.scss / header.component.ts-->
      <!-- ============================================================== -->
      <div *ngIf="!horizontal; else horizontalheader">
        <app-vertical-header></app-vertical-header>
      </div>

      <ng-template #horizontalheader>
        <app-horizontal-header></app-horizontal-header>
      </ng-template>

      <!-- ============================================================== -->
      <!-- Right sidebar toggle - style you can find in rightsidebar.component.scss -->
      <!-- ============================================================== -->

      <button (click)="end.toggle()" mat-icon-button class="ml-xs overflow-visible">
        <mat-icon>settings</mat-icon>
      </button>
    </div>
  </mat-toolbar>
  <!-- ============================================================== -->
  <!-- End Topbar - style you can find in pages.scss -->
  <!-- ============================================================== -->
  <mat-sidenav-container
    class="example-sidenav-container"
    [style.marginTop.px]="mobileQuery.matches ? 0 : 0"
    [ngClass]="{ minisidebar: minisidebar }"
  >
    <!-- ============================================================== -->
    <!-- Sidebar - style you can find in sidebar.scss -->
    <!-- ============================================================== -->
    <!-- [mode]="mobileQuery.matches ? 'side' : 'over'" -->
    <mat-sidenav
      #snav
      id="snav"
      class="pl-xs verticalMenu"
      mode="side"
      fixedTopGap="0"
      [opened]="mobileQuery.matches"
      [disableClose]="mobileQuery.matches"
      (open)="sidebarOpened = true"
      (close)="sidebarOpened = false"
    >
      <div style="height: 100%">
        <perfect-scrollbar
          *ngIf="!horizontal; else horizontalsidebar"
          class="scbar"
          [config]="config"
        >
          <app-vertical-sidebar class="app-sidebar" [showClass]="status" (notify)="snav.toggle()">
          </app-vertical-sidebar>
        </perfect-scrollbar>
      </div>
      <div
        style="
          margin-top: 2%;
          position: fixed;
          font-family: Montserrat;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 22px;
          letter-spacing: 0em;
          text-align: right;
          margin-left: 4%;
        "
      >
        &#169; 2023 Upplft Pty Ltd
      </div>

      <ng-template #horizontalsidebar>
        <app-horizontal-sidebar></app-horizontal-sidebar>
      </ng-template>
    </mat-sidenav>

    <!-- <mat-sidenav-content>
      <mat-toolbar style="width: fit-content;">
        <div class="btn-css">
          <button type="button" class="side-button" mat-button (click)="snav.toggle()">
            <mat-icon>menu</mat-icon>
          </button>
        </div>

      </mat-toolbar>

      <div class="ylb-app-content">
        <ng-content></ng-content>
      </div>
    </mat-sidenav-content> -->

    <!-- ============================================================== -->
    <!-- Sidebar - style you can find in sidebar.scss -->
    <!-- ============================================================== -->
    <!-- ============================================================== -->
    <!-- Right Sidebar - style you can find in rightsidebar.component.scss -->
    <!-- ============================================================== -->
    <mat-sidenav #end position="end" class="chat-panel" mode="over" opened="false">
      <div class="scroll">
        <mat-nav-list>
          <h3 mat-subheader class="text-uppercase font-weight-bold">Settings</h3>
          <mat-list-item *ngIf="!minisidebar">
            <mat-slide-toggle color="warn" [(ngModel)]="horizontal">
              Horizontal Layout</mat-slide-toggle
            >
          </mat-list-item>
          <mat-list-item>
            <mat-slide-toggle color="warn" (change)="dir = dir == 'rtl' ? 'ltr' : 'rtl'"
              >RTL
            </mat-slide-toggle>
          </mat-list-item>
          <mat-list-item>
            <mat-slide-toggle color="warn" [(ngModel)]="boxed">Boxed Layout</mat-slide-toggle>
          </mat-list-item>
          <mat-list-item *ngIf="!horizontal">
            <mat-slide-toggle [(ngModel)]="minisidebar">Mini Sidebar</mat-slide-toggle>
          </mat-list-item>
          <mat-divider></mat-divider>

          <h3 mat-subheader class="text-uppercase font-weight-bold">Colors</h3>

          <mat-list-item>
            <mat-checkbox
              color="warn"
              [(ngModel)]="danger"
              (change)="green = blue = false"
              class="text-danger"
              >Red
            </mat-checkbox>
          </mat-list-item>

          <mat-list-item>
            <mat-checkbox
              color="warn"
              [(ngModel)]="green"
              (change)="danger = blue = false"
              class="text-megna"
              >Teal Green</mat-checkbox
            >
          </mat-list-item>

          <mat-list-item>
            <mat-checkbox
              color="warn"
              [(ngModel)]="blue"
              (change)="green = danger = false"
              class="text-info"
              >Blue
            </mat-checkbox>
          </mat-list-item>

          <mat-list-item>
            <mat-checkbox color="warn" [(ngModel)]="dark" (change)="darkClick()"
              >Dark
            </mat-checkbox>
          </mat-list-item>
        </mat-nav-list>
      </div>
    </mat-sidenav>

    <!-- ============================================================== -->
    <!-- Page container - style you can find in pages.scss -->
    <!-- ============================================================== -->

    <mat-sidenav-content class="page-wrapper" [ngClass]="status ? 'minitoggle' : ''">
      <div class="page-content">
        <div class="btn-css">
          <button
            type="submit"
            class="side-button"
            id="side-nav-btn"
            mat-button
            (click)="snav.toggle()"
          >
            <mat-icon>menu</mat-icon>
          </button>
        </div>
        <div
          class="content-css ease-in"
          id="main-content"
          (click)="!mobileQuery.matches ? snav.close() : ''"
        >
          <router-outlet>
            <app-spinner></app-spinner>
          </router-outlet>
        </div>
      </div>
    </mat-sidenav-content>
    <!-- ============================================================== -->
    <!-- Page container - style you can find in pages.scss -->
    <!-- ============================================================== -->
  </mat-sidenav-container>

  <!-- <div class="btn-css">
    <button type="button" class="side-button" mat-button (click)="snav.toggle()">
      <mat-icon>menu</mat-icon>
    </button>
  </div> -->
</div>
