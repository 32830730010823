import { Injectable } from '@angular/core'
import { HttpClient, HttpParams } from '@angular/common/http'
import { environment } from '../../../environments/environment'
import { map } from 'rxjs/operators'
import { ApiListResponse, ApiResponse, PaginationParams } from 'src/app/core/models/base'
import {
  AllBussinessesResponse,
  BusinessBasicStatsResponse,
  BusinessBookedTimeslotsResponse,
  BusinessProfile
} from 'src/app/core/models/business'
import { User } from 'src/app/core/models/user'

@Injectable()
export class BusinessService {
  constructor(private http: HttpClient) {}

  private apiRoot = environment.apiUrl + '/v1'

  private apiV2Root = environment.apiUrl + '/v2'

  inviteNewBusiness(data) {
    return this.http.post<ApiResponse<{}>>(`${this.apiV2Root}/admin/business/invite`, data).pipe(
      map(
        (res) => {
          return res
        },
        (err) => {
          console.log('Invite New Business', err)
        }
      )
    )
  }

  create(data) {
    return this.http.post<ApiResponse<BusinessProfile>>(`${this.apiRoot}/business`, data).pipe(
      map(
        (res) => {
          return res
        },
        (err) => {
          console.log('Create Business', err)
        }
      )
    )
  }

  update(data) {
    return this.http
      .put<ApiResponse<BusinessProfile>>(`${this.apiRoot}/business/${data.id}`, data)
      .pipe(
        map(
          (res) => {
            return res
          },
          (err) => {
            console.log('Update Business', err)
          }
        )
      )
  }

  updateSelf(businessUpdate: any) {
    return this.http
      .put<ApiResponse<BusinessProfile>>(`${environment.apiUrl}/v1/businesses/me`, businessUpdate)
      .pipe(
        map(
          (res) => {
            return res
          },
          (err) => {
            console.log('Update Own Business Profile', err)
          }
        )
      )
  }

  getBusiness(id: number | string) {
    return this.http.get<ApiResponse<BusinessProfile>>(`${this.apiRoot}/business/${id}`).pipe(
      map(
        (res) => {
          return res
        },
        (err) => {
          console.log('Get Business', err)
        }
      )
    )
  }

  getAllBusinesses({
    limit = 50,
    offset = 0,
    order = 'asc',
    sort = 'created_at',
    q = '',
    status = [0, 1]
  }: PaginationParams<BusinessProfile>) {
    return this.http
      .get<ApiResponse<AllBussinessesResponse>>(`${this.apiRoot}/business`, {
        params: new HttpParams()
          .set('limit', limit.toString())
          .set('offset', offset.toString())
          .set('order', order)
          .set('sort', sort)
          .set('q', q)
          .set('status', status.join(','))
      })
      .pipe(
        map(
          (res) => {
            return res
          },
          (err) => {
            console.log('candidate onboarding', err)
          }
        )
      )
  }

  getBusinessOwner(businessId: number) {
    return this.http.get<ApiResponse<User>>(`${this.apiRoot}/business/${businessId}/user`).pipe(
      map(
        (res) => {
          return res
        },
        (err) => {
          console.log('Get User by Business ID', err)
        }
      )
    )
  }

  getAllBusinessEvents(limit: number = 50, offset: number = 0, q: string = '') {
    return this.http
      .get<ApiListResponse<any>>(`${this.apiRoot}/businesses/me/events`, {
        params: new HttpParams()
          .set('limit', limit.toString())
          .set('offset', offset.toString())
          .set('q', q)
      })
      .pipe(
        map(
          (res) => {
            return res
          },
          (err) => {
            console.log('business events', err)
          }
        )
      )
  }

  getBusinessBasicStats() {
    return this.http
      .get<ApiResponse<BusinessBasicStatsResponse>>(`${this.apiRoot}/business/stats`)
      .pipe(
        map(
          (res) => {
            return res
          },
          (err) => {
            console.log('Business basic stats', err)
          }
        )
      )
  }

  getBusinessBookedTimeslots(limit: number = 0, offset: number = 0) {
    return this.http
      .get<ApiListResponse<BusinessBookedTimeslotsResponse>>(
        `${this.apiRoot}/businesses/me/booked-timeslots`,
        {
          params: new HttpParams().set('limit', limit.toString()).set('offset', offset.toString())
        }
      )
      .pipe(
        map(
          (res) => {
            return res
          },
          (err) => {
            console.log('Business booked timeslots', err)
          }
        )
      )
  }
}
