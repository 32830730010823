<div
  class="body ease-in"
  fxLayout="row wrap"
  style="box-sizing: border-box; max-width: 100%; padding: 10px 0px 10px 10px"
>
  <div fxFlex.gt-lg="96" fxFlex.gt-md="96" fxFlex.gt-xs="96" fxFlex="96">
    <div class="row col-12 pl-0">
      <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 business-profile">
        <app-verified-business-profile
          [businessProfileId]="businessProfileId"
          [events]="eventSubject.asObservable()"
          [isEditFlag]="isEditFlag"
        ></app-verified-business-profile>
        <!-- <div class="postsBox mt-2">
                    <div class="div-btn mb-2" (click)="openViewProfile('all');cancel()">
                        <label class="mb-0" style="font-weight: 600;">Business Users</label>
                    </div>
                </div> -->
      </div>
      <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12">
        <div class="postsBox">
          <div class="font-18 pl-3 pr-3 mt-2">
            <div class="">
              <label class="profile-heading-text">About {{ businessProfile?.name }}</label>
              <!-- <img src="../../../../assets/images/chat-icon/Edit.svg" class="icon-css"
                                *ngIf="!editAboutUsFlag" (click)="editAboutUs()"> -->
              <!-- <mat-icon (click)="submitData();editAboutUsFlag = !editAboutUsFlag">
                                check_circle_outline
                            </mat-icon> -->
              <button *ngIf="isEditFlag" mat-raised-button class="save-btn" (click)="submitData()">
                Save<i class="fa fa-bookmark-o ml-2" aria-hidden="true"></i>
              </button>
            </div>
            <!-- <div>
                            <button mat-raised-button class="more-btn">More</button>
                        </div> -->
          </div>
          <div class="pl-3 pr-3 mt-4 font-14" color="#0C2149">
            <!-- <span class="word-break-css">{{!editAboutUsFlag?aboutus:''}}</span> -->
            <mat-form-field appearance="fill">
              <textarea matInput rows="5" [(ngModel)]="aboutus" [disabled]="!isEditFlag"></textarea>
            </mat-form-field>
          </div>
        </div>
        <hr />
        <div class="postsBox mb-3">
          <div class="pl-3 pr-3 mt-2">
            <div>
              <label class="profile-heading-text font-18">Company Headquarters</label>
              <!-- <img src="../../../../assets/images/chat-icon/Edit.svg" (click)="editLocation()"
                                *ngIf="!locationFlag" class="icon-css">
                            <mat-icon *ngIf="locationFlag" (click)="submitData();locationFlag = !locationFlag">
                                check_circle_outline
                            </mat-icon> -->
            </div>
            <!-- <div class="font-14" color="#0C2149" *ngIf="!locationFlag">
                            {{location}}
                        </div> -->
            <div class="pl-2 edit-div">
              <mat-form-field class="example-full-width" appearance="fill">
                <mat-select
                  #matSelect
                  [(ngModel)]="location"
                  name="location"
                  placeholder="Select Location"
                  disableOptionCentering
                  panelClass="myPanelClass2"
                  [disabled]="!isEditFlag"
                >
                  <div (mouseleave)="matSelect.close()">
                    <mat-option *ngFor="let location of locationData" [value]="location">
                      {{ location }}
                    </mat-option>
                  </div>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>
        <!-- <div class="postsBox mb-3">
          <div class="pl-3 pr-3 mt-2 profile-role">
            <div class="profile-header">
              <div class="d-flex" style="width: 100%">
                <label class="profile-heading-text font-18">Leadership</label>
                <img src="../../../../assets/images/chat-icon/Edit.svg" (click)="editLeaderShip()"
                                    *ngIf="!leaderShipFlag" class="icon-css">
                                <mat-icon *ngIf="leaderShipFlag"
                                    (click)="submitLeaderShipData();leaderShipFlag = !leaderShipFlag">
                                    check_circle_outline
                                </mat-icon>
                <button
                  mat-raised-button
                  class="add-btn-1 ml-auto"
                  style="padding: 5px"
                  *ngIf="leaderShipFlag && isEditFlag"
                  (click)="addLeaderShip()"
                >
                  +
                </button>
                <mat-icon class="mat-plus" *ngIf="leaderShipFlag" (click)="addLeaderShip()">add_circle
                                </mat-icon>
              </div>
            </div>
            <div class="row col-12 pl-0 pr-0">
              <div
                class="col-md-6 col-lg-6 col-xl-6 col-sm-12 mb-2 mt-2"
                *ngFor="let leadership of leadershipArray; let i = index"
              >
                <div class="row role-content-div">
                  <div class="profile-div">
                    <div class="custom-file-upload mx-auto">
                      <ngx-dropzone
                        *ngIf="isEditFlag"
                        class="dropzone"
                        [multiple]="false"
                        (change)="uploadImageProfile($event, leadership)"
                        accept="image/jpeg, image/gif, image/png, image/webp"
                      >
                        <img
                          src="{{ leadership.img_url }}"
                          class="profile-img"
                          onerror="this.onerror=null;this.src='../../../../../../assets/images/users/Character.png'"
                        />
                        <mat-icon class="mat-plus" *ngIf="isEditFlag"
                          >add_circle</mat-icon
                        >
                      </ngx-dropzone>
                      <img
                        *ngIf="!isEditFlag"
                        src="{{ leadership.img_url }}"
                        class="profile-img"
                        onerror="this.onerror=null;this.src='../../../../../../assets/images/users/Character.png'"
                      />
                    </div>

                    <label for="file-upload{{i}}" class="custom-file-upload mx-auto">
                                            <img src="{{leadership.img_url}}" class="profile-img"
                                                onerror="this.onerror=null;this.src='../../../../../../assets/images/users/Character.png'" />

                                            <mat-icon class="mat-plus">add_circle
                                            </mat-icon>
                                        </label>
                                        <input id="file-upload{{i}}" type="file"
                                            accept="image/jpeg, image/gif, image/png, image/webp"
                                            (change)="uploadImageProfile($event,leadership);" />
                  </div>
                  <div class="pl-3" *ngIf="!leaderShipFlag">
                                        <h3 class="profile-label">{{leadership.name}}
                                        </h3>
                                        <label class="font-14"
                                            style="color: #0C2149;">{{leadership.role}}</label><br/>
                                    </div>
                  <div class="pl-2 edit-div">
                    <mat-form-field
                      class="example-full-width"
                      appearance="fill"
                    >
                      <input
                        type="text"
                        matInput
                        specialIsAlphaNumeric
                        placeholder="Full Name"
                        [(ngModel)]="leadership.name"
                        [disabled]="!isEditFlag"
                      />
                      <mat-icon
                        *ngIf="isEditFlag"
                        class="mat-cross"
                        (click)="removeLeaderShip(i)"
                        >cancel
                      </mat-icon>
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                      <input
                        type="text"
                        matInput
                        specialIsAlphaNumeric
                        placeholder="Role"
                        [(ngModel)]="leadership.role"
                        [disabled]="!isEditFlag"
                      />
                      <mat-select [(ngModel)]="userDesignation1" name="role"
                                                placeholder="Role in Business">
                                                <mat-option *ngFor="let role of roleArray" [value]="role">
                                                    {{role}}
                                                </mat-option>
                                            </mat-select>
                    </mat-form-field>
                    <button mat-raised-button color="primary" class="btn-profile-leader"
                                            (click)="addProfile(leadership)">Profile</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> -->
        <div class="postsBox mb-3">
          <div class="pl-3 pr-3 mt-2">
            <div class="profile-header">
              <div class="d-flex">
                <label class="profile-heading-text font-18">Company Values</label>
                <!-- <img src="../../../../assets/images/chat-icon/Edit.svg" (click)="editCompanyValue()"
                                    *ngIf="!editCompanyFlag" class="icon-css">
                                <mat-icon *ngIf="editCompanyFlag"
                                    (click)="submitData();editCompanyFlag = !editCompanyFlag">
                                    check_circle_outline
                                </mat-icon> -->
              </div>
            </div>
            <div class="" *ngIf="isEditFlag">
              <div class="row">
                <mat-form-field appearance="fill" class="col-xs-12 col-sm-12 col-md-10 col-lg-10">
                  <mat-icon matPrefix class="search-icon">search</mat-icon>
                  <input
                    type="text"
                    aria-label="Number"
                    [matAutocomplete]="auto"
                    matInput
                    placeholder="Type answers..."
                    [(ngModel)]="companyValue"
                    (keyup)="companyValueChange()"
                    (click)="companyValueChange()"
                  />
                  <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                    <mat-option
                      *ngFor="let option of companyFilteredOptions"
                      [value]="option"
                      (click)="companyValue = option; companyValueChange()"
                    >
                      {{ option }}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
                <button
                  mat-raised-button
                  class="upload-btn col-sm-12 col-lg-1 col-md-1"
                  (click)="addCompanyValue(companyValue); flag = true; companyValueChange()"
                  [disabled]="companyValueArray?.length > 9 || disableAddCompanyValue"
                >
                  +
                </button>
              </div>
            </div>
            <div class="details-div mt-3">
              <mat-chip-list aria-label="Company Value Selection">
                <mat-chip
                  *ngFor="let companyvalue of companyValueArray; let i = index"
                  class="chip-btn"
                >
                  {{ companyvalue }}
                  <i
                    *ngIf="isEditFlag"
                    class="fa fa-times pl-2"
                    (click)="removeCompanyValue(i); flag = true"
                  ></i>
                </mat-chip>
              </mat-chip-list>
            </div>
          </div>
        </div>
        <div class="postsBox mb-3">
          <div class="pl-3 pr-3 mt-2">
            <div class="profile-header">
              <div class="d-flex">
                <label class="profile-heading-text font-18">Company Culture</label>
                <!-- <img src="../../../../assets/images/chat-icon/Edit.svg" (click)="editCompanyCulture()"
                                     class="icon-css">
                                <mat-icon *ngIf="editCompanyCultureFlag"
                                    (click)="submitData();editCompanyCultureFlag = !editCompanyCultureFlag">
                                    check_circle_outline
                                </mat-icon> -->
              </div>
            </div>
            <div class="" *ngIf="isEditFlag">
              <div class="row">
                <mat-form-field appearance="fill" class="col-xs-12 col-sm-12 col-md-10 col-lg-10">
                  <mat-icon matPrefix class="search-icon">search</mat-icon>
                  <input
                    type="text"
                    aria-label="Number"
                    matInput
                    [matAutocomplete]="culture"
                    matInput
                    placeholder="Type answers..."
                    [(ngModel)]="companyCulture"
                    (keyup)="companyCultureChange()"
                    (click)="companyCultureChange()"
                  />
                  <mat-autocomplete autoActiveFirstOption #culture="matAutocomplete">
                    <mat-option
                      *ngFor="let option of companyCultureFilteredOptions"
                      (click)="companyCulture = option; companyCultureChange()"
                      [value]="option"
                    >
                      {{ option }}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
                <!-- <button mat-raised-button class="upload-btn col-sm-12 col-lg-2 col-md-2"
                                    (click)="addCompanyCulture()">+</button> -->
                <button
                  mat-raised-button
                  class="upload-btn col-sm-12 col-lg-1 col-md-1"
                  (click)="addCompanyCulture(companyCulture); flag = true; companyCultureChange()"
                  [disabled]="companyCultureArray?.length > 9 || disableAddcompanyCulture"
                >
                  +
                </button>
              </div>
            </div>
            <div class="details-div mt-3">
              <mat-chip-list aria-label="Company Culture Selection">
                <mat-chip
                  *ngFor="let culture of companyCultureArray; let i = index"
                  class="chip-btn"
                >
                  {{ culture }}
                  <i
                    *ngIf="isEditFlag"
                    class="fa fa-times pl-2"
                    (click)="removeCompanyCultureValue(i)"
                  ></i>
                </mat-chip>
              </mat-chip-list>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="col-lg-1 col-md-1 col-sm-12 col-xs-12">
                <button mat-raised-button class="close-btn" routerLink="/dashboards/business">
                    <mat-icon class="font-18">clear</mat-icon>
                </button>
            </div> -->
    </div>
  </div>
  <div fxFlex.gt-lg="4" fxFlex.gt-md="4" fxFlex.gt-xs="4" fxFlex="4" style="display: block">
    <button
      type="button"
      class="close-btn"
      aria-label="Close"
      data-dismiss="modal"
      (click)="cancel()"
    >
      <span aria-hidden="true" style="font-size: 30px">&times;</span>
    </button>
  </div>
</div>
<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
  <p style="color: white">Please Wait.</p>
</ngx-spinner>
