import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { environment } from 'src/environments/environment'
import { CRUDService } from 'src/app/crud.service'
@Component({
  selector: 'app-faqs',
  templateUrl: './faqs.component.html',
  styleUrls: ['./faqs.component.scss']
})
export class FaqsComponent implements OnInit {
  role: any
  questionAnswerArr: any[] = []
  previousPath: any
  notificationIcon: boolean = false
  // editor = ClassicEditor;
  constructor(
    private router: Router,
    private CRUDService: CRUDService
  ) {}
  async ngOnInit(): Promise<void> {
    this.previousPath = '/dashboards/setting/faqs'
    this.role = localStorage.getItem('role')
    let allData: any = await this.CRUDService.getAllData('AppInfomation').toPromise()
    allData = allData.sort((a, b) => a.display_seq - b.display_seq)
    this.questionAnswerArr = allData.filter((x) => x.category === 'Upplft FAQ')
    this.notificationIcon = environment.notificationShow
  }
  // onReady(editor: ClassicEditor): void {
  //   // editor.plugins.set('ImageResize')
  //   editor.enableReadOnlyMode('feature-id')
  // }
  closeFAQS() {
    if (this.role === 'candidate') {
      this.router.navigate(['/dashboards/candidate-settings'])
    } else if (this.role === 'business') {
      this.router.navigate(['/dashboards/business-settings'])
    } else if (this.role === 'partner') {
      this.router.navigate(['/dashboards/partner-settings'])
    } else {
      this.router.navigate(['/dashboards/candidate-settings'])
    }
  }
}
