// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

const apiUrl = 'https://api-dev.upplft.com'

export const environment = {
  production: false,
  appRoot: 'https://dev.upplft.com',
  apiUrl: 'https://api-dev.upplft.com', //ngrok.io',
  linkedinclientId: '86ddzvjko6mq53',
  linkedinclientsecret: 'g3sFU1keYtGbzhao',
  pendoAccountId: 'adam@upplft.com',
  menuShowHide: false,
  imageDocSize: 10999999,
  audioVideoSize: 12000000,
  notificationShow: true,
  //profile_status: true,
  fileErrorMessage: 'Uploaded image or documents must be below 10mb and videos must be below 12mb',
  imageErrorMessage: 'Only image with jpeg, gif, png and webp format are allowed',
  test: true,
  isOldRoleLink: false,
  viewSpiritAnimal: false,
  calendarMonthRestriction: true,
  createHubspotContactForPartner: true,
  isApplyForRoleWithoutProfileCompletion: false,
  // AWS S3 configuration
  awsS3Config: {
    accessKeyId: 'AKIAX4YM5CON3WA2N6HC',
    secretAccessKey: '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',
    region: 'ap-southeast-2',
    bucket: 'upplft-dev'
  },
  // Auth0 configuration
  auth0Config: {
    domain: 'upplft-dev.au.auth0.com',
    clientId: 'fXLUIUQ50KJusdRSdnB6VCiXFRsgnBrs'
  },
  auth: {
    domain: 'upplft-dev.au.auth0.com',
    clientId: 'fXLUIUQ50KJusdRSdnB6VCiXFRsgnBrs',
    authorizationParams: {
      audience: 'https://api.dev.upplft.com/',
      redirect_uri: window.location.origin,
      scope: 'openid profile email offline_access'
    },
    errorPath: '/'
  },
  httpInterceptor: {
    allowedList: [`${apiUrl}/*`, { uri: `${apiUrl}/v1/auth/*`, allowAnonymous: true }]
  }
}

/* linkedinclientId: '86ddzvjko6mq53',
   linkedinclientsecret: 'g3sFU1keYtGbzhao',*/
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
