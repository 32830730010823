<div class="modal-content" #test>
  <div class="modal-header">
    <h6 class="circularBold signUpHeading">Enter {{ title }} Details</h6>
    <button
      type="button"
      class="close ml-2"
      aria-label="Close"
      data-dismiss="modal"
      (click)="closeModal()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="detailsForm" autocomplete="off">
      <mat-form-field appearance="fill">
        <textarea matInput rows="5" formControlName="details" [(ngModel)]="details"></textarea>
      </mat-form-field>
      <div>
        <button mat-raised-button class="like-btn" (click)="submit()">Submit</button>
      </div>
    </form>
  </div>
</div>
