import { Component, OnDestroy, OnInit, ViewChild, Inject, TemplateRef } from '@angular/core'
import { NgxSpinnerService } from 'ngx-spinner'
import { CRUDService } from 'src/app/crud.service'
import { CommonService } from 'src/app/common.service'
import { ActivatedRoute } from '@angular/router'
import { getSessionValue, getArrayStringValue } from 'src/app/common-utils'
import { environment } from '../../../../environments/environment'
import * as moment from 'moment'
import { FormArray, FormBuilder, FormControl } from '@angular/forms'
import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS
} from '@angular/material-moment-adapter'
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core'
import { MatDatepicker } from '@angular/material/datepicker'
import { Moment } from 'moment'
import { Subject } from 'rxjs'
import { BsModalRef } from 'ngx-bootstrap/modal'
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { MatSelect } from '@angular/material/select'
import { NullTemplateVisitor } from '@angular/compiler'
import { FieldAlertComponent } from '../../modals/field-alert/field-alert.component'
import { MyScoreComponent } from '../../modals/my-score/my-score.component'
import { PersonalityBuilderComponent } from 'src/app/personality-builder/personality-builder.component'
import { ToastrService } from 'ngx-toastr'
import { CultureBuilderComponent } from 'src/app/culture-builder/culture-builder.component'
import { CultureScoreComponent } from '../../modals/culture-score/culture-score.component'
import { CandidateProfileDetailComponent } from '../candidate-profile-detail/candidate-profile-detail.component'
import { CandidateService } from 'src/app/shared/services/candidate.service'
import { ValuesMapService } from 'src/app/shared/services/values-map.service'
import { SkillsetService } from 'src/app/shared/services/skillset.service'
import { UserService } from 'src/app/shared/services/user.service'
import { S3Service } from 'src/app/shared/services/s3.service'
// import { FieldAlertComponent } from "../../../../app/dashboards/modals/field-alert/field-alert.component";

export const MY_FORMATS = {
  parse: {
    dateInput: 'YYYY'
  },
  display: {
    dateInput: 'YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY'
  }
}

declare var $: any
@Component({
  selector: 'app-candidate-profile-view',
  templateUrl: './candidate-profile-view.component.html',
  styleUrls: ['./candidate-profile-view.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
  ]
})
export class CandidateProfileViewComponent implements OnInit, OnDestroy {
  @ViewChild('reTakeTestModal') reTakeTestModal: TemplateRef<any>
  @ViewChild(CandidateProfileDetailComponent)
  profileDetailsComponent!: CandidateProfileDetailComponent
  flag: boolean = false
  showPencilIcon: boolean = true
  roleArray: any = []
  // skillsArray: any = [];
  editAllFlag: boolean = true
  editBioFlag: boolean = false
  editSkillFlag: boolean = false
  editCultureFlag: boolean = false
  editFrustrationsFlag: boolean = false
  editPersonalityFlag: boolean = false
  editLanguageFlag: boolean = false
  editGoalFlag: boolean = false
  editExperienceFlag: boolean = false
  editPortfolioFlag: boolean = false
  editHashtagFlag: boolean = false
  editPersonalityTypeFlag: boolean = false
  editTechnicalSkillFlag: boolean = false
  apiUrl: any
  bio: any = ''
  skillValue: any = ''
  values: any = ''
  frustrations: any = ''
  personality: any = ''
  goals: any = ''
  languagesProficiencyForm: any
  experienceCompanyName1: any = 'Company 1'
  experienceCompanyName2: any = 'Company 2'
  experienceCompanyName3: any = 'Company 3'
  companyDesignation1: any = 'Position 1'
  companyDesignation2: any = 'Position 2'
  companyDesignation3: any = 'Position 3'
  experience1From: any = moment('')
  experience1To: any = moment()
  experience2From: any = moment()
  experience2To: any = moment()
  experience3From: any = moment()
  experience3To: any = moment()
  experience1: any = ''
  experience2: any = ''
  experience3: any = ''
  imageProfile1: any
  imageProfile2: any = ''
  imageProfile3: any = ''
  resImageProfile1: any
  resImageProfile2: any = ''
  resImageProfile3: any = ''
  skillFilteredOptions: any[] = []
  valuesFilteredOptions: any[] = []
  frustrationsFilteredOptions: any[] = []
  hobbyFilteredOptions: any[] = []
  personalityFilteredOptions: any[] = []
  searchSkillValue: string[] = []
  searchValues: string[] = []
  searchFrustrationsValue: string[] = []
  qualificationsArray: string[] = []
  skillsArray: any[] = []
  valuesArr: any[] = []
  frustrationsArray: any[] = []
  personalityArray: any[] = []
  hobbiesArray: any[] = []
  languagesArr: any[] = []
  languagesProficiencyArr: any[] = []
  selectedLanguageData: any[] = []
  ckeditorContent: string = '<p>Some html</p>'
  profileData: any
  userInfo: any
  candidateProfileId: any
  candidateProfile: any
  maxDate = new Date()
  seeMoreFalg: boolean = false
  seeMoreBioFalg: boolean = false
  seeMoreGoalsFalg: boolean = false
  btnvisible: boolean = true
  languagesProficiencyData: any[] = []
  selectedLanguage: any
  selectedProficiency: any
  personalityTypeArray: any
  selectedPersonalityType: any
  eventSubject: Subject<any> = new Subject<void>()
  languagesData: any[] = []
  editFlag: boolean = true
  // editor: Editor;
  // toolbar: Toolbar = [
  //   ["underline", "bold", "italic"],
  //   ["ordered_list", "bullet_list"],
  //   ["text_color"],
  //   ["align_left", "align_center", "align_right", "align_justify"],
  // ];
  hashtagArray: any = []
  disbleHashtagAddition: boolean = false
  hashtag: any
  companyFilteredOptions: any[] = []
  searchCompanyValue: string[] = []
  companyValue: any
  disableAddCompanyValue: boolean = true
  selectedTechnicalSkill: any[] = []
  /* toolbar: Toolbar = [
    ['bold', 'italic'],
    ['underline', 'strike'],
    ['code', 'blockquote'],
    ['ordered_list', 'bullet_list'],
    [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
    ['link', 'image'],
    ['text_color', 'background_color'],
    ['align_left', 'align_center', 'align_right', 'align_justify'],
  ]; */
  loading = true
  currentlyEmployed: boolean
  yearDropdown: any = []
  domainFilteredValue: any[] = []
  domainArray: any
  selectedDomain: any
  addedDomainAndExpArray: any = []
  selectedExp: string
  expArray: any
  selectedOtherExp: any = ''
  selectedOtherExpList: string[] = []
  @ViewChild(MatSelect) matSelect: MatSelect
  otherExpData: string[]
  clonedOtherExpData: any
  salary: any
  day_rate: any
  location: any
  modalRef: BsModalRef
  isAllFieldsFilled: boolean
  viewSpiritAnimal: boolean = false
  isPersonalityTestCompleted: boolean = false
  isCultureQuizCompleted: boolean = false
  personalityFactors
  personalityTestInfo
  personalityTestDetails
  disableAddValues: boolean = true
  cultureQuizData
  cultureQuizInfo
  cultureFactors

  constructor(
    private CRUDService: CRUDService,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<CandidateProfileViewComponent>,
    private SpinnerService: NgxSpinnerService,
    private commonService: CommonService,
    private userService: UserService,
    private candidateService: CandidateService,
    private valuesMapService: ValuesMapService,
    private skillsetService: SkillsetService,
    private s3Service: S3Service,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private toastr: ToastrService
  ) {}

  dialogRef2: MatDialogRef<FieldAlertComponent> = Object.create(NullTemplateVisitor)

  profileForm = this.fb.group({
    technicalSkills: new FormControl(''),
    qualification: new FormControl(''),
    biotag: new FormControl(''),
    skillValue: new FormControl(''),
    values: new FormControl(''),
    selectedDomain: new FormControl(''),
    selectedExp: new FormControl(''),
    experienceFrom1: new FormControl(''),
    experienceTo1: new FormControl(''),
    experienceCompanyName3: new FormControl(''),
    companyDesignation3: new FormControl(''),
    experienceCompanyName2: new FormControl(''),
    companyDesignation2: new FormControl(''),
    experienceCompanyName1: new FormControl(''),
    companyDesignation1: new FormControl(''),
    frustrations: new FormControl(''),
    personality: new FormControl(''),
    seeMreBio: new FormControl(''),
    seeLessBio: new FormControl(''),
    goals: new FormControl(''),
    goals2: new FormControl(''),
    currentlyEmployed: new FormControl(''),
    selectedProficiency: new FormControl(''),
    experienceFrom2: new FormControl(''),
    experienceTo2: new FormControl(''),
    experience3From: new FormControl(''),
    experience3To: new FormControl(''),
    selectedLanguage: new FormControl(''),
    hobbies: new FormControl(''),
    selectExpectation: new FormControl(''),
    otherExpectations: new FormControl(''),
    cv_name: new FormControl(''),
    portfolio_name: new FormControl('')
  })
  async ngOnInit() {
    this.viewSpiritAnimal = environment.viewSpiritAnimal
    this.loading = true
    $('.modal-content').on('contextmenu', function () {
      // Display the message
      return false
    })
    // this.editor = new Editor();
    this.languagesProficiencyForm = this.fb.group({
      languagesField: this.fb.array([])
    })
    this.apiUrl = environment.apiUrl
    let id = this.activatedRoute.snapshot.paramMap.get('id')
    // let modalOptionData = this.data1.initialState.data
    if (typeof this.data != 'undefined') {
      if (this.data) {
        id = this.data.id
      }
    }
    if (id) {
      this.candidateProfileId = id
    } else {
      this.candidateProfile = getSessionValue('CANDIDATE_PROFILE')
      this.candidateProfileId = this.candidateProfile.id
    }

    this.loadValueMap()
    this.personalityTypeArray = [
      {
        name: 'Dog',
        image_name: 'dog.png',
        description: 'Perceptive, Supportive, Optimistic',
        long_desc:
          'Dogs are idealist organisers, driven to implement their vision of what is best for humanity. They often act as catalysts for human growth because of their ability to see potential in other people and their charisma in persuading others to their ideas.'
      },
      {
        name: 'Dolphin',
        image_name: 'dolphin.png',
        description: 'Enthusiastic, Creative, Engaging',
        long_desc:
          'Dolphins are creators with a focus on possibilities and a contagious enthusiasm for new ideas, people and activities. Energetic, warm, and passionate, ENFPs love to help other people explore their creative potential.'
      },
      {
        name: 'Narwhal',
        image_name: 'narwhal.png',
        description: 'Imaginative, Idealist, Compassionate',
        long_desc:
          'Narwhals are imaginative idealists, guided by their own core values and beliefs. To a Healer, possibilities are paramount; the reality of the moment is only of passing concern. They see potential for a better future, and pursue truth and meaning with their own flair.'
      },
      {
        name: 'Seahorse',
        image_name: 'seahorse.png',
        description: 'Mysterious, Intuitive, Nurturing',
        long_desc:
          'Seahorses are creative nurturers with a strong sense of personal integrity and a drive to help others realise their potential. Creative and dedicated, they have a talent for helping others with original solutions to their personal challenges.'
      },
      {
        name: 'Bear',
        image_name: 'bear.png',
        description: 'Powerful, Direct, Outgoing',
        long_desc:
          'Bears are strategic leaders, motivated to organise change. They are quick to see inefficiency and conceptualise new solutions, and enjoy developing long-range plans to accomplish their vision. They excel at logical reasoning and are usually articulate and quick-witted.'
      },
      {
        name: 'Octopus',
        image_name: 'octopus.png',
        description: 'Intellectual, Perceptive, Strategic',
        long_desc:
          'Octopus are analytical problem-solvers, eager to improve systems and processes with their innovative ideas. They have a talent for seeing possibilities for improvement, whether at work, at home, or in themselves.'
      },
      {
        name: 'Fox',
        image_name: 'fox.png',
        description: 'Clever, Curious, Quick',
        long_desc:
          'Foxes are inspired innovators, motivated to find new solutions to intellectually challenging problems. They are curious and clever, and seek to comprehend the people, systems, and principles that surround them.'
      },
      {
        name: 'Owl',
        image_name: 'owl.png',
        description: 'Original, Aloof, Philosophical',
        long_desc:
          'Owls are philosophical innovators, fascinated by logical analysis, systems, and design. They are preoccupied with theory, and search for the universal law behind everything they see. They want to understand the unifying themes of life, in all their complexity.'
      },
      {
        name: 'Whale',
        image_name: 'whale.png',
        description: 'Cooperative, Devoted, Conscientious',
        long_desc:
          'Executives are representatives of tradition and order, utilizing their understanding of what is right, wrong and socially acceptable to bring families and communities together. Embracing the values of honesty, dedication and dignity.'
      },
      {
        name: 'Meerkat',
        image_name: 'meerkat.png',
        description: 'Confident, Organised, Social',
        long_desc:
          'Meerkats are hardworking traditionalists, eager to take charge in organising projects and people. Orderly, rule-abiding, and conscientious, Meerkats like to get things done, and tend to go about projects in a systematic, methodical way.'
      },
      {
        name: 'Elephant',
        image_name: 'elephant.png',
        description: 'Reliable, Loyal, Principled',
        long_desc:
          'Elephants are industrious caretakers, loyal to traditions and organisations. They are practical, compassionate, and caring, and are motivated to provide for others and protect them from the perils of life.'
      },
      {
        name: 'Ant',
        image_name: 'ant.png',
        description: 'Dependable, Productive, Meticulous',
        long_desc:
          'Ants are responsible organisers, driven to create and enforce order within systems and institutions. They are neat and orderly, inside and out, and tend to have a procedure for everything they do.'
      },
      {
        name: 'Otter',
        image_name: 'otter.png',
        description: 'Charming, Energetic, Fun-Loving',
        long_desc:
          'Otters are vivacious entertainers who charm and engage those around them. They are spontaneous, energetic, and fun-loving, and take pleasure in the things around them: food, clothes, nature, animals, and especially people.'
      },
      {
        name: 'Beaver',
        image_name: 'beaver.png',
        description: 'Energetic, Playful, Adaptive',
        long_desc:
          'Beavers are energetic thrill-seekers who are at their best when putting out fires, whether literal or metaphorical. They bring a sense of dynamic energy to their interactions with others and the world around them.'
      },
      {
        name: 'Panda',
        image_name: 'panda.png',
        description: 'Spontaneous, Gentle, Present',
        long_desc:
          'Pandas are gentle caretakers who live in the present moment and enjoy their surroundings with cheerful, low-key enthusiasm. They are flexible and spontaneous, and like to go with the flow to enjoy what life has to offer.'
      },
      {
        name: 'Honey Bee',
        image_name: 'honeybee.png',
        description: 'Masterful, Responsive, Constructive',
        long_desc:
          'Bees are observant artisans with an understanding of mechanics and an interest in troubleshooting. They approach their environments with a flexible logic, looking for practical solutions to the problems at hand.'
      }
    ]
    await this.getCandidateData()
    this.getPersonalityTestData()
    this.getCultureTestInfo()
    for (let i = new Date().getFullYear(); i >= new Date().getFullYear() - 30; i--) {
      this.yearDropdown.push(i)
    }

    this.userInfo = getSessionValue('USER_INFO')
    if (this.userInfo?.user_type == '3') {
      const result = await this.userService.getById(this.data.user_id).toPromise()
      this.userInfo = result.data
    }
    if (this.userInfo?.user_type != '1') {
      this.editAllFlag = false
    }
    this.SpinnerService.hide()
  }

  loadValueMap() {
    this.valuesMapService.getFilteredValuesMap(100, 0, '', 'INCENTIVES').subscribe((res) => {
      let data = res.data.items.sort((a, b) => a.display_order - b.display_order)
      //data = data.sort((a, b) => (a.description > b.description ? 1 : -1));
      let names = data.map(function (item) {
        return item['description']
      })
      this.clonedOtherExpData = data
      this.otherExpData = names
    })
    this.valuesMapService
      .getFilteredValuesMap(100, 0, '', 'LANGUAGE_PROFICIENCY')
      .subscribe((res) => {
        const data = res.data.items.sort((a, b) => a.display_order - b.display_order)
        let names = data.map(function (item) {
          return item['description']
        })
        this.languagesProficiencyData = names
      })
    this.valuesMapService.getFilteredValuesMap(100, 0, '', 'LANGUAGES').subscribe((res) => {
      // data = data.sort((a, b) => a.display_order - b.display_order);
      const data = res.data.items.sort((a, b) => (a.description > b.description ? 1 : -1))
      let names = data.map(function (item) {
        return item['description']
      })
      this.languagesData = names
    })
    this.valuesMapService.getFilteredValuesMap(100, 0, '', 'SOFT_SKILLS').subscribe((res) => {
      const data = res.data.items.sort((a, b) => a.display_order - b.display_order)
      let names = data.map(function (item) {
        return item['description']
      })
      this.searchSkillValue = names
      // this.skillsArray?.push(names[0]);
      // this.skillsArray.push(names[1]);
    })
    this.valuesMapService.getFilteredValuesMap(100, 0, '', 'VALUES').subscribe((res) => {
      const data = res.data.items.sort((a, b) => a.display_order - b.display_order)
      let names = data.map(function (item) {
        return item['description']
      })
      this.searchValues = names
      // this.cultureArray?.push(names[0]);
    })
    this.valuesMapService.getFilteredValuesMap(100, 0, '', 'FRUSTRATIONS').subscribe((res) => {
      const data = res.data.items.sort((a, b) => a.display_order - b.display_order)
      let names = data.map(function (item) {
        return item['description']
      })
      this.searchFrustrationsValue = names
      // this.frustrationsArray?.push(names[0]);
    })
    this.valuesMapService.getFilteredValuesMap(100, 0, '', 'POST_ROLES').subscribe((res) => {
      const data = res.data.items.sort((a, b) => a.display_order - b.display_order)
      let names = data.map(function (item) {
        return item['description']
      })
      this.roleArray = names
    })
    this.valuesMapService.getFilteredValuesMap(100, 0, '', 'DOMAIN').subscribe((res) => {
      const data = res.data.items.sort((a, b) => a.display_order - b.display_order)
      //data = data.sort((a, b) => (a.description > b.description ? 1 : -1));
      let names = data.map(function (item) {
        return item['description']
      })
      this.domainArray = names
    })
    this.valuesMapService.getFilteredValuesMap(100, 0, '', 'DOMAIN_EXP').subscribe((res) => {
      const data = res.data.items.sort((a, b) => a.display_order - b.display_order)
      let names = data.map(function (item) {
        return item['description']
      })
      this.expArray = names
    })
    this.skillsetService.getFilteredSkillset(100, 0, '').subscribe((data) => {
      let names = data.data.items.map(function (item) {
        //return item['category'] + '-' + item['sub_category'];
        return item['category']
      })
      this.searchCompanyValue = names
    })
  }

  addOtherExp(otherExp: any): void {
    if (otherExp == null || otherExp == '') {
      return
    }
    if (otherExp.toLowerCase() == 'other' || otherExp.toLowerCase() == 'other (not listed)') {
      this.selectedOtherExp = otherExp
    } else {
      this.selectedOtherExpList.push(otherExp)
      let index = this.otherExpData.findIndex((res: any) => res == otherExp)
      this.otherExpData.splice(index, 1)
    }
  }
  addOtherExpectations() {
    this.selectedOtherExpList.push(this.profileForm.controls.otherExpectations.value)
    this.profileForm.controls.otherExpectations.setValue('')
    this.selectedOtherExp = ''
  }
  removeOtherExp(value: any, index: any) {
    this.selectedOtherExpList.splice(index, 1)
    if (value.indexOf('Other') > -1) {
      this.profileForm.controls.otherExpectations.setValue('')
    } else {
      let index1 = this.clonedOtherExpData.findIndex((val) => val.description == value)
      if (index1 > -1) {
        this.otherExpData.push(value)
      }
    }
    this.selectedOtherExp = ''
  }

  // ngAfterContentInit() {
  // }
  companyValueChange() {
    let index = this.companyFilteredOptions.findIndex(
      (val) => val === this.profileForm.controls.technicalSkills.value
    )
    this.searchCompanyValue.sort()
    if (index > -1) {
      this.disableAddCompanyValue = false
    } else {
      this.disableAddCompanyValue = true
    }
    if (this.profileForm.controls.technicalSkills.value) {
      this.companyFilteredOptions = this.searchCompanyValue.filter((option) =>
        option.toLowerCase().includes(this.profileForm.controls.technicalSkills.value.toLowerCase())
      )
    } else {
      this.companyFilteredOptions = this.searchCompanyValue
    }
  }
  addTechnicalSkill() {
    if (!this.selectedTechnicalSkill.includes(this.profileForm.controls.technicalSkills.value)) {
      this.selectedTechnicalSkill.push(this.profileForm.controls.technicalSkills.value)
      this.profileForm.controls.technicalSkills.setValue('')
      this.disableAddCompanyValue = true
    } else {
      this.toastr.warning('Value already added')
      this.profileForm.controls.technicalSkills.setValue('')
      this.disableAddCompanyValue = true
    }
  }
  removeTechnicalSkill(index: any) {
    this.selectedTechnicalSkill.splice(index, 1)
  }
  addQualification() {
    const qualificationControl = this.profileForm.get('qualification')
    if (qualificationControl?.value.trim()) {
      if (!this.qualificationsArray.includes(qualificationControl?.value.trim())) {
        this.qualificationsArray.push(qualificationControl?.value.trim())
        qualificationControl.setValue('')
      } else {
        this.toastr.warning('Value already added')
      }
    }
  }
  removeQualification(index) {
    this.qualificationsArray.splice(index, 1)
  }
  getCandidateData() {
    return new Promise<void>((resolve) => {
      this.candidateService.getById(this.candidateProfileId).subscribe({
        next: (res) => {
          const data = res.data
          this.profileData = data
          this.candidateProfile = data
          this.salary = data.salary
          this.day_rate = data.day_rate
          this.location = data.location

          if (data?.languages !== null && data?.languages != '') {
            this.selectedLanguageData = this.profileData.languages.split(';')
          }
          if (data?.skills !== undefined && data.skills !== null && data.skills !== '') {
            this.skillsArray = data?.skills?.split(';')
          }
          if (
            data?.candidate_values !== undefined &&
            data.candidate_values !== null &&
            data.candidate_values !== ''
          ) {
            this.valuesArr = data?.candidate_values?.split(';')
          }
          if (
            data?.domain_exp !== undefined &&
            data.domain_exp !== null &&
            data.domain_exp !== ''
          ) {
            this.addedDomainAndExpArray = data?.domain_exp?.split(';')
          }
          if (data?.experience_1_img_url !== null) {
            this.resImageProfile1 = data?.experience_1_img_url
          }
          if (data?.experience_2_img_url !== null) {
            this.resImageProfile2 = data?.experience_2_img_url
          }
          if (data?.experience_3_img_url !== null) {
            this.resImageProfile3 = data?.experience_3_img_url
          }
          this.hobbiesArray = data?.hobbies ? data?.hobbies.split(';') : []
          this.qualificationsArray = data?.qualification ? data?.qualification.split(';') : []

          this.selectedOtherExpList = data?.other_expectations
            ? data?.other_expectations.split(';')
            : []

          // this.selectedOtherExpList = getArrayFromString(this.profileForm.other_expectations)
          this.selectedTechnicalSkill = data?.technical_skills
            ? data?.technical_skills.split(';')
            : []
          /* 
            this.experience1From = data?.experience_1_from !== 'Invalid date' && data?.experience_1_from !== null && data?.experience_1_from !== undefined ? this.formatDate(data.experience_1_from) : null;
            this.experience1To = data?.experience_1_to !== 'Invalid date' && data?.experience_1_to !== null && data?.experience_1_to !== undefined ? this.formatDate(data.experience_1_to) : null;
             */
          this.experience1From =
            data?.experience_1_from !== null && data?.experience_1_from !== undefined
              ? data.experience_1_from
              : null
          this.experience1To =
            data?.experience_1_to !== null && data?.experience_1_to !== undefined
              ? data.experience_1_to
              : null
          if (data?.experience_1_from != null && data?.experience_1_to != null) {
            this.experience1 = this.experience1From + ' - ' + this.experience1To
          }
          this.experienceCompanyName1 = data?.experience_1_compname
          this.companyDesignation1 = data?.experience_1_role
          this.experience2From =
            data?.experience_2_from !== null && data?.experience_2_from !== undefined
              ? data.experience_2_from
              : null
          this.experience2To =
            data?.experience_2_to !== null && data?.experience_2_to !== undefined
              ? data.experience_2_to
              : null
          if (data?.experience_2_from != null && data?.experience_2_to != null) {
            this.experience2 = this.experience2From + ' - ' + this.experience2To
          }
          this.experienceCompanyName2 = data?.experience_2_compname
          this.companyDesignation2 = data?.experience_2_role
          this.experience3From =
            data?.experience_3_from !== null && data?.experience_3_from !== undefined
              ? data.experience_3_from
              : null
          this.experience3To =
            data?.experience_3_to !== null && data?.experience_3_to !== undefined
              ? data.experience_3_to
              : null
          if (data?.experience_3_from != null && data?.experience_3_to != null) {
            this.experience3 = this.experience3From + ' - ' + this.experience3To
          }
          this.experienceCompanyName3 = data?.experience_3_compname
          this.companyDesignation3 = data?.experience_3_role
          // this.experience1To = data?.experience_1_to;
          /* if (data?.experience_1_from != null && data?.experience_1_to != null && data?.experience_1_from !== 'Invalid date' && data?.experience_1_to !== 'Invalid date') {
              var exp1fromdates = this.formatDate(data.experience_1_from);
              var exp1todates = this.formatDate(data.experience_1_to);
              const experience1tempfrom = moment(exp1fromdates).format('YYYY');
              const experience1tempto = moment(exp1todates).format('YYYY');
              this.experience1 = experience1tempfrom + ' - ' + experience1tempto;
            }
            this.experience2From = data?.experience_2_from !== 'Invalid date' && data?.experience_2_from !== null && data?.experience_2_from !== undefined ? this.formatDate(data.experience_2_from) : null;
            this.experience2To = data?.experience_2_to !== 'Invalid date' && data?.experience_2_to !== null && data?.experience_2_to !== undefined ? this.formatDate(data.experience_2_to) : null;
            if (data?.experience_2_to !== null && data?.experience_2_from !== null && data?.experience_2_to !== 'Invalid date' && data?.experience_2_from !== 'Invalid date') {
              var exp1fromdates = this.formatDate(data.experience_2_from);
              var exp1todates = this.formatDate(data.experience_2_to);
              const experience1tempfrom = moment(exp1fromdates).format('YYYY');
              const experience1tempto = moment(exp1todates).format('YYYY');
              this.experience2 = experience1tempfrom + ' - ' + experience1tempto;
              // const experience2tempfrom = moment(data?.experience_2_from).format('DD/MM/YYYY');
              // const experience2tempto = moment(data?.experience_2_to).format('DD/MM/YYYY');
              // this.experience2 = moment(experience2tempfrom).format('MM/YYYY') + ' - ' + moment(experience2tempto).format('MM/YYYY');
              // this.experience2 = moment(data?.experience_2_from).format('MM/YYYY') + ' - ' + moment(data?.experience_2_to).format('MM/YYYY');
            } 
            this.experience3From = data?.experience_3_from !== 'Invalid date' && data?.experience_3_from !== null && data?.experience_3_from !== undefined ? this.formatDate(data.experience_3_from) : null;
            this.experience3To = data?.experience_3_to !== 'Invalid date' && data?.experience_3_to !== null && data?.experience_3_to !== undefined ? this.formatDate(data.experience_3_to) : null;
            if (data?.experience_3_from !== null && data?.experience_3_to !== null && data?.experience_3_from !== 'Invalid date' && data?.experience_3_to !== 'Invalid date') {
              var exp1fromdates = this.formatDate(data.experience_3_from);
              const experience1tempfrom = moment(exp1fromdates).format('YYYY');
              const experience1tempto = moment(exp1todates).format('YYYY');
              this.experience3 = experience1tempfrom + ' - ' + experience1tempto;
              // const experience3tempfrom = moment(data?.experience_3_from).format('DD/MM/YYYY');
              // const experience3tempto = moment(data?.experience_3_to).format('DD/MM/YYYY');
              // this.experience3 = moment(experience3tempfrom).format('MM/YYYY') + ' - ' + moment(experience3tempto).format('MM/YYYY');
              // this.experience3 = moment(data?.experience_3_from).format('MM/YYYY') + '-' + moment(data?.experience_3_to).format('MM/YYYY');
            }
         */
          if (
            data?.currently_employed_yn !== null &&
            data?.currently_employed_yn != '' &&
            data?.currently_employed_yn !== undefined
          ) {
            if (data?.currently_employed_yn === 'Y') {
              this.currentlyEmployed = true
            } else {
              this.currentlyEmployed = false
            }
          }
          if (data?.frustrations !== null && data?.frustrations != '') {
            this.frustrationsArray = data?.frustrations?.split(';')
          }
          if (data?.personality !== null && data?.personality != '') {
            this.personalityArray = data?.personality?.split(';')
          }
          this.bio = data?.bio
          this.goals = data?.goals
          this.hashtagArray = data?.hashtags ? data.hashtags.split(';') : []
          if (data?.personality_type !== null && data?.personality_type != '') {
            let type = this.personalityTypeArray?.find((val) => val.name == data?.personality_type)
            this.selectedPersonalityType = type
          } else {
            this.selectedPersonalityType = this.personalityTypeArray[0]
          }
          const perc = this.profileData.profile_completion
          this.profileData.profileData = perc
          this.loading = false
          resolve()
        },
        error: (error) => {
          console.error(error)
          resolve()
        }
      })
    })
  }
  formatDate(date: any) {
    var exp1fromdate = date.split('/')
    var exp1fromnewDate = exp1fromdate[2]
    return exp1fromnewDate
  }
  get languagesField(): FormArray | undefined {
    return this.languagesProficiencyForm
      ? (this.languagesProficiencyForm.get('languagesField') as FormArray)
      : undefined
  }
  /* createLanguageField() {
    return this.fb.group({
      languagesProficiency: [''],
      languages: [''],
      disabled: [false]
    })
  } */
  ngAfterViewInit() {
    this.matSelect?.openedChange.subscribe((opened) => {
      if (opened) {
        this.matSelect.panel.nativeElement.addEventListener('mouseleave', () => {
          this.matSelect.close()
        })
      }
    })
  }
  addLanguages() {
    const selectedLangualges = this.selectedLanguageData.map((e) => e.split('-')[0].trim())
    if (!selectedLangualges.includes(this.selectedLanguage.trim())) {
      this.selectedLanguageData.push(this.selectedLanguage + '-' + this.selectedProficiency)
      let index = this.languagesData.findIndex((val) => val == this.selectedLanguage)
      this.selectedLanguage = null
      this.selectedProficiency = null
      this.languagesData.splice(index, 1)
      this.flag = true
    } else {
      this.toastr.warning('Value already added')
    }
  }
  editLanguages() {
    // this.editLanguageFlag = true;
    this.selectedLanguage = null
    this.selectedProficiency = null
    this.selectedLanguageData.forEach((value) => {
      let entry = value.split('-')
      let language = entry[0]
      let index = this.languagesData.findIndex((val) => val === language.trim())
      if (index > -1) {
        this.languagesData.splice(index, 1)
      }
    })
  }
  removeLanguages(index: any) {
    let value = this.selectedLanguageData[index]
    let entry = value.split('-')
    let language = entry[0]
    if (language !== null && language !== '') {
      this.languagesData.push(language.trim())
    }
    this.languagesData = this.languagesData.sort((a, b) => (a > b ? 1 : -1))
    this.selectedLanguageData.splice(index, 1)
    //   /* this.languagesField.removeAt(index)
    //   this.languagesArr.splice(index, 1)
    //   this.languagesProficiencyArr.splice(index, 1) */
  }
  changeLanguage() {
    let index = this.selectedLanguageData.findIndex((val) =>
      val.toLowerCase().includes(this.selectedLanguage.toLowerCase())
    )
    if (index > -1) {
      this.selectedLanguage = null
      this.toastr.warning('Value already added')
    }
  }
  changeLanguageOld(item, index) {
    if (this.languagesArr.includes(item.controls.languages.value)) {
      this.toastr.warning('Value already added')
      if (this.languagesProficiencyArr[index] === undefined) {
        this.languagesField.controls[index].get('languages').setValue('')
        item.controls.disabled.setValue(false)
      } else {
        this.languagesField.controls[index].get('languages').setValue(this.languagesArr[index])
      }
    } else {
      item.controls.disabled.setValue(true)
      this.languagesArr[index] = item.controls.languages.value
      if (this.languagesProficiencyArr[index] !== undefined) {
        this.setValue(this.languagesArr[index], this.languagesProficiencyArr[index], index)
      }
    }
  }
  setValue(languages, proficiency, index: any) {
    const data = languages + '-' + proficiency
    if (!this.selectedLanguageData.includes(data)) {
      if (this.selectedLanguageData[index] === undefined) {
        this.selectedLanguageData.push(data)
      } else {
        this.selectedLanguageData[index] = data
      }
    } else {
      this.toastr.warning('Value already added')
      if (this.selectedLanguageData[index] === undefined) {
        this.languagesField.controls[index].get('languages').setValue('')
        this.languagesField.controls[index].get('languagesProficiency').setValue('')
      } else {
        const arr = this.selectedLanguageData[index].split('-')
        this.languagesArr[index] = arr[0]
        this.languagesProficiencyArr[index] = arr[1]
        this.languagesField.controls[index].get('languages').setValue(arr[0])
        this.languagesField.controls[index].get('languagesProficiency').setValue(arr[1])
      }
    }
  }
  // removeLanguages(index: any) {
  //   this.languagesField.removeAt(index)
  //   this.selectedLanguageData.splice(index, 1)
  //   this.languagesArr.splice(index, 1)
  //   this.languagesProficiencyArr.splice(index, 1)
  // }
  getRemainingChar(aboutYourSelf: number): number {
    return 240 - aboutYourSelf > 0 ? 240 - aboutYourSelf : 0
  }

  checkfrustrationsValidity() {
    if (this.frustrations) {
      this.frustrationsFilteredOptions = this.searchFrustrationsValue.filter((option) =>
        option.toLowerCase().includes(this.frustrations.toLowerCase())
      )
    } else {
      this.frustrationsFilteredOptions = this.searchFrustrationsValue
    }
  }
  removefrustrations(index: any) {
    let value = this.frustrationsArray[index]
    let entry = value.split(' - ')
    let option = entry[0]
    this.searchFrustrationsValue.push(option)
    this.frustrationsFilteredOptions = this.searchFrustrationsValue.sort((a, b) => (a > b ? 1 : -1))
    this.frustrationsArray.splice(index, 1)
  }
  setLanguageData(item, index) {
    item.controls.disabled.setValue(true)
    this.languagesProficiencyArr[index] = item.controls.languagesProficiency.value
    this.setValue(this.languagesArr[index], this.languagesProficiencyArr[index], index)
  }
  seeMore(value: any) {
    if (value === 'personality_desc') this.seeMoreFalg = !this.seeMoreFalg
    else if (value === 'bio') this.seeMoreBioFalg = !this.seeMoreBioFalg
    else if (value === 'goals') this.seeMoreGoalsFalg = !this.seeMoreGoalsFalg
  }
  // editBio() {
  //   this.editBioFlag = true;
  // }
  editHashtag() {
    this.editHashtagFlag = true
  }
  editSkill() {
    // this.editSkillFlag = true;
    this.skillFilteredOptions = this.searchSkillValue
    this.skillsArray.forEach((selectedSkills) => {
      let index = this.searchSkillValue.findIndex((val) => val === selectedSkills)
      if (index > -1) {
        this.searchSkillValue.splice(index, 1)
      }
    })
  }
  editTechnicalSkill() {
    // this.editTechnicalSkillFlag = true;
    this.companyFilteredOptions = this.searchCompanyValue
    this.selectedTechnicalSkill.forEach((selectedSkills) => {
      let index = this.searchCompanyValue.findIndex((val) => val === selectedSkills)
      if (index > -1) {
        this.searchCompanyValue.splice(index, 1)
      }
    })
  }
  disableAddSkill: boolean = true
  checkSkillValidity() {
    let index = this.skillFilteredOptions.findIndex((val) => val === this.skillValue)
    this.searchSkillValue.sort()
    if (index > -1) {
      this.disableAddSkill = false
    } else {
      this.disableAddSkill = true
    }
    if (this.skillValue) {
      this.skillFilteredOptions = this.searchSkillValue.filter((option) =>
        option.toLowerCase().includes(this.skillValue.toLowerCase())
      )
    } else {
      this.skillFilteredOptions = this.searchSkillValue
    }
  }
  addSkillData(skillValue) {
    if (this.skillValue !== null && this.skillValue !== '') {
      if (this.skillFilteredOptions.includes(skillValue)) {
        if (
          this.skillsArray !== null &&
          this.skillsArray !== undefined &&
          this.skillsArray.length !== 0
        ) {
          if (!this.skillsArray?.includes(this.skillValue)) {
            this.skillsArray?.push(this.skillValue)
            let index = this.searchSkillValue.findIndex((val) => val == this.skillValue)
            this.skillValue = null
            this.searchSkillValue.splice(index, 1)
          } else {
            this.toastr.warning('Value already added')
          }
        } else {
          this.skillsArray = []
          this.skillsArray.push(this.skillValue)
          let index = this.searchSkillValue.findIndex((val) => val == this.skillValue)
          this.skillValue = ''
          this.searchSkillValue.splice(index, 1)
        }
      }
    }
  }
  // removeSkills(skill: any) {
  //   this.skillsArray.splice(this.skillsArray.indexOf(skill), 1);
  // }
  removeSkills(index: any) {
    let value = this.skillsArray[index]
    let entry = value.split(' - ')
    let option = entry[0]
    this.searchSkillValue.push(option)
    this.skillFilteredOptions = this.searchSkillValue.sort((a, b) => (a > b ? 1 : -1))
    this.skillsArray.splice(index, 1)
  }
  editValues() {
    // this.editCultureFlag = true;
    this.valuesFilteredOptions = this.searchValues
    this.valuesArr.forEach((selectedValue) => {
      let index = this.searchValues.findIndex((val) => val === selectedValue)
      if (index > -1) {
        this.searchValues.splice(index, 1)
      }
    })
  }

  candidateValuesChange() {
    let index = this.valuesFilteredOptions.findIndex((val) => val === this.values)
    this.searchValues.sort()
    if (index > -1) {
      this.disableAddValues = false
    } else {
      this.disableAddValues = true
    }
    if (this.values) {
      this.valuesFilteredOptions = this.searchValues.filter((option) =>
        option.toLowerCase().includes(this.values.toLowerCase())
      )
    } else {
      this.valuesFilteredOptions = this.searchValues
    }
    // const filterValue = this.culture.toLowerCase();
    // this.cultureFilteredOptions = this.searchCultureValue.filter(option => option.toLowerCase().includes(filterValue));
  }
  addValues(culture: any): void {
    if (this.values !== null && this.values !== '' && this.values !== undefined) {
      if (this.valuesFilteredOptions.includes(culture)) {
        if (
          this.valuesArr !== null &&
          this.valuesArr !== undefined &&
          this.valuesArr.length !== 0
        ) {
          if (!this.valuesArr?.includes(this.values)) {
            this.valuesArr?.push(this.values)
            let index = this.searchValues.findIndex((val) => val == this.values)
            this.values = null
            this.searchValues.splice(index, 1)
          } else {
            this.toastr.warning('Value already added')
            this.values = ''
          }
        } else {
          this.valuesArr = []
          this.valuesArr.push(this.values)
          let index = this.searchValues.findIndex((val) => val == this.values)
          this.values = ''
          this.searchValues.splice(index, 1)
        }
        this.flag = true
      }
    } else {
      this.flag = false
    }
  }
  // removeCultureValue(cultureValue: string) {
  //   this.cultureArray.splice(this.cultureArray.indexOf(cultureValue), 1);
  // }
  removeValues(index: any) {
    let value = this.valuesArr[index]
    let entry = value.split(' - ')
    let option = entry[0]
    this.searchValues.push(option)
    this.valuesFilteredOptions = this.searchValues.sort((a, b) => (a > b ? 1 : -1))
    this.valuesArr.splice(index, 1)
  }
  editFrustrations() {
    // this.editFrustrationsFlag = true;
    this.frustrationsFilteredOptions = this.searchFrustrationsValue
    this.frustrationsArray.forEach((selectedFrustrations) => {
      let index = this.searchFrustrationsValue.findIndex((val) => val === selectedFrustrations)
      if (index > -1) {
        this.searchFrustrationsValue.splice(index, 1)
      }
    })
  }
  // editLanguages() {
  //   this.editLanguageFlag = true;
  //   this.languagesField.clear();
  //   this.selectedLanguageData.forEach(async (data: any, index: any) => {
  //     if (this.languagesField.length < this.selectedLanguageData.length) {
  //       const fg = this.createLanguageField()
  //       this.languagesField.push(fg)
  //       const arr = data.split('-');
  //       this.languagesArr[index] = arr[0];
  //       this.languagesProficiencyArr[index] = arr[1];
  //       this.languagesField.controls[index].get('languages').setValue(arr[0]);
  //       this.languagesField.controls[index].get('languagesProficiency').setValue(arr[1])
  //       this.languagesField.controls[index].get('disabled').setValue(true)
  //     }
  //   })
  // }
  addFrustrations(): void {
    if (this.frustrations) {
      if (this.frustrationsFilteredOptions.includes(this.frustrations)) {
        if (
          this.frustrationsArray !== null &&
          this.frustrationsArray !== undefined &&
          this.frustrationsArray.length !== 0
        ) {
          if (!this.frustrationsArray?.includes(this.frustrations)) {
            this.frustrationsArray?.push(this.frustrations)
            let index = this.searchFrustrationsValue.findIndex((val) => val == this.frustrations)
            this.frustrations = null
            this.searchFrustrationsValue.splice(index, 1)
          } else {
            this.toastr.warning('Value already added')
          }
        } else {
          this.frustrationsArray = []
          this.frustrationsArray.push(this.frustrations)
          let index = this.searchFrustrationsValue.findIndex((val) => val == this.frustrations)
          this.frustrations = ''
          this.searchFrustrationsValue.splice(index, 1)
        }
        this.flag = true
      }
    } else {
      this.flag = false
    }
  }
  addHobbies() {
    this.hobbiesArray.push(this.profileForm.get('hobbies').value)
    this.profileForm.get('hobbies').setValue('')
  }
  removeHobbies(index) {
    this.hobbiesArray.splice(index, 1)
  }
  frustrationsChange() {
    const filterValue = this.frustrations.toLowerCase()
    this.frustrationsFilteredOptions = this.searchFrustrationsValue.filter((option) =>
      option.toLowerCase().includes(filterValue)
    )
  }
  removeFrustrationsValue(frustrationsValue: string) {
    this.frustrationsArray.splice(this.frustrationsArray.indexOf(frustrationsValue), 1)
    // this.searchFrustrationsValue.push(frustrationsValue);
    // this.frustrationsFilteredOptions = this.searchFrustrationsValue
  }
  editPortfolio() {
    this.editPortfolioFlag = true
  }
  editExperience() {
    // this.editExperienceFlag = true;
    this.experience1From = this.experience1From ? this.experience1From : null
    this.experience1To = this.experience1To ? this.experience1To : null
    this.experience2From = this.experience2From ? this.experience2From : null
    this.experience2To = this.experience2To ? this.experience2To : null
    this.experience3From = this.experience3From ? this.experience3From : null
    this.experience3To = this.experience3To ? this.experience3To : null
  }
  async uploadImageProfile(event: any, srcUrl: any) {
    await this.SpinnerService.show()
    if (
      event.addedFiles &&
      event.addedFiles[0] &&
      (event.addedFiles[0].type == 'image/jpeg' ||
        event.addedFiles[0].type == 'image/png' ||
        event.addedFiles[0].type == 'image/gif' ||
        event.addedFiles[0].type == 'image/webp')
    ) {
      const file = event.addedFiles[0]
      await this.SpinnerService.show()
      if (file.size <= environment.imageDocSize) await this.uploadImage(file, srcUrl)
      else {
        this.SpinnerService.hide()
        this.toastr.warning(environment.fileErrorMessage)
      }
    } else {
      this.toastr.warning(environment.imageErrorMessage)
      this.SpinnerService.hide()
    }
  }
  async uploadImage(file: any, srcUrl: any) {
    await this.SpinnerService.show()
    await this.s3Service
      .uploadFile(file, `candidates/${this.candidateProfileId}`)
      .then(async (res) => {
        const urlConst = res.Location
        if (srcUrl === 'imageProfile1') {
          this.imageProfile1 = urlConst
          this.resImageProfile1 = res.Location
        } else if (srcUrl === 'imageProfile2') {
          this.imageProfile2 = urlConst
          this.resImageProfile2 = res.Location
        } else if (srcUrl === 'imageProfile3') {
          this.imageProfile3 = urlConst
          this.resImageProfile3 = res.Location
        }
      })
      .finally(() => {
        this.SpinnerService.hide()
      })
  }

  chosenYearHandler(normalizedYear: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = moment(this.experience1From)
    this.experience1From = ctrlValue.year(normalizedYear.year())
    datepicker.close()
  }
  chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = moment(this.experience1From)
    this.experience1From = ctrlValue.month(normalizedMonth.month())
    datepicker.close()
  }
  chosenEndYearHandler(normalizedYear: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = moment(this.experience1To)
    this.experience1To = ctrlValue.year(normalizedYear.year())
    ctrlValue.year(normalizedYear.year())
    datepicker.close()
  }
  chosenEndMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = moment(this.experience1To)
    ctrlValue.month(normalizedMonth.month())
    this.experience1To = ctrlValue.month(normalizedMonth.month())
    datepicker.close()
  }
  chosenExperience2YearHandler(normalizedYear: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = moment(this.experience2From)
    this.experience2From = ctrlValue.year(normalizedYear.year())
    datepicker.close()
  }
  chosenExperience2MonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = moment(this.experience2From)
    this.experience2From = ctrlValue.month(normalizedMonth.month())
    datepicker.close()
  }
  chosenExperience2EndYearHandler(normalizedYear: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = moment(this.experience2To)
    this.experience2To = ctrlValue.year(normalizedYear.year())
    datepicker.close()
  }
  chosenExperience2EndMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = moment(this.experience2To)
    this.experience2To = ctrlValue.month(normalizedMonth.month())
    datepicker.close()
  }
  chosenExperience3YearHandler(normalizedYear: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = moment(this.experience3From)
    this.experience3From = ctrlValue.year(normalizedYear.year())
    datepicker.close()
  }
  chosenExperience3MonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = moment(this.experience3From)
    this.experience3From = ctrlValue.month(normalizedMonth.month())
    datepicker.close()
  }
  chosenExperience3EndYearHandler(normalizedYear: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = moment(this.experience3To)
    this.experience3To = ctrlValue.year(normalizedYear.year())
    datepicker.close()
  }
  chosenExperience3EndMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = moment(this.experience3To)
    this.experience3To = ctrlValue.month(normalizedMonth.month())
    datepicker.close()
  }

  getDataFromChildComponents() {
    const candidateProfileDetailComponentData = this.profileDetailsComponent.getData()
    return { ...candidateProfileDetailComponentData }
  }

  async submitData() {
    const candidateDetails = this.getDataFromChildComponents()
    this.SpinnerService.show()
    if (this.goals === null || this.goals === undefined) {
      this.goals = ''
    }
    let candidateResponse = await this.candidateService.getById(this.candidateProfileId).toPromise()
    let json = candidateResponse.data
    json.languages = getArrayStringValue(this.selectedLanguageData)
    json.bio = this.bio
    json.skills = getArrayStringValue(this.skillsArray)
    json.candidate_values = getArrayStringValue(this.valuesArr)
    json.frustrations = getArrayStringValue(this.frustrationsArray)
    json.personality = getArrayStringValue(this.personalityArray)
    json.domain_exp = getArrayStringValue(this.addedDomainAndExpArray)
    json.experience_1_img_url = this.resImageProfile1
    json.experience_1_compname = this.experienceCompanyName1
    json.experience_1_role = this.companyDesignation1
    json.experience_2_img_url = this.resImageProfile2
    json.experience_2_compname = this.experienceCompanyName2
    json.experience_2_role = this.companyDesignation2
    json.experience_3_img_url = this.resImageProfile3
    json.experience_3_compname = this.experienceCompanyName3
    json.experience_3_role = this.companyDesignation3
    json.experience_1_from = this.experience1From
    json.experience_1_to = this.experience1To
    json.experience_2_from = this.experience2From
    json.experience_2_to = this.experience2To
    json.experience_3_from = this.experience3From
    json.experience_3_to = this.experience3To
    /* 
    json.experience_1_from = this.experience1From ? moment(this.experience1From).format('DD/MM/YYYY') : null;
    json.experience_1_to = this.experience1To ? moment(this.experience1To).format('DD/MM/YYYY') : null;
    json.experience_2_from = this.experience2From ? moment(this.experience2From).format('DD/MM/YYYY') : null;
    json.experience_2_to = this.experience2To ? moment(this.experience2To).format('DD/MM/YYYY') : null;
    json.experience_3_from = this.experience3From ? moment(this.experience3From).format('DD/MM/YYYY') : null;
    json.experience_3_to = this.experience3To ? moment(this.experience3To).format('DD/MM/YYYY') : null;
     */
    json.goals = typeof this.goals === 'string' ? this.goals : JSON.stringify(this.goals) // schema is optional,
    json.hashtags = getArrayStringValue(this.hashtagArray)
    json.personality_type = this.selectedPersonalityType?.name
    json.personality_desc = this.selectedPersonalityType?.description
    json.technical_skills = getArrayStringValue(this.selectedTechnicalSkill)
    json.qualification = getArrayStringValue(this.qualificationsArray)
    json.hobbies = getArrayStringValue(this.hobbiesArray)
    json.currently_employed_yn = this.currentlyEmployed ? 'Y' : 'N'
    json.other_expectations = getArrayStringValue(this.selectedOtherExpList)
    json.culture = JSON.stringify(this.cultureQuizData)

    // if (this.flag === true) {
    const updatedData = { ...json, ...candidateDetails }
    let response = await this.candidateService
      .updateById(this.candidateProfileId, updatedData)
      .toPromise()
    if (response.success) {
      // sessionStorage.setItem(
      //   "CANDIDATE_PROFILE",
      //   JSON.stringify(response.data[0])
      // );
      this.eventSubject.next()
      this.SpinnerService.hide()
      this.flag = false
      setTimeout(() => {
        this.getCandidateData()
      }, 1500)
    }
    // }
    else {
      this.SpinnerService.hide()
    }
  }
  async eventFromChild(data) {
    if (data) {
      this.dialogRef.close({})
    } else {
      this.toastr.warning('Candidate Profile Not Updated')
    }
  }
  ngOnDestroy(): void {
    // this.editor.destroy();
  }
  detectChange(childKey: string, value: string) {
    this.flag = true
    // value.replace(/\s/g, '')
    // if(value.length > 280){
    //   return false;
    // }
  }
  checklength(event: any) {
    const characters = event.target.value.split('')
    const count = characters.filter((item) => {
      return item != ' '
    }).length
    if (count >= 280) {
      event.preventDefault()
      return false
    }
  }
  async cancel() {
    if (this.isAllFieldsFilled || !this.editAllFlag) {
      this.dialogRef.close({})
    } else {
      this.dialogRef2 = this.dialog.open(FieldAlertComponent, {
        panelClass: 'candidate-profile-alert',
        disableClose: true,
        width: '55vw',
        autoFocus: false
      })
      this.dialogRef2.afterClosed().subscribe(async (result) => {
        if (result != 'Proceed') {
          this.dialogRef.close({})
        }
      })
    }
  }

  proceedtofill() {}

  closeModal() {
    this.dialogRef.close({})
  }
  addHashTags() {
    if (this.hashtag !== '' && this.hashtag !== undefined && this.hashtag !== null) {
      if (!this.hashtagArray.includes(this.hashtag)) {
        this.hashtagArray.push(this.hashtag)
        this.hashtag = ''
      } else {
        this.toastr.warning('Value already added')
      }
    }
    if (this.hashtagArray.length > 4) {
      this.disbleHashtagAddition = true
    }
  }
  // checkHashtag() {
  //   if (typeof this.hashtag != 'undefined') {
  //     if (this.hashtag != null && this.hashtag !== '') {
  //       const text = this.hashtag;
  //       if (!text.startsWith('#') && text.length > 2) {
  //         this.disbleHashtagAddition = true;
  //       } else {
  //         this.disbleHashtagAddition = false;
  //       }
  //     }
  //   }
  //   if (this.hashtagArray.length > 4) {
  //     this.disbleHashtagAddition = true;
  //   }
  // }
  removeHashtags(value: any, index: any) {
    this.hashtagArray.splice(index, 1)
  }
  changePersonlityType(direction) {
    let index = this.personalityTypeArray.findIndex(
      (val) => val.name == this.selectedPersonalityType.name
    )
    if (direction == 'previous') {
      if (index == 0) {
        this.selectedPersonalityType =
          this.personalityTypeArray[this.personalityTypeArray.length - 1]
      } else {
        this.selectedPersonalityType = this.personalityTypeArray[index - 1]
      }
    } else if (direction == 'next') {
      if (index == this.personalityTypeArray.length - 1) {
        this.selectedPersonalityType = this.personalityTypeArray[0]
      } else {
        this.selectedPersonalityType = this.personalityTypeArray[index + 1]
      }
    }
  }
  valueChange() {
    // let index = this.domainFilteredValue.findIndex(
    //   (val) => val === this.selectedDomain
    // );
    this.domainArray.sort()
    if (this.selectedDomain) {
      this.domainFilteredValue = this.domainArray.filter((option) =>
        option.toLowerCase().includes(this.selectedDomain.toLowerCase())
      )
    } else {
      this.domainFilteredValue = this.domainArray
    }
  }
  addDomainExp() {
    const addedDomains = this.addedDomainAndExpArray.map((e) => e.split('-')[0].trim())
    if (!addedDomains.includes(this.selectedDomain.trim())) {
      this.addedDomainAndExpArray.push(this.selectedDomain + '-' + this.selectedExp)
      let index = this.domainArray.findIndex((val) => val == this.selectedDomain)
      this.selectedDomain = null
      this.selectedExp = null
      this.domainArray.splice(index, 1)
    } else {
      this.toastr.warning('Value already added')
    }
  }
  removeDomainAndExp(index: any) {
    let value = this.addedDomainAndExpArray[index]
    let entry = value.split('-')
    let domain = entry[0]
    if (domain !== null && domain !== '') {
      this.domainArray.push(domain.trim())
    }
    this.domainArray = this.domainArray.sort((a, b) => (a > b ? 1 : -1))
    this.addedDomainAndExpArray.splice(index, 1)
  }

  isStringifiedJSON(str: string): boolean {
    try {
      JSON.parse(str)
      return true
    } catch (error) {
      return false
    }
  }

  getPersonalityTestData() {
    if (!this.candidateProfile && this.userInfo.user_type == '3') {
      this.candidateProfile = this.profileData
    }
    this.personalityTestDetails = this.candidateProfile?.personality_test_info
    if (this.personalityTestDetails) {
      this.personalityTestDetails = JSON.parse(this.personalityTestDetails)
    }
    this.isPersonalityTestCompleted = this.candidateProfile?.personality_test_info ? true : false

    if (this.isPersonalityTestCompleted) {
      this.valuesMapService
        .getFilteredValuesMap(200, 0, '', 'PERSONALITY_FACTORS')
        .subscribe((res) => {
          let data = res.data.items
          this.personalityFactors = data
          this.personalityFactors.forEach((ele) => {
            const key = ele?.code
            ele['count'] = Number(this.personalityTestDetails[key])
            ele['description'] = JSON.parse(
              ele['description']?.replace('"Adjectives"', ', "Adjectives"')
            )
          })
          this.personalityTestInfo = this.personalityFactors.find((value) => {
            return value.code === this.candidateProfile.personality_archetype
          })
        })
    }
  }

  async getCultureTestInfo() {
    this.isCultureQuizCompleted =
      this.candidateProfile?.culture && this.isStringifiedJSON(this.candidateProfile?.culture)
        ? true
        : false

    const res = await this.valuesMapService
      .getFilteredValuesMap(200, 0, '', 'CULTURE_FACTORS')
      .toPromise()
    this.cultureFactors = res.data.items

    if (this.cultureFactors?.length) {
      this.cultureFactors.forEach((e) => {
        if (e?.description) {
          e.description = JSON.parse(e.description)
        }
      })
    }

    if (this.isCultureQuizCompleted) {
      this.cultureQuizData = JSON.parse(this.candidateProfile.culture)
      this.cultureQuizData?.sort((a, b) => a.rank - b.rank)
      this.cultureQuizInfo = this.cultureFactors.find(
        (e) => e?.description?.name === this.cultureQuizData[0]?.name
      )?.description
      if (!this.cultureQuizInfo) {
        this.isCultureQuizCompleted = false
      }
      this.cultureFactors.forEach((culture) => {
        culture['count'] =
          this.cultureQuizData.find((e) => e?.name === culture?.description?.name)?.score || 0
      })
    }
  }

  retakeTest() {
    this.dialog.open(this.reTakeTestModal, {
      maxWidth: '55vw',
      autoFocus: false,
      id: 'testRetakeModal'
    })
  }

  closeRetakeModal() {
    const dialogRef = this.dialog.getDialogById('testRetakeModal')
    if (dialogRef) {
      dialogRef.close()
    }
  }

  takePersonalityTest(test) {
    const dialogRef = this.dialog.open(PersonalityBuilderComponent, {
      panelClass: 'personality-builder-modal',
      maxHeight: '90vh',
      maxWidth: '90vw',
      autoFocus: false,
      data: {
        testType: test,
        candidateProfile: this.candidateProfile
      }
    })

    dialogRef.afterClosed().subscribe(() => {
      this.eventSubject.next()
      this.candidateService.getById(this.candidateProfileId).subscribe((res) => {
        let data = res.data
        sessionStorage.setItem('CANDIDATE_PROFILE', JSON.stringify(data))
        this.candidateProfile = data
      })
      setTimeout(() => {
        this.getPersonalityTestData()
      }, 1500)
    })
  }

  openPersonalityScoreModal() {
    const personalityFactors = [...this.personalityFactors]
    personalityFactors.sort((a, b) => {
      return Number(b?.count) - Number(a?.count)
    })
    personalityFactors.forEach((value, index) => {
      value['count'] = 6 - index
    })

    this.dialog.open(MyScoreComponent, {
      panelClass: 'my-score-modal',
      autoFocus: false,
      data: {
        personalityTestInfo: this.personalityTestInfo,
        personalityFactors: personalityFactors,
        header: 'Personality Structure'
      }
    })
  }

  takeCultureQuiz() {
    const dialogRef = this.dialog.open(CultureBuilderComponent, {
      panelClass: 'culture-rank-modal',
      maxWidth: '70vw',
      maxHeight: '90vh',
      autoFocus: 'false',
      data: { type: 'candidate' }
    })

    dialogRef.afterClosed().subscribe(async (data) => {
      if (data) {
        const cultureData = data.sort((a, b) => a.rank - b.rank)
        this.isCultureQuizCompleted = true
        this.cultureQuizInfo = this.cultureFactors.find(
          (e) => e?.description?.name === cultureData[0]?.name
        )?.description
        this.cultureFactors.forEach((culture) => {
          culture['count'] =
            cultureData.find((e) => e?.name === culture?.description?.name)?.score || 0
        })
        this.cultureQuizData = cultureData
      }
    })
  }

  openCultureScoreModal() {
    const cultureFactors = [...this.cultureFactors]
    cultureFactors.sort((a, b) => {
      return Number(b?.count) - Number(a?.count)
    })

    this.dialog.open(CultureScoreComponent, {
      panelClass: 'my-score-modal',
      autoFocus: false,
      data: {
        cultureTestInfo: this.cultureQuizInfo,
        cultureFactors: cultureFactors,
        header: 'Culture'
      }
    })
  }

  uploadExp1Image(elementId) {
    const myDropzone = document.getElementById(elementId)
    myDropzone.getElementsByTagName('input')[0].click()
  }
}
