import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { getSessionValue } from '../../../common-utils'
import * as moment from 'moment'
import { CommonService } from 'src/app/common.service'
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal'
import { CRUDService } from 'src/app/crud.service'
import { NgxSpinnerService } from 'ngx-spinner'
import { MatDialog } from '@angular/material/dialog'
import { EventDetailComponent } from 'src/app/scheduler/schedule-calendar/event-detail/event-detail.component'
import { ViewCandidatesComponent } from '../../modals/view-candidates/view-candidates.component'
import { ToastrService } from 'ngx-toastr'
declare var $: any
@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {
  notificationArray: any = []
  role: any
  messages: string = 'New Notifications'
  user_uuid: any
  searchData: any
  businessPostCandidateInfo: any
  businessPost: any
  businessPostSlots: any
  bsModalRef: BsModalRef
  readNotifications: any = []
  constructor(
    private router: Router,
    private commonService: CommonService,
    private dialog: MatDialog,
    private _bsModalRef: BsModalRef,
    private bsModalService: BsModalService,
    private CrudService: CRUDService,
    private spinnerService: NgxSpinnerService,
    private toastr: ToastrService
  ) {}

  async ngOnInit() {
    $('.modal-content').on('contextmenu', function () {
      // Display the message
      return false
    })
    this.spinnerService.show()
    this.commonService.currentNotificationMessage.subscribe((message: any) => {
      this.getNotifications()
    })
    await this.getNotifications()
    await this.getBusinessPostCandidates()
  }
  async getNotifications() {
    return new Promise<void>(async (resolve) => {
      let userInfo = getSessionValue('USER_INFO')
      this.user_uuid = userInfo.id
      this.role = localStorage.getItem('role')
      let notifications: any = await this.CrudService.getDataByField(
        'AppNotifications',
        'user_id',
        userInfo.id
      ).toPromise()
      this.notificationArray = notifications.filter((val: any) => val.read_status === '0')
      this.readNotifications = notifications.filter((val: any) => val.read_status === '1')
      this.notificationArray?.map((notification: any) => {
        notification.created = moment(notification.created_at).fromNow()
      })
      this.spinnerService.hide()
      resolve()
    })
  }

  async getBusinessPostCandidates() {
    const businessPostCandidates: any = await this.CrudService.getDataByField(
      'BusinessPostCandidates',
      'user_id',
      this.user_uuid
    ).toPromise()
    this.notificationArray.forEach((notification) => {
      const data = businessPostCandidates.find((value) => {
        return value.post_id === notification.ref_id
      })
      if (data?.status == '1' && data?.action_status == '1') {
        notification['showAccept'] = true
      } else {
        notification['showAccept'] = false
      }
    })
  }

  closeNotification() {
    const path: any = localStorage.getItem('previous_path')
    this.router.navigate([path])
  }
  searchNotification(event: any) {
    this.notificationArray = this.searchData.filter((data: any, index: any) => {
      if (
        data.msg.toLowerCase().toString().includes(event.target.value.toLowerCase()) ||
        data.channel_name.toLowerCase().toString().includes(event.target.value.toLowerCase())
      ) {
        return data
      }
    })
    if (event.target.value === '' || event.target.value === undefined) {
      this.notificationArray = this.searchData
    }
  }
  closeModal() {
    this.bsModalService.hide()
  }
  clearNotification(notification: any, index: any) {
    this.notificationArray.splice(index, 1)
    this.updateReadStatus(notification)
  }
  async updateReadStatus(notification: any) {
    notification.read_status = '1'
    notification.read_on = new Date()
    delete notification.showAccept
    await this.commonService.updateRecord('AppNotifications', notification)
    this.getNotifications()
    this.commonService.getUnreadCount()
    if (notification.ref_type === 'ROLE') {
      await this.getJobInfo(notification)
      this.router.navigateByUrl('/dashboards/view-job', {
        state: {
          roleInfo: this.businessPost,
          businessPostCandidateInfo: this.businessPostCandidateInfo
        }
      })
    } else if (notification.ref_type === 'SCHEDULE') {
      let actedCandidates: any = await this.CrudService.getDataByField(
        'BusinessPostCandidates',
        'post_id',
        notification.ref_id
      ).toPromise()
      const candidateSlots = actedCandidates.filter((val: any) => val.user_id === this.user_uuid)
      let data1: any = await this.CrudService.getDataByField(
        'BusinessPosts',
        'id',
        notification.ref_id
      ).toPromise()
      data1[0].startDate = candidateSlots['slot_start_time']
        ? moment(candidateSlots['slot_start_time']).format('YYYY-MM-DD hh:mm:ss')
        : ''
      data1[0].endDate = candidateSlots['slot_end_time']
        ? moment(candidateSlots['slot_end_time']).format('YYYY-MM-DD')
        : ''
      const json = {
        title: data1[0].title,
        extendedProps: data1[0]
      }
      this.dialog.open(EventDetailComponent, {
        panelClass: 'event-details-dialog',
        width: '80vh',
        data: {
          dateSlots: json
        }
      })
    } else if (
      notification.ref_type === 'BUSINESS' ||
      notification.ref_type?.toLowerCase() === 'business'
    ) {
      let data1: any = await this.CrudService.getDataByField(
        'BusinessPosts',
        'id',
        notification.ref_id
      ).toPromise()
      this.router.navigate(['/dashboards/view-role'], {
        state: { roleInfo: data1[0], edit: true }
      })
    } else if (
      notification.ref_type === 'CANDIDATE' ||
      notification.ref_type?.toLowerCase() === 'candidate'
    ) {
      let data1: any = await this.CrudService.getDataByField(
        'BusinessPosts',
        'id',
        notification.ref_id
      ).toPromise()
      let candidateData = await this.CrudService.getDataByField(
        'CandidateProfile',
        'user_id',
        notification.user_id
      ).toPromise()
      candidateData[0]['businessData'] = data1
      const initialState: any = {
        data: candidateData[0],
        type: 'view-candidate'
      }
      this.bsModalRef = this.bsModalService.show(ViewCandidatesComponent, {
        ariaLabelledBy: 'modal-basic-title',
        class: 'modal-height modal-lg',
        backdrop: 'static',
        initialState
      })
    }
    this._bsModalRef.hide()
  }

  async acceptConnection(notification) {
    await this.getJobInfo(notification)
    // const data: any = await this.CrudService.getDataByField('BusinessPostCandidates', 'user_id', notification.user_id).toPromise();
    // const businessPostCandidate = data.find((value) => {return value.post_id === notification.ref_id});
    const businessPostCandidate = this.businessPostCandidateInfo
    businessPostCandidate['status'] = 1
    businessPostCandidate['action_status'] = 5
    let response: any = await this.commonService.updateRecord(
      'BusinessPostCandidates',
      businessPostCandidate
    )
    if (response.success == true || response.success == 'true') {
      notification.read_status = '1'
      notification.read_on = new Date()
      delete notification.showAccept
      await this.commonService.updateRecord('AppNotifications', notification)
      this.getNotifications()
      this.commonService.getUnreadCount()
      this.toastr.success('Connection Accepted!')
    } else {
      this.toastr.error('Something went wrong, please try again later')
    }
  }
  async getJobInfo(post: any) {
    return new Promise<void>(async (resolve) => {
      // let res: any = await this.CrudService.getBusinessPostCandidatesInfo(
      //   post.ref_id,
      //   this.user_uuid
      // ).toPromise();
      // this.businessPostCandidateInfo = res[0];
      const res: any = await this.CrudService.getDataByField(
        'BusinessPostCandidates',
        'user_id',
        this.user_uuid
      ).toPromise()
      this.businessPostCandidateInfo = res.find((value) => {
        return value.post_id === post.ref_id
      })
      let data: any = await this.CrudService.getDataByField(
        'BusinessPostSlots',
        'post_id',
        post.ref_id
      ).toPromise()
      this.businessPostSlots = data
      let data1: any = await this.CrudService.getDataByField(
        'BusinessPosts',
        'id',
        post.ref_id
      ).toPromise()
      this.businessPost = data1[0]
      resolve()
    })
  }
  async openNotifications(notification) {
    if (notification.ref_type === 'ROLE') {
      await this.getJobInfo(notification)
      this.router.navigateByUrl('/dashboards/view-job', {
        state: {
          roleInfo: this.businessPost,
          businessPostCandidateInfo: this.businessPostCandidateInfo
        }
      })
    } else if (notification.ref_type === 'SCHEDULE') {
      let actedCandidates: any = await this.CrudService.getDataByField(
        'BusinessPostCandidates',
        'post_id',
        notification.ref_id
      ).toPromise()
      const candidateSlots = actedCandidates.filter((val: any) => val.user_id === this.user_uuid)
      let data1: any = await this.CrudService.getDataByField(
        'BusinessPosts',
        'id',
        notification.ref_id
      ).toPromise()
      data1[0].startDate = candidateSlots['slot_start_time']
        ? moment(candidateSlots['slot_start_time']).format('YYYY-MM-DD hh:mm:ss')
        : ''
      data1[0].endDate = candidateSlots['slot_end_time']
        ? moment(candidateSlots['slot_end_time']).format('YYYY-MM-DD')
        : ''
      const json = {
        title: data1[0].title,
        extendedProps: data1[0]
      }
      this.dialog.open(EventDetailComponent, {
        panelClass: 'event-details-dialog',
        width: '80vh',
        data: {
          dateSlots: json
        }
      })
    } else if (
      notification.ref_type === 'BUSINESS' ||
      notification.ref_type?.toLowerCase() === 'business'
    ) {
      let data1: any = await this.CrudService.getDataByField(
        'BusinessPosts',
        'id',
        notification.ref_id
      ).toPromise()
      this.router.navigate(['/dashboards/view-role'], {
        state: { roleInfo: data1[0], edit: true }
      })
    } else if (
      notification.ref_type === 'CANDIDATE' ||
      notification.ref_type?.toLowerCase() === 'candidate'
    ) {
      let data1: any = await this.CrudService.getDataByField(
        'BusinessPosts',
        'id',
        notification.ref_id
      ).toPromise()
      let candidateData = await this.CrudService.getDataByField(
        'CandidateProfile',
        'user_id',
        notification.user_id
      ).toPromise()
      candidateData[0]['businessData'] = data1
      const initialState: any = {
        data: candidateData[0],
        type: 'view-candidate'
      }
      this.bsModalRef = this.bsModalService.show(ViewCandidatesComponent, {
        ariaLabelledBy: 'modal-basic-title',
        class: 'modal-height modal-lg',
        backdrop: 'static',
        initialState
      })
    }
    this._bsModalRef.hide()
  }
}
