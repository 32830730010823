import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { environment } from '../../../environments/environment'
import { map } from 'rxjs/operators'
import { ToastrService } from 'ngx-toastr'
import { NewAppInfo, AppInfoListResponse } from 'src/app/core/models/app-info'
import { ApiResponseNoData } from 'src/app/core/models/base'

@Injectable()
export class AppInfoService {
  constructor(
    private http: HttpClient,
    private toastr: ToastrService
  ) {}

  getAllAppInfo() {
    return this.http.get<AppInfoListResponse>(`${environment.apiUrl}/v1/app-info`).pipe(
      map(
        (res) => {
          return res
        },
        (err) => {
          console.log('error getting FAQs', err)
        }
      )
    )
  }

  addNewAppInfo(appInfo: NewAppInfo) {
    return this.http.post<ApiResponseNoData>(`${environment.apiUrl}/v1/app-info`, appInfo).pipe(
      map((res) => {
        this.toastr.success('Values Map added successfully')
        return res
      })
    )
  }

  updateAppInfo(appInfo: NewAppInfo) {
    return this.http
      .put<ApiResponseNoData>(`${environment.apiUrl}/v1/app-info/${appInfo.id}`, appInfo)
      .pipe(
        map((res) => {
          this.toastr.success('Values Map updated successfully')
          return res
        })
      )
  }
}
