import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import * as moment from 'moment'
import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal'
import { CustomValidators } from 'ngx-custom-validators'
import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { CommonService } from 'src/app/common.service'

@Component({
  selector: 'app-leadership-profile',
  templateUrl: './leadership-profile.component.html',
  styleUrls: ['./leadership-profile.component.scss']
})
export class LeadershipProfileComponent implements OnInit {
  profileForm: FormGroup
  reasonList: any = []
  type: any
  bsModalRef: BsModalRef
  showInput: boolean = false
  maxDate: any
  minDate: any
  submitted: boolean = false
  SearchCountryField = SearchCountryField
  CountryISO = CountryISO
  PhoneNumberFormat = PhoneNumberFormat
  invalidPhoneNumber: boolean = false
  data: any

  @Output() messageEvent = new EventEmitter<string>()

  constructor(
    public dialogRef: MatDialogRef<LeadershipProfileComponent>,
    @Inject(MAT_DIALOG_DATA) private data1: any,
    private CommonService: CommonService,
    private _bsModalRef: BsModalRef,
    public options: ModalOptions,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.data = this.data1
    this.maxDate = moment({
      year: new Date().getFullYear() - 70,
      month: new Date().getMonth(),
      day: new Date().getDay()
    }).format('YYYY-MM-DD')
    this.minDate = moment({
      year: new Date().getFullYear() - 18,
      month: new Date().getMonth(),
      day: new Date().getDay()
    }).format('YYYY-MM-DD')

    this.profileForm = this.fb.group({
      dob: [''],
      mobile: [''],
      email: [
        null,
        Validators.compose([Validators.required, CustomValidators.email, Validators.maxLength(200)])
      ]
    })
  }

  checkNumberValidity(value) {
    value = value.internationalNumber.replace(' ', '')
    if (value.length > 9 && value.length < 16) {
      this.invalidPhoneNumber = false
    } else {
      this.invalidPhoneNumber = true
    }
  }
  closeModal() {
    this._bsModalRef.hide()
    // this.bsModalService.hide();
  }
  async profileSubmit() {
    let msg: any
    this.submitted = true
    this.data.data['email_id'] = this.profileForm.controls['email'].value
    if (typeof this.profileForm.value.dob !== 'undefined') {
      this.data.data.dob = moment(this.profileForm.value.dob._d).format('DD/MM/YYYY')
    }
    this.data.data['primary_phone'] = this.profileForm.controls['mobile'].value.internationalNumber
    const json = {
      first_name: this.data.data.first_name,
      last_name: this.data.data.last_name,
      email_id: this.data.data.email_id,
      dob: this.data.data.dob,
      primary_phone: this.data.data.primary_phone
    }
    const response: any = await this.CommonService.updateRecord('Users', json)
    if (response.success === true) {
      this.data.data['user_id'] = response.ROWID
      await this.CommonService.updateRecord('Leadership', this.data.data)
    }
    // }

    this.messageEvent.emit(msg)
    this.dialogRef.close()
    // this.bsModalRef.hide()
  }
}
