<div class="postsBox row col-12 profileCard">
  <div class="row d-flex" style="flex-wrap: nowrap">
    <div class="profile-div pl-2">
      <img
        class="profile-img"
        src="{{ profileImage }}"
        onerror="this.onerror=null;this.src='assets/images/business/business_logo.png'"
      />
    </div>
    <div class="pl-2" style="display: grid">
      <label class="profile-label">{{ businessProfile?.name }}</label>
      <button
        mat-raised-button
        class="view-profile-btn"
        (click)="openViewProfile()"
        style="color: #0c2149"
      >
        View Profile
      </button>
    </div>
  </div>
  <div class="mt-2 w-100">
    <mat-label class="roles-available">Hiring</mat-label>
    <mat-slide-toggle
      [checked]="roles_available_check"
      [(ngModel)]="roles_available"
      (change)="changeRolesStatus($event)"
    >
    </mat-slide-toggle>
  </div>
  <!-- <div class="mt-2">
        <label class="looking-label">Profile status -
            {{businessProfile?.profile_completion?businessProfile?.profile_completion:0}}%</label>
        <mat-progress-bar class="mt-1"
            [ngClass]="{'yellow-class':businessProfile?.profile_completion < 25,'blue-class':businessProfile?.profile_completion >= 25 && businessProfile?.profile_completion < 50,'orange-class':businessProfile?.profile_completion >= 50 && businessProfile?.profile_completion < 75,'pink-class':businessProfile?.profile_completion >=75 && businessProfile?.profile_completion <= 100}"
            mode="determinate" [value]="businessProfile?.profile_completion"></mat-progress-bar>
    </div> -->
  <!-- <div class="mt-2">
        <label class="looking-label">We're looking for</label>
        <mat-form-field appearance="fill" class="mt-1">
            <mat-select [(ngModel)]="selectedRole" name="business" placeholder="Select option">
                <mat-option *ngFor="let role of roleArray" [value]="role?.title">
                    {{role?.description}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div> -->
</div>
