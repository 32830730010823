import { Injectable } from '@angular/core'
import { Subject } from 'rxjs'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { getToken } from './common-utils'
import { environment } from 'src/environments/environment'

@Injectable({ providedIn: 'root' })
export class CRUDService {
  private CrudSource = new Subject<string>()
  private resetSource = new Subject()
  CrudSource$ = this.CrudSource.asObservable()
  resetSource$ = this.resetSource.asObservable()
  /* 
		appRoot = 'https://upplftmvp.com/';
		apiRoot = 'https://upplftmvp.com:4001/';
	 */

  appRoot = environment.appRoot + '/'
  //'https://upplftstaging.com/';
  apiRoot = environment.apiUrl + '/'
  //'https://upplftstaging.com:4001/';

  token: any = ''
  //token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjEiLCJpYXQiOjE1MDY1NjA5ODB9.LAGHEE6tyd9FmZXaK40yDeUkHVZkOl8MdXSrtEcGwB4';

  constructor(private http: HttpClient) {}
  // tslint:disable: max-line-length
  getAPIRoot() {
    return this.apiRoot
  }
  getAPPRoot() {
    return this.appRoot
  }
  create(moduleName: any, input: any) {
    const headers = new HttpHeaders().set('Authorization', getToken()).set('keyword', moduleName)
    return this.http.post(this.apiRoot + 'crud/processTransaction', input, {
      headers
    })
  }
  update(moduleName: any, input: any) {
    const headers = new HttpHeaders()
      .set('Authorization', getToken())
      .set('keyword', moduleName + '-update')
    return this.http.post(this.apiRoot + 'crud/processTransactionForUpdate', input, { headers })
  }

  createData(moduleName, data) {
    const headers = new HttpHeaders().set('Authorization', getToken()).set('keyword', moduleName)
    return this.http.post(
      this.apiRoot + 'crud/newRecordX',
      { tableName: moduleName, columns: data },
      { headers }
    )
  }

  updateData(moduleName, data) {
    const headers = new HttpHeaders().set('Authorization', getToken()).set('keyword', moduleName)
    return this.http.post(
      this.apiRoot + 'crud/updateRecordX',
      { tableName: moduleName, columns: data },
      { headers }
    )
  }

  AddData(moduleName: any, input: any) {
    const headers = new HttpHeaders().set('Authorization', getToken()).set('keyword', moduleName)
    return this.http.post(this.apiRoot + 'crud/AddData', input, { headers })
  }

  deleteByField(moduleName: any, filterField: any, filterValue: any) {
    const headers = new HttpHeaders().set('Authorization', getToken())
    return this.http.get(this.apiRoot + 'crud/deleteRecordByField', {
      headers,
      params: {
        table_name: moduleName,
        field_name: filterField,
        value: filterValue
      }
    })
  }

  getDescByField(moduleName: any, descField: any, filterField: any, filterValue: any) {
    const headers = new HttpHeaders().set('Authorization', getToken())
    return this.http.get(this.apiRoot + 'crud/getDescriptionValue', {
      headers,
      params: {
        table_name: moduleName,
        column_name: descField,
        field_name: filterField,
        value: filterValue
      }
    })
  }
  getValuesMapList(name: any) {
    const headers = new HttpHeaders().set('Authorization', getToken())
    return this.http.get(this.apiRoot + 'crud/getDescriptionObject', {
      headers,
      params: {
        table_name: 'ValuesMap',
        field_name: 'value_group',
        value: name
      }
    })
  }
  getValuesMapInOrder(name: any) {
    const headers = new HttpHeaders().set('Authorization', getToken())
    return this.http.get(this.apiRoot + 'crud/getValuesMapInOrder', {
      headers,
      params: { value: name }
    })
  }
  getDataByField(moduleName: any, filterField: any, filterValue: any) {
    const headers = new HttpHeaders().set('Authorization', getToken())
    return this.http.get(this.apiRoot + 'crud/getDescriptionObject', {
      headers,
      params: {
        table_name: moduleName,
        field_name: filterField,
        value: filterValue
      }
    })
  }
  getDataByTextField(moduleName: any, filterField: any, filterValue: any) {
    const headers = new HttpHeaders().set('Authorization', getToken())
    return this.http.get(this.apiRoot + 'crud/getDescriptionObjectLive', {
      headers,
      params: {
        table_name: moduleName,
        field_name: filterField,
        value: filterValue
      }
    })
  }
  getBusinessPostAttachments() {
    const headers = new HttpHeaders().set('Authorization', getToken())
    return this.http.get(this.apiRoot + 'getData/getBusinessPostAttachments', {
      headers,
      params: {}
    })
  }
  getAllData(table_name: any) {
    const headers = new HttpHeaders().set('Authorization', getToken())
    return this.http.get(this.apiRoot + 'crud/getAllData', {
      headers,
      params: { table_name }
    })
  }
  checkLoginSSO(email_id, user_type) {
    const headers = new HttpHeaders().set('Authorization', getToken())
    return this.http.post(this.apiRoot + 'auth/checkLoginSSO', { email_id, user_type }, { headers })
  }
  uploadChatImage(file: any, roleId: any, userId: any) {
    const data = new FormData()
    data.append('file', file)
    data.append('channel_id', roleId)
    data.append('sender_id', userId)
    let response = null
    const xhr = new XMLHttpRequest()
    let url = this.apiRoot + 'file/uploadImage'
    xhr.open('POST', url, false)
    xhr.setRequestHeader('Authorization', getToken())
    xhr.send(data)
    xhr.onload = function () {
      response = xhr.response
    }
    if (xhr.readyState == 4 && xhr.status == 200) {
      response = xhr.response // no parsing needed
    }
    return response
  }

  // uploadFile(file: File, path: any) {
  // 	let response = null;
  // 	const data = new FormData();
  // 	data.append('files', file);
  // 	data.append('filepath', path);
  // 	const xhr = new XMLHttpRequest();
  // 	let url = this.apiRoot + 'file/uploadFile';
  // 	xhr.open('POST', url, false);
  // 	xhr.setRequestHeader('Authorization', getToken());
  // 	xhr.send(data);
  // 	xhr.onload = function () {
  // 		response = xhr.response;
  // 	};
  // 	if (xhr.readyState == 4 && xhr.status == 200) {
  // 		response = xhr.response; // no parsing needed
  // 	}
  // 	return response;
  // }

  uploadFile(file: File) {
    const formData = new FormData()
    formData.append('files', file)

    const headers = new HttpHeaders().set('Authorization', getToken())
    return this.http.post(this.apiRoot + 'file/uploadFile', formData, {
      headers
    })
  }

  sendPushNotification(data: any) {
    const headers = new HttpHeaders().set('Authorization', getToken())
    return this.http.post(this.apiRoot + 'notification/send', data, {
      headers
    })
  }
  query(moduleName: any) {
    const headers = new HttpHeaders().set('Authorization', getToken()).set('keyword', moduleName)
    return this.http.post(this.apiRoot + 'crud/processTransaction', {}, { headers })
  }
  updatePassword(id: any, password: any) {
    const headers = new HttpHeaders().set('Authorization', getToken())
    return this.http.get(this.apiRoot + 'auth/updatePassword', {
      headers,
      params: { id, password }
    })
  }
  changePassword(userId: any, password: any) {
    const headers = new HttpHeaders().set('Authorization', getToken())
    return this.http.post(this.apiRoot + 'mail/reset', { userId, password }, { headers })
  }
  getUserDetails(email_id: string, password: string) {
    const headers = new HttpHeaders().set('Authorization', getToken())
    return this.http.post(this.apiRoot + 'auth/checkLogin', { email_id, password }, { headers })
  }
  getTeamsToken() {
    const headers = new HttpHeaders().set('Authorization', getToken())
    return this.http.post(this.apiRoot + 'teamsmeeting/updateToken', {}, { headers })
  }
  createTeamsMeeting(params: any) {
    const headers = new HttpHeaders().set('Authorization', getToken())
    return this.http.post(this.apiRoot + 'teamsmeeting/createMeeting', params, {
      headers
    })
  }

  getPosts(user_id: any) {
    const headers = new HttpHeaders().set('Authorization', getToken())
    return this.http.get(this.apiRoot + 'getData/getPosts', {
      headers,
      params: { user_id }
    })
  }
  getBusinessPostCandidatesInfo(role_id: any, user_id: any) {
    const headers = new HttpHeaders().set('Authorization', getToken())
    return this.http.get(this.apiRoot + 'getData/getBusinessPostsCandidateInfo', {
      headers,
      params: { role_id, user_id }
    })
  }
  cancelTeamsMeeting(meeting_id: any, token: any) {
    const headers = new HttpHeaders().set('Authorization', getToken())
    return this.http.post(
      this.apiRoot + 'teamsmeeting/cancelMeeting',
      { meeting_id, token },
      { headers }
    )
  }

  getUnreadCount() {
    const headers = new HttpHeaders().set('Authorization', getToken())
    return this.http.get(this.apiRoot + 'getData/getUnreadCount', { headers })
  }
  socialLogin(url: any) {
    const headers = new HttpHeaders().set('Authorization', getToken())
    return this.http.post(this.apiRoot + 'sociallogin/linkedinlogin', { url }, { headers })
  }

  getAdminUserInfo() {
    const headers = new HttpHeaders().set('Authorization', getToken())
    return this.http.post(this.apiRoot + 'auth/getAdminUserInfo', {
      headers,
      params: {}
    })
  }
  verifyMail(body: any) {
    // const headers = new HttpHeaders().set('Authorization', getToken());
    return this.http.post(this.apiRoot + 'mail/verfiyAccount ', body)
  }
  getEvents() {
    // const headers = new HttpHeaders().set('Authorization', getToken());
    return this.http.get(this.apiRoot + 'getData/getEvents')
  }
  getCandidateEvents(candidate_id: any) {
    const headers = new HttpHeaders().set('Authorization', getToken())
    return this.http.get(this.apiRoot + 'getData/getEvents', {
      headers,
      params: { candidate_id }
    })
  }

  getRoleEvents(post_id: any) {
    const headers = new HttpHeaders().set('Authorization', getToken())
    return this.http.get(this.apiRoot + 'getData/getRoleEvents', {
      headers,
      params: { post_id }
    })
  }

  getBusinessEvents(business_id: any) {
    const headers = new HttpHeaders().set('Authorization', getToken())
    return this.http.get(this.apiRoot + 'getData/getBusinessEvents', {
      headers,
      params: { business_id }
    })
  }
  verifyToken(token: any) {
    return this.http.get(this.apiRoot + 'mail/verfiy/' + token)
  }
  resetPassword(body: any) {
    return this.http.post(this.apiRoot + 'mail/resetPasswordWithToken', body)
  }
  verifyOtp(body: any) {
    return this.http.post(this.apiRoot + 'mail/verifyOtp', body)
  }
  resetPasswordData(body: any) {
    return this.http.post(this.apiRoot + 'mail/reset', body)
  }

  getValuesMaps() {
    const headers = new HttpHeaders().set('Authorization', getToken())
    return this.http.get(this.apiRoot + 'getData/getValuesMapList', {
      headers,
      params: {}
    })
  }
  getCandidatesList() {
    const headers = new HttpHeaders().set('Authorization', getToken())
    return this.http.get(this.apiRoot + 'getData/getCandidatesList', {
      headers,
      params: {}
    })
  }
  getAppliedCandidatesList(post_id) {
    const headers = new HttpHeaders().set('Authorization', getToken())
    return this.http.get(this.apiRoot + 'getData/getAppliedCandidatesList', {
      headers,
      params: { post_id }
    })
  }
  sendMail(body) {
    // const headers = new HttpHeaders().set("Authorization", getToken());
    return this.http.post(this.apiRoot + 'mail/mailWithCC', body)
  }
  shortlistMailer(body) {
    // const headers = new HttpHeaders().set("Authorization", getToken());
    return this.http.post(this.apiRoot + 'mail/shortlistCandidate', body)
  }
  sendMailForDeactivation(body) {
    // const headers = new HttpHeaders().set("Authorization", getToken());
    return this.http.post(this.apiRoot + 'mail/deactivate', body)
  }
  generateToken(userId) {
    // const headers = new HttpHeaders().set("Authorization", getToken());
    return this.http.post(this.apiRoot + 'user/generateToken', { userId })
  }
  getProfileCompletionData(order: any = 'desc') {
    const headers = new HttpHeaders().set('Authorization', getToken())
    return this.http.get(this.apiRoot + 'candidate/profileProgress?order=' + order, {
      headers,
      params: {}
    })
  }
  getStatisticsDataByMonth(year: any) {
    const headers = new HttpHeaders().set('Authorization', getToken())
    return this.http.get(this.apiRoot + 'candidate/statistic?year=' + year, {
      headers,
      params: {}
    })
  }
  getStatisticsDataByDate(month: any, year: any) {
    const headers = new HttpHeaders().set('Authorization', getToken())
    return this.http.get(this.apiRoot + 'candidate/statistic?month=' + month + '&year=' + year, {
      headers,
      params: {}
    })
  }
  getBusinessPostByToken(token: string) {
    const headers = new HttpHeaders().set('Authorization', getToken())
    return this.http.get(this.apiRoot + `businessPosts/getbyToken/${token}`, {
      headers,
      params: {}
    })
  }
  createBusinessPost(body: any) {
    const headers = new HttpHeaders().set('Authorization', getToken())
    return this.http.post(this.apiRoot + 'businessPosts', body, { headers })
  }
  createCandidateHubspot(body: any) {
    const headers = new HttpHeaders().set('Authorization', getToken())
    return this.http.post(this.apiRoot + 'hubspot/createCandidate', body, {
      headers
    })
  }
  updateCandidateHubspot(body: any) {
    const headers = new HttpHeaders().set('Authorization', getToken())
    return this.http.post(this.apiRoot + 'hubspot/updateCandidate', body, {
      headers
    })
  }
  createClientHubspot(body: any) {
    const headers = new HttpHeaders().set('Authorization', getToken())
    return this.http.post(this.apiRoot + 'hubspot/createClient', body, {
      headers
    })
  }
  updateClientHubspot(body: any) {
    const headers = new HttpHeaders().set('Authorization', getToken())
    return this.http.post(this.apiRoot + 'hubspot/updateClient', body, {
      headers
    })
  }
  getHubspotIdByEmail(body) {
    const headers = new HttpHeaders().set('Authorization', getToken())
    return this.http.post(this.apiRoot + 'hubspot/getHubspotIdByEmail', body, {
      headers
    })
  }
  sendMailToTalentPartner(body) {
    // const headers = new HttpHeaders().set("Authorization", getToken());
    return this.http.post(this.apiRoot + 'mail/candidateExistTalentPartner', body)
  }
  rejectCandidateMail(body) {
    // const headers = new HttpHeaders().set("Authorization", getToken());
    return this.http.post(this.apiRoot + 'mail/rejectCandidate', body)
  }
  nextInterviewRoundMail(body) {
    // const headers = new HttpHeaders().set("Authorization", getToken());
    return this.http.post(this.apiRoot + 'mail/nextInterviewRound', body)
  }
  appliedForRoleMail(body) {
    // const headers = new HttpHeaders().set("Authorization", getToken());
    return this.http.post(this.apiRoot + 'mail/appliedForRole', body)
  }
  syncDataToHubspot(body) {
    const headers = new HttpHeaders().set('Authorization', getToken())
    return this.http.post(this.apiRoot + 'hubspot/sync', body, {
      headers
    })
  }
}
