<div>
  <div class="modal-header">
    <h4 class="modal-title">Confirmation</h4>
    <button type="button" class="close" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">Do you want to enable Notifications in this Device?</div>
  <div class="modal-footer">
    <button mat-raised-button class="confirm-button" (click)="confirm()">Yes</button>
    <button mat-raised-button class="cancle-button" (click)="closeModal()">No</button>
  </div>
</div>
